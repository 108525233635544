<template>

    <ChooseType v-if="PageToLoad == 'choose'" @typechoosed="LoadFinancePage" />
    <TenantPayment v-if="PageToLoad == 'payment'" :data="PageData" @openmainpage="PageToLoad = 'choose'" @closepage="PaymentUpdated" />
    <BulkRentPayment v-if="PageToLoad == 'bulk'" :data="PageData" @openmainpage="PageToLoad = 'choose'" @closepage="PaymentUpdated" />
    <UnitCharge v-if="PageToLoad == 'charges'" :data="PageData" @openmainpage="PageToLoad = 'choose'" @closepage="PaymentUpdated" />
    <BusinessExpnese v-if="PageToLoad == 'business'" :data="PageData" @openmainpage="PageToLoad = 'choose'" @closepage="PaymentUpdated" />

</template>

<script>

    // components
    import ChooseType from './ChooseType.vue';
    import BusinessExpnese from './BusinessExpnese.vue';
    import TenantPayment from './TenantPayment.vue';
    import UnitCharge from './UnitCharge.vue';
    import BulkRentPayment from './BulkRentPayment.vue';

    export default {
        name: 'AddTransaction',
        props: ['data'],
        emits: ['close'],
        components: {
            ChooseType,
            BusinessExpnese,
            TenantPayment,
            UnitCharge,
            BulkRentPayment,
        },

        data () {
            return {
                /*
                    1. choose type
                    2. business expenses
                    3. tenant payment
                    4. tenant/unit charges
                    5. bulk rent payment for selected property
                */

                PageToLoad: this.data.type,
                PageData: this.data,
                

            }
        },

        mounted () {
            console.log (this.data);
        },

        methods: {

            // move ahead with selected finance transaction to do
            LoadFinancePage (type) {
                this.PageToLoad = type;
            },

            // tenant payment has been added
            PaymentUpdated (payment) {
                this.$emit('close', payment);
            }


        }
    }
</script>