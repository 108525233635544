<template>
  <div class="prop-form _propscrl">
    <div class="form-area" style="padding-bottom: 65px">
      <form name="propform" id="propform-moveout" style="display: block">
        <div class="pf--title">
          <h3>Initiate Tenant Moveout.</h3>
          <p>You can update the tenant/unit move-out process here.</p>
        </div>
        
        <div class="pf--input">
          <p>Movepout Date</p>
          <Datepicker class="dp__input_custom" v-model="EffectiveDate" :enableTimePicker="false" autoApply required placeholder="Pick Date" />
        </div>

        <div class="pf--input">
          <p>Reason</p>
          <select v-model="Reason" name="Notified" id="NOtified" data-type="select">
            <option value="-1" selected>- Choose One -</option>
            <option value="Moving to a new area">Moving to a new area</option>
            <option value="Cheaper Rent">Cheaper Rent</option>
            <option value="No Reason Provided">No Reason Provided</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div class="pf--input" v-if="Reason == 'Other'">
            <p>Other Reason</p>
            <input type="text" v-model="OtherReason" placeholder="i.e. Moveout note" id="OtherReason" data-type="len" data-max="2" :disabled="Requesting" />
        </div>

        <div class="pf--input">
            <p>Moveout Note</p>
            <input type="text" v-model="Note" placeholder="i.e. Moveout note" id="MiscOther" data-type="len" data-max="2" :disabled="Requesting" />
        </div>

      </form>

      <span v-if="formError" class="__formerror">{{ infoText }}</span>
    </div>

    <div class="form-action">
      <div class="form-area">
        <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="InitiateMoveOutProcess">
          <p v-if="!Requesting">Initiate Moveout</p>
          <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>

    //@ JS Modules
    import Request from "../../javascript/getRequest";
    import FormValidation from "../../javascript/FormValidation";
    import Handler from "../../javascript/Handler";

    // datepicer
    import Datepicker from "@vuepic/vue-datepicker";
    import "@vuepic/vue-datepicker/dist/main.css";

    export default {
      name: "InitiateMoveOut",
      props: ["data"],
      emits: ["close"],
      components: {
        Datepicker,
      },

      data() {
        return {
          EffectiveDate: null,
          Reason: '-1',
          OtherReason: '',
          Note: '',

          // form error
          formError: false,
          infoText: "",
          Requesting: false,
        };
      },

      mounted() {
        console.log (this.data);
      },

      methods: {
        
        async InitiateMoveOutProcess () {
          if (this.Requesting) return;

          // form & validation
          let form = document.getElementById("propform-moveout");
          let canGoAhead = FormValidation.Validate(form);

          if (canGoAhead) {
            this.Requesting = true;

            let formData = new FormData();

            formData.append("property", this.data.property.id);
            formData.append("unit", this.data.property.unit);
            formData.append("assignment", this.data.property.assignment);
            formData.append("tenant", this.data.tenant.id);

            // other data
            formData.append("reason", this.Reason);
            formData.append("note", this.Note);

            if (this.Reason == 'Other') {
              formData.append("other", this.OtherReason);
            }


            let EffectiveDate = this.EffectiveDate.getTime();
            EffectiveDate = EffectiveDate/1000;
            formData.append('date', EffectiveDate);

            // submit tasks data
            const ReturnData = await Request.postServer("owner/units/initiate-moveout.php", formData);
            this.Requesting = false;

            // now check if the email already exists
            if (ReturnData.error == false) {

              // notiy owner
              Handler.PropNotification("Successfully initiated unit/tenant moveout process!");
              this.$emit("close", true);

              // if error
            } else {
              this.formError = true;
              this.infoText = "Something went wrong!";
            }
          }
        },
      },
    };
</script>

<style scoped>
div.-vd-image-view {
  width: 100%;
  height: 240px;
  overflow: hidden;
  border: 1px solid #000;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 4px;
}
div.-vd-image-view img {
  width: calc(100% - 21px);
  height: calc(100% - 21px);
  object-fit: cover;
}

div.iagreebtn {
  display: flex;
  flex-direction: row;
}
div.iagreebtn input {
  display: flex;
  flex: 1;
  margin-right: 16px;
}
div.iagreebtn span {
  flex: 12;
  margin-top: 20px;
  letter-spacing: 0.15px;
  line-height: 16pt;
}

span.__formerror {
  margin-left: 0 !important;
}
.v-calendar {
  width: calc(100% - 2px) !important;
  min-width: 100%;
}
</style>
