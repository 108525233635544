<template>

    <div class="fc--header" style="overflow: unset;">      
      <div class="fc-more-filter" style="width: 100%">
        
        <div class="fil--area" style="width: 216px;margin-right: 14px;">
            <input type="text" placeholder="Search.." 
                    :style="{'background-image':'url('+require('../../../images/icons/search.svg')+')'}"
                    @keyup="SearchTransactionList" v-model="SearchKeyword" />
        </div>

        <div class="fmf-btn" @click="PropertySingleSelect = true">
            <img src="./../../../images/icons/tablesort.svg" />
            <p>Choose Property</p>
        </div>
        <PropertyList v-if="PropertySingleSelect" :properties="OwnerProperties"  @prosel="PropertySelected"
                @proboxclse="PropertySelectClose"
                :distyle="'top: 65px;position: absolute;height: 355px;width: 360px;left: 23px;overflow-y: scroll;'" />

        <div class="fmf-btn" @click="UnitsSingleSelect = true">
            <img src="./../../../images/icons/tablesort.svg" />
            <p>Choose Unit</p>
        </div>
        <UnitSelection v-if="UnitsSingleSelect == true" :units="PropertyUnits" @lstboxclse="UnitSelected"
            :distyle="'top: 62px;left: 200px;'" />

        <div class="fmf-btn" style="display: none;">
            <img src="./../../../images/icons/tablesort.svg" />
            <p>Categories</p>
        </div>

      </div>
    </div>

    <div class="prop--table" style="margin-top: 80px;">
        <div class="prop-row row-header">
            <div class="column column15 sortedColumn" title="Sort By Date" @click="SortDataByDate (SortingRules.sortbydate)"><p>Date</p> <img src="./../../../images/icons/tablesort.svg" /></div>
            <div class="column column15" style="font-weight: 700;"><p>Type</p></div>
            <div class="column column15 sortedColumn" title="Sort By Payment Type" @click="SortDataByPaymentType (SortingRules.sortybypayment)"><p>Payment Type</p><img src="./../../../images/icons/tablesort.svg" /></div>
            <div class="column column25 sortedColumn" title="Sort By Property Name" @click="SortDataByPropertyName (SortingRules.sortbyproperty)"><p>Property</p><img src="./../../../images/icons/tablesort.svg" /></div>
            <div class="column column15 sortedColumn" title="Sort By Unit Number" @click="SortDataByUnitNumber (SortingRules.sortybyunit)"><p>Unit</p><img src="./../../../images/icons/tablesort.svg" /></div>
            <div class="column column15 sortedColumn" title="Sort By Amount" @click="SortDataByAmount (SortingRules.sortbyamount)"><p>Amount</p><img src="./../../../images/icons/tablesort.svg" /></div>
        </div>

        <div v-if="isRequesting == true" 
            style="display: flex;flex-direction: column;justify-content: center;align-items: center;width: 100%;height: auto;padding-top: 50px;">
            <Skeleton :data="2" />
        </div>

        <div v-else class="prop-row" v-for="(trans, index) in FinanceTransactions.filtered" :key="trans" 
                :class="index % 2 ? 'odd' : 'even'" 
                style="margin-bottom: 0;padding: 25px 0;">

            <div class="column column15 animated fadeInUp"><p>{{ trans.date }}</p></div>
            <div class="column column15 animated fadeInUp"><p style="margin-right: 10px;">{{ trans.type }}</p></div>
            <div class="column column15 animated fadeInUp"><p style="margin-right: 10px;">{{ trans.paytype }}</p></div>
            <div class="column column25 animated fadeInUp"><p>{{ trans.propname }}</p></div>
            <div class="column column15  animated fadeInUp"><p>{{ trans.unit }}</p></div>
            <div class="column column15  animated fadeInUp" style="display: flex;flex-direction: row;justify-content: flex-start;align-items: center;">
                <p>{{parseFloat(trans.amount).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            })}}</p>
                <!-- <p>{{ trans.amount }}</p> -->
                <button v-if="trans.edit == true" class="btn-primary" @click="EditThisTransaction (trans)">Edit</button>
            </div>
        </div>

        <WalkThroughVue
                v-if="hasEditTransaction"
                :image="'edit-property.svg'"
                :title="'Edit Transaction'"
                :content="'You can edit transactions that were created within the last 15 minutes.'"
                :toPoint="'nowhere'"
                :style="'top: 70px;right: 115px;'"
                @closewth="hasEditTransaction = false" />

        <div class="empty-result animated fadeInUp" v-if="FinanceTransactions.filtered.length <= 0 && isRequesting == false">
            <img src="./../../../images/icons/on-verification.svg">
            <p>No transactions found with your filter!<br>Please update the filter and try again.</p>
        </div>

    </div>

    <!-- pop ups --> 
    <RightModal v-if="AddTransactionModal" :form="'add-transaction'" :data="AddTransactionData" @modalclose="CloseTransactionModal" />

</template>

<script>
    
    import DataCenter from './../../../javascript/DataCenter';

    // component
    import PropertyList from '../../blocks/PropertyList.vue';
    import UnitSelection from '../../web-forms/UnitSelection.vue';

    import Skeleton from '../../blocks/Skeleton.vue';
    import WalkThroughVue from '../../reusable/WalkThrough.vue';

    import RightModal from '../../modal/RightModal.vue';

    // pinia
    import { useOwnerStore } from './../../../stores/OwnerStore';

    export default {

        name: 'Transactions',
        // props: ['data'],
        // emits: ['close'],

        components: {
            Skeleton,
            WalkThroughVue,
            PropertyList,
            UnitSelection,

            RightModal,
        },

        data () {
            return {
                FinanceTransactions: {
                    filtered: [],
                    raw: []
                },

                SortingRules: {
                    sortbydate: false,
                    sortybyunit: false,
                    sortbyamount: false,
                    sortbyproperty: false,
                    sortybypayment: false
                },

                SearchKeyword: '',

                isRequesting: true,

                PropertySingleSelect: false,
                SelectedProperty: null,
                UnitsSingleSelect: false,
                SelectedUni: null,

                // pinia storage
                OwnerProperties: false,
                PropertyUnits: [],
                OwnerStore: useOwnerStore (),  
                
                // model
                AddTransactionModal: false,
                AddTransactionData: {
                    type: 'choose',
                    isMainPage: false,
                    property: false,
                    unit: false,
                    tenant: false,
                    assignment: false
                },

                hasEditTransaction: false,
            }

        },

        async mounted () {

            // reset on the main page
            // finance payment data
            this.OwnerStore.financepaymentdata = {
                type: 'choose',
                isMainPage: true,
                tenant: false,
                property: false,
                unit: false,
                whichProperty: {},
                whichUnit: {},
                whichTenant: {}
            }


            // update pinia storage to local variables
            this.OwnerProperties = this.OwnerStore.properties;

            // load current owner property transactions
            let Transactions = await DataCenter.ProPRequest('all-property-transaction-list', false, false);
            this.FinanceTransactions.raw = Transactions;
            this.FinanceTransactions.filtered = Transactions;
            this.isRequesting = false;

            this.hasEditTransaction = this.CheckIfContainEditTransaction ();
            
        },

        methods: {

            // search the data list
            SearchTransactionList () {
                this.FinanceTransactions.filtered = this.FinanceTransactions.filtered.filter (trans => {
        			var keyword = this.SearchKeyword.toUpperCase();
                    if (trans.propname.toUpperCase().indexOf(keyword) > -1 || trans.paytype.toUpperCase().indexOf(keyword) > -1 || trans.unit.toUpperCase().indexOf(keyword) > -1) return trans;
                });

                if (this.SearchKeyword.length == 0) {
                    this.FinanceTransactions.filtered = this.FinanceTransactions.raw;
                }
            },

            // sort by date
            SortDataByDate (updown) {
                this.SortingRules.sortbydate = !updown;
                this.FinanceTransactions.filtered = this.FinanceTransactions.filtered.sort(function(a,b){
                    if (updown) return new Date(b.datetime) - new Date(a.datetime);
                    else return new Date(a.datetime) - new Date(b.datetime);
                });
            },

            // sort by unit number
            SortDataByUnitNumber (updown) {
                this.SortingRules.sortybyunit = !updown;
                this.FinanceTransactions.filtered.sort ((a, b) => {
                    var c, d;
                    if (updown) { c = a; d = b} else {d = a; c = b;}
                    a = c; b = d;

                    if (Number(a.unit) > Number(b.unit)) return 1;
                    if (Number(a.unit) == Number(b.unit)) return 0;
                    if (Number(a.unit) < Number(b.unit)) return -1;
                });
            },

            // sorty by amount
            SortDataByAmount (updown) {
                this.SortingRules.sortbyamount = !updown;
                this.FinanceTransactions.filtered.sort ((a, b) => {
                    var c, d;
                    if (updown) { c = a; d = b} else {d = a; c = b;}
                    a = c; b = d;

                    if (Number(a.amount) > Number(b.amount)) return 1;
                    if (Number(a.amount) == Number(b.amount)) return 0;
                    if (Number(a.amount) < Number(b.amount)) return -1;
                });
            },

            // sort by property name
            SortDataByPropertyName (updown) {
                this.SortingRules.sortbyproperty = !updown;
                this.FinanceTransactions.filtered = this.FinanceTransactions.filtered.sort(function(a,b){
                    var c, d;
                    if (updown) { c = a; d = b} else {d = a; c = b;}
                    a = c; b = d;

                    if (a.propname > b.propname) return 1;
                    if (a.propname == b.propname) return 0;
                    if (a.propname < b.propname) return -1;
                });
            },

            // sort by payment type
            SortDataByPaymentType (updown) {
                this.SortingRules.sortybypayment = !updown;
                this.FinanceTransactions.filtered = this.FinanceTransactions.filtered.sort(function(a,b){
                    var c, d;
                    if (updown) { c = a; d = b} else {d = a; c = b;}
                    a = c; b = d;

                    if (a.paytype > b.paytype) return 1;
                    if (a.paytype == b.paytype) return 0;
                    if (a.paytype < b.paytype) return -1;
                });
            },

            CheckIfContainEditTransaction () {
                var hasEdit = false;
                this.FinanceTransactions.raw.forEach(trans => {
                   if (trans.edit == true) hasEdit = true;
                }); return hasEdit;
            },

            // edit the selected transaction for edit.
            EditThisTransaction (trans) {
                this.AddTransactionData = {
                    type: trans.trans_type,
                    isMainPage: false,
                    trans: trans,
                    EditMode: true,
                }; this.AddTransactionModal = true;
            },
            
            CloseTransactionModal (trans) {
                if (trans) {
                    if (trans.EditMode) {
                        this.FinanceTransactions.raw.filter (tranx => {
                            if (tranx.trans == trans.trans) {
                                tranx.paytype = trans.paytype;
                                tranx.amount = trans.amount;
                            }
                        });
                    }
                }

                // close transaction
                this.AddTransactionModal = false;
            },

            FilterTransactions (type) {
                this.FinanceTransactions.filtered = this.FinanceTransactions.raw.filter ( trans => {
                    if (type == 'property') {
                        if (trans.pid == this.SelectedProperty) return trans;
                    }

                    if (type == 'unit') {
                        if (trans.uid == this.SelectedUnit) return trans;
                    }
                });
            },

            PropertySelected (property) {
                if (property !== null) {
                    // now load its unit too
                    this.LoadPropertyUnits (property.id);
                    this.SelectedProperty = property.id;
                    this.FilterTransactions ('property');
                }this.PropertySingleSelect = false;
            },

            PropertySelectClose () {
                this.PropertySingleSelect = false;
            },

            UnitSelected (unit) {
                if (unit !== null) {
                    this.SelectedUnit = unit.id;
                    this.FilterTransactions ('unit');
                }this.UnitsSingleSelect = false;
            },

            async LoadPropertyUnits (property) {   
                this.Requesting = true;
                const Units = await DataCenter.ProPRequest('property-units', false, {
                    key : 'property',
                    value : property
                });
                
                // now filter units which only have assigned tenants
                if (!Units) {}else {
                    this.PropertyUnits = Units;
                }  
            },

        }

    }

</script>

<style scoped>
   div.fmf-btn img {
    -o-object-fit: contain !important;
    object-fit: contain !important;
    width: 18px !important;
    height: 18px !important;
    margin-top: 1px !important;
    }
</style>