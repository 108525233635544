<script>

    // javascript library
    import Document from './../../javascript/Document';

    import { useAdminStore } from '../../stores/AdminStore';

    // component
    import Skeleton from './../../components/blocks/Skeleton.vue';
    import ReplaceImage from '../../components/blocks/ReplaceImage.vue';
    import HtmlSelect from './../../components/blocks/HtmlSelect.vue';  

    // component
    import PropModal from './../../components/modal/PropModal.vue';

    export default {
        name: 'OwnerProperties',
        props: ['userProfile', 'owners'],
        components : {
            Skeleton,
            ReplaceImage,
            HtmlSelect,

            PropModal
        },

        data () {
            return {
                AdminStore: useAdminStore(),

                PropertyFilters : [
                    {
                        id      : 1,
                        name    : 'All',
                        active  : true,
                    },
                    {
                        id      : 4,
                        name    : 'Verified',
                        active  : false,
                    },
                    {
                        id      : 3,
                        name    : 'Unverified',
                        active  : false,
                    },
                    {
                        id      : 2,
                        name    : 'Pending Verification',
                        active  : false,
                    },
                ],
                FilterChoosed : false,

                AdminProfile : false,

                OwnerAccounts : [],

                OwnerProperties : [],
                FilteredOwnerProperties : [],
                EmptyPropertiesList : false,
                TotalProperties : 0,
                PropertiesKey : false,

                // dialog
                ConfirmDialog : false,
                PropertyData: false,

                // update rent
                UpdateUnitRent: false,
                
                Properties : {
                    filtered: [],
                    raw: []
                },

                isRequesting: true,
                SearchText: '',
            }
        },

        async mounted () {
            // site title and seo
            Document.DocumentHeadings ({title : 'Dashboard - Properties'});

            // Admin Profile
            this.AdminProfile = this.AdminStore.user;

            // Accounts and Properties
            this.Properties.raw = this.AdminStore.properties; 
            this.Properties.filtered = this.AdminStore.properties;
            this.TotalProperties = this.AdminStore.properties.length;

            this.OwnerAccounts = this.AdminStore.owners;

            this.isRequesting = false;

        },

        methods: {

            UpdateRent () {
                this.UpdateUnitRent = true;
            },

            CloseUpdateUnitRent () {
                this.UpdateUnitRent = false;
            },

            // filter click
            PropertyFilterHandler (filter) {
                this.FilterChoosed = filter;

                // now filter the team members
                // and show to the stage
                this.Properties.filtered = this.Properties.raw.filter(property => {
                    if (filter.name == "All") return property;

                    if (filter.name == "Verified") {
                        if (property.verified == "true") return property;
                    }

                    if (filter.name == "Unverified") {
                        if (property.verified == "false") return property;
                    }

                    if (filter.name == "Pending Verification") {
                        if (property.verification == "true") return property;
                    }
                });
            },

            PropertyClickHandler (property) {                
                // select the owner of this property
                const Owners = this.OwnerAccounts.filter (owner => {
                    if (property.owner == owner.owner) return owner;
                });

                property['ownerprofile'] = Owners[0];

                this.PropertyData = {
                    property : property,
                    owner : Owners[0]
                };

                this.ConfirmDialog = true;
            },

            CloseConformModal (action) {
                if (action != false) {
                    // update this property as verified
                    this.Properties.raw = this.Properties.raw.filter(account => {
                        if (action == account.id) {
                            account.verified = 'true';
                            account.verification = 'false';
                        } return account;
                    });

                    this.Properties.filtered = this.Properties.raw;

                    // filter to the Verified section
                    this.PropertyFilterHandler (this.PropertyFilters[1]);
                }else {this.ConfirmDialog = false;}
            },

            VerifyProperty (id) {
                
            },

            SearchProperties () {
                var keyword = this.SearchText.toUpperCase();
                this.Properties.filtered = this.Properties.raw.filter(property => {
                    if (property.name.toUpperCase().indexOf(keyword) > -1 || property.address.toUpperCase().indexOf(keyword) > -1) return property;
                });                
            },

        }

    };
</script>

<template>

    <div class="admin-property _propscrl">
        <div class="dashpageheader">
            <div class="dash-header">
                <div class="actions">
                    <HtmlSelect :options="PropertyFilters" @selected="PropertyFilterHandler" />
                    <input type="text" placeholder="Search" v-model="SearchText" @keyup="SearchProperties()">
                </div>
                <div class="addition">
                    <div class="btn-primary" @click="UpdateRent()">
                        <img src="../../images/icons/addbtn-icon.svg" />
                        <p>Add Rent</p>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isRequesting" style="width: 50%;display: flex;margin: 0px auto;margin-top: 25%;">
            <Skeleton :type="'user-list'" :data="2" />
        </div>

        <ul class="dash-list" v-if="!isRequesting">
            <li v-for="property in Properties.filtered" :key="property.id" @click="PropertyClickHandler(property)">
                <div class="content">
                    <div class="image property">
                        <ReplaceImage :source="property.thumbnail" />
                    </div>
                    <div class="dlc-detail">
                        <div class="od-header">
                            <h3>{{ property.name }}</h3>
                            <span class="image" v-if="property.verified == 'true'"><img src="../../images/icons/nverified.svg" alt="Property Verified"></span>
                        </div>
                        <div class="od-footer">
                            <ul>
                                <li>
                                    <img src="../../images/icons/nmap.svg">
                                    <p>{{ property.address }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <ul class="dli-hover">
                    <li>
                        <img src="../../images/icons/nedit.svg">
                    </li>
                </ul>
            </li>
        </ul>

        <div v-if="Properties.filtered.length == 0 && !isRequesting" class="empty-result animated fadeInUp" style="position: unset;height: 50vh;">
            <img src="../../images/icons/empty-result.svg" />
            <p>You don't have much information on this request.</p>
            <p>You can filter properties.</p>
        </div>
    </div>

    <!-- Property detail and Confirm verification -->
    <PropModal v-if="ConfirmDialog == true" :from="'propert-verify'" :data="PropertyData" @modalclose="CloseConformModal" />
    <PropModal v-if="UpdateUnitRent == true" :from="'admin-update-rent'" :data="'null'" @modalclose="CloseUpdateUnitRent" />

</template>