<template>
    <div class="prop-form _propscrl" style="overflow: unset;" v-if="!isOTPRequestPage && !isSendingOTP">
        <div class="form-area" style="padding-bottom: 65px;">
            <form name="propform" id="propform-profile">
                <div class="pf--title">
                    <h3>Change Password</h3>
                    <p>Please check your email, we have sent you a verificaiton code for password reset request.</p>
                </div>

                <div class="pf--input">
                    <p>Old Password</p>
                    <input type="password" v-model="oldPassword" placeholder="Password" id="oldPassword" data-type="len" data-max="5" />
                </div>

                <div class="pf--input">
                    <PasswordInput @passwordchange="HandlePasswordInput" :direction="''" />
                </div>

                <div class="pf--input">
                    <p>OTP Code</p>
                    <input type="text" v-model="otpVerify" placeholder="6 Digit Code" id="otpVerify" data-type="len" data-max="6" />
                </div>
            </form>
            
            <span v-if="formError" class="__formerror" style="position: absolute;top: 10px;
background-color: red;color: white;">{{ infoText }}</span>
        </div>

        <div class="form-action" style="width: calc(100% - 9.5%);">
            <div class="form-area">
                <div class="btn-primary" ref="changepassword" @click="ChangePassword">Change Password</div>
            </div>
        </div>
    </div>

    <div class="prop-otppage" v-if="isOTPRequestPage && !isSendingOTP">
        <div class="info">
            <h3>OTP Verification Required!</h3>
            <p>You need to verify with the OTP verification code to reset your password. You will get 6 digit verification code in your email!</p>
            <span class="btn-primary" @click="SendOTPVerification">Send Verification</span>
            <p class="btn-sec" @click="SetVerificationSent">I have verification code</p>
        </div>
    </div>

    <div class="prop-otppage" v-if="isOTPRequestPage && isSendingOTP">
        <div class="info">
            <SkeletonVue :type="'user-list'" :data="2" />
        </div>
    </div>

</template>

<script>
    //@ JS Modules
    import Request from './../../../javascript/getRequest';
    import FormValidation from './../../../javascript/FormValidation';

    // component import
    import PasswordInput from './../../web-forms/PasswordInput.vue';
    import SkeletonVue from '../../blocks/Skeleton.vue';

    export default {
        name: 'ChangeParking',
        props: ['data'],
        emits: ['close'],
        components : {
            PasswordInput,
            SkeletonVue,
        },

        data () {
            return {
                oldPassword : '',
                newPassword : '',
                otpVerify: '',
                passwordStrength : 'red',

                isOTPRequestPage: true,
                isSendingOTP: false,

                formError : false,
                infoText : false,
            }
        },


        mounted () {
            

        },

        methods: {

            SetVerificationSent () {
                this.isSendingOTP = false;
                this.isOTPRequestPage = false; 
            },

            async SendOTPVerification () {
                this.isSendingOTP = true;

                let formData = new FormData();
                formData.append('user', this.data.id);
                formData.append('email', this.data.email);

                // send and receive data
                const ReturnData = await Request.postServer('admin/user/otp.php', formData);
                if (!ReturnData.error) {
                    this.SetVerificationSent ();
                }else {
                    alert ('Error sending OTP. Please try again later');
                }
                
            },

            HandlePasswordInput (password, strength) {
                this.newPassword = password;
                this.passwordStrength = strength;
            },

            async ChangePassword () {

                this.formError = false;
                let form = document.getElementById('propform-profile');
                let canGoAhead = FormValidation.Validate (form);

                if (canGoAhead == true && (this.passwordStrength == "green" || this.passwordStrength == "orange")) {

                    let formData = new FormData();
                    formData.append('old', this.oldPassword);
                    formData.append('new', this.newPassword);
                    formData.append('otp', this.otpVerify);

                    this.$refs.changepassword.innerHTML = 'Working...';

                    // send and receive data
                    const ReturnData = await Request.postServer('admin/user/change-password.php', formData);
                    if (!ReturnData.error) {
                        // return and close modal
                        this.$emit('close', true);
                        
                    }else {
                        this.formError = true;
                        this.infoText = 'Error! ' + ReturnData.response;
                    }

                    this.$refs.changepassword.innerHTML = "Change Password";
                }
            },
        }

    };


</script>