<template>
    <div class="gs--body">
        <h3>Archive Ticket?</h3>
        <p>Your ticket <strong>{{data.subject}}</strong> will be moved to the archive folder. This will close the ticket and move it to the archive.</p>
    </div>

    <div class="gs--action">
        <div class="btn-primary" @click="confirmModal" ref="confirm" style="width: 120px; display: block;float: left;margin-top: 10px;">Archive</div>
    </div>
</template>

<script>
    export default {
        name: 'ConfirmDeleteTicket',
        props: ['data'],
        emits: ['close'],
        methods : {
            confirmModal () {
                this.$refs.confirm.innerHTML = 'Working...';
                this.$emit('close', this.data);
            }
        },
        mounted () {
            console.log (this.data);
        }
    };
</script>