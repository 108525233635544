<template>
    <div class="internal--header ih-parking">
        <div class="summery" style="width: 30%">
            <h3><strong>{{totalOpen}}</strong> Open</h3>
            <h3><strong>{{totalReserved}}</strong> Reserved</h3>
        </div>

        <div class="actions" style="width: 70%">
            <div class="fil--area">
                <input type="text" placeholder="Search.." 
                        :style="{'background-image':'url('+require('../../images/icons/search.svg')+')'}"
                        @keyup="SearchParkingList" v-model="SearchKeyword" />
            </div>

            <div style="display: none;" class="btn-secondary" @click="AddNewParkingModal = true"><p>Add Parking</p></div>
            <div v-if="!EmptyPropertyParkings" style="display: none;" class="btn-secondary" @click="ParkingAssigned(false)"><p>Assign Units</p></div>
        </div>
    </div>

    <!-- Loading State Units Skeletion -->
    <div v-if="!PropertyParkings && !EmptyPropertyParkings" style="margin-top:55px">
        <Skeletion :data="3" />
    </div>

    <div v-if="PropertyListed" class="prop--table animated fadeInUp">
        <div class="prop-row row-header">
            <div class="column column35" style="cursor: pointer;" @click="SortByParkingHandler">Parking
                <span>
                    <img src="../../images/icons/sort-up.svg" v-if="SortByParking == true" />
                    <img src="../../images/icons/sort-down.svg" v-else-if="SortByParking == false" />
                    <img src="../../images/icons/sort.svg" v-else />                            
                </span>
            </div>
            <div class="column column25">Status</div>
            <div class="column column25" style="position:relative;cursor: pointer;" >Reserved For
                <span class="fbtn" @click="FilterParkingByUnit">
                    <img src="../../images/icons/sort-down.svg" />                            
                </span>

                <MultiUnitSelectionVue v-if="MultiUnitSelection"  :units="PropertyUnits" @lstboxclse="UnitsAreSelected" />
            </div>
            <div class="column column15">Actions</div>
        </div>

        <WalkThroughVue
                v-if="WthoughPrimary"
                :image="'edit-property.svg'"
                :title="'Reserved for units'"
                :content="'Assign parking to tenants/units to reserve them.'"
                :toPoint="'top'"
                :style="'top: -20px;right: 80px;'"
                @closewth="CloseWalkThrough()" />

        <ParkingLists v-for="(parking, index) in FilteredParkings" :key="parking.id"
                        :parking="parking" 
                        :count="index"
                        @hamclick="ActionMenuClicked"
                        @editparking="EditParking"
                        @assigntenant="ParkingAssigned"
                        @unassigntenant="ParkingUnAssigned"
                        @delete="DeleteParking" />
    </div>

    <!-- If no data on result -->
    <div v-if="EmptyPropertyParkings" class="empty-result animated fadeInUp">
        <img src="../../images/icons/empty-result.svg" />
        <p>You dont have much information on this request. <br />Please modify or add parkings.</p>

        <WalkThroughVue
                v-if="WthoughSecondary"
                :image="'edit-property.svg'"
                :title="'Add Parkings'"
                :content="'You can add parkings from here and assign to units.'"
                :toPoint="'nowhere'"
                :style="'top: -30px;right: 55px;'"
                @closewth="WthoughSecondary = false" />
    </div>

    <!-- pop ups --> 
    <RightModal v-if="AddNewParkingModal" :form="'add-parking'" :data="PropertyData" @modalclose="CloseModalBox" />
    <RightModal v-if="EditParkingModal" :form="'edit-parking'" :data="EditParkingData" @modalclose="CloseModalBox" />

    <RightModal v-if="AssignTenantModal" :form="'assign-tenant-parking'" :data="TenantAssignData" @modalclose="CloseAssignModalBox" />    
    <PropModal v-if="WantDeleteParking == true" :from="'confirm-delete-parking'" :data="DeleteParkingData" @modalclose="CloseConfrimDelete" />
    <PropModal v-if="WantUnassignedParking == true" :from="'confirm-unassign-parking'" :data="UnassignedParkingData" @modalclose="CloseConfrimUnassign" />

</template>

<script>

    //@ JS Modules
    import Document from '../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';
    import Request from './../../javascript/getRequest';
    import Storage from './../../javascript/Storage';

    // pinia storage
    import { useOwnerStore } from './../../stores/OwnerStore';

    import Skeletion from './../blocks/Skeleton.vue';

    // @import components
    import HtmlImage from './../blocks/HtmlImage.vue';
    import ParkingLists from './blocks/ParkingLists.vue';
    import MultiUnitSelectionVue from '../web-forms/MultiUnitSelection.vue';

    // walk through
    import WalkThroughVue from './../reusable/WalkThrough.vue';

    // Modal
    import RightModal from './../../components/modal/RightModal.vue';
    import PropModal from './../../components/modal/PropModal.vue';

    export default {
        name : 'Parking',
        props: ['property', 'initAdsgnParking'],
        emits: ['dischargeParking'],
        components : {
            Skeletion,

            HtmlImage,
            ParkingLists,
            MultiUnitSelectionVue,

            WalkThroughVue,

            RightModal,
            PropModal,
        },

        data () {
            return {
                EmptyPropertyParkings : false,
                PropertyListed : false,
                PropertyParkings : false,
                FilteredParkings : false,
                totalOpen : 0,
                totalReserved : 0,

                // add parking things
                AddNewParkingModal : false,
                PropertyData : this.property,

                // assign tenant settings
                TenantAssignData : false,
                AssignTenantModal : false,
                WantDeleteParking : false,
                DeleteParkingData : [],

                // unassig
                WantUnassignedParking: false,
                UnassignedParkingData:  null,

                // edit parking
                EditParkingModal : false,
                EditParkingData: null,

                // sorting
                SortByParking : null,
                SortByTenant : null,

                // search
                SearchKeyword : '',

                // property units
                MultiUnitSelection : false,
                PropertyUnits : false,

                // pinia storage
                OwnerStore: useOwnerStore (),

                // page walk through
                WthoughPrimary: false,
                WthoughSecondary: true,

            }
        },

        async mounted () {


            // site title and seo
            Document.DocumentHeadings ({title : ' Properties - Parking'});

            // walkthrough settings
            this.WthoughPrimary =  this.OwnerStore.walkthrough.property.parking;

            // now load all the owner parkings
            let getdata = {
                key : 'property',
                value : this.property.id
            }
            const Parkings = await DataCenter.ProPRequest('parkings-list', true, getdata);
            if (!Parkings) {
                this.EmptyPropertyParkings = true;
                this.PropertyListed = false;
            }else {
                this.PropertyListed = true;
                this.PropertyParkings = Parkings;
                
                // init parking
                this.initParking ();
            }

            // init assign and add parking
            if (this.initAdsgnParking == 'add') this.AddNewParkingModal = true;
            if (this.initAdsgnParking == 'assign') this.ParkingAssigned(false);
            
            // load all units
            this.loadSelectedPropertyUnits (this.property.id);

        },

        methods : {

            // close the walkthrough
            async CloseWalkThrough () {
                this.WthoughPrimary = false;
                this.OwnerStore.walkthrough.property.parking = false;

                // update to the server
                await DataCenter.ProPRequest('owner-walkghrough', false, {
                    key : 'wlkough',
                    value : 'parking'
                });
            },

            // load blank units on the selected properties
            async loadSelectedPropertyUnits (propertyid) {
                // load units data from server
                let getdata = {
                    key : 'property',
                    value : propertyid
                }
                
                const Units = await DataCenter.ProPRequest('property-units', false, getdata);                
                if (!Units) {}else {
                    this.PropertyUnits = Units;
                }            
            },

            // filter unit 
            UnitsAreSelected (units) {
                this.MultiUnitSelection = false;

                this.initParking ();

                if (units.length == 0) return;

                units = units.filter (unit => {
                    if (unit.active) return unit;
                });

                if (units.length == 0) {
                }else {
                    var NewFilter = [];
                    this.FilteredParkings.forEach (parking => {
                        if (parking.tenant == null || !parking.tenant) return;
                        if (parking.tenant.unit_number < 1) return;

                        units.forEach(unit => {
                            var keyword = unit.unit_number;
                            if (parking.unit.indexOf(keyword) > -1) {
                                NewFilter.push (parking);
                            }
                        });
                    });

                    this.FilteredParkings = NewFilter;
                }

            },

            // init/start parkings
            initParking () {
                // filter with current property selected
                this.FilteredParkings = this.PropertyParkings.filter (parking => {
                    parking.active = false;
                    if (parking.property == this.property.id) return parking;
                });

                // count total open and reserved
                this.totalOpen = 0; this.totalReserved = 0;
                this.FilteredParkings.forEach(parking => {                    
                    if (parking.status == "Open") this.totalOpen +=1;
                    else this.totalReserved += 1;
                });

                // update the list
            },
            
            // close add parking modal box
            CloseModalBox (parkings) {
                if (parkings) {
                    this.PropertyParkings = parkings;
                    this.FilteredParkings = false;

                    this.PropertyListed = true;
                    this.EmptyPropertyParkings = false;

                    Storage.storeCache('parkings-list', this.PropertyParkings);
                    this.initParking();
                }

                this.AddNewParkingModal = false;
                this.EditParkingModal = false;
                this.$emit('dischargeParking');
            },

            // action menu clicked
            ActionMenuClicked (parking) {
                this.PropertyParkings = this.PropertyParkings.filter (parkg => {
                    parkg.active = false;
                    if (parking.id == parkg.id) parkg.active = true;
                    return parkg;
                });
            },

            // parking assigned to tenant
            ParkingAssigned (parking) {

                //sending data
                this.TenantAssignData = {
                    property : this.property,
                    parkings : this.FilteredParkings,
                    selected : parking
                }

                // remove action
                if (parking) parking.active = false;

                // open modal
                this.AssignTenantModal = true;
            },

            EditParking (parking) {
                // remove action
                if (parking) parking.active = false;
                
                this.EditParkingData = parking;
                this.EditParkingModal = true;
            },

            DeleteParking (parking) {
                parking.active = false;
                this.WantDeleteParking = true;
                this.DeleteParkingData = parking;
            },

            ParkingUnAssigned (parking) {
                parking.active = false;
                this.WantUnassignedParking = true;
                this.UnassignedParkingData = parking;
            },

            async CloseConfrimDelete (parking) {
                if (parking) {
                    const ReturnData = await Request.postServer('property/parking/delete.php?id=' + parking.id);
                    if (!ReturnData.error) {
                        this.PropertyParkings = this.PropertyParkings.filter (prkg => {
                            if (prkg.id == parking.id) {}else return prkg;
                        });

                        Storage.storeCache('parkings-list', this.PropertyParkings);
                        this.initParking();
                    }
                }

                // close modal
                this.WantDeleteParking = false;
                
            },

            async CloseConfrimUnassign (parking) {
                if (parking) {
                    const ReturnData = await Request.postServer('property/parking/unassign.php?id=' + parking.id);
                    if (!ReturnData.error) {
                        this.PropertyParkings = ReturnData.response;
                        Storage.storeCache('parkings-list', this.PropertyParkings);

                        this.initParking();
                    }
                }

                // close modal
                this.WantUnassignedParking = false;
            },

            // assign tenant modal close
            CloseAssignModalBox (parkings) {
                if (parkings) {
                    this.PropertyParkings = parkings;
                    Storage.storeCache('parkings-list', this.PropertyParkings);                   
                }

                // filter one also
                this.initParking();

                this.AssignTenantModal = false;
                this.$emit('dischargeParking');
            },
            
            // sort by parking name
            SortByParkingHandler () {
                // toggle
                this.SortByParking = !this.SortByParking;
                this.FilteredParkings.sort ((a, b) => {
                    var c, d;
                    if (this.SortByParking) { c = a; d = b} else {d = a; c = b;}
                    a = c; b = d;

                    if (Number(a.name) > Number(b.name)) return 1;
                    if (Number(a.name) == Number(b.name)) return 0;
                    if (Number(a.name) < Number(b.name)) return -1;
                });
            },

            // filter parking by unit selection
            FilterParkingByUnit () {
                this.MultiUnitSelection = true;
            },
            
            // sort by tenant name
            SortByTenantHandler () {
                // toggle
                this.SortByTenant = !this.SortByTenant;
                this.FilteredParkings.sort ((a, b) => {
                    if (a.tenant == null || b.tenant == null) return;
                    var c, d;
                    if (this.SortByTenant) { c = a; d = b} else {d = a; c = b;}
                    a = c; b = d;


                    if (a.tenant.fullname > b.tenant.fullname) return 1;
                    if (a.tenant.fullname == b.tenant.fullname) return 0;
                    if (a.tenant.fullname < b.tenant.fullname) return -1;
                });
            },

            // search parking list
            SearchParkingList () {
                this.FilteredParkings = this.PropertyParkings.filter (parking => {
                    if (parking.tenant == null || parking.tenant == null) return;
                    if (parking.tenant.unit_number < 1) return;

        			var keyword = this.SearchKeyword.toUpperCase();
                    if (parking.tenant.fullname.toUpperCase().indexOf(keyword) > -1 || parking.tenant.unit_number.toUpperCase().indexOf(keyword) > -1) return parking;
                });

                if (this.SearchKeyword == '') {
                    this.initParking ();
                }
            }


        }
    };
</script>

<style scoped>
div.row-header span.fbtn {
    border: 1px solid #2a2a2a;
    background-color: #f3f3f3;
    padding: 4px;
    padding-top: 2px;
    border-radius: 3px;
    margin-left: 5px;
}
</style>