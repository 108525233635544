import DataCenter from './DataCenter';

// pinia
import { useOwnerStore } from './../stores/OwnerStore';

const UpdateAccountChanges = (Data, type) => {

    let n = Data.notification;
    let t = Data.tickets;

    let ElTickets = document.getElementById('TicketsChanges');
    let ElNotifications = document.getElementById('NotificationChanges');
    let PrevNotifications = parseInt(ElNotifications.innerHTML);
    let PrevTickets = parseInt(ElTickets.innerHTML);

    // tickets update
    ElTickets.innerHTML = t;
    if (t <= 0) ElTickets.style.display = 'none';
    else ElTickets.style.display = 'block';
    
    // notification update
    ElNotifications.innerHTML = n;
    if (n <= 0) ElNotifications.style.display = 'none';
    else ElNotifications.style.display = 'block';

    // has to refresh the tenant and owner dashboard
    if (n != PrevNotifications || t != PrevTickets) {
        if (type == 'owner') DataCenter.RemoveOwnerStorage ();
        else if (type == 'owner') DataCenter.RemoveTenantStorage ();
        else DataCenter.RemoveEmployeeStorage ();
    }

    // manage support things
    const OwnerStore = useOwnerStore ();
    OwnerStore.support = Data.support;
};

const UpdateAdminAccountChanges = (Data) => {
    let p = Data.properties;
    let a = Data.accounts;

    let ElAccounts = document.getElementById('AccountsChanges');
    let PrevAccounts = parseInt(ElAccounts.innerHTML);

    let ElProperties = document.getElementById('PropertiesChanges');
    let PrevProperties = parseInt(ElProperties.innerHTML);

    // accounts waitlist update
    ElAccounts.innerHTML = a;
    if (a <= 0) ElAccounts.style.display = 'none';
    else ElAccounts.style.display = 'block';
    
    // properties waitlist update
    ElProperties.innerHTML = p;
    if (p <= 0) ElProperties.style.display = 'none';
    else ElProperties.style.display = 'block';

    // has to refresh the storage
    if (a != PrevAccounts || p != PrevProperties) DataCenter.RemoveAdminStorage ();

};

const AccountChangesHandler = (type) => {
    setInterval ( async () => {
        let apiUri = type + '-changes';
        const RequestData = await DataCenter.ProPRequest(apiUri, false, false);
        
        // now update the dashboard
        if (type == 'admin') {
            UpdateAdminAccountChanges (RequestData);
        }else {
            UpdateAccountChanges (RequestData, type);
        }

    }, 120000);
};

export default {
    AccountChangesHandler,
}