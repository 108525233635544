<template>
    <div class="propstop-app">        
        <div class="admin-nav" :style="SidebarHamClick ? 'left: 0px !important;box-shadow: 0px 0px 10px rgb(0 0 0 / 33%) !important;' : ''">
            <div class="logo-content">
                <img src="@/images/company/logo-white.png" />
            </div>

            <ul class="navigation">
                <div v-if="navigationlist.length == 0" style="margin-left: 25px; opacity: 0.7;">
                    <Skeleton :type="'icon-menu'" :data="5" />
                </div>
                <AdminSideNav v-else v-for="list in navigationlist" :key="list.key" :current="list" @navchange="NavigationClicked" />
            </ul>
        </div>

        <div class="admin-content">
            <div class="content-header">
                <div class="ham-side-menu" @click="SidebarHamClick = true">
                    <img src="./../../images/icons/line-menu.svg" />
                </div>
                
                <ul>
                    <li><h3> {{CurrentPageName}} </h3></li>
                    <li>
                        <input type="text" name="search" 
                                :placeholder="'Search in '+ CurrentPageName +'...'"
                                :style="{'background-image':'url('+require('../../images/icons/search.svg')+')'}" />
                    </li>
                    <li>
                        <div v-if="CurrentAdminProfile" class="admin">
                            <div class="notification" style="display: none;">
                                <img src="@/images/icons/notification.svg" />
                                <span>2</span>
                            </div>

                            <div class="user" style="cursor: pointer;" @click="userProfileActive = true">
                                <h3>Hi, {{CurrentAdminProfile.fname}}</h3>
                                <div class="avatar">
                                    <UserImage :init="CurrentAdminProfile.fullname" :back="CurrentAdminProfile.back" :source="CurrentAdminProfile.avatar" style="border-radius:50%" />
                                </div>
                            </div>
                            <AdminProfilePopup v-if="userProfileActive" :user="CurrentAdminProfile" @profileclose="closeProfilepopup" />
                        </div>
                    </li>
                </ul>
            </div>

            <div class="page-content">
                <router-view
                    :userProfile="CurrentAdminProfile" :owners="propertyOwners" :key="userprofileloaded"></router-view>
            </div>
        </div>

    </div>

    <AccountChanges accountype='admin' />
    <RightModal v-if="UpdateAdminProfile" :form="'admin-profile-complete'" :data="AdminProfile" @modalclose="AdminIsVerified" />

</template>

<script>
    // import js library
    import DataCenter from './../../javascript/DataCenter';
    import { AdminNavigation } from '../../javascript/AppNavigation';

    // pinia
    import { useAdminStore } from './../../stores/AdminStore';

    // import components
    import Skeleton from './../blocks/Skeleton.vue';
    import AdminSideNav  from './../admin/AdminSideNav.vue';
    import UserImage from './../blocks/UserImage.vue';

    import AdminProfilePopup from './../owner/AdminProfilePopup.vue';

    import AccountChanges from './../reusable/PropStopAccountChanges.vue';
    import RightModal from '../modal/RightModal.vue';


    export default {
        name: 'AdminDashboard',
        components: {
            Skeleton,
            AdminSideNav,
            UserImage,

            AdminProfilePopup,
            AccountChanges,

            RightModal
        },
        
        data () {
            return {

                AdminStore: useAdminStore (),
                
                // Owner Dashboard Navigation List
                navigationlist : [],

                // page name
                CurrentPageName : 'Dashboard',

                // logged in admin profile
                UpdateAdminProfile : false,
                CurrentAdminProfile : false,
                propertyOwners: [],
                AdminProfile: {},
                userprofileloaded : false,

                userProfileActive: false,

                // responsive
                SidebarHamClick : false,
            }
        },

        async mounted () {

            const RequestData = await DataCenter.ProPRequest('admin-profile', false, false);
            if (!RequestData) {}{
                this.CurrentAdminProfile = RequestData.profile;
                this.propertyOwners = RequestData.owners;
                this.userprofileloaded = true;

                // update pinia
                this.AdminStore.ResetUser(RequestData.profile);
                this.AdminStore.ResetOwners(RequestData.owners);
                this.AdminStore.ResetProperties(RequestData.properties);
                this.AdminStore.ResetRoles(RequestData.roles);
                this.AdminProfile = RequestData.profile;

                if (RequestData.profile.verified == "false" ||RequestData.profile.verified == null) this.UpdateAdminProfile = true; 
            }

            // now update the navigation according to the user roles
            // and routes
            let userRoutes = this.AdminStore.user.roles;
            if (userRoutes == "all") {
                this.navigationlist = AdminNavigation;
            }else {
                let RoutesArray = userRoutes.split("/");
                RoutesArray.forEach(route => {
                    let toPushRoute = AdminNavigation.find(nav => nav.route == route);
                    this.navigationlist.push(toPushRoute);
                });
            }
        },

        methods: {
            // navigation list
            NavigationClicked (nav) {
                this.navigationlist = this.navigationlist.filter(list => {
                    list.active = false;
                
                    if (list.key == nav.key) {
                        list.active = true;
                    }

                    return list;
                });

                this.CurrentPageName = nav.name;

                // now route to the acitve one
                this.$router.push({name : nav.route});

                // remove sidebar menu
                this.SidebarHamClick = false;
            },

            closeProfilepopup () {
                this.userProfileActive = false;
            },

            AdminIsVerified (profile) {
                if (!profile) return;
                this.AdminProfile = profile;

                this.AdminStore.ResetUser(profile);

                // close update profile
                this.UpdateAdminProfile = false;
            },
        }

    };
</script>