<template>
    <div class="prop-form _propscrl" style="overflow: unset;">
        <div class="form-area" style="padding-bottom: 65px;">
            <form name="propform" id="propform-profile">
                <div class="pf--title">
                    <h3>Change Password</h3>
                    <p>Please provide your current password and new password.</p>
                </div>

                <div class="pf--input">
                    <p>Old Password</p>
                    <input type="password" v-model="oldPassword" placeholder="Password" id="oldPassword" data-type="len" data-max="5" />
                </div>

                <div class="pf--input">
                    <PasswordInput @passwordchange="HandlePasswordInput" :direction="''" />
                </div>
            </form>
            
            <span v-if="formError" class="__formerror" style="margin-top: -18px;">{{ infoText }}</span>
        </div>

        <div class="form-action" style="width: calc(100% - 9.5%);">
            <div class="form-area">
                <div class="btn-primary" ref="changepassword" @click="ChangePassword">Change Password</div>
            </div>
        </div>
    </div>
</template>

<script>
    //@ JS Modules
    import Request from './../../../javascript/getRequest';
    import FormValidation from './../../../javascript/FormValidation';

    // component import
    import PasswordInput from './../../web-forms/PasswordInput.vue';

    export default {
        name: 'ChangeParking',
        props: ['data'],
        emits: ['close'],
        components : {
            PasswordInput
        },

        data () {
            return {
                oldPassword : '',
                newPassword : '',
                passwordStrength : 'red',

                formError : false,
                infoText : false,
            }
        },

        methods: {
            HandlePasswordInput (password, strength) {
                this.newPassword = password;
                this.passwordStrength = strength;
            },

            async ChangePassword () {

                this.formError = false;
                let form = document.getElementById('propform-profile');
                let canGoAhead = FormValidation.Validate (form);

                if (canGoAhead == true && (this.passwordStrength == "green" || this.passwordStrength == "orange")) {
                    let formData = new FormData();
                    formData.append('old', this.oldPassword);
                    formData.append('new', this.newPassword);

                    this.$refs.changepassword.innerHTML = 'Working...';

                    let cpserver = '';
                    if (this.data == 'tenant') {
                        cpserver = 'tenant/user/';
                    }else if (this.data == 'owner') {
                        cpserver = 'owner/user/';
                    }else if (this.data == 'employee') {
                        cpserver = 'employee/user/';
                    }

                    // send and receive data
                    const ReturnData = await Request.postServer(cpserver + 'change-password.php', formData);
                    if (!ReturnData.error) {
                        // return and close modal
                        this.$emit('close', true);
                        
                    }else {
                        this.formError = true;
                        if (ReturnData.reason == "match") {
                            this.infoText = ReturnData.response;
                        }else {
                            this.infoText = "Something went wrong! Please try again later!"
                        }
                    }

                    this.$refs.changepassword.innerHTML = "Change Password";
                }
            },
        }

    };


</script>