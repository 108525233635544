<template>
<div class="profile-content _propscrl">
    <ul class="owner--profile">
      <li :class="[profileSlide['basic']]" @click="ProfileAccordinHandler ('basic')">
          <div class="op-header">
              <div class="image">
                  <img src="./../../images/profile/user.svg" />
              </div>

              <div class="op-content">
                  <h3>Basic Information</h3>
                  <p>Owner basic info, profiles, name & password settings</p>
                  <input type="file" ref="files" @change="handleFilesUploads" style="display:none;" />
              </div>

              <div class="op-hover">
                  <img data-name="hambasic" src="./../../images/icons/ham-menu.svg" />
                    <ul v-if="UpdateBasicInfo == true" class="action-menu amma-menu">
                        <li><img src="./../../images/icons/close.svg" @click="UpdateBasicInfo = false" /></li>
                        <li @click="filesclick"><img src="./../../images/icons/change-avatar.svg" /> Change Avatar</li>
                        <li @click="ChangePasswordHandler"><img src="./../../images/icons/change-password.svg" /> Change Password</li>
                        <li @click="UpdateProfileHandler"><img src="./../../images/icons/update-profile.svg" /> Update Profile</li>
                    </ul>
              </div>
          </div>

          <div class="op-content" v-if="profileSlide.basic">
              <ul class="opc--info">
                  <li>
                      <div class="asker">
                          <h3>Avatar</h3>
                      </div>
                      <div class="answerer">
                            <p>Last updated June 25, 2021</p>
                      </div>
                      <div class="extraer">
                          <UserImage :init="profile.fullname" :back="profile.back" :source="profile.avatar" />
                      </div>
                  </li>

                  <li>
                      <div class="asker">
                          <h3>Fullname</h3>
                      </div>
                      <div class="answerer">
                          <h4>{{profile.fullname}}</h4>
                      </div>
                  </li>

                  <li>
                      <div class="asker">
                          <h3>Business</h3>
                      </div>
                      <div class="answerer">
                          <h4>{{profile.business}}</h4>
                      </div>
                  </li>

                  <li>
                      <div class="asker">
                          <h3>Gender</h3>
                      </div>
                      <div class="answerer">
                          <h4>{{profile.gender}}</h4>
                      </div>
                  </li>

                  <li>
                      <div class="asker">
                          <h3>Birthday</h3>
                      </div>
                      <div class="answerer">
                          <h4>{{profile.birthday}}</h4>
                      </div>
                  </li>

                  <li>
                      <div class="asker">
                          <h3>Password</h3>
                      </div>
                      <div class="answerer">
                          <div>
                            <h4>**********</h4>
                            <span class="password-change" v-if="IsPasswordChanged">Your password is uddated!</span>
                            <p>{{PasswordChangeDate}}</p>
                          </div>
                      </div>
                  </li>
              </ul>
          </div>
      </li>

      <li :class="[profileSlide['contact']]" @click="ProfileAccordinHandler ('contact')">
          <div class="op-header">
              <div class="image">
                  <img src="./../../images/profile/mail.svg" />
              </div>

              <div class="op-content">
                  <h3>Contact Information</h3>
                  <p>Your contact numbers and primary email and other information</p>
              </div>

              <div class="op-hover" style="display: none;">
                  <img src="./../../images/icons/ham-menu.svg" />
              </div>
          </div>

          <div class="op-content" v-if="profileSlide.contact">
              <ul class="opc--info">
                  <li>
                      <div class="asker">
                          <h3>Email</h3>
                      </div>
                      <div class="answerer">
                          <div>
                            <h4>{{profile.email}}</h4>
                            <p>{{profile.secondary}}</p>
                          </div>
                      </div>
                  </li>

                  <li>
                      <div class="asker">
                          <h3>Phones</h3>
                      </div>
                      <div class="answerer">
                          <h4>{{FormatedMobileNumber}}</h4>
                      </div>
                  </li>

                  <li>
                      <div class="asker">
                          <h3>Address</h3>
                      </div>
                      <div class="answerer">
                          <h4>{{profile.address}}</h4>
                      </div>
                  </li>
              </ul>
          </div>
      </li>

      <li :class="[profileSlide['verification']]" @click="ProfileAccordinHandler ('verification')" style="display: none;">
          <div class="op-header">
              <div class="image">
                  <img src="./../../images/profile/security.svg" />
              </div>

              <div class="op-content">
                  <h3>2-Step Verification</h3>
                  <p>Status: On (Primary Mobile Number)</p>
              </div>

              <div class="op-hover">
                  <img src="./../../images/icons/ham-menu.svg" />
              </div>
          </div>

          <div class="op-content" v-if="profileSlide.verification">
              <ul class="opc--info">
                  <li>
                      <div class="asker">
                          <h3>Mobile Verification</h3>
                      </div>
                      <div class="answerer">
                          <h4>{{FormatedMobileNumber}}</h4>
                          <p>Primary Mobile Number</p>
                      </div>
                  </li>
              </ul>
          </div>

      </li>
  </ul>
</div>

    <!-- Change password modal -->
    <PropModal v-if="ChangePassword == true" :from="'change-password'" :data="'owner'" @modalclose="CloseChangePasswordModal" />

    <!-- Update profile -->
    <RightModal v-if="UpdateOwnerProfile == true" :form="'owner-profile-update'" @modalclose="CloseModalBox" :data="profile" />

</template>

<script>

    //@ JS Modules
    import Document from './../../javascript/Document';
    import Handler from './../../javascript/Handler';
    import Request from './../../javascript/getRequest';
    import Methods from './../../javascript/Handler';

    import Skeletion from './../../components/blocks/Skeleton.vue';
    import UserImage from './../../components/blocks/UserImage.vue';

    import PropModal from './../../components/modal/PropModal.vue';
    import RightModal from './../../components/modal/RightModal.vue';


  export default {
    name: 'Profile',
    props: ['profile', 'properties', 'employees', 'selected'],
    emits: ['updateproperties'],
    
    components: {
        Skeletion,
        UserImage,
        PropModal,
        RightModal,
    },

    data () {
      return {
        StockURL : Request.StockURL,
        profileSlide : {
          'basic' : 'active',
          'contact' : '',
          'verification' : ''
        },

        FormatedMobileNumber : false,

        // update ham menu
        UpdateBasicInfo : false,

        // functions
        ChangePassword : false,
        UpdateOwnerProfile : false,

        IsPasswordChanged : false,
        PasswordChangeDate : 'Last changed Mar 25, 2019',

        // profile picture
        UploadAttachment : false,
        AttachmentSchema : ['jpeg', 'jpg', 'png'],
        AttachmentFile: [],
        AttachmentError: false,
        hasFileAttached: false,
      }
    },

    async mounted () {
        // show back the properties selection from here
        var OwnPrty = document.getElementById('ownerprtities');
        if (OwnPrty != null || OwnPrty != undefined) {
            OwnPrty.style.visibility = 'visible';
            OwnPrty.style.opacity = '1';
        }

        // site title and seo
        Document.DocumentHeadings ({title : 'PropStop - Owner Profile'});

        // formated mobile number
        this.FormatedMobileNumber = this.FormatMobileNumber (this.profile.mobile);
        if (this.FormatedMobileNumber == null) this.FormatedMobileNumber = this.profile.mobile;

        console.log (this.profile);
    },

    methods : {
        // format mobile number
        FormatMobileNumber (phoneNumberString) {
            var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
            var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                var intlCode = (match[1] ? '+1 ' : '');
                return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
            }
            return null;
        },

        // accordin click handler
        ProfileAccordinHandler (accordin) {
            var target = event.target.getAttribute('data-name');
            if (target == "hambasic") {
                this.UpdateBasicInfo = true;
            }else this.makeTargetActive (accordin);
        },

        // active accordin
        makeTargetActive (accordin) {
            this.profileSlide.basic = '';
            this.profileSlide.contact = '';
            this.profileSlide.verification = '';

            this.profileSlide[accordin] = 'active';
        },

        // change password pop-up/handler
        ChangePasswordHandler () {
            this.UpdateBasicInfo = false;
            this.ChangePassword = true;
        },

        CloseChangePasswordModal (password) {
            this.ChangePassword = false;
            if (password) {
                this.IsPasswordChanged = true;
                this.PasswordChangeDate = "Today";

                // update notification
                Handler.PropNotification ('You just change your password. Please use changed password from next login.');
            }
        },

        // update profile close modal box
        CloseModalBox () {
            this.UpdateBasicInfo = false;
            this.UpdateOwnerProfile = false;
        },

        UpdateProfileHandler () {
            this.UpdateBasicInfo = false;
            this.UpdateOwnerProfile = true;
        },

        filesclick () {
            this.$refs.files.click();
            this.AttachmentError = false;
        },

        handleFilesUploads () {
            this.AttachmentFile = this.$refs.files.files[0];
            var AttachedFileInfo = Methods.AttachmentUploadHandler (this.AttachmentFile, this.AttachmentSchema, 2);
            
            // file is okay to upload
            if (AttachedFileInfo.matched && !AttachedFileInfo.largesize) {
                this.hasFileAttached = true;
            }else {
                this.AttachmentFile = [];
                this.hasFileAttached = false;
            }

            // now upload and update the avatar
            if (this.hasFileAttached) {
                this.UploadAndUpdateAvatar ();
            }else {
                alert ('Image not supported!');
            }

        },

        async UploadAndUpdateAvatar () {
            this.UpdateBasicInfo = false;
            
            let formData = new FormData();
            formData.append('user', this.profile.id);
            formData.append('document', this.AttachmentFile);

            // load data from server
            const ReturnData = await Request.postServer('owner/upload/avatar.php', formData);
            if (ReturnData.error == false) {
                var NewAvatar = ReturnData.response;
                this.profile.avatar = NewAvatar;
            }

        }

    }
    
  }
</script>

<style scoped>
  div.profile-content {width: 100%; height: 100%; overflow-y: scroll;}
  ul.action-menu {     width: 190px;
    left: unset;
    right: -56px;
    top: -24px;
    text-align: left;}
    ul.action-menu li img {    margin-right: 10px;
    width: 16px;
    height: 16px;
    float: left;
    object-fit: contain;
    margin-bottom: 5px;}
</style>