<template>
    <div class="emptyskeleton">
        <img src="../../../images/icons/empty-result.svg" class="psanim-1 fadeInUp">
        <p class="psanim-2 fadeInUp">You dont have much information on this request. <br />Please add new plan to see more features.</p>
        <div class="btn-primary psanim-3 fadeInUp" @click="AddNewPlan">
            <img src="./../../../images/icons/addbtn-icon.svg" />
            <p>Add New Plan</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FloorPlanEmpty',
        emits: ['addplan'],
        methods: {
            AddNewPlan () {
                this.$emit('addplan');
            }
        }    
    }
</script>

<style scoped>
    .btn-primary {margin-top: 5px !important;}
    .btn-primary p {    color: #fff;
    margin-top: 1px;}
</style>