<script>

    // @ is an alias to /src
    import Locale from './../../../javascript/Locale';
    import Request from './../../../javascript/getRequest';
    import Handler from './../../../javascript/Handler';
    import DataCenter from './../../../javascript/DataCenter';

    // pinia storage
    import { useOwnerStore } from './../../../stores/OwnerStore';

    // @import component
    import Skeletion from './../../../components/blocks/Skeleton.vue';
    import UserImage from './../../../components/blocks/UserImage.vue';
    import ReplaceImageVue from './../../../components/blocks/ReplaceImage.vue';
    import UnitSelection from './../../web-forms/UnitSelection.vue';
    import MultiUnitSelection from './../../web-forms/MultiUnitSelection.vue';

    // walk through
    import WalkThroughVue from './../../reusable/WalkThrough.vue';

    import RightModal from './../../modal/RightModal';

    // flicking
    import Flicking from "@egjs/vue3-flicking";
    import "@egjs/vue3-flicking/dist/flicking.css";

     export default {
        name: 'FloorPlanDetail',
        props: ['property', 'plan', 'units'],
        components: {
            Skeletion,
            UserImage,
            ReplaceImageVue,
            UnitSelection,
            MultiUnitSelection,
            WalkThroughVue,

            RightModal,
            Flicking,
        },

        data () {
            return {
                list: [0, 1, 2, 3, 4],

                TenantProperty : false,

                FloorName : Locale.FloorNumName(),

                tenantAgency : false,
                tenantProperty : false,
                tenantUnit : false,

                Amenities: Locale.UnitAmenities (),
                ChoosedAmenities: [],
                AmenitiesUpdated: false,

                FloorPlan: {},

                // view media files
                ViewMediaFile: false,
                ViewMediaData: [],

                // units
                SelUnit : '',
                SelUnitID : null,
                PropertyUnits : false,
                UnitsSingleSelect : false,
                SelectedUnit : null,

                // linked units
                LinkedUnits : [],

                // pinia storage
                OwnerStore: useOwnerStore (),

                // page walk through
                WthoughPrimary: false,
                WthoughSecondary: false,

            }
        },

        mounted () {

            // walkthrough settings
            this.WthoughPrimary =  this.OwnerStore.walkthrough.property.floorplan;

            // amenities
            var Amenities = this.plan.amenities.split(',');
            this.ChoosedAmenities = this.Amenities;
            this.ChoosedAmenities = this.ChoosedAmenities.filter(amenity => {
                for (var i = 0; i < Amenities.length; i++) {
                    if (Amenities[i] == amenity.id) return amenity;
                };
            });
            this.AmenitiesUpdated = true;

            // units already linked
            if (this.plan.units.length > 0) {
                this.plan.units.filter  (unit => {
                    this.units.forEach (aunit => {
                        if (unit.id == aunit.id) {
                            aunit.active = true;
                        }
                    })
                });
            }

        },

        methods: {

            ClosePrimaryThrough () {
                this.WthoughPrimary = false;
                this.WthoughSecondary = true;
            },

            // close the walkthrough
            async CloseWalkThrough () {
                this.WthoughPrimary = false;
                this.OwnerStore.walkthrough.property.floorplan = false;

                // update to the server
                await DataCenter.ProPRequest('owner-walkghrough', false, {
                    key : 'wlkough',
                    value : 'floorplan'
                });
            },
            
            // once unit is selected from single select
            UnitSelected (units) {
                var SelectedUnits = [];
                units.forEach (unit => {
                    if (unit.active) {
                        SelectedUnits.push (unit);
                    }
                });

                this.plan.units = SelectedUnits;

                // link current unit to this floorplan
                this.LinkThisUnitToFloorPlan (SelectedUnits);
                this.UnitsSingleSelect = false;                
            },

            LinkAllUnits () {

                var SelectedUnits = [];
                this.units.forEach (unit => {
                    SelectedUnits.push (unit);
                });

                this.plan.units = SelectedUnits;

                // link current unit to this floorplan
                this.LinkThisUnitToFloorPlan (SelectedUnits);

            },

            async LinkThisUnitToFloorPlan (units) {

                var UnitToLink = '';
                units.forEach (unit => {
                    UnitToLink += unit.id + ',';
                });

                // now upload the selected files to the server
                let formData = new FormData();
                formData.append('floorplan', this.plan.id);
                formData.append('units', UnitToLink);             

                // upload and load data from server
                const ReturnData = await Request.postServer('owner/property/floorplan/link.php', formData);

                // show notification is success
                if (ReturnData.error == false) {
                    Handler.PropNotification ('Selected units are successfully linked/unlinked to floorplan ' + this.plan.name);
                }

            },

            async UnlinkThisUnitToFloorPlan (unit) {

                // remove from the list
                this.plan.units = this.plan.units.filter (unt => {
                    if (unt.id == unit.id) { // do nothing
                    }else return unt;
                });

                // now unlink from database too
                let formData = new FormData();
                formData.append('unit', unit.id);             

                // upload and load data from server
                const ReturnData = await Request.postServer('owner/property/floorplan/unlink.php', formData);

                // show notification is success
                if (ReturnData.error == false) {
                    Handler.PropNotification ('Unit: ' + unit.unit_name + ' successfully removed from the floorplan ' + this.plan.name);
                }


            },

            // handle view media files
            ViewMediaFileHandler (image) {
                this.ViewMediaData = {
                    property: this.property,
                    gallery : this.plan.gallery,
                    current : image,
                    from : 'floorplan',
                    floorplan: this.plan.id
                };

                // start viewing the selected image
                this.ViewMediaFile = true;

            },

            // close the media files view modal
            CloseViewModal (update) {
                
                if (update) {
                    // add all to gallery
                    this.plan.gallery = update.Gallery;

                    // check if set as default
                    if (update.isDefault) {
                        this.$emit('setdefault', update.DefaultUrl);
                    }

                    Handler.PropNotification ('Successfully updated selected media!');
                }

                this.ViewMediaFile = false;

            },
        }
    };

</script>

<template>
    <WalkThroughVue
        v-if="WthoughPrimary"
        :image="'edit-property.svg'"
        :title="'Update Floorplan'"
        :content="'You can always edit floorplan, upload images to the floorplan etc.'"
        :style="'top: 70px;right: 40px;'"
        @closewth="ClosePrimaryThrough()" />
        
    <div class="unit-info">
        <div class="unit-gallery">
            <Flicking :options="{ align: 'prev', bound: true }">
                <div class="flicking-unit-image" v-for="gallery in plan.gallery" :key="gallery.blobid" @click="ViewMediaFileHandler (gallery)">
                    <img :src="gallery.url" />
                </div>
            </Flicking>
        </div>

        <div class="ui-ot-dtls">

            <div class="uodtls" style="width: 320px;">

                <div class="li">
                    <h4>Plan Name</h4>
                    <h3>{{plan.name}}</h3>
                </div>

                <div class="li">
                    <h4>Floor Type</h4>
                    <h3>{{plan.floortype}}</h3>
                </div>

                <div class="li">
                    <h4>Bed Rooms</h4>
                    <h3>{{plan.bedrooms}} Bd</h3>
                </div>

                <div class="li">
                    <h4>Bath Rooms</h4>
                    <h3>{{plan.bathrooms}}</h3>
                </div>

                <div class="li">
                    <h4>Square Feet</h4>
                    <h3>{{plan.squarefeet}} sq. ft.</h3>
                </div>

            </div>

            <div class="uouiam">

                <div class="li">
                    <h4>Floorplan Description</h4>
                    <h3 class="desc">{{plan.description}}</h3>
                </div>

                <div class="li">
                    <h4>Amenities</h4>                        
                    <ul class="unit-amenities" v-if="AmenitiesUpdated">
                        <li v-for="amenity in ChoosedAmenities" :key="amenity">
                            <img :src="require(`../../../images/amenities/${amenity.icon}`)" />
                            <p>{{amenity.name}}</p>
                        </li>
                    </ul>
                </div>

                <div class="li">
                    <h4>Blue Print</h4>
                    <div class="blueprint">
                        <ReplaceImageVue :source="plan.planurl" />
                    </div>
                </div>

                <div class="li" style="position: relative;">
                    <h4>Linked to Units <span @click="UnitsSingleSelect = true;"><img src="./../../../images/icons/plus.svg" /></span></h4>
                    <h3 style="color: #449adf;font-weight: 500;font-size: 11pt;cursor: pointer;" @click="LinkAllUnits">Link all units?</h3>

                    <WalkThroughVue
                        v-if="WthoughSecondary"
                        :image="'edit-property.svg'"
                        :title="'Link Units'"
                        :content="'Link units to the floorplan, you can update from units page too.'"
                        :toPoint="'bottom'"
                        :style="'top: -280px;left: 5px;'"
                        @closewth="CloseWalkThrough()" />

                    <MultiUnitSelection v-if="UnitsSingleSelect == true" :units="units" @lstboxclse="UnitSelected"/>

                    <ul class="link-units">
                        <li v-for="unit in plan.units" :key="unit" @click="UnlinkThisUnitToFloorPlan (unit)">
                            <div class="image">
                                <ReplaceImageVue :source="''" />
                            </div>
                            <div class="content">
                                <h3>{{unit.unit_name}}</h3>
                                <p v-if="unit.status == 'rented'">{{unit.status_date}}</p>
                                <p v-else style="color:black">Unit not rented yet.</p>
                            </div>

                            <div class="button">
                                <span>Unlink</span>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </div>

    <RightModal v-if="ViewMediaFile" :form="'view-document'" :data="ViewMediaData" @modalclose="CloseViewModal" />

</template>