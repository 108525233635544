<template>
    
    <!-- Header -->
    <WebNav />
    <!-- Header -->

    <div class="propstop-login" :style="{'background-image':'url('+require('../../images/website/login-bg.jpg')+')'}">
        <div class="login-box animated fadeInUp" :class="[currentPage]">            
            <!-- Login Page -->
            <Login v-if="currentPage == 'login'" @login="showSignupScreen" @forgot="showForgotScreen" :user="isAccountCreated" />

            <!-- Signup Page -->
            <Signup v-if="currentPage == 'signup'" @signup="showLoginScreen" />

            <!-- Forgot Password/Change password page -->
            <Forgot v-if="currentPage == 'forgot'" />

            <!-- Signup Graphics -->
            <div v-if="currentPage == 'signup'" class="illustration animated fadeInLeft">
                <img src="../../images/website/signup-page.svg" alt="Signup" title="Signup" />
            </div>
        </div>
    </div>
</template>

<script>
    
    //@import component
    import WebNav from './../../components/website/WebNav.vue';

    import Login from './../../components/auth/Login.vue';
    import Signup from './../../components/auth/Signup.vue';
    import Forgot from './../../components/auth/Forgot.vue';

    export default {
        name : 'Auth',

        components : {
             WebNav,
            Login,
            Signup,
            Forgot,
        },

        data () {
            return {
                currentPage : 'login',
                isAccountCreated : false,
            }
        },

        methods : {
            showLoginScreen (isUser) {
                this.isAccountCreated = isUser;
                this.currentPage = "login";
            },

            showSignupScreen () {
                this.currentPage = "signup";
            },

            showForgotScreen () {
                this.currentPage = "forgot";
            }
        }
    }
</script>

<style scoped>
    /* Header and Navigation */
header {
width: 100%;
height: 75px;
position: fixed;
z-index: 9999;
color: #fff;
top: 0px;
border-bottom: 1px solid #cce2f7;
background-color: #fff;
}

.logopart {
float: left;
width: 190px;
height: 65px;
overflow: hidden;
}

.logopart img {    width: 150px;
margin-top: 15px;}

ul.nav {width: auto;
margin: 0px auto;
height: 50px;
position: relative;
margin-top: 12px;
float: right;}

ul.nav > li {    width: auto;
height: auto;
float: left;
position: relative;}

ul.nav > li > a {
display: block;
width: auto;
height: auto;
padding: 16px 25px;
color: #000;
font-size: 11pt;
letter-spacing: 0.35px;
font-weight: 400;
text-decoration: none;
}

ul.nav > li:last-child > a {text-align: center;
float: left;
margin: 0 10px;
padding: 13px 20px;
letter-spacing: 0.15px;
font-size: 11pt;
font-weight: 500;
color: #fff;
margin-top: 3px;
border-radius: 35px;}
</style>