<template>
    <div class="confirm-modal animated fadeInBottom">
        <div class="gs--header">
            <img src="./../../images/icons/close.svg" @click="closeDialog ()" />
        </div>

        <div class="gs--body">
            <h3>Delete '{{data.fullname}}'?</h3>
            <p>Your employee and employee's information will be all archive. Deletion include all employee history.</p>
        </div>

        <div class="gs--action">
            <div class="btn-primary" @click="confirmModal" ref="confirm">Delete</div>
        </div>

    </div>
    <div class="overlay"></div>
</template>

<script>
    export default {
        name: 'ConfirmModal',
        props: ['data'],
        emits: ['modalclose', 'delemployee'],

        methods: {
            // close confirm dialog
            closeDialog () {
                this.$emit('modalclose');
            },

            // confirm modal
            confirmModal () {
                this.$refs.confirm.innerHTML = "Working..";
                this.$emit('delemployee', this.data);
            }
        }
    };
</script>