<template>
      <section class="seprator">
            <div class="wrapper">
                  <div class="sep"></div>
            </div>
      </section>
</template>

<script>

    export default {
        name : 'Seprator',
    };

</script>

<style>
/** seprator */
section.seprator {padding: 0;}
section.seprator div.wrapper {padding: 50px 0;}
    section.seprator div.sep {width: 980px;margin: 0px auto;}
</style>