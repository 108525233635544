<template>

    <div class="loading-state" v-if="PropertyLoadingState">
        <Skeleton :data="3" />
    </div>

    <div class="prop-proprty-detail _propscrl" v-if="!PropertyLoadingState">
        <div class="proprty-gallery">
            <div class="main-image">
                <div class="image">
                    <ReplaceImageVue :source="MainImage" />
                </div>

                <ul class="gal-summery">
                    <li>
                        <div class="image">
                            <img src="./../../images/tenrty/photo.svg" />
                        </div>
                        <p>{{TotalPropertyImages}} Photos</p>
                    </li>

                    <li>
                        <div class="image">
                            <img src="./../../images/tenrty/marker.svg" />
                        </div>
                        <p>Map</p>
                    </li>

                    <li>
                        <div class="image">
                            <img src="./../../images/tenrty/share.svg" />
                        </div>
                    </li>

                    <li>
                        <div class="image">
                            <img src="./../../images/tenrty/favourite.svg" />
                        </div>
                    </li>
                </ul>
            </div>

            <div class="mi-images">
                <ul>
                    <li v-for="(image, index) in ShowCaseImage" :key="image">
                        <div class="image">
                            <ReplaceImageVue :source="image.url" />
                        </div>
                        <span v-if="TotalPropertyImages > 4 && index == 2">{{TotalPropertyImages - 4}}+</span>
                    </li>
                </ul>
            </div>

        </div>

        <div class="proprty-information">
            <div class="content">

                <div class="pi-c-info">
                    <h1>{{Property.name}}</h1>
                    <p>{{Property.address}}</p>
                </div>

                <div class="proprty-section">
                    <div class="header">
                        <h3>Property Detail</h3>
                    </div>

                    <div class="content">
                        <p>{{Property.description}}</p>
                    </div>
                </div>

                <div class="proprty-section">
                    <div class="header">
                        <h3>Amenities</h3>
                    </div>

                    <div class="content">
                        <ul class="unit-amenities">
                            <li v-for="amenity in PropertyAmenities" :key="amenity">
                                <img :src="require(`../../images/amenities/${amenity.icon}`)" />
                                <p>{{amenity.name}}</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="proprty-section">
                    <div class="header">
                        <h3>Property Floor Plans</h3>
                    </div>

                    <div class="content">
                        <div class="floor--plans">
                            <ul>
                                <li v-for="plan in PropertyPlans" :key="plan">
                                    <div class="fps-content">
                                        <h3>{{plan.name}}</h3>
                                        <ul class="plan-desc">
                                            <li>{{plan.bedrooms}} Beds</li>
                                            <li>{{plan.bathrooms}} Batsh</li>
                                            <li>{{plan.squarefeet}} sq. ft</li>
                                        </ul>
                                        <p>{{plan.description}}</p>
                                    </div>

                                    <div class="fps-image">
                                        <div class="blueprint">
                                            <img :src="plan.planurl" />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="proprty-section">
                    <div class="header">
                        <h3>Property Map</h3>
                    </div>

                    <div class="content">
                        <div class="map" id="GeoLocation"></div>
                    </div>
                </div>

            </div>

            <div class="summery">

                <div class="pi-s-agency">
                    <div class="header">
                        <h3>Agency</h3>
                    </div>

                    <div class="agency-profile">
                        <div class="image">
                            <UserImage :init="Agency.business" :source="''" />
                        </div>

                        <div class="content">
                            <h3>{{Agency.business}}</h3>
                            <p>{{Agency.email}} . {{Agency.phone}}</p>
                        </div>
                    </div>

                    <div class="s-agcy-contact">
                        <div class="btn-primary" @click="GetInTouch">
                            <p>Get In Touch</p>
                        </div>

                        <div class="btn-secondary">
                            <p>Send Message</p>
                        </div>
                    </div>

                </div>

                <div class="pi-s-section">
                    <div class="header">
                        <h3>Unique Features</h3>
                    </div>

                    <div class="content">
                        <ul class="feaices">
                            <li v-for="feature in PropertyFeatures" :key="feature">
                                <p>{{feature.title}}</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="pi-s-section">
                    <div class="header">
                        <h3>Propety Services</h3>
                    </div>

                    <div class="content">
                        <ul class="feaices">
                            <li v-for="service in PropertyServices" :key="service">
                                <p>{{service.title}}</p>
                            </li>
                        </ul>
                    </div>
                </div>                

            </div>
        </div>
    </div>

    <RightModalVue v-if="GetInTouchModal" :form="'get-in-touch'" :data="this.selected" @modalclose="CloseModalBox" />

</template>

<script>
    // @ is an alias to /src
    import Document from '../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';
    import Locale from './../../javascript/Locale';

    import Skeleton from './../../components/blocks/Skeleton.vue';

    import UserImage from './../../components/blocks/UserImage.vue';
    import ReplaceImageVue from './../../components/blocks/ReplaceImage.vue';

    import RightModalVue from '../../components/modal/RightModal.vue';

    export default {
        name: 'PropStopPropertyDetail',
        // props: ['tenantProfile'],
        props: ['profile', 'properties', 'selected', 'hasNoProperty'],
        components: {
            Skeleton,

            UserImage,
            ReplaceImageVue,

            RightModalVue,
        },

        data () {
            return {
                PropertyID : false,
                PropertyLoadingState: true,

                // property info
                Property : {},
                Agency: {},
                PropertyServices: [],
                PropertyFeatures: [],

                PropertyGallery : [],
                MainImage : '',
                ShowCaseImage : [],
                TotalPropertyImages : 0,

                PropertyAmenities: [],

                // floorplans
                Amenities: Locale.UnitAmenities (),
                PropertyPlans: [],

                GetInTouchModal: false,
            }
        },

        async mounted () {
            this.PropertyID = this.$route.params.propertyid;
            
            // now load all the details of current property
            let getdata = {
                key : 'property',
                value : this.PropertyID
            };

            const RequestData = await DataCenter.ProPRequest('tenant-property-detail', false, getdata);
            if (RequestData) {
                this.CreatePageInfo (RequestData);

                // site title and seo
                Document.DocumentHeadings ({title : this.Property.name});

                // start the map
                setTimeout (() => {
                    this.initializeMap ();
                }, 500)
            }
        },

        methods: {

            GetInTouch () {
                this.GetInTouchModal = true;

            },

            CloseModalBox () {
                this.GetInTouchModal = false;
            },

            CreatePageInfo (PropInfo) {
                // property gallery
                this.PropertyGallery = PropInfo.property.gallery;
                this.TotalPropertyImages = this.PropertyGallery.length;
                
                if (this.TotalPropertyImages > 1) {
                    this.PropertyGallery.forEach((gallery, index) => {
                        if (index == 0) {
                            this.MainImage = gallery.url;
                        }else if (index == 1 || index == 2 || index == 3) {
                            this.ShowCaseImage.push (gallery);
                        }
                    });
                }

                // Property information
                this.Property = PropInfo.property;
                this.PropertyServices = this.Property.services;
                this.PropertyFeatures = this.Property.features;

                // Floor plans
                this.PropertyPlans = PropInfo.floorplans;

                // property floorplans amenities
                let AllAmenities = '';
                this.PropertyPlans.forEach (plan => {
                    AllAmenities += ',' + plan.amenities;
                });
                var Amenities = AllAmenities.split(',');
                this.ChoosedAmenities = this.Amenities;
                this.PropertyAmenities = this.Amenities.filter(amenity => {
                    for (var i = 0; i < Amenities.length; i++) {
                        if (Amenities[i] == amenity.id) return amenity;
                    };
                });

                // agency
                this.Agency = PropInfo.owner;

                // finally load the page
                this.PropertyLoadingState = false;

            },

            // google map init
            initializeMap () {

                // property lat and long
                var prplocation = this.Property.location;
                
                var icon = 'https://dev.propstop.com/fetch-stock/marker.png';
			
                var locations = [{lat : prplocation.latitude, lng : prplocation.longitude}];
                
                // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
                var mapOptions = {
                    // How zoomed in you want the map to start at (always required)
                    zoom: 16,

                    // The latitude and longitude to center the map (always required)
                    center: new google.maps.LatLng(prplocation.latitude, prplocation.longitude),

                    // remove buttons and etc
                    //disableDefaultUI: true,
                };
        
                // Get the HTML DOM element that will contain your map 
                // We are using a div with id="map" seen below in the <body>
                var mapElement = document.getElementById('GeoLocation');
        
                // Create the Google Map using our element and options defined above
                var map = new google.maps.Map(mapElement, mapOptions);
                        
                // Let's also add a marker while we're at it
                var markers = locations.map(function(location, i) {
                    return new google.maps.Marker({
                        position: location,
                        icon: icon,
                        animation:google.maps.Animation.DROP
                    });
                });

                // Add a marker clusterer to manage the markers.
			    // var markerCluster = new MarkerClusterer(map, markers,
				// 	{imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
            },
            
        },

    };

</script>