<template>

    <div class="internal--header">
        <div class="summery fp-smry">
            <ul class="backtounitlist">
                <li><img src="../../../images/employee-detail/arrow-left.svg" @click="BackToList"> <p>Back</p></li>
            </ul>           
        </div>

        <div class="actions fp-atns" style="display: none;">
            <div class="btn-secondary" @click="AddTransactiontoUnit"><p>Add Transaction</p></div>
        </div>
    </div>

    <div class="prop--table">
        <div class="prop-row row-header" style="padding: 10px 0 !important;">
            <div class="column column20"><p>Date</p></div>
            <div class="column column35"><p>Remarks</p></div>
            <div class="column column15"><p>Billing</p></div>
            <div class="column column15"><p>Payment</p></div>
            <div class="column column15"><p>Balance</p></div>
        </div>

        <div v-if="FinanceLoadingState == true" style="padding-top: 95px;">
            <Skeletion :data="2" />
        </div>

        <div class="prop-row odd" v-else v-for="(trans, index) in TransactionsList" :key="trans"  :class="index % 2 ? 'odd' : 'even'" >
            <div class="column column20">
                <div class="fin-transaction">
                    <p>{{trans.created}}</p>
                </div>
            </div>

            <div class="column column35">
                <div class="fin-transaction" style="display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;">
                    <p>{{trans.remarks}}</p>
                    <p v-if="trans.isRent" style="color: #F44336;">Due: {{ trans.due }}</p>
                </div>
            </div>

            <div class="column column15">
                <div class="fin-paymethod">
                    <p v-if="trans.billing != '0.0'">{{trans.billing.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        })}}</p>
                </div>
            </div>

            <div class="column column15">
                <div class="fin-paymethod">
                    <p v-if="trans.payment != '0.0'">{{trans.payment.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        })}}</p>
                </div>
            </div>

            <div class="column column15" style="display: flex;flex-direction: row;justify-content: flex-start;align-items: center;">
                <div class="fin-paymethod">
                    <p>{{trans.balance.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        })}}</p>
                    <button v-if="trans.edit == true" class="btn-primary" @click="EditThisTransaction (trans)">Edit</button>
                </div>
            </div>

        </div> 


    </div>

</template>

<script>

    import DataCenter from './../../../javascript/DataCenter';
    import Skeletion from './../../../components/blocks/Skeleton.vue';

    // pinia
    import { useOwnerStore } from './../../../stores/OwnerStore';

    export default {
        name: 'UnitTransactions',
        props: ['unit', 'property'],
        emits: ['back', 'addpayment', 'addtrans', 'edittrans'],
        components: {
            Skeletion,
        },

        data() {
            return {
                TransactionsList : [],
                CurrentProperty: {},
                CurrentTenant: {},
                CurrentUnit: {},
                FinanceLoadingState : true,

                OwnerStore: useOwnerStore (),

                TransData: {
                    unit : {
                        property: this.unit.unit.property,
                        unit: this.unit.unit.unit,
                    },
                    tenant: {
                        tid: this.unit.tenant.tid,
                        fullname: this.unit.tenant.fullname,
                        unit_name: this.unit.unit.unit_name,
                    },
                }
            }
        },

        async mounted () {

            var property, unit, tenant;
            property = this.unit.unit.property;
            unit = this.unit.unit.unit;
            // tenant = this.unit.tenant.id;

            // now load current unit/tenant transactions
            // load all the transaction of this property
            let getdata = {
                key : 'unit',
                value : unit
            }
            
            const ReturnData = await DataCenter.ProPRequest('unit-transaction-list', false, getdata);
            this.FinanceLoadingState = false;

            if (!ReturnData) {
                this.TransactionsList = [];
            }else {
                this.TransactionsList = ReturnData.transactions;
                this.CurrentProperty = ReturnData.property;
                this.CurrentUnit = ReturnData.unit;
                this.CurrentTenant = ReturnData.unit.tenant;

                // update the store too
                this.OwnerStore.financepaymentdata = {
                    type: 'choose',
                    isMainPage: true,
                    tenant: true,
                    property: true,
                    unit: true,
                    whichProperty: this.CurrentProperty,
                    whichUnit: this.CurrentUnit,
                    whichTenant: this.CurrentTenant
                }
            }
            
        },

        methods: {

            // edit the selected transaction for edit.
            EditThisTransaction (trans) {
                var EditTrans = {
                    type: trans.trans_type,
                    isMainPage: false,
                    trans: trans,
                    EditMode: true,
                };
                this.$emit('edittrans', EditTrans);
            },

            AddPaymentoUnit () {
                this.$emit('addpayment', this.TransData);
            },

            AddTransactiontoUnit () {
                var data = {
                    tenant: true,
                    property: true,
                    unit: true,
                    whichProperty: this.CurrentProperty,
                    whichUnit: this.CurrentUnit,
                    whichTenant: this.CurrentTenant
                };
                
                this.$emit('addtrans', data);
            },

            BackToList () {
                this.$emit('back');
            },


            
        },
}
</script>

<style scoped>
 .btn-secondary {
    float: right;
    margin-right: 15px;
}
div.prop--table div.prop-row {
    padding: 2px !important;
    margin-bottom: 0px !important;
}
div.internal--header {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #E5E5E5;
    overflow: hidden;
    margin-bottom: 20px;
}

div.internal--header div.summery {
    width: 50%;
    float: left;
}
div.internal--header div.actions {
    width: 50%;
    float: right;
}
</style>