<template>
    <div v-if="agreements == true || agreements.length <= 0" class="emptyskeleton">
        <img src="./../../../images/icons/agreement.svg" class="psanim-1 fadeInUp">
        <p class="psanim-2 fadeInUp">Seems you dont have added agreement with {{ profile.fullname }}. <br>Please update your tenant agreement.</p>
        <div class="btn-secondary psanim-3 fadeInUp" @click="AddAgreement">
            <p>Add Agreement</p>
        </div>
    </div>

    <div v-if="!agreements" style="margin-top:45px">
        <Skeleton :type="'large-list'" :data="2" />
    </div>

    <ul class="attachment-files">
        <li v-for="contract in agreements" :key="contract.contractid">
            <div class="atch-icon">
                <img v-if="contract.extension == 'doc' || contract.extension == 'docx'" src="./../../../images/attachment/atch-docx.svg" />

                <img v-else-if="contract.extension == 'xls' || contract.extension == 'xlsx'" src="./../../../images/attachment/atch-xlsx.svg" />

                <img v-else-if="contract.extension == 'ppt' || contract.extension == 'pptx'" src="./../../../images/attachment/atch-pptx.svg" />

                <img v-else-if="contract.extension == 'jpeg' || contract.extension == 'jpg' || contract.extension == 'png'" src="./../../../images/attachment/atch-image.svg" />

                <img v-else-if="contract.extension == 'pdf'" src="./../../../images/attachment/atch-pdf.svg" />

                <img v-else src="./../../../images/attachment/atch-others.svg" />
            </div>
            
            <div class="atch-content">
                <h3>{{contract.title}}</h3>
                <ul class="ac-info">
                    <li><p>{{contract.created}}</p></li>
                    <li><p>{{contract.size}} kb</p></li>
                </ul>
            </div>
            
            <div class="atch-actions">
                <span @click="EditAgreement(contract)"><img src="./../../../images/icons/edit.svg" /></span>
                <span>
                    <a :href="contract.url" target="_blank">
                        <img src="./../../../images/icons/download.svg" />
                    </a>
                </span>
            </div>
        </li>
    </ul>

</template>

<script>

    import Skeleton from './../../../components/blocks/Skeleton.vue';

    export default {
        name: 'Agreement',
        props: ['profile', 'agreements'],
        emits: ['addgrement', 'editgrement'],
        components: {
            Skeleton,
        },

        data () {
            return {
                TenantAgreements : this.agreements,
            }
        },

        mounted () {
            console.log (this.agreements);
        },

        methods: {
            AddAgreement () {
                this.$emit('addgrement');
            },

            EditAgreement (contract) {
                this.$emit('editgrement', contract);
            },
        }
    };
</script>