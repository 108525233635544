<template>
    <div class="property-list animatedss fadeInUp" :style="distyle">
        <ul class="_propscrl">            
            <li class="transAe" v-for="property in properties" :key="property.id" @click="propertySel(property)">
                <div class="image">
                    <ReplaceImage :source="''" />
                </div>

                <div class="content">
                    <h3>{{ property.propname }}</h3>
                    <p>{{ property.unitname }}</p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    import ReplaceImage from './ReplaceImage.vue';

    export default {
        name: 'TenantPropertySelect',
        props: ['distyle', 'properties'],
        components: {
            ReplaceImage,
        },

        mounted () {
            console.log (this.properties);
        },

        methods : {
            propertySel (property) {
                this.$emit('prosel', property);
            },

            closeList () {
                this.$emit('proboxclse');
            }
        }
    };
</script>