<template>
    <div class="prop-form _propscrl">        
        <div class="form-area" style="padding-bottom: 65px;">
            <form name="propform" id="propform-document" style="display: block;">
                <div class="pf--title">
                    <h3>Attachment Details</h3>
                    <p>You can view and update image here!</p>
                </div>

                <div class="-vd-image-view">
                    <img :src="Current.url">
                </div>
                
                <div class="pf--input">
                    <p>Image Title</p>
                    <input type="text" placeholder="Image Title" v-model="Title" id="Title" data-type="len" data-max="3" :disabled="DeleteImage" />
                </div>

                <div class="pf--input">
                    <p>Description</p>
                    <textarea placeholder="Image Description" style="height: 75px" v-model="Description" id="Description" data-type="len" data-max="0" :disabled="DeleteImage"></textarea>
                </div>

                <div class="pf--input" v-if="ViewerType == 'gallery'">
                    <div class="pretty p-switch p-fill" style="border: none;">
                        <input type="checkbox" v-model="SetPropertyDefault">
                        <div class="state p-success">
                            <label>Mark default Property Image</label>
                        </div>
                    </div>
                </div>

                <div class="pf--input">
                    <div class="pretty p-switch p-fill" style="border: none;">
                        <input type="checkbox" @click="WantToDeleteImage">
                        <div class="state p-success">
                            <label style="color: red;">Delete This Image</label>
                        </div>
                    </div>
                </div>

            </form>
            
            <span v-if="formError" class="__formerror">{{ infoText }}</span>
        </div>

        <div class="form-action">
            <div class="form-area">
                <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="UpdateDocument" style="margin-top: 10px;float: left;width: 100px;">
                    <p v-if="!Requesting">Update</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    //@ JS Modules
    import Request from './../../javascript/getRequest';
    import FormValidation from './../../javascript/FormValidation';

    // component
    import UnitSelection from './../web-forms/UnitSelection.vue';


    export default {
        name: 'AssignTasks',
        props: ['data'],
        emits: ['close'],
        components: {
            UnitSelection,
        },

        data () {
            return {
                // data
                Current: this.data.current,
                Gallery: this.data.gallery,
                Property: this.data.property,
                ViewerType: this.data.from,

                // Form v-Modal
                Title : '',
                Description: '',
                Requesting: false,

                // for default image on property
                SetPropertyDefault: false,
                DeleteImage: false,

                // form error
                formError : false,
                infoText : '',
            }
        },

        mounted () {

            this.SetPropertyDefault = false;
            this.DeleteImage = false;

            // set the details
            this.Title = this.Current.title;
            this.Description = this.Current.description;

        },

        methods: {
            // delete function
            WantToDeleteImage () {
                this.DeleteImage = !this.DeleteImage;
                if(this.DeleteImage) {
                    this.SetPropertyDefault = false;
                }
            },  

            // update the image 
            async UpdateDocument () {
                if (this.Requesting) return;

                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-document');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead) {
                    this.Requesting = true;

                    let formData = new FormData();
                    formData.append('blobid', this.Current.blobid);
                    formData.append('property', this.Property.id);
                    formData.append('title', this.Title);
                    formData.append('description', this.Description);
                    formData.append('propertydefault', this.SetPropertyDefault);
                    formData.append('delete', this.DeleteImage);
                    formData.append('extension', this.Current.extension);
                    formData.append('from', this.ViewerType);

                    if (this.ViewerType == 'floorplan') {
                        formData.append('floorplan', this.data.floorplan);
                    }

                    // submit tasks data
                    const ReturnData = await Request.postServer('owner/upload/gallery-update.php', formData);
                    this.Requesting = false;

                    // now check if the email already exists
                    if (ReturnData.error == false) {
                        var Update = {
                            isDefault : this.SetPropertyDefault,
                            DefaultUrl : this.Current.url,
                            Gallery: ReturnData.response
                        };
                        // now close modal, and load current property
                        this.$emit('close', Update);
                    
                    // if error
                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! ";
                    }
                }



            }

            
        }


    };
</script>

<style scoped>

   
span.__formerror {margin-left: 0 !important;}
.v-calendar {width: calc(100% - 2px) !important;min-width: 100%;}
</style>