<template>
    <div class="prop-form _propscrl">        
        <div class="form-area trenhistry" style="padding-bottom: 15px;overflow: hidden;">
            <form name="propform" id="propform-tenant1" style="display: block;">
                <div class="pf--title">
                    <h3>Update Tenant Email</h3>
                    <p>Enter the email of the tenant for confirmation!</p>
                </div>

                <div class="pf--multi-input">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Email Address *</p>
                            <input type="text" placeholder="i.e jon@example.com" v-model="Email" id="Email" data-type="email" />
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <span v-if="isOnLookUp" class="primary-btn-loader" style="width: 100px !important;float: left !important;margin-top: 0px !important;margin-left: 72px;" :class="Requesting ? 'btn-disabled' : ''" @click="CheckEmail">
            <span v-if="!Requesting">Check Email</span>
            <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
        </span>

        <span v-else class="primary-btn-loader" style="width: 100px !important;float: left !important;margin-top: 0px !important;margin-left: 72px;" :class="Requesting ? 'btn-disabled' : ''" @click="UpdateEmail">
            <span v-if="!Requesting">Update Email</span>
            <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
        </span>

        <span v-if="formError" class="__formerror" style="margin-top: 3px !important;margin-left: 8px !important;">{{ infoText }}</span>
    </div>
</template>

<script>
    //@ JS Modules
    import DataCenter from '../../javascript/DataCenter';
    import Request from '../../javascript/getRequest';
    import FormValidation from '../../javascript/FormValidation';
    import Handler from '../../javascript/Handler';

    import SkeletonVue from '../blocks/Skeleton.vue';

    // datepicer
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    export default {
        name: 'RentAmountChange',
        props: ['data'],
        emits: ['close'],
        components: {
            SkeletonVue,
            Datepicker,
        },

        data () {
            return {
                Requesting: false,
                RentHistory: [],
                Email: '',
                User: this.data.id,
                Fname: this.data.fullname,
                isOnLookUp: true,
                formError: false,
                infoText: '',
            }
        },

        async mounted () {

            console.log (this.data);

        },

        methods: {

            async CheckEmail () {
                if (this.Requesting) return;
                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-tenant1');
                let canGoAhead = FormValidation.Validate (form);

                if (canGoAhead) {
                    this.Requesting = true;

                    let formData = new FormData();
                    formData.append('email', this.Email);
                    const ReturnData = await Request.postServer('tenants/lookup.php', formData);
                    var TenantEmail = ReturnData.response;
                    if (TenantEmail.isUser) {
                        this.formError = true;
                        this.infoText = 'This email cannot be updated to current tenant.';
                    }else {
                        this.isOnLookUp = false;
                    }

                    this.Requesting = false;
                }
            },

            async UpdateEmail () {
                if (this.Requesting) return;
                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-tenant1');
                let canGoAhead = FormValidation.Validate (form);

                if (canGoAhead) {
                    this.Requesting = true;

                    let formData = new FormData();
                    formData.append('email', this.Email);
                    formData.append('user', this.User);
                    formData.append('fname', this.Fname);
                    const ReturnData = await Request.postServer('tenants/update-email.php', formData);

                    // notify user and close
                    Handler.PropNotification('Successfully updated current tenant email!');
                    this.$emit('close', this.Email);

                }
            },

            CloseModel () {
                this.$emit('close');
            }
            
        }


    };
</script>

<style scoped>
 .notactive {
        text-decoration: line-through !important;
    }
    div.prop--table div.prop-row:last-child {
    border: none;
}
   

span.__formerror {margin-left: 0 !important;}
.v-calendar {width: calc(100% - 2px) !important;min-width: 100%;}
</style>