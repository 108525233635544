<template>
    <WebNav />
    <IndexHeroNew />
    <PropInfo />
    <PropOffer />
    <PropFeatures />
    <!-- <SignupForm /> -->
    <Footer />
</template>

<script>

    // import Website Navigation
    import WebNav from './../../components/website/WebNav.vue';
    import IndexHeroNew from './../../components/website/WebHeroNew.vue';
    import PropInfo from './../../components/website/PropInfo.vue';
    import PropOffer from './../../components/website/PropOffer.vue';
    import PropFeatures from './../../components/website/PropFeatures.vue';
    // import SignupForm from './../../components/website/SignupForm.vue';
    import Footer from './../../components/website/Footer.vue';
    

    export default {
        name : 'HomePage',
        components : {
            WebNav,
            IndexHeroNew,
            PropInfo,
            PropOffer,
            PropFeatures,
            // SignupForm,
            Footer
        },

        mounted () {
            // scroll to top
            window.scrollTo(0, 0);

            // remove styling used for apps
            document.getElementById('propstop-app').style.height = 'auto';
            document.getElementById('propstop-app').style.overflow = 'auto';

            //  change the title
            document.title = "PropStop: Revolutionizing Property Management for Landlords & Tenants";
        }
    }
</script>