<script>

    //@ JS Modules
    import Request from '../../javascript/getRequest';
    import Handler from '../../javascript/Handler';
    import FormValidation from '../../javascript/FormValidation';

    // google address
    import VueGoogleAutocomplete from 'vue-google-autocomplete';

    export default {
        name: 'OwnerBusinessProfile',
        props: ['data'],
        emits: ['close'],
        components: {
            VueGoogleAutocomplete,
        },

        data () {
            return {
                Requesting: false,

                BusinessName : this.data.business,
                Address : this.data.address,
                Country : this.data.bcountry,
                City: this.data.bcity,
                BusinessPhone: this.data.bmobile,
                SupportEmail: this.data.bemail,
                ZipCode: this.data.bzipcode,

                InvalidLocation : false,

                // google address
                gaddress : this.data.address,
                GoogleAddressValue : '',
                goelocation : {
                    location : {latitude : 0, longitude : 0},
                    country : '',
                    city : '',
                    zipcode : '',
                    state : '',
                    fulladdress : ''
                },

                BusinessEditMode : false,

                // form error handler
                formError : false,
                infoText : '',
            }
        },

        methods: {
            getAddressData: function (addressData, placeResultData, id) {
                let thisFieldID = document.getElementById('gogleaddress');

                // check if we get the postal code or not for real data
                if (addressData.postal_code == undefined || addressData.postal_code == null) {
                    this.InvalidLocation = true;
                    this.gaddress = '';
                    thisFieldID.value = '';

                }else {
                    this.InvalidLocation = false;
                    this.address = addressData;

                    // location
                    this.goelocation.location.latitude = addressData.latitude;
                    this.goelocation.location.longitude = addressData.longitude;

                    // other address
                    this.goelocation.country = addressData.country;
                    this.goelocation.city = addressData.locality;
                    this.goelocation.state = addressData.administrative_area_level_1;
                    this.goelocation.zipcode = addressData.postal_code;
                    this.goelocation.fulladdress = thisFieldID.value;

                    this.gaddress = thisFieldID.value;

                    // now update the address automatically
                    this.Country = addressData.country;
                    this.City = addressData.locality;
                    this.ZipCode = addressData.postal_code;

                    this.Address = this.gaddress;
                }

            },

            // handle submit the form
            async HandleSubmitForm () {
                if (this.Requesting) return;

                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-business');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead) {
                    this.Requesting = true;

                    let formData = new FormData();
                    formData.append('buz-name', this.BusinessName);
                    formData.append('buz-address', this.gaddress);
                    formData.append('buz-country', this.Country);
                    formData.append('buz-city', this.City);
                    formData.append('buz-phone', this.BusinessPhone);
                    formData.append('buz-email', this.SupportEmail);
                    formData.append('buz-zipcode', this.ZipCode);
                    
                    // send and receive data
                    const ReturnData = await Request.postServer('owner/user/update-business.php', formData);
                    this.Requesting = false;
                    
                    if (!ReturnData.error) {
                        // update notification
                        Handler.PropNotification ('Your business information has been updated, please reload app for better performance.');                        
                        location.reload();
                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again later!"
                    }                   

                }
            },
        },
    }
</script>

<template>

    <div class="prop-form _propscrl">        
        <div class="form-area" style="padding-bottom: 65px;">
            <form name="propform" id="propform-business" style="display: block;">
                <div class="pf--title">
                    <h3>Business Profile</h3>
                    <p>You can view or edit your business information from here.</p>
                </div>

                <div v-if="BusinessEditMode == false">
                    <div class="update--business" @click="BusinessEditMode = true">
                        <img src="./../../images/icons/edit.svg" />
                        <p>Edit</p>
                    </div>

                    <div class="pf--input">
                        <p>Business Name</p>
                        <input type="text" v-model="BusinessName" readonly />
                    </div>

                    <div class="pf--input">
                        <p>Address</p>
                        <input type="text" v-model="Address" readonly />
                    </div>

                    <div class="pf--input">
                        <p>City</p>
                        <input type="text" v-model="City" readonly />
                    </div>

                    <div class="pf--multi-input" style="overflow: hidden;">
                        <div class="half hleft">
                            <div class="pf--input">
                                <p>Business Phone</p>
                                <input type="text" v-model="BusinessPhone" readonly />
                            </div>
                        </div>
                        <div class="half hright">
                            <div class="pf--input">
                                <p>Support Email</p>
                                <input type="text" v-model="SupportEmail" readonly />
                            </div>
                        </div>
                    </div>

                    <div class="pf--multi-input" style="overflow: hidden;">
                        <div class="half hleft">
                            <div class="pf--input">
                                <p>Zipcode</p>
                                <input type="text" v-model="ZipCode" readonly />
                            </div>
                        </div>
                    </div>

                </div>

                <div v-if="BusinessEditMode">
                    <div class="update--business" @click="BusinessEditMode = false">
                        <img src="./../../images/icons/close.svg" />
                        <p>Close</p>
                    </div>

                    <div class="pf--input">
                        <p>Business Name</p>
                        <input type="text" placeholder="ABC Group LLC" v-model="BusinessName" id="BusinessName" data-type="len" data-max="5" />
                    </div>

                    <div class="pf--input">
                        <p>Address</p>
                        <vue-google-autocomplete
                            v-model="Address"
                            ref="address"
                            id="gogleaddress"
                            classname="map-input-control"
                            :placeholder="data.address"
                            data-type="len"
                            data-max="10"
                            v-on:placechanged="getAddressData"
                        >
                        </vue-google-autocomplete>
                        <p class="_geo_error" v-if="InvalidLocation">This doesn't appear to be a valid address. Please check and try it again.</p>
                    </div>

                    <div class="pf--input" style="overflow: hidden;">
                        <p>City</p>
                        <input type="text" placeholder="California" v-model="City" id="City" data-type="len" data-max="3" />
                    </div>

                    <div class="pf--multi-input" style="overflow: hidden;">
                        <div class="half hleft">
                            <div class="pf--input">
                                <p>Business Phone</p>
                                <input type="text" placeholder="+1 818 555 1212" v-model="BusinessPhone" />
                            </div>
                        </div>
                        <div class="half hright">
                            <div class="pf--input">
                                <p>Support Email</p>
                                <input type="text" placeholder="support@business.com" v-model="SupportEmail" />
                            </div>
                        </div>
                    </div>

                    <div class="pf--multi-input" style="overflow: hidden;">
                        <div class="half hleft">
                            <div class="pf--input">
                                <p>Zipcode</p>
                                <input type="text" placeholder="90650" v-model="ZipCode" id="ZipCode" data-type="len" data-max="2" />
                            </div>
                        </div>
                    </div>

                </div>

            </form>
        </div>

        <div class="form-action">
            <div class="form-area" v-if="BusinessEditMode">
                 <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="HandleSubmitForm">
                    <p v-if="!Requesting">Update Profile</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>
                <span v-if="formError" class="__formerror">{{ infoText }}</span>
            </div>
        </div>

    </div>
</template>

<style scoped>
span.__formerror {
    margin-left: 10px !important;
    margin-top: 17px !important;
}
</style>