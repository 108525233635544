<script>

    import Document from '../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';

    import Skeleton from './../../components/blocks/Skeleton.vue';
    import UserImage from '../../components/blocks/UserImage.vue';
    import HtmlSelect from './../../components/blocks/HtmlSelect.vue';

      export default {
            name: 'Feature',
            props: ['userProfile', 'owners'],
            components: {
                  Skeleton,
                  UserImage,
                  HtmlSelect,
            },

            data () {
                  return {
                        AppFeatures: [],
                        EmptyAppFeaturesList : false,

                        Filter: [
                            {
                                id      : 1,
                                name    : 'All',
                            },
                            {
                                id      : 2,
                                name    : 'Pending',
                            },
                            {
                                id      : 3,
                                name    : 'Completed',
                            }
                        ],

                        FeatureList: {
                            raw: [],
                            filtered: []
                        },

                        SearchText: '',
                        isRequesting: true,
                  }
            },

            async mounted () {
                // site title and seo
                Document.DocumentHeadings ({title : 'Dashboard - Dispute'});

                // load the dispute information
                const ReturnData = await DataCenter.ProPRequest('app-features', false, false);

                // update information
                this.FeatureList.raw = ReturnData;
                this.FeatureList.filtered = ReturnData;

                this.isRequesting = false;

                console.log (this.FeatureList.raw);

                //todo remove later

                this.AppFeatures = ReturnData;

                if (this.AppFeatures.length <= 0) this.EmptyAppFeaturesList = true;
                this.isRequesting = false;
            },

            methods: {
                 // filter click
                FilterHandler (filter) {
                    this.FeatureList.filtered = this.FeatureList.raw.filter(feature => {
                        if (filter.name == "All") return feature;

                        if (filter.name == "Pending") {
                            if (feature.active == "true") return feature;
                        }

                        if (filter.name == "Completed") {
                            if (feature.active == "false") return feature;
                        }
                    });
                },

                SearchFeatures () {
                    var keyword = this.SearchText.toUpperCase();
                    this.FeatureList.filtered = this.FeatureList.raw.filter(feature => {
                        if (feature.user.fullname.toUpperCase().indexOf(keyword) > -1 || feature.message.toUpperCase().indexOf(keyword) > -1) return feature;
                    });                
                },
            }

      };

</script>

<template>

    <div class="admin-property _propscrl">
        <div class="dashpageheader">
            <div class="dash-header">
                <div class="actions">
                    <HtmlSelect :options="Filter" @selected="FilterHandler" />
                    <input type="text" placeholder="Search" v-model="SearchText" @keyup="SearchFeatures()">
                </div>
            </div>
        </div>

        <div v-if="isRequesting" style="width: 50%;display: flex;margin: 0px auto;margin-top: 25%;">
            <Skeleton :type="'user-list'" :data="2" />
        </div>

        <ul class="dash-list dl-noselect" v-if="!isRequesting">
            <li v-for="feature in FeatureList.filtered" :key="feature.id">
                <div class="content">
                    <div class="image">
                        <UserImage :init="feature.user.fname" :source="feature.user.avatar" :back="feature.user.back" />
                    </div>
                    <div class="dlc-detail">
                        <div class="od-header">
                            <h3>{{ feature.user.fullname }}</h3>
                            <span>{{ feature.user.type }}</span>
                        </div>
                        <div class="od-footer">
                            <ul>
                                <li>
                                    <img src="../../images/icons/page.svg">
                                    <p>{{ feature.page }}</p>
                                </li>
                                <li>
                                    <img src="../../images/icons/ndate.svg">
                                    <p>{{ feature.created }}</p>
                                </li>
                            </ul>
                            <h4>{{ feature.message }}</h4>
                        </div>
                    </div>
                </div>
                <ul class="dli-hover">
                    <li>
                        <img src="../../images/icons/nedit.svg">
                    </li>
                </ul>
            </li>
        </ul>

        <div v-if="FeatureList.filtered.length == 0 && !isRequesting" class="empty-result animated fadeInUp" style="position: unset;height: 50vh;">
            <img src="../../images/icons/empty-result.svg" />
            <p>You don't have much information on this request.</p>
            <p>You can filter features above.</p>
        </div>
    </div>

<!-- <div class="admin-property">
        
      <div v-if="isRequesting" style="width: 50%;display: flex;margin: 0px auto;margin-top: 25%;">
            <Skeleton :type="'user-list'" :data="1" />
      </div>

      <div v-if="!isRequesting && !EmptyAppFeaturesList" class="admin-property-content animated fadeInUp" style="width: calc(100% - 40px);">
            <div class="admin-prop--table">
                <div class="prop-row row-header">
                    <div class="column column25">Requsted</div>
                    <div class="column column15">Dashboard</div>
                    <div class="column column20">Page</div>
                    <div class="column column40">Information</div>
                </div>

                <div class="prop-row" :class="index % 2 ? 'odd' : 'even'" 
                                        v-for="(feature, index) in AppFeatures" 
                                        :key="feature.id">

                    <div class="column column25">
                        <div class="contact-dtl">
                            <div class="image">
                                <UserImage :init="feature.user.fullname" :source="feature.user.avatar" />
                            </div>

                            <div class="content">
                                <h3>{{ feature.user.fullname }}</h3>
                                <p>{{ feature.user.email }}</p>
                                <p>{{ feature.user.created }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="column column15">
                        <div class="contact-dtl">
                            <p>{{ feature.dashboard }}</p>
                        </div>
                    </div> 

                    <div class="column column20">
                        <div class="contact-dtl">
                            <p>{{ feature.page }}</p>
                        </div>
                    </div> 

                    <div class="column column40">
                        <div class="contact-dtl">
                            <p>{{ feature.message }}</p>
                        </div>
                    </div> 

                </div>
            </div>
        </div>

        <div v-if="!isRequesting && EmptyAppFeaturesList" class="empty-result animated fadeInUp">
            <img src="../../images/icons/empty-result.svg" />
            <p>You dont have much information on this request. <br />New requested features on dashboard will be listed here.</p>
        </div>
</div> -->
      

</template>