<script>
    // javascript library
    import Document from './../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';

    import { useAdminStore } from '../../stores/AdminStore';

    // component
    import Skeleton from './../../components/blocks/Skeleton.vue';
    import UserImage from './../../components/blocks/UserImage.vue';

    import DashboardAccounts from './../../components/admin/DashboardAccounts.vue';
    import DashboardProperties from './../../components/admin/DashboardProperties.vue';

    import PropModal from './../../components/modal/PropModal.vue';

    export default {
        name: 'Dashboard',
        props: ['userProfile', 'owners'],
        components: {
            Skeleton,
            UserImage,

            DashboardAccounts,
            DashboardProperties,

            PropModal
        },

        data () {
            return {
                AdminStore: useAdminStore(),
                AdminProfile : false,

                PropOwners: [],
                PropProperties: [],
                AccountData : [],
                PropertyData: [],
                
                AccountVerifyDialog : false,
                PropertyVerifyDialog : false,

                VerifiedOwnerPercent: 0,
                VerifiedPropertyPercent: 0,

                Counter: {
                    properties: 0,
                    units: 0,
                    owners: 0,
                    tenants: 0,
                    employees: 0,
                    tickets: 0,
                },

                isRequesting : true,
            }
        },

        async mounted () {

            // site title and seo
            Document.DocumentHeadings ({title : 'Dashboard - PropStop'});

            if (!this.AdminStore.dashboard) {
                const AdminData = await DataCenter.ProPRequest('admin-dashboard', false, false);
                this.AdminStore.ResetDashboard(AdminData);
                this.UpdateDashboard();
            }else this.UpdateDashboard();
        },

        methods: {

            UpdateDashboard () {
                // Admin Profile
                this.AdminProfile = this.AdminStore.user;

                // dashboard counter
                this.Counter = this.AdminStore.dashboard;

                // owners and properties
                this.PropOwners = this.AdminStore.owners;
                this.PropProperties = this.AdminStore.properties;

                // calculate verified vs unverified accounts
                var verified = 0, total = this.PropOwners.length;
                this.PropOwners.forEach(owner => {
                    if (owner.verified == "true") {
                        verified++;
                    }
                });

                this.VerifiedOwnerPercent = Math.floor((verified / total) * 100);

                // calculate verified vs unverified properties
                var verified = 0, total = this.PropProperties.length;
                this.PropProperties.forEach(property => {
                    if (property.verified == "true") {
                        verified++;
                    }
                });

                this.VerifiedPropertyPercent = Math.floor((verified / total) * 100);

                // update
                this.isRequesting = false;
            },

            ShowAllProperties () {
                document.getElementById('pnav_Accounts').click();
            },

            ShowAllAccounts () {
                document.getElementById('pnav_Properties').click();
            },

            AccountClickHandler (owner) {
                this.AccountVerifyDialog = true;
                this.AccountData = owner;

                // select the properties of this owner
                const Proprties = this.OwnerProperties.filter (property => {
                    if (property.owner == owner.owner) return property;
                });

                this.AccountData = {
                    properties : Proprties,
                    owner : owner
                };
            },

            PropertyClickHandler (property) {
                this.PropertyVerifyDialog = true;

                this.PropertyData = {
                    property : property,
                };

            },

            CloseConformModal () {
                this.AccountVerifyDialog = false;
                this.PropertyVerifyDialog = false;
            },

            CloseAccountConformModal (action) {
                this.AccountVerifyDialog = false;
                if (action.task == 'delete') {
                    var NewAccounts = this.OwnerAccounts.filter(owner => {
                        if (owner.id != action.owner.id) return owner;
                    });

                    this.OwnerAccounts = NewAccounts;
                }
            }
        }

    };
</script>

<template>
    <div class="admin-dashboard _propscrl" :style="{'background-image':'url('+require('../../images/company/loading-bg.jpg')+')'}">
        <div v-if="isRequesting" style="width: 50%;">
            <Skeleton :type="'user-list'" :data="1" />
        </div>

        <div v-else class="user">
            <div class="avatar">
                <UserImage :init="AdminProfile.fullname" :source="AdminProfile.avatar" :back="AdminProfile.back" />
            </div>
            <div class="content">
                <h2>Hi, {{AdminProfile.fname}}</h2>
            </div>
        </div>

        <div class="fin-summery" style="margin-top: 30px;">
            <ul>
                <li>
                    <div class="image" style="background: #fd686b;">
                        <img style="width: 32px; height: 32px;" src="./../../images/admin/property.svg" />
                    </div>
                    <div class="content">
                        <p style="font-size: 11pt;">Properties / Units</p>
                        <h3 style="margin-top: 10px;">
                            <span style="font-size: 14pt;font-weight: 600;">{{ Counter.properties }} /</span>
                            <span class="big" style="font-size: 14pt;">{{ Counter.units }}</span>
                        </h3>
                    </div>
                </li>

                <li>
                    <div class="image" style="background: #209cff;">
                        <img style="width: 32px; height: 32px;"  src="./../../images/admin/users.svg" />
                    </div>
                    <div class="content">
                        <p style="font-size: 11pt;">Owners / Tenants</p>
                        <h3 style="margin-top: 10px;">
                            <span style="font-size: 14pt;font-weight: 600;">{{ Counter.owners }} /</span>
                            <span class="big" style="font-size: 14pt;">{{ Counter.tenants }}</span>
                        </h3>
                    </div>
                </li>

                <li>
                    <div class="image" style="background: #ff8818;">
                        <img style="width: 32px; height: 32px;"  src="./../../images/admin/employee.svg" />
                    </div>
                    <div class="content">
                        <p style="font-size: 11pt;">Employees</p>
                        <h3>{{ Counter.employees }}</h3>
                    </div>
                </li>

                <li>
                    <div class="image" style="background: #fd686b;">
                        <img style="width: 32px; height: 32px;"  src="./../../images/admin/tickets.svg" />
                    </div>
                    <div class="content">
                        <p style="font-size: 11pt;">Tickets</p>
                        <h3>{{ Counter.tickets }}</h3>
                    </div>
                </li>
            </ul>
        </div>
        
        <div class="admin-dash">            
            <div class="recent-prop">
                <h3 style="margin-bottom: 0;">Properties ({{ PropProperties.length }}) <span @click="ShowAllAccounts">See All</span></h3>
                <p>Verified vs Unverified properties listed by owners</p>

                <ul class="tasksum" style="margin-top: 5px;">
                    <li>
                        <div class="taskper">
                            <div class="task-percentage transAe" :style="'width: '+ VerifiedPropertyPercent +'%;'"></div>
                        </div>            
                    </li>
                </ul>
                
                <div v-if="isRequesting" style="margin-top: 45px;">
                    <Skeleton :type="'user-list'" :data="4" />
                </div>
                <DashboardProperties v-else :properties="PropProperties" @choose="PropertyClickHandler"  />
            </div>

            <div class="recent-acc">
                <h3 style="margin-bottom: 0;">Owner Accounts ({{ Counter.owners }}) <span @click="ShowAllProperties">See All</span></h3>
                <p>Verified vs Unverified property owner accounts</p>

                <ul class="tasksum" style="margin-top: 5px;">
                    <li>
                        <div class="taskper">
                            <div class="task-percentage transAe" :style="'width: '+ VerifiedOwnerPercent +'%;'"></div>
                        </div>            
                    </li>
                </ul>

                <div v-if="isRequesting" style="margin-top: 45px;">
                    <Skeleton :type="'user-list'" :data="5" />
                </div>
                <DashboardAccounts v-else :accounts="PropOwners" @choose="AccountClickHandler" />
            </div>
        </div>
    </div>

    <!-- Modals -->
    <PropModal v-if="AccountVerifyDialog == true" :from="'account-verify'" :data="AccountData" @modalclose="CloseAccountConformModal" />
    <PropModal v-if="PropertyVerifyDialog == true" :from="'propert-verify'" :data="PropertyData" @modalclose="CloseConformModal" />

</template>