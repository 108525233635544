<template>
    <div v-if="!ImageLoad" class="imgcls" :data-loading-image="source" :style="{'background-image':'url('+require('../../images/icons/img-loading.svg')+')'}"></div>
    <div v-if="ImageError" class="imgcls" :data-error-image="source" :style="{'background-image':'url('+require('../../images/icons/img-property.svg')+')'}"></div>
    <img class="imgrep" v-if="ImageLoad && !ImageError" :src="source" />
</template>

<script>    
    export default {
        name : 'ReplaceImage',
        props : ['source'],

        data () {
            return {
                Img : null,
                ImageLoad : false,
                ImageError : false
            }
        },

         mounted () {
            this.Img = new Image ();
            this.Img.addEventListener ('load', () => {
                this.ImageLoad = true;
            });

            this.Img.addEventListener ('error', () => {
                this.ImageLoad = true;
                this.ImageError = true;
            });this.Img.src = this.source;
        },
    }
</script>

<style scoped>
.imgcls {
width: calc(100% - 2px);
height: calc(100% - 2px);
background-repeat: no-repeat;
background-size: 45%;
background-position: center; background-color: rgb(0 0 0 / 7%); border: 1px solid #c2c2c2; border-radius: 4px;}
.imgcls p {    font-weight: 800;
text-align: center;
font-size: 14pt;
padding-top: 3vh;
color: rgb(100 100 100) !important;}img.imgrep {height: 100%; height: 100%; object-fit: cover;}
</style>