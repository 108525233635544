<template>
  <div class="owner-properties">
      <div class="sidebar" :style="SidebarHamClick ? 'left: 0px !important;box-shadow: rgb(0 0 0 / 22%) 0px 7px 10px !important;' : ''">

          <div v-if="!OwnerProperties" style="margin-top:45px">
              <SkeletonVue :type="'user-list'" :data="4" />
          </div>
          
          <div v-if="!OwnerHasNoProperty" class="choose-property" @click="isPropertySelection = true">
              <div class="image">
                  <ReplaceImage :source="currentProperty.image" />
              </div>

              <div class="content">
                  <h3>{{ currentProperty.name}}</h3>
                  <p>{{ currentProperty.address }}</p>
              </div>
          </div>
          
          <div v-if="isPropertySelection">
            <PropertyList :distyle="'top: 90px;bottom: unset;left: 195px;'" :properties="OwnerProperties" @prosel="propertySelected" @proboxclse="closeList" />
          </div>

          <ul v-if="!OwnerHasNoProperty" class="property-menu" id="propertyMenu">
              <PropertyNav v-for="list in navigationlist" :key="list.key" :current="list" @navchange="NavigationClicked" />
          </ul>

          <div v-for="(action, index) in SecondaryAction" :class="`btn-primary-addition-` + index" @click="SecondaryActionProcess(action)">
              <p>{{ action.name }}</p>
          </div>

          <div class="btn-primary" @click="addpropertydialog">
              <img src="../../images/icons/addbtn-icon.svg" />
              <p>Add Property</p>
          </div>
      </div>

      <div class="property-content _propscrl">

          <div class="proprty-ham-menu" @click="SidebarHamClick = true">
            <img src="./../../images/icons/line-menu.svg" />
            <p>{{this.currentPage}}</p>
          </div>
          
          <div v-if="OwnerHasNoProperty" style="margin-top:205px">
              <SkeletonVue :data="3" />
          </div>

            <!-- Current Property Dynamic Content Here -->          
            <Overview v-if="!OwnerHasNoProperty && currentPage=='Overview'" 
                      :property="currentProperty" 
                      :key="OverviewPageKey" 
                      @verify="VerifyProperty" 
                      @navigate="NavigatePages"
                      @editproperty="EditProperty" />

            <Gallery v-if="!OwnerHasNoProperty && currentPage=='Gallery'" 
                    @setdefault="updateDefaultImage"
                    :property="currentProperty" 
                    
                    :initUpload="GalleryUpload"
                    @dischargeUpload="GalleryUpload = false"
                    :key="GalleryUpload" />

            <Units v-if="!OwnerHasNoProperty && currentPage=='Units'" 
                    :property="currentProperty" 
                    
                    :initModify="ModifyUnit"
                    @dischargeModify="ModifyUnit = false"
                    :key="ModifyUnit" />

            <FloorPlan v-if="!OwnerHasNoProperty && currentPage=='Floorplan'"
                    :property="currentProperty" 

                    :initFloorPlan="AddFloorPlan"
                    @dischargeFloorPlan="AddFloorPlan = false"
                    :key="AddFloorPlan" />

            <Parking v-if="!OwnerHasNoProperty && currentPage=='Parking'" 
                    :property="currentProperty" 
                    
                    :initAdsgnParking="ParkingAction"
                    @dischargeParking="ParkingAction = ''"
                    :key="ParkingAction"  />

            <People v-if="!OwnerHasNoProperty && currentPage=='People'" 
                    :property="currentProperty" 
                    
                    :initPeople="AddPeople"
                    @dischargePeople="AddPeople = ''"
                    :key="AddPeople"  />

            <Tasks v-if="!OwnerHasNoProperty && currentPage=='Tasks'" 
                :property="currentProperty" 
                :ownerProfile="profile" 
                :employees="employees"

                :initTask="AddTasks"
                @dischargeTask="AddTasks = false"
                :key="AddTasks"  />

            <Finance v-if="!OwnerHasNoProperty && currentPage=='Finance'"
                :property="currentProperty"
                :properties="properties"
                
                :initFinance="AddFinance"
                @dischargeFinance="AddFinance = false"
                :key="AddFinance" />

            <!-- Current Property Dynamic Content Here -->

      </div>

      <div v-if="OwnerHasNoProperty" class="empty-result animated fadeInUp">
          <img src="./../../images/icons/on-verification.svg">
          <p>It looks like you haven't added a property yet.<br>Please add a property to view all features.</p>
          <div class="btn-primary bphasno" @click="addpropertydialog">
              <img src="../../images/icons/addbtn-icon.svg" />
              <p>Add Property</p>
          </div>
      </div>
  </div>
  <!-- Owner Properties Page -->

  <!-- Modals & Dialogs --> 
  <RightModal v-if="AddNewPropertyModal == true" :form="'add-property'" @modalclose="CloseModalBox" :data="null" />
  <RightModal v-if="EditCurrentProperty" :form="'edit-property'" :data="PropertyToEditData" @modalclose="CloseEditModal" />

  <!-- new property created -->
  <PropModal v-if="NewPropertyCreated == true" :from="'property-created'" @modalclose="CloseNewPropertyModal" />
  <PropModal v-if="ConfirmVerifyPropery == true" :from="'confirm-verify-property'" :data="currentProperty" @modalclose="CloseConfrimVerify" /> 


</template>

<script>

  //@ JS Modules
  import Document from '../../javascript/Document';

  import Request from './../../javascript/getRequest';
  import Storage from './../../javascript/Storage';

  // pinia
  import { useOwnerStore } from './../../stores/OwnerStore';

  // @ is an alias to /src
  import HtmlImage from '../../components/blocks/HtmlImage.vue';
  import ReplaceImage from '../../components/blocks/ReplaceImage.vue';
  import PropertyNav from '../../components/blocks/PropertyNav.vue';
  import PropertyList from '../../components/blocks/PropertyList.vue';

  import SkeletonVue from './../../components/blocks/Skeleton.vue';
  
  // Property Pages
  import Overview from '../../components/property/Overview.vue';
  import Gallery from '../../components/property/Gallery.vue';
  import Units from '../../components/property/Units.vue';
  import FloorPlan from '../../components/property/FloorPlan.vue';
  import Parking from '../../components/property/Parking.vue';
  import People from '../../components/property/People.vue';
  import Tasks from '../../components/property/Tasks.vue';
  import Finance from '../../components/property/Finance.vue';

  // Modal
  import RightModal from './../../components/modal/RightModal.vue';
  import PropModal from './../../components/modal/PropModal.vue';


  export default {
    name: 'Properties',
    props: ['profile', 'properties', 'employees', 'selected'],
    emits: ['updateproperties'],
    
    components: {
      HtmlImage,
      ReplaceImage,
      PropertyNav,
      PropertyList,

      SkeletonVue,
            
      Overview,
      Gallery,
      Units,
      FloorPlan,
      Parking,
      People,
      Tasks,
      Finance,

      // Modal
      RightModal,
      PropModal
    },

    data () {
      return {
        OwnerProperties : false,

        StockURL : Request.StockURL,
        
        currentPage : 'Overview',        

        // key change pages
        OverviewPageKey : 'overview',
        currentPropertyImageKey : 'current',
        isPropertySelection : false,
        
        OwnerHasNoProperty : true,
        currentProperty : [],

        // Navigation List
        navigationlist : [
          {
            key  : 1,
            name : 'Overview',
            icon : 'overview.svg',
            active : true
          },
          {
            key  : 2,
            name : 'Gallery',
            icon : 'gallery.svg',
            active : false
          },
          {
            key  : 3,
            name : 'Units',
            icon : 'units.svg',
            active : false
          },
          {
            key  : 4,
            name : 'Floorplan',
            icon : 'floorplan.svg',
            active : false
          },
          {
            key  : 5,
            name : 'Parking',
            icon : 'parking.svg',
            active : false
          },
          {
            key  : 6,
            name : 'People',
            icon : 'peoples.svg',
            active : false
          },
          {
            key  : 7,
            name : 'Tasks',
            icon : 'tasks.svg',
            active : false
          },
          {
            key  : 8,
            name : 'Finance',
            icon : 'dollar-sign.svg',
            active : false
          }
        ],

        // secondary action for page
        SecondaryAction: [{
            id: 1,
            current: 'Overview',
            action: 'edit',
            name: 'Edit Property'
        }],
        SecondaryActions: [
          {
            id: 1,
            current: 'Overview',
            action: 'edit',
            name: 'Edit Property'
          },
          {
            id: 2,
            current: 'Gallery',
            action: 'upload',
            name: 'Upload Images'
          },
          {
            id: 3,
            current: 'Units',
            action: 'modify',
            name: 'Modify Units'
          },
          {
            id: 4,
            current: 'Floorplan',
            action: 'floor',
            name: 'Add Floorplan'
          },
          {
            id: 5,
            current: 'Parking',
            action: 'addparking',
            name: 'Add Parking'
          },
          {
            id: 51,
            current: 'Parking',
            action: 'assignparking',
            name: 'Assign Units'
          },
          {
            id: 6,
            current: 'People',
            action: 'employee',
            name: 'Add Employee'
          },
          {
            id: 61,
            current: 'People',
            action: 'tenant',
            name: 'Add Tenant'
          },
          {
            id: 7,
            current: 'Tasks',
            action: 'task',
            name: 'Add Tasks'
          },
          {
            id: 8,
            current: 'Finance',
            action: 'finance',
            name: 'Add Transactions'
          }
        ],

        // secondary actions key and values for 
        // different components
        GalleryUpload: false,
        ModifyUnit: false,
        AddFloorPlan: false,
        ParkingAction: '',
        AddPeople: '',
        AddTasks: false,
        AddFinance: false,

        // Modals & Settings
        AddNewPropertyModal : false,
        NewPropertyCreated : false,
        ConfirmVerifyPropery : false,

        // responsive
        SidebarHamClick: false,

        // edit property
        EditCurrentProperty: false,
        PropertyToEditData: null,
        
        // search click functions
        OwnerStore: useOwnerStore(),
        ParamsChange: useOwnerStore().route,

      }
    },

    watch: {

      ParamsChange: {
        handler (newStore) {
          if (this.ParamsChange.page == 'Properties') {
            var SelectedProperty = newStore.params;
            var Properties = this.OwnerStore.properties;
            var Selected = {};
            Properties.filter (property => {
              if (SelectedProperty == property.id) Selected = property;
            });

            // open the property
            if (Selected) this.propertySelected (Selected);
          }
        },
        deep: true,
      }

    },

    async mounted () {

        // pinia setup
        const OwnerStore = useOwnerStore ();

        // // check if owner has propeties inactive
        // let hasInactive = this.OwnerStore.properties.some(property => !property.active);
        // let createdDays = this.OwnerStore.properties.some(property => property.created_days <= 1 ? true : false);
        // if (hasInactive && createdDays) this.NewPropertyCreated = true;
      
        // hide the properties selection from here
        var OwnPrty = document.getElementById('ownerprtities');
        if (OwnPrty != null || OwnPrty != undefined) {
            OwnPrty.style.visibility = 'hidden';
            OwnPrty.style.opacity = '0';
        }

        // site title and seo
        Document.DocumentHeadings ({title : ' Properties - PropStop'});
        
        // property list of current owner
        this.OwnerProperties = this.properties;
        this.currentProperty = this.selected;

        // check the properties lenth and show the data
        if (this.OwnerProperties.length > 0) {
          this.OwnerHasNoProperty = false;
        }

        // global add action
        var AddAction = document.getElementById('AppMainAddActionIcon');
        AddAction.onclick = () => {
          this.addpropertydialog ();
        };

    },

    methods : {

      SecondaryActionProcess (action) {
        if (action.action == 'edit') this.EditProperty();
        else if (action.action == 'upload') this.GalleryUpload = true;
        else if (action.action == 'modify') this.ModifyUnit = true;
        else if (action.action == 'floor') this.AddFloorPlan = true;
        else if (action.action == 'addparking') this.ParkingAction = 'add';
        else if (action.action == 'assignparking') this.ParkingAction = 'assign';
        else if (action.action == 'tenant') this.AddPeople = 'tenant';
        else if (action.action == 'employee') this.AddPeople = 'employee';
        else if (action.action == 'task') this.AddTasks = true;
        else if (action.action == 'finance') this.AddFinance = true;
      },

      EditProperty () {
        this.PropertyToEditData = this.currentProperty;
        this.EditCurrentProperty = true;

      },

      CloseEditModal (properties) {
        this.EditCurrentProperty = false;

        if (properties) {
          this.OwnerProperties = properties;

          // select the added property i.e. last on the list
          var total = this.OwnerProperties.length;
          var toSelect = this.OwnerProperties[total-1];

          this.propertySelected(toSelect);
          
          // set the global 
          this.$emit('updateproperties', properties);
        }
      },

       updateDefaultImage (url) {
            this.selected.image = url;
        },  
      
      // change the active state on the navigation
      NavigationClicked (nav) {
        this.currentPage = nav.name;
        
        this.navigationlist = this.navigationlist.filter(list => {
          list.active = false;
          
          if (list.key == nav.key) {
            list.active = true;
          }

          return list;
        });

        // remove sidebar responsive
        this.SidebarHamClick = false;

        // update the secondary buttons
        this.SecondaryAction = this.SecondaryActions.filter(action => {
          if (action.current == this.currentPage) return action;
        });
        console.log (this.SecondaryAction);

      },

      // navigation
      NavigatePages (page) {
        
        this.currentPage = page;

        this.navigationlist = this.navigationlist.some(list => {
          list.active = false;
          
          if (list.name == page) {
            list.active = true;
          }

          return list;
        });
      },

      // property selected
      propertySelected (property) {
          this.currentProperty = property;

          // show the overview page
          this.currentPage = "Overview";
          this.OverviewPageKey = property.id;
          this.currentPropertyImageKey = property.id;

          // now close the list too
          this.closeList ();
      },

      // close property list
      closeList () {
        this.isPropertySelection = false;
      },

      // add property dialog
      addpropertydialog () {
          this.AddNewPropertyModal = true;
      },

      // close add property dialog 
      CloseModalBox (properties) {
        this.AddNewPropertyModal = false;

        if (properties) {
          this.OwnerProperties = properties;

          // property added message pop-up
          this.NewPropertyCreated = true;
          this.OwnerHasNoProperty = false;

          // select the added property i.e. last on the list
          var total = this.OwnerProperties.length;
          var toSelect = this.OwnerProperties[total-1];

          this.propertySelected(toSelect);
          
          // set the global 
          this.$emit('updateproperties', properties);
        }
      },
      
      CloseNewPropertyModal () {
          this.NewPropertyCreated = false;
      },

      // Want to verify Property
      VerifyProperty () {
          this.ConfirmVerifyPropery = true;
      },

      // verify property? confirmation?
      async CloseConfrimVerify (action) {
          if (!action) {
            this.ConfirmVerifyPropery = false;
            return;
          }
          
          // now send for the verification
          const ReturnData = await Request.postServer('property/verify.php?property=' + action.id);
          if (!ReturnData.error) {
              // now update current property is on the verification list
              this.OwnerProperties = this.OwnerProperties.filter (property => {
                  if (property.id == action.id) {property.verification = 'true';}
                  return property;
              });

              // update current property
              this.propertySelected (action);
              Storage.storeCache('property-list', this.OwnerProperties);

              // remove dialog
              this.ConfirmVerifyPropery = false;
          }
          
      },

      // reload properties and update
      ReloadOwnerProperties () {
        
      }

    }

  };
</script>

<style scoped>
  div.property-list {
    top: 90px !important;
    bottom: unset !important;
    left: 195px !important;
  }
  div.empty-result {position: absolute;background-color: rgb(255 255 255 / 92%);}
  .bphasno {margin-top: 15px !important;}
  .bphasno > img {width: 15px !important;}

</style>