<script>

    // javascript library
    import Document from './../../javascript/Document';

    import { useAdminStore } from '../../stores/AdminStore';

    // component
    import Skeleton from './../../components/blocks/Skeleton.vue';
    import UserImage from '../../components/blocks/UserImage.vue';
    import HtmlSelect from './../../components/blocks/HtmlSelect.vue';    

    import PropModal from './../../components/modal/PropModal.vue';

    export default {
        name: 'OwnerAccounts',
        props: ['userProfile', 'owners'],
        components : {
            Skeleton,
            UserImage,
            HtmlSelect,
            PropModal
        },

        data () {
            return {
                AdminStore: useAdminStore(),
                AccountFilters : [
                    {
                        id      : 1,
                        name    : 'All',
                        active  : true,
                    },
                    {
                        id      : 2,
                        name    : 'Waitlist',
                        active  : false,
                    },
                    {
                        id      : 3,
                        name    : 'Verified',
                        active  : false,
                    },
                    {
                        id      : 4,
                        name    : 'Unverified',
                        active  : false,
                    },
                    {
                        id      : 5,
                        name    : 'Unverified Email',
                        active  : false,
                    },
                ],

                FilterChoosed : false,

                AdminProfile : false,

                OwnerProperties: [],

                OwnerAccounts : {
                    filtered: [],
                    raw: []
                },
                TotalOwners : 0,
                AccountsKey : false,               

                // dialog
                ConfirmDialog : false,
                AccountData: false,

                isRequesting: true,
                SearchText: ''
            }
        },

        async mounted () {
            // site title and seo
            Document.DocumentHeadings ({title : 'Dashboard - Accounts'});

            // Admin Profile
            this.AdminProfile = this.AdminStore.user;

            // Accounts and Properties
            this.OwnerAccounts.raw = this.AdminStore.owners; 
            this.OwnerAccounts.filtered = this.AdminStore.owners;
            this.TotalOwners = this.AdminStore.owners.length;

            this.OwnerProperties = this.AdminStore.properties;

            this.isRequesting = false;
        },

        methods: {
            // filter click
            AccountFilterHandler (filter) {
                this.FilterChoosed = filter;

                // now filter the team members
                // and show to the stage
                this.OwnerAccounts.filtered = this.OwnerAccounts.raw.filter(user => {
                    if (filter.name == "All") return user;

                    if (filter.name == "Verified") {
                        if (user.verified == "true") return user;
                    }

                    if (filter.name == "Unverified") {
                        if (user.verified == "false") return user;
                    }

                    if (filter.name == "Waitlist") {
                        if (user.verification == "true") return user;
                    }

                    if (filter.name == "Unverified Email") {
                        if (user.email_verify == "false") return user;
                    }
                });
            },

            AccountClickHandler (owner) {
                this.ConfirmDialog = true;
                this.AccountData = owner;

                // select the properties of this owner
                const Properties = this.OwnerProperties.filter (property => {
                    if (property.owner == owner.owner) return property;
                });

                this.AccountData = {
                    properties : Properties,
                    owner : owner
                };
            },

            SearchOwners () {
                var keyword = this.SearchText.toUpperCase();
                this.OwnerAccounts.filtered = this.OwnerAccounts.raw.filter(account => {
                    if (account.fullname.toUpperCase().indexOf(keyword) > -1 || account.email.toUpperCase().indexOf(keyword) > -1 || account.mobile.toUpperCase().indexOf(keyword) > -1) return account;
                });                
            },  

            CloseConformModal (action) {
                if (action != false) {
                    // update this account as verified
                    this.OwnerAccounts.raw = this.OwnerAccounts.raw.filter(user => {
                        if (action == user.id) {
                            user.verified = 'true';
                            user.verification = 'false';
                        } return user;
                    });

                    this.OwnerAccounts.filtered = this.OwnerAccounts.raw;

                    // filter to the Verified section
                    this.AccountFilterHandler (this.AccountFilters[1]);

                }else this.ConfirmDialog = false;
            }
        }

    };
</script>

<template>
    <div class="admin-property _propscrl">
        <div class="dashpageheader">
            <div class="dash-header">
                <div class="actions">
                    <HtmlSelect :options="AccountFilters" @selected="AccountFilterHandler" />
                    <input type="text" placeholder="Search" v-model="SearchText" @keyup="SearchOwners()">
                </div>
                <div class="addition">
                    <p>{{ OwnerAccounts.filtered.length }} of <strong>{{ OwnerAccounts.raw.length }}</strong> Showing</p>
                </div>
            </div>
        </div>

        <div v-if="isRequesting" style="width: 50%;display: flex;margin: 0px auto;margin-top: 25%;">
            <Skeleton :type="'user-list'" :data="2" />
        </div>

        <ul class="dash-list" v-if="!isRequesting">
            <li v-for="account in OwnerAccounts.filtered" :key="account.id" @click="AccountClickHandler(account)">
                <div class="content">
                    <div class="image">
                        <UserImage :init="account.fname" :source="account.avatar" :back="account.back" />
                    </div>
                    <div class="dlc-detail">
                        <div class="od-header">
                            <h3>{{ account.fullname }}</h3>
                            <span class="image" v-if="account.verified == 'true'"><img src="../../images/icons/nverified.svg" alt="Account Verified"></span>
                        </div>
                        <div class="od-footer">
                            <ul>
                                <li>
                                    <img src="../../images/icons/nemail.svg">
                                    <p>{{ account.email }}</p>
                                </li>
                                <li v-if="account.mobile != ''">
                                    <img src="../../images/icons/nphone.svg">
                                    <p>{{ account.mobile }}</p>
                                </li>
                                <li>
                                    <img src="../../images/icons/ndate.svg">
                                    <p>{{ account.created }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <ul class="dli-hover">
                    <li>
                        <img src="../../images/icons/nedit.svg">
                    </li>
                </ul>
            </li>
        </ul>

        <div v-if="OwnerAccounts.filtered.length == 0 && !isRequesting" class="empty-result animated fadeInUp" style="position: unset;height: 50vh;">
            <img src="../../images/icons/empty-result.svg" />
            <p>You don't have much information on this request.</p>
            <p>You can filter owner account.</p>
        </div>
    </div>

    <!-- Account detail and Confirm verificaiton -->
    <PropModal v-if="ConfirmDialog == true" :from="'account-verify'" @modalclose="CloseConformModal" :data="AccountData" />

</template>