<template>
    <!-- What is Propstop -->
    <section class="what-propstop">
        <div class="wrapper">
            <h2>What is <em><p>PropStop</p></em> and what<br />does it do?</h2>
            <ul>
                <li>
                    <div class="icon">
                        <img src="../../images/website/home.svg" alt="Home" />
                    </div>
                    <div class="content">
                        <h3>One Software</h3>
                        <p>The biggest problems with the property management tools currently on the market is that NONE of them do EVERYTHING, and they get pricey. That means property managers need to subscribe to multiple services for their document management, tenant management, background checks, service tickets, maintenance receipts, rent tracking, and automated rent collection. PropStop does it all.</p>

                        <p>Did we mention it's free?</p>
                    </div>
                </li>

                <li>
                    <div class="icon">
                        <img src="../../images/website/chart.svg" alt="Chart" />
                    </div>
                    <div class="content">
                        <h3>Makes Management Easier</h3>
                        <p>Instead of scrambling and fumbling through various apps and tools and services—PropStop gives you everything you need under one roof. This makes managing your properties simpler, keeps your life tidy, and eliminates the possibility that something important slips through the cracks. </p>
                    </div>
                </li>

            </ul>
        </div>
    </section>
    <!-- What is Propstop -->

    <!-- What is Propstop -->
    <section class="what-propstop">
        <div class="wrapper" style="padding-top: 0;">
            <h2>For Landlords & Tenants</h2>
            <ul>
                <li>
                    <div class="icon">
                        <img src="../../images/website/home.svg" alt="Home" />
                    </div>
                    <div class="content">
                        <h3>Tenants</h3>
                        <p>Let's face it: dealing with landlords can be better. With PropStop, you can virtually submit and monitor service requests, digitally pay rent through PropStop's online bill payment tool, and reduce the need for face-to-face communication. Finally!</p>
                        <router-link :to="{name : 'web-tenants'}">Learn More</router-link>
                    </div>
                </li>

                <li>
                    <div class="icon">
                        <img src="../../images/website/chart.svg" alt="Chart" />
                    </div>
                    <div class="content">
                        <h3>Landlords</h3>
                        <p>The last thing YOU need is more clutter in your life. With PropStop, you get a free tool that allows you to virtually track and monitor EVERY facet of your property management business. No more chasing tenants for rent, no more redundant paperwork—all while seamlessly tracking maintenance requests and managing all of the documentation that comes with property management.</p>
                        <router-link :to="{name : 'web-landlords'}">Learn More</router-link>
                    </div>
                </li>

            </ul>
        </div>
    </section>
    <!-- What is Propstop -->
</template>

<script>
    export default {
        name : 'PropInfo',  
    };
</script>