<template>
    <div class="prop-form _propscrl">        
        <div class="form-area" style="padding-bottom: 65px;">
            <form name="propform" id="propform">
                <div class="pf--title">
                    <h3>Add New Employee</h3>
                    <p>Please choose the breif information about your new employee. Also assign they with the property & role.</p>
                    <p style="font-size: 10pt;">* Indicate a required field</p>
                </div>

                <!-- <div class="pf--input" style="margin-top: 45px;">
                    <p>Property</p>
                    <PropertySelected :property="data.property" />
                </div> -->

                <div class="pf--input" style="margin-top: 45px; position: relative;">
                    <p>Property *</p>
                    <input type="text" placeholder="Select Property" id="Properties" data-type="len" data-max="3" readonly @click="MultiPropertySelection = true" v-model="PropertyName" />

                    <MultiPropertySelectionVue v-if="MultiPropertySelection" 
                            :properties="data.properties" :style="'top: -85px;left: 25px;'"   
                            @lstboxclse="PropertiesAreSelected" />
                </div>

                <div class="pf--multi-input" style="height: 75px;">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>First Name *</p>
                            <input type="text" placeholder="i.e Jon" v-model="FirstName" id="FirstName" data-type="len" data-max="3" />
                        </div>
                    </div>
                    <div class="half hright">
                        <div class="pf--input">
                            <p>Last Name *</p>
                            <input type="text" placeholder="i.e Doe" v-model="LastName" id="lastName" data-type="len" data-max="3" />
                        </div>
                    </div>
                </div>

                <div class="pf--multi-input" style="height: 90px;">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Email Address *</p>
                            <input type="text" placeholder="i.e jon@example.com" v-model="Email" id="Email" data-type="email" />
                        </div>
                    </div>
                    <div class="half hright">
                        <div class="pf--input">
                            <p>Start Date *</p>
                            <Datepicker class="dp__input_custom" v-model="StartDate" :enableTimePicker="false" autoApply required placeholder="Pick Date" />
                        </div>
                    </div>
                </div>

                <div class="pf--multi-input" style="height: 85px;">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Employee Role *</p>
                            <select name="floor" v-model="Role" id="Role" data-type="select">
                                <option value="-1" selected>Select Role</option>
                                <option v-for="role in EmployeeRole" :key="role" :value="role.title">{{ role.title }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        
        <div class="form-action">
            <div class="form-area">
                <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="AddNewEmployee">
                    <p v-if="!Requesting">Add Employee</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>
                <span v-if="userDuplicate || signupError" class="__formerror" style="margin-left: 10px !important;">{{ infoText }}</span>
            </div>
        </div>
    </div>
</template>

<script>

    //@ JS Modules
    import Request from './../../javascript/getRequest';
    import Locale from './../../javascript/Locale';
    import FormValidation from './../../javascript/FormValidation';

    // import component
    import PropertySelected from './../blocks/PropertySelected.vue';
    import MultiPropertySelectionVue from './../web-forms/MultiPropertySelection.vue';

    // datepicer
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    export default {
        name: 'AddEmployee',
        props: ['data'],
        emits: ['close'],
        components: {
            Datepicker,
            PropertySelected,
            MultiPropertySelectionVue,
        },

        data () {
            return {
                // Form Modal
                FirstName : null,
                LastName: null,
                Email : null,
                StartDate : null,
                Role : '-1',
                PropertyName : '',
                PropertyIDs: '',
                Requesting: false,

                // others
                EmployeeRole : false,
                userDuplicate : false,
                signupError: false,
                infoText: '',

                // property selection
                MultiPropertySelection: false,
                PropertiesSelected : [],

            }
        },

        async mounted () {

            // employee role
            this.EmployeeRole = Locale.EmployeeRoles ();
            this.EmployeeRole.shift (); // remove 'All' from the list

        },

        methods: {

            // filter the properties by selection
            PropertiesAreSelected (propties) {
                this.MultiPropertySelection = false;
                this.PropertiesSelected = propties;
                this.PropertyIDs = '';

                var selected = 0;
                this.PropertiesSelected.filter (property => {
                    if (property.active == true) {
                        this.PropertyIDs += property.id + ','
                        selected++;
                    }
                });

                if (selected <= 0) this.PropertyName = '';
                else this.PropertyName = selected + ' Property Selected';
            },
            
            // form submission
            async AddNewEmployee () {
                if (this.Requesting) return;

                // set user duplicate as false
                this.userDuplicate = false;
                this.signupError = false;
                this.infoText = '';

                let PickerAhead = true;
                
                // calendar validation
                if (this.StartDate == null) {
                    PickerAhead = false;
                    alert ('Please pick rental start date!');
                }

                // form & validation
                let form = document.getElementById('propform');
                let canGoAhead = FormValidation.Validate (form);

                // now collection and move ahead
                if (canGoAhead && PickerAhead) {
                    this.Requesting = true;

                    // convert date time to timestamp
                    let hiredate;
                    if (this.StartDate == null) this.StartDate = new Date ();
                    hiredate = this.StartDate.getTime();
                    hiredate = hiredate/1000;

                    let formData = new FormData();
                    formData.append('firstname', this.FirstName);
                    formData.append('lastname', this.LastName);
                    formData.append('email', this.Email);
                    formData.append('password', this.Password);
                    formData.append('property', this.PropertyIDs);
                    formData.append('hiredate', hiredate);
                    formData.append('role', this.Role);

                    // load data from server
                    const ReturnData = await Request.postServer('employees/add.php', formData);
                    this.Requesting = false;

                    if (ReturnData.error == false) {
                        this.$emit('close', ReturnData.response);
                    }else {
                        if (ReturnData.reason == "duplicate") {
                            this.signupError = true;
                            this.userDuplicate = true;
                            this.infoText = "Duplicate User!";
                        }else {
                            this.signupError = true;
                            this.infoText = "Something went wrong! Please try again.";
                        }
                    }

                }

            },
        }
    };

</script>

<style scoped>
span.__formerror {width: auto;display: block;font-size: 9pt;background-color: #ffc107;padding: 10px;color: #000;border-radius: 5px;overflow: hidden;float: left;margin-top: 16px;}
.primary-btn-loader {margin-top: 15px !important;padding: 10px 30px !important;border-radius: 6px !important;float: left !important;width: 125px !important;}
</style>