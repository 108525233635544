<template>
    <ul class="recent-accounts _propscrl">
        <li v-for="account in Accounts" :key="account" @click="ClickHandler (account)">
            <div class="image">
                <UserImage :init="account.fullname" :source="account.avatar" :back="account.back" />
            </div>
            <div class="acc-dtls">
                <h3>{{ account.fullname }}
                    <span v-if="account.verified == 'true'" style="display: inline;float: none;">
                        <img style="width: 20px;" src="./../../images/icons/nverified.svg" alt="Property Verified">
                    </span>
                </h3>
                <p>{{ account.email }}</p>
            </div>
        </li>
    </ul>
</template>

<script>    
    // components
    import UserImage from './../blocks/UserImage.vue';
    export default {
        name: 'DashboardAccounts',
        props: ['accounts'],
        emits: ['choose'],
        components: {
            UserImage
        },

        data () {
            return {
                Accounts: []
            }
        },

        mounted () {
            this.Accounts = this.accounts.filter (account => {
                if (account.verified == "true") return account;
            });
        },
        
        methods: {
            ClickHandler (owner) {
                this.$emit('choose', owner);
            }
        }
    };
</script>