<template>
    <li :class="task.active ? 'active' : ''">
        <div class="header" @click="TaskClicked">
            <div class="title">
                <img src="../../../images/icons/icon-task.svg" />
                <h3>{{task.title}}</h3>
                <p v-if="task.expiry == 'Expired'" style="color: #F00">{{task.expiry}}</p>
                <p v-else style="color: hsl(120deg 34% 32%)">{{task.expiry_date}} ({{task.expiry}})</p>
            </div>

            <ul class="actions">
                <li v-if="task.priority == 'Critical'" class="priorityaction critical"><img src="../../../images/icons/critical.svg" /> <p>{{task.priority}}</p></li>
                <li v-if="task.priority == 'High'" class="priorityaction high"><img src="../../../images/icons/high.svg" /> <p>{{task.priority}}</p></li>
                <li v-if="task.priority == 'Normal'" class="priorityaction normal"><img src="../../../images/icons/normal.svg" /> <p>{{task.priority}}</p></li>
                <li v-if="task.priority == 'Low'" class="priorityaction low"><img src="../../../images/icons/low.svg" /> <p>{{task.priority}}</p></li>
            </ul>
        </div>

        <div class="content">
            <p class="psanim-2 fadeInUp">{{task.content}}</p>

            <div class="task-us psanim-3 fadeInUp">
                <div class="tasus-user" v-if="AssignedTask">
                    <div class="image">
                        <UserImage :init="task.assignto.fullname" :back="task.assignto.back" :source="task.assignto.avatar" />
                    </div>
                    <div class="content">
                        <h3>{{task.assignto.fullname}}</h3>
                        <p>{{task.assignto.email}}</p>
                    </div>
                </div>

                <div class="tasus-unassign" v-else>
                    <p>Please edit to re-assign task.</p>
                </div>

                <ul class="task-actions">
                    <li v-if="task.status == 'Open'" @click="EditTask"><img src="../../../images/icons/edit-task.svg" /></li>
                    <li @click="DeleteTask"><img src="../../../images/icons/delete-task.svg" /></li>
                    <li v-if="task.status == 'Open'" @click="CompleteTask"><img src="../../../images/icons/complete-task.svg" /></li>
                </ul>
            </div>
        </div>
    </li>
</template>

<script>
    import UserImage from './../../blocks/UserImage.vue';

    export default {
        name: 'TaskList',
        props: ['task',  'count'],
        emits: ['taskchange', 'taskdelete', 'taskedit', 'completetask'],
        components: {
            UserImage
        },

        data () {
            return {
                AssignedTask: false,
            }
        },

        mounted () {
            if (this.task.assignto.length <= 0) {
                this.AssignedTask = false;
            }else {
                this.AssignedTask = true;
            }
        },
        
        methods : {
            TaskClicked () {
                this.$emit('taskchange', this.task);
            },
            DeleteTask () {
                this.$emit('taskdelete', this.task);
            },
            CompleteTask () {
                this.$emit('completetask', this.task);
            },
            EditTask () {
                this.$emit('taskedit', this.task);
            }
        }
    };
</script>