import { defineStore } from "pinia";

export const useAdminStore = defineStore ({
    id: 'admin',

    // reactive state owner data
    state: () => ({

        user: {},
        userRoutes: {},

        route : {
            page: 'Dashboard',
            params: null,
        },

        dashboard: false,
        roles: [],
        owners: [],
        properties: [],
        

    }),

    // methods
    actions: {

        ResetUser (user) {
            this.user = user;
        },

        ResetRoutes (routes) {
            this.userRoutes = routes;
        },

        ResetDashboard (dash) {
            this.dashboard = dash;
        },

        ResetRoles (roles) {
            this.roles = roles;
        },

        ResetOwners (owners) {
            this.owners = owners;
        },

        ResetProperties (properties) {
            this.properties = properties;
        },

    }
});