<template>
    <div class="property">
        <div class="image">
            <ReplaceImage :source="unit.thumbnail" />
        </div>

        <div class="content">
            <h3>{{unit.unit_name}}</h3>
            <p v-if="unit.status == 'rented'">{{unit.tenant.fullname}}</p>
            <p v-else style="color: #919191;font-size: 9pt;line-height: 16pt;">unit is not rented yet.</p>

            <p v-if="!unit.floorplan">(floor plan not assigned yet)</p>
        </div>
    </div>
</template>

<script>
    import ReplaceImage from './ReplaceImage.vue';

    export default {
        name: 'UnitSelected',
        props: ['unit'],
        components: {
            ReplaceImage,
        }
    };
</script>