<template>
    <div class="prop-row" v-if="parseInt(unit.floor) == floor && !unit.deleted" :class="count % 2 ? 'odd' : 'even'" style="overflow: unset; float: left;">
        <div class="column column35">
            <div class="unit--tenant">
                <div class="image">
                    <ReplaceImage :source="unit.thumbnail" />
                </div>

                <div class="content">
                    <h3>{{unit.unit_name}}</h3>
                    <p v-if="unit.status == 'rented'">{{unit.tenant.fullname}}</p>
                    <p v-else style="color: #919191;font-size: 9pt;line-height: 16pt;">unit is not rented yet.</p>
                </div>
            </div>
        </div>

        <div class="column column25">
            <div class="unit--status">
                <div v-if="unit.status == 'rented'" class="status secondary">Occupied</div>
                <div v-else class="status primary">Open</div>
                <p v-if="unit.status == 'rented'">{{ unit.status_date}}</p>
            </div>
        </div>

        <div class="column column25">
            <div class="floor-plan">
                <h3 v-if="!unit.floorplan" style="color: #ff5722;font-size: 10pt;font-weight: 400;letter-spacing: 0.15px;">Not Assigned</h3>
                <h3 v-else>{{ unit.floorplan.name}}</h3>
            </div>
        </div>

        <div class="column column10" style="position: relative;">
            <div class="unit-menu" @click="DeleteUnit (unit)" v-if="unit.status == 'blank'">
                <img src="../../../images/icons/delete.svg" />
            </div>
        </div>
    </div>
</template>

<script>
    //@import component
    import ReplaceImage from './../../blocks/ReplaceImage.vue';

    export default {
        name: 'ModifyUnitList',
        props: ['unit',  'count', 'floor'],
        emits: ['delete'],
        components: {
            ReplaceImage,
        },
        mounted () {
        },
        methods : {
            DeleteUnit (unit) {
                this.$emit('delete', unit);
            }
        }
    };
</script>