<template>
    <!-- Page Hero -->
    <section class="page-hero" :style="{'background-image':'url('+require('../../images/website/hero-bg.jpg')+')'}">
        <div class="wrapper" style="display: flex;justify-content: center;align-items: center;height: 100%;">
            <div class="content animated fadeInUp">
                <h1>THE ONLY PROPERTY MANAGEMENT TOOL YOU'LL EVER NEED</h1>
                <p>PROPSTOP is about to change the way property managers, and tenants, do business. PROPSTOP is the only FREE, full-spectrum property management tool that does what you need it to do: EVERYTHING. </p>
                <a href="#propstop-signup" class="btn-primary jtwlink">Join The Waitlist!</a>
            </div>
        </div>
    </section>
    <!-- Page Hero -->
</template>

<script>
    export default {
        name: 'IndexHero',
    };
</script>