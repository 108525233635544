<template>
    <div v-if="type=='select-list'" class="select-list sl-simple">
        <ul class="_propscrl">            
            <li class="transAe" :class="{ active : list.active}" v-for="list in ListData" :key="list.id" @click="ListSelect(list)">
                <div class="image">
                    <img class="select" src="../../images/icons/select-list.svg" />
                    <img class="selected" src="../../images/icons/select-check.svg" />
                </div>
                <div class="content">
                    <h3>{{ list.title }}</h3>
                </div>
            </li>
        </ul>
    </div>
    <div class="overlay" @click="closeList" style="background-color: transparent !important;"></div>
</template>

<script>
    export default {
        name: 'MultiSelectInput',
        props: ['type', 'lists'],
        emits: ['lstboxclse'],
        data () {
            return {
                ListData : this.lists,
            }
        },
        components: {

        },

        methods: {
            ListSelect (sl) {
                this.ListData = this.ListData.filter(list => {
                    // if list is already acitve
                    if (list.id == sl.id) {
                        if (sl.active) {
                            list.active = false;
                        }else {
                            list.active = true;
                        }
                    }
                    return list;
                });
            },

            closeList () {
                this.$emit('lstboxclse', this.ListData);
            }
        }
    };
</script>