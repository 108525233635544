<template>
    <div class="gs--body">
        <h3 style="margin-top: -30px; margin-bottom: -5px;">Select Unit Images</h3>

        <div class="prop-gallery _propscrl">
            <div v-if="GalleryLoadingState" style="margin-top:55px">
                <Skeleton :data="3" />
            </div>

            <div v-if="!GalleryLoadingState && PropertyGallery.length > 0" class="prop--table animated fadeInUp">
                <div class="prop-row row-header">
                    <div class="column column100">Uncategories Images</div>
                </div>
                
                <ul class="gallery animated fadeInUp" style="width: 100%;max-height: 350px;">
                    <div v-for="gallery in PropertyGallery" :key="gallery.key" :class="{ active : gallery.active}">
                        <li v-if="gallery.published == 'false'" @click="MultiSelectHandler (gallery)">
                            <div class="image">
                                <img :src="gallery.url">
                            </div>
                        </li>
                    </div>
                </ul>
            </div>

            <!-- If no images on gallery -->
            <div v-if="!GalleryLoadingState && PropertyGallery.length <= 0" class="empty-gallery">
                <img src="../../images/icons/no-image.png" />
                <p>No Images found on this Property</p>
            </div>
        </div>

    </div>

    <div class="unit-image-action">
        <span class="primary-btn-loader" @click="UpdateUnitImages" :class="Requesting ? 'btn-disabled' : ''" style="margin-top: 0px !important;margin-left: 70px;">
            <p v-if="!Requesting">Update Images</p>
            <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
        </span>
    </div>

</template>

<script>

    // import js
    import Request from './../../javascript/getRequest';
    import DataCenter from './../../javascript/DataCenter';

    import Skeleton from './../blocks/Skeleton.vue';


    export default {
        name: 'UnitImageSelect',
        props: ['data'],
        emits: ['close'],
        components: {
            Skeleton,
        },

        data () {
            return {
                Requesting: false,
                GalleryLoadingState: true,
                PropertyGallery: [],

                GallerySelection: [],

            }
        },

        async mounted () {
            console.log (this.data);

            // load gallery data from server
            let getdata = {
                key : 'property',
                value : this.data.property_id
            }
            
            const Gallery = await DataCenter.ProPRequest('property-gallery', false, getdata);
            this.GalleryLoadingState = false;
            if (!Gallery) {
            }else {
                this.PropertyGallery = Gallery;
            }

            console.log (this.PropertyGallery);
        },

        methods: {
            MultiSelectHandler (image) {
                this.PropertyGallery = this.PropertyGallery.filter(gallery => {
                    if (gallery.blobid == image.blobid) {
                        if (image.active) {
                            gallery.active = false;
                        }else {
                            gallery.active = true;
                        }
                    }
                    return gallery;
                });
            },

            async UpdateUnitImages () {
                if (this.Requesting) return;

                // update the images
                this.Requesting = true;
                let formData = new FormData();
                formData.append('property', this.data.property_id);
                formData.append('unit', this.data.id);

                this.GallerySelection = this.PropertyGallery.filter (gallery => {
                    if (gallery.active) {
                        formData.append('blobs[]', gallery.blobid);
                    }
                });

                // submit tasks data
                const ReturnData = await Request.postServer('owner/units/gallery-update.php', formData);
                this.Requesting = false;

                // now check if the email already exists
                if (ReturnData.error == false) {
                    // now close modal, and load current property
                    this.$emit('close', ReturnData.response);
                
                // if error
                }
            }
        }

    };

</script>

<style scoped>
    div.unit-image-action {    width: auto;
    height: 45px;
    padding-top: 20px;
    border-top: 1px solid #efefef;}

    div.unit-image-action div.btn-primary {float: left; border-radius: 4px;
    padding: 10px 25px; cursor: pointer;}
    div.unit-image-action div.btn-primary img {    float: left;}
    div.unit-image-action div.btn-primary p {    color: #fff;
    line-height: 15pt !important;}
</style>