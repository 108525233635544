<template>
    <div class="tinput">
        <p>{{label}} <strong v-if="required">*</strong></p>
        <input type="text" :placeholder="placeholder" @input="inputData ($event.target.value)" :value="value">
    </div>
</template>

<script>
    export default {
        name: 'TextInput',
        props: ['label', 'required', 'placeholder', 'value'],
        methods : {
            inputData (value) {
                this.$emit('inputvalue', value);
            }
        }
    };
</script>

<style scoped>
div.tinput {width: 100%;
height: auto;
overflow: hidden;padding-bottom: 10px;}
div.tinput p {    font-size: 11pt;
    font-weight: 500;
    line-height: 24pt;
    letter-spacing: 0.15px;
    position: relative;
    color: #000;}
div.tinput p strong {
color: red;
font-size: 12pt;
font-weight: 500;
line-height: 24pt;
letter-spacing: 0.25px;}
div.tinput input {    width: calc(100% - 22px);
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 3px;
    font-size: 12pt;
    color: #000;
    font-weight: 400;
    margin-bottom: 0;}

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(104, 104, 104);
  opacity: 1; /* Firefox */font-family: 'Montserrat', sans-serif;
  font-size: 11pt;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: red;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: red;
}
</style>