<template>
    <div v-if="!ImageLoad" class="imgcls" :data-loading-image="source" :style="{'background-image':'url('+require('../../images/icons/img-loading.svg')+')'}"></div>
    <div v-else-if="ImageError" class="imgcls" :data-error-image="source" :style="{'background-image':'url('+require('../../images/icons/img-error.svg')+')'}"></div>
    <img v-else :src="source" alt="PropStop" />
</template>

<script>    
    export default {
        name : 'ImageApp',
        props : ['source'],

        data () {
            return {
                Img : null,
                ImageLoad : false,
                ImageError : false
            }
        },

         mounted () {
            this.Img = new Image ();
            this.Img.addEventListener ('load', () => {
                this.ImageLoad = true;
            });

            this.Img.addEventListener ('error', () => {
                this.ImageLoad = true;
                this.ImageError = true;
            });this.Img.src = this.source;
        },
    }
</script>

<style scoped>
    .imgcls {background-color: rgb(0 0 0 / 7%); width: 100%; height: 100%; background-repeat: no-repeat; background-size: 35%; background-position: center;}
    .name-gradient {width: 100%; height: 100%;}
</style>