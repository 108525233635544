<template>
  <!-- Owner Employees Page -->
  <div class="owner-employees">
      <div class="sidebar" :style="SidebarHamClick ? 'left: 0px !important;box-shadow: rgb(0 0 0 / 22%) 0px 7px 10px !important;' : ''">

          <ul class="employees-menu">
              <li v-for="role in EmployeeRoleList" :key="role" :class="{active : role.active}" @click="employeeFilterListHandler(role)">
                  <p>{{role.title}}</p>
              </li>
          </ul>

          <div class="btn-primary" @click="AddNewEmployee">
              <img src="../../images/icons/addbtn-icon.svg" />
              <p>Add Emlpoyee</p>
          </div>
      </div>

      <div class="proprty-ham-menu" style="margin-left: 15px;" v-if="!EmployeeSelected" @click="SidebarHamClick = true">
        <img src="./../../images/icons/line-menu.svg" />
        <p>Employees Filter</p>
      </div>

        <!-- Skeleton -->
        <div v-if="!OwnerEmployeesList && !EmployeeSelected" class="employees-content _propscrl" style="margin-top:5px">
            <Skeletion :data="4" />
        </div>

        <!-- Employee List -->
        <div v-if="!EmployeeSelected && OwnerEmployeesList" class="employees-content _propscrl animated fadeInUp">
            <OwnerEmployees :property="currentProperty" 
                            :employees="OwnerEmployeesList" 
                            :key="filterChanges" 
                            :filter="EmployeeFilter"
                            :properties="properties"
                            
                            @detail="showEmployeeDetail" 
                            @delete="EmployeeDelete" 
                            @task="EmployeeAssignTask" 
                            @ticket="EmployeeAssignTicket" />
        </div>

        <!-- Employee Detail -->
        <OwnerEmployeeDetail v-if="EmployeeSelected" 
                                :employee="EmployeeChoosed"
                                :properties="properties"
                                :fullscreen="false"
                                @assigntask="EmployeeAssignTask"
                                @assignticket="EmployeeAssignTicket"
                                @backtolist="BackbtnClicked" />

        <div v-if="OwnerHasNoProperty" class="empty-result animated fadeInUp">
            <img src="./../../images/icons/on-verification.svg">
            <p>It looks like you haven't added a property yet.<br>Please add a property to view all features.</p>
            <div class="btn-primary bphasno" @click="RouteToAddProperty">
                <p>Property Page</p>
            </div>
        </div>

  </div>
  <!-- Owner Employees Page -->

  <!-- Modals & Dialogs --> 
  <RightModal v-if="AddNewEmployeeModal == true" :form="'add-employee'" @modalclose="CloseModalBox" :data="PassFormData" />
  <RightModal v-if="AddNewTicketModal" :form="'add-ticket'" :data="AddTicketData" @modalclose="CloseTicketModalBox" />
  <RightModal v-if="AddNewTaskModal" :form="'assign-tasks'" :data="AssignTasksData" @modalclose="CloseTaskModalBox" />

  <ConfirmModal v-if="ConfirmDialog == true" @modalclose="CloseConformModal" @delemployee="ConfirmEmployeeDelete" :data="ConfirmFormData" />

</template>

<script>

    //@ JS Modules
    import Document from './../../javascript/Document';
    import Locale from './../../javascript/Locale';
    import Request from './../../javascript/getRequest';
    import DataCenter from './../../javascript/DataCenter';

    import Storage from './../../javascript/Storage';

    import Skeletion from './../../components/blocks/Skeleton.vue';
    import HtmlImage from './../../components/blocks/HtmlImage.vue';
    import ReplaceImage from '../../components/blocks/ReplaceImage.vue';
    import PropertyList from '../../components/blocks/PropertyList.vue';

    // current page components
    import OwnerEmployees from './../../components/owner/OwnerEmployees.vue';
    import OwnerEmployeeDetail from './../../components/owner/OwnerEmployeeDetail.vue';

    // Modal
    import RightModal from './../../components/modal/RightModal.vue';
    import ConfirmModal from './../../components/modal/ConfirmModal.vue';

    export default {
        
        name: 'Employees',
        props: ['profile', 'properties', 'employees', 'selected'],
        emits: ['updateproperties'],
        
        components: {
            Skeletion,
            HtmlImage,
            ReplaceImage,
            PropertyList,

            OwnerEmployees,
            OwnerEmployeeDetail,

            RightModal,
            ConfirmModal
        },

        data () {
            return {
                // required variables
                EmployeeSelected : false,
                
                // catch filter click and changes
                EmployeeFilter : 'All',
                filterChanges : null,

                // tenants list for current owner & for all properties
                OwnerEmployeesList : this.employees,
                EmployeeChoosed : false,

                // page modal settings
                AddNewEmployeeModal : false,
                ConfirmDialog : false,

                // employe filter/role menu  
                EmployeeRoleList : false,

                // pass data to forms
                PassFormData : null,
                ConfirmFormData : null,

                // property list for each tenants
                OwnerHasNoProperty : true,
                OwnerProperties : false,
                currentProperty : [],
                currentPropertyImageKey : 'current',
                isPropertySelection : false,

                // ticket and tasks assign
                AddNewTicketModal : false,
                AddTicketData : [],
                AddNewTaskModal : false,
                AssignTasksData : [],

                // responsive
                SidebarHamClick: false,
            }
        },

        async mounted () {
            // hide the properties selection from here
            var OwnPrty = document.getElementById('ownerprtities');
            if (OwnPrty != null || OwnPrty != undefined) {
                OwnPrty.style.visibility = 'hidden';
                OwnPrty.style.opacity = '0';
            }

            // site title and seo
            Document.DocumentHeadings ({title : 'Employees - PropStop'});

            // role menu
            this.EmployeeRoleList = Locale.EmployeeRoles();

            // property list of current owner
            this.OwnerProperties = this.properties;
            this.currentProperty = this.selected;

            if (this.OwnerProperties.length >= 1) {
                this.OwnerHasNoProperty = false;
                this.OwnerEmployeesList = this.employees;
                this.filterChanges = 'All';
            }

            // global add action
            var AddAction = document.getElementById('AppMainAddActionIcon');
            AddAction.onclick = () => {
              this.AddNewEmployee ();
            };
            
        },

        methods : {
            
            // no properties now route to add property
            RouteToAddProperty () {
                this.$router.push({name : 'owner-properties'});
            },

            // property selected
            propertySelected (property) {
                this.currentProperty = property;
                this.filterChanges = this.currentProperty.id;

                // show the overview page
                this.currentPropertyImageKey = property.id;

                // now close the list too
                this.closeList ();
            },

            // close property list
            closeList () {
                this.isPropertySelection = false;
            },

            // show employee detail page
            showEmployeeDetail (employee) {
                this.EmployeeSelected = true;
                this.EmployeeChoosed = employee;
            },

            // edit the target employee
            EmployeeAssignTask (employee) {
                this.AssignTasksData = {
                    assign : true,
                    assignToData : employee,
                    property : this.currentProperty,
                    owner : this.ownerProfile,
                };

                this.AddNewTaskModal = true;
            },

            CloseTaskModalBox (task) {
                this.AddNewTaskModal = false;
            },

            EmployeeAssignTicket (employee) {
                this.AddTicketData = {
                    property : this.currentProperty,
                    employee : true,
                    employeeData : employee
                };
                this.AddNewTicketModal = true;
            },

            CloseTicketModalBox (tickets) {
                if (tickets) {
                    // update the Storage
                    Storage.deleteCache('ticket-list');
                    Storage.storeCache('ticket-list', tickets);
                }

                this.AddNewTicketModal = false;
            },

            // delete clicked employee
            EmployeeDelete (employee) {
                this.ConfirmDialog = true;
                this.ConfirmFormData = employee;
            },

            // confirm employee delete
            async ConfirmEmployeeDelete (employee) {                
                // delete from database
                let formData = new FormData();
                formData.append('userid', employee.id);
                await Request.postServer ('employees/delete.php', formData);
                
                // remove from list
                this.OwnerEmployeesList = this.OwnerEmployeesList.filter (emp => {
                    if (employee.id == emp.id) {} else {return emp;}
                });

                // update the Storage
                Storage.deleteCache('employees-list');
                Storage.storeCache('employees-list', this.OwnerEmployeesList);

                // finally close the modal
                this.ConfirmDialog = false;
                this.filterChanges = 'delete-employee';
            },

            // employee role or filter click handler
            employeeFilterListHandler (role) {
                // clicked role active
                this.EmployeeRoleList = this.EmployeeRoleList.filter (emprle => {
                    emprle.active = false;
                    if (role.id == emprle.id) emprle.active = true;
                    return emprle;
                });

                // now set the new filter selection
                this.EmployeeFilter = role.title;
                this.filterChanges = role.title;

                // remove siderbar menu
                this.SidebarHamClick = false;
            },

            // Add New Employee Form/Modal
            AddNewEmployee () {
                // set data to pass to forms
                this.PassFormData = {
                    property : this.currentProperty,
                    properties : this.properties,
                }

                // now load the right modal for new employee additions
                this.AddNewEmployeeModal = true;
            },

            // close modal box
            CloseModalBox (employee) {
                 if (employee) {
                    if (!this.OwnerEmployeesList) {
                        this.OwnerEmployeesList = [];
                        this.OwnerEmployeesList[0] = employee;
                    }else {
                        this.OwnerEmployeesList.push (employee);
                    }

                    // update data
                    this.EmployeeSelected = true;
                    this.EmployeeChoosed = employee;

                    // update the Storage
                    Storage.deleteCache('employees-list');
                    Storage.storeCache('employees-list', this.PropertyEmployees);  

                    // filter employees
                    this.filterChanges = Math.round(+new Date()/1000);;
                    this.employeeFilterListHandler (this.EmployeeRoleList[0]);
                }

                // close the modal
                this.AddNewEmployeeModal = false;
                
            },

            CloseConformModal () {
                this.ConfirmDialog = false;
            },

            // get back from tenant detail page 
            BackbtnClicked () {
                this.EmployeeSelected = false;
                this.EmployeeChoosed = false;
            },

        }
}
</script>