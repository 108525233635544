<template>
    <div class="content">
        <ul class="tasksum">
            <li>
                <p>Open Tasks ({{open}})</p>
                <div class="taskper">
                    <div class="task-percentage transAe" :style="'width: '+opentaskpercent+'%;'"></div>
                </div>            
            </li>
            <li>
                <p>Completed Tasks ({{completed}})</p>
                <div class="taskper">
                    <div class="task-percentage transAe" :style="'width: '+completedpercent+'%;'"></div>
                </div>            
            </li>
            <li>
                <p>Total Tasks ({{total}})</p>
                <div class="taskper">
                    <div class="task-percentage transAe" :style="'width: '+totaltaskpercent+'%;'"></div>
                </div>            
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'Tasks',
        props: ['data'],
        
        data () {
            return {
                open : 0,
                completed : 0,
                total : 0,

                // percentage
                opentaskpercent : 0,
                completedpercent : 0,
                totaltaskpercent : 0,

            }
        },

        mounted () {
            this.open = this.data.open;
            this.completed = this.data.completed;
            this.total = this.data.total;

            this.opentaskpercent = (this.open/this.total) * 100;
            this.completedpercent = (this.completed/this.total) * 100;
            this.totaltaskpercent = (this.total/this.total) * 100;
        }
    };
</script>