<template>
      <div class="cookie" v-if="ShowCookie">
            <img src="./../../images/icons/cookie.png" />
            <h1>We use cookies</h1>
            <p>Please, accept cookies to continue enjoying our website!</p>
            <span class="primary-btn-loader" style="width: 120px; margin: 12px 0;" @click="UpdateCookies">
                  <p>Accept</p>
            </span>

            <p class="manage" @click="ManagePreferences">Manage preferences</p>
      </div>

      <RightModel v-if="ShowPreferences" :form="'cookie-preferences'" :data="null" @modalclose="CloseModal" />
</template>

<script>

      import Cookie from '../../javascript/Cookie';
      import RightModel from './../modal/RightModal.vue';

      export default {
        name: 'Cookie',
        components: {
            RightModel,
        },

        data () {
            return {
                  ShowCookie: this.CheckCookies(),
                  ShowPreferences: false,
            }
        },

        mounted () {
           
        },

        methods : {

            CheckCookies () {
                  let UserCookie = Cookie.getCookie('ps-cookie');
                  if (UserCookie == 'accept') return false;
                  else return true;
            },

            UpdateCookies () {
                  Cookie.setCookie ('ps-cookie', 'accept', 90);
                  this.ShowCookie = false;
            },

            CloseModal () {
                  this.ShowPreferences = false;
            },

            ManagePreferences () {
                  this.ShowPreferences = true;
            }

        }

      }

</script>

<style>
      div.cookie-preferences {
            z-index: 999999999 !important;
      }
</style>

<style scoped>

      

      div.cookie {
            display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    width: 250px;
    padding: 25px;
    position: fixed;
    z-index: 999999999;
    bottom: 25px;
    right: 18px;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 13%);
      }
      div.cookie img {
            width: 85px;
    height: 85px;
    object-fit: contain;
    margin-bottom: 15px;
      }
      div.cookie h1 {
            color: black;
    line-height: 24pt;
    font-size: 18pt;
      }
      div.cookie p {
            text-align: center;
    margin: 11px 0;
    color: black;
    line-height: 14pt;
      }
      div.cookie p.manage {
            font-size: 10pt;
    color: #2196F3;
    cursor: pointer;
    text-decoration: underline;
      }

</style>