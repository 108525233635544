<template>
  Reports
</template>

<script>

  // @ is an alias to /src
  import Document from '../../javascript/Document';

  export default {
    name: 'Reports',
    components: {
    },

    mounted () {
      // show back the properties selection from here
      var OwnPrty = document.getElementById('ownerprtities');
      if (OwnPrty != null || OwnPrty != undefined) {
          OwnPrty.style.visibility = 'visible';
          OwnPrty.style.opacity = '1';
      }
      
      // site title and seo
      Document.DocumentHeadings ({title : 'Reports - PropStop'});
    }
  }
</script>