<template>
    <div class="prop-form _propscrl">
        <div class="form-area" style="padding-bottom: 65px;">
            
            <form name="propform" v-if="formProcess == 1" id="propform-asnunit1" style="display: block;">
                <div class="pf--title">
                    <h3>Assign Unit</h3>
                    <p>Assign new unit to tenant, select your agreement type and monthly price for the selected unit.</p>
                </div>

                <div v-if="!CurrentUnit" class="pf--multi-input" style="height:85px;">
                    <div class="half hleft">
                        <div class="pf--input" style="overflow:unset; position:relative;">
                            <p>Choose Property</p>
                            <input v-model="SelProperty" type="text" placeholder="Choose One" id="Properties" data-type="len" data-max="5" readonly @click="PropertySingleSelect = true" :disabled="Requesting" />
                            <PropertyList v-if="PropertySingleSelect" :distyle="'top: 90px !important;bottom: unset !important;left: 195px !important;'" :properties="OwnerProperties" @prosel="PropertySelected" @proboxclse="CloseList" />
                        </div>
                    </div>

                    <div class="half hright">
                        <div class="pf--input" style="overflow:unset; position:relative;">
                            <p>Choose Unit</p>
                            <input v-model="SelUnit" type="text" placeholder="Choose One" id="Units" data-type="len" data-max="5" readonly @click="UnitsSingleSelect = true"  :disabled="Requesting || !SelProperty"  />
                            <UnitSelection v-if="UnitsSingleSelect == true" :units="PropertyUnits" :distyle="'top: 0px;left: -35px;'" @lstboxclse="UnitSelected" />
                        </div>
                    </div>                    
                </div>

                <div v-if="!CurrentTenant" class="pf--input" style="height: 75px;overflow:unset; position:relative; margin-top: 45px;">
                    <p>Choose Tenant</p>
                    <input v-model="SelTenantName" type="text" placeholder="Choose One" id="Tenants" data-type="len" data-max="5" readonly @click="TenantSingleSelect = true" :disabled="Requesting" />
                    <TenantSelection v-if="TenantSingleSelect == true" :lists="PropertyTenants" @lstboxclse="TenantSelected" />
                </div>

                <div class="pf--input" style="overflow:hidden;">
                    <p>Agreement Type</p>
                    <div class="pets-select">
                        <ul>
                            <li :class="{active : LeaseAgreement}" @click="AgreementHandler('lease')">
                                <div class="image">
                                    <img class="select" src="../../images/icons/select-list.svg" />
                                    <img class="selected" src="../../images/icons/select-check.svg" />
                                </div>
                                <div class="content">
                                    <p>Lease</p>
                                </div>
                            </li>

                            <li :class="{active : MonthAgreement}" @click="AgreementHandler('month')">
                                <div class="image">
                                    <img class="select" src="../../images/icons/select-list.svg" />
                                    <img class="selected" src="../../images/icons/select-check.svg" />
                                </div>
                                <div class="content">
                                    <p>Monthly</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <!-- ammount and payment type -->
                <div class="pf--multi-input" style="height: 84px;">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Start Date</p>
                            <Datepicker class="dp__input_custom" style="width: 100% !important;" v-model="StartDate" :enableTimePicker="false" autoApply required placeholder="Pick Date" :disabled="Requesting" />
                        </div>
                    </div>

                    <div class="half hright" v-if="LeaseAgreement == true">
                        <div class="pf--input">
                            <p>End Date</p>
                            <Datepicker class="dp__input_custom" v-model="EndDate" :enableTimePicker="false" autoApply placeholder="Pick Date" :disabled="Requesting" />
                        </div>
                    </div>
                </div>

                <!-- rental types -->
                <div class="pf--input">
                    <p>Rental Type</p>
                    <select v-model="RentalType" id="RentalType" data-type="select">
                        <option value="-1">Select One</option>
                        <option value="short">Short Term</option>
                        <option value="long">Long Term</option>
                    </select>
                </div>

                <!-- description of the transaction -->
                <div class="pf--input">
                    <p>Monthly Rent</p>
                    <input v-model="MonthlyRent" type="number" placeholder="1200.00" id="MonthlyRent" data-type="len" data-max="2" :disabled="Requesting" />
                </div>


            </form>

            <form name="propform" v-if="formProcess == 2" id="propform-asnunit2" style="display: block;">
                <div class="pf--title">
                    <h3>Initial Deposits</h3>
                    <p>Assign new unit to tenant, Enter the initial deposits and other deposits</p>
                </div>

                <!-- Deposit Tracking -->
                <div class="pf--multi-input" style="height: 84px;">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Deposit Amount</p>
                            <input v-model="DepositAmount" type="number" placeholder="1800.00" id="DepositAmount" data-type="len" data-max="2" :disabled="Requesting" />
                        </div>
                    </div>

                    <div class="half hright">
                        <div class="pf--input">
                            <p>Deposit Date</p>
                            <Datepicker class="dp__input_custom" v-model="DepositDate" :enableTimePicker="false" autoApply placeholder="Pick date.." :disabled="Requesting" />
                        </div>
                    </div>
                </div>

                <div class="pf--multi-input" style="height: 84px;">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Deposit Payment Type</p>
                            <select v-model="PaymentType" id="PaymentType" data-type="select" :disabled="Requesting">
                                <option value="-1">- Select One -</option>
                                <option value="Check">Check</option>
                                <option value="Credit Card">Credit Card</option>
                                <option value="Money Order">Money Order</option>
                                <option value="venmo">Venmo</option>
                                <option value="zelle">Zelle</option>
                            </select>
                        </div>
                    </div>

                    <div class="half hright">
                        <div class="pf--input">
                            <p>Deposit Notes</p>
                            <input v-model="DepositNote" type="text" placeholder="Deposit notes" id="DepositNote" data-type="len" data-max="0" :disabled="Requesting" />
                        </div>
                    </div>
                </div>

            </form>

        </div>

        <div class="form-action">
            <div class="form-area">
                <div v-if="formProcess != 1" class="btn-secondary process-back" @click="getBackToFirstPage"><img src="./../../images/icons/arrow-left-blue.svg" /></div>
                <span style="float: left;margin-top: 10px;" class="primary-btn-loader" v-if="formProcess == 1" :class="Requesting ? 'btn-disabled' : ''" @click="ValidateFirstPage">
                    <p v-if="!Requesting">Next Step</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>
                <span style="float: left;margin-top: 10px;"  class="primary-btn-loader" v-if="formProcess == 2" :class="Requesting ? 'btn-disabled' : ''" @click="AssignTenant">
                    <p v-if="!Requesting">Assign Tenant</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>
                <span v-if="formError" class="__formerror">{{ infoText }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    
    //@ JS Modules
    import Request from './../../javascript/getRequest';
    import DataCenter from './../../javascript/DataCenter';

    import FormValidation from './../../javascript/FormValidation';

    // @component
    import PropertyList from '../blocks/PropertyList.vue';
    import UnitSelection from './../web-forms/UnitSelection.vue';
    import TenantSelection from './../web-forms/TenantSingleSelectInput.vue';

    // datepicer
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    export default {
        name: 'AssignUnit',
        props: ['data'],
        emits: ['close'],
        components: {
            PropertyList,
            UnitSelection,
            TenantSelection,
            Datepicker,
        },

        data() {
            return {
                // Form Modal
                LeaseAgreement : false,
                MonthAgreement : false,   

                StartDate : null,
                EndDate : null,
                RentalType : '-1',
                MonthlyRent: '',

                Requesting: true,

                PropertyName: null,
                CurrentUnit: false,
                CurrentTenant: false,

                // page settings
                formProcess : 1,

                // Properties list
                hasToSelectProperty : false,
                SelProperty: '',
                OwnerProperties : this.data.properties,
                SelectedProperty : false,
                SelectProperty : false,
                PropertySingleSelect: false,

                // units
                SelUnit : '',
                SelUnitID : null,
                SelUnitTenantID: null,
                PropertyUnits : false,
                UnitsSingleSelect : false,
                SelectedUnit : null,

                // tenants
                TenantSingleSelect: false,
                SelTenantName: '',
                SelectedTenant: {},
                SelectedTenantID: null,
                PropertyTenants: null,

                // deposit tracking
                DepositAmount: '',
                DepositDate: null,
                PaymentType: '-1',
                DepositNote: '',

                // form error
                formError : false,
                infoText : '',
            }
        },

        async mounted () {

            console.log (this.data);

            // property id
            this.PropertyName = this.data.property;

            // tenant or user id
            this.CurrentTenant = this.data.tenant;

            // unit id or unit detail
            this.CurrentUnit = this.data.unit;

            // load all the units of current property
            // also filter which are not rented yet
            if (!this.CurrentUnit) {
                this.loadSelectedPropertyUnits (this.PropertyName);
            }else {
                this.SelUnitID = this.CurrentUnit;
            }

            // load all the tenant of current property
            // also filter which are already living on the property
            if (!this.CurrentTenant) {
                this.loadSelectedPropertyTenants (this.PropertyName);
            }else {
                this.SelectedTenantID = this.CurrentTenant;
            }

        },

        methods: {

            // auto validate the number field for rent and other
            RefineNumberField () {

            },

            getBackToFirstPage () {
                this.formProcess--;             
            },

            // agreemnt radio handler
            AgreementHandler (type) {

                if (type == 'lease') {
                    this.LeaseAgreement = !this.LeaseAgreement;
                    this.MonthAgreement = false;
                }else {
                    this.MonthAgreement = !this.MonthAgreement;
                    this.LeaseAgreement = false;
                }

            },            

            // load blank units on the selected properties
            async loadSelectedPropertyUnits (propertyid) {
                // new selection
                this.SelectedUnit = null;
                this.PropertyUnits = null;

                // load units data from server
                let getdata = {
                    key : 'property',
                    value : propertyid
                }
                
                const Units = await DataCenter.ProPRequest('property-units', false, getdata);
                this.Requesting = false;
                
                // now filter units which only have assigned tenants
                if (!Units) {}else {
                    this.PropertyUnits = Units;

                    // filter only those unit which is not assgned yet
                    this.PropertyUnits = this.PropertyUnits.filter (unit => {
                        if (unit.status != 'rented') return unit;
                    });
                }            
            },

            // load all the property tenants
            async loadSelectedPropertyTenants (property) {

                // load units data from server
                let getdata = {
                    key : 'property',
                    value : property
                }

                // const Tenants = await DataCenter.ProPRequest('property-tenants-list', false, getdata);
                const Tenants = await DataCenter.ProPRequest('owner-tenants-list', false, false);
                this.Requesting = false;

                // now filter tenant which are not assigned yet
                if (!Tenants) {}else {
                    this.PropertyTenants = Tenants;
                }   

            },

            // once tenatn is selected from single select
            TenantSelected (tenant) {
                if (tenant) {
                    this.SelTenantName = tenant.fullname;
                    this.SelectedTenant = tenant;
                    this.SelectedTenantID = this.SelectedTenant.id;
                }

                // close box
                this.TenantSingleSelect = false;
            },

            // once unit is selected from single select
            UnitSelected (unit) {
                if (unit !== null) {
                    this.SelUnit = unit.unit_name;
                    this.SelUnitID = unit.id;
                    this.SelUnitTenantID = unit.tenant.id
                }

                this.UnitsSingleSelect = false;                
            },
            
            PropertySelected (property) {
                
                if (property !== null) {
                    // remove the selected unit
                    this.SelUnit = '';

                    this.SelProperty = property.name;
                    this.SelectedProperty = property;

                    this.Requesting = true;
                    this.loadSelectedPropertyUnits (property.id);
                }

                this.PropertySingleSelect = false;
            },  

            CloseList () {
                this.PropertySingleSelect = false;
            },


            // validate the first form page
            ValidateFirstPage () {

                let PickerAhead = true;

                // calendar validation
                if (this.StartDate == null) {
                    PickerAhead = false;
                    alert ('Please pick rental start date!');
                }

                // enddate validation
                if (this.LeaseAgreement) {
                    if (this.EndDate == null) {
                        PickerAhead = false;                        
                        alert ('Please pick rental end date!');
                    }
                }

                // form & validation
                let form = document.getElementById('propform-asnunit1');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead && PickerAhead) {
                    this.formError = false;
                    this.formProcess++;
                }

            },

            // add new transaction
            async AssignTenant () {
                if (this.Requesting) return;
                this.formError = false;
                let PickerAhead = true;

                // calendar validation
                if (this.DepositDate == null) {
                    PickerAhead = false;
                    alert ('Please pick rental start date!');
                }
                

                // form & validation
                let form = document.getElementById('propform-asnunit2');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead && PickerAhead) {
                    this.Requesting = true;

                    // collect form data
                    let formData = new FormData();
                    formData.append('property', this.PropertyName);
                    formData.append('unit', this.SelUnitID);
                    formData.append('tenant', this.SelectedTenantID);

                    // agreement type
                    let Agreement = 'month';
                    if (this.LeaseAgreement == true) Agreement = 'lease';
                    formData.append('agreement', Agreement);

                    // start date timestamp
                    let StartDate = this.StartDate.getTime();
                    StartDate = StartDate/1000;
                    formData.append('startdate', StartDate);

                    // end date timestamp
                    let EndDate;
                    if (this.LeaseAgreement) {
                        EndDate = this.EndDate.getTime();     
                        
                    // if not lease then end date will be the next month
                    }else {
                        EndDate = new Date();
                        EndDate.setDate(this.StartDate.getDate() + 30);
                        EndDate = EndDate.getTime();
                    }

                    EndDate = EndDate/1000;
                    formData.append('enddate', EndDate);

                    // monthly rent
                    formData.append('rent', this.MonthlyRent);

                    // deposit tracking
                    formData.append('deposit', this.DepositAmount);
                    formData.append('paytype', this.PaymentType);
                    formData.append('depositnote', this.DepositNote); 
                    
                    // deposit date
                    var DepDate = this.DepositDate.getTime();   
                    DepDate = DepDate/1000; 
                    formData.append('depositdate', DepDate);

                    // upload and load data from server
                    const ReturnData = await Request.postServer('owner/tenant/assign.php', formData);
                    this.Requesting = false;

                    // now check if the email already exists
                    if (ReturnData.error == false) {
                        // now close modal, and load current property
                        this.$emit('close', ReturnData.response);

                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again.";
                    }                   

                }
            }
        },

    }
</script>