const Storage = window.localStorage;

const storeCache = (key, data) => {
    try {
        Storage.setItem(key, JSON.stringify(data));
    } catch (error) {
        return false;
    }
};

const getCache = (key) => {
    try {
        let cacheData = Storage.getItem(key);
        if (cacheData == null) return false;

        return JSON.parse(cacheData);
    } catch (error) {
        return false;
    }
};

const deleteCache = (key) => {
    try {
        Storage.removeItem(key);
        return true;
    } catch (error) {
        return false;
    }
};

export default {
    storeCache,
    getCache,
    deleteCache
}