const FormatMobileNumber = (number) => {
    var cleaned = ('' + number).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }return null;
};

const PropNotification = (text) => {
    let notiEl = document.querySelector('.propnotification');
    notiEl.style.display = 'block';
    notiEl.classList.add ('fadeInRight');

    // add text to the notification
    let p = notiEl.lastChild;
    p.innerHTML = text;

    // animate and remove notification
    setTimeout (() => {
        notiEl.classList.add('fadeOutUp');
        
        // remove the dislplay attribute
        setTimeout (() => {
            notiEl.style.display = 'none';
            notiEl.classList.remove('fadeInRight');
            notiEl.classList.remove('fadeOutUp');
        }, 500);

    }, 10000);

};

const ToolTipHandler = (todo, event) => {
    var timeout = 300;
    if (todo) timeout = 600;

    // wait for few seconds
    setTimeout (() => {
        var target = event.target;
        var ToolTip = document.getElementById('PropToolTip');

        if (todo) {
            var targetPosition = target.getBoundingClientRect();
            var targetWidth = target.offsetWidth;                
            var title = target.getAttribute('data-tip');

            // tooltip
            ToolTip.innerHTML = title;
            ToolTip.style.display = 'block';
            ToolTip.classList.add ('fadeIn');

            var ttWidth = ToolTip.offsetWidth;
            var ttHeight = ToolTip.offsetHeight;

            if (typeof event !== "undefined") {
                var x = event.clientX;
                var y = event.clientY;

                var targetX = targetPosition.left + (targetWidth / 2);

                var newposX = targetX - (ttWidth/2);
                var newposY = targetPosition.top - (ttHeight + 14);

                ToolTip.style.left = newposX + 'px';
                ToolTip.style.top = newposY + 'px';                       
            };

            // remove any way the tooltip later
            setTimeout (() =>{
                ToolTip.style.display = 'none';
            }, 3000);

        }else {
            ToolTip.style.display = 'none';
            ToolTip.classList.remove('fadeIn');
        }

    }, timeout);  

};

const AttachmentUploadHandler = (upload, extension, size) => {

    let filextion = upload.name;
    let fileextension = filextion.split('.').pop();
    let filename = filextion.split('.')[0];
    let filesize = upload.size;

    // required size of file
    let reqSize = size * 1024 * 1024;

    // extension matches
    let match = false;
    for (let ext of extension) {
        if (fileextension == ext)
            match = true;
    }

    // if not matches the scheme return false
    var isMatched = true;
    if (!match) isMatched = false;

    // if filesize is greater then required
    var isLarge = false;
    if (filesize > reqSize) isLarge = true;
    filesize = filesize/1024;
    filesize = filesize.toFixed(2);

    // modified date
    var timestamp = upload.lastModified;
    var miliseconds = timestamp * 1000;
    var dateobj = new Date(miliseconds);
    var humanDate = dateobj.toLocaleString(dateobj);

    var Image = null;
    if (fileextension == 'jpeg' || fileextension == 'jpg') {
        Image = URL.createObjectURL(upload);
    }

    return {
        name : filename,
        extension : fileextension,
        size : filesize,
        modified: humanDate,
        matched: isMatched,
        largesize: isLarge,
        image: Image,
    }
    
};


export default {
    FormatMobileNumber,
    PropNotification,
    ToolTipHandler,
    AttachmentUploadHandler,
};
