<template>
    <div class="internal--header" style="height: 75px;">
        <div class="summery fp-smry" v-if="from == 'root'" style="width: 100%;">
            <ul class="backtounitlist forroot">
                <li><img src="../../../images/employee-detail/arrow-left.svg" @click="BackToList"> <p>Back</p></li>
                <li style="flex-direction: column;">
                    <h3 style="font-size: 11pt;">{{ property.name }}</h3>
                    <p style="font-size: 10pt;line-height: 16pt;">{{ property.remarks }}</p>
                </li>
                <li>
                    <div class="actions fp-atns">
                        <!-- <div class="btn-secondary" @click="AddPaymentoUnit"><p>Add Payment</p></div> -->
                    </div>
                </li>
            </ul>           
        </div>

        <div v-else class="summery fp-summery">
            <ul class="propsummery">
                <li>
                    <p>Monthly Balance</p>
                    <h3>{{PropFinance.monthly.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            })}}</h3>
                </li>
 
                <li>
                    <p>YTD</p>
                    <h3>{{PropFinance.ytd.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            })}}</h3>
                </li>

                <li>
                    <p>Status</p>
                    <h3 v-if ="PropFinance.due == 0">All Current</h3>
                    <h3 style="color: #f00" v-else>{{PropFinance.due}} Past Due</h3>
                </li>
            </ul>
        </div>

        <div class="actions fp-atns" v-if="from == 'null'" style="display: none;">
            <div class="btn-secondary" @click="AddTransaction"><p>Add Transaction</p></div>
            <!-- <div class="btn-secondary" @click="AddPaymentoUnit"><p>Add Payment</p></div> -->
        </div>
    </div>

    <div class="prop--table">
        <div class="prop-row row-header" style="padding-bottom: 20px !important;">
            <div class="column column40"><p>Unit/Tenant</p></div>
            <div class="column column35"><p>Current Balance</p></div>
            <div class="column column25"><p>YTD</p></div>
        </div>

    <div class="prop-row odd" v-for="(trans, index) in FilteredTransList" :key="trans"  :class="index % 2 ? 'odd' : 'even'"  @click="TransactionClickHandler(trans)" style="display: flex;justify-content: center;align-items: center;overflow: unset; float: left; cursor:pointer;">
            <div class="column column40">
                <div v-if="trans.unit.hasUnit" class="fin-transaction" style="flex-direction: column;">
                    <h3>{{trans.unit.unit_name}}</h3>
                    <p>{{trans.tenant.fullname}}</p>
                </div>

                <div v-else class="fin-transaction" style="flex-direction: column;">
                    <h3>{{trans.unit.unit_name}}</h3>
                    <p style="color: red;">Unit not rented!</p>
                </div>
            </div>

            <div class="column column35">
                <div class="fin-transaction" v-if="trans.transaction.length == 0">
                    <h3 style="font-size: 14pt;">$0</h3>
                    <p>No transaction yet!</p>
                </div>

                <div v-else class="fin-transaction">
                    <h3 v-if="trans.transaction.balance == undefined">//</h3>
                    <h3 v-else-if="trans.transaction.balance < 0" style="font-size: 14pt;">
                        {{trans.transaction.balance.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            })}}
                    </h3>
                    <h3 v-else style="font-size: 14pt;">
                        {{trans.transaction.balance.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            })}}
                    </h3>
                    <!-- <p>{{trans.transaction.remarks}}</p> -->
                </div>
            </div>

            <div class="column column25">
                <div v-if="trans.unit.hasUnit" class="fin-paymethod">
                    <h3>{{trans.transaction.ytd.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            })}}</h3>
                </div>

                <div v-else class="fin-paymethod">
                    <h3>$0</h3>
                </div>
            </div>

            <div class="column column15" style="display: none; position: relative;">
                <div v-if="trans.unit.hasUnit" class="fin-paymethod">
                    <div class="unit-menu" data-name="hammenu">
                        <img src="../../../images/icons/ham-menu.svg" data-name="hammenu" />
                    </div>
                    <ul class="action-menu" v-if="trans.active == true">
                        <li data-name="closeham"><img src="./../../../images/icons/close.svg" data-name="closeham"/></li>
                        <li data-name="add-payment">Add Payment</li>
                        <!-- <li data-name="add-rent">Update Rent</li> -->
                    </ul>
                </div>

                <div v-else class="fin-paymethod"></div>
            </div>
        </div>  


    </div>
</template>

<script>

    // pinia storage
    import { useOwnerStore } from './../../../stores/OwnerStore';

    export default {
        name: 'Transactions',
        props: ['transactions', 'propfinance', 'property', 'filter', 'from'],
        emits: ['addpayment', 'addrent', 'transelected', 'back', 'addtrans', 'addunitpayment'],
        components: {

        },

        data() {
            return {
                FilteredTransList: [],
                PropFinance: this.propfinance,

                // pinia storage
                OwnerStore: useOwnerStore (),
            }
        },

        mounted () {
            // now filter the transactions 
            // according to the filter provided
            this.FilterTransactions ();

            // reset on the main page
            // finance payment data
            let FinanceData = this.OwnerStore.financepaymentdata;
            this.OwnerStore.financepaymentdata = {
                type: 'choose',
                isMainPage: true,
                tenant: false,
                property: true,
                unit: false,
                whichProperty: this.property,
                whichUnit: {},
                whichTenant: {}
            }

        },

        methods: {

            AddTransaction () {
                this.$emit('addtrans');
            },

            TransactionClickHandler (trans) {
                let target = event.target.getAttribute('data-name');
                
                // add new payment
                if (target == "add-payment") {
                    this.$emit('addpayment', trans);

                // want to add/payment rent
                }else if (target == "add-rent") {
                    this.$emit('addrent', trans);

                // want to see the menu
                }else if (target == "hammenu") {
                   trans.active = true;
                
                // want to close the ham menu
                }else if (target == "closeham") {
                    trans.active = false;

                // want unit detail page
                }else {
                    this.$emit('transelected', trans);
                }
            },

            AddPaymentoUnit () {
                this.$emit('addunitpayment', this.property);
            },

            FilterTransactions () {
                this.FilteredTransList = this.transactions.filter (transaction => {                    
                    // nothing to worry if need to load all the tickets 
                    if (this.filter == "all") {return transaction;}

                    if (this.filter == transaction.transtype) return transaction;                 
                });

                console.log (this.FilteredTransList);
            },

            ReadableTransactionType () {
                this.transactions.filter (transaction => {


                });
            },

            BackToList () {
                this.$emit('back');
            },
            
        },
}
</script>

<style scoped>

    ul.forroot {
        display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    }
    ul.forroot li {display: flex;}

    div.prop--table div.prop-row {
        padding: 15px 10px !important;
        margin-bottom: 0px !important;
    }
    div.internal--header {
        width: 100%;
        height: 60px;
        border-bottom: 1px solid #E5E5E5;
        overflow: hidden;
        margin-bottom: 20px;
    }

    div.internal--header div.summery {
        width: 50%;
        float: left;
    }
    div.internal--header div.actions {
        width: 50%;
        float: right;
    }

    ul.propsummery {    display: flex;
    flex-direction: row;
    justify-content: space-between;}
    /* ul.propsummery li {} */
    ul.propsummery li p {    font-size: 11pt;
    margin-bottom: 5px;}
    ul.propsummery li h3 {    font-size: 12pt;
    letter-spacing: 0.35px;}


</style>