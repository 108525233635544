<template>
    <!-- Header -->
    <header>
        <div class="wrapper" style="padding: 0; overflow: unset;">
            <div class="logopart">
                <router-link :to="{name : 'home-page'}">
                    <img src="../../images/website/web-logo.png" alt="PropStop" title="PropStop" />
                </router-link>
            </div>
            <ul class="nav bignav">
                <li><router-link :to="{name : 'home-page'}">Home</router-link></li>
                <li><router-link :to="{name : 'about-us'}">About Us</router-link></li>
                <li><router-link :to="{name : 'web-features'}">Features</router-link></li>
                <li><router-link :to="{name : 'web-tenants'}">Tenants</router-link></li>
                <li><router-link :to="{name : 'web-landlords'}">Landlords</router-link></li>
                <!-- <li><router-link :to="{name : 'explore'}">Properties</router-link></li> -->
                <li>
                    <router-link class="btn-secondary" :to="{name : 'Auth'}" style="color: #449ADF">Log In</router-link>
                    <router-link :to="{name : 'web-waitlist'}" class="btn-primary">Register</router-link>
                </li>
            </ul>

            <div class="respnav" @click="ShowResponsiveMenu = true">
                <img src="./../../images/icons/resp-ham.svg" alt="Responsive">
            </div>

        </div>
    </header>

    <div class="responsive-menu" v-if="ShowResponsiveMenu">
        <div class="respclose-btn" @click="ShowResponsiveMenu = false">
            <img src="./../../images/icons/close.svg" alt="Close">    
        </div>

        <div class="respwrapper">
            <div class="resplogo psanim-1 fadeInUp">
                <img src="../../images/website/web-logo.png" alt="PropStop" title="PropStop" />
            </div>

            <ul class="respmenu psanim-2 fadeInUp">
                <li v-for="resp in ResponsiveMenu" :key="resp.id" :class="resp.class" @click="MenuClicked (resp)"><span>{{ resp.name }}</span></li>
            </ul>
        </div>      

        
    </div>
    <!-- Header -->
</template>

<script>

    export default {
        name : 'WebNav',
        data () {
            return {
                ShowResponsiveMenu: false,
                ResponsiveMenu: [
                    {
                        id : 1,
                        name: 'Home',
                        slug: 'home-page',
                        class: ''
                    },
                    {
                        id : 2,
                        name: 'About Us',
                        slug: 'about-us',
                        class: ''
                    },
                    {
                        id : 3,
                        name: 'Features',
                        slug: 'web-features',
                        class: ''
                    },
                    {
                        id : 4,
                        name: 'Tenants',
                        slug: 'web-tenants',
                        class: ''
                    },
                    {
                        id : 5,
                        name: 'Landlords',
                        slug: 'web-landlords',
                        class: ''
                    },
                    {
                        id : 6,
                        name: 'Log In',
                        slug: 'Auth',
                        class: 'login'
                    },
                    {
                        id : 7,
                        name: 'Register',
                        slug: 'web-waitlist',
                        class: 'reg'
                    }
                ],
            }
        },

        mounted () {
            this.ShowResponsiveMenu = false;
        },

        methods: {
            MenuClicked (menu) {
                this.$router.push({
                    name: menu.slug,
                })
            },
        }
    };

</script>