<template>
    <div class="welcome-message">
        <img src="../../images/svg/move-out.svg" />
        <h3>Tenant Moveout!</h3>
        <p>Hello! Please check recent tasks/tickets to complete the tenant moveout process!</p>
        <span class="btn-primary" @click="CloseModal">Close</span>
    </div>
</template>

<script>
    export default {
        name : 'OwnerMoveOutInfo',
        emits: ['close'],
        methods: {
            CloseModal () {
                this.$emit('close');
            }
        }
    };
</script>