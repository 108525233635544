export const AdminNavigation = [
    {
        key  : 1,
        name : 'Dashboard',
        route : 'admin-dashboard',
        icon : 'images/icons/dashboard.svg',
        active : true
    },
    {
        key  : 2,
        name : 'Accounts',
        route : 'admin-accounts',
        icon : 'images/icons/tenants.svg',
        active : false
    },
    {
        key  : 3,
        name : 'Properties',
        route : 'admin-properties',
        icon : 'images/icons/properties.svg',
        active : false
    },
    {
        key  : 4,
        name : 'Teams',
        route : 'admin-team',
        icon : 'images/icons/teams.svg',
        active : false
    },
    {
        key  : 5,
        name : 'Dispute',
        route : 'admin-dispute',
        icon : 'images/icons/dispute.svg',
        active : false
    },
    {
        key  : 6,
        name : 'Features',
        route : 'admin-features',
        icon : 'images/icons/app_feature.svg',
        active : false
    },
    {
        key  : 7,
        name : 'Prod Owners',
        route : 'admin-prodowners',
        icon : 'images/icons/tenants.svg',
        active : false
    },
    {
        key  : 8,
        name : 'Messages',
        route : 'user-assistance',
        icon : 'images/icons/assistance.svg',
        active : false
    },
    {
        key  : 9,
        name : 'Communication',
        route : 'admin-communication',
        icon : 'images/icons/communication.svg',
        active : false
    }    
];