<template>
    <div class="propstop-readystate animated fadeInUp">
        <img src="../../images/icons/error.svg">
        <p>This section is not ready yet! under development!</p>
    </div>
</template>

<script>
    export default {
        name: "UnderDev"
    };
</script>

<style scoped>
.propstop-readystate {position: absolute;width: 240px;padding: 0px;height: 175px;text-align: center;left: calc(50% - 120px);top: calc(50% - 87.5px);}
.propstop-readystate > img {width: 120px;}
.propstop-readystate > p {font-size: 11pt;line-height: 16pt;margin-top: 10px;color: var(--dark-grey);}
</style>