<template>
    <div class="prop-form _propscrl">        
        <div class="form-area" style="padding-bottom: 65px;">
            <form name="propform" id="propform-invitation">
                <div class="pf--title">
                    <h3>Update Admin User</h3>
                    <p>You can update user and/or update them their respective roles.</p>
                </div>
                
                <div class="pf--input">
                    <p>First Name</p>
                    <input type="text" placeholder="FirstName" v-model="FirstName" id="FirstName" data-type="len" data-max="2" />
                </div>

                <div class="pf--input">
                    <p>Last Name</p>
                    <input type="text" placeholder="LastName" v-model="LastName" id="LastName" data-type="len" data-max="2" />
                </div>

                <div class="pf--input">
                    <p>Email</p>
                    <input type="text" placeholder="Email" v-model="Email" id="Email" data-type="email" />
                </div>                

                <div class="pf--input">
                    <p>User Role</p>
                    <select v-model="UserRole" id="UserRole" data-type="select">
                        <option value="-1">- Select One -</option>
                        <option v-for="role in Roles" :key="role.id" :value="role.id">{{ role.name }}</option>
                    </select>
                </div>

            </form>
            
            <span v-if="formError" class="__formerror">{{ infoText }}</span>
        </div>

        <div class="form-action">
            <div class="form-area">
                <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" style="float: left;margin-top: 15px;" @click="UpdateUser">
                    <p v-if="!Requesting">Update User</p>
                    <img v-else class="loader" src="./../../../images/gif/btn-loader.gif" />
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    //@ JS Modules
    import Request from '../../../javascript/getRequest';
    import FormValidation from '../../../javascript/FormValidation';

    export default {
        name: 'InviteTeamUser',
        props: ['data'],
        emits: ['close'],
        components: {

        },

        data () {
            return {
                // Form v-Modal
                UserID: '',
                FirstName : '',
                LastName : '',
                Email: '',
                UserRole : '-1',

                Roles: [],

                Requesting: false,

                // form error
                formError : false,
                infoText : '',
            }
        },

        async mounted () {
            this.Roles = this.data.roles.filter((index, role) => {
                if (index !== 0) return role;
            });

            // other user data
            this.UserID = this.data.user.id;
            this.FirstName = this.data.user.fname;
            this.LastName = this.data.user.lname;
            this.Email = this.data.user.email;
            this.UserRole = this.data.user.role;
        },

        methods: { 
            
            async UpdateUser () {
                if (this.Requesting) return;

                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-invitation');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead) {
                    this.Requesting = true;

                    let formData = new FormData();
                    formData.append('user', this.UserID);
                    formData.append('firstname', this.FirstName);
                    formData.append('lastname', this.LastName);
                    formData.append('email', this.Email);
                    formData.append('role', this.UserRole);

                    // submit tasks data
                    const ReturnData = await Request.postServer('admin/user/update-team.php', formData);
                    this.Requesting = false;

                    // now check if the email already exists
                    if (ReturnData.success == true) {
                        // now close modal, and load current property
                        this.$emit('close', ReturnData.response);
                    
                    // if error
                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again later or contact support.";
                    }
                }
            }

        }

    };
</script>

<style scoped>
span.__formerror {margin-left: 0 !important;}
</style>