<script>

    // import Website Navigation
    import WebNav from './../../components/website/WebNav.vue';
    import Footer from './../../components/website/Footer.vue';

    //@import JavaScript
    import Request from './../../javascript/getRequest';
    import Document from '../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';

    //@import component
    import HtmlImage from './../../components/blocks/HtmlImage.vue';
    import ReplaceImage from './../../components/blocks/ReplaceImage.vue';

    import InfoWindow from './../../components/tenants/MapInfoWindow.vue';
    

    export default {
        name : 'Explore',
        components : {
            WebNav,
            Footer,

            HtmlImage,
            ReplaceImage,
            InfoWindow,
        },

        data () {
            return {
                StockURL : Request.StockURL,
                loadingState : true,
                Properties : false,
                GoogleMapLocation : [],
                MapCenterLocation : null,

                GoogleMap : null,
                ShowMapInfoWindow : false,
                MarkerClickedProperty : false,
                InfoWindowPosition : ''
            }
        },

        async mounted () {
            // scroll to top
            window.scrollTo(0, 0);

            // remove styling used for apps
            document.getElementById('propstop-app').style.height = 'auto';
            document.getElementById('propstop-app').style.overflow = 'auto';

            //  change the title
            document.title = "PropStop - Explore Properties";

            // tenant property details
            const RequestData = await DataCenter.ProPRequest('explore-properties', false, false);
            if (!RequestData) {} else {
                this.Properties = RequestData;

                // location for map, i.e. locate to the current tenant location
                // later we can ask for the current location and locate there for now to their saved address


                var count = 1;
                for (const element of this.Properties) {
                    var location = {
                            lat: element.location.latitude,
                            lng: element.location.longitude,
                            property : element
                    };

                    this.GoogleMapLocation.push(location);
                    if (count == 1) this.MapCenterLocation = [element.location.latitude, element.location.longitude];
                    count++;
                }
            }

            // show the data
            this.loadingState = false;

            this.initializeMap ();
        },

        methods: {

            ChangeMapLocation (property) {
                
                var locateTo;
                for (const element of this.Properties) {
                    if (element.id == property.id) {
                        locateTo = {lat: element.location.latitude, lng: element.location.longitude};
                        break;
                    }
                }

                this.GoogleMap.setCenter(locateTo);
            },

            initializeMap () {
                var icon = 'https://dev.propstop.com/fetch-stock/marker.png';

                var locations = this.GoogleMapLocation;
                var currentLocation = JSON.parse(this.tenantProfile.location);
                
                // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
                var mapOptions = {
                    // How zoomed in you want the map to start at (always required)
                    zoom: 8,

                    // The latitude and longitude to center the map (always required)
                    center: new google.maps.LatLng(currentLocation.latitude, currentLocation.longitude),

                    disableDefaultUI: false,
                };

                // Get the HTML DOM element that will contain your map 
                // We are using a div with id="map" seen below in the <body>
                var mapElement = document.getElementById('googleMap');

                // Create the Google Map using our element and options defined above
                this.GoogleMap = new google.maps.Map(mapElement, mapOptions);
                        
                // Let's also add a marker while we're at it
                var markers = locations.map(function(location, i) {
                    return new google.maps.Marker({
                        position: location,
                        icon: icon,
                        animation:google.maps.Animation.DROP,
                        property: location.property
                    });
                });

                // map infowindow after click
                markers.map(data => {
                    data.addListener('click', () => {
                        var event = event || window.event;
                        if (typeof event !== "undefined") {
                            var x = event.clientX;
                            var y = event.clientY;

                            var newposX = x - 385;
                            var newposY = y - 440;

                            var newtrans = "translate3d(" + newposX + "px," + newposY + "px, 0px)";

                            this.MarkerClickedProperty = data.property;
                            this.InfoWindowPosition = newtrans;
                            this.ShowMapInfoWindow = true;

                        };
                    });
                });

                this.GoogleMap.addListener('center_changed', () => {
                    this.ShowMapInfoWindow = false;
                });
                
                // Add a marker clusterer to manage the markers.
                var markerCluster = new MarkerClusterer(this.GoogleMap, markers,
                        {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
            }
            }
    }
</script>

<template>
    <WebNav />


    <div class="search--content">

        <InfoWindow v-if="ShowMapInfoWindow" :property="MarkerClickedProperty" :position="InfoWindowPosition" />

        <div id="googleMap"></div>

        <div class="search--result animatedss fadeInRight">
            
            <div class="header">
                <h3>{{Properties.length}} Results Found</h3>
                <ul class="searlter">
                    <li class="filter">
                        <p>Price</p>
                        <img src="../../images/icons/sort-down-alt.svg" alt="Sort" />
                    </li>
                    <li>
                        <img src="../../images/icons/filter.svg" alt="Filter" />
                    </li>
                </ul>
            </div>

            <div class="pd-loading" v-if="loadingState">
                <img src="../../images/icons/loading.gif" alt="Loader" />
                <p>Looking for properties <br> near to you!</p>
            </div>

            <ul class="search--data _propscrl animated fadeInUp" v-if="!loadingState">
                <li v-for="property in Properties" :key="property" @mouseenter="ChangeMapLocation(property)">
                    <router-link :to="{path: '/explore/property/'+property.id+'/'}">
                        <div class="property-detail">
                            <div class="pd-image">
                                <ReplaceImage :source="property.image" />
                            </div>

                            <div class="pd-content">
                                <div class="pd-title">
                                    <h4>${{property.lowprice}} - ${{property.highprice}}</h4>
                                    <span>{{property.type}}</span>
                                </div>

                                <h3>{{property.name}}</h3>
                                <p>{{property.address}}</p>

                                <ul class="pd-features" v-if="property.total_features > 0">
                                    <li>{{property.feature}}</li>
                                    <li>{{property.total_features}}+</li>
                                </ul>
                            </div>
                        </div>

                        <ul class="property--features">
                            <li>
                                <img src="../../images/icons/bed-outline.svg" alt="2 Beds" />
                                <p>2 Beds</p>
                            </li>

                            <li>
                                <img src="../../images/icons/bathroom.svg" alt="1 Bath" />
                                <p>1 Bath</p>
                            </li>

                            <li>
                                <img src="../../images/icons/scale.svg" alt="1.2k sq. m." />
                                <p>1.2k sq. m.</p>
                            </li>

                            <li>
                                <img src="../../images/icons/pet.svg" alt="Pets" />
                                <p>Pets</p>
                            </li>
                        </ul>
                    </router-link>
                </li>
            </ul>

        </div>
    </div>


    <Footer />
</template>