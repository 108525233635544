<template>
    <div class="gs--body">
        <h3 style="margin-top: -30px; margin-bottom: -5px;">Search Filters</h3>
        <p style="font-size: 10pt;">Choose your filters pricing range, bed rooms etc.</p>

        <div class="floor-plan-area">
            <div class="planprop-form _propscrl">        
                <div class="form-area" style="width: 95%;">
                    <form id="propform-floorplan" style="width: 100%;">
                        <ul class="afb-form">
                            <li>
                                <div class="pf--multi-input" style="height: 75px; overflow: hidden;">
                                    <div class="half hleft">
                                        <div class="pf--input">
                                            <p>Min. Price *</p>
                                            <input type="text" placeholder="Minimum Price" v-model="data.minprice" id="MinPrice" data-type="len" data-max="2" />
                                        </div>
                                    </div>
                                    <div class="half hright">
                                        <div class="pf--input">
                                            <p>Max. Price *</p>
                                            <input type="text" placeholder="Maximum Price" v-model="data.maxprice" id="MaxPrice" data-type="len" data-max="2" />
                                        </div>
                                    </div>
                                </div>

                                <div class="pf--input">
                                    <p>Bedrooms</p>
                                    <ul class="i-selection">
                                        <li v-for="bed in Beds" :key="bed.id" @click="BedSelected(bed)" :class="bed.active ? 'active' : ''">{{bed.name}}</li>
                                    </ul>
                                </div>

                                <div class="pf--input">
                                    <p>Bathrooms</p>
                                    <ul class="i-selection" style="justify-content: flex-start;">
                                        <li v-for="bath in Baths" :key="bath.id" @click="BathSelected(bath)" style="margin-right: 15px;" :class="bath.active ? 'active' : ''">{{bath.name}}</li>
                                    </ul>
                                </div>

                                <div class="pf--input">
                                    <p>Pet Policy</p>
                                    <ul class="i-selection" style="justify-content: flex-start;">
                                        <li v-for="pet in Pets" :key="pet.id" @click="PetsSelected(pet)" style="margin-right: 15px;" :class="pet.active ? 'active' : ''">{{pet.name}}</li>
                                    </ul>
                                </div>

                            </li>

                            <li>
                                <ul class="selectamin _propscrl">
                                    <li v-for="amenity in Amenities" :key="amenity" :class="amenity.active ? 'active' : ''" @click="AmenityChoosed (amenity)">
                                        <img :src="require(`../../images/amenities/${amenity.icon}`)" />
                                        <p>{{amenity.name}}</p>
                                    </li>
                                </ul>

                            </li>
                        </ul>
                    </form>
                </div>
            </div>

            <div class="floor-plan-action">
                <span class="primary-btn-loader" @click="UpdateSearchPlan" :class="Requesting ? 'btn-disabled' : ''" style="margin-top: 0px !important;margin-left: 70px;">
                    <p v-if="!Requesting">Update</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>                
            </div>
        </div>
    </div>
</template>

<script>

    // import javascript
    import Locale from './../../javascript/Locale';
    import Request from './../../javascript/getRequest';
    import FormValidation from './../../javascript/FormValidation';
    import Methods from './../../javascript/Handler';

    export default {
        name: 'SearchFilterModel',
        props: ['data'],
        emits: ['close'],

        components: {
        },

        mounted () {
            console.log (this.data);
        },

        data () {
            return {
                PlanName : '',
                BedRooms : '',
                BathRooms : '',
                SquareFeet: '',
                Description: '',
                AmenitiesChoosed : [],

                UploadAttachment : false,
                AttachmentSchema : ['jpeg', 'jpg', 'png'],
                AttachmentFile: [],
                AttachmentError: false,
                hasFileAttached: false,

                Requesting : false,

                Amenities: Locale.UnitAmenities (),

                formError : false,
                infoText : '',
                FloorPlanSelection: false,
                SelectedPlanTypes: 0,

                // beds multi select
                Beds: [
                    {
                        id: 1,
                        name: 'Studio',
                        filter: 'studio'
                    },
                    {
                        id: 2,
                        name: '1 Bed',
                        filter: '1'
                    },
                    {
                        id: 3,
                        name: '2 Beds',
                        filter: '2'
                    },
                    {
                        id: 4,
                        name: '3 Beds',
                        filter: '3'
                    },
                    {
                        id: 5,
                        name: '4+ Beds',
                        filter: '4'
                    }
                ],

                // baths multi select
                Baths: [
                    {
                        id: 1,
                        name: '1 Bath',
                        filter: '1'
                    },
                    {
                        id: 2,
                        name: '2 Baths',
                        filter: '2'
                    },
                    {
                        id: 3,
                        name: '3+ Baths',
                        filter: '3'
                    },
                ],

                // pets multi select
                Pets: [
                    {
                        id: 1,
                        name: 'Cats',
                        filter: 'cats'
                    },
                    {
                        id: 2,
                        name: 'Dogs',
                        filter: 'dogs'
                    },
                    {
                        id: 3,
                        name: 'Cats & Dogs',
                        filter: 'both'
                    },
                    {
                        id: 4,
                        name: 'No Pets',
                        filter: 'no'
                    }
                ]

            }
        },

        methods : {

            // beds multi select 
            BedSelected (selected) {
                if (selected.active) selected.active = false;
                else selected['active'] = true;
                // this.Beds.filter(bed => {
                //     bed.active = false;
                //     if (bed.id == selected.id) bed.active = true;
                // });
            },

            // bathroom multi select
            BathSelected (selected) {
                if (selected.active) selected.active = false;
                else selected['active'] = true;
                // this.Baths.filter(bath => {
                //     bath.active = false;
                //     if (bath.id == selected.id) bath.active = true;
                // });
            },

            // pets multi select
            PetsSelected (selected) {
                if (selected.active) selected.active = false;
                else selected['active'] = true;
                // this.Pets.filter(pet => {
                //     pet.active = false;
                //     if (pet.id == selected.id) pet.active = true;
                // });
            },

            FloorPlanClick () {
                this.FloorPlanSelection = !this.FloorPlanSelection;
            },

            FloorPlanSelect (plan) {
                this.FloorPlanType.filter (pln => {
                    if (pln.key == plan.key) {
                        pln.active = !pln.active;
                        if (pln.active) {
                            this.SelectedPlanTypes++;
                        }else {
                            this.SelectedPlanTypes--;
                        }
                    }
                });
            },
            
            AmenityChoosed (amenity) {
                if (amenity.active) amenity.active = false;
                else amenity['active'] = true;
                
                this.AmenitiesChoosed = this.Amenities.filter (ament => {
                    if (ament.active) return ament;
                });              
            },

            filesclick () {
                this.$refs.files.click();
                this.formError = false;
                this.AttachmentError = false;
            },

            handleFilesUploads () {
                if (this.Requesting) return;

                this.AttachmentFile = this.$refs.files.files[0];
                var AttachedFileInfo = Methods.AttachmentUploadHandler (this.AttachmentFile, this.AttachmentSchema, 2);
                
                // file is okay to upload
                if (AttachedFileInfo.matched && !AttachedFileInfo.largesize) {
                    this.hasFileAttached = true;
                    this.AttachmentError = false;
                }else {
                    this.AttachmentFile = [];
                    this.AttachmentError = true;
                    this.hasFileAttached = false;
                }

            },

            async UpdateSearchPlan () {
                this.formError = false;
                if (this.Requesting) return;

                // form & validation
                let form = document.getElementById('propform-floorplan');
                let canGoAhead = FormValidation.Validate (form);

                // amenities choosed or not
                if (this.AmenitiesChoosed.length <=0 && canGoAhead == true) {
                    canGoAhead = false;
                    this.formError = true;
                    this.infoText = "Please choose floor plan's Amenities";
                }

                // check for the floorplan type selected
                var FloorPlanisOkay = false;
                this.FloorPlanType.forEach (plan => {
                    if (plan.active) FloorPlanisOkay = true;
                });

                if (FloorPlanisOkay == false) {
                    canGoAhead = false;
                    this.formError = true;
                    this.infoText = "Please choose floor plan's type!";
                }

                if (canGoAhead) {
                    this.Requesting = true;

                    let formData = new FormData();
                    formData.append('name', this.PlanName);
                    formData.append('bedrooms', this.BedRooms);
                    formData.append('bathrooms', this.BathRooms);
                    formData.append('squarefeet', this.SquareFeet);
                    formData.append('description', this.Description);
                    formData.append('document', this.AttachmentFile);
                    
                    // floor type 
                    var FloorType = this.FloorPlanType.map(function (plan) {
                        if (plan.active) return plan.name;
                    }).join(', ');
                    FloorType = FloorType.slice(0, -2);
                    formData.append('type', FloorType);

                    // amenities
                    let amenities = this.AmenitiesChoosed.map(amenity => {
                        return amenity.id;
                    }).join(',');

                    formData.append('amenities', amenities);
                    formData.append('property', this.data);

                    // load data from server
                    const ReturnData = await Request.postServer('owner/property/floorplan/add-plan.php', formData);
                    this.Requesting = false;

                    if (ReturnData.error == false) {
                        this.$emit('close', ReturnData.response);
                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again.";
                    }

                }
            },
        }
    };

</script>

<style scoped>
    /* never move these lines */
    .primary-btn-loader {    border-radius: 4px !important;
    margin: 0 !important;
    margin-left: 15px !important;
    float: left !important;}
    .primary-btn-loader p {line-height: 20px !important;}
</style>