<template>
    <div class="prop-row" :class="count % 2 ? 'odd' : 'even'" style="overflow: unset; float: left; cursor:pointer;" @click="ApplicantHandler(applicant)">
        <div class="column column25">
            <div class="unit--tenant">
                <div class="image">
                    <UserImage :init="applicant.tenant.fullname" :source="applicant.tenant.avatar" />
                </div>

                <div class="content">
                    <h3>{{applicant.tenant.fullname}}</h3>
                    <p>{{ applicant.tenant.date }}</p>                    
                </div>
            </div>
        </div>

        <div class="column column30">
            <div class="unit--status">
                <p style="font-weight: bold">{{ applicant.tenant.email }}</p>
                <p>{{ applicant.tenant.phone}}</p>
            </div>
        </div>

        <div class="column column25">
            <div class="floor-plan" style="padding: 0">
                <p style="font-weight: bold">{{ applicant.property.name }}</p>
                <p>{{ applicant.property.address }}</p>
            </div>
        </div>

        <div class="column column10">
            <p>{{ applicant.ticket }}</p>
        </div>

        <div class="column column10" style="position: relative;">
            <div class="unit-menu" data-name="hammenu">
                <img src="../../../images/icons/ham-menu.svg" data-name="hammenu" />
            </div>
            <ul class="action-menu" v-if="applicant.active == true">
                <li data-name="closeham"><img src="./../../../images/icons/close.svg" data-name="closeham"/></li>
                <li v-for="date in applicant.dates" :key="date.id" :class="date.selected ? 'invited' : ''">
                    <h3>Invite Tenant</h3>
                    <p v-if="date.selected">{{ date.date }}</p>
                    <p>{{ date.date }}</p>
                </li>
                <li data-name="assign">Assign Tenant</li>
                <li data-name="cancel">Cancel Request</li>
            </ul>
        </div>
    </div>
</template>

<script>
    //@import component
    import UserImage from '../../blocks/UserImage.vue';

    export default {
        name: 'ApplicantCard',
        props: ['applicant',  'count'],
        emits: ['hamclick'],
        components: {
            UserImage,
        },

        methods: {
            ApplicantHandler (applicant) {
                let target = event.target.getAttribute('data-name');
                
                // want to see the unit menu list
                if (target == "hammenu") {
                   this.$emit('hamclick', this.applicant);
                
                // want to close the ham menu
                }else if (target == "closeham") {
                    this.applicant.active = false;
                }
            },
        }
    };
</script>

<style scoped>
.invited {
    opacity: 0.4;
}
.invited:hover {
    background-color: transparent;
    cursor: default
}
</style>