<template>
  <div class="prop-form _propscrl">
    <div class="form-area" style="padding-bottom: 65px">
      <form name="propform" id="propform-document" style="display: block">
        <div class="pf--title">
          <h3>Complete Tenant Moveout.</h3>
          <p>You can complete the tenant/unit move-out process here.</p>
        </div>

        <div class="pf--multi-input" style="height: 80px">
          <div class="half hleft">
            <div class="pf--input">
              <p>Deposit Returned?</p>
              <select>
                <option value="-1" selected>- Choose One -</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>

          <div class="half hright">
            <div class="pf--input">
              <p>Reason</p>
              <select name="Reason" id="Reason" v-model="MoveReason" data-type="select">
                <option value="-1" selected>- Choose One -</option>
                <option value="new place">Found new place</option>
                <option value="no reason">No Reason Given</option>
                <option value="more space">Need More Space</option>
                <option value="early lease">Early Lease Termination (Good Standing)</option>
                <option value="abandoned unit">Abandoned Unit</option>
                <option value="broken lease">Broken Lease - Termination</option>
                <option value="eviction">Eviction</option>
              </select>
            </div>
          </div>
        </div>
        
        <div class="pf--input">
          <p>Note</p>
          <input type="text" placeholder="i.e. Moveout note" id="MiscOther" data-type="len" data-max="2" :disabled="Requesting" />
        </div>

        <div class="pf--input" v-if="MoveReason == 'no reason' || MoveReason == 'abandoned unit' || MoveReason == 'broken lease' || MoveReason == 'eviction'">
          <p>Balance Due?</p>
          <select>
            <option value="-1" selected>- Choose One -</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div class="pf--input" v-if="MoveReason == 'eviction'" style="margin-top: 10px;">
            <div class="upload-button">
                <img src="./../../images/icons/upload.svg" />
                <p v-if="hasFileAttached" style="color: #449adf;">File Ready To Upload!</p>
                <p v-else>Upload document</p>
            </div>
            <p v-if="AttachmentError" style="color: red;font-size: 10pt;line-height: 13pt;">The uploaded file is not supported or size is greater than 2MB!</p>
        </div>

      </form>

      <span v-if="formError" class="__formerror">{{ infoText }}</span>
    </div>

    <div class="form-action">
      <div class="form-area">
        <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="CompleteMoveOutProcess">
          <p v-if="!Requesting">Unassign Unit</p>
          <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>

    //@ JS Modules
    import Request from "../../javascript/getRequest";
    import FormValidation from "../../javascript/FormValidation";
    import Handler from "../../javascript/Handler";

    // datepicer
    import Datepicker from "@vuepic/vue-datepicker";
    import "@vuepic/vue-datepicker/dist/main.css";

    export default {
      name: "CompleteMoveOut",
      props: ["data"],
      emits: ["close"],
      components: {
        Datepicker,
      },

      data() {
        return {
          Deposits: "",
          EffectiveDate: null,
          AdditionalDeposit: '-1',
          AdditionalAmount: '',
          MiscOther: '',
          PaymentType: '-1',
          DepositNote: '',

          // complete moveout
          MoveReason: '-1',

          hasFileAttached: false,
          AttachmentError: false,

          // form error
          formError: false,
          infoText: "",
          Requesting: false,
        };
      },

      mounted() {

        console.log (this.data);
        
      },

      methods: {        

        async CompleteMoveOutProcess () {
          if (this.Requesting) return;

          // form & validation
          let form = document.getElementById("propform-document");
          let canGoAhead = FormValidation.Validate(form);

          if (canGoAhead) {
            this.Requesting = true;

            let formData = new FormData();

            formData.append("property", this.data.property.id);
            formData.append("unit", this.data.property.unit);
            formData.append("assignment", this.data.property.assignment);
            formData.append("tenant", this.data.tenant.id);

            // submit tasks data
            const ReturnData = await Request.postServer("owner/units/complete-moveout.php", formData);
            this.Requesting = false;

            // now check if the email already exists
            if (ReturnData.error == false) {

              // now close modal, and load current property
              this.$emit("close", true);

              // notiy owner
              Handler.PropNotification("Successfully unassigned tenant from the selected unit.");

              // if error
            } else {
              this.formError = true;
              this.infoText = "Something went wrong!";
            }
          }
        },
      },
    };
</script>

<style scoped>

span.__formerror {
  margin-left: 0 !important;
}
.v-calendar {
  width: calc(100% - 2px) !important;
  min-width: 100%;
}
</style>
