<script>

    // pinia
    import { useTenantStore } from './../../../stores/TenantStore';

    import Request from '../../../javascript/getRequest';
    import FormValidation from '../../../javascript/FormValidation';

    // datepicer
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';


    export default {
        name: 'GetInTOuch',
        props: ['data'],
        emits: ['close'],
        components: {

            Datepicker,
        },

        data () {
            return {
                Date1: null,
                Date2: null,
                Date3: null,
                Notes: '',

                isRequesting: false,
                formError: false,
                infoTextL: '',

                TenantStore: useTenantStore(),
            }
        },

        mounted () {

            // console.log (this.data);

        },

        methods: {

            async RequestVisit () {
                if (this.isRequesting) return;

                let PickerAhead = true;

                this.isRequesting = true;

                // dates are required
                if (this.Date1 == null && this.Date2 == null && this.Date3 == null) {
                    PickerAhead = false;
                    alert ('Please pick ticket expiriy date!');
                }

                let form = document.getElementById('propform-getintouch');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead && PickerAhead) {
                    this.Requesting = true;

                    let formData = new FormData();

                    // start date timestamp
                    let D1 = this.Date1.getTime();
                    D1 = D1/1000;
                    formData.append('date1', D1);

                    let D2 = this.Date2.getTime();
                    D2 = D2/1000;
                    formData.append('date2', D2);

                    let D3 = this.Date1.getTime();
                    D3 = D3/1000;
                    formData.append('date3', D3);

                    formData.append('notes', this.Notes);
                    formData.append('user', this.TenantStore.user.id);
                    formData.append('tenant', this.TenantStore.user.tenant);
                    formData.append('owner', this.data.owner.owner);
                    formData.append('property', this.data.property.property);

                    // upload and load data from server
                    const ReturnData = await Request.postServer('tenant/propstop/getintouch.php', formData);
                    this.Requesting = false;

                    // now check if the email already exists
                    if (ReturnData.error == false) {
                        // now close modal, and load current property
                        this.$emit('close', ReturnData.response);

                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again.";
                    }

                }

            }


        }

    }

</script>

<template>
    <div class="prop-form _propscrl">
        <div class="form-area" style="padding-bottom: 65px;">
            
            <form name="propform" id="propform-getintouch" style="display: block;">
                <div class="pf--title">
                    <h3>Get In Touch</h3>
                    <p>Please select the brief information needed to contact the property owner.</p>
                    <p style="font-size: 10pt;">* Indicate a required field</p>
                </div>

                <div class="pf--info" style="margin-bottom: 45px;">
                    <div class="icon">
                        <img src="./../../../images/icons/info-blue.svg" />
                    </div>
                    <div class="content">
                        <p>Provide any three dates you would like to visit the property.</p>
                    </div>
                </div>

                <div class="pf--input">
                    <p>Visit Date 1 *</p>
                    <Datepicker class="dp__input_custom" v-model="Date1" 
                                :enableTimePicker="false" 
                                autoApply 
                                required 
                                fixedStart
                                placeholder="Pick Date" />
                </div>

                <div class="pf--input">
                    <p>Visit Date 2 *</p>
                    <Datepicker class="dp__input_custom" v-model="Date2" 
                                :enableTimePicker="false" 
                                autoApply 
                                required 
                                fixedStart
                                placeholder="Pick Date" />
                </div>

                <div class="pf--input">
                    <p>Visit Date 3 *</p>
                    <Datepicker class="dp__input_custom" v-model="Date3" 
                                :enableTimePicker="false" 
                                autoApply 
                                required 
                                fixedStart
                                placeholder="Pick Date" />
                </div>

                <div class="pf--input">
                    <p>Visit Note *</p>
                    <input v-model="Notes" type="text" placeholder="Visit notes.." id="Notes" data-type="len" data-max="2" :disabled="isRequesting" />
                </div>
            </form>
        </div>

        <div class="form-action">
            <div class="form-area">
                <span class="primary-btn-loader" style="display: block;float: left;margin-top: 10px;" :class="isRequesting ? 'btn-disabled' : ''" @click="RequestVisit">
                    <p v-if="!isRequesting">Request Visit</p>
                    <img v-else class="loader" src="./../../../images/gif/btn-loader.gif" />
                </span>

                <span v-if="formError" class="__formerror">{{ infoText }}</span>                
            </div>
        </div>
    </div>
</template>