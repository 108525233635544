<template>
    <div class="prop-form _propscrl">        
        <div class="form-area" style="padding-bottom: 65px;">
            <form v-if="formProcess == 1" name="propform" id="propform-ticket">
                <div class="pf--title">
                    <h3>Edit Ticket</h3>
                    <p>You can edit ticket here, change subject, expiriy date and other information.</p>
                </div>

                <div class="pf--input">
                    <p>Ticket Subject</p>
                    <input type="text" placeholder="Subject of your new ticket" v-model="Subject" id="Subject" data-type="len" data-max="5" disabled />
                </div>

                <div class="pf--multi-input" style="height:80px;">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Due Date</p>
                            <Datepicker class="dp__input_custom" v-model="Expiry" :enableTimePicker="false" autoApply required placeholder="Pick date.." />
                        </div>
                    </div>

                    <div class="half hright">
                        <div class="pf--input">
                            <p>Ticket Priority</p>
                            <select v-model="Priority" id="Priority" data-type="select">
                                <option value="-1">- Select One -</option>
                                <option value="Critical">Critical</option>
                                <option value="High">High</option>
                                <option value="Normal">Normal</option>
                                <option value="Low">Low</option>
                            </select>
                        </div>
                    </div>                    
                </div>

                <div class="pf--multi-input" style="height:85px;">
                    <div class="half hleft">
                        <div class="pf--input" style="overflow:unset; position:relative;">
                            <p>Choose Unit</p>
                            <input v-model="SelUnit" type="text" id="Units" data-type="len" data-max="5" readonly @click="UnitsSingleSelect = true" disabled />
                            <UnitSelection v-if="UnitsSingleSelect == true" :units="PropertyUnits" @lstboxclse="UnitSelected" />
                        </div>
                    </div>

                    <div class="half hright">
                        <div class="pf--input">
                        </div>
                    </div>                    
                </div>

                <div class="pf--input">
                    <p style="text-align: center;">Ticket Details</p>
                    <textarea placeholder="Describe issues" style="height: 150px" v-model="Content" id="Content" data-type="len" data-max="10" disabled></textarea>
                    <p v-if="ContentLengthIssue" style="font-size: 9pt;font-weight: 500;color: red;line-height: 18pt;">You need at least 10 characters.</p>
                </div>

            </form>

        </div>

        <div class="form-action">
            <div class="form-area">               

                <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="EditCurrentTicket">
                    <p v-if="!Requesting">Edit Ticket</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>

                <span v-if="formError" class="__formerror">{{ infoText }}</span>
            </div>
        </div>

    </div>
</template>

<script>
    //@ JS Modules
    import Request from './../../javascript/getRequest';
    import DataCenter from './../../javascript/DataCenter';

    import FormValidation from './../../javascript/FormValidation';

    // import component
    import PropertyList from './../blocks/PropertyList.vue';
    import UserMultiSelectInput from './../web-forms/UserMultiSelectInput.vue';
    import UnitSelection from './../web-forms/UnitSelection.vue';

    // datepicer
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    export default {
        name: 'EditTicket',
        props: ['data'],
        emits: ['close'],
        components: {
            PropertyList,
            UserMultiSelectInput,
            UnitSelection,
            Datepicker,
        },

        data () {
            return {

                CurrentTicketID: this.data.id,

                // Form Modal
                Subject : this.data.subject,
                Priority : this.data.priority,
                Expiry: new Date (this.data.expiry_date),
                Content: this.data.detail.content,
                FirstThread: this.data.detail.thread,
                PropertyName : this.data.property, // property id

                AssignTo : '',
                AssignToList : [],
                RequestedBy : '',
                RequestedByName : '',
                ContentLengthIssue: false,              

                ShowAssignTenant : false,
                ShowAssignEmployee : false,

                Requesting: true,

                formProcess : 1,

                // units
                SelUnit : this.data.prop_unit.unit,
                SelUnitID : this.data.unit,
                PropertyUnits : false,
                UnitsSingleSelect : false,
                SelectedUnit : null,

                // multi and single select settings
                UserMultiSelect : false,
                UserSingleSelect : false,

                // Properties list
                OwnerProperties : false,
                SelectedProperty : false,
                SelectProperty : false,

                // tenants and employee
                OwnerTenants : false,
                OwnerEmployees : false,
                EmployeeRoleList : false,
                FilteredTenants : false,
                FilteredEmployees : false,

                // form error
                formError : false,
                infoText : '',

                // if tenant or employee is already assigned or set
                isRequestedByReady : false,
                isAssignToReady : false,
            }
        },

        async mounted () {

            console.log (this.data);
            
            // load all the units of current property
            this.loadSelectedPropertyUnits (this.PropertyName);

        },

        methods : {            

            // load blank units on the selected properties
            async loadSelectedPropertyUnits (propertyid) {
                // new selection
                this.SelectedUnit = null;
                this.PropertyUnits = null;

                // load units data from server
                let getdata = {
                    key : 'property',
                    value : propertyid
                }
                
                const Units = await DataCenter.ProPRequest('property-units', false, getdata);
                this.Requesting = false;

                if (!Units) {}else {
                    this.PropertyUnits = Units;
                }            
            },

            // once features are slected lets collect the data
            AssignToSelected (assigns) {
                this.UserMultiSelect = false;

                // now update the selected data list too
                this.AssignToList = assigns.filter(list => {
                    if (list.active == true) {
                        return list;
                    }
                });

                // now set the form modal value
                let len = this.AssignToList.length;
                if (len < 1) {
                    this.AssignTo = '';
                }else {
                    this.AssignTo = len + ' Selected';
                }
            },

            // once unit is selected from single select
            UnitSelected (unit) {
                if (unit !== null) {
                    this.SelUnit = unit.unit_name;
                    this.SelUnitID = unit.id;
                }

                this.UnitsSingleSelect = false;                
            },

            // now finally create new ticket and assign
            async EditCurrentTicket () {

                if (this.Requesting) return;
                this.formError = false;
                this.ContentLengthIssue = false;

                let PickerAhead = true;
                
                // calendar validation
                if (this.Expiry == null) {
                    PickerAhead = false;
                    alert ('Please pick ticket expiriy date!');
                }

                // content length
                if (this.Content.length < 10) this.ContentLengthIssue = true;
                else this.ContentLengthIssue = false;

                // form & validation
                let form = document.getElementById('propform-ticket');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead && PickerAhead) {
                    this.Requesting = true;

                    let formData = new FormData();

                    // start date timestamp
                    let ticketExpiry = this.Expiry.getTime();
                    ticketExpiry = ticketExpiry/1000;
                    formData.append('expiry', ticketExpiry);

                    formData.append('subject', this.Subject);
                    formData.append('priority', this.Priority);                    
                    formData.append('property', this.PropertyName);        
                    formData.append('ticket', this.CurrentTicketID);        
                    formData.append('thread', this.FirstThread);        
                    formData.append('content', this.Content);
                    formData.append('unit', this.SelUnitID);

                    // upload and load data from server
                    const ReturnData = await Request.postServer('owner/tickets/update.php', formData);
                    this.Requesting = false;

                    // now check if the email already exists
                    if (ReturnData.error == false) {
                        // now close modal, and load current property
                        this.$emit('close', ReturnData.response);
                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again.";
                    }

                }
            }
        }


    };
</script>

<style scoped>
span.__formerror {margin-top: 15px !important;margin-left: 15px !important;}
.primary-btn-loader {    margin-top: 15px !important;
    padding: 10px 30px !important;
    border-radius: 6px !important;
    float: left !important;
    width: 125px !important;}

    div.process-back {    display: block;
    float: left;
    margin-right: 10px;
    margin-top: 17px;
    height: 22px;
}div.process-back img {    float: none;
    margin: 0;
    width: 20px;}
</style>