<template>
    <!-- Skeleton -->
    <div v-if="tickets === false" style="margin-top:55px">
        <Skeletion :data="4" />
    </div>

    <!-- If no data on result -->
    <div v-if="FilteredTicketList.length <= 0" class="emptyskeleton">
        <img src="../../images/icons/empty-result.svg" class="psanim-1 fadeInUp">
        <p class="psanim-2 fadeInUp">You dont have much information on this request. <br />Please add new ticket to see more features.</p>
    </div>

    <div v-else class="tc-tickets" style="margin-top: 0">
        <div class="prop--table">
            <div class="prop-row row-header">
                <div class="column column55">Subject</div>
                <div class="column column25">Assignee</div>
                <div class="column column20">Status</div>
            </div>
            
            <!-- All ticket list -->
            <div class="prop-row ticketClk transAe" 
                    v-for="(ticket, index) in FilteredTicketList" 
                    :key="ticket"  
                    :class="index % 2 ? 'odd' : 'even'" @click="tiketclicked(ticket)">
                <div class="column column55">
                    <div class="ticket--subject" style="padding-left: 20px;">
                        <p><strong>#{{ticket.id}}</strong> {{ ticket.subject }}</p>
                        <ul class="ticket-priority">
                            <li v-if="ticket.priority == 'Critical'" class="critical"><img src="../../images/icons/critical.svg" /> <p>{{ticket.priority}}</p></li>
                            <li v-if="ticket.priority == 'High'" class="high"><img src="../../images/icons/high.svg" /> <p>{{ticket.priority}}</p></li>
                            <li v-if="ticket.priority == 'Normal'" class="normal"><img src="../../images/icons/normal.svg" /> <p>{{ticket.priority}}</p></li>
                            <li v-if="ticket.priority == 'Low'" class="low"><img src="../../images/icons/low.svg" /> <p>{{ticket.priority}}</p></li>
                        </ul>                        
                    </div>
                </div>
        
                <div class="column column25">
                    <div class="ticket-assignee">
                        <div class="assigneenme" v-for="(assignee) in ticket.assignee" :key="assignee">
                            <h3>{{assignee.fullname}}</h3>
                        </div>
                        <h3 v-if="ticket.assignee.length < 1" style="color: #ff5722;">Not Assigned</h3>
                    </div>
                </div> 
        
                <div class="column column20">
                    <div v-if="ticket.status == 'Open'" class="status primary">{{ticket.status}}</div>
                    <div v-else class="status secondary">{{ticket.status}}</div>
                    
                    <p v-if="ticket.expiry == 'Expired' && ticket.status == 'Open'" style="color: #F00">{{ticket.expiry}}</p>
                    <p v-else-if="ticket.status == 'Open'" style="color: hsl(120deg 34% 32%)">{{ticket.expiry}}</p>
                </div>
            </div>
            <!-- All ticket list -->

        </div>
    </div>

    
    
</template>

<script>

    import Skeletion from './../blocks/Skeleton.vue';
    import UserImage from './../blocks/UserImage.vue';
    
    export default {
        name: 'OwnerTickets',
        props: ['property', 'tickets', 'filter'],
        emits: ['ticketClicked'],
        components: {
            Skeletion,
            UserImage,
        },

        data () {
            return {
                FilteredTicketList : [],
            }
        },

        mounted () {

            console.log (this.tickets);
            
            // filter according the clicked filter menu and load the data
            if (this.tickets)
                this.filterTheList ();

        },

        methods: {

            filterTheList () {
                // filter ticket with selected property
                // this.FilteredTicketList = this.tickets.filter (ticket => {
                //     if (ticket.property == this.property.property) return ticket;
                // });

                this.FilteredTicketList = this.tickets.filter (ticket => {
                    // nothing to worry if need to load all the tickets 
                    if (this.filter == "all") {return ticket;}

                    // now load only the open status tickets
                    if (this.filter == "open") {
                        if (ticket.status == "Open") return ticket;
                    }

                    // now load only the closed status tickets
                    if (this.filter == "closed") {
                        if (ticket.status == "Closed") return ticket;
                    }

                    // now load only the unassigned tickets
                    if (this.filter == "unassigned") {
                        if (!ticket.isAssigned) return ticket;
                    }

                    // now load only the favourites tickets
                    if (this.filter == "favorites") {
                        if (ticket.isFavourite) return ticket;
                    }                    
                });

                console.log (this.filter);
                console.log (this.FilteredTicketList);
            },

            tiketclicked (ticket) {
                this.$emit('ticketClicked', ticket);
            },

        }
    };
</script>