<template>
    <li class="transAe" :class="{ active : current.active}" @click="NavigationClicked">
            <div class="icon">
                <img :src="require(`../../${current.icon}`)" />
            </div>
            <div class="content">
                <p>{{ current.name }}</p>
                <span v-if="current.name == 'Tickets'" id="TicketsChanges" style="display: none;">0</span>
            </div>
    </li>
</template>

<script>
    export default {
        name: 'OwnerSideNav',
        props: ['current'],

        methods : {
            NavigationClicked () {
                this.$emit('navchange', this.current);
            }
        }
    };
</script>