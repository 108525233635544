<template>
    <div class="app-walkthrough animated" :class="[toHide ? 'fadeOutUp' : 'fadeInUp', toPoint]" v-if="toShow" :style="style">
        <div class="image">
            <img :src="require(`../../images/walk/${image}`)" />
        </div>
        <div class="content">
            <div class="context">
                <h3>{{ title }}</h3>
                <p>{{ content }}</p>
            </div>
            <div class="conclose" @click="CloseWalkThrough">
                <img src="./../../images/icons/close.svg" />
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'WalkThroughVue',
        props: ['image', 'title', 'content', 'toPoint', 'style'],
        emits: ['closewth'],

        data () {
            return {
                toShow: false,
                toHide: false,
            }
        },

        mounted () {
            setTimeout (() => {
                this.toShow = true;
            }, 1000);
        },

        methods: {

            CloseWalkThrough () {
                this.toHide = true;

                setTimeout(() => {
                    this.$emit('closewth');
                }, 500);
            }

        }
    }

</script>