<script>

      //@ JS Modules
      import Request from '../../../javascript/getRequest';
      import Handler from '../../../javascript/Handler';
      import FormValidation from '../../../javascript/FormValidation';

      import UserMultiSelectInput from '../../web-forms/UserMultiSelectInput.vue';

      // datepicer
      import Datepicker from '@vuepic/vue-datepicker';
      import '@vuepic/vue-datepicker/dist/main.css';

      export default {
            name: 'Wipes',
            props: ['owners'],
            components: {
                  Datepicker,
                  UserMultiSelectInput,
            },
            
            data () {
                  return {
                        
                        Message: '',
                        WipeDate: '',

                        Requesting: false,
                        formError: false,
                        infoText: '',

                        OwnerMultiSelect: false,
                        SelectedOwners: [],
                        SelectedTotalOwners: '',
                        SentToAll: false,
                  }

            },

            methods: {        
                  
                  OwnerSelected (owners) {
                        this.OwnerMultiSelect = false;

                        this.SelectedOwners = this.owners.filter((owner) => {
                              if (owner.active) {
                                    return owner;
                              }
                        });

                        if (this.SelectedOwners.length == 0) {
                              this.SelectedTotalOwners = "";
                        }else {
                              this.SelectedTotalOwners = this.SelectedOwners.length + " Selected";
                        }
                  },

                  async HandleSubmitForm () {
                        if (this.Requesting) return;

                        this.formError = false;

                        // form & validation
                        let form = document.getElementById('communication-form');
                        let canGoAhead = FormValidation.Validate (form);
                        if (canGoAhead) {
                              this.Requesting = true;

                              let newDate = new Date(this.WipeDate);
                              let year = newDate.getFullYear();
                              let month = newDate.getMonth() + 1;
                              let day = newDate.getDate();

                              let formData = new FormData();
                              formData.append('message', this.Message);
                              formData.append('date', year+'-'+month+'-'+day);

                              // list of owners to send email
                              let OwnerData = [];
                              if (this.SentToAll) OwnerData = this.owners;
                              else OwnerData = this.SelectedOwners;

                              formData.append('owners', JSON.stringify(OwnerData));

                              // send and receive data
                              const ReturnData = await Request.postServer('admin/action/wipes.php', formData);
                              this.Requesting = false;
                              
                              if (!ReturnData.error) {
                                    Handler.PropNotification ('Announcement has been sent successfully!');
                              }else {
                                    this.formError = true;
                                    this.infoText = "Something went wrong! Please try again later!"
                              } 
                        }
                  },
            }

      }

</script>

<template>
      <div class="comm-body animated fadeInUp">                        
            <form id="communication-form" autocomplete="off" style="display: block;">

                  <div class="pf--info" style="margin-bottom: 45px;">
                        <div class="icon">
                              <img src="./../../../images/icons/info-blue.svg" />
                        </div>
                        <div class="content">
                              <p>Designate the date for the data wipe below, then click 'Submit' to dispatch an email notification to the proprietors.</p>
                        </div>
                  </div>

                  <div class="pf--input">
                        <p>Choose Owners</p>
                        <input type="text" v-model="SelectedTotalOwners" placeholder="Choose Owners" readonly @click="OwnerMultiSelect = true" :disabled="SentToAll" style="width: 40% !important;">
                        <UserMultiSelectInput v-if="OwnerMultiSelect" :lists="owners" :style="'left: 25px;top: 100px;'" @lstboxclse="OwnerSelected()" />

                        <div class="checkbox">
                              <input type="checkbox" :checked="SentToAll" v-model="SentToAll" />
                              <p>Send email to all Owners</p>
                        </div>
                  </div>

                  <div class="pf--input">
                        <p>Wipes Message</p>
                        <textarea placeholder="Message" v-model="Message" id="Message" data-type="len" data-max="3"></textarea>
                  </div>

                  <div class="pf--input2" style="width: 150px;">
                        <p>Wipe Date</p>
                        <Datepicker :input-class="'pf--calendar db__input'" v-model="WipeDate" :enableTimePicker="false" autoApply required placeholder="Pick Date"  />
                  </div>

                  <div class="form-submit" style="margin-top: 25px;">
                        <span style="display: block;float: left;" 
                              class="primary-btn-loader" 
                              :class="Requesting ? 'btn-disabled' : ''" 
                              @click="HandleSubmitForm">

                              <p v-if="!Requesting">Submit Server Wipes</p>
                              <img v-else class="loader" src="./../../../images/gif/btn-loader.gif" />
                        </span>
                        <span v-if="formError" class="__formerror">{{ infoText }}</span>
                  </div>

            </form>

      </div>
</template>

<style scoped>

      .db__input_wrap {
            border: 1px solid #ddd;
            border-radius: 4px;
            margin-top: 7px;
      }

      .dp__input_wrap input {
            width: 100% !important;
            padding-left: 35px !important;
      }

      div.checkbox {
            float: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 55%;
      }

      div.checkbox input {
            width: 25px !important;
      }

      div.checkbox p {
            flex: 1;
            line-height: 30px;
      }

      

</style>