<template>
    <div class="tenant-contnt _propscrl">
        <div class="property-info">

            <div class="dash-box" style="margin-top: 0;">       
                <router-link :to="{path: '/tenant/property/'+tenantProperty.property+'/'}">     
                    <div class="dbprtimage">
                        <ReplaceImageVue :source="tenantUnit.thumbnail" />
                        
                        <div class="count">
                            <p>{{tenantProperty.gallery}}</p>
                            <img src="../../images/icons/gallery-white.svg" />
                        </div>
                    </div>

                    <div class="content">
                        <div class="propery-detail">
                            <div class="title" style="height: 30px;">
                                <span style="float: left;">{{tenantProperty.type}}</span>
                            </div>

                            <h3>{{tenantProperty.name}}</h3>
                            <p>{{tenantProperty.address}}</p>

                            <ul class="features" v-if="tenantProperty.total_features > 0">
                                <li>{{tenantProperty.feature}}</li>
                                <li>{{tenantProperty.total_features}}+</li>
                            </ul>
                        </div>

                        <ul class="main-feat">
                            <li>
                                <img src="../../images/icons/bed-outline.svg" />
                                <p>2 Beds</p>
                            </li>

                            <li>
                                <img src="../../images/icons/bathroom.svg" />
                                <p>1 Bath</p>
                            </li>

                            <li>
                                <img src="../../images/icons/scale.svg" />
                                <p>1.2k sq. m.</p>
                            </li>
                        </ul>

                    </div>
                </router-link>            
            </div>

            <div class="dash-box">
                <div class="header">
                    <h3>Agency Contact</h3>
                </div>
                
                <div v-if="!tenantAgency" style="width: 50%;">
                    <Skeletion :type="'user-list'" :data="1" />
                </div>
                <div v-else class="content">
                    <div class="agency-profile">
                        <div class="image">
                            <UserImage :init="tenantAgency.business" :source="''" />
                        </div>

                        <div class="content">
                            <h3>{{tenantAgency.business}}</h3>
                            <p>{{tenantAgency.email}} . {{tenantAgency.phone}}</p>
                        </div>
                    </div>

                </div>

            </div>

            <div class="dash-box">
                <div class="header">
                    <h3>Moveout Process</h3>
                </div>
                
                <div class="content">
                    <ul class="dllinks _propscrl" style="height: auto;">
                        <li @click="InitiateMoveOutFunc">
                            <div class="image">
                                <img src="../../images/icons/user-profile.svg" />
                            </div>

                            <div class="content">
                                <h3>Initiate Move-out</h3>
                                <p>Start if you are leaving the unit.</p>
                            </div>
                        </li>
                        
                    </ul>
                </div>

            </div>

        </div>

        <div class="unit-info" v-if="tenantUnit">

            <div class="unit-gallery">
                <Flicking :options="{ align: 'prev', bound: true }" v-if="ShowUnitGallery">
                    <div class="flicking-unit-image" v-for="gallery in UnitGallery" :key="gallery.blobid">
                        <img :src="gallery.url" />
                    </div>
                </Flicking>
            </div>

            <div class="ui-ot-dtls">

                <div class="uodtls">

                    <div class="li">
                        <h4>Unit Plan</h4>
                        <h3>{{FloorPlan.name}}</h3>
                    </div>

                    <div class="li">
                        <h4>Floor Type</h4>
                        <h3>{{FloorPlan.floortype}}</h3>
                    </div>

                    <div class="li">
                        <h4>Bed Rooms</h4>
                        <h3>{{FloorPlan.bedrooms}} Bd</h3>
                    </div>

                    <div class="li">
                        <h4>Bath Rooms</h4>
                        <h3>{{FloorPlan.bathrooms}}</h3>
                    </div>

                    <div class="li">
                        <h4>Square Feet</h4>
                        <h3>{{FloorPlan.squarefeet}} sq. ft.</h3>
                    </div>

                    <div class="li">
                        <h4>Floor</h4>
                        <h3>101 - First Floor</h3>
                    </div>

                    <div class="li">
                        <h4>Monthly Rent</h4>
                        <h3>$2400</h3>
                    </div>

                    <div class="li">
                        <h4>Agreement</h4>
                        <h3>30 June 2021 12:00 PM</h3>
                        <p>See Details</p>
                    </div>

                </div>

                <div class="uouiam">

                    <div class="li">
                        <h4>Plan Information</h4>
                        <h3 class="desc">{{FloorPlan.description}}</h3>
                    </div>

                    <div class="li">
                        <h4>Amenities</h4>                        
                        <ul class="unit-amenities">
                            <li v-for="amenity in ChoosedAmenities" :key="amenity">
                                <img :src="require(`../../images/amenities/${amenity.icon}`)" />
                                <p>{{amenity.name}}</p>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>
    </div>

    <div v-if="hasNoProperty" class="empty-result animated fadeInUp" style="top: 0px;left: 0px;">
        <img src="./../../images/icons/on-verification.svg">
        <p>It looks like you haven't been assigned to a property yet.<br>Please search property/units where you want to live.</p>
        <div class="btn-primary bphasno" style="margin-top: 10px;" @click="RouteToPage('tenant-search')">
            <p>Search Properties</p>
        </div>
    </div>

    <!-- moveout processes -->
    <RightModal v-if="InitiateMoveOut == true" :form="'initiate-tenant-moveout'"  :data="InitiateMoveOutData" @modalclose="CloseInitiateMoveOut" />
</template>

<script>
    // @ is an alias to /src
    import Document from '../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';
    import Locale from './../../javascript/Locale';

    // @import component
    import Skeletion from './../../components/blocks/Skeleton.vue';
    import UserImage from './../../components/blocks/UserImage.vue';
    import ReplaceImageVue from '../../components/blocks/ReplaceImage.vue';

    import RightModal from './../../components/modal/RightModal.vue';

    // flicking
    import Flicking from "@egjs/vue3-flicking";
    import "@egjs/vue3-flicking/dist/flicking.css";

    // image viewer
    import 'viewerjs/dist/viewer.css';
    import VueViewer from 'v-viewer';

    export default {
        name: 'PropertyDetail',
        // props: ['tenantProfile'],
        props: ['profile', 'properties', 'selected', 'hasNoProperty'],
        components: {
            Skeletion,
            UserImage,
            ReplaceImageVue,

            RightModal,

            Flicking,
            VueViewer
        },

        data () {
            return {
                list: [0, 1, 2, 3, 4],

                TenantProperty : false,

                FloorName : Locale.FloorNumName(),

                tenantAgency : false,
                tenantProperty : false,
                tenantUnit : false,

                // updage gallery
                ShowUnitGallery: false,
                UnitGallery: [],

                Amenities: Locale.UnitAmenities (),
                ChoosedAmenities: [],

                FloorPlan: {},

                InitiateMoveOut: false,
                InitiateMoveOutData: {
                    tenant: {
                        id : this.profile.tenant
                    },
                    unit: this.selected.unit,
                    property: this.selected.property
                },
            }
        },

        async mounted () {

            console.log (this.selected);

            // site title and seo
            Document.DocumentHeadings ({title : 'Property'});

            this.TenantProperty = this.selected;

            // property and owner info
            this.tenantAgency = this.TenantProperty.owner;
            this.tenantProperty = this.TenantProperty.property;
            this.tenantUnit = this.TenantProperty.unit;

            // gallery update
            this.ShowUnitGallery = true;
            // floor plans
            this.FloorPlan = this.tenantUnit.floorplan;
            if (this.FloorPlan) {
                this.UnitGallery = this.tenantUnit.floorplan.gallery;            

                // amenities
                var Amenities = this.tenantUnit.floorplan.amenities.split(',');
                this.ChoosedAmenities = this.Amenities;
                this.ChoosedAmenities = this.ChoosedAmenities.filter(amenity => {
                    for (var i = 0; i < Amenities.length; i++) {
                        if (Amenities[i] == amenity.id) return amenity;
                    };
                });
            }
        },

        methods: {
            RouteToPage (page) {
                this.$router.push({name : page});
            },

            InitiateMoveOutFunc () {
                this.InitiateMoveOut = true;
            },

            CloseInitiateMoveOut (moveout) {
                this.InitiateMoveOut = false;
            },
        }
    };

</script>