<template>
      <div class="prop-form _propscrl">
          <div class="form-area" style="padding-bottom: 65px;">
  
              <form name="propform" id="propform-finance" style="display: block;">
                  <div class="pf--title">
                      <h3>Bulk Rent Payment</h3>
                      <p>Add rented unit or tenant monthy rent at once.</p>
                  </div>
  
                  <div class="pf--input">
                      <p>Choose Property *</p>
                      <input v-model="SelProperty" type="text" placeholder="Choose One" id="Properties" data-type="len" data-max="5" 
                                          readonly 
                                          @click="PropertySingleSelect = true"
                                          :disabled="Requesting || FinanceData.property" />
                      <PropertyList v-if="PropertySingleSelect" :distyle="'top: 154px;position: absolute;height: 355px;width: 360px;left: 63px;overflow-y: scroll;'" :properties="OwnerProperties" @prosel="PropertySelected" />
                  </div>

                  <div v-if="DataLoaded && !Requesting" class="prop--table" style="margin-top: 5px;">
                    <div class="prop-row row-header">
                        <div class="column column40" style="font-weight: 700;"><p>Unit/Due</p></div>
                        <div class="column column30" style="font-weight: 700;"><p>Amount Paid</p></div>
                        <div class="column column30" style="font-weight: 700;"><p>Note</p></div>
                    </div>

                    <div class="prop-row" v-for="data in BulkPaymentData" :key="data.unit">
                        <div class="column column40">
                            <div class="brp-unit">
                                <h3>{{ data.unit_name }}</h3>
                                <p v-if="data.balance < 0">Due: {{ data.balance.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            }) }}</p>
                                <p v-else-if="data.balance == 0" style="color: rgb(179 179 179) !important">Balance is maintained ($0)</p>
                                <p v-else style="color: #65a700 !important">Overpaid: {{ data.balance.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            }) }}</p>
                            </div>
                        </div>
                        <div class="column column30">
                            <div class="brp-input">
                                <input type="number" v-model="data.paid" class="number" placeholder="Enter amount..">
                            </div>
                        </div>
                        <div class="column column30">
                            <div class="brp-input">
                                <input type="text" v-model="data.note" placeholder="Remarks..">
                            </div>
                        </div>
                    </div>

                </div>

                <div v-if="Requesting == true" 
                    style="display: flex;flex-direction: column;justify-content: center;align-items: center;width: 100%;height: auto;padding-top: 50px;">
                    <Skeleton :data="2" />
                </div>

                <div v-if="!DataLoaded && !Requesting" class="animated fadeInUp" style="display: flex;flex-direction: column;justify-content: center;align-items: center;width: 100%;height: auto;padding-top: 50px;" >
                    <img src="./../../../images/icons/on-verification.svg" style="width: 200px;">
                    <p style="text-align: center;width: 80%;font-size: 11pt;line-height: 17pt;margin-top: 15px;color: black;">To view the outstanding balance for each unit or tenant, please select one of your properties.</p>
                </div>
  
              </form>
  
          </div>
  
          <div class="form-action">
              <div class="form-area">
                  <div v-if="!Requesting && PreviousPage" class="btn-secondary process-back" @click="getBackToFirstPage"><img src="./../../../images/icons/arrow-left-blue.svg" /></div>
                  <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="AddBulkPayment" style="display: block;width: 120px;margin-top: 15px;float: left;">
                      <p v-if="!Requesting">Add Payment</p>
                      <img v-else class="loader" src="./../../../images/gif/btn-loader.gif" />
                  </span>
                  <span v-if="formError" class="__formerror">{{ infoText }}</span>
              </div>
          </div>
  
      </div>      
</template>
  
  <script>
  
      // scripts
      import Locale from '../../../javascript/Locale';
      import FormValidation from '../../../javascript/FormValidation';
      import Request from '../../../javascript/getRequest';
      import DataCenter from '../../../javascript/DataCenter';
      import Handler from '../../../javascript/Handler';
  
      // components
      import PropertyList from '../../blocks/PropertyList.vue';
      import Skeleton from '../../blocks/Skeleton.vue';
  
      // pinia
      import { useOwnerStore } from './../../../stores/OwnerStore';
  
      export default {
          name: 'BulkRentPayment',
          props: ['data'],
          emits: ['openmainpage', 'closepage'],
  
          components: {
              PropertyList,
              Skeleton,
          },
  
          data () {
              return {
                  // fetching data or not
                  Requesting: false,
                  DataLoaded: false,
                  PreviousPage: this.data.isMainPage,
  
                  // Form Modal
                  UnitTransType : '-1',
                  TransAmount : '',
                  Remarks: '',
  
                  // form handler
                  formError: false,
                  infoText: '',
  
                  // Properties list
                  SelProperty: '',
                  OwnerProperties : false,
                  SelectedProperty : false,
                  SelectProperty : false,
                  PropertySingleSelect: false,
  
                  // pinia storage
                  OwnerStore: useOwnerStore (),  
                  FinanceData: {},
  
                  // for remarks i.e. notes
                  TransactionName : Locale.TransactionType(),
                  BulkPaymentData: [],
              }
          },
  
          mounted () {

            this.FinanceData = this.OwnerStore.financepaymentdata;
  
              // if finance add transaction from internal property
                if (this.FinanceData.property) {
                    this.PropertySelected (this.FinanceData.whichProperty);
                }
  
              // update pinia storage to local variables
              this.OwnerProperties = this.OwnerStore.properties;
  
          },
  
          methods: {
  
              // go the first page i.e. choose payment type
              getBackToFirstPage () {
                  this.$emit('openmainpage');
              },
  
              // add new business expense
              async AddBulkPayment () {
                  if (this.Requesting) return;
                  this.formError = false;
  
                  // form & validation
                  let form = document.getElementById('propform-finance');
                  let canGoAhead = FormValidation.Validate (form);
  
                  if (canGoAhead) {
                      this.Requesting = true;
  
                      // collect form data
                      let formData = new FormData();
                      formData.append('property', this.SelectedProperty);
                      formData.append('unitinfo', JSON.stringify(this.BulkPaymentData));
  
                      // upload and load data from server
                      const ReturnData = await Request.postServer('owner/finance/bulkpayupdate.php', formData);
                      this.Requesting = false;
  
                      // now check if the email already exists
                      if (ReturnData.error == false) {
                          // now close modal, and load current property
                          // update notification
                          Handler.PropNotification ('Successfully created bulk rent transactions.');
                          this.$emit('closepage', ReturnData.response);
  
                      }else {
                          this.formError = true;
                          this.infoText = "Something went wrong! Please try again.";
                      } 
                  }
  
  
              },
  
              PropertySelected (property) {
                  if (property !== null) {
                      // remove the selected unit
                      this.SelUnit = '';
  
                      this.SelProperty = property.name;
                      this.SelectedProperty = property.id;
  
                      // now load its unit too
                      this.LoadPropertUnitPaymentInformation (property.id);
                  }this.PropertySingleSelect = false;
              },

              async LoadPropertUnitPaymentInformation (property) {
                this.Requesting = true;
                this.DataLoaded = false;
                const UnitsInfo = await DataCenter.ProPRequest('units-payment-info', false, {
                    key : 'property',
                    value : property
                });

                this.BulkPaymentData = UnitsInfo;
                this.Requesting = false;
                this.DataLoaded = true;
              },  
  
  
          }
          
      }
  </script>

  <style>

div.brp-unit {    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;}
div.brp-unit h3 {    font-weight: 500 !important;}
div.brp-unit p {    color: #ff5f5f !important;
    font-weight: 600;
    line-height: 20pt;
    letter-spacing: 0px !important;
    font-size: 9pt !important;}
div.brp-input {width: 90%;}
div.brp-input input {     width: 100%;
    border: none;
    font-size: 11pt;
    padding: 10px;
    border: 1px solid white;
    border-radius: 4px;}
    div.brp-input input.number {width: 85%;}
    div.brp-input input::placeholder {    font-size: 10pt;
    color: #a5a5a5;}
    div.brp-input input:focus {border: 1px solid #449adf;box-shadow: 0px 0px 10px rgb(68 154 223 / 26%);}
    div.brp-input input:hover {border: 1px solid #449adf;}

</style>