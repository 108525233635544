<template>
      <WebNav />

        <!-- internal header hero -->
        <InternalHeader  :page="'web-tenants'" 
                    :title="'Simplify Your Rental Experience with PropStop'" 
                    :description="'PropStop offers tenants a streamlined rental experience with digital rent payments, easy service requests, and effective communication tools. Learn how PropStop can make your rental experience better.'" />
        <!-- internal header hero -->

        <!-- our story -->
        <section class="about" style="padding-bottom: 65px;">
            <div class="wrapper" style="padding: 0;">
                    <div class="abwr-about atbout-resp" style="flex-direction: row-reverse;">
                        <div class="image" style="height: unset">
                                <img src="./../../images/website/landlords.svg" alt="Tenants">
                        </div>
                        <div class="content">
                                <span>Tenants</span>
                                <h2>Your Home, <em><p>Simplified</p></em> with PropStop.</h2>
                                <p>At PropStop, we believe that your rental experience should be as comfortable and hassle-free as your own home. We're dedicated to empowering tenants with tools and services that make living in your rental property a breeze.</p>
                        </div>
                    </div>
            </div>
        </section>
        <!-- our story -->


        <section class="more-contents">
            <div class="wrapper" style="padding: 0px;">
                <ul>
                    <li>
                        <div class="image">
                            <img src="./../../images/website/payment-transfer.svg" />
                        </div>
                        <div class="content">
                            <h2>Easy Online Rent Payments</h2>
                            <p><strong>No more checks, no more mail.</strong> Pay your rent online through PropStop's secure platform. Track your payment history, set up automatic payments, and never worry about late fees again.</p>
                        </div>
                    </li>

                    <li>
                        <div class="image">
                            <img src="./../../images/website/maintanence-request.svg" />
                        </div>
                        <div class="content">
                            <h2>Maintenance Requests Made Simple</h2>
                            <p><strong>Report issues in a few clicks.</strong> Use PropStop to submit maintenance requests anytime, anywhere. Track the status of your request and communicate directly with property management to ensure timely repairs.</p>
                        </div>
                    </li>

                    <li>
                        <div class="image">
                            <img src="./../../images/website/communication.svg" />
                        </div>
                        <div class="content">
                            <h2>Communication at Your Fingertips</h2>
                            <p><strong>Stay updated, always.</strong> With PropStop, receive important updates from your landlord or property manager directly. Whether it's about maintenance schedules or community events, you'll never be out of the loop.</p>
                        </div>
                    </li>

                    <li>
                        <div class="image">
                            <img src="./../../images/website/documents.svg" />
                        </div>
                        <div class="content">
                            <h2>Features Designed for Tenants:</h2>
                            <p><strong>Online Rent Payments:</strong> Secure and straightforward rent transactions.</p>
                            <p><strong>Maintenance Requests:</strong> Quick submission and tracking of repair requests.</p>
                            <p><strong>Direct Communication:</strong> Efficient communication channels with your landlord.</p>
                            <p><strong>Document Storage:</strong> Easy access to all your rental documents in one place.</p>
                        </div>
                    </li>


                </ul>
            </div>
        </section>

        <section class="counters" style="position: relative;">
            <div class="wrapper" style="background-color: transparent; padding: unset; padding: 0">
                    <div class="countper">
                        <h2>Join PropStop <em><p style="color: white !important;font-weight: 700;">Today</p></em></h2>
                        <p>Experience a rental experience where convenience meets comfort. Join PropStop, and let's make your rental journey smoother than ever.</p>
                        <div class="info-tenant psanim-1 fadeInDown" v-if="ShowTenantSignupPopUp">
                            <img src="./../../images/icons/close.svg" @click="ShowTenantSignupPopUp = false" alt="Close">
                            <p>Only invited tenants can use this service. Please contact your property manager and ask them to join for free!</p>
                        </div>
                        <div class="site-btn-dark" @click="ShowTenantSignupPopUp = true">
                            <p>Sign Up as a Tenant</p>
                        </div>
                        <p style="font-size: 9pt;color: #a7a7a7;">Welcome to the PropStop family --where property management meets innovation</p>
                    </div>
            </div>
        </section>

      <Footer />
</template>

<script>
      // import Website Navigation
      import WebNav from './../../components/website/WebNav.vue';
      import Seprator from './../../components/website/Seprator.vue';
    import InternalHeader from '../../components/website/InternalHeader.vue';
      import Footer from './../../components/website/Footer.vue';

      export default {
        name : 'Tenants',
        components : {
            WebNav,
            Seprator,
            InternalHeader,
            Footer
        },
        data () {
            return {
                ShowTenantSignupPopUp: false,
            }
        },

        mounted () {
            // scroll to top
            window.scrollTo(0, 0);

            // remove styling used for apps
            document.getElementById('propstop-app').style.height = 'auto';
            document.getElementById('propstop-app').style.overflow = 'auto';

            //  change the title
            document.title = "For Tenants: Simplify Your Rental Experience with PropStop";

        }
    }

</script>