<template>
    <div class="pf--input" style="position: relative;">
        <p>Password *</p>
        <input type="password" placeholder="************" autocomplete="new-password" v-model="Password" @focus="ShowPasswordInfo = true" @keyup="HandelPasswordInputStrength" />
        <div class="strength-password" v-if="StartPasswordStrength">
            <div class="spbox" ref="spbox"><span class="transAe"></span></div>
            <p ref="spboxinfo"></p>
        </div>

        <div id="PasswordInfo" v-if="ShowPasswordInfo == true" @click="ShowPasswordInfo = false" class="password-info animated fadeInRight" :class="direction == 'left' ? 'pi-left' : ''">Please change your password to be secure about your personal profile.</div>

    </div>
</template>

<script>
    export default {
        name: 'PasswordInput',
        props: ['direction'],
        emits: ['passwordchange'],
        data () {
            return {
                Password: '',
                ShowPasswordInfo : false,
                StartPasswordStrength : false,
            }
        },

        methods: {
            
            // password strength handler
            HandelPasswordInputStrength () {
                var strength;

                // start password strength check
                this.StartPasswordStrength = true;

                // add new data to the information box
                let pwdinfoEl = document.getElementById('PasswordInfo');
                let passwordInfo = `<ul>
                                        <li>The password length must be 8 characters or longer.</li>
                                        <li>The password must contain at least 1 lowercase  and 1 uppercase alphabetical character.</li>
                                        <li>The password must contain at least 1 numeric character.</li>
                                        <li>The password must contain at least one special character.</li>
                                    </ul>`;
                if (pwdinfoEl != null) pwdinfoEl.innerHTML = passwordInfo;

                var value = this.Password;

                var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
                var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

                // remove previous styles
                this.$refs.spbox.classList.remove('sb-green', 'sb-orange', 'sb-red');

                if(strongRegex.test(value)) {
                    this.$refs.spbox.classList.add('sb-green');
                    this.ShowPasswordInfo = false;
                    strength = 'green';
                }else if(mediumRegex.test(value)) {
                    this.$refs.spbox.classList.add('sb-orange');
                    this.ShowPasswordInfo = false;
                    strength = 'orange';
                }else {
                    this.$refs.spbox.classList.add('sb-red');
                    strength = 'red';
                }

                this.$emit('passwordchange', this.Password, strength);

            }

        }
    };
</script>