<template>
    <div class="form-area animated fadeInRight">
        
        <div class="lb-logo" style="display: none;">
            <img src="../../images/website/propstop-logo.svg" alt="PropStop" title="PropStop" />
            <h2>PROPSTOP</h2>
            <p>Create New Customer Account!</p>
        </div>

        <div class="input-form">
            <form name="propform" id="propform-signup">
                <div class="pf--input">
                    <p>First Name</p>
                    <input type="text" placeholder="Jon" v-model="FirstName" id="FirstName" data-type="len" data-max="3" />
                </div>

                <div class="pf--input">
                    <p>Last Name</p>
                    <input type="text" placeholder="Doe" v-model="LastName" id="LastName" data-type="len" data-max="3" />
                </div>

                <div class="pf--input">
                    <p>Email Address</p>
                    <input type="text" placeholder="jon.doe@mail.com" v-model="Email" id="Email" data-type="email" />
                </div>

                <div class="pf--input">
                    <PasswordInput @passwordchange="HandlePasswordInput" :direction="'left'" />
                </div>

                <div class="pf--input" style="overflow: hidden;">
                    <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="CreateNewAccount">
                        <p v-if="!Requesting">Create Account</p>
                        <img v-else class="loader" src="./../../images/gif/btn-loader.gif" alt="Loader" />
                    </span>
                </div>
            </form>
        </div>

        <div class="oth-action">
            <span class="link" @click="showLoginScreen">Already have account?</span>
            <span v-if="userDuplicate || signupError" class="__dubplicate">{{ infoText }}</span>
        </div>
    </div>
</template>

<script>
    //@ JS Modules
    import Document from './../../javascript/Document';
    import Request from './../../javascript/getRequest';

    import FormValidation from './../../javascript/FormValidation';
    import PasswordInput from './../web-forms/PasswordInput.vue';

    export default {
        name : "Signup",
        emits: ['signup'],
        components: {
            PasswordInput,
        },
        data () {
            return {
                FirstName : '',
                LastName : '',
                Email: '',
                Password: '',
                passwordStrength : 'red',
                UserType: 'Owner',
                Requesting : false,

                infoText: '',
                userDuplicate: false,
                signupError: false,
            }
        },

        mounted () {
            // site title and seo
            Document.DocumentHeadings ({title : 'Sign Up - PropStop'});
        },

         methods : {

            // go to login screen
            showLoginScreen () {
                this.$emit('signup', false);
            },
            
            HandlePasswordInput (password, strength) {
                this.Password = password;
                this.passwordStrength = strength;
            },
            
            // create new account, validation etc
            async CreateNewAccount () {
                if (this.Requesting) return;

                // set user duplicate as false
                this.userDuplicate = false;
                this.signupError = false;
                this.infoText = '';

                // form & validation
                let form = document.getElementById('propform-signup');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead && (this.passwordStrength == "green" || this.passwordStrength == "orange")) {
                    this.Requesting = true;

                    // create new formdata
                    let formData = new FormData();

                    // add datas
                    formData.append('firstname', this.FirstName);
                    formData.append('lastname', this.LastName);
                    formData.append('email', this.Email);
                    formData.append('password', this.Password);
                    formData.append('usertype', this.UserType);

                    // request to server
                    const ReturnData = await Request.postServer('auth/signup.php', formData);
                    this.Requesting = false;
                    
                    // now check if the email already exists
                    if (ReturnData.success == false) {
                        if (ReturnData.reason == "duplicate") {
                            this.userDuplicate = true;
                            this.infoText = "This user is already registered with PropStop. Please try login with your email address " + this.Email;
                        }else {
                            this.signupError = true;
                            this.infoText = "Something went wrong! Please try again later or contact Support";
                        }
                    }else if (ReturnData.success == true) {                        
                        // go to login page
                        this.$emit('signup', {'fullname' : this.FirstName, 'email' : this.Email});
                    }else {
                        this.signupError = true;
                        this.infoText = "Something went wrong! Please try again later or contact Support";
                    }
                }
            }
        },        
    };
</script>

<style scoped>
    .primary-btn-loader {    display: block;
    float: none !important;
    width: calc(100% - 63px) !important; text-transform: uppercase;}
</style>