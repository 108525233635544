<template>
    <div class="tinput">
        <p>{{label}} <strong v-if="required">*</strong></p>
        <textarea :placeholder="placeholder" @input="inputData ($event.target.value)" :style="`height:${height}px`"></textarea>
    </div>
</template>

<script>
    export default {
        name: 'TextAreaInput',
        props: ['label', 'height', 'required', 'placeholder'],
        methods : {
            inputData (value) {
                this.$emit('inputvalue', value);
            }
        }
    };
</script>