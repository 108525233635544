<template>
    <div class="prop-form _propscrl">
        <div class="form-area" style="margin-bottom: 100px;">
            <form id="propform-profile" autocomplete="off" style="display: block;">
                <div class="pf--title">
                    <h3>Update Your Profile</h3>
                    <p>You can update your personal information from here.</p>
                </div>

                <div class="pf--info" style="margin-bottom: 45px;">
                    <div class="icon">
                        <img src="./../../../images/icons/info-blue.svg" />
                    </div>
                    <div class="content">
                        <p>Once you sbumit your personal information, it will be updated your profile.</p>
                    </div>
                </div>

                    
                <div class="pf--multi-input" style="height: 75px;">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>First Name</p>
                            <input type="text" placeholder="Jon" v-model="FirstName" id="FirstName" data-type="len" data-max="3" />
                        </div>
                    </div>
                    <div class="half hright">
                        <div class="pf--input">
                            <p>Last Name</p>
                            <input type="text" placeholder="Doe" v-model="LastName" id="lastName" data-type="len" data-max="3" />
                        </div>
                    </div>
                </div>

                <div class="pf--multi-input">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Email Address</p>
                            <input type="text" :value="data.email" disabled />
                        </div>
                    </div>
                    <div class="half hright">
                        <div class="pf--input">
                            <p>Password</p>
                            <input type="password" value="************" disabled/>
                        </div>
                    </div>
                </div>

                <div class="pf--input" style="overflow:hidden;">
                    <p>Mobile/Phone</p>
                    <input type="text" placeholder="+1 818 555 1212" v-model="Mobile" id="Mobile" data-type="len" data-max="5" />
                </div>

                <div class="pf--multi-input">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Date of Birth</p>
                            <Datepicker class="dp__input_custom" v-model="userDOB" :enableTimePicker="false" autoApply required placeholder="Pick Date" />
                        </div>
                    </div>
                    <div class="half hright">
                        <div class="pf--input">
                            <p>Preferred Pronoun</p>
                            <select name="floor" v-model="Gender" id="Gender" data-type="select">
                                <option value="-1" selected>Select One</option>
                                <option value="He">He/Him</option>
                                <option value="She">She/Her</option>
                                <option value="They">They/Them</option>
                            </select>
                        </div>
                    </div>
                </div>

            </form>
        </div>

        <div class="form-action">
            <div class="form-area">
                 <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="HandleSubmitForm">
                    <p v-if="!Requesting">Update Profile</p>
                    <img v-else class="loader" src="./../../../images/gif/btn-loader.gif" />
                </span>
                <span v-if="formError" class="__formerror">{{ infoText }}</span>
            </div>
        </div>
    </div>
</template>

<script>

    //@ JS Modules
    import Request from '../../../javascript/getRequest';
    import Handler from '../../../javascript/Handler';
    import FormValidation from '../../../javascript/FormValidation';

    // datepicer
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    export default {
        name: 'UpdateEmployeeProfile',
        props: ['data'],
        emits: ['close'],
        components: {
            Datepicker,

        },

        data () {
            return {
                Requesting: false,

                FirstName : this.data.fname,
                LastName : this.data.lname,
                Mobile : this.data.mobile,
                userDOB : this.data.birthday,
                Gender : this.data.gender,

                // form error handler
                formError : false,
                infoText : '',
            }
        },

        methods: {

            // handle submit the form
            async HandleSubmitForm () {
                if (this.Requesting) return;

                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-profile');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead) {
                    this.Requesting = true;

                    let formData = new FormData();
                    formData.append('fname', this.FirstName);
                    formData.append('lname', this.LastName);
                    formData.append('mobile', this.Mobile);
                    formData.append('dob', this.userDOB);
                    formData.append('gender', this.Gender);
                    
                    // send and receive data
                    const ReturnData = await Request.postServer('admin/user/update-profile.php', formData);
                    this.Requesting = false;
                    
                    if (!ReturnData.error) {
                        // update notification
                        Handler.PropNotification ('Your profile information has been updated, please reload app for better performance.');
                        location.reload();
                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again later!"
                    }                   

                }
            },
        }
    };
</script>