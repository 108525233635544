<template>
    <div class="property-list animatedss fadeInUp _propscrl" :style="distyle">
        <ul class="_propscrl">            
            <li class="transAe" style="height: auto; display: flex;" v-for="prop in properties" :key="prop" @click="propertySel(prop)">
                <div class="image">
                    <ReplaceImage :source="prop.property.image" />
                </div>

                <div class="content">
                    <h3 v-if="prop.isRented == 'true'">{{ prop.unit.unit_name }}</h3>
                    <h3 v-else style="color:#ff5722">(Unit not assigned yet!)</h3>
                    <p>{{ prop.property.name }}</p>
                    <p>{{ prop.property.address }}</p>                    
                </div>
            </li>
        </ul>
    </div>

    <div class="overlay" @click="closeList" style="opacity: 0;"></div>
</template>

<script>
    
    //@ import components blocks
    import ReplaceImage from './ReplaceImage.vue';


    export default {
        name : 'PropertyList',
        props: ['distyle', 'properties'],
        emits: ['prosel', 'proboxclse'],
        components : {
            ReplaceImage
        },

        mounted () {
            // console.log (this.properties);
        },

        methods : {
            propertySel (property) {
                this.$emit('prosel', property);
            },

            closeList () {
                this.$emit('proboxclse');
            }
        }
        
    };
</script>