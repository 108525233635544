<template>
    <div class="gs--body">
        <h3>Confirmation!</h3>
        <p>I understand that abusing this function may lead to the suspension of my account and would like to proceed with the claim.</p>

        <div class="pf--input" style="margin-top: 15px;">
            <p>Dispute Note *</p>
            <textarea placeholder="Write us in brief about the property and dispute remarks" v-model="Notes"></textarea>
        </div>
    </div>

    <div class="gs--action">
        <span class="primary-btn-loader pbl" :class="Requesting ? '' : ''" @click="confirmModal()" style="width: 120px; display: block;float: left;margin-top: 10px;">
            <p v-if="!Requesting">Yes</p>
            <img v-else class="loader" src="./../../../images/gif/btn-loader.gif" />
        </span>

        <span class="primary-btn-loader pblsec" @click="CloseModal" style="width: 120px; display: block;float: left;margin-top: 10px;">
            <p>No</p>
        </span>
    </div>
</template>

<script>
    
    import Request from './../../../javascript/getRequest';

    export default {
        name: 'ConfirmCompleteTask',
        props: ['data'],
        emits: ['close'],
        data () {
            return {
                Requesting : false,
                Notes: '',
            }
        },
        
        mounted () {
            // console.log (this.data);
        },
        
        methods : {
            async confirmModal () {
                if (this.Notes.length <= 3) {
                    alert('Please write more in detail');
                }else {
                    this.Requesting = true;

                    // upadte to the server
                    let formData = new FormData();
                    formData.append('owner', this.data.owner);
                    formData.append('property', this.data.property);
                    formData.append('propowner', this.data.property_owner);
                    formData.append('note', this.Notes);

                    // load data from server
                    await Request.postServer('property/dispute.php', formData);

                    this.$emit('close', true);
                }
            },

            CloseModal () {
                this.$emit('close', false);
            }
        }
    };
</script>