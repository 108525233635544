<template>
    <div class="prop-form _propscrl">        
        <div class="form-area" style="padding-bottom: 65px;">
            <form name="propform" id="propform-document" style="display: block;">
                <div class="pf--title">
                    <h3>Contract Document Information</h3>
                    <p>Update your contract document here!</p>
                </div>
                
                <div class="pf--input">
                    <p>Contract Title</p>
                    <input type="text" placeholder="Title" id="Title" v-model="Title" data-type="len" data-max="3" />
                </div>

                <div class="pf--input">
                    <p>Contract Description</p>
                    <input type="text" placeholder="Description" v-model="Description" id="Description" data-type="len" data-max="3" />
                </div>

                <div class="pf--multi-input" style="height:80px;">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Start Date</p>
                            <Datepicker class="dp__input_custom"  :enableTimePicker="false" autoApply required placeholder="Pick Date" v-model="Startdate" />
                        </div>
                    </div>

                    <div class="half hright">
                        <div class="pf--input">
                            <p>End Date</p>
                            <Datepicker class="dp__input_custom"  :enableTimePicker="false" autoApply required placeholder="Pick Date" v-model="Enddate" />
                        </div>
                    </div>                    
                </div>

                <div class="pf--input">
                    <p>Contract Type</p>
                    <select  id="CType" data-type="select" v-model="Contracttype">
                        <option value="-1">- Select One -</option>
                        <option value="Lease">Lease</option>
                        <option value="Monthly">Monthly</option>
                    </select>
                </div>

                <div class="pf--input">
                    <div class="pretty p-switch p-fill" style="border: none;">
                        <input type="checkbox" v-model="Ownersign">
                        <div class="state p-success">
                            <label>Owner Signature</label>
                        </div>
                    </div>
                </div>

                <div class="pf--input">
                    <div class="pretty p-switch p-fill" style="border: none;">
                        <input type="checkbox" v-model="TenantSign">
                        <div class="state p-success">
                            <label>Tenant Signature</label>
                        </div>
                    </div>
                </div>

                <div class="pf--input">
                    <div class="pretty p-switch p-fill" style="border: none;">
                        <input type="checkbox" v-model="ByPassSign">
                        <div class="state p-success">
                            <label>ByPass Signature</label>
                        </div>
                    </div>
                </div>

            </form>
            
            <span v-if="formError" class="__formerror">{{ infoText }}</span>
        </div>

        <div class="form-action">
            <div class="form-area">
                <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="UpdateDocument">
                    <p v-if="!Requesting">Update</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    //@ JS Modules
    import Request from './../../javascript/getRequest';
    import DataCenter from './../../javascript/DataCenter';
    import FormValidation from './../../javascript/FormValidation';

    // datepicer
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    export default {
        name: 'ViewContract',
        props: ['data'],
        emits: ['close'],
        components: {
            Datepicker,
        },

        data () {
            return {

                // for default image on property
                Title: this.data.title,
                Description: this.data.description,
                Startdate: this.data.startdate,
                Enddate: this.data.enddate,
                Contracttype: () => {
                    if (this.data.monthly) return 'Monthly';
                    else return 'Lease';
                },
                Ownersign: this.data.ownersign,
                TenantSign: this.data.tenantsign,
                ByPassSign: this.data.bypasssign,

                // form error
                formError : false,
                infoText : '',
                Requesting: false,
            }
        },

        mounted () {

        },

        methods: {
            

            // update the image 
            async UpdateDocument () {
                if (this.Requesting) return;

                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-document');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead) {
                    this.Requesting = true;

                    let formData = new FormData();
                    formData.append('blobid', this.Current.blobid);
                    formData.append('property', this.Property.id);
                    formData.append('title', this.Title);
                    formData.append('description', this.Description);
                    formData.append('propertydefault', this.SetPropertyDefault);
                    formData.append('delete', this.DeleteImage);
                    formData.append('extension', this.Current.extension);
                    formData.append('from', this.ViewerType);

                    if (this.ViewerType == 'floorplan') {
                        formData.append('floorplan', this.data.floorplan);
                    }

                    // submit tasks data
                    const ReturnData = await Request.postServer('owner/upload/gallery-update.php', formData);
                    this.Requesting = false;

                    // now check if the email already exists
                    if (ReturnData.error == false) {
                        var Update = {
                            isDefault : this.SetPropertyDefault,
                            DefaultUrl : this.Current.url,
                            Gallery: ReturnData.response
                        };
                        // now close modal, and load current property
                        this.$emit('close', Update);
                    
                    // if error
                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! ";
                    }
                }



            }

            
        }


    };
</script>

<style scoped>
span.__formerror {margin-left: 0 !important;}
.v-calendar {width: calc(100% - 2px) !important;min-width: 100%;}
</style>