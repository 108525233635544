<template>
    <div class="ticket-details-content">
        <div class="lft-sid">
            <div class="lft-heading">
                <div class="backbtn" @click="backtoList">
                    <img src="../../images/tickets/back-btn.png">
                </div>
                <div class="headngs">
                    <p>{{ticket.subject}}</p>
                    <p class="nam-dtl"><a href="#">{{ticket.requested.fullname}}</a> {{ticket.created}}</p>
                </div>
                <div class="ticket-setting">
                    <img src="../../images/icons/ticket-setting.svg" @click="TicketSetting = true">
                    <ul class="action-menu" v-if="TicketSetting == true" style="top: 0;">
                        <li><img src="./../../images/icons/close.svg" @click="TicketSetting = false"/></li>
                        <li v-if="ticket.status != 'Closed'" @click="EditTicket"><img src="./../../images/icons/edit.svg" class="icon" /> <p>Edit Ticket</p></li>

                        <li @click="UpdateTicketFavourite">
                            <img src="./../../images/icons/star.svg" class="icon" /> 
                            <p v-if="ticket.isFavourite">Remove favorite</p>
                            <p v-else>Add to favorite</p>
                        </li>

                        <li v-if="ticket.status != 'Closed'" @click="CloseCurrentTicet">
                            <img src="./../../images/icons/complete-ticket.svg" class="icon" />
                            <p>Close Ticket</p></li>
                        
                            <li v-if="!ticket.isArchive" @click="WantArchiveTicket = true; TicketSetting = false">
                            <img src="./../../images/icons/archive.svg" class="icon" />
                            <p>Archive Ticket</p></li>
                    </ul>
                </div>
            </div>

            <div class="ticket-summery _propscrl" id="ticketSummery">
                <!-- Skeleton -->
                <div v-if="!TicketDetail" style="margin-top:55px">
                    <Skeletion :type="'large-list'" :data="2" />
                </div>

                <!-- If no data on result -->
                <div v-if="TicketDetail.length <= 0" class="empty-result animated fadeInUp">
                    <img src="../../images/icons/empty-result.svg" />
                    <p>Ticket has been created at {{ticket.created}} by you. <br> But no thread has been started yet.</p>
                </div>

                <!-- Ticket details -->
                <ul v-if="TicketDetail && TicketDetail.length >= 1" class="ticket-threads">
                    <li class="info">
                        <p>You created ticket at {{ticket.created}}</p>
                    </li>

                    <li class="info" v-if="isToVisit">
                        <p>This ticket was created to visit one of your property <strong>{{ MoveOutProperty.name }}</strong>. Please check following information!</p>
                    </li>

                    <li class="table" v-if="isToVisit">
                        <p>You can choose date to visit your property for the tenant!</p>
                        <div class="prop--table ticket-table" style="display: flex;flex-direction: column;align-items: center;justify-content: flex-start;border: 1px solid #dbdbdb;margin: 10px 0;border-radius: 7px; padding:10px;">
                            <div class="prop-row row-header" style="padding-bottom: 20px !important;">
                                <div class="column column50"><p>Date</p></div>
                                <div class="column column50"><p>Action</p></div>
                            </div>

                            <div class="prop-row" v-for="visit in VisitsDate" :key="visit.id">
                                <div class="column column50"><p>{{ visit.date }}</p></div>
                                <div class="column column50">
                                    <p v-if="visit.selected">Selected</p>
                                    <span class="prcol-link" v-else @click="SelectVisitDate (visit)">Invite</span>
                                </div>
                            </div>
                        </div>                          
                    </li>

                    <li class="table" v-if="isMoveOut">
                        <p>Deposit History of current Unit</p>
                        <div class="prop--table ticket-table" style="display: flex;flex-direction: column;align-items: center;justify-content: flex-start;border: 1px solid #dbdbdb;margin: 10px 0;border-radius: 7px; padding:10px;">
                            <div class="prop-row row-header" style="padding-bottom: 20px !important;">
                                <div class="column column30"><p>Type</p></div>
                                <div class="column column30"><p>Notes</p></div>
                                <div class="column column40"><p>Amount</p></div>
                            </div>

                            <div class="prop-row">
                                <div class="column column30"><p>Initial Depost</p></div>
                                <div class="column column30"><p>note</p></div>
                                <div class="column column40"><p>${{ initdeposit }}</p></div>
                            </div>

                            <div class="prop-row" v-for="history in deposits" :key="history.id">
                                <div class="column column30"><p>{{history.additional}}</p></div>
                                <div class="column column30"><p>{{history.notes}}</p></div>
                                <div class="column column40"><p>${{ history.amount }}</p></div>
                            </div>
                        </div>                          
                    </li>

                    <li class="info" v-if="isMoveOut">
                        <p v-if="balance < 0" style="color: #f00;">Unit Current Balance: -${{-balance}}</p>
                        <p style="color: #919191;" v-else>Balance: ${{balance}}</p>
                    </li>

                    <li v-for="detail in TicketDetail" :key="detail">
                        <div v-if="detail.type == 'Info'" class="info">
                            <p>{{detail.content}}</p>
                        </div>

                        <div v-else :class="detail.private == 'true' ? 'private' : ''">
                            <div class="image">
                                <div class="avatar">
                                    <UserImage :init="detail.threadby.fullname" :back="detail.threadby.back" :source="''" />
                                </div>
                            </div>

                            <div class="content">
                                <div class="user">
                                    <h3>{{detail.threadby.fullname}}</h3>
                                    <p>{{detail.created}}</p>
                                </div>

                                <div class="message">
                                    <p>{{detail.content}}</p>

                                    <ul class="attachment-files" v-if="detail.type = 'Blob'">
                                        <li v-for="file in detail.attachments" :key="file.id" @click="ViewAttachment(file)">
                                            <div class="atch-icon">
                                                <img v-if="file.extension == 'doc' || file.extension == 'docx'" src="./../../images/attachment/atch-docx.svg" />

                                                <img v-else-if="file.extension == 'xls' || file.extension == 'xlsx'" src="./../../images/attachment/atch-xlsx.svg" />

                                                <img v-else-if="file.extension == 'ppt' || file.extension == 'pptx'" src="./../../images/attachment/atch-pptx.svg" />

                                                <img v-else-if="file.extension == 'jpeg' || file.extension == 'jpg' || file.extension == 'png'" src="./../../images/attachment/atch-image.svg" />

                                                <img v-else-if="file.extension == 'pdf'" src="./../../images/attachment/atch-pdf.svg" />

                                                <img v-else src="./../../images/attachment/atch-others.svg" />
                                            </div>
                                            
                                            <div class="atch-content">
                                                <h3>{{file.title}}</h3>
                                                <ul class="ac-info">
                                                    <li><p>{{detail.created}}</p></li>
                                                    <li><p>{{file.size}} kb</p></li>
                                                </ul>
                                            </div>
                                            
                                            <div class="atch-actions">
                                                <span>
                                                    <a :href="file.url" target="_blank" v-if="file.url">
                                                        <img src="./../../images/icons/download.svg" />
                                                    </a>
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="table -ticket-action" v-if="isMoveOut">
                        <span @click="UploadTicketAttachment ()">Upload Property Images</span>
                        <span @click="UploadTicketAttachment ()">Upload Documents</span>
                        <span @click="CompleteMoveOut = true">Complete Move Out (Unassign)</span>
                    </li>

                    <li class="table -ticket-action" v-if="isToVisit && ticket.status != 'Closed'">
                        <span>Assign Tenant to Unit</span>
                        <span @click="CancelTheTenantVisit">Cancel this request</span>
                    </li>

                    <li v-if="ticket.status == 'Closed'" class="alert">
                        <p>Ticket has been closed!</p>
                    </li>
                </ul>
            </div>

            <div class="ticket-reply" :class="Private ? 'privatebox' : ''">
                <div class="mesz-private">
                    <div class="pretty p-switch p-fill">
                        <input type="checkbox" v-model="Private">
                        <div class="state p-success">
                            <label>Private reply</label>
                        </div>
                    </div>
                </div>

                <div class="mesz-input" :class="Private ? 'privatereply' : ''">
                    <textarea v-if="!ticket.isArchive && ticket.status != 'Closed'" placeholder="Type a ticket reply here.." v-model="Message" :class="Private ? 'privateinput' : ''"></textarea>
                    <textarea v-else placeholder="Ticket closed or archived!.." readonly></textarea>
                </div>
                <div class="mesz-actions">
                    <ul class="mesz-icons">
                        <li><img src="../../images/tickets/smiley.svg"></li>
                        <li><img src="../../images/tickets/attachment.svg" @click="UploadTicketAttachment ()"></li>
                        <li><img src="../../images/tickets/response.svg"></li>
                        <!-- <li v-if="hasAttachments" class="animated fadeInUp" @click="hasAttachments = false">
                            <p>{{TicketAttachments.length}} files ready to upload!</p>
                        </li> -->
                    </ul>
                    <ul class="mesz-buttons">
                        <li @click="SendMessage" :style="Message.length >= 3 ? '' : 'cursor: not-allowed;background-color: #b3b3b3;'"><p>Send</p></li>
                    </ul>
                </div>
            </div>
            
        </div>

        <div class="rht-sid _propscrl">
            <h3>Ticket Information</h3>

            <div class="status">
                <p>Status <img v-if="!ticket.isArchive" src="./../../images/icons/edit.svg" @click="UpdateStatus = true" /></p>
                <ul class="mini-action-menu" v-if="UpdateStatus == true">
                    <li><img src="./../../images/icons/close.svg" @click="UpdateStatus = false"/></li>
                    <li 
                        v-for="status in TicketStatus" :key="status"
                            @click="TicketStatusEdited (status)">
                            <p>{{status.name}}</p>
                    </li>                 
                </ul>    
            </div>
            <p class="des-bld blue-color">{{ticket.status}}</p>
            
            <div class="priority">
                <p>Priority <img v-if="!ticket.isArchive && ticket.status != 'Closed'" src="./../../images/icons/edit.svg" @click="EditPriority = true" /></p>
                <ul class="mini-action-menu" v-if="EditPriority == true">
                    <li><img src="./../../images/icons/close.svg" @click="EditPriority = false"/></li>
                    <li 
                        v-for="priority in TicketPriority" :key="priority"
                            @click="TicketPrioirtyEdited (priority)">
                            <p>{{priority.name}}</p>
                    </li>                 
                </ul>                
            </div>
            
            <ul class="ticket-priority">
                <li v-if="ticket.priority == 'Critical'" class="critical"><img src="../../images/icons/critical.svg" /> <p>{{ticket.priority}}</p></li>
                <li v-if="ticket.priority == 'High'" class="high"><img src="../../images/icons/high.svg" /> <p>{{ticket.priority}}</p></li>
                <li v-if="ticket.priority == 'Normal'" class="normal"><img src="../../images/icons/normal.svg" /> <p>{{ticket.priority}}</p></li>
                <li v-if="ticket.priority == 'Low'" class="low"><img src="../../images/icons/low.svg" /> <p>{{ticket.priority}}</p></li>
            </ul>

            <p>Ticket ID</p>
            <p class="des-bld">#{{ticket.id}}</p>

            <p>Created Date</p>
            <p class="des-bld">{{ticket.created}}</p>

            <p>Due Date                

                <Datepicker class="dp__input_custom" v-if="!ticket.isArchive && ticket.status != 'Closed'" v-model="NewExpiryDate" :enableTimePicker="false" autoApply required @update:modelValue="UpdateDatePicker">
                    <template #trigger>
                        <img src="./../../images/icons/edit.svg" />
                    </template>
                </Datepicker>
            </p>
            
            <p v-if="ticket.expiry == 'Expired'" class="des-bld" style="color: #F00">Past Due</p>
            <p v-else class="des-bld">{{ticket.expiry}}</p>          
            <p class="des-bld blue-color">{{ticket.expiry_date}}</p>

            <p>Requested For</p>
            
            <div v-if="ticket.unit" class="ticket--requested"
                    style="margin-top: 15px; height: auto;">
                <div class="image">
                    <ReplaceImage :source="ticket.prop_unit.thumbnail"  />
                </div>

                <div class="content">
                    <h3>{{ ticket.prop_unit.unit }}</h3>
                    <p>{{ ticket.prop_unit.property }}</p>
                </div>
            </div>

            <p style="position:relative;">Assignee ({{ticket.assignee.length}}) <img v-if="!ticket.isArchive && ticket.status != 'Closed'" src="./../../images/icons/edit.svg" @click="UserSingleSelect = true" />
                <div v-if="ShowToCloseTicket" class="__closeinfo__ animated fadeInDown" @click="UserSingleSelect = true; ShowToCloseTicket = false;">
                    <p>This ticket is not assigned yet! You must assign this ticket to close.</p>
                </div>
            </p>

            <p class="des-bld" v-for="assignee in ticket.assignee" :key="assignee">{{assignee.fullname}}</p>

            <div class="tckinput">
                <h3>Ticket Tags</h3>
                <p>Tags helps to search or organize specific tickets later.</p>
                <ul class="tickettags">
                    <li v-for="tag in TicketTags" :key="tag">
                        <p>#{{tag}}</p>
                        <img src="../../images/icons/close-circled.svg" @click="RemoveTicketTags(tag)" />
                    </li>
                </ul>
                <input type="text" v-model="NewTag" @keyup="TicketTagHandler" placeholder="tags" />
                <span class="btn-primary" v-if="hasToUpdateTags" @click="updateTicketTags">Update</span>
            </div>
        </div>

        <UserSingleSelectInput v-if="UserSingleSelect == true" :lists="Assignees"  @lstboxclse="EmployeeIsSelected" :style="'right: 55px;bottom: 20%;left: unset;top: unset;'" />


    </div>

    <!-- Modals -->
    <PropModal v-if="WantArchiveTicket == true" :from="'confirm-archive-ticket'" :data="ticket" @modalclose="CloseConfrimArchive" />
    
    <PropModal v-if="WantCloseTicket == true" :from="'confirm-close-ticket'" :data="ticket" @modalclose="CloseConfrimClose" />
    <PropModal v-if="UploadAttachment == true" :from="'upload-document'" :data="AttachmentData" @modalclose="CloseUploadModal" />

    <RightModal v-if="EditSelectedTicket" :form="'edit-ticket'" :data="ticket" @modalclose="CloseEditModalBox" />

    <!-- moveout complete -->
    <RightModal v-if="CompleteMoveOut == true" :form="'complete-moveout'"  :data="CompleteMoveOutData" @modalclose="CloseCompleteMoveOut" />
    <PropModal v-if="ViewMediaFile" :from="'view-tick-document'" :data="CurrentFileData" @modalclose="CloseViewModal" />

</template>

<script>

    //@ JS Modules
    import Request from './../../javascript/getRequest';
    import Handler from './../../javascript/Handler';

    // pinia
    import { useOwnerStore } from '../../stores/OwnerStore';

    import Skeletion from '../blocks/Skeleton.vue';
    import UserImage from '../blocks/UserImage.vue';
    import ReplaceImage from './../blocks/ReplaceImage.vue';

    import UserSingleSelectInput from './../web-forms/EmployeeSingleSelectInput.vue';

    import PropModal from './../modal/PropModal.vue';
    import RightModal from './../modal/RightModal.vue';

    // datepicer
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    export default {
        name: 'OwnerTicketDetail',
        props: ['ticket', 'tickets', 'employees', 'profile'],
        emits: ['backtolist', 'backandclose', 'editticket', 'updatefilter'],
        components: {
            Skeletion,
            UserImage,
            ReplaceImage,

            UserSingleSelectInput,
            Datepicker,

            // modals
            PropModal,
            RightModal,
        },

        data () {
            return {
                // ticket detail to be loaded from server
                TicketDetail : false,
                UserDetail : false,
                Assignees: [],
                UserSingleSelect: false,

                // page settings
                Message : '',
                Private: false,

                TicketSetting : false,

                WantArchiveTicket : false,
                WantCloseTicket : false,
                EditSelectedTicket: false,

                // tags and notes
                TicketTags : this.InitTags (this.ticket.tags),
                hasToUpdateTags : false,
                NewTag : '',

                // attachment
                UploadAttachment : false,
                AttachmentData: {
                    title: 'Upload Documents',
                    description: 'Please click on the "Add Files" button or drag and drop documents below to attach ticket documents.',
                    error: 'Some uploaded file type are not supported!',
                    fileschema: ['jpeg', 'jpg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
                },
                hasAttachments: false,
                TicketAttachments: [],

                ViewMediaFile: false,
                ViewAttachmentData: [],

                // ticket status
                TicketStatus: [
                    {
                        id: 1,
                        name: 'Open'
                    },
                    {
                        id: 2,
                        name: 'On Hold'
                    },
                    {
                        id: 3,
                        name: 'In-Progress'
                    },
                    {
                        id: 4,
                        name: 'Closed'
                    },
                ],
                UpdateStatus: false,

                // edit details
                TicketPriority: [
                    {
                        id: 1,
                        name: 'Critical'
                    },
                    {
                        id: 2,
                        name: 'High'
                    },
                    {
                        id: 3,
                        name: 'Normal'
                    },
                    {
                        id: 4,
                        name: 'Low'
                    }
                ],
                EditPriority: false,

                // edit date
                NewExpiryDate: null,

                // inform to assign before closing
                ShowToCloseTicket: false,

                // ticket type is moveout
                isMoveOut: false,
                initdeposit: 0,
                deposits: [],
                balance: 0,

                CompleteMoveOut: false,
                CompleteMoveOutData: {
                    tenant: { id: this.ticket.prop_unit.tenant},
                    property: {
                        property: this.ticket.property,
                        unit: this.ticket.unit,
                        assignment: this.ticket.prop_unit.assignment
                    }
                },

                isToVisit: false,
                VisitsDate: [],
                MoveOutProperty: {},
            }
        },

        async mounted () {
            // pinia setup
            const OwnerStore = useOwnerStore ();

            // filter the employees
            this.Assignees = this.CurrentPropertyAssignee ();

            // load ticket data from server
            // only if the ticket threads are not in pinia
            this.loadTicketThreads (OwnerStore, this.ticket);

            // if ticket type is moveout
            if (this.ticket.moveout == "1") {
                this.isMoveOut = true;
                this.initdeposit = this.ticket.deposits.deposit;
                this.deposits = this.ticket.deposits.deposit_history;
                this.balance = this.ticket.balance;
            }

            // check if ticket type is toVisit i.e. tenant want to visit the property
            if (this.ticket.tovisit == "true") {
                this.isToVisit = true;
                let property = this.ticket.visitinfo.property;
                OwnerStore.properties.some(element => {
                    if (element.id == property) {
                        this.MoveOutProperty = element;
                        return true;
                    }
                });

                this.VisitsDate = this.ticket.visitinfo.dates;
            }
            
        },

        methods : {

            async CancelTheTenantVisit () {

                // first update to the stage
                let dom = {
                        content: 'This ticket has been closed',
                        created: 'Just Now',
                        id: Math.round(new Date()/1000),
                        type: 'Info',
                        threadby: this.UserDetail,
                        private: false
                    };
                this.TicketDetail.push(dom);
                this.ticket.status = "Closed";

                // update to database
                let formData = new FormData();
                formData.append('git', this.ticket.visitinfo.id);
                formData.append('ticket', this.ticket.id);
                await Request.postServer('owner/units/cancel-visit.php', formData);

                Handler.PropNotification('Successfully canceled the request and closed the ticket!');
            },

            async AssignTenantToUnit () {

            },

            async SelectVisitDate (visit) {

                // first update to the stage
                let dom = {
                        content: 'We have chosen ' + visit.date + ' for our property visit. You are welcome to visit the property on this date.',
                        created: 'Just Now',
                        id: Math.round(new Date()/1000),
                        type: 'Reply',
                        threadby: this.UserDetail,
                        private: false
                    };
                this.TicketDetail.push(dom);

                // update the visit
                this.VisitsDate.map (element => {
                    if (visit.id == element.id) element.selected = true;
                    return element;
                });

                let formData = new FormData();
                formData.append('git', this.ticket.visitinfo.id);
                formData.append('date', visit.id);
                await Request.postServer('owner/units/invite-visit.php', formData);

                Handler.PropNotification('Successfully updated date for the tenant invitation!');
            },

            // close the media files view modal
            CloseViewModal (update) {    
                this.ViewMediaFile = false;               
            },

            // view documents
            ViewAttachment (attachment) {
                if (attachment.extension == 'jpg' || attachment.extension == 'png') {
                    this.ViewMediaFile = true;
                    this.CurrentFileData = attachment;
                }
            },

            UploadTicketAttachment () {
                if (this.ticket.status != 'Closed' || this.ticket.isArchive == false) {
                    this.UploadAttachment = true;
                }
            },

            CloseCompleteMoveOut (moveout) {
                // close the modal
                this.CompleteMoveOut = false;

                if (moveout) Handler.PropNotification('Suucessfully completed the move-out for selected unit/tenant');
            },


            async loadTicketThreads (OwnerStore, ticket) {
                if (this.hasThreads(OwnerStore, ticket)) {

                    // pinia update
                    OwnerStore.tickets.filter (tkt => {
                        if (tkt.id == ticket.id) {
                            this.TicketDetail = tkt.threads;
                            this.UserDetail = tkt.creator;
                        }
                    });   
                    
                }else {
                    const Requests = await Request.getData('tickets/detail.php?ticket=' + ticket.id);
                    if (Requests) {
                        // we got the ticket list
                        this.TicketDetail = Requests.response.threads;
                        this.UserDetail = Requests.response.user;

                        // pinia update
                        OwnerStore.tickets.filter (tkt => {
                            if (tkt.id == ticket.id) {
                                tkt.threads = this.TicketDetail;
                                tkt.creator = this.UserDetail;
                            }
                        });               
                    }
                }

                // scroll to the bottom
                setTimeout(() => {
                    var ticketSummery = document.getElementById('ticketSummery');
                    ticketSummery.scrollTo(0, ticketSummery.scrollHeight);
                }, 100); 
            },

            hasThreads (OwnerStore, current) {
                let hasThreads = false;
                OwnerStore.tickets.filter (ticket => {
                    if (ticket.id == current.id) {
                        if (ticket.hasOwnProperty('threads')) hasThreads = true;
                    }
                });

                return hasThreads;
            },

            CloseCurrentTicet () {
                this.TicketSetting = false;
                if (!this.ticket.isAssigned) {
                    this.ShowToCloseTicket = true;
                }else {
                    this.WantCloseTicket = true; 
                }
            },

            // update date
            async UpdateDatePicker () {

                // now check the date is past date
                const dateok = this.CheckPastDate (this.NewExpiryDate);

                if (dateok) {
                    let formData = new FormData();
                    let ticketExpiry = this.NewExpiryDate.getTime();
                    ticketExpiry = ticketExpiry/1000;
                    formData.append('ticket', this.ticket.id);
                    formData.append('expiry', ticketExpiry);

                    // readable date format
                    var readableformat = this.NewExpiryDate.toLocaleDateString("en-US")

                    // now upate the expiry
                    // info
                    var info = this.UserDetail.fullname + ' updated due date to ' + readableformat;
                    formData.append('info', info);

                    // now update to the field/log
                    var newdom =  {
                            content: info,
                            created: 'Just Now',
                            id: Math.round(new Date()/1000),
                            type: 'Info',
                            threadby: this.UserDetail,
                            private: false
                        };
                    this.TicketDetail.push (newdom);

                    const UpdTicket = await Request.postServer('owner/tickets/updatedue.php', formData);

                    if (!UpdTicket.error) {
                        // notification update
                        Handler.PropNotification('Successfully updated due date to ' + readableformat);

                        // update to current ticket
                        this.ticket.expiry = UpdTicket.response.expiry;
                        this.ticket.expiry_date = UpdTicket.response.expiry_date;

                        this.$emit('updatefilter');
                    }

                }
                
            },

            CheckPastDate (date) {
                const today = new Date();
                if (date < today) {
                    alert ('Please choose present date!');
                    return false;
                }else {
                    return true;
                }
            },

            CurrentPropertyAssignee () {
                var NewFilter = [];
                var CurrentProperty = this.ticket.property;

                this.employees.forEach (employee => {
                    let assigned = employee.assignments;
                    for (let assigne in assigned) {
                        let propid = assigned[assigne].property.id;
                        if (CurrentProperty == propid) {
                            NewFilter.push (employee);
                        }
                    }
                });

                // add current owner to the list too
                let currOwner = {
                    active : false,
                    assignments : [],
                    avatar : '',
                    email : this.profile.email,
                    fullname : this.profile.fullname + ' (Self)',
                    id : this.profile.id
                };NewFilter.unshift (currOwner);

                // now check if this ticket has been already assigned
                // just remove them from the list
                // var FinalList = [];
                // NewFilter.forEach(user => {
                //     for (let assignee in this.ticket.assignee) {
                //         if (user.id != assignee.id) {
                //             FinalList.push(user);
                //         }
                //     }
                // });

                // assignees employee data
                return NewFilter;
            },

            EmployeeIsSelected (employee) {
                if (employee != null) {
                    var astotal = this.ticket.assignee;
                    if (astotal == 0) {
                        this.ticket.assignee[0] = employee;
                    }else {
                        var PreviousAssignee = this.ticket.assignee;
                        var NewOne = [];
                        NewOne = PreviousAssignee.filter (asigne => {
                            if (asigne.id == employee.id) {}
                            else return asigne;
                        });

                        NewOne.push (employee);
                    }

                    // now update to the field/log
                    var newdom =  {
                            content: 'You assigned this ticket to ' + employee.fullname,
                            created: 'Just Now',
                            id: Math.round(new Date()/1000),
                            type: 'Info',
                            threadby: this.UserDetail,
                            private: false
                        };

                    this.TicketDetail.push (newdom);

                    // upate to server
                    this.UpdateAssigneeServer (employee);
                    
                }
                
                // close the employee list
                this.UserSingleSelect = false; 
            },
            
            backtoList () {
                this.$emit('backtolist', false);
            },

            async UpdateAssigneeServer (employee) {
                let formData = new FormData();
                formData.append('ticket', this.ticket.id);
                formData.append('assign', employee.id);

                // info
                var info = this.UserDetail.fullname + ' assigned this ticket to ' + employee.fullname;
                formData.append('info', info);

                const UpdAssignee = await Request.postServer('owner/tickets/assign.php', formData);

                if (!UpdAssignee.error) {
                    // notification update
                    Handler.PropNotification('Successfully updated ticket assignee to ' + employee.fullname);
                    this.ticket.isAssigned = true;
                    this.$emit('updatefilter');
                }
            },

            async TicketPrioirtyEdited (priority) {
                this.EditPriority = false;
                this.ticket.priority = priority.name;

                // now update to the field/log
                var newdom =  {
                        content: 'You just update priority of ticket to ' + priority.name,
                        created: 'Just Now',
                        id: Math.round(new Date()/1000),
                        type: 'Info',
                        threadby: this.UserDetail,
                        private: false
                    };
                this.TicketDetail.push (newdom);

                let formData = new FormData();
                formData.append('ticketid', this.ticket.id);
                formData.append('priority', priority.name);

                // info
                var info = this.UserDetail.fullname + ' updated priority of ticket to ' + priority.name;
                formData.append('info', info);

                const Prio = await Request.postServer('owner/tickets/priority.php', formData);

                if (!Prio.error) {
                    // notify on success
                    Handler.PropNotification('Successfully updated ticket priority to ' + priority.name);
                    this.$emit('updatefilter');
                }
                
            },

            async TicketStatusEdited (status) {
                if (status.name == 'Closed') {
                    this.CloseCurrentTicet ();
                    this.UpdateStatus = false;
                }else {
                    this.UpdateStatus = false;
                    this.ticket.status = status.name;

                    // now update to the field/log
                    var newdom =  {
                            content: 'You just updated the status of ticket to ' + status.name,
                            created: 'Just Now',
                            id: Math.round(new Date()/1000),
                            type: 'Info',
                            threadby: this.UserDetail,
                            private: false
                        };
                    this.TicketDetail.push (newdom);

                    let formData = new FormData();
                    formData.append('ticketid', this.ticket.id);
                    formData.append('status', status.name);
                    
                    // info
                    var info = this.UserDetail.fullname + ' just updated the status of ticket to ' + status.name;
                    formData.append('info', info);

                    const Sta = await Request.postServer('owner/tickets/status.php', formData);

                    if (!Sta.error) {
                        // notify on success
                        Handler.PropNotification('Successfully updated ticket status to ' + status.name);
                        this.$emit('updatefilter');
                    }
                }                
            },

            InitTags (tags) {
                if (tags == undefined || tags == "") return [];
                else return tags.split(',');
            },

            TicketTagHandler () {
                let event = event || window.event;
                if (event.key == 'Enter') {
                    this.hasToUpdateTags = true;

                    // lowercase the tags
                    this.NewTag.toLowerCase();
                    
                    if (this.TicketTags.length < 1) this.TicketTags[0] = this.NewTag;
                    else this.TicketTags.push (this.NewTag);

                    this.NewTag = '';
                }                
            },

            RemoveTicketTags (tag) {
                this.hasToUpdateTags = true;
                this.TicketTags = this.TicketTags.filter(T => {
                    if (tag == T) {}
                    else return T;
                })
            },

            async updateTicketTags () {
                let Tags = this.TicketTags.map(function (tag) {
                    return tag
                }).join(',');

                let formData = new FormData();
                formData.append('ticketid', this.ticket.id);
                formData.append('tags', Tags);

                // remove update button
                this.hasToUpdateTags = false;

                const Tgs = await Request.postServer('owner/tickets/tags.php', formData);
                if (!Tgs.error) {
                    // notify on success
                    Handler.PropNotification('Successfully updated your tags');
                    this.$emit('updatefilter');
                } 

                // update to client side
                this.ticket.tags = Tags;
            },

            async CloseConfrimArchive (ticket) {
                if (ticket) {
                    let formData = new FormData();
                    formData.append('ticket', ticket.id);

                    const DeleteTicket = await Request.postServer('owner/tickets/archive.php', formData);

                    // update current ticket in client side
                    // notify on success
                    Handler.PropNotification('Successfully updated to the archive folder!');

                    // update list in client
                    this.ticket.isArchive = true;
                    this.tickets.filter ((ticket) => {
                        if (ticket.id == this.ticket.id) {
                            ticket.isArchive = true;
                            ticket.status = 'Closed';
                        }
                    });

                    this.$emit('updatefilter');

                }

                this.WantArchiveTicket = false;
            },

            async CloseConfrimClose (ticket) {
                
                if (ticket) {
                    this.ticket.status = "Closed";
                    
                    let formData = new FormData();
                    formData.append('ticketid', ticket.id);
                    formData.append('message', ticket.message);

                    const CloseTicket = await Request.postServer('tickets/close.php', formData);
                    if (!CloseTicket.error) {
                        this.$emit('backandclose', ticket);
                        this.$emit('updatefilter');
                    }
                }

                // remove dialog
                this.WantCloseTicket = false;
            },

            async SendMessage () {
                if (this.Message.length <=3) return;
                
                let NewThread;

                // update message
                if (this.hasAttachments) NewThread = this.SendMediaMessage ();
                else NewThread = this.SendTextMessage ();

                // push to the list
                this.TicketDetail.push(NewThread.dom);

                // null the message body
                this.Message = '';
                this.hasAttachments = false;
                this.Private = false;

                // send data from server
                await Request.postServer(NewThread.path, NewThread.formdata);

                this.TicketAttachments = [];

            },

            // send text message
            SendTextMessage () {
                let formData = new FormData();
                formData.append('ticket', this.ticket.id);
                formData.append('type', 'Reply');

                var PrivateMode = 'false';
                if (this.Private) PrivateMode = 'true';
                formData.append('private', PrivateMode);

                formData.append('thready', this.UserDetail.id);
                formData.append('content', this.Message);

                // return the collected data
                return {
                    dom: {
                        content: this.Message,
                        created: 'Just Now',
                        id: Math.round(new Date()/1000),
                        type: 'Reply',
                        threadby: this.UserDetail,
                        private: PrivateMode
                    },
                    path: 'tickets/update-thread.php',
                    formdata: formData
                };
            },

            // send text and files attachment message
            SendMediaMessage () {
                let formData = new FormData();
                formData.append('ticket', this.ticket.id);
                formData.append('type', 'Blob');

                var PrivateMode = 'false';
                if (this.Private) PrivateMode = 'true';
                formData.append('private', PrivateMode);

                formData.append('thready', this.UserDetail.id);
                formData.append('content', this.Message);

                // documents as attachments
                // convert file list into files[array] form data    
                var attachments = [];
                for (let element of this.TicketAttachments) {
                    formData.append('documents[]', element);

                    // extension of the current file
                    var filename = element.name;
                    var extension = filename.split('.').pop();

                    attachments.push ({
                        id: Math.round(new Date()/1000),
                        title: filename,
                        size: element.size,
                        extension: extension,
                        url: false
                    });
                };
                
                // return the collected data
                return {
                    dom: {
                        content: this.Message,
                        created: 'Just Now',
                        id: Math.round(new Date()/1000),
                        threadby : this.UserDetail,
                        type: 'Blob',
                        attachments: attachments,
                        private: PrivateMode
                    },
                    path: 'owner/upload/tickets.php',
                    formdata: formData
                };
            },

            // close documents upload modal
            //todo
            CloseUploadModal (blobupload) {
                if (blobupload) {
                    this.hasAttachments = true;
                    this.TicketAttachments = blobupload;
                    this.DirectAttachmentUpload ();
                }

                this.UploadAttachment = false;
            },

            async DirectAttachmentUpload () {
                let NewThread = this.SendMediaMessage ();

                // push to the list
                this.TicketDetail.push(NewThread.dom);

                // null the message body
                this.Message = '';
                this.hasAttachments = false;
                this.Private = false;

                // send data from server
                await Request.postServer(NewThread.path, NewThread.formdata);

                this.TicketAttachments = [];
            },

            EditTicket () {
                this.EditSelectedTicket = true;
                this.TicketSetting = false;
            },

            async UpdateTicketFavourite () {
                this.TicketSetting = false;

                let formData = new FormData();
                formData.append('ticket', this.ticket.id);
                formData.append('favourite', this.ticket.isFavourite);
                await Request.postServer('owner/tickets/favourite.php', formData);

                // notify on success
                Handler.PropNotification('Successfully updated to the favourite list!');
                this.$emit('updatefilter');

                // update list in client
                var todo = !this.ticket.isFavourite;
                this.ticket.isFavourite = todo;
                this.tickets.filter ((ticket) => {
                    if (ticket.id == this.ticket.id) {
                        ticket.isFavourite = todo;
                    }
                });

            },

            CloseEditModalBox (tickets) {
                if (tickets) {
                    var Selected;
                    tickets.filter (ticket => {
                        if (ticket.id == this.ticket.id) {
                            Selected = ticket;
                        }
                    });

                    // also emit to update the ticket list
                    this.$emit ('editticket', tickets, Selected);

                }
                // close the modal
                this.EditSelectedTicket = false;
            }
        }
    };
</script>



<style scoped>
/* dont move this code */
.ticket-table {
        display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid rgb(219, 219, 219);
    border-radius: 7px;
    padding: 10px;
    width: 95%;
    margin: 0px auto;
    background-color: #f3f3f3 ;
    }

    div.ticket-table div.prop-row {
        background-color: #f3f3f3 !important;
    border-bottom: 1px solid #e3e3e3;
    }

/* dont move this code */


</style>