const OwnerLogin = () => {
    return {
        'email' : 'matt.alexander@propstop.com',
        'password' : '5o9%TAS7p7x!',
        'ps-route' : 'owner',
        'token' : 'lku7ioaljdfliswi23halsdasdf121asd21rDKJDLKFf12slakdf',
    }
};

const TenantsLogin = () => {
    return {
        'email' : 'madspam@outlook.com',
        'ps-route' : 'tenant',
        'password' : 'D!cezYU1w03O',
        'token' : 'ISu7ioalkjasdfLKSjlakwrLUSLKJufgasd932sdf12slakdf',
    }
};

const PropetyUniqueFeatures = () => {
    return [
        {id : 1, title : 'Bike Parking'},
        {id : 4, title : 'Bike Repair Station'},
        {id : 7, title : 'Car Charging Station'},
        {id : 10, title : 'Conference Room'},
        {id : 13, title : 'Controlled Access'},
        {id : 16, title : 'Guest Parking'},
        {id : 19, title : 'High Ceilings'},
        {id : 2, title : 'Indoor Pool'},
        {id : 5, title : 'Outdoor Grilling Area'},
        {id : 11, title : 'Outdoor Lounge'},
        {id : 14, title : 'Outdoor Lounge with Fire Pit'},
        {id : 17, title : 'Outdoor Pool'},
        {id : 23, title : 'Pet Play Area'},
        {id : 26, title : 'Planned Social Activities'},
        {id : 3, title : 'Private Balconies'},
        {id : 6, title : 'Property Manager on Site'},
        {id : 9, title : 'Quartz Countertopsz'},
        {id : 15, title : 'Residential Storage Lockers Available'},
        {id : 21, title : 'Stylish Lobby Entrance'},
        {id : 24, title : 'Washer/Dryer'},
        {id : 27, title : 'WiFi Available Throughout'},
    ];
};

const PropertyServices = () => {
    return [
        {id : 6, title : '24-Hour Availability'},
        {id : 14, title : '24-Hour Door Attendant'},
        {id : 4, title : 'Car Washing Service'},
        {id : 2, title : 'Community-Wide WiFi'},
        {id : 10, title : 'Dry Cleaning Service'},
        {id : 11, title : 'Laundry Service'},
        {id : 5, title : 'Maintenance on site'},
        {id : 7, title : 'On-Site Food Services'},
        {id : 1, title : 'On-Site Retail Stores'},
        {id : 13, title : 'Package Service'},
        {id : 12, title : 'Spa'},
    ];
};

const FloorNumName = () => {
    return ['Ground', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth', 'Tenth', 'Elevanth', 'Twelfth', 'Thirteenth', 'Fourteenth', 'Fifteenth', 'Sixteenth', 'Seventeenth', 'Eighteenth', 'Nineteenth', 'Twentieth', '21st', '22nd', '23rd', '24th', '25th', '26th', '27th', '28th', '29th', '30th'];
};

const EmployeeRoles = () => {
    return [
            {
                id : 1,
                title : 'All',
                active : true
            },
            {
                id : 2,
                title : 'Accounting'
            },
            {
                id : 3,
                title : 'Senior Manager'
            },
            {
                id : 4,
                title : 'Regional Manager'
            },
            {
                id : 5,
                title : 'Property Manager'
            },
            {
                id : 6,
                title : 'Facilities Manager'
            },
            {
                id : 7,
                title : 'Maintenance and Repair'
            },
            {
                id : 8,
                title : 'Groundskeeping'
            }
        ];
}

const UnitAmenities = () => {
    return [
        {
            id: 'refrigerator',
            name: 'Refrigerator',
            icon: 'refrigerator.png'
        },
        {
            id: 'oven',
            name: 'Oven',
            icon: 'oven.png'
        },
        {
            id: 'stove',
            name: 'Stove',
            icon: 'stove.png'
        },
        {
            id: 'microwave',
            name: 'Microwave',
            icon: 'microwave.png'
        },
        {
            id: 'dishwasher',
            name: 'Dishwasher',
            icon: 'dishwasher.png'
        },
        {
            id: 'central-ac',
            name: 'Central AC',
            icon: 'central-ac.png'
        },
        {
            id: 'central-heat',
            name: 'Central Heat',
            icon: 'central-heat.png'
        },
        {
            id: 'balcony',
            name: 'Balcony',
            icon: 'balcony.png'
        },
        {
            id: 'front-yard',
            name: 'Front Yard',
            icon: 'front-yard.png'
        },
        {
            id: 'laundry-hookups',
            name: 'Laundry Hookups',
            icon: 'laundry.png'
        }
    ]
};

const TransactionType = () => {
    return {
        utility : 'Utility',
        late : 'Late Fee',
        penalty : 'Penalty',
        bulk : 'Bulk Item Pick-Up',
        trash : 'Trash'
    }
};

const UtilityType = () => {
    return {
        water : 'Water Usage',
        gas : 'Gas Usage',
        electricity : 'Electricity Usage',
        multiple : 'Multiple'
    }
};

export default {
    OwnerLogin,
    TenantsLogin,
    PropetyUniqueFeatures,
    PropertyServices,
    FloorNumName,
    EmployeeRoles,
    UnitAmenities,

    TransactionType,
    UtilityType,
    
}