<template>
  <div class="prop-form _propscrl">
    <div class="form-area" style="padding-bottom: 65px">
      <form name="propform" id="propform-document" style="display: block">
        <div class="pf--title">
          <h3>Update rent for your tenant/unit.</h3>
          <p>
            You can update the tenant/unit rent from here. Enter the new amount and other
            info to submit.
          </p>
        </div>

        <div class="pf--input">
          <p>
            New Amount
            <span style="font-size: 9pt; color: #999">(${{ data.rent }} Prevously)</span>
          </p>
          <input
            type="number"
            placeholder="Amount"
            id="Amount"
            v-model="Amount"
            data-type="len"
            data-max="3"
          />
        </div>

        <div class="pf--multi-input" style="height: 80px">
          <div class="half hleft">
            <div class="pf--input">
              <p>Effective Month</p>
              <select id="Emonthsel" data-type="select" v-model="eMonth">
                <option value="-1" selected>- Choose Month -</option>
                <option
                  v-for="month in EffectiveMonths"
                  :key="month.number"
                  :value="month.number"
                >
                  {{ month.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="half hright">
            <div class="pf--input">
              <p>Effective Year</p>
              <select id="EYearsel" data-type="select" v-model="eYear">
                <option value="-1" selected>- Choose Year -</option>
                <option v-for="year in EffectiveYears" :key="year" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="pf--input">
          <p>Have you already notified the tenant?</p>
          <select
            name="Notified"
            id="NOtified"
            data-type="select"
            v-model="toNotifyTenant"
          >
            <option value="-1" selected>- Choose One -</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div class="pf--input" v-if="toNotifyTenant == 'No'">
          <p>Would you like to electronically notify them?</p>
          <select id="CType" data-type="select" v-model="NotifyTenant">
            <option value="-1" selected>- Choose One -</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div class="pf--input" v-if="toNotifyTenant == 'No' && NotifyTenant == 'Yes'">
          <div class="iagreebtn">
            <input type="checkbox" v-model="iAgree" :checked="iAgree" />
            <span :style="iAgreetoCheck ? '' : 'color: red;'"
              >I have communicated this change to the tenant to meet all applicable laws
              and regulations in my area.</span
            >
          </div>
        </div>
      </form>

      <span v-if="formError" class="__formerror">{{ infoText }}</span>
    </div>

    <div class="form-action">
      <div class="form-area">
        <span
          class="primary-btn-loader"
          :class="Requesting ? 'btn-disabled' : ''"
          @click="UpdateRentChange"
        >
          <p v-if="!Requesting">Update Rent</p>
          <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
//@ JS Modules
import Request from "../../javascript/getRequest";
import FormValidation from "../../javascript/FormValidation";
import Handler from "../../javascript/Handler";

// datepicer
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "RentAmountChange",
  props: ["data"],
  emits: ["close"],
  components: {
    Datepicker,
  },

  data() {
    return {
      Amount: "",
      // EffectiveDate: null,
      toNotifyTenant: "-1",
      NotifyTenant: "-1",
      iAgree: false,
      iAgreetoCheck: true,
      eMonth: "-1",
      eYear: "-1",

      // effective date month/year
      EffectiveMonths: [],
      EffectiveYears: [],

      // form error
      formError: false,
      infoText: "",
      Requesting: false,
    };
  },

  mounted() {
    // console.log (this.EffectiveMonths);
    this.EffectiveMonths = this.EffMonths();
    this.EffectiveYears = this.EffYears();
  },

  methods: {
    CheckPastDate(date) {
      const today = new Date();
      if (date < today) {
        alert("Please choose present date!");
        this.Expiry = "";
        return false;
      } else {
        return true;
      }
    },

    EffMonths() {
      var MonthName = [
        "0",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "Novemember",
        "December",
      ];
      const d = new Date();
      let month = d.getMonth() + 1;
      var months = [];
      for (var i = month; i <= 12; i++) {
        months.push({
          number: i,
          name: MonthName[i],
        });
      }

      return months;
    },

    EffYears() {
      const d = new Date();
      let year = d.getFullYear();
      return [year, year + 1];
    },

    async UpdateRentChange() {
      if (this.Requesting) return;

      // form & validation
      let form = document.getElementById("propform-document");
      let canGoAhead = FormValidation.Validate(form);

      // other validation
      if (this.toNotifyTenant == "No") {
        if (!this.iAgree) this.iAgreetoCheck = false;
        else this.iAgreetoCheck = true;
      }

      let PickerAhead = true;

      // // calendar validation
      // if (this.EffectiveDate == null) {
      //     PickerAhead = false;
      //     alert ('Please pick ticket expiriy date!');
      // }
      if (canGoAhead && this.iAgreetoCheck) {
        this.Requesting = true;

        let formData = new FormData();
        formData.append("amount", this.Amount);
        formData.append("previous", this.data.rent);
        formData.append("emonth", this.eMonth);
        formData.append("eyear", this.eYear);

        // start date timestamp
        // let EffectiveDate = this.EffectiveDate.getTime();
        // EffectiveDate = EffectiveDate/1000;
        // formData.append('date', EffectiveDate);

        formData.append("tenant", this.data.tenant);
        formData.append("assignment", this.data.assignment);
        formData.append("unit", this.data.unit);
        formData.append("notify", this.NotifyTenant);

        // tick mark
        var agree = "false";
        if (this.iAgree) agree = "true";
        else agree = "false";
        formData.append("communicated", agree);

        // submit tasks data
        const ReturnData = await Request.postServer(
          "owner/units/update-rent.php",
          formData
        );
        this.Requesting = false;

        // now check if the email already exists
        if (ReturnData.error == false) {
          var Update = {
            rent: this.Amount,
          };

          // now close modal, and load current property
          this.$emit("close", Update);

          // notiy owner
          Handler.PropNotification(
            "Successfully updated rent to the selected unit/tenent to <b>$" +
              this.Amount +
              "</b>"
          );

          // if error
        } else {
          this.formError = true;
          this.infoText = "Something went wrong!";
        }
      }
    },
  },
};
</script>

<style scoped>
span.__formerror {
  margin-left: 0 !important;
}
.v-calendar {
  width: calc(100% - 2px) !important;
  min-width: 100%;
}
</style>
