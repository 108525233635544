<template>
    <div class="welcome-message">
        <img src="../../images/gif/welcome.gif" />
        <h3>Welcome to PropStop!</h3>
        <p>Hello! Welcome to PropStop, the only property management tool you'll ever need.</p>
        <span class="btn-primary" @click="CloseModal">Thank You!</span>
    </div>
</template>

<script>
    export default {
        name : 'WelcomeOwner',
        emits: ['close'],
        methods: {
            CloseModal () {
                this.$emit('close');
            }
        }
    };
</script>