<template>
    <div class="mul-parking-list animatedss fadeInUp">
        <!-- If no data on result -->
        <div v-if="lists.length <= 0 || lists == false" class="empty-result">
            <img src="../../images/icons/empty-result.svg" />
            <p>No parking in this list, Please add new parkings and get back to the list.</p>
        </div>

        <ul class="_propscrl">
            <li class="transAe" :class="{ active : parking.active}" v-for="parking in lists" :key="parking.id" @click="ListSelect(parking)">
                <div class="parking-sel">
                    <div class="image">
                        <img v-if="parking.type == 'Guest Parking'" src="../../images/icons/guest-parking.svg" />
                        <img v-else-if="parking.type == 'Handicap Parking'" src="../../images/icons/handicap-parking.svg" />
                        <img v-else src="../../images/icons/normal-parking.svg" />
                    </div>

                    <div class="content">
                        <h3>{{parking.name}}</h3>
                        <p>{{parking.type}}</p>
                    </div>
                </div>
            </li>
        </ul>

        <div class="confirm-ar">
            <div class="clea-r" @click="ClearAll">Clear All</div>
            <div class="o-k" @click="closeList">Apply</div>
        </div>
    </div>

    <div class="overlay" @click="closeList" style="opacity: 0;"></div>
</template>

<script>

    export default {
        name: 'MultiSelectParking',
        props: ['lists'],
        emits: ['lstboxclse'],

        data () {
            return {
                ListData : this.lists,
            }
        },
        
        methods: {
            ListSelect (sl) {
                this.ListData = this.ListData.filter(list => {
                    if (list.id == sl.id) {
                        if (sl.active) {
                            list.active = false;
                        }else {
                            list.active = true;
                        }
                    }
                    return list;
                });
            },

            closeList () {
                this.$emit('lstboxclse', this.lists);
            },

            ClearAll () {
                this.ListData = this.ListData.filter(list => {
                    list.active = false;
                    return list;
                });
            }
        },
    };
</script>