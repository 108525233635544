<template>
    <div v-if="!data.self" class="gs--body">
        <h3>Duplicate Property!</h3>
        <p>This property is already added to the PropStop service. Would you like to file a claim of ownership for this property?</p>

        <div class="chkprty">
            <input type="checkbox" v-model="AcceptProof" />
            <p>I attest that I own this property and can provide proof of ownership.</p>
        </div>
    </div>

    <div v-if="!data.self" class="gs--action">
        <span class="primary-btn-loader pbl" :class="AcceptProof ? '' : 'btnotready'" @click="confirmModal">
            <p v-if="!Requesting">Yes</p>
            <img v-else class="loader" src="./../../../images/gif/btn-loader.gif" />
        </span>
        <span class="primary-btn-loader pblsec" @click="CloseModal">
            <p>No</p>
        </span>
    </div>

    <div v-if="data.self" class="gs--body">
        <h3>Confirmation!</h3>
        <p>You already have this property added to your account and it cannot be added again.</p>
    </div>

    <div v-if="data.self" class="gs--action">
        <span class="primary-btn-loader pbl" @click="CloseModal" style="width: 120px; display: block;float: left;margin-top: 10px;">
            <p>Okay</p>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'ConfirmCompleteTask',
        props: ['data'],
        emits: ['close'],
        data () {
            return {
                Requesting : false,
                isReady: false,
                AcceptProof: false,
            }
        },
        mounted() {
            console.log (this.data);
        },
        methods : {
            confirmModal () {
                if (!this.AcceptProof) return;
                this.$emit('close', true);
            },

            CloseModal () {
                this.$emit('close', false);
            }
        }
    };
</script>