import { defineStore } from "pinia";

export const useTenantStore = defineStore ({
    id: 'tenant',

    // reactive state owner data
    state: () => ({

        user: {},
        property: {},
        route : {
            page: 'Dashboard',
            params: null,
        },

        dashboard: {},

        properties: [],
        

    }),

    // methods
    actions: {

        ResetUser (user) {
            this.user = user;
        },

        ResetProperty (property) {
            this.property = property;
        },

        ResetDashboard (dashboard) {
            this.dashboard = dashboard;
        },

        ResetProperties (properties) {
            this.properties = properties;
        },

    }
});