<template>
      <WebNav />

        <!-- internal header hero -->
        <InternalHeader  :page="'web-feature'" 
                    :title="'Comprehensive Property Management Solutions'" 
                    :description="'Explore the wide range of PropStop features designed to address all your property management needs. From seamless rent collection to efficient maintenance tracking, see how our platform can transform your management experience.'" />
        <!-- internal header hero -->

        <!-- Company features -->
        <section class="about">
            <div class="wrapper" style="padding: 0;">
                    <div class="abwr-about">
                        <div class="image" style="height: 360px;">
                                <img src="./../../images/website/feature-property-management.jpg" alt="Feature">
                        </div>
                        <div class="content">
                                <span>Our Features</span>
                                <h2>Welcome to a <em><p>New Era</p></em> of Property Management.</h2>
                                <p>At PropStop, we understand the complexities of managing properties and the challenges both landlords and tenants face. That's why we've developed a comprehensive suite of features designed to streamline every aspect of property management. Discover how PropStop can transform your property management experience.</p>
                        </div>
                    </div>
            </div>
        </section>
        <!-- Company features -->

        <!-- Property features -->
        <section class="prop-features">
            <div class="wrapper" style="padding-top: 0;">

                <ul class="web-features">
                    <li>
                        <div class="image">
                            <img src="./../../images/website/feature-image-1.svg" alt="Comprehensive Document Management" />
                        </div>
                        <div class="content">
                            <h2>Comprehensive Document Management</h2>
                            <h3>Effortless Organization at Your Fingertips</h3>
                            <p>Gone are the days of lost paperwork and cluttered desks. With PropStop's Document Management feature, you can store, access, and share all your property-related documents in one secure, easily accessible location. From lease agreements to maintenance records, everything is organized and at your fingertips.</p>
                        </div>
                    </li>

                    <li>
                        <div class="image">
                            <img src="./../../images/website/feature-image-2.svg" alt="Automated Rent Collection" />
                        </div>
                        <div class="content">
                            <h2>Automated Rent Collection</h2>
                            <h3>Simplify Rent Payments Like Never Before</h3>
                            <p>Say goodbye to late payments and manual tracking. PropStop's Automated Rent Collection feature ensures rent is paid on time, every time. Our platform offers tenants convenient payment options while providing landlords with a hassle-free way to manage financials, all with real-time tracking and notifications.</p>
                        </div>
                    </li>

                    <li>
                        <div class="image">
                            <img src="./../../images/website/feature-image-3.svg" alt="Maintenance Request Tracking" />
                        </div>
                        <div class="content">
                            <h2>Maintenance Request Tracking</h2>
                            <h3>Streamline Repairs for a Happier Tenancy</h3>
                            <p>Maintenance made easy. PropStop offers a straightforward way for tenants to report issues and for landlords to track and manage repair requests. This feature ensures timely responses to maintenance needs, improving tenant satisfaction and property upkeep.</p>
                        </div>
                    </li>

                    <li>
                        <div class="image">
                            <img src="./../../images/website/feature-image-4.svg" alt="Tenant Engagement Tools" />
                        </div>
                        <div class="content">
                            <h2>Tenant Engagement Tools</h2>
                            <h3>Enhance Communication and Build Community</h3>
                            <p>Foster a positive relationship with your tenants through PropStop's Tenant Engagement Tools. Our platform facilitates direct communication, announcements, and feedback, helping you create a vibrant, engaged community within your properties.</p>
                        </div>
                    </li>

                    <li>
                        <div class="image">
                            <img src="./../../images/website/feature-image-5.svg" alt="Data-Driven Insights" />
                        </div>
                        <div class="content">
                            <h2>Data-Driven Insights</h2>
                            <h3>Make Informed Decisions with Real-Time Data</h3>
                            <p>Unlock the power of your data. PropStop's analytics provide valuable insights into the financial and operational health of your properties. Understand trends, track performance, and make data-driven decisions to optimize your portfolio.</p>
                        </div>
                    </li>

                    <li>
                        <div class="image">
                            <img src="./../../images/website/feature-image-6.svg" alt="Security and Compliance" />
                        </div>
                        <div class="content">
                            <h2>Security and Compliance</h2>
                            <h3>Your Trust Is Our Priority</h3>
                            <p>With PropStop, your data is protected. Our platform adheres to the highest security standards and ensures compliance with all relevant property management laws and regulations, giving you peace of mind.</p>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        <!-- Propstop Featers -->

        <section class="counters">
            <div class="wrapper" style="background-color: transparent; padding: unset; padding: 0">
                    <div class="countper">
                        <h2>Join the PropStop <em><p style="color: white !important;font-weight: 700;">Revolution</p></em></h2>
                        <p>Ready to experience the future of property management? PropStop is here to make your property management tasks more efficient, your tenant relationships stronger, and your property portfolio more profitable.</p>
                        <router-link to="/waitlist/" class="site-btn-dark">
                            <p>Get Started with PropStop Today</p>
                        </router-link>
                        <p style="font-size: 9pt;color: #a7a7a7;">Welcome to the PropStop family --where property management meets innovation</p>
                    </div>
            </div>
        </section>

      <Footer />
</template>

<script>
      // import Website Navigation
      import WebNav from './../../components/website/WebNav.vue';
      import InternalHeader from '../../components/website/InternalHeader.vue';
      import Seprator from './../../components/website/Seprator.vue';
      import Footer from './../../components/website/Footer.vue';

      export default {
        name : 'Features',
        components : {
            WebNav,
            InternalHeader,
            Seprator,
            Footer
        },

        mounted () {
            // scroll to top
            window.scrollTo(0, 0);

            // remove styling used for apps
            document.getElementById('propstop-app').style.height = 'auto';
            document.getElementById('propstop-app').style.overflow = 'auto';

            //  change the title
            document.title = "PropStop Features: Comprehensive Property Management Solutions";

        }
    }

</script>