<template>
    <div class="gs--body">
        <h3>Complete Task?</h3>
        <p>Your task <strong>{{data.title}}</strong> will be marked as completed, are you sure for this action?</p>
    </div>

    <div class="gs--action">
        <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="confirmModal" style="width: 120px; display: block;float: left;margin-top: 10px;">
            <p v-if="!Requesting">Complete Task</p>
            <img v-else class="loader" src="./../../../images/gif/btn-loader.gif" />
        </span>
    </div>
</template>

<script>
    export default {
        name: 'ConfirmCompleteTask',
        props: ['data'],
        emits: ['close'],
        data () {
            return {
                Requesting : false,
            }
        },
        methods : {
            confirmModal () {
                this.Requesting = true;
                this.$emit('close', this.data);
            }
        }
    };
</script>