<template>
    <div class="prop-form _propscrl">
        <div class="form-area" style="padding-bottom: 65px;">

            <form name="propform" id="propform-finance" style="display: block;">
                <div class="pf--title">
                    <h3>Add Business Expenses</h3>
                    <p>Update business expenses for specific property for repair, contract, electric, issues etc.</p>
                </div>

                <div class="pf--input">
                    <p>Choose Property</p>
                    <input v-model="SelProperty" type="text" placeholder="Choose One" id="Properties" data-type="len" data-max="5" 
                                        readonly 
                                        @click="PropertySingleSelect = true"
                                        :disabled="Requesting || FinanceData.property" />
                    <PropertyList v-if="PropertySingleSelect" :distyle="'top: 154px;position: absolute;height: 355px;width: 360px;left: 63px;overflow-y: scroll;'" :properties="OwnerProperties" @prosel="PropertySelected" />
                </div>

                <div class="pf--multi-input" style="height: 84px;">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Payment Type</p>
                            <select v-model="UnitTransType" id="TransType" data-type="select" :disabled="Requesting">
                                <option value="-1">- Select One -</option>
                                <option value="Contract">Contract Work</option>
                                <option value="Electrical">Electrical Work</option>
                                <option value="Groundskeeper">Groundskeeper</option>
                                <option value="Handyman">Handyman General Repair</option>
                                <option value="Insurance">Insurance</option>
                                <option value="Minor">Minor Project</option>
                                <option value="Plumbing">Plumbing Issue</option>
                                <option value="Pool">Pool Repair</option>
                                <option value="Property">Property Enhancement</option>
                                <option value="Property Management">Property Management</option>
                                <option value="Rental Expenses">Rental Expenses</option>
                                <option value="Side">Side Walk Repair</option>
                                <option value="Advertisement">Advertisement</option>
                                <option value="Rental Listing">Rental Listing</option>
                                <option value="Sprinkler">Sprinkler Repair</option>
                                <option value="Survey">Survey</option>
                                <option value="Taxes">Taxes</option>
                                <option value="Utility">Utility Installation/Repair</option>
                                <option value="misc">Other/Misc</option>
                            </select>
                        </div>
                    </div>

                    <div class="half hright">
                        <div class="pf--input">
                            <p>Amount ($)</p>
                            <input type="number" v-model="TransAmount" id="TransAmount" 
                            placeholder="Price" data-type="len" data-max="1" :disabled="Requesting" />
                        </div>
                    </div>
                </div>

                <!-- description of the transaction -->
                <div class="pf--input" v-if="UnitTransType == 'misc'">
                    <p>Remarks</p>
                    <input v-model="Remarks" type="text" placeholder="Transaction notes" id="Remarks" data-type="len" data-max="0"  :disabled="Requesting" />
                </div>

            </form>

        </div>

        <div class="form-action">
            <div class="form-area">
                <div v-if="!Requesting && PreviousPage" class="btn-secondary process-back" @click="getBackToFirstPage"><img src="./../../../images/icons/arrow-left-blue.svg" /></div>
                <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="AddBusinessExpense" style="display: block;width: 120px;margin-top: 15px;float: left;">
                    <p v-if="!Requesting">Add Expense</p>
                    <img v-else class="loader" src="./../../../images/gif/btn-loader.gif" />
                </span>
                <span v-if="formError" class="__formerror">{{ infoText }}</span>
            </div>
        </div>

    </div>
    
</template>

<script>

    // scripts
    import Locale from '../../../javascript/Locale';
    import FormValidation from '../../../javascript/FormValidation';
    import Request from '../../../javascript/getRequest';
    import Handler from '../../../javascript/Handler';

    // components
    import PropertyList from '../../blocks/PropertyList.vue';

    // pinia
    import { useOwnerStore } from './../../../stores/OwnerStore';

    export default {
        name: 'BusinessExpense',
        props: ['data'],
        emits: ['openmainpage', 'closepage'],

        components: {
            PropertyList,
        },

        data () {
            return {
                // fetching data or not
                Requesting: false,
                PreviousPage: this.data.isMainPage,

                // Form Modal
                UnitTransType : '-1',
                TransAmount : '',
                Remarks: '',

                // form handler
                formError: false,
                infoText: '',

                // Properties list
                SelProperty: '',
                OwnerProperties : false,
                SelectedProperty : false,
                SelectProperty : false,
                PropertySingleSelect: false,

                // pinia storage
                OwnerStore: useOwnerStore (),
                FinanceData: {},

                // for remarks i.e. notes
                TransactionName : Locale.TransactionType(),
            }
        },

        mounted () {

            this.FinanceData = this.OwnerStore.financepaymentdata;

            // if finance add transaction from internal property
            if (this.FinanceData.property) {
                this.PropertySelected (this.FinanceData.whichProperty);
            }

            // update pinia storage to local variables
            this.OwnerProperties = this.OwnerStore.properties;

        },

        methods: {

            // go the first page i.e. choose payment type
            getBackToFirstPage () {
                this.$emit('openmainpage');
            },

            // add new business expense
            async AddBusinessExpense () {
                if (this.Requesting) return;
                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-finance');
                let canGoAhead = FormValidation.Validate (form);

                if (canGoAhead) {
                    this.Requesting = true;

                    // collect form data
                    let formData = new FormData();
                    let Notes;
                    formData.append('property', this.SelectedProperty);
                    formData.append('amount', this.TransAmount);
                    formData.append('transtype', this.UnitTransType);

                    if (this.UnitTransType == 'misc') Notes = this.Remarks;
                    else Notes = this.UnitTransType;
                    formData.append('remarks', Notes);

                    // upload and load data from server
                    const ReturnData = await Request.postServer('owner/finance/buzz-expense.php', formData);
                    this.Requesting = false;

                    // now check if the email already exists
                    if (ReturnData.error == false) {
                        // now close modal, and load current property
                        // update notification
                        Handler.PropNotification ('Successfully added new business expense.');
                        this.$emit('closepage', ReturnData.response);

                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again.";
                    } 
                }


            },

            PropertySelected (property) {
                if (property !== null) {
                    // remove the selected unit
                    this.SelUnit = '';

                    this.SelProperty = property.name;
                    this.SelectedProperty = property.id;

                    // now load its unit too
                    // this.LoadPropertyUnits (property.id);
                }this.PropertySingleSelect = false;
            },



        }
        
    }
</script>