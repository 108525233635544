<template>
    <div class="prop-form _propscrl">
        <div class="form-area" style="padding-bottom: 65px;">
            
            <form name="propform" id="propform-finance" style="display: block;">
                <div class="pf--title">
                    <h3>Add Unit/Tenant Payment</h3>
                    <p>You can add payments here received from your tenants.</p>
                </div>

                <div class="pf--multi-input" style="height:85px;">
                    <div class="half hleft">
                        <div class="pf--input" style="overflow:unset; position:relative;">
                            <p>Choose Property</p>
                            <input v-model="SelProperty" type="text" placeholder="Choose One" id="Properties" data-type="len" data-max="5" 
                                        readonly 
                                        @click="PropertySingleSelect = true"
                                        :disabled="Requesting || data.EditMode || FinanceData.property" />
                            <PropertyList v-if="PropertySingleSelect" :distyle="'top: 0px;position: absolute;height: 355px;width: 360px;left: 0px;overflow-y: scroll;'" :properties="OwnerProperties" @prosel="PropertySelected" @proboxclse="PropertySelected" />
                        </div>
                    </div>

                    <div class="half hright">
                        <div class="pf--input" style="overflow:unset; position:relative;">
                            <p>Choose Unit</p>
                            <input v-model="SelUnit" type="text" placeholder="Choose One" id="Units" data-type="len" data-max="5" 
                                    readonly 
                                    @click="UnitsSingleSelect = true"
                                    :disabled="Requesting || data.EditMode || FinanceData.unit" />
                            <UnitSelection v-if="UnitsSingleSelect == true" :units="PropertyUnits" :distyle="'top: 0px;'" @lstboxclse="UnitSelected" />
                        </div>
                    </div>                    
                </div>

                <div class="pf--multi-input" style="height: 84px;">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Payment Type</p>
                            <select v-model="PaymentType" id="PaymentType" data-type="select" :disabled="Requesting">
                                <option value="-1">- Select One -</option>
                                <option value="check">Check</option>
                                <option value="Credit Card">Credit Card</option>
                                <option value="moneyorder">Money Order</option>
                                <option value="venmo">Venmo</option>
                                <option value="zelle">Zelle</option>
                            </select>
                        </div>
                    </div>

                    <div class="half hright">
                        <div class="pf--input">
                            <p>Amount ($)</p>
                            <input type="number" v-model="TransAmount" id="TransAmount" 
                            placeholder="Price" data-type="len" data-max="1" :disabled="Requesting" />
                        </div>
                    </div>
                </div>

                <!-- description of the transaction -->
                <div class="pf--input">
                    <p>Remarks</p>
                    <input v-model="Remarks" type="text" placeholder="Transaction notes" id="Remarks" data-type="len" data-max="0"  :disabled="Requesting" />
                </div>

            </form>

        </div>

        <div class="form-action">
            <div class="form-area">
                <div v-if="!Requesting && PreviousPage" class="btn-secondary process-back" @click="getBackToFirstPage"><img src="./../../../images/icons/arrow-left-blue.svg" /></div>
                <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="AddNewTransaction" style="display: block;width: 120px;margin-top: 15px;float: left;">
                    <p v-if="!Requesting">{{ ActionButton }}</p>
                    <img v-else class="loader" src="./../../../images/gif/btn-loader.gif" />
                </span>
                <span v-if="formError" class="__formerror">{{ infoText }}</span>
            </div>
        </div>
    </div>
</template>

<script>

    // scripts
    import DataCenter from '../../../javascript/DataCenter';
    import FormValidation from '../../../javascript/FormValidation';
    import Request from '../../../javascript/getRequest';
    import Handler from '../../../javascript/Handler';

    // components
    import PropertyList from '../../blocks/PropertyList.vue';
    import UnitSelection from '../../web-forms/UnitSelection.vue';

    // pinia
    import { useOwnerStore } from './../../../stores/OwnerStore';


    export default {
        name: 'TenantPayment',
        props: ['data'],
        emits: ['openmainpage', 'closepage'],

        components: {
            PropertyList,
            UnitSelection,
        },

        data () {
            return {
                // fetching data or not
                ActionButton : 'Add Payment',
                Requesting: false,
                PreviousPage: this.data.isMainPage,

                // Form Modal
                PaymentType : '-1',
                TransAmount : '',
                Remarks: '',

                // form handler
                formError: false,
                infoText: '',

                // Properties list
                SelProperty: '',
                OwnerProperties : false,
                SelectedProperty : false,
                SelectProperty : false,
                PropertySingleSelect: false,

                // units
                SelUnit : '',
                SelUnitID : null,
                PropertyUnits : false,
                UnitsSingleSelect : false,
                SelectedUnit : null,

                // tenant and assignment
                SelectedTenant: false,
                SelectedAssignment: false,

                // pinia storage
                OwnerStore: useOwnerStore (),      
                FinanceData: {},

            }
        },

        mounted () {

            this.FinanceData = this.OwnerStore.financepaymentdata;

            // if finance add transaction from internal property
            if (this.FinanceData.property) {
                this.PropertySelected (this.FinanceData.whichProperty);
            }

            // update pinia storage to local variables
            this.OwnerProperties = this.OwnerStore.properties;

            // if it was on edit mode
            if (this.data.EditMode) {
                this.SelProperty = this.data.trans.propname;
                this.SelUnit = 'Unit ' + this.data.trans.unit;
                var CurrentAmount = this.data.trans.amount;
                if (CurrentAmount < 0) CurrentAmount = -CurrentAmount;
                this.TransAmount = CurrentAmount;

                this.PaymentType = this.data.trans.paytype;
                this.Remarks = this.data.trans.type;

                // other form payment
                this.SelectedProperty = this.data.trans.pid;
                this.SelUnitID = this.data.trans.uid;
                this.SelectedTenant = this.data.trans.tid;
                this.SelectedAssignment = this.data.trans.aid;

                // button
                this.ActionButton = "Edit Payment";
            }
            
        },

        methods: {

            // go the first page i.e. choose payment type
            getBackToFirstPage () {
                this.$emit('openmainpage');
            },

            async AddNewTransaction () {
                if (this.Requesting) return;
                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-finance');
                let canGoAhead = FormValidation.Validate (form);

                if (canGoAhead) {
                    this.Requesting = true;

                    // collect form data
                    let formData = new FormData();
                    formData.append('property', this.SelectedProperty);
                    formData.append('unit', this.SelUnitID);
                    formData.append('tenant', this.SelectedTenant);
                    formData.append('assignment', this.SelectedAssignment);
                    formData.append('type', this.PaymentType);
                    formData.append('amount', this.TransAmount);
                    formData.append('remarks', this.Remarks);

                    if (this.data.EditMode) {
                        // upload and load data from server
                        formData.append('trans', this.data.trans.trans);
                        const ReturnData = await Request.postServer('owner/finance/edit-payment.php', formData);
                        this.Requesting = false;

                        // update the last updated transaction
                        var RT = {
                            trans: this.data.trans.trans,
                            paytype: this.PaymentType,
                            amount: this.TransAmount,
                            EditMode: true,
                        }

                        // now check if the email already exists
                        if (ReturnData.error == false) {
                            // now close modal, and load current property
                            // update notification
                            Handler.PropNotification ('Successfully updated your selected payment.');
                            this.$emit('closepage', RT);

                        }else {
                            this.formError = true;
                            this.infoText = "Something went wrong! Please try again.";
                        }
                    }else {
                        // upload and load data from server
                        const ReturnData = await Request.postServer('owner/finance/add-payment.php', formData);
                        this.Requesting = false;

                        // now check if the email already exists
                        if (ReturnData.error == false) {
                            // now close modal, and load current property
                            // update notification
                            Handler.PropNotification ('Successfully added new payment.');
                            this.$emit('closepage', ReturnData.response);

                        }else {
                            this.formError = true;
                            this.infoText = "Something went wrong! Please try again.";
                        }                        
                    }
                }

            },

            PropertySelected (property) {
                if (property !== null && property != false) {
                    // remove the selected unit
                    this.SelUnit = '';

                    this.SelProperty = property.name;
                    this.SelectedProperty = property.id;

                    // now load its unit too
                    // also lcheck if unit is already selected
                    if (this.FinanceData.unit) this.UnitSelected(this.FinanceData.whichUnit);
                    else this.LoadPropertyUnits (property.id);
                }
                
                this.PropertySingleSelect = false;
            },



            UnitSelected (unit) {
                if (unit !== null) {
                    this.SelUnit = unit.unit_name;
                    this.SelUnitID = unit.id;
                    this.SelectedTenant = unit.tenant.tid;
                    this.SelectedAssignment = unit.assignment;
                }this.UnitsSingleSelect = false; 

            },

            async LoadPropertyUnits (property) {   
                this.Requesting = true;
                const Units = await DataCenter.ProPRequest('property-units', false, {
                    key : 'property',
                    value : property
                });this.Requesting = false;
                
                // now filter units which only have assigned tenants
                if (!Units) {}else {
                    this.PropertyUnits = Units;

                    // filter only those unit who have tenants
                    this.PropertyUnits = this.PropertyUnits.filter (unit => {
                        if (unit.status == 'rented') return unit;
                    });
                }  
            },


        }
        
    }
</script>