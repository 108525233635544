<template>
    <div class="fc--header" style="overflow: unset;">      
      <div class="fc-more-filter" style="width: 100%">

        <div class="fmf-btn" @click="PropertySingleSelect = true">
            <img src="./../../../images/icons/sort.svg" />
            <p>Choose Property</p>
        </div>
        <PropertyList v-if="PropertySingleSelect" :properties="OwnerProperties"  @prosel="PropertySelected"
                @proboxclse="PropertySelectClose"
                :distyle="'top: 65px;position: absolute;height: 355px;width: 360px;left: 23px;overflow-y: scroll;'" />

      </div>
    </div>

    <div class="fin-summery" style="margin-top: 88px;">
        <ul>
            <li>
                <div class="image" style="background: #fd686b;">
                    <img src="./../../../images/finance/income.svg" />
                </div>
                <div class="content">
                    <p>Total Income</p>
                    <h3>${{Total.income}}</h3>
                </div>
            </li>

            <li>
                <div class="image" style="background: #209cff;">
                    <img src="./../../../images/finance/expense.svg" />
                </div>
                <div class="content">
                    <p>Total Expenses</p>
                    <h3>${{Total.expense}}</h3>
                </div>
            </li>

            <li>
                <div class="image" style="background: #ff8818;">
                    <img src="./../../../images/finance/profit.svg" />
                </div>
                <div class="content">
                    <p>Total Profit</p>
                    <h3>${{Total.profit}}</h3>
                </div>
            </li>

            <li>
                <div class="image" style="background: #fd686b;">
                    <img src="./../../../images/finance/fin-units.svg" />
                </div>
                <div class="content">
                    <p>Total Units</p>
                    <h3 style="margin-top: 7px;">
                        <span>{{ Total.blank + Total.rented}} /</span>
                        <span class="big">{{Total.rented}}</span>
                    </h3>
                </div>
            </li>
        </ul>
    </div>

    <div v-if="isRequesting" style="margin-top:145px">
        <SkeletonVue :type="'user-list'" :data="2" />
    </div>

    <div v-if="!isRequesting" class="fin-property">
        <ul>
            <li v-for="property in Properties">
                <div class="property">
                    <div class="image">
                        <ReplaceImage :source="property.detail.thumbnail" />
                    </div>
                    <div class="content">
                        <h3>{{property.detail.name}}</h3>
                        <p>{{property.detail.address}}</p>
                    </div>
                </div>
                <div class="fp-transactions">
                    <div class="fpt-table">                    
                        <div class="prop--table">
                            <div class="prop-row row-header" style="background-color: #f1f1f1 !important;">
                                <div class="column column40"><p>Particular</p></div>
                                <div class="column column20"><p>{{Months[1]}}</p></div>
                                <div class="column column20"><p>{{Months[2]}}</p></div>
                                <div class="column column20"><p>{{Months[3]}}</p></div>
                            </div>

                            <div class="prop-row" v-for="finance in property.finance">
                                <div class="column column40"><p>{{finance[0]}}</p></div>
                                <div class="column column20"><p>{{finance[1].toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            })}}</p></div>
                                <div class="column column20"><p>{{finance[2].toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            })}}</p></div>
                                <div class="column column20"><p>{{finance[3].toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            })}}</p></div>
                            </div>
                        </div>                    
                    </div>

                    <div class="fpt-chart">
                        <div class="ch-ar-t" id="property_chart_container"></div>
                    </div>

                </div>
            </li>
        </ul>
    </div>

</template>

<script>

    import DataCenter from '../../../javascript/DataCenter';

    import ReplaceImage from '../../blocks/ReplaceImage.vue';
    import SkeletonVue from '../../blocks/Skeleton.vue';

    // component
    import PropertyList from '../../blocks/PropertyList.vue';

    // pinia
    import { useOwnerStore } from './../../../stores/OwnerStore';

    export default {
        name: 'ProfitLoss',
        components: {
            ReplaceImage,
            SkeletonVue,

            PropertyList,
        },

        data () {
            return {
                isRequesting: true,

                Properties: [],
                Total: {
                    income: 0,
                    expense: 0,
                    profit: 0,
                    rented: 0,
                    blank: 0
                },

                Months: [],

                PropertySingleSelect: false,
                SelectedProperty: null,

                // pinia storage
                OwnerProperties: false,
                OwnerStore: useOwnerStore (),
                FinanceProfitLoss: [],
            }
        },

        async mounted () {

            // reset on the main page
            // finance payment data
            this.OwnerStore.financepaymentdata = {
                type: 'choose',
                isMainPage: true,
                tenant: false,
                property: false,
                unit: false,
                whichProperty: {},
                whichUnit: {},
                whichTenant: {}
            }

            // update pinia storage to local variables
            this.OwnerProperties = this.OwnerStore.properties;
            this.SelectedProperty = this.OwnerProperties[0];
            this.FinanceProfitLoss = this.OwnerStore.finance.profitloss;

            // load current owner property transactions
            this.LoadSelectedPropertyPLData (this.SelectedProperty.id);
        },

        methods: {

            async LoadSelectedPropertyPLData (property) {
                this.isRequesting = true;

                // check if current property data is already on pinia store
                let Transactions;
                if (this.FinanceProfitLoss[property] == undefined) {
                    Transactions = await DataCenter.ProPRequest('owner-property-transaction-list', false, {
                        key : 'property',
                        value : property
                    });

                    // store to pinia
                    this.FinanceProfitLoss[property] = Transactions;
                }else {
                    Transactions = this.FinanceProfitLoss[property];
                }                         
                
                // next process   
                if (Transactions) {
                    this.Properties = Transactions.properties;
                    this.Total = Transactions.total;
                    this.Months = Transactions.month;
                    
                    // show data
                    this.isRequesting = false;
                    this.UpdatePropertyChart ();
                }
            },

            UpdatePropertyChart () {
                var propChartdata = {
                    income: [],
                    profit: []
                }

                // month name i.e. categories
                var Categories = [this.Months[1], this.Months[2], this.Months[3]];

                // now current property income and total data
                // currently it is on 5th and 7th of array
                // later we will continue with php from server
                var FinanceData = this.Properties[0].finance;
                var ProfitIndex, IncomeIndex;
                ProfitIndex = FinanceData.length - 1;
                IncomeIndex = ProfitIndex - 2;
                var FDIncome = FinanceData[IncomeIndex];
                var FDProfit = FinanceData[ProfitIndex];

                propChartdata.income = [FDIncome[1], FDIncome[2], FDIncome[3]];
                propChartdata.profit = [FDProfit[1], FDProfit[2], FDProfit[3]];

                setTimeout(() => {
                    this.PropertyChart ('property_chart_container', propChartdata, Categories);
                }, 250);
            },
            
            PropertyChart (id, data, categories) {
                var ChartID = document.getElementById (id);
                Highcharts.chart(ChartID, {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    xAxis: {
                        categories: categories,
                        crosshair: true
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: ''
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                            '<td style="padding:0"><b>${point.y:.1f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    series: [{
                        name: 'Income',
                        data: data.income

                    }, {
                        name: 'Profit',
                        data: data.profit

                    }]
                });
            },

            PropertySelectClose () {
                this.PropertySingleSelect = false;
            },

            PropertySelected (property) {
                if (property !== null) {
                    this.SelectedProperty = property;

                    // now load the selected property profit and loss
                    this.LoadSelectedPropertyPLData (property.id);

                }this.PropertySingleSelect = false;
            },
        }
    }

</script>

<style scoped>
    div.fmf-btn img {
        object-fit: contain !important;
    width: 12px !important;
    height: 12px !important;
    margin-top: 4px !important;
    }
</style>