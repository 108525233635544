<template>
    <ul class="prop-left">
        <li>
            <div class="dotblue"></div>
            <div class="pcontent">
                <p><strong>{{ occupiedUnit }}</strong> Occupied</p>
            </div>
        </li>
        <li>
            <div class="dotlblue"></div>
            <div class="pcontent">
                <p><strong>{{ vacantUnit }}</strong> Vacant</p>
            </div>
        </li>
        <li>
            <div class="orange"></div>
            <div class="pcontent">
                <p><strong>{{ maintenanceUnit }}</strong> Maintenance</p>
            </div>
        </li>
    </ul>
    <div class="prop-right">
        <div id="properties-chart" style="overflow: hidden;width: 100%;height: 100%; "></div>
    </div>
</template>

<script>
    // components
    import Skeletion from './../../blocks/Skeleton.vue';

    export default {
        name: 'Properties',
        props: ['data'],
        components: {
            Skeletion,
        },

        data () {
            return {
                occupiedUnit : 0,
                vacantUnit : 0,
                maintenanceUnit : 0,
                totalUnits : 0,
            }
        },

        mounted () {
            
            // set the data
            this.occupiedUnit = this.data.occupied;
            this.vacantUnit = this.data.vacant;
            this.maintenanceUnit = this.data.maintenance;
            this.totalUnits = this.data.total;

            this.PropertyChart (this.data);
        },

        methods : {

            // chart settings
            PropertyChart (data) {
                Highcharts.chart('properties-chart', {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie'
                    },
                    title: {
                        text: ''
                    },
                    tooltip: {                        
                        pointFormat:  '<b>{point.y}</b>',
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.textColor) || '#545758',                            
                        }                    
                    },
                    title: {
                        text: '<b>' + data.total + '</b><br><p>Total Units</p>',
                        align: 'center',
                        verticalAlign: 'middle',
                        y: 20,
                        style: {
                            color:  '#545758', fontSize:'14'                             
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    plotOptions: {
                        pie: {
                            dataLabels: {
                                enabled: false
                            },
                            colors: [
                                '#A6CEE3',
                                '#5FA7FA',
                                '#FFAA00'
                            ],
                            size: '100%'
                        }
                    },
                    legend: {
                        title: {
                            text: '',
                            style: {
                                color: (Highcharts.theme && Highcharts.theme.textColor) || 'black'
                            }
                        }        
                    },
                    series: [{
                        type: 'pie',
                        name: 'properties',
                        colorByPoint: true,
                        innerSize: '70%',
                        data: [{
                            name: 'Occupied Units',
                            y: data.occupied,
                        }, {
                            name: 'Vacant Units',
                            y: data.vacant,
                        }, {
                            name: 'Maintenance',
                            y: data.maintenance,
                        }]
                    }]
                });
            },

        }
    };
</script>