<template>
    <div class="tinput">
        <p>{{label}} <strong v-if="required">*</strong></p>
        <select @change="inputData ($event.target.value)">
            <option value="-1">Select</option>
            <option v-for="select in data" :key="select.key" :value="select.value">{{ select.name }}</option>
        </select>
    </div>
</template>

<script>
    export default {
        name: 'SelectInput',
        props: ['label', 'required', 'data'],
        methods : {
            inputData (value) {
                this.$emit('inputvalue', value);
            }
        }
    };
</script>