<template>
    <div class="prop-row" v-if="parseInt(unit.floor) == floor" :class="count % 2 ? 'odd' : 'even'" style="overflow: unset; float: left; cursor:pointer;" @click="UnitClickHandler(unit)">
        <div class="column column35">
            <div class="unit--tenant">
                <div class="image">
                    <ReplaceImage :source="unit.thumbnail" />
                </div>

                <div class="content">
                    <h3>{{unit.unit_name}}</h3>
                    
                    <p v-if="unit.status == 'rented' && unit.balance < 0" style="color: #f00;">Balance: -${{-unit.balance}}</p>
                    <p style="color: #919191;" v-else>Balance: ${{unit.balance}}</p>
                    
                    <p v-if="unit.status == 'rented'">{{unit.tenant.fullname}}</p>
                    <p v-else style="color: #919191;font-size: 9pt;line-height: 16pt;">unit is not rented yet.</p>
                    
                </div>
            </div>
        </div>

        <div class="column column25">
            <div class="unit--status">
                <div v-if="unit.status == 'rented'" class="status secondary">Occupied</div>
                <div v-else class="status primary">Open</div>
                <p v-if="unit.status == 'rented'">{{ unit.status_date}}</p>
            </div>
        </div>

        <div class="column column25">
            <div class="floor-plan">
                <h3 v-if="!unit.floorplan" style="color: #9e9e9e;font-size: 10pt;font-weight: 400;letter-spacing: 0.15px;">Not Assigned</h3>
                <h3 data-name="floorplan" v-else>{{unit.floorplan.name}}</h3>
            </div>
        </div>

        <div class="column column15" style="position: relative;">
            <div class="unit-menu" data-name="hammenu">
                <img src="../../../images/icons/ham-menu.svg" data-name="hammenu" />
            </div>
            <ul class="action-menu" v-if="unit.active == true">
                <li data-name="closeham"><img src="./../../../images/icons/close.svg" data-name="closeham"/></li>
                <li data-name="assign-floorplan" v-if="!unit.floorplan">Assign Floorplan</li>
                <li data-name="assign-tenant" v-if="unit.status == 'blank'">Assign Tenant</li>
                <li data-name="end-contract" v-if="unit.status == 'rented'">End Contract</li>
            </ul>
        </div>
    </div>
</template>

<script>
    //@import component
    import ReplaceImage from './../../blocks/ReplaceImage.vue';

    export default {
        name: 'UnitLists',
        props: ['unit',  'count', 'floor'],
        emits: ['hamclick', 'assignplan', 'unitselected', 'assigntenant', 'unassigntenant', 'floorplan'],
        components: {
            ReplaceImage,
        },

        methods: {
            UnitClickHandler (unit) {
                let target = event.target.getAttribute('data-name');

                // want to assign floor plan
                if (target == "assign-floorplan") {
                    this.$emit('assignplan', this.unit);

                // want to assign tenant
                }else if (target == "assign-tenant") {
                    this.$emit('assigntenant', this.unit);

                // want to assign tenant
                }else if (target == "end-contract") {
                    this.$emit('unassigntenant', this.unit);

                // want to change unit image
                }else if (target == "update-image") {
                
                // want to see the unit menu list
                }else if (target == "hammenu") {
                   this.$emit('hamclick', this.unit);
                
                // want to close the ham menu
                }else if (target == "closeham") {
                    this.unit.active=false;

                // want to load the floorplan
                }else if (target == "floorplan") {
                    this.$emit('floorplan', unit);

                // want unit detail page
                }else {
                    this.$emit('unitselected', unit);
                }
            },
        }
    };
</script>