<script>

    export default {
        name: 'PropNotification',
        methods: {
            Hide () {
                var notification = this.$refs.propnotification;
                notification.style.display = 'none';
            }
        }
    }

</script>

<template>
    <div class="propnotification prop-notification psanim-3" ref="propnotification">
        <img src="./../../images/icons/close-circled.svg" @click="Hide" />
        <p>Here's your new notification, it will be shown only you change something on app</p>
    </div>
</template>