<template>
    <ul class="contact-summery">
        <li>
            <p>Legal Name</p>
            <h3>{{ profile.fullname }}</h3>
        </li>
        <li>
            <p>Birthday</p>
            <h3>{{ profile.dob }}</h3>
        </li>
        <li>
            <p>Last Login</p>
            <h3>{{ profile.login }}</h3>
        </li>
        <li>
            <p>Date of Hire</p>
            <h3>{{ profile.hired.date }}</h3>
            <p class="p-color">{{ profile.hired.count}} {{ profile.hired.time}} ago</p>
        </li>
        <li>
            <h3>Description</h3>
            <p>No profile description yet.</p>
        </li>                        
    </ul>

    <div class="managed-property">
        <div class="title">
            <p>Managed Property <span @click="MultiPropertySelection = true"><img src="./../../../images/icons/plus.svg" /></span></p>

            <MultiPropertySelectionVue v-if="MultiPropertySelection" :properties="properties" :style="'top: -10px;left: -155px;'"   
                        @lstboxclse="PropertiesAreSelected"/>
        </div>
        
        <ul class="property-list">
            <li v-for="assignme in profile.assignments" :key="assignme">
                <div class="image">
                    <ReplaceImage :source="assignme.property.thumbnail" />
                </div>
                <div class="detail-wrp">
                    <p class="property-name">{{assignme.property.name}}</p>
                    <p class="address">{{assignme.property.address}}</p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>

    import Request from './../../../javascript/getRequest';

    // @ import component
    import ReplaceImage from './../../blocks/ReplaceImage.vue';
    import MultiPropertySelectionVue from './../../web-forms/MultiPropertySelection.vue';

    export default {
        name: 'Profile',
        props: ['profile', 'properties'],
        components: {
            ReplaceImage,
            MultiPropertySelectionVue
        },

        data () {
            return {
                // property selection
                MultiPropertySelection: false,
                PropertiesSelected : [],

                PropertyName : '',
                PropertyIDs: '',

            }
        },

        methods: {

            // filter the properties by selection
            PropertiesAreSelected (propties) {
                this.MultiPropertySelection = false;
                this.PropertiesSelected = propties;
                this.PropertyIDs = '';

                var selected = 0;
                this.PropertiesSelected.filter (property => {
                    if (property.active == true) {
                        this.PropertyIDs += property.id + ','
                        selected++;
                    }
                });

                if (selected <= 0) this.PropertyName = '';
                else this.PropertyName = selected + ' Property Selected';

                // now update to the employee data
                this.UpdateToServer ();
            },

            // upadte selected properties to the current employee
            async UpdateToServer () {
                
                let formData = new FormData();
                formData.append('user', this.profile.id);
                formData.append('role', this.profile.role);
                formData.append('properties', this.PropertyIDs);

                // load data from server
                const ReturnData = await Request.postServer('employees/update-property.php', formData);
                if (ReturnData.error == false) {
                    var newassignments = ReturnData.response.assignments;
                    this.profile.assignments = newassignments;
                }
            }

        },
        
    };
</script>