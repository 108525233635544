<template>
    <div class="parking-sel">
        <div class="image">
            <img v-if="parking.type == 'Guest Parking'" src="../../images/icons/guest-parking.svg" />
            <img v-else-if="parking.type == 'Handicap Parking'" src="../../images/icons/handicap-parking.svg" />
            <img v-else src="../../images/icons/normal-parking.svg" />
        </div>

        <div class="content">
            <h3>{{parking.name}}</h3>
            <p>{{parking.type}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ParkingSelected',
        props: ['parking']
    };
</script>