<template>
    <div class="emptyskeleton">
        <img src="./../../../images/icons/issues.svg" class="psanim-1 fadeInUp" style="width: 220px;">
        <p class="psanim-2 fadeInUp">No Issues found for {{ profile.fullname }}. <br>Issues will be recorded for every tenant here.</p>
        <div class="btn-secondary psanim-3 fadeInUp">
            <p>Add Issue</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Issue',
        props: ['profile'],
    };
</script>