<script>

    import Document from '../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';

    import Skeleton from './../../components/blocks/Skeleton.vue';
    import ReplaceImage from '../../components/blocks/ReplaceImage.vue';
    import HtmlSelect from './../../components/blocks/HtmlSelect.vue';
    import PropModal from '../../components/modal/PropModal.vue';

    export default {
        name: 'Dispute',
        props: ['userProfile', 'owners'],
        components: {
            Skeleton,
            ReplaceImage,
            HtmlSelect,

            PropModal,
        },

        data () {
            return {

                Filter: [
                    {
                        id      : 1,
                        name    : 'All',
                    },
                    {
                        id      : 2,
                        name    : 'Pending',
                    },
                    {
                        id      : 3,
                        name    : 'Completed',
                    }
                ],

                Properties: {
                    raw: [],
                    filtered: []
                },

                SearchText: '',

                isRequesting: true,
                ShowPropertyInfo: false,
            }
        },

        async mounted () {

            // site title and seo
            Document.DocumentHeadings ({title : 'Dashboard - Dispute'});

            // load the dispute information
            let ReturnData = await DataCenter.ProPRequest('disputed-properties', false, false);
            ReturnData.map(property => {
                property['location'] = Document.MinifiedText(property.property.address);
            });

            // update information
            this.Properties.raw = ReturnData;
            this.Properties.filtered = ReturnData;

            this.isRequesting = false;

        },

        methods: {

            // filter click
            FilterHandler (filter) {
                this.Properties.filtered = this.Properties.raw.filter(dispute => {
                    if (filter.name == "All") return dispute;

                    // if (filter.name == "Pending") {
                    //     if (dispute.property.verified == "true") return dispute;
                    // }

                    // if (filter.name == "Completed") {
                    //     if (dispute.property.verified == "false") return dispute;
                    // }
                });
            },

            CloseConformModal () {
                this.ShowPropertyInfo = false;
            },

            ClickHandler (property) {
                this.PropertyData = property;
                this.ShowPropertyInfo = true;
            },

            SearchProperties () {
                var keyword = this.SearchText.toUpperCase();
                this.Properties.filtered = this.Properties.raw.filter(dispute => {
                    if (dispute.property.name.toUpperCase().indexOf(keyword) > -1) return dispute;
                });                
            },

        }

    }

</script>

<template>
    <div class="admin-property _propscrl">
        <div class="dashpageheader">
            <div class="dash-header">
                <div class="actions">
                    <HtmlSelect :options="Filter" @selected="FilterHandler" />
                    <input type="text" placeholder="Search" v-model="SearchText" @keyup="SearchProperties()">
                </div>
            </div>
        </div>

        <div v-if="isRequesting" style="width: 50%;display: flex;margin: 0px auto;margin-top: 25%;">
            <Skeleton :type="'user-list'" :data="2" />
        </div>

        <ul class="dash-list" v-if="!isRequesting">
            <li v-for="dispute in Properties.filtered" :key="dispute.id" @click="ClickHandler(dispute)">
                <div class="content">
                    <div class="image property">
                        <ReplaceImage :source="dispute.property.thumbnail" />
                    </div>
                    <div class="dlc-detail">
                        <div class="od-header">
                            <h3>{{ dispute.property.name }}</h3>
                            <span class="image" v-if="dispute.property.verified == 'true'"><img src="../../images/icons/nverified.svg" alt="Property Verified"></span>
                        </div>
                        <div class="od-footer">
                            <ul>
                                <li>
                                    <img src="../../images/icons/nmap.svg">
                                    <p>{{ dispute.location }}</p>
                                </li>
                                <li>
                                    <img src="../../images/icons/user.svg">
                                    <p>{{ dispute.property_owner.fullname }}</p>
                                </li>
                                <li>
                                    <img src="../../images/icons/ndate.svg">
                                    <p>{{ dispute.created }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <ul class="dli-hover">
                    <li>
                        <img src="../../images/icons/nedit.svg">
                    </li>
                </ul>
            </li>
        </ul>

        <div v-if="Properties.filtered.length == 0 && !isRequesting" class="empty-result animated fadeInUp" style="position: unset;height: 50vh;">
            <img src="../../images/icons/empty-result.svg" />
            <p>You don't have much information on this request.</p>
            <p>You can filter properties.</p>
        </div>
    </div>

    <!-- Property detail and Confirm verificaiton -->
    <PropModal v-if="ShowPropertyInfo == true" :from="'property-info'" :data="PropertyData" @modalclose="CloseConformModal" />


</template>