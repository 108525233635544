<template>
    <div class="modify-units">
        <div class="mu--floors _propscrl">
            <ul>
                <li v-for="floor in PropertyFloors" :key="floor" :class="{active : floor.active}" @click="FloorClicked (floor)">
                    <p>{{ floor.name }} Floor</p>
                </li>
                <li @click="AddNewFloor"><img src="../../images/icons/add.svg" /><p>Add Floor</p></li>
            </ul>
        </div>

        <div class="mu--units _propscrl">
            <div class="prop--table">
                <div class="prop-row row-header">
                    <div class="column column35">Floor Units</div>
                    <div class="column column25">Status</div>
                    <div class="column column25">Floor Plan</div>
                    <div class="column column15">Actions</div>
                </div>
                <ModifyUnitList v-for="(unit, index) in PropertyUnits" :key="unit.id" :unit="unit" :count="index" :floor="FloorActive" @delete="DeleteUnit" />
            </div>
        </div>

    </div>

    <div class="form-action" style="width: calc(100% - 55px);bottom: 0px;height: 74px;">
        <div class="form-area" style="width: 95%;">
            <div v-if="doesSelectedFloorisEmpty" class="btn-primary" ref="DeleteFloor" style="background-color: #eb3800;border: 1px solid #eb3800;" @click="DeleteFloor">Delete Floor</div>
            <div class="btn-secondary" ref="AddUnit" @click="AddNewUnit">Add New Unit</div>

            <span class="primary-btn-loader" :class="isRequesting ? 'btn-disabled' : ''" style="float: left !important;margin-top: 15px;margin-left: 10px;padding: 7px 15px;" @click="MondifyUnits">
                <p v-if="!isRequesting">Update Units</p>
                <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
            </span>
        </div>
    </div>
</template>

<script>
    // import js
    import Locale from './../../javascript/Locale';

    // import component
    import ModifyUnitList from './blocks/ModifyUnitList.vue';

    import Request from '../../javascript/getRequest';

    export default {
        name: 'ModifyUnitsModal',
        emits: ['close'],
        props: ['data'],
        components: {
            ModifyUnitList,
        },

        data () {
            return {
                isRequesting: false,
                totalUnits : 0,
                totalFloor : 0,
                PropertyFloors : [],
                FloorActive : 0,
                PropertyUnits : [],
                FloorName : Locale.FloorNumName(),
                PropertyID : this.data.property,
                
                // other settings
                doesSelectedFloorisEmpty: false,
            }
        },

        mounted () {
            this.totalUnits = this.data.totalunits;
            this.totalFloor = this.data.totalfloor;
            this.PropertyUnits = this.data.units;
            this.PropertyID = this.data.property,

            this.CreateFloorArray ();
        },

        methods: {

            async MondifyUnits () {
                // now only collect the modified units only and move ahead for this.
                var ModifiedUnits = [];
                this.PropertyUnits.filter(unit => {
                    if (unit.modified) {
                        if (unit.deleted && unit.newunit) {                            
                        }else ModifiedUnits.push (unit);
                    }
                });

                // now upload the selected files to the server
                let formData = new FormData();
                formData.append('property', this.PropertyID);
                formData.append('units', JSON.stringify(ModifiedUnits));
                this.isRequesting = true;

                // upload and load data from server
                const ReturnData = await Request.postServer('owner/units/modify.php', formData);
                this.isRequesting = false;

                this.$emit('close', ReturnData.response);


            },

            CreateFloorArray () {
                for (var i = 0; i < this.totalFloor; i++) {
                    this.PropertyFloors[i] = {
                        id      : i,
                        active  : false,
                        name    : this.FloorName[i]
                    }

                    if (i == 0) {
                        this.PropertyFloors[i].active = true;
                        this.FloorActive = i;
                    }
                }
            },

            CheckUnitsonSelectedFloor (floor) {
                var isEmpty = true;
                this.PropertyUnits.filter (unit => {
                    if (floor == unit.floor) isEmpty = false;
                });

                this.doesSelectedFloorisEmpty = isEmpty;
            },

            FloorClicked (floor) {
                this.PropertyFloors.filter(flr => {
                    flr.active = false;
                    if (floor.id == flr.id) {
                        flr.active = true;
                        this.FloorActive = flr.id;

                    }
                    return flr;
                });

                // check for the empty units or not
                this.CheckUnitsonSelectedFloor (this.FloorActive);
            },

            AddNewFloor () {
                // make all floor inactive
                this.PropertyFloors.filter(flr => {
                    flr.active = false;
                });

                // add new floor
                this.totalFloor++;
                let currentFloorCount = this.totalFloor - 1;
                let currentFloor = {
                    id      : currentFloorCount,
                    active  : true,
                    name    : this.FloorName[currentFloorCount]
                }

                this.PropertyFloors[currentFloorCount] = currentFloor;

                this.FloorClicked (currentFloor);
            },

            AddNewUnit () {
                let CurrentFloorUnits;
                CurrentFloorUnits = this.PropertyUnits.filter (unit => {
                    if (parseInt(unit.floor) == this.FloorActive) return unit;
                })

                // now add units to current selected floor
                let totalUnit = CurrentFloorUnits.length;
                let AddedUnit, unitNumber = 0;

                for (var i = 0; i <= totalUnit; i++) {
                    if (i == totalUnit) {
                        unitNumber = (this.FloorActive + 1) * 100;
                        unitNumber += totalUnit + 1;

                        AddedUnit = {
                            floor       : this.FloorActive.toString(),
                            floorplan   : false,
                            id          : 'unitid-' + unitNumber,
                            property_id : null,
                            status      : 'blank',
                            status_date : null,
                            tenant      : [],
                            thumbnail   : '',
                            unit_name   : unitNumber + ' - ' + this.FloorName[this.FloorActive] + ' Floor',
                            newunit     : true,
                            unit_number : unitNumber,
                            modified    : true,
                        }
                    }
                }

                // add current added unit the list
                this.PropertyUnits.push (AddedUnit);

                // check for the empty units or not
                this.CheckUnitsonSelectedFloor (this.FloorActive);
            },

            DeleteUnit (unit) {
                this.PropertyUnits = this.PropertyUnits.filter (unt => {
                    if (unit.id == unt.id) {
                        unit.deleted = true;
                        unit.modified = true;
                    }return unt;
                });

                // check for the empty units or not
                this.CheckUnitsonSelectedFloor (this.FloorActive);
            },

            DeleteFloor () {
                let floorToDelete = this.FloorActive;

                // delete floor
                this.PropertyFloors = this.PropertyFloors.filter(floor => {
                    if (floor.id == floorToDelete) {
                    } else {return floor};
                });
                
                this.totalFloor = this.PropertyFloors.length;

                this.PropertyUnits = this.PropertyUnits.filter (unt => {
                    if (unt.floor == floorToDelete) {
                        console.log ('unit has');
                    } else {return unt};
                });

                // update the floor
                this.CreateFloorArray ();
                
            }
        }
    };
</script>

<style scoped>
    div.btn-secondary {margin-top: 15px;padding: 10px 30px;border-radius: 6px;float: left;margin-left: 15px;}
</style>