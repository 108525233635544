<template>
    <div class="unit--detail">
        <div class="prop-info-alert" v-if="!unit.floorplan">
            This unit has no floorplan assigned yet!
        </div>

        <div class="unit-info">
            <div class="unit-gallery">
                <Flicking :options="{ align: 'prev', bound: true }">
                    <div class="flicking-unit-image" v-for="gallery in unit.floorplan.gallery" :key="gallery.blobid">
                        <img :src="gallery.url" />
                    </div>
                </Flicking>
            </div>
        </div>

        <ul class="ud-unit-summery">
            <li>
                <ul class="uus-overview">
                    <li>
                        <p>Floor Plan</p>
                        <h3>{{FloorPlan.name}}</h3>
                    </li>

                    <li>
                        <p>Floor</p>
                        <h3>{{unit.unit_name}}</h3>
                    </li>

                    <li>
                        <p>Tenant</p>
                        <p v-if="unit.tenant.length <= 0">Unit not assigned!</p>

                        <div v-else>
                            <h3 style="margin-bottom: -5px;">{{unit.tenant.fullname}}</h3>
                            <p>{{unit.tenant.start_date}}</p>
                        </div>
                    </li>

                    <li>
                        <p>Monthly Rent</p>
                        <h3 v-if="unit.rent == null">Rent not yet added!</h3>
                        <h3 v-else>${{unit.rent}}</h3>
                    </li>

                    <li>
                        <p>Unit Status</p>
                        <h3>{{unit.status_date}}</h3>
                    </li>
                </ul>
            </li>

            <li>
                <ul class="uus-overview">
                    <li>
                        <p>Floor Type</p>
                        <h3>{{FloorPlan.type}}</h3>
                    </li>

                    <li>
                        <p>Bed Rooms</p>
                        <h3>{{FloorPlan.bedrooms}}</h3>
                    </li>

                    <li>
                        <p>Bath Rooms</p>
                        <h3>{{FloorPlan.bathrooms}}</h3>
                    </li>

                    <li>
                        <p>Square Feet</p>
                        <h3>{{FloorPlan.squarefeet}}</h3>
                    </li>

                    <li>
                        <p>Descriptions</p>
                        <p>{{FloorPlan.description}}</p>
                    </li>
                </ul>
            </li>

            <li>
                <p>Amenities</p>
                <ul class="uus-amenities">
                    <li v-for="amenity in ChoosedAmenities" :key="amenity">
                        <img :src="require(`../../../images/amenities/${amenity.icon}`)" />
                        <p>{{amenity.name}}</p>
                    </li>
                </ul>
            </li>
        </ul>
    </div>

</template>

<script>
    import Locale from './../../../javascript/Locale';

    // flicking
    import Flicking from "@egjs/vue3-flicking";
    import "@egjs/vue3-flicking/dist/flicking.css";

    export default {
        name: "UnitDetail",
        props: ['unit'],
        emits: ['update'],
        components: {

            Flicking
        },

        data () {
            return {
                Amenities: Locale.UnitAmenities (),
                ChoosedAmenities: [],
                Gallery: this.unit.gallery,

                FloorPlan : {
                    id: '',
                    name: '-',
                    squarefeet: '-',
                    bathrooms : '-',
                    bedrooms : '-',
                    type : '-',
                    description: '--descriptions--'
                },
            }
        },

        mounted () {
            // has floor plan
            if (!this.unit.floorplan) {}
            else this.FilterAmenities ();

        },

        methods: {
            FilterAmenities () {
                // floor plan details
                var plan = this.unit.floorplan;

                this.FloorPlan = {
                    id: plan.id,
                    name: plan.name,
                    squarefeet: plan.squarefeet,
                    bathrooms : plan.bathrooms,
                    bedrooms : plan.bedrooms,
                    type : plan.floortype,
                    description: plan.description
                };

                var Amenities = this.unit.floorplan.amenities.split(',');
                this.ChoosedAmenities = this.Amenities;
                this.ChoosedAmenities = this.ChoosedAmenities.filter(amenity => {
                    for (var i = 0; i < Amenities.length; i++) {
                        if (Amenities[i] == amenity.id) return amenity;
                    };
                });
            },

        }

    }
</script>