import { createApp } from 'vue';
import { createPinia } from 'pinia';
import router from './router';
import App from './App.vue';

import '@/css/theme.css';
import '@/css/owner.css';
import '@/css/tenant.css';
import '@/css/admin.css';
import '@/css/tickets.css';
import '@/css/finance.css';
import '@/css/misc.css';

// Animate
import '@/plugins/animate/animate.css';

import '@/css/responsive.css';

// pinia
const pinia = createPinia();
const app = createApp (App);

app.use (router);
app.use (pinia);

app.mount ('#propstop-app');

// create application
// createApp(App).use(router, pinia).mount('#propstop-app');