<template>
    <div class="prop-form _propscrl">        
        <div class="form-area" style="padding-bottom: 65px;">
            <form name="propform" id="propform-asgntasks">
                <div class="pf--title">
                    <h3>Create New Task</h3>
                    <p>If you didnt see any user to assign a task, please make sure of property where you are creating tasks.</p>
                </div>
                
                <div class="pf--input">
                    <p>Task Title</p>
                    <input type="text" placeholder="Task Title" v-model="Title" id="Title" data-type="len" data-max="3" />
                </div>

                <div class="pf--input">
                    <p>Task Priority</p>
                    <select v-model="Priority" id="Priority" data-type="select">
                        <option value="-1">- Select One -</option>
                        <option value="Critical">Critical</option>
                        <option value="High">High</option>
                        <option value="Normal">Normal</option>
                        <option value="Low">Low</option>
                    </select>
                </div>

                <div class="pf--multi-input" style="height: 82px;">

                    <div v-if="isAssignReady" class="half hleft">
                        <div class="pf--input" style="position:relative;">
                            <p>Assign To</p>
                            <input v-model="AssignToName" type="text" placeholder="Choose One" id="AssignToName" readonly />
                        </div>
                    </div>
                    
                    <div v-else class="half hleft">
                        <div class="pf--input" style="position:relative;">
                            <p>Assign To</p>
                            <input v-model="AssignToName" type="text" placeholder="Choose One" id="AssignToName" data-type="len" data-max="5" readonly @click="UserSingleSelect = true" />
                            <UserSingleSelectInput v-if="UserSingleSelect == true" :lists="FilteredEmployees"  @lstboxclse="EmployeeIsSelected" />
                        </div>
                    </div>
                    <div class="half hright">
                        <div class="pf--input">
                            <p>Due Date</p>
                            <Datepicker class="dp__input_custom" v-model="ExpiryDate" :enableTimePicker="false" @update:modelValue="CheckPastDate" autoApply required placeholder="Pick date.." />
                        </div>
                    </div>
                </div>

                <div class="pf--input">
                    <p>Task Detail</p>
                    <textarea placeholder="Describe issues" style="height: 150px" v-model="Content" id="Content" data-type="len" data-max="10"></textarea>
                </div>

            </form>
            
            <span v-if="formError" class="__formerror">{{ infoText }}</span>
        </div>

        <div class="form-action">
            <div class="form-area">
                <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" style="float: left;margin-top: 15px;" @click="CreateNewTask">
                    <p v-if="!Requesting">Assign Task</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    //@ JS Modules
    import Request from './../../javascript/getRequest';
    import DataCenter from './../../javascript/DataCenter';
    import FormValidation from './../../javascript/FormValidation';

    // import datepicker component
    import 'vue-datepicker-ui/lib/vuedatepickerui.css';
    import VueDatepickerUi from 'vue-datepicker-ui';

    // import component
    import UserSingleSelectInput from './../web-forms/UserSingleSelectInput.vue';  
    
    // datepicer
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    export default {
        name: 'AssignTasks',
        props: ['data'],
        emits: ['close'],
        components: {
            Datepicker: VueDatepickerUi,
            UserSingleSelectInput,
            Datepicker,
        },

        data () {
            return {
                // Form v-Modal
                Title : '',
                Property : '',
                Priority: '-1',
                AssignTo : '',
                AssignToName : '',
                ExpiryDate: '',
                Content: '',
                Requesting: false,

                // employees
                OwnerEmployees : this.data.employees,
                FilteredEmployees : false,

                // user select/employee
                UserSingleSelect : false,

                // form error
                formError : false,
                infoText : '',

                // already selected user to assign
                isAssignReady : false,
            }
        },

        async mounted () {


            this.Property = this.data.property.id;

            // now load all the owner employees
            if (this.data.assign == true) {
                this.isAssignReady = true;
                this.AssignToName = this.data.assignToData.fullname;
                this.AssignTo = this.data.assignToData.id;
            }
            
            // filter current property employee
            if (this.data.assign == false) {
                this.filterEmployee (this.Property);
            }

        },

        methods: {        
            
            // past date handler
            CheckPastDate (date) {
                const today = new Date();
                if (date < today) {
                    alert ('Please choose present date!');
                    this.ExpiryDate = '';
                    return false;
                }else {
                    return true;
                }
            },

            // now add the parking & get back to the list
            async CreateNewTask () {
                if (this.Requesting) return;

                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-asgntasks');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead) {
                    this.Requesting = true;

                    let taskExpiry;
                    if (this.ExpiryDate == null) this.ExpiryDate = new Date ();
                    taskExpiry = this.ExpiryDate.getTime();
                    taskExpiry = taskExpiry/1000;

                    let formData = new FormData();
                    formData.append('property', this.data.property.id);
                    formData.append('title', this.Title);
                    formData.append('priority', this.Priority);
                    formData.append('assignto', this.AssignTo);
                    formData.append('content', this.Content);

                    // expiriy date settings
                    formData.append('expiry', taskExpiry);

                    // submit tasks data
                    const ReturnData = await Request.postServer('property/task/add-task.php', formData);
                    this.Requesting = false;

                    // now check if the email already exists
                    if (ReturnData.error == false) {
                        // now close modal, and load current property
                        this.$emit('close', ReturnData.response);
                    
                    // if error
                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again later or contact support.";
                    }
                }
            },

            // filter all the tenants according to the current property
            filterEmployee (propertyid) {
                this.FilteredEmployees = this.OwnerEmployees.filter (employee => {
                    let assigned = employee.assignments;
                    for (let assigne in assigned) {
                        let propid = assigned[assigne].property.id;
                        if (propertyid == propid) return employee;
                    }
                });

                // add current owner to the list too
                let currOwner = {
                    active : false,
                    assignments : [],
                    avatar : '',
                    email : this.data.owner.email,
                    fullname : this.data.owner.fullname + ' (Self)',
                    id : this.data.owner.id
                };

                let unassign = {
                    active : false,
                    assignments : [],
                    avatar : '',
                    email : 'Assign tasks later',
                    fullname : 'Unassigned',
                    id : '0'
                };


                this.FilteredEmployees.unshift (currOwner);
                this.FilteredEmployees.unshift (unassign);

                // select unassign user init
                this.EmployeeIsSelected (unassign);

            },

            // once tenant selected
            EmployeeIsSelected (employee) {
                if (employee !== null) {
                    this.AssignTo = employee.id;
                    this.AssignToName = employee.fullname;
                }

                this.UserSingleSelect = false;  
            }
        }


    };
</script>

<style scoped>
span.__formerror {margin-left: 0 !important;}
.v-calendar {width: calc(100% - 2px) !important;min-width: 100%;}
</style>