<template>
    <!-- Propstop Featers -->
    <section class="prop-features">
        <div class="wrapper" style="padding-top: 0;">
            <h2>PropStop Top Features</h2>
            <p>Benefits to customer instead of product features. Unlike other property management software, PropStop offers all core functionality for free so that users can save time and money.</p>

            <ul class="features">
                <li>
                    <div class="asimgcon">
                        <img src="./../../images/website/feature-image-1.svg" alt="Management tools" />
                    </div>
                    <h3>Management Tools</h3>
                    <p>Save time by accessing all the property management tools you need in one place.</p>
                </li>

                <li>
                    <div class="asimgcon">
                        <img src="./../../images/website/feature-image-2.svg" alt="Free of cost" />
                    </div>
                    <h3>Free of cost</h3>
                    <p>Save money with PropStop because all our core features are included at no cost.</p>
                </li>

                <li>
                    <div class="asimgcon">
                        <img src="./../../images/website/feature-image-3.svg" alt="Tracking and Storing" />
                    </div>
                    <h3>Tracking & Storing</h3>
                    <p>Get more organized and reduce clutter in your life by tracking and storing all your property management documents in one place.</p>
                </li>

                <li>
                    <div class="asimgcon">
                        <img src="./../../images/website/feature-image-4.svg" alt="Automatic Deposits" />
                    </div>
                    <h3>Automatic Deposits</h3>
                    <p>Effortlessly collect rent payments through PropStop and have your funds automatically deposited into your account.</p>
                </li>

                <li>
                    <div class="asimgcon">
                        <img src="./../../images/website/feature-image-5.svg" alt="Single Logins" />
                    </div>
                    <h3>Single Logins</h3>
                    <p>You only need to remember one password for all your property management needs.</p>
                </li>

                <li>
                    <div class="asimgcon">
                        <img src="./../../images/website/feature-image-6.svg" alt="Automatic Updates" />
                    </div>
                    <h3>Automatic Updates</h3>
                    <p>Redundant and inefficient since property managers need to update the same info in each tool every time an update is needed.</p>
                </li>
            </ul>

            <router-link :to="{name: 'web-features'}" style="text-decoration: none;margin: 0px auto;width: 180px;display: block;"><span class="btn-primary">See our all features</span></router-link>
        </div>
    </section>
    <!-- Propstop Featers -->
</template>

<script>
    //@import component
    import HtmlImage from './../blocks/HtmlImage.vue';

    export default {
        name : 'PropFeatures',
        components : {
            HtmlImage,
        }
    };
</script>