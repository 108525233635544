<template>
    <div class="infowindow _propscrl" :style="'transform : ' + position">
        <div class="dash-box">                        
            <div class="dbprtimage">
                <ReplaceImage :source="property.image" />
                <div class="count">
                    <p>{{property.gallery}}</p>
                    <img src="../../images/icons/gallery-white.svg" />
                </div>
            </div>

            <div class="content">
                <div class="propery-detail">
                    <div class="title">
                        <span>{{property.type}}</span>
                    </div>

                    <h3>{{property.name}}</h3>
                    <p>{{property.address}}</p>

                    <ul class="features" v-if="property.total_features > 0">
                        <li>{{property.feature}}</li>
                        <li>{{property.total_features}}+</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    // @import component 
    import ReplaceImage from './../blocks/ReplaceImage.vue';

    export default {
        name: 'MapInfoWindow',
        props: ['property', 'position'],

        components : {
            ReplaceImage,
        },

    };
</script>