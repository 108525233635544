<template>
    <div class="gs--body">
        <h3>Unassigned unit: '{{data.unit_number}}'?</h3>
        <p>Your unit <strong>{{data.unit_number}}</strong> assgined with <strong>{{data.tenant.fullname}}</strong> will be permanantly unassigned, are you sure for this action?</p>
    </div>

    <div class="gs--action">
        <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="confirmModal" style="width: 120px; display: block;float: left;margin-top: 10px;">
            <p v-if="!Requesting">Unassign</p>
            <img v-else class="loader" src="./../../../images/gif/btn-loader.gif" />
        </span>
    </div>
</template>

<script>
    export default {
        name: 'ConfirmUnassignTenant',
        props: ['data'],
        emits: ['close'],
        data () {
            return {
                Requesting : false,
            }
        },
        methods : {
            confirmModal () {
                this.Requesting = true;
                this.$emit('close', this.data);
            }
        }
    };
</script>