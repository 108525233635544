<template>
  <div class="prop-form _propscrl">
    <div class="form-area" style="padding-bottom: 65px">
      <form name="propform" id="propform-document" style="display: block">
        <div class="pf--title">
          <h3>Additional deposits to unit.</h3>
          <p>You can update the tenant/unit additional deposits from here. Enter the new deposit amount and other info to submit.</p>
          <p style="font-size: 10pt;">* Indicate a required field</p>
        </div>
        
        <div class="pf--multi-input" style="height: 84px">
          <div class="half hleft">
            <div class="pf--input">
              <p>Unit/Tenant *</p>
              <input type="text" placeholder="Tenant/Unit" id="TenantName" v-model="TenantName" data-type="len" data-max="3" readonly :disabled="Requesting" />
            </div>
          </div>

          <div class="half hright">
            <div class="pf--input">
              <p>Effective Date *</p>
              <Datepicker class="dp__input_custom" 
                          v-model="EffectiveDate" 
                          :enableTimePicker="false" 
                          autoApply 
                          required 
                          placeholder="Pick Date" />
            </div>
          </div>
        </div>

        <div class="pf--multi-input" style="height: 84px;">
            <div class="half hleft">
                <div class="pf--input">
                    <p>Payment Type *</p>
                    <select v-model="PaymentType" id="PaymentType" data-type="select" :disabled="Requesting">
                        <option value="-1">- Select One -</option>
                        <option value="Check">Check</option>
                        <option value="Credit Card">Credit Card</option>
                        <option value="Money Order">Money Order</option>
                    </select>
                </div>
            </div>

            <div class="half hright">
                <div class="pf--input">
                    <p>Deposit Notes *</p>
                    <input v-model="DepositNote" type="text" placeholder="Deposit notes" id="DepositNote" data-type="len" data-max="5" :disabled="Requesting" />
                </div>
            </div>
        </div>

        <div class="pf--multi-input" style="height: 84px;">
            <div class="half hleft">
                <div class="pf--input">
                    <p>Additional Deposits *</p>
                    <select v-model="AdditionalDeposit" id="AdditionalDeposit" data-type="select" :disabled="Requesting">
                        <option value="-1" selected>- Select One -</option>
                        <option value="Gate Remote">Gate Remote</option>
                        <option value="Garage Remote">Garage Remote</option>
                        <option value="Parking Spot">Parking Spot</option>
                        <option value="Pet">Pet</option>
                        <option value="Misc. Others">Misc. Others</option>
                    </select>
                </div>
            </div>

            <div class="half hright">
                <div class="pf--input">
                    <p>Amount *</p>
                    <input v-model="Deposits" type="text" placeholder="450.00" id="AdditionalAmount" data-type="len" data-max="1" :disabled="Requesting" />
                </div>
            </div>
        </div>

        <div class="pf--input" v-if="AdditionalDeposit == 'Misc. Others'" style="height: 80px">
            <p>Misc. Others? *</p>
            <input v-model="MiscOther" type="text" placeholder="i.e. Added Furniture" id="MiscOther" data-type="len" data-max="2" :disabled="Requesting" />
        </div>

      </form>

      <span v-if="formError" class="__formerror">{{ infoText }}</span>
    </div>

    <div class="form-action">
      <div class="form-area">
        <span
          class="primary-btn-loader"
          :class="Requesting ? 'btn-disabled' : ''"
          @click="UpdateAdditionalDeposit"
        >
          <p v-if="!Requesting">Add Deposit</p>
          <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>

    //@ JS Modules
    import Request from "../../javascript/getRequest";
    import FormValidation from "../../javascript/FormValidation";
    import Handler from "../../javascript/Handler";

    // datepicer
    import Datepicker from "@vuepic/vue-datepicker";
    import "@vuepic/vue-datepicker/dist/main.css";

    export default {
      name: "AdditionalDeposits",
      props: ["data"],
      emits: ["close"],
      components: {
        Datepicker,
      },

      data() {
        return {
          Deposits: "",
          EffectiveDate: null,
          TenantName: this.data.tenant.fullname,
          AdditionalDeposit: '-1',
          AdditionalAmount: '',
          MiscOther: '',
          PaymentType: '-1',
          DepositNote: '',

          // form error
          formError: false,
          infoText: "",
          Requesting: false,
        };
      },

      mounted() {
        
      },

      methods: {
        CheckPastDate(date) {
          const today = new Date();
          if (date < today) {
            alert("Please choose present date!");
            this.Expiry = "";
            return false;
          } else {
            return true;
          }
        },

        async UpdateAdditionalDeposit () {
          if (this.Requesting) return;

          // form & validation
          let form = document.getElementById("propform-document");
          let canGoAhead = FormValidation.Validate(form);

          let PickerAhead = true;

          // calendar validation
          if (this.EffectiveDate == null) {
              PickerAhead = false;
              alert ('Please pick ticket expiriy date!');
          }

          if (canGoAhead && PickerAhead) {
            this.Requesting = true;

            let formData = new FormData();

            // start date timestamp
            let EffectiveDate = this.EffectiveDate.getTime();
            EffectiveDate = EffectiveDate/1000;
            formData.append('date', EffectiveDate);

            formData.append("property", this.data.property.id);
            formData.append("unit", this.data.property.unit);
            formData.append("tenant", this.data.tenant.id);
            formData.append("assignment", this.data.assignment);

            formData.append("deposit", this.Deposits);
            formData.append("additional", this.AdditionalDeposit);
            formData.append("miscother", this.MiscOther);
            formData.append("paytype", this.PaymentType);
            formData.append("notes", this.DepositNote);

            // submit tasks data
            const ReturnData = await Request.postServer("owner/units/update-deposits.php", formData);
            this.Requesting = false;

            // now check if the email already exists
            if (ReturnData.error == false) {
              var Update = {
                rent: this.Amount,
              };

              // now close modal, and load current property
              this.$emit("close", Update);

              // notiy owner
              Handler.PropNotification("Successfully updated additional deposits to the selected unit/tenent to <b>$" + this.Deposits + "</b>");

              // if error
            } else {
              this.formError = true;
              this.infoText = "Something went wrong!";
            }
          }
        },
      },
    };
</script>

<style scoped>
span.__formerror {
  margin-left: 0 !important;
}
.v-calendar {
  width: calc(100% - 2px) !important;
  min-width: 100%;
}
</style>
