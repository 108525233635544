<template>
    <div v-if="FinanceLoadingState === true" style="margin-top:55px">
        <SkeletonVue :data="2" />
    </div>

    <TransactionVue v-else-if="!FinanceLoadingState && ShowTransactionsList"
            
            :transactions="TransactionsList"
            :propfinance="PropertyFinance"
            :property="property"
            :filter="filterChanges" 
            :key="RefreshTransaction"
            :from="'null'"

            @addpayment="AddTenantPayments"
            @addrent="UpdateTheRent"
            @transelected="TransactionSelected"
            @addtrans="AddNewTransactions"
            @addunitpayment="AddUnitPayment"

    />

    <UnitTransactionsVue v-else-if="!FinanceLoadingState && !ShowTransactionsList"
        :unit="UnitSelected"
        :property="property"
        
        @edittrans="EditTransaction"
        @addtrans="AddNewTransaction"
        @back="ShowTransactionsList = true"
    />

    <!-- pop ups -->
    <RightModalVue v-if="AddTransactionModal" :form="'add-transaction'" :data="AddTransactionData" @modalclose="CloseTransactionModal" />

</template>

<script>
    
    import DataCenter from './../../javascript/DataCenter';
    import Request from './../../javascript/getRequest';
    import Handler from './../../javascript/Handler';

    // components
    import SkeletonVue from '../blocks/Skeleton.vue';
    import TransactionVue from '../owner/finance/Transaction.vue';
    import UnitTransactionsVue from '../owner/finance/UnitTransactions.vue';

    // modal
    import RightModalVue from '../modal/RightModal.vue';

    export default {
        name: 'Finance',
        props: ['property', 'properties', 'initFinance'],
        emits: ['dischargeFinance'],
        components: {
            SkeletonVue,
            TransactionVue,
            UnitTransactionsVue,

            RightModalVue

        },

        data () {
            return {

                FinanceLoadingState: true,
                ShowTransactionsList : true,
                UnitSelected: {},
                
                AddTransactionModal : false,
                AddTransactionData: {
                    type: 'choose',
                    isMainPage: true,
                    property: true,
                    whichProperty: this.property,
                    unit: false,
                    tenant: false,
                },

                PrevTransactionData: {
                    type: 'choose',
                    isMainPage: true,
                    property: true,
                    whichProperty: this.property,
                    unit: false,
                    tenant: false,
                },

                TransactionData : {
                    from: 'null',
                    properties: this.properties,
                    selected: this.property,
                    hasProperty: true,
                    hasUnit: false,
                    property: {
                        name: this.property.name,
                        id: this.property.id
                    },
                    unit: {
                        id: '',
                        unit_name: '',
                        tenant: {
                            name: '',
                            id: '',
                        }
                    }
                },

                TenantPaymentModal: false,

                TransactionsList: [],
                PropertyFinance: {},
                filterChanges: null,
                RefreshTransaction: null,

                PaymentData: {},

            }
        },

        async mounted () {
            // load all the transaction of this property
            this.LoadTransactions ();

            if (this.initFinance) {
                this.AddTransactionModal = true;
            }
        },
        
        methods: {

            EditTransaction (trans) {
                this.AddTransactionData = trans;
                this.AddTransactionModal = true;
            },

            async LoadTransactions () {
                let getdata = {
                    key : 'property',
                    value : this.property.id
                };
                
                const Transactions = await DataCenter.ProPRequest('tenants-transaction-list', false, getdata);
                this.FinanceLoadingState = false;

                if (!Transactions) {
                this.TransactionsList = [];
                this.PropertyFinance = {};
                }else {
                    this.TransactionsList = Transactions.transactions;
                    this.PropertyFinance = Transactions.property;
                }

                // update the list re-render
                this.filterChanges = "all";
                this.RefreshTransaction = "all";
            },

            AddUnitPayment (property) {
                console.log (property);
                this.PaymentData = {
                    hasTenant: false,
                    property : property.id,
                };
                this.TenantPaymentModal = true;
            },

            AddTenantPayments (trans) {
                this.MakeInactive ();
                this.PaymentData = {
                    property : this.property.id,
                    unit   : trans.tenant.unit,
                    tenant : trans.tenant.id,
                    name : trans.tenant.fullname,
                    unit_name : trans.tenant.unit_name,
                };

                this.TenantPaymentModal = true;
            },

            AddNewTransaction (data) {
                this.AddTransactionData = {
                    ...data,
                    type: 'choose',
                    isMainPage: true,
                };
                this.AddTransactionModal = true;
            },

            async UpdateTheRent (trans) {
                this.MakeInactive ();

                // update the monthly rent
                // i.e. add new transaction saying current month rent
                var CurrentProperty = this.property.id;
                var CurrentUnit = trans.tenant.unit;
                var CurrentTenant = trans.tenant.id;
                var UnitRent = trans.tenant.rent;

                let formData = new FormData();
                formData.append('property', CurrentProperty);
                formData.append('unit', CurrentUnit);
                formData.append('tenant', CurrentTenant);
                formData.append('rent', UnitRent);

                // upload and load data from server
                const ReturnData = await Request.postServer('owner/finance/update-rent.php', formData);

                // now check if the email already exists
                if (ReturnData.error == false) {

                    // update notification
                    Handler.PropNotification ('You have succesfully added new transaction!');
                    
                    // update the unit list
                    var transaction = ReturnData.response;              
                    this.TransactionsList.filter (trans => {

                        if (trans.tenant.unit == transaction.unit) {
                            trans.transaction.balance = transaction.balance;
                            trans.transaction.remarks = transaction.remarks;
                            trans.transaction.created = transaction.created;
                        }
                    });
                    
                }else {
                    
                }            

            },

            TransactionSelected (trans) {
                this.MakeInactive ();
                this.UnitSelected = trans;

                this.TransactionData.hasUnit = true;
                this.TransactionData.unit.id = trans.unit.unit;
                this.TransactionData.unit.unit_name = trans.unit.unit_name;
                this.TransactionData.unit.tenant.name = trans.tenant.fullname;
                this.TransactionData.unit.tenant.id = trans.tenant.id;
                
                this.ShowTransactionsList = false;
            },

            MakeInactive () {
                this.TransactionsList.filter(trans => {
                    trans.active = false;
                });
            },

            // with selected property
            AddNewTransactions () {
                
                this.AddTransactionModal = true;
            },

            // close add transaction right modal
            CloseTransactionModal (trans) {
                this.AddTransactionData = this.PrevTransactionData;

                if (trans) {
                    this.FinanceLoadingState = true;
                    this.ShowTransactionsList = true;
                    this.LoadTransactions ();
                }

                this.AddTransactionModal = false;
                this.$emit('dischargeFinance');
            },

            CloseTenantPaymentModal (trans) {
                if (trans) {
                    // update the unit list
                    var transaction = trans;              
                    this.TransactionsList.filter (trans => {
                        if (trans.tenant.unit == transaction.unit) {
                            trans.transaction.ytd = transaction.ytd;
                            trans.transaction.balance = transaction.balance;
                            trans.transaction.remarks = transaction.remarks;
                            trans.transaction.created = transaction.created;
                        }
                    });

                    // update notification
                    Handler.PropNotification ('You have succesfully added new transaction!');

                    // filter to show the changes
                    this.filterChanges = 'all';
                    this.RefreshTransaction = "added-new" + Math.random();
                }

                this.TenantPaymentModal = false;

            },

        }
    };

</script>

<style scoped>

div.fin--filter div.fil--area input {
    border: none !important;
    width: calc(100% - 61px) !important;
}

</style>