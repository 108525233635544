<template>
    <div class="app--notification animatedss fadeInRight">
        <div class="title">
            <h3>Notifications</h3>
            <span @click="MarkasAllRead">Mark all as read</span>
        </div>

        <!-- If no data on result -->
        <div v-if="EmptyActivity == true" class="empty-result animated fadeInUp">
            <img src="../../images/icons/empty-result.svg" />
            <p>You dont have much information on this request. <br /> You will see your recent activity.</p>
        </div>

        <div v-if="LoadingState == true" style="margin-top: 45px">
            <Skeletion :type="'user-list'" :data="7" />
        </div>

        <ul v-else class="appnotifcs _propscrl animated fadeInUp">
            <li :class="activity.unread == 'true' ? 'active':''" v-for="activity in AllActivities" :key="activity">
                <div class="image">
                    <img :src="require('../../images/notifications/' + activity.icon)" />
                </div>
                <div class="content">
                    <h3>{{ activity.content }}</h3>
                    <div class="timing">
                        <img src="../../images/icons/time.svg" />
                        <p>{{ activity.created }}</p>
                    </div>
                </div>
                <span></span>
            </li>
        </ul>
    </div>

    <div class="overlay" @click="closenotification"></div>
    
</template>

<script>

    //@import JavaScript
    import DataCenter from './../../javascript/DataCenter';

    // @ import components
    import Skeletion from './../blocks/Skeleton.vue';
    import HtmlImage from './../blocks/HtmlImage.vue';

    export default {
        name: 'Notifications',
        props: ['accounttype'],
        emits: ['notificlose'],
        components: {
            Skeletion,
            HtmlImage,
        },

        data () {
            return {
                LoadingState : true,
                EmptyActivity : false,
                AllActivities : [],

                accountActivities: '',
                ReadActivities: '',

            }
        },

        async mounted () {

            if (this.accounttype == 'owner') {
                this.accountActivities = 'owner-activity';
                this.ReadActivities = 'owner-notify-read';
            }else if  (this.accounttype == 'tenant') {
                this.accountActivities = 'tenant-activity';
                this.ReadActivities = 'tenant-notify-read';
            }else if  (this.accounttype == 'employee') {
                this.accountActivities = 'employee-activity';
                this.ReadActivities = 'employee-notify-read';
            }

            const Activities = await DataCenter.ProPRequest(this.accountActivities, false, false);


            this.LoadingState = false;
            if (!Activities) {
                this.EmptyActivity = true;
            }else {
                this.AllActivities = Activities; 
                //this.updateDOMNoti ();
            }
        },

        methods : {
            closenotification () {
                this.$emit('notificlose');
            },

            updateDOMNoti () {
                this.AllActivities.map((activity) => {
                    var parser = new DOMParser();
                    var doc = parser.parseFromString(activity.content, 'text/html');
                    var id = '_n' + activity.id;
                    var activityID = document.getElementById(id);
                    activityID.innerHTML = doc.body.innerHTML;
                }).join('');
            },

            // mark as all read notificaiton
            async MarkasAllRead () {
                if (this.AllActivities) {
                    const ReturnData = await DataCenter.ProPRequest(this.ReadActivities, false, false);
                    if (!ReturnData) {
                        this.AllActivities = this.AllActivities.filter (activity => {
                            activity.unread = 'false'; return activity;
                        })
                    }
                }

            }
        }

        
    };
</script>