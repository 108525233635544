<template>
    <div class="prop-form _propscrl">        
        <div class="form-area trenhistry _propscrl" style="padding-bottom: 65px;height: 450px;overflow-y: scroll;overflow-x: hidden;">
                <div class="pf--title">
                    <h3>Tenant Payment History</h3>
                    <p>View the payment history of current unit <br><b>{{ data.unit.name }} Floor</b> </p>
                </div>

                <div v-if="Requesting" style="display: flex;margin-top: 65px;">
                    <SkeletonVue :type="'user-list'" :data="1" />
                </div>

                <!-- If no data on result -->
                <div v-if="!Requesting && FinanceHistory.length == 0" class="emptyskeleton">
                    <img src="../../images/icons/empty-result.svg" class="psanim-1 fadeInUp">
                    <p class="psanim-2 fadeInUp">This unit doesn't have payment history. <br />You will see the unit payment history here.</p>
                </div>

                <div v-if="!Requesting && FinanceHistory.length > 0" class="prop--table" style="display: flex;flex-direction: column;align-items: center;justify-content: flex-start;border: 1px solid #dbdbdb;margin: 10px 0;border-radius: 7px; padding:10px;">
                    <div class="prop-row row-header" style="padding: 10px 0 !important;">
                        <div class="column column20"><p>Date</p></div>
                        <div class="column column35"><p>Remarks</p></div>
                        <div class="column column15"><p>Billing</p></div>
                        <div class="column column15"><p>Payment</p></div>
                        <div class="column column15"><p>Balance</p></div>
                    </div>

                    <div class="prop-row odd" v-for="(trans, index) in FinanceHistory" :key="trans"  :class="index % 2 ? 'odd' : 'even'" >
                        <div class="column column20">
                            <div class="fin-transaction">
                                <p>{{trans.created}}</p>
                            </div>
                        </div>

                        <div class="column column35">
                            <div class="fin-transaction">
                                <p>{{trans.remarks}}</p>
                            </div>
                        </div>

                        <div class="column column15">
                            <div class="fin-paymethod">
                                <p v-if="trans.billing != '0.0'">{{trans.billing.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    })}}</p>
                            </div>
                        </div>

                        <div class="column column15">
                            <div class="fin-paymethod">
                                <p v-if="trans.payment != '0.0'">{{trans.payment.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    })}}</p>
                            </div>
                        </div>

                        <div class="column column15">
                            <div class="fin-paymethod">
                                <p>{{trans.balance.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    })}}</p>
                            </div>
                        </div>

                    </div> 
                </div>            

            
        </div>
        <!-- <span class="primary-btn-loader" style="float: right !important;" @click="CloseModel">
            <p >Okay</p>
        </span> -->
    </div>
</template>

<script>
    //@ JS Modules
    import DataCenter from '../../javascript/DataCenter';

    import SkeletonVue from '../blocks/Skeleton.vue';

    // datepicer
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    export default {
        name: 'RentAmountChange',
        props: ['data'],
        emits: ['close'],
        components: {
            SkeletonVue,

            Datepicker,
        },

        data () {
            return {
                Requesting: true,
                FinanceHistory: [],
            }
        },

        async mounted () {


            var getdata = {key : 'assignment', value : this.data.assignment}           
            const RequestData = await DataCenter.ProPRequest('former-tenant-history', false, getdata);
            if (RequestData) {
                this.FinanceHistory = RequestData;
                this.Requesting = false;
            }

        },

        methods: {
            

            CloseModel () {
                this.$emit('close');
            }
            
        }


    };
</script>

<style scoped>
 .notactive {
        text-decoration: line-through !important;
    }
    div.prop--table div.prop-row:last-child {
    border: none;
}

span.__formerror {margin-left: 0 !important;}
.v-calendar {width: calc(100% - 2px) !important;min-width: 100%;}
</style>