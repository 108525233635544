<template>
    <div class="prop-form _propscrl">
        <div class="form-area" style="width: 75%;height: 100%;padding-bottom: 100px;">
            <form v-if="!UserIsOnVerification" id="propform-profile" autocomplete="off">
                <div class="pf--title" style="margin-bottom: 45px;margin-top: 26px;">
                    <h3>Complete Profile for Verification</h3>
                    <p>Please submit your profile including business information. Once you submit this information we will verify your account and you can move ahead with creating properties. </p>
                    <p style="font-size: 10pt;">* Indicate a required field</p>
                </div>

                <!-- <div class="pf--info pimobile" style="margin-bottom: 45px;width: 42%;">
                    <div class="icon">
                        <img src="./../../images/icons/info-blue.svg" />
                    </div>
                    <div class="content">
                        <p>You cannot continue adding properties or tenants until your account is verified.</p>
                    </div>
                </div> -->

                <div class="half-bkg hbkgleft" style="float: left;">
                    <h4 style="font-size: 12pt;line-height: 24pt;font-weight: 500;border-bottom: 1px solid #c1dbef;padding-bottom: 10px;margin-bottom: 15px;">Personal Profile</h4>
                    
                    <div class="pf--multi-input" style="height: 75px;">
                        <div class="half hleft">
                            <div class="pf--input">
                                <p>First Name *</p>
                                <input type="text" placeholder="Jon" v-model="FirstName" id="FirstName" data-type="len" data-max="3" />
                            </div>
                        </div>
                        <div class="half hright">
                            <div class="pf--input">
                                <p>Last Name *</p>
                                <input type="text" placeholder="Doe" v-model="LastName" id="lastName" data-type="len" data-max="3" />
                            </div>
                        </div>
                    </div>

                    <div class="pf--multi-input">
                        <div class="half hleft">
                            <div class="pf--input">
                                <p>Email Address *</p>
                                <input type="text" :value="data.email" disabled />
                            </div>
                        </div>
                        <div class="half hright">
                            <div class="pf--input">
                                <PasswordInput 
                                        @passwordchange="HandlePasswordInput" 
                                        :direction="''" />
                            </div>
                        </div>
                    </div>

                    <div class="pf--input" style="overflow:hidden;">
                        <p>Mobile Phone</p>
                        <input type="text" placeholder="+1 818 555 1212 (optional)" v-model="Mobile" />
                    </div>

                    <div class="pf--multi-input">
                        <div class="half hleft">
                            <div class="pf--input">
                                <p>Date of Birth</p>
                                <Datepicker class="dp__input_custom" v-model="userDOB" :enableTimePicker="false" 
                                    autoApply 
                                    required 
                                    fixedStart
                                    placeholder="Pick Date" />
                            </div>
                        </div>
                        <div class="half hright">
                            <div class="pf--input">
                                <p>Preferred Pronoun</p>
                                <select name="floor" v-model="Gender" data-type="select">
                                    <option value="-1" selected>Select One</option>
                                    <option value="He">He/Him</option>
                                    <option value="She">She/Her</option>
                                    <option value="They">They/Them</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="half-bkg hbkgright">
                    <div class="pf--input">
                        <p>Business Name</p>
                        <input type="text" placeholder="ABC Group LLC (optional)" v-model="BusinessName" autocomplete="off" autocorrect="off" />
                    </div>

                    <div class="pf--input">
                        <p>Address *</p>
                        <vue-google-autocomplete
                            v-model="Address"
                            ref="address"
                            id="gogleaddress"
                            classname="map-input-control"
                            placeholder="Bussness or Your Full Address"
                            data-type="len"
                            data-max="10"
                            v-on:placechanged="getAddressData"
                            @keydown="getAddressStarted">
                        </vue-google-autocomplete>
                        <p class="_geo_error" v-if="InvalidLocation">This doesn't appear to be a valid address. In order for Google to resolve your address, please start entering in the address.</p>
                    </div>

                    <!-- <div class="pf--input" style="overflow: hidden;">
                        <div class="pf--input">
                            <p>City</p>
                            <input type="text" placeholder="California" v-model="City" id="City" data-type="len" data-max="3" />
                        </div>
                    </div> -->

                    <div class="pf--multi-input" style="overflow: hidden;">
                        <div class="half hleft">
                            <div class="pf--input">
                                <p>Business Phone</p>
                                <input type="text" placeholder="+1 818 555 1212 (optional)" v-model="BusinessPhone" />
                            </div>
                        </div>
                        <div class="half hright">
                            <div class="pf--input">
                                <p>Support Email</p>
                                <input type="text" placeholder="Email (optional)" v-model="SupportEmail" />
                            </div>
                        </div>
                    </div>

                    <!-- <div class="pf--multi-input" style="overflow: hidden;">
                        <div class="half hleft">
                            <div class="pf--input">
                                <p>Zipcode</p>
                                <input type="text" placeholder="90650" v-model="ZipCode" id="ZipCode" data-type="len" data-max="2" />
                            </div>
                        </div>
                    </div> -->

                </div>

            </form>

            <div v-else>
                <div class="empty-result animated fadeInUp" style="display: flex !important;position: unset;justify-content: center !important;align-items: center !important;text-align: center;width: 100%;height: 75vh;">
                    <img src="./../../images/icons/on-verification.svg">
                    <p style="line-height: 16pt !important;font-size: 11pt !important;">Your account is being verified. <br>You will receive an email from our service with additional details.</p>
                </div>
            </div>
        </div>
        
        <div class="form-action">
            <div class="form-area">
                <div v-if="!UserIsOnVerification" class="btn-primary" ref="requestVerification" @click="HandleSubmitForm">Complete Profile</div>
                <span v-if="formError" class="__formerror">{{ infoText }}</span>
            </div>
        </div>
    </div>
</template>

<script>

    //@ JS Modules
    import Request from './../../javascript/getRequest';
    import Handler from './../../javascript/Handler';
    import FormValidation from './../../javascript/FormValidation';

    // google address
    import VueGoogleAutocomplete from 'vue-google-autocomplete';

    import PasswordInput from './../web-forms/PasswordInput.vue';

    // datepicer
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    export default {
        name: 'CompleteOwnerProfile',
        props: ['data'],
        emits: [],
        components: {
            VueGoogleAutocomplete,
            PasswordInput,
            Datepicker,
        },

        data () {
            return {
                FirstName : this.data.fname,
                LastName : this.data.lname,
                Password: '',
                Mobile : this.data.bmobile,
                userDOB : '',
                Gender : '-1',

                BusinessName : this.data.business,
                Address : this.data.address,
                Country : this.data.bcountry,
                City: this.data.bcity,
                BusinessPhone: this.data.bmobile,
                SupportEmail: '',
                ZipCode: this.data.bzipcode,

                // google address
                InvalidLocation : false,
                GoogleLocationSelected: false,
                gaddress : '',
                GoogleAddressValue : '',
                goelocation : {
                    location : {latitude : 0, longitude : 0},
                    country : '',
                    city : '',
                    zipcode : '',
                    state : '',
                    fulladdress : ''
                },

                UserIsOnVerification : false,

                // form error handler
                formError : false,
                infoText : '',
            }
        },
        
        mounted () {
            if (this.data.on_verification == "true") {
                this.UserIsOnVerification = true;
            }

            // console.log (this.data);
        },

        methods: {

            HandlePasswordInput (password, strength) {
                this.Password = password;
                this.passwordStrength = strength;
            },

            getAddressStarted () {
                this.GoogleLocationSelected = false;
            },
            
            getAddressData: function (addressData, placeResultData, id) {
                let thisFieldID = document.getElementById('gogleaddress');

                // check if we get the postal code or not for real data
                if (addressData.postal_code == undefined || addressData.postal_code == null) {
                    this.InvalidLocation = true;
                    this.gaddress = '';
                    thisFieldID.value = '';
                    this.getAddressStarted ();

                }else {

                    this.InvalidLocation = false;
                    this.GoogleLocationSelected = true;
                    this.address = addressData;

                    // location
                    this.goelocation.location.latitude = addressData.latitude;
                    this.goelocation.location.longitude = addressData.longitude;

                    // other address
                    this.goelocation.country = addressData.country;
                    this.goelocation.city = addressData.locality;
                    this.goelocation.state = addressData.administrative_area_level_1;
                    this.goelocation.zipcode = addressData.postal_code;
                    this.goelocation.fulladdress = thisFieldID.value;

                    this.gaddress = thisFieldID.value;

                    // now update the address automatically
                    this.Country = addressData.country;
                    this.City = addressData.locality;
                    this.ZipCode = addressData.postal_code;
                }

            },

            // handle submit the form
            async HandleSubmitForm () {
                this.formError = false;

                if (!this.GoogleLocationSelected) {
                    this.InvalidLocation = true;
                    return;
                }

                // form & validation
                let form = document.getElementById('propform-profile');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead == true && (this.passwordStrength == "green" || this.passwordStrength == "orange")) {
                    
                    let formData = new FormData();
                    formData.append('fname', this.FirstName);
                    formData.append('lname', this.LastName);
                    formData.append('email', this.data.email);
                    formData.append('password', this.Password);
                    formData.append('mobile', this.Mobile);
                    formData.append('dob', this.userDOB);
                    formData.append('gender', this.Gender);

                    // business details goes here
                    formData.append('buz-name', this.BusinessName);
                    formData.append('buz-address', this.gaddress);
                    formData.append('buz-country', this.Country);
                    formData.append('buz-city', this.City);
                    formData.append('buz-zipcode', this.ZipCode);
                    formData.append('buz-phone', this.BusinessPhone);
                    formData.append('buz-email', this.SupportEmail);

                    this.$refs.requestVerification.innerHTML = 'Working...';
                    
                    // send and receive data
                    const ReturnData = await Request.postServer('auth/update-owner.php', formData);
                    if (!ReturnData.error) {
                        this.UserIsOnVerification = true;
                        
                        // reload the app
                        // update notification
                        Handler.PropNotification ('Your profile information has been updated, please reload app for better performance.');
                        location.reload();
                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again later!"
                    }
                    

                }
            },
        }
    };
</script>

<style scoped>
span.__formerror {
    margin-left: 10px !important;
    margin-top: 17px !important;
}

</style> 