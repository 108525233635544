<template>
    <div class="prop-form _propscrl">        
        <div class="form-area" style="padding-bottom: 65px;">
            <form name="propform" id="propform-ticket">
                <div class="pf--title">
                    <h3>Create New Ticket</h3>
                    <p>You can create ticket and assign your employee, once you create new ticket you can upate or reply, add tags, private notes etc.</p>
                </div>

                <div class="pf--input">
                    <p>Ticket Subject</p>
                    <input type="text" placeholder="Subject of your new ticket" v-model="Subject" id="Subject" data-type="len" data-max="5" />
                </div>

                <div class="pf--multi-input" style="height:85px;">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Ticket Priority</p>
                            <select v-model="Priority" id="Priority" data-type="select">
                                <option value="-1">- Select One -</option>
                                <option value="Critical">Critical</option>
                                <option value="High">High</option>
                                <option value="Normal">Normal</option>
                                <option value="Low">Low</option>
                            </select>
                        </div>
                    </div>

                    <div class="half hright">
                        <div class="pf--input"></div>
                    </div>
                    
                </div>

                <div class="pf--input">
                    <p>Ticket Details</p>
                    <textarea placeholder="Describe issues" style="height: 150px" v-model="Content" id="Content" data-type="len" data-max="10"></textarea>
                </div>

                <div class="pf--input">
                    <div class="upload-button" @click="filesclick">
                        <img src="./../../images/icons/upload.svg" />
                        <p v-if="hasFileAttached" style="color: #449adf;">File Ready To Upload!</p>
                        <p v-else>Upload document</p>
                    </div>
                    <p v-if="AttachmentError" style="color: red;font-size: 10pt;line-height: 13pt;">The uploaded file is not supported or size is greater than 2MB!</p>
                </div>

            </form>
        </div>

        <div class="form-action">
            <div class="form-area">
                <div class="btn-primary" ref="createticket" @click="AddNewTicket">Create Ticket</div>
                <span v-if="formError" class="__formerror">{{ infoText }}</span>
                <input type="file" ref="files" @change="handleFilesUploads" style="display:none;" />

            </div>
        </div>

    </div>
</template>

<script>
    //@ JS Modules
    import Request from '../../javascript/getRequest';
    import DataCenter from '../../javascript/DataCenter';
    import Methods from '../../javascript/Handler';


    import FormValidation from '../../javascript/FormValidation';

    // datepicer
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    // import component
    import PropertyList from '../blocks/PropertyList.vue';
    import UserMultiSelectInput from '../web-forms/UserMultiSelectInput.vue';
    import UserSingleSelectInput from '../web-forms/UserSingleSelectInput.vue';


    export default {
        name: 'AddNewTicket',
        props: ['data'],
        emits: ['close'],
        components: {
            Datepicker,
            PropertyList,
            UserMultiSelectInput,
            UserSingleSelectInput,
        },

        data () {
            return {
                // Form Modal
                Subject : '',
                PropertyName : '',
                Unit: '',
                Priority : '-1',
                Content: '',

                // form error
                formError : false,
                infoText : '',

                UploadAttachment : false,
                AttachmentSchema : ['jpeg', 'jpg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
                AttachmentFile: [],
                AttachmentError: false,
                hasFileAttached: false,

                // if tenant or employee is already assigned or set
                isRequestedByReady : false,
                isAssignToReady : false,
            }
        },

        async mounted () {
            
            // property id
            this.PropertyName = this.data.property.property;
            this.Unit = this.data.property.unit;

        },

        methods : {

            filesclick () {
                this.$refs.files.click();
                this.formError = false;
                this.AttachmentError = false;
            },

            handleFilesUploads () {
                if (this.Requesting) return;

                this.AttachmentFile = this.$refs.files.files[0];
                var AttachedFileInfo = Methods.AttachmentUploadHandler (this.AttachmentFile, this.AttachmentSchema, 2);
                
                // file is okay to upload
                if (AttachedFileInfo.matched && !AttachedFileInfo.largesize) {
                    this.hasFileAttached = true;
                    this.AttachmentError = false;
                }else {
                    this.AttachmentFile = [];
                    this.AttachmentError = true;
                    this.hasFileAttached = false;
                }

            },

            // now finally create new ticket and assign
            // with validation
            async AddNewTicket () {
                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-ticket');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead) {
                    let ticketExpiry;
                    if (this.Expiry == null) this.Expiry = new Date ();
                    ticketExpiry = this.Expiry.getTime();
                    ticketExpiry = ticketExpiry/1000;

                    let formData = new FormData();
                    formData.append('subject', this.Subject);
                    formData.append('priority', this.Priority);                    
                    formData.append('property', this.PropertyName.property);
                    formData.append('unit', this.Unit.id);
                    formData.append('content', this.Content);

                    // if attachment is uploaded
                    if (this.hasFileAttached) {
                        formData.append('document', this.AttachmentFile);                        
                    }

                    this.$refs.createticket.innerHTML = 'Working...';

                    // upload and load data from server
                    const ReturnData = await Request.postServer('tenant/tickets/create.php', formData);

                    // now check if the email already exists
                    if (ReturnData.error == false) {
                        // now close modal, and load current property
                        this.$emit('close', ReturnData.response);
                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again.";
                        this.$refs.createticket.innerHTML = 'Create Ticket';
                    }

                }
            }

        }


    };
</script>