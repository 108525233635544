<template>
      <WebNav />
  
      <!-- Internal page breadcrumbs -->
      <section class="internalcrumbs">
          <div class="wrapper" style="overflow: initial; padding: 25px 0;">
              <ul>
                  <li><a href="/"><img src="../../images/icons/home.svg" alt="Home"></a></li>
                  <li><img src="../../images/icons/chevron-right.svg" alt="Right"></li>
                  <li style="width:auto;"><p>Privacy Policies</p></li>
              </ul>
          </div>
      </section>
      <!-- Internal page breadcrumbs -->
  
      <!-- Privacy Policy Content -->
      <section class="_policies_page">
          <div class="wrapper" style="padding-top: 0;">
  
              <h3>PropStop, Inc. Cookie Policy</h3>
  
              
  
          </div>
      </section>
  
      <Footer />
  </template>
  
  <script>
  
      // import Website Navigation
      import WebNav from './../../components/website/WebNav.vue';
      import Footer from './../../components/website/Footer.vue';
      
  
      export default {
          name : 'HomePage',
          components : {
              WebNav,
              Footer
          },
  
          mounted () {
              // scroll to top
              window.scrollTo(0, 0);
  
              // remove styling used for apps
              document.getElementById('propstop-app').style.height = 'auto';
              document.getElementById('propstop-app').style.overflow = 'auto';
  
              //  change the title
              document.title = "PropStop - Polices";
  
          }
      }
  </script>