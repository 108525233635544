<template>
    <div class="gs--body">
        <h3>Verify '{{data.name}}'?</h3>
        <p>To help ensure a smooth verification process make sure all the information is accurate such as property address and your owner information.  If we need additional information, we'll be in touch!</p>
    </div>

    <div class="gs--action">
        <div class="btn-primary" @click="confirmModal" ref="confirm" style="width: 120px; display: block;float: left;margin-top: 10px;">Verify Now</div>
    </div>
</template>

<script>
    export default {
        name: 'ConfirmVerifyProperty',
        props: ['data'],
        emits: ['close'],
        methods : {
            confirmModal () {
                this.$refs.confirm.innerHTML = 'Working...';
                this.$emit('close', this.data);
            }
        }
    };
</script>