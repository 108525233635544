<template>
      <section class="becmember">
            <div class="wrapper" style="padding: 0;">
                  <div class="bemberper">
                        <span>Our Mission</span>
                        <h2>Our <em><p>organization</p></em> can provide you with valuable support and resources.</h2>
                        <ul>
                              <li style="align-items: center;">
                                    <img src="./../../images/website/propmission.svg" />
                              </li>
                              <li>
                                    <h2>Our mission is to simplify property management.</h2>
                                    <p>Our mission is to simplify property management through innovation, making it seamless for landlords to manage their properties and for tenants to enjoy their homes. We believe in creating technology that not only serves practical purposes but also fosters a positive and supportive community for all stakeholders in the property management ecosystem.</p>
                              </li>
                        </ul>
                  </div>
            </div>
      </section>
</template>

<script>

    export default {
        name : 'Mission',
    };

</script>