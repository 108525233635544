<template>
      <section class="internal-hero">
            <div class="wrapper herowrper psanim-1 fadeInDown" style="padding: unset; width: 100%">
                  <div class="iheroimage" :class="page">
                        <div class="ihi-content internalherader">
                              <h1 class="psanim-2 fadeInUp">{{ title }}</h1>
                              <p class="psanim-4 fadeInUp">{{ description }}</p>
                        </div>
                  </div>
            </div>
      </section>
</template>

<script>
      export default {
        name : 'InternalHeader',
        props: ['page', 'title', 'description'],
        data () {
            return {

            }
        },
        components : {
        },

        mounted () {

        }
    }
</script>