<template>
    <div class="search--content">

        <InfoWindow v-if="ShowMapInfoWindow" :property="MarkerClickedProperty" :position="InfoWindowPosition" />

        <div id="googleMap"></div>

        <div class="search--result animatedss fadeInRight">
            
            <div class="header">
                <h3>{{Properties.length}} Results Found</h3>
                <ul class="searlter">
                    <li class="filter" @click="PriceFilter">
                        <p>Price</p>
                        <img v-if="PriceRange" src="../../images/icons/sort-down-alt.svg" />
                        <img v-else="PriceRange" src="../../images/icons/sort-up-alt.svg" />
                    </li>
                    <li @click="SearchFilterModel = true">
                        <img src="../../images/icons/filter.svg" />
                    </li>
                </ul>
            </div>

            <div class="pd-loading" v-if="loadingState">
                <img src="../../images/icons/loading.gif" />
                <p>Looking for properties <br> near to you!</p>
            </div>

            <ul class="search--data _propscrl animated fadeInUp" v-if="!loadingState">
                <!-- <li v-for="property in Properties" :key="property" @mouseenter="ChangeMapLocation(property)"> -->
                <li v-for="property in Properties" :key="property">
                    <router-link :to="{path: '/tenant/property/'+property.id+'/'}">
                        <div class="property-detail">
                            <div class="pd-image">
                                <ReplaceImage :source="property.image" />
                            </div>

                            <div class="pd-content">
                                <div class="pd-title">
                                    <h4>${{property.lowprice}} - ${{property.highprice}}</h4>
                                    <span>{{property.type}}</span>
                                </div>

                                <h3>{{property.name}}</h3>
                                <p>{{property.address}}</p>

                                <ul class="pd-features" v-if="property.total_features > 0">
                                    <li>{{property.feature}}</li>
                                    <li>{{property.total_features}}+</li>
                                </ul>
                            </div>
                        </div>

                        <ul class="property--features">
                            <li>
                                <img src="../../images/icons/bed-outline.svg" />
                                <p>2 Beds</p>
                            </li>

                            <li>
                                <img src="../../images/icons/bathroom.svg" />
                                <p>1 Bath</p>
                            </li>

                            <li>
                                <img src="../../images/icons/scale.svg" />
                                <p>1.2k sq. ft.</p>
                            </li>

                            <li>
                                <img src="../../images/icons/pet.svg" />
                                <p>Pets</p>
                            </li>
                        </ul>
                    </router-link>
                </li>
            </ul>

        </div>
    </div>

    <PropModalVue v-if="SearchFilterModel == true" :from="'search-filter'" :data="FilterType" @modalclose="StartSearchFilter" />
    
</template>

<script>
    
    //@import JavaScript
    import Request from './../../javascript/getRequest';
    import Document from '../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';

    //@import component
    import HtmlImage from './../../components/blocks/HtmlImage.vue';
    import ReplaceImage from './../../components/blocks/ReplaceImage.vue';

    import InfoWindow from './../../components/tenants/MapInfoWindow.vue';
    import PropModalVue from '../../components/modal/PropModal.vue';

    export default {
        name: 'Search',
        // props: ['tenantProfile'],
        props: ['profile', 'properties', 'selected', 'hasNoProperty'],
        components: {
            HtmlImage,
            ReplaceImage,
            InfoWindow,
            PropModalVue,
        },

         data () {
            return {
                StockURL : Request.StockURL,
                loadingState : true,
                Properties : false,
                GoogleMapLocation: [],
                MapCenterLocation : null,
                
                GoogleMap : null,
                ShowMapInfoWindow : false,
                MarkerClickedProperty : false,
                InfoWindowPosition : '',
                
                GeoLatitude: 34.16547721403417,
                GeoLongitude: -118.32771947094756,
                MapCenterLocation : [this.GeoLatitude, this.GeoLongitude],

                // filter and others
                PriceRange: true, // true low to high and false means high to low
                SearchFilterModel: false,
                FilterType: {
                    minprice: '',
                    maxprice: '',
                    bedrooms: '',
                    bathrooms: '',
                    pets: true,
                    cats: true,
                    dogs: true,
                    amenities: [],
                }
            }
        },

        async mounted () {
            
            // site title and seo
            Document.DocumentHeadings ({title : 'Properties - PropStop'});

            // request for the geo-location
            this.GeoLocationRequest ();

            // tenant property details
            const RequestData = await DataCenter.ProPRequest('properties', false, false);
            if (!RequestData) {} else {
                this.Properties = RequestData;

                // location for map, i.e. locate to the current tenant location
                // later we can ask for the current location and locate there for now to their saved address

                console.log (this.Properties);


                var count = 1;
                for (const element of this.Properties) {
                    var location = {
                            lat: element.location.latitude,
                            lng: element.location.longitude,
                            property : element
                    };

                    this.GoogleMapLocation.push(location);
                    count++;
                }
            }

            // show the data
            this.loadingState = false;

            this.initializeMap ();
        },

        methods: {

            StartSearchFilter (filters) {
                this.FilterType = filters;
                
                // close the modal
                this.SearchFilterModel = false;
            },

            PriceFilter () {
                this.PriceRange = !this.PriceRange;
                if (!this.loadingState) {
                    this.Properties.sort ((a, b) => {
                        var c, d;
                        if (this.PriceRange) { c = a; d = b} else {d = a; c = b;}
                        a = c; b = d;

                        if (Number(a.lowprice) > Number(b.lowprice)) return 1;
                        if (Number(a.lowprice) == Number(b.name)) return 0;
                        if (Number(a.lowprice) < Number(b.lowprice)) return -1;
                    });
                }
            },

            GeoLocationRequest () {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(this.SetGeoPosition);
                }
            },

            SetGeoPosition (position) {
                this.GeoLatitude = position.coords.latitude;
                this.GeoLongitude = position.coords.longitude;
                this.MapCenterLocation = [this.GeoLatitude, this.GeoLongitude];
            },

            ChangeMapLocation (property) {
                this.GoogleMap.setCenter({lat: property.location.latitude, lng: property.location.longitude});
            },

            initializeMap () {
                var icon = 'https://dev.propstop.com/fetch-stock/marker.png';
			
                var locations = this.GoogleMapLocation;
                var currentLocation = JSON.parse(this.profile.location);
                
                // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
                var mapOptions = {
                    // How zoomed in you want the map to start at (always required)
                    zoom: 8,

                    // The latitude and longitude to center the map (always required)
                    center: new google.maps.LatLng({lat: this.GeoLatitude, lng: this.GeoLongitude}),

                    disableDefaultUI: false,
                };
        
                // Get the HTML DOM element that will contain your map 
                // We are using a div with id="map" seen below in the <body>
                var mapElement = document.getElementById('googleMap');
        
                // Create the Google Map using our element and options defined above
                this.GoogleMap = new google.maps.Map(mapElement, mapOptions);
                        
                // Let's also add a marker while we're at it
                var markers = locations.map(function(location, i) {
                    return new google.maps.Marker({
                        position: location,
                        icon: icon,
                        animation:google.maps.Animation.DROP,
                        property: location.property
                    });
                });

                // map infowindow after click
                markers.map(data => {
                    data.addListener('click', () => {
                        var event = event || window.event;
                        if (typeof event !== "undefined") {
                            var x = event.clientX;
                            var y = event.clientY;

                            var newposX = x - 385;
                            var newposY = y - 440;

                            var newtrans = "translate3d(" + newposX + "px," + newposY + "px, 0px)";

                            this.MarkerClickedProperty = data.property;
                            this.InfoWindowPosition = newtrans;
                            this.ShowMapInfoWindow = true;

                        };
                    });
                });

                this.GoogleMap.addListener('center_changed', () => {
                    this.ShowMapInfoWindow = false;
                });
                
                // Add a marker clusterer to manage the markers.
                var markerCluster = new MarkerClusterer(this.GoogleMap, markers,
                        {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
            }
        }
    };
</script>