<template>
    <div class="property-list animatedss fadeInUp _propscrl" :style="distyle">
        <ul class="_propscrl">            
            <li class="transAe" style="height: auto; display: flex;" v-for="property in properties" :key="property.id" @click="propertySel(property)">
                <div class="image">
                    <ReplaceImage :source="property.image" />
                </div>

                <div class="content">
                    <h3>{{ property.name }} <span v-if="property.verified" class="propverified" title="Verified Property"></span></h3>
                    <p>{{ property.address }}</p>
                    <span v-if="property.verified">{{ property.time }}</span>
                    <span v-else style="color:#ff5722">(Unverified)</span>
                </div>
            </li>
        </ul>

        <div class="confirm-ar">
            <div class="o-k" @click="closeList">Close</div>
        </div>

    </div>

    <div class="overlay" @click="closeList" style="opacity: 0;"></div>
</template>

<script>
    
    //@ import components blocks
    import ReplaceImage from './ReplaceImage.vue';


    export default {
        name : 'PropertyList',
        props: ['distyle', 'properties'],
        emits: ['prosel', 'proboxclse'],
        components : {
            ReplaceImage
        },

        mounted () {
            console.log (this.properties);
        },

        methods : {
            propertySel (property) {
                this.$emit('prosel', property);
            },

            closeList () {
                this.$emit('proboxclse', false);
            }
        }
        
    };
</script>

<style scoped>

</style>