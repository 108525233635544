<template>
  <!-- Owner Tenants Page -->
  <div class="owner-tenants">
      <div class="sidebar" :style="SidebarHamClick ? 'left: 0px !important;box-shadow: rgb(0 0 0 / 22%) 0px 7px 10px !important;' : ''">

          <ul class="tenants-menu" @click="SidebarHamClick = false">
                <li :class="list.active ? 'active' : ''" v-for="list in FilterMenuList" :key="list" @click="TenantFilterListHandler(list)">
                  <p>{{list.name}}</p>
              </li>
              <li data-menu="filter">
                  <span>Create a new filter</span>
              </li>
          </ul>

          <div class="btn-primary" @click="AddNewTenant">
              <img src="../../images/icons/addbtn-icon.svg" />
              <p>Add Tenants</p>
          </div>
      </div>

      <div class="proprty-ham-menu" style="margin-left: 15px;" v-if="!TenantSelected" @click="SidebarHamClick = true">
        <img src="./../../images/icons/line-menu.svg" />
        <p>Tenants Filter</p>
      </div>

        <!-- Skeleton -->
        <div v-if="!OwnerTenants && !TenantSelected" class="tenants-content _propscrl" style="margin-top:55px">
            <Skeleton :data="3" />
        </div>
        
        <!-- Tenant list for current property -->
        <div  v-if="!TenantSelected && OwnerTenants" class="tenants-content _propscrl">
            <div class="fc--header">      
                <div class="fc-more-filter" style="float: right; position:relative">

                    <div class="fmf-btn" style="float: right;" @click="FilterTenantByProperty">
                        <img src="./../../images/finance/unit.svg" />
                        <p>Filter Property</p>
                    </div>

                    <MultiPropertySelectionVue v-if="MultiPropertySelection" :properties="properties" :style="'top: 0px;left: 85px;'"   
                    @lstboxclse="PropertiesAreSelected"/>

                </div>
            </div>
                
            <OwnerTenantsVue 
                    :property="currentProperty"
                    :tenants="FilteredTenantList" 
                    :key="filterChanges" 
                    
                    @selected="TenantClicked" 
                    @addtenant="AddNewTenant" />
        </div>

        <!-- Tenant Detail Page -->
        <TenantDetail v-if="TenantSelected == true" 
                            :key="TenantUpdated" 
                            :tenant="TenantChoosed" 
                            :property="currentProperty" 
                            :properties="properties"
                            :fullscreen="false" 
                            
                            @backtolist="BackbtnClicked" 
                            @updatetenant="UpdateAssignedTenant" 
                    />

        <div v-if="OwnerHasNoProperty" class="empty-result animated fadeInUp">
            <img src="./../../images/icons/on-verification.svg">
            <p>It looks like you haven't added a property yet.<br>Please add a property to view all features.</p>
            <div class="btn-primary bphasno" @click="RouteToAddProperty">
                <p>Property Page</p>
            </div>
        </div>

  </div>
  <!-- Owner Tenants Page -->

  <!-- pop ups --> 
  <RightModal v-if="AddNewTenantModal" :form="'add-tenant'" :data="AddTenantData" @modalclose="CloseModalBox" />  

</template>

<script>

    //@ JS Modules
    import Document from './../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';

    import Storage from './../../javascript/Storage';

    // pinia
    import { useOwnerStore } from '../../stores/OwnerStore';

    import Skeleton from './../../components/blocks/Skeleton.vue'
    import HtmlImage from './../../components/blocks/HtmlImage.vue';
    import ReplaceImage from '../../components/blocks/ReplaceImage.vue';
    import PropertyList from '../../components/blocks/PropertyList.vue';

    import MultiPropertySelectionVue from '../../components/web-forms/MultiPropertySelection.vue';

    // current page components
    import OwnerTenantsVue from './../../components/owner/OwnerTenants.vue';
    import TenantDetail from './../../components/owner/OwnerTenantDetail.vue';

    // Modal
    import RightModal from './../../components/modal/RightModal.vue';


    export default {
        name: 'Tenants',
        props: ['profile', 'properties', 'employees', 'selected'],
        emits: ['updateproperties'],
        
        components: {
            Skeleton,
            HtmlImage,
            ReplaceImage,

            PropertyList,
            MultiPropertySelectionVue,
            OwnerTenantsVue,
            TenantDetail,

            RightModal
        },

        data () {
            return {
                // required variables
                TenantSelected : false,
                TenantUpdated : 'null',

                MultiPropertySelection: false,
                PropertiesSelected: [],
                
                // catch filter click and changes
                filterChanges : null,

                // property list for each tenants
                OwnerHasNoProperty : true,
                OwnerProperties : false,
                currentProperty : [],
                currentPropertyImageKey : 'current',
                isPropertySelection : false,

                // tenants list for current owner & for all properties
                OwnerTenants : false,
                FilteredTenantList: [],
                TenantChoosed : false,

                // page modal settings
                AddNewTenantModal : false,
                AddTenantData : false,

                // tenants filter menu with custom filter 
                FilterMenuList : [
                  {
                    key : 1,
                    name : 'All',
                    filter  : 'all',
                    count: 0,
                    active : true
                  },
                  {
                    key : 2,
                    name : 'Recently Added',
                    filter  : 'recent',
                    count: 0
                  },
                  {
                    key : 3,
                    name : 'Good Rating',
                    filter  : 'rating',
                    count: 0,
                  },
                  {
                    key : 5,
                    name : 'Agreement Ending',
                    filter  : 'agreement',
                    count: 0
                  },
                  {
                    key : 6,
                    name : 'Favorites',
                    filter  : 'favorites',
                    count: 0
                  },
                  {
                    key : 7,
                    name : 'Former Tenants',
                    filter  : 'former',
                    count: 0
                  }
                ],

                // responsive
                SidebarHamClick: false,
            };
        },

        async mounted () {

            // hide the properties selection from here
            var OwnPrty = document.getElementById('ownerprtities');
            if (OwnPrty != null || OwnPrty != undefined) {
                OwnPrty.style.visibility = 'hidden';
                OwnPrty.style.opacity = '0';
            }
            
            // site title and seo
            Document.DocumentHeadings ({title : 'Tenants - PropStop'});

            // pinia setup
            const OwnerStore = useOwnerStore ();

            // property list of current owner
            this.OwnerProperties = OwnerStore.properties;
            this.currentProperty = OwnerStore.propselected;

            // todo: previous to remove or rollout
            // this.OwnerProperties = this.properties;
            // this.currentProperty = this.selected;

            if (this.OwnerProperties.length >= 1) {
                this.OwnerHasNoProperty = false;

                // todo: previous to remove or rollout
                // now load all the owner tenants
                // await this.CurrentPropertyTenants ();
                // this.filterChanges = 'all';

                // now load all the owner tenants
                await this.LoadOwnerTenants (OwnerStore);
                this.filterChanges = 'all';
            }
            
            // global add action
            var AddAction = document.getElementById('AppMainAddActionIcon');
            AddAction.onclick = () => {
              this.AddNewTenant ();
            };
            
        },

        methods: {

            async LoadOwnerTenants (OwnerStore) {
                if (OwnerStore.tenants.length == 0) {
                    const Tenants = await DataCenter.ProPRequest('owner-tenants-list', false, false);
                    if (!Tenants || Tenants == null) {
                        this.OwnerTenants = [];

                        // pinia
                        OwnerStore.tenants = [];
                    }else {

                        this.OwnerTenants = Tenants;

                        // pinia
                        OwnerStore.tenants = Tenants;
                    }
                }else {
                    this.OwnerTenants = OwnerStore.tenants;
                }

                // filter the tenant and show them
                this.filterTheList ();

                this.TenantFilterListHandler (this.FilterMenuList[0]);

            },

            FilterTenantByProperty () {
                this.MultiPropertySelection = true;
            },

            // filter the properties by selection
            PropertiesAreSelected (propties) {
                this.MultiPropertySelection = false;
                this.PropertiesSelected = propties;

                // now filter the list
                this.filterTheList ();
            },

            // filter the tenant and show them
            filterTheList () {
                console.log (this.OwnerTenants);
                
                // show only tenant which are selected to the selected property
                this.PropertiesSelected = this.properties.filter (property => {
                    if (property.active) return property;
                });

                console.log (this.PropertiesSelected);


                // now filter accordintly
                if (this.PropertiesSelected.length == 0) {
                    this.FilteredTenantList = this.OwnerTenants;

                }else {
                    var NewFilter = [];
                    this.OwnerTenants.forEach (tenant => {
                        this.PropertiesSelected.forEach(property => {
                            let propids = tenant.properties;
                            propids.forEach (prop => {
                                if (property.id == prop.id && prop.isRented == 'true') {
                                    NewFilter.push (tenant);
                                }
                            });
                        });
                    });

                    this.FilteredTenantList = NewFilter;
                }

                // check for the duplicate
                this.FilteredTenantList = [...new Set(this.FilteredTenantList)];
                this.filterChanges = Math.random() * 100;
                
            },

            TenantFilterListHandler (list) {
                console.log (list);
                // make the clicked list active
                this.FilterMenuList = this.FilterMenuList.filter (ftr => {
                  ftr.active = false;
                  if (list.key == ftr.key) {ftr.active = true;}
                  return ftr;
                });

                // filter the tenant
                var NewFilter = [];
                if (list.filter == 'former') {
                    this.OwnerTenants.forEach (tenant => {
                        if (tenant.isRented == 'false') NewFilter.push (tenant);
                    });
                }else {
                    this.OwnerTenants.forEach (tenant => {
                        if (tenant.isRented == 'true') NewFilter.push (tenant);
                    });
                }

                // check for the duplicate
                this.FilteredTenantList = NewFilter;
                this.FilteredTenantList = [...new Set(this.FilteredTenantList)];
                this.filterChanges = Math.random() * 100;


                // remove sidebar responsive
                this.SidebarHamClick = false;
            },

            // load current property tenants
            async CurrentPropertyTenants () {
                const Tenants = await DataCenter.ProPRequest('owner-tenants-list', false, false);
                if (!Tenants || Tenants == null) {
                    this.OwnerTenants = [];
                }else {
                    this.OwnerTenants = Tenants;
                }

                // filter the tenant and show them
                this.filterTheList ();
            },

            // no properties now route to add property
            RouteToAddProperty () {
                this.$router.push({name : 'owner-properties'});
            },

            // when tenant is clicked
            TenantClicked (tenant) {
                this.TenantChoosed = tenant;
                this.TenantSelected = true;
            },

            // get back from tenant detail page 
            BackbtnClicked () {
                this.TenantSelected = false;
                if (!this.OwnerTenants) this.CurrentPropertyTenants ();
            },

            // Add New Tenant Form/Modal
            AddNewTenant () {
                // now load the right modal for new tenant additions
                this.AddTenantData = {
                    property : this.currentProperty,
                    hasProperty: false,
                };
                this.AddNewTenantModal = true;
            },

            // close the modal box
            CloseModalBox (tenant) {
                if (tenant) {
                    if (!this.OwnerTenants) {
                        this.OwnerTenants = [];
                        this.OwnerTenants[0] = tenant;
                    }else {
                        this.OwnerTenants.unshift (tenant);
                    }

                    this.FilteredTenantList = this.OwnerTenants;
                    this.TenantSelected = false;
                    this.filterChanges = Math.round(+new Date()/1000);
                }

                this.AddNewTenantModal = false;

            },

            // upadte the list & reopen the tenant profile
            UpdateAssignedTenant (units) {
                Storage.deleteCache('tenants-list');

                this.TenantSelected = false;
                this.CurrentPropertyTenants ();     
            }

            
        }

    }
</script>

<style scoped>
div.empty-result {position: absolute;background-color: rgb(255 255 255 / 92%);}
.bphasno {margin-top: 15px !important;}
.bphasno > img {width: 15px !important;}
div.fc--header {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 20px;
}

</style>