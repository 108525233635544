<template>
  <div class="owner-tickets">
      
      <div class="sidebar">

        <!-- Skeleton -->
        <div v-if="TicketLoadingState == true" style="margin-top:55px">
            <Skeletion :data="2" />
        </div>
        
          <ul v-else class="tickets-menu">              
              <li :class="list.active ? 'active' : ''" v-for="list in FilterMenuList" :key="list" @click="ticketFilterListHandler(list)">
                  <p>{{list.name}}</p>
              </li>
              <li>
                  <span>Create a new filter</span>
              </li>
          </ul>

          <div class="btn-primary" @click="AddNewTicket">
              <img src="../../images/icons/addbtn-icon.svg" />
              <p>Create Ticket</p>
          </div>
      </div>

        <!-- Ticket list page -->
      <div v-if="!ticketSelected" class="tickets-content _propscrl animated fadeInUp">
          <EmployeeTickets :tickets="TicketList" :propselected="SelectedProperty" :filter="TicketFilter" :key="filterChanges" @ticketClicked="ticketClickHandler" />
      </div>

      <!-- Ticket detail page as selected -->
      <TicketDetail v-else :ticket="selectedTicket" :profile="employeeProfile" @backtolist="listBackbtn" @backandclose="listBackTicketClosed" :key="ticketSelected" />

      <RightModal v-if="AddNewTicketModal" :form="'add-employee-ticket'" :data="AddTicketData" @modalclose="CloseModalBox" />


  </div>

</template>

<script>

    //@ JS Modules
    import Document from './../../javascript/Document';
    import Handler from './../../javascript/Handler';
    import DataCenter from './../../javascript/DataCenter';
    import Storage from './../../javascript/Storage';

    import Skeletion from './../../components/blocks/Skeleton.vue';

    import EmployeeTickets from './../../components/tickets/EmployeeTickets.vue';
    import TicketDetail from './../../components/tickets/EmployeeTicketDetail.vue';

    // Modal
    import RightModal from './../../components/modal/RightModal.vue';

    export default {
        name: 'Tickets',
        props: ['employeeProfile', 'properties', 'selected'],

        components: {
            Skeletion,

            EmployeeTickets,
            TicketDetail,

            RightModal,

        },

         data () {
            return {
                // all ticket lists
                TicketList : false,
                TicketLoadingState : true,

                // filter selected
                TicketFilter : 'all',

                // tickets clicked/selected
                ticketSelected : false,
                selectedTicket : null,

                // catch filter click and changes
                filterChanges : null,

                // ticket filter menu with custom filter 
                FilterMenuList : [
                  {
                    key : 1,
                    name : 'All',
                    filter  : 'all',
                    active : true
                  },
                  {
                    key : 2,
                    name : 'Unassigned',
                    filter  : 'unassigned'
                  },
                  {
                    key : 3,
                    name : 'Open',
                    filter  : 'open'
                  },
                  {
                    key : 4,
                    name : 'Closed',
                    filter  : 'closed'
                  },
                  {
                    key : 5,
                    name : 'Favourites',
                    filter  : 'favourites'
                  }
                ],

                // modal and settings
                AddTicketData : false,
                AddNewTicketModal : false,

                EmployeeProperties : this.properties,
                SelectedProperty : this.selected,

            }
        },

        async mounted () {

            // site title and seo
            Document.DocumentHeadings ({title : 'Tickets - PropStop'});

            // now load all the owner parkings
            const Tickets = await DataCenter.ProPRequest('employee-tickets', false, false);
            this.TicketLoadingState = false;
            if (!Tickets) {
              this.TicketList = [];
            }else {
                this.TicketList = Tickets;
            }            

            // update the list re-render
            this.filterChanges = "all";
            
        },

        methods: {
            ticketClickHandler (ticket) {
                this.selectedTicket = ticket;
                this.ticketSelected = true;
            },

            ticketFilterListHandler (list) {
                // make the clicked list active
                this.FilterMenuList = this.FilterMenuList.filter (ftr => {
                  ftr.active = false;
                  if (list.key == ftr.key) {ftr.active = true;}
                  return ftr;
                });

                // now set the new filter selection
                this.TicketFilter = list.filter;
                this.filterChanges = list.filter;
            },

            listBackbtn (ticket) {
                // now delete ticket
                if (ticket) {
                    this.TicketList = this.TicketList.filter (tkt => {
                        if (tkt.id == ticket.id) {} else {return tkt;}
                    });

                    // update to the cache too
                    Storage.deleteCache('employee-tickets');
                    Storage.storeCache('employee-tickets', this.TicketList);

                    // update notification
                    Handler.PropNotification ('Your ticket "' + ticket.subject + '" has been successfully archived.');
                }

                // back to list
                this.ticketSelected = false;
            },

            // close the ticket
            listBackTicketClosed (ticket) {
                this.TicketList = this.TicketList.filter (tkt => {
                    if (tkt.id == ticket.id) {
                      tkt.status = "Closed";
                    }return tkt;
                });

                // update to the cache too
                Storage.deleteCache('employee-tickets');
                Storage.storeCache('employee-tickets', this.TicketList);

                // back to list
                this.ticketSelected = false;

                // update notification
                Handler.PropNotification ('Your ticket "' + ticket.subject + '" has been successfully closed.');
            },

             // Add New Tenant Form/Modal
            AddNewTicket () {
                // now load the right modal for new tenant additions
                this.AddTicketData = {
                    property : this.selected.property
                };
                this.AddNewTicketModal = true;
            },

            CloseModalBox (tickets) {
              if (tickets) {
                   this.TicketList = tickets;

                    // update the ticket list
                    this.filterChanges = 'new-ticket-added' + Math.random(15);

                    // update notification
                    Handler.PropNotification ('New ticket has been added to the list. Successfully updated!');
                }

                this.AddNewTicketModal = false;
            }
        }

    };

</script>

<style scoped>
div.empty-result {position: absolute;background-color: rgb(255 255 255 / 85%);}
  .bphasno {margin-top: 15px !important;}
  .bphasno > img {width: 15px !important;}


</style>