<script>

    // scripts
    import Handler from '../../javascript/Handler';    

      // import Website Navigation
      import WebNav from './../../components/website/WebNav.vue';
      import Footer from './../../components/website/Footer.vue';
      
      import PropModal from '../../components/modal/PropModal.vue';
  
      export default {
          name : 'TermsConditions',
          components : {
              WebNav,
              Footer,

              PropModal
          },

          data () {
            return {

                ContactUsModal: false,

            }
          },
  
          mounted () {
              // scroll to top
              window.scrollTo(0, 0);
  
              // remove styling used for apps
              document.getElementById('propstop-app').style.height = 'auto';
              document.getElementById('propstop-app').style.overflow = 'auto';
  
              //  change the title
              document.title = "PropStop - Polices";
  
          },

          methods: {

            CloseContactModal (succes) {
                this.ContactUsModal = false;

                // update notification
                if (succes) Handler.PropNotification ('Thank You! Your request has been received and a PropStop team member will follow up with you soon.');

            }

          }
      }
</script>

<template>
      <WebNav />
  
      <!-- Internal page breadcrumbs -->
      <section class="internalcrumbs">
          <div class="wrapper" style="overflow: initial; padding: 25px 0;">
              <ul>
                  <li><a href="/"><img src="../../images/icons/home.svg" alt="Home"></a></li>
                  <li><img src="../../images/icons/chevron-right.svg" alt="Right"></li>
                  <li style="width:auto;"><p>Terms of Use</p></li>
              </ul>
          </div>
      </section>
      <!-- Internal page breadcrumbs -->
  
      <!-- Privacy Policy Content -->
      <section class="_policies_page">
          <div class="wrapper" style="padding-top: 0; padding-bottom: 0;">
  
              <h3>PropStop, Inc. Terms of Use</h3>

              <p>IMPORTANT: READ THESE TERMS OF USE ("TERMS") CAREFULLY. BY ACCESSING, DOWNLOADING OR USING THE PROPSTOP.COM WEBSITE AND MOBILE APPLICATIONS (COLLECTIVELY THE “WEBSITE”),
                    YOU ACKNOWLEDGE THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS.</p>

                <h2>1. CONTRACTUAL RELATIONSHIP.</h2>
                
            <p>PropStop, Inc. ("PropStop", “we” and “us”) provides an online platform, accessible via the Internet and mobile applications, for: 
                property managers to research, hire, rate and review a variety of third party service providers and real estate related professionals (collectively “Service Providers”); for tenants 
                to interact with their rental provider; and for making payments for those services and related products (collectively, with all other services and content provided through or in 
                connection with the Website (the "Services"). These Terms are a legally binding contract governing your use of the Website and Services.  If you are a Service Provider, the PropStop 
                Service Provider Agreement controls to the extent inconsistent with these Terms.</p>

            <p>
                By accessing or using the Website or Services, you are accepting these Terms, and you represent and warrant that you have the right, authority and capacity to accept these Terms, 
                are 18 years or older and are not prohibited by law from using or accessing the Wesbite or Services.
            </p>

            <p>
                    Your use of the Website and Services is voluntary.  If you do not agree with all of the provisions of these Terms, including the <router-link to="/privacy-policy/">Privacy Policy</router-link>
                    which is incorporated fully herein, do not access or use the Website or Services.
            </p>

            <p>
                These Terms require the use of binding arbitration to resolve all disputes on an individual basis, rather than through jury trials or class actions. 
                The Terms also limit the remedies available to you in the event of a dispute.
            </p>
            <p>
                These Terms expressly supersede all prior agreements we may have with you. PropStop reserves the right to immediately terminate any Services, or generally cease offering or deny
                access to the Services or any portion thereof, at any time for any reason.  In these Terms and the Privacy Policy, the words "including" and "include" mean "including, but not limited to."
            </p>
            <p>
                Supplemental terms may apply to certain Services, such as policies for a particular event or promotion, and such supplemental terms will be disclosed to you in connection with the
                applicable Services. Supplemental terms are in addition to, and shall be deemed a part of, the Terms for the purposes of the applicable Services. Supplemental terms shall prevail
                over these Terms in the event of a conflict with respect to the applicable Services.
            </p>
            <p>
                PropStop may amend the Terms from time to time.  Amendments will be effective upon posting on the Website. Your continued use of the Services after such posting constitutes your
                consent to be bound by the Terms, as amended.
            </p>

            <h2>2. SERVICES.</h2>
            <p>PropStop provides a technology platform to enable users of the Website to arrange and schedule property management services including, but not limited
                to: tenant acquisition and communication, repairs, rent collection and vendor interaction including assigning service tickets and paying for services rendered by Service Providers.
                Unless otherwise agreed by PropStop in a separate written agreement with you, Services are made available solely for your use and not for resale by you to any other party.
                YOU ACKNOWLEDGE THAT PROPSTOP, ITSELF, DOES NOT ACT AS A PROPERTY MANAGER OR SERVICE PROVIDER OF ANY INSPECTION, MAINTENANCE, REPAIR, CERTIFICATION, VALUATION, RECORDING, TRANSFER
                LEGAL OR OTHER SERVICE PROVIDED BY ANY SERVICE PROVIDER,  NOR DOES PROPSTOP FUNCTION AS AN AGENT OF ANY SERVICE PROVIDER.
            </p>

            <h2>3. CANCELLATION.</h2>
            <p>A Service Provider booking must be cancelled by through use of the Website and in accordance with the applicable cancellation policy. Payment in
                full will be due and payable unless you properly cancel in accordance with the applicable cancellation policy.
            </p>

            <h2>4. CANCELLATION OR RESCHEDULING BY SERVICE PROVIDER.</h2>
            <p>From time to time, a Service Provider may find it necessary to cancel or reschedule a booked appointment.
                In such event, the Service Provider will communicate directly with you or the other individual designated as your contact person.  You may reject such rescheduling without any charge.
            </p>

            <h2>5. LICENSE.</h2>
            <p>Subject to your compliance with these Terms, PropStop grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferrable license to:
                (i) access and use the Website on your personal device solely in connection with your use of the Services; and (ii) access and use any content, information and related materials that
                may be made available through the Services, in each case solely for your personal use. Any rights not expressly granted herein are reserved by PropStop and PropStop's licensors.
            </p>

            <h2>6. PROHIBITED CONDUCT.</h2>
            <p>You may not: (i) remove any copyright, trademark or other proprietary notices from any portion of the Services; (ii) reproduce, modify,
                prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Services
                except as expressly permitted by PropStop; (iii) decompile, reverse engineer or disassemble the Services except as may be permitted by applicable law; (iv) link to, mirror or frame any
                portion of the Services; (v) cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the Services or unduly
                burdening or hindering the operation and/or functionality of any aspect of the Services; or (vi) attempt to gain unauthorized access to or impair any aspect of the Services or its
                related systems or networks.
            </p>

            <h2>7. THIRD PARTY SERVICES AND CONTENT.</h2>
            <p>The Services may be made available or accessed in connection with third party services and content (including advertising)
                that PropStop does not control. You acknowledge that different terms of use and privacy policies may apply to your use of third party services and content. PropStop does not endorse
                third party services and content, and in no event shall PropStop be responsible or liable for any products or services of third party providers including Service Providers.
            </p>

            <h2>8. OWNERSHIP.</h2>
            <p>The Services and all rights therein are and shall remain PropStop's property or the property of PropStop's licensors. Neither these Terms nor your
                use of the Services convey or grant to you any rights: (i) in or related to the Services except for the limited license granted above; or (ii) to use or reference in any manner
                PropStop's company names, logos, product and service names, trademarks or services marks or those of PropStop's licensors.
            </p>

            <h3>YOUR USE OF THE SERVICES</h3>

            <h2>9. USER ACCOUNTS.</h2>
            <p>In order to use most aspects of the Services, you must register for and maintain an active personal user account. Account registration requires
                you to submit to PropStop certain personal information, such as your name, address, mobile phone number and age, as well as at least one valid payment method (either a credit card or
                other accepted payment). You agree to provide PropStop accurate, complete and up-to-date information regarding your account at all times. Your failure to maintain accurate, complete,
                and up-to-date account information, including having an invalid or expired payment method on file, may result in your inability to access and use the Services or PropStop's termination
                of your account. You are responsible for all activity that occurs from your account, and you agree to maintain the security and secrecy of your account username, password and other
                security information at all times. Unless otherwise permitted by PropStop in writing, you may only possess one account.
            </p>

            <h2>10. USER REQUIREMENTS AND CONDUCT.</h2>
            <p>You may not authorize third parties to use your account, and you may not allow persons under the age of 18 to use your account,
                receive or oversee services from Service Providers. You may not assign or otherwise transfer your account to any other person or entity. You agree to comply with all applicable laws
                when using the Services, and you may only use the Services for lawful purposes. You will not in your use of the Services cause nuisance, annoyance, inconvenience or property damage,
                whether to a Service Provider or any other person or entity. In certain instances you may be asked to provide proof of identity to access or use the Services, and you agree that you may
                be denied access to or use of the Services if you refuse to provide proof of identity.
            </p>

            <h2>11. TEXT MESSAGING.</h2>
            <p>By creating an account, you agree that we may send you informational text (SMS) messages as part of your use of the Services. You may opt-out of receiving text (SMS) messages from PropStop at any time by updating your profile settings. You acknowledge that opting out of receiving text (SMS) messages may impact your use of the Services.
            </p>

            <h2>12. PROMOTIONAL CODES.</h2>
            <p>PropStop may, in PropStop's sole discretion, create promotional codes that may be redeemed for benefits related to the Services or a Service Provider's services, subject to any additional terms that PropStop establishes on a per promotional code basis ("Promo Codes"). You agree that Promo Codes: (i) must be used for the intended audience and purpose, and in a lawful manner; (ii) may not be duplicated, sold or transferred in any manner, or made available to the general public (whether posted to a public form or otherwise) unless expressly permitted by PropStop; (iii) may be disabled by PropStop at any time for any reason without liability to PropStop; (iv) may only be used pursuant to the specific terms that PropStop establishes for such Promo Code; (v) are not valid for cash; and (vi) may expire prior to your use. PropStop reserves the right to withhold or deduct credits or other features or benefits obtained through the use of Promo Codes by you or any other user in the event that PropStop determines or believes that the use or redemption of the Promo Code was in error, fraudulent, illegal, or in violation of the applicable Promo Code terms or these Terms.
            </p>

            <h2>13. USER PROVIDED CONTENT INCLUDING REVIEWS.</h2>
            <p>PropStop may, in PropStop's sole discretion, permit you from time to time to submit, upload, publish or otherwise make 
                available to PropStop textual, audio, and/or visual content and information, including commentary and feedback related to the Services, initiation of support requests and submission 
                of entries for competitions and promotions ("User Content"). Any User Content provided by you remains your property. However, by providing User Content to PropStop, you grant PropStop 
                a worldwide, perpetual, irrevocable, transferrable, royalty-free license, with the right to sublicense, use, copy, modify, create derivative works of, distribute, publicly display, 
                publicly perform, and otherwise exploit in any manner such User Content in all formats and distribution channels now known or hereafter devised (including in connection with the Services 
                and PropStop's business and on third-party sites and services), without further notice to or consent from you, and without the requirement of payment to you or any other person or entity.
            </p>    

            <p>
                You represent and warrant that: (i) you either are the sole and exclusive owner of all User Content or you have all rights, licenses, consents and releases necessary to grant 
                PropStop the license to the User Content as set forth above; and (ii) neither the User Content nor your submission, uploading, publishing or otherwise making available of such User 
                Content nor PropStop's use of the User Content as permitted herein will infringe, misappropriate or violate a third party's intellectual property or proprietary rights, or rights of 
                publicity or privacy, or result in the violation of any applicable law or regulation.
            </p>

            <p>
                We may permit you to post reviews, comments or other information about your use of the Services and your experience with Service Providers ("User Content") on the Site. You are 
                solely responsible for your User Content.  You assume all risks associated with the use of your User Content, including any reliance by others on its accuracy, completeness or 
                usefulness, or any disclosure of your User Content that makes you or any third party personally identifiable.  You may not state or imply that your User Content is in any way provided, 
                sponsored or endorsed by PropStop.  Because you are responsible for your User Content, you may expose yourself to liability if, for example, your User Content violates our Terms.  
                We are not obligated to maintain or otherwise back up any User Content.  You are responsible for creating backup copies of your User Content, if required or desired.  You agree to 
                not provide User Content that is defamatory, libelous, hateful, violent, obscene, unlawful, or otherwise offensive, as determined by PropStop in its sole discretion, whether or not 
                such material may be protected by law. PropStop may, but shall not be obligated to, review, monitor, or remove User Content, at PropStop's sole discretion and at any time and for 
                any reason, without notice to you.
            </p>

            <h2>14. FEEDBACK.</h2>
            <p>We welcome your feedback and suggestions about the Website and Services. By transmitting to us any feedback or suggestions regarding the Website or Services ("Feedback"), you represent and warrant that the Feedback does not infringe or violate the intellectual or proprietary rights of any third party. In addition, you hereby assign to us all right, title and interest in and to the Feedback and agree that we have the right to use such Feedback and related information in any manner we deem appropriate.  We will treat any Feedback you provide to us as non-confidential and non-proprietary.  You agree that you will not submit to us any information or ideas that you consider to be confidential or proprietary.
            </p>

            <h2>15. NETWORK ACCESS AND DEVICES.</h2>
            <p>You are responsible for obtaining the data network access necessary to use the Services. Network data and messaging rates and fees may apply if you access or use the Services from a wireless-enabled device. You are responsible for acquiring and updating compatible hardware or devices necessary to access and use the Services. PropStop does not guarantee that the Services, or any portion thereof, will function on any particular hardware or devices. In addition, the Services may be subject to malfunctions and delays inherent in the use of the Internet and electronic communications.
            </p>

            <h2>16. PAYMENT FOR SERVICES.</h2>
            <p>Use of the Services may result in charges to you for services or goods you request or receive from a Service Provider ("Charges"). 
                Payment of the Charges in such manner shall be considered the same as payment made directly by you to the Service Provider. Charges will be inclusive of applicable taxes where 
                required by law. Charges paid by you are final and non-refundable, unless otherwise determined in accordance with these Terms.
             </p>

            <p>The credit card or other payment mechanism affiliated with your account will be charged in full for the Service Provider’s services upon the Service Provider’s acceptance of your 
                request as confirmed to you through the Website. We may have credit card details verified when an account is created, as well as in connection with payment. We use a third party 
                payment processor ("Payment Processor") to link your credit card account to the Website and Services and to process all payments. Payment processing is subject to the terms and 
                conditions and privacy policy of the Payment Processor and your credit card provider in addition to these Terms. We are not responsible for any errors by the Payment Processor or your 
                credit card provider.
            </p>

            <p>
                If your primary account payment method is determined to be expired, invalid or otherwise not able to be charged, you agree that PropStop may, as the Service Provider's limited payment collection agent, use a secondary payment method identified in your account, if available or seek to collect payment as allowed by law.
            </p>

            <p>
                As between you and PropStop, PropStop reserves the right to establish, remove and revise Charges for any or all services or goods obtained through the use of the Services at any time in PropStop's sole discretion. PropStop will use reasonable efforts to inform you of Charges that may apply, provided that you will be responsible for Charges incurred under your account regardless of your awareness of such Charges or the amounts thereof. PropStop may from time to time provide certain users with promotional offers and discounts that may result in different amounts charged for the same or similar services or goods obtained through the use of the Services, and you agree that such promotional offers and discounts, unless also made available to you, shall have no bearing on your use of the Services or the Charges applied to you. All Service Provider transactions are subject to the contractual terms of such Service Provider, so be sure to understand any Service Provider’s payment and other terms before contracting their services. This payment structure is intended to fully compensate the Service Provider for the services or goods provided. After you have received services or goods obtained through the Service Provider, you will have the opportunity to rate your experience and leave additional feedback about the Service Provider.
            </p>

            <h2>17. REFUNDS.</h2>
            <p>Refunds will only be issued if (a) the Service Provider cancels after confirmation, (b) if the Service Provider reschedules and you do not accept 
                the proposed rescheduling, or (c) if the service requested by you been properly cancelled as required by the Service Provider in writing at the time of your initial request for 
                their services. Any request for a refund that is not received must be made through use of the Website.
            </p>

            <h3>DISCLAIMERS; LIMITATION OF LIABILITY; INDEMNITY.</h3>

            <h2>18. OUR ROLE AND RESPONSIBILITIES.</h2>
            <p>PropStop acts solely as an intermediary for the purpose of booking appointments and facilitating payment for services and 
                related products offered through our Services. We provide Services solely as a convenience, and we do not review, approve, endorse or otherwise warrant any Service Provider or 
                the quality of its services and related products. Any transaction is made solely between you and the Service Provider, subject to our role in facilitating booking and collecting 
                payment. You are solely responsible for compliance with terms imposed by a Service Provider. Any disputes between you and a Service Provider regarding a transaction must be addressed 
                between you and the Service Provider.
            </p>

            <h2>19. DISCLAIMER.</h2>
            <p>THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE." PROPSTOP DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, NOT 
                EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION, PROPSTOP MAKES NO 
                REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, ACCURACY OR AVAILABILITY OF THE SERVICES OR ANY INFORMATION, SERVICE PROVIDERS 
                OR GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. PROPSTOP DOES NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY 
                OF SERVICE PROVIDERS. PUBLIC RECORDS AND PRIVATE REPORTS WE ACCESS ON YOUR BEHALF MAY BE INNACCURATE. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND 
                ANY SERVICE PROVIDER OR GOOD REQUESTED IN CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
            </p>

            <h2>20. LIMITATION OF LIABILITY.</h2>
            <p>PROPSTOP SHALL NOT BE LIABLE IN TORT OR OTHERWISE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, 
                INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY, OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES, EVEN IF PROPSTOP HAS BEEN 
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. PROPSTOP SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR 
                INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY SERVICE PROVIDER, EVEN IF PROPSTOP HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH 
                DAMAGES. PROPSTOP SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND PROPSTOP'S REASONABLE CONTROL. YOU ACKNOWLEDGE THAT SERVICE PROVIDERS YOU REQUEST 
                MAY NOT BE PROFESSIONALLY LICENSED AND HAVE NOT UNDERGONE BACKGROUND CHECKS BY PROPSTOP. IN NO EVENT SHALL PROPSTOP'S TOTAL LIABILITY TO YOU IN CONNECTION WITH THE SERVICES FOR ALL 
                DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED IN THE AGGREGATE ONE HUNDRED DOLLARS ($100.00) OR THE TOTAL AMOUNT YOU HAVE PAID TO PROPSTOP IN THE PRIOR SIX (6) MONTHS FOR USING PROPSTOP’S 
                SERVICES, WHICHEVER IS LESS. THE EXISTANCE OF MORE THAN ONE CLAIM WILLNOT INCREASE THIS LIMIT OF LIABILITY.
            </p>    

            <p>
                PROPSTOP'S SERVICES MAY BE USED BY YOU TO REQUEST AND SCHEDULE SERVICE PROVIDERS, BUT YOU AGREE THAT PROPSTOP HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY SERVICES 
                OR GOODS PROVIDED TO YOU OR OMITTED BY SERVICE PROVIDERS OTHER THAN AS EXPRESSLY SET FORTH IN THESE TERMS.
            </p>

            <p>
                THE LIMITATIONS AND DISCLAIMER IN THIS SECTION DO NOT PURPORT TO LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW.
            </p>

            <h2>21. INDEMNITY.</h2>
            <p>You agree to indemnify and hold PropStop and its officers, directors, employees, and agents harmless from any and all claims, demands, losses, liabilities, and expenses
                (including reasonable attorneys' fees and costs), arising out of or in connection with: (i) your use of the Services or services or goods obtained through your use of the Services;
                (ii) your breach or violation of any of the Terms or Privacy Policy; (iii) your User Content; (iv) your violation of the rights of any third party, including Other Users and Service
                Providers; and (v) your violation of applicable laws.
            </p>

            <h2>22. RELEASE.</h2>
            <p>You hereby release us, our officers, employees, agents and successors, from any and all claims, demands, losses, damages, rights and actions of any kind, including those
                relating to personal injuries, death and property damage, that relate or arise from (a) a Service Provider's performance, or failure to perform, services or your interaction with a
                Service Provider, and (b) your use of third party websites. In connection with the foregoing, IF YOU ARE A CALIFORNIA RESIDENT, YOU EXPRESS WAIVE CALIFORNIA CIVIL CODE SECTION 1542,
                WHICH STATES: "A GENERAL RELEASE DOES NOT EXTENT TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IS KNOWN BY HIM
                MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR."
            </p>

            <h2>23. BASIS OF BARGAIN.</h2>
            <p>We each understand that the indemnity, release, disclaimers, and exclusions and limitations in these Terms are essential elements of these Terms and that they
                represent a reasonable allocation of risk. You understand that PropStop would be unable to make the Website and Services available to you on an economic basis if it did not limit its
                exposure as provided herein.
            </p>

            <h2>24. TERM AND TERMINATION.</h2>
            <p>Subject to this Section, the Terms will remain in full force and effect while you use the Website or Services.  We may (a) suspend your rights to use the
                Website and Services (including your account) or (b) terminate the Terms and your right to use the Website and Services (including your account), at any time and for any reason at our
                sole discretion, including for any use of the Website or Services in violation of the Terms.  Upon termination of the Terms your account and right to access and use the Website and
                Services will terminate immediately and, unless otherwise agreed between us, all requests with Service Providers will be cancelled. You understand that any termination of your account
                involves deletion of your PII from our live database (but not necessarily from our archived data).  Your posted content may continue to be posted and we have no obligation to remove it.
                Even after the Terms are terminated, certain provisions of the Terms will remain in effect. The following sections shall survive termination of the Terms: 2, 6,7,8,9,10,13,14,16, and
                18 through 26.
            </p>

            <h3>25. DISPUTE RESOLUTION – READ CAREFULLY</h3>

            <h2>BINDING ARBITRATION REQUIRED.</h2>
            <p>You and we agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination,
                    enforcement, interpretation or validity thereof or the use of the Services (collectively "Disputes") will be settled exclusively by binding arbitration between you and PropStop,
                    except that each party retains the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement,
                    misappropriation or violation of a party's copyrights, trademarks, trade secrets, patents or other intellectual property rights. You acknowledge and agree that you and PropStop are
                    each waiving the right to a trial by jury or to participate as a plaintiff or class in any purported class action or representative proceeding. Further, unless both you and PropStop
                    otherwise agree in writing, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of any class or representative proceeding.
                    If this specific paragraph is held unenforceable, then the entirety of this "Dispute Resolution" section will be deemed void. Except as provided in the preceding sentence, this
                    "Dispute Resolution" section will survive any termination of these Terms.
            </p>

            <h2>ARBITRATION RULES AND GOVERNING LAW.</h2>
            <p>The binding arbitration will be administered by the American Arbitration Association ("AAA") in accordance with the Commercial Arbitration
                    Rules and the Supplementary Procedures for Consumer Related Disputes (the "AAA Rules") then in effect, except as modified by this "Dispute Resolution" section. The AAA Rules are
                    available at www.adr.org/arb_med or by calling the AAA at 1-800-778-7879. The Federal Arbitration Act will govern the interpretation and enforcement of this Section.
            </p>

            <h2>ARBITRATION PROCESS.</h2>
            <p>A party who desires to initiate arbitration must provide the other party with a written Demand for Arbitration as specified in the AAA Rules. The arbitrator will
                    be either a retired judge or an attorney licensed to practice law in the state of California and will be selected by the parties from the AAA's roster of consumer dispute arbitrators.
                    If the parties are unable to agree upon an arbitrator within seven (7) days of delivery of the Demand for Arbitration, then the AAA will appoint the arbitrator in accordance with the
                    AAA Rules.
            </p>

            <h2>ARBITRATION LOCATION AND PROCEDURE.</h2>
            <p>The arbitration will be conducted in Ventura County, California, and may be conducted by teleconference if you request. If your claim does not
                    exceed $15,000, then the arbitration will be conducted solely on the basis of documents you and PropStop submit to the arbitrator, unless you request a hearing or the arbitrator
                    determines that a hearing is necessary. Subject to the AAA Rules, the arbitrator will have the discretion to direct a reasonable exchange of information by the parties, consistent with
                    the expedited nature of the arbitration.
            </p>

            <h2>ARBITRATOR'S DECISION.</h2>
            <p>The arbitrator will make a finding of facts and render an award within the time frame specified in the AAA Rules. The arbitrator's decision will include the
                    essential findings and conclusions upon which the arbitrator based the award. Judgment on the arbitration award may be entered in the Superior Court of California located in Ventura
                    County or any court having jurisdiction thereof. The arbitrator's award damages must be consistent with the terms of the "Limitation of Liability" section above as to the types and
                    the amounts of damages for which a party may be held liable. The arbitrator may award declaratory or injunctive relief only in favor of the claimant and only to the extent necessary
                    to provide relief warranted by the claimant's individual claim. The prevailing party in arbitration may be entitled to an award of attorneys' fees and expenses, to the extent provided
                    under applicable law, at the arbitrator’s sole discretion.
            </p>

            <h2>ARBITRATION FEES.</h2>
            <p>Each party’s responsibility to pay any AAA filing, administrative and arbitrator fees will be solely as set forth in the AAA Rules. If the arbitrator finds that
                    either the substance of a claim or the relief sought in a Demand for Arbitration was frivolous or was brought for an improper purpose (as measured by the standards set forth in Federal
                    Rule of Civil Procedure 11(b), the arbitrator may order such to pay the other’s reasonable attorney’s fees and costs.
            </p>

            <h2>CHANGES TO DISPUTE RESOLUTION PROCEDURE.</h2>
            <p>Notwithstanding the provisions of the modification-related provisions above, if PropStop changes this "Dispute Resolution" section after the
                    date you first accepted these Terms (or accepted any subsequent changes to these Terms), you may reject any such change by providing PropStop written notice of such rejection by mail or
                    hand delivery to: PropStop Attn: Legal, 2470 Stearns St., Simi Valley, CA 93063, or by email from the email address associated with your Account to: Legal@PropStop.com, within 30 days of
                    the date such change became effective, as indicated in the "Last update" date above. In order to be effective, the notice must include your full name and clearly indicate your intent to
                    reject changes to this "Dispute Resolution" section. By rejecting changes, you are agreeing that you will arbitrate any Dispute between you and PropStop in accordance with the provisions
                    of this "Dispute Resolution" section as of the date you first accepted these Terms (or accepted any subsequent changes to these Terms).
            </p>

            <h3>26. OTHER PROVISIONS</h3>

            <h2>CHOICE OF LAW.</h2>
            <p>These Terms are governed by and construed exclusively in accordance with the laws of the State of California without giving effect to any conflict of
                    law principles.
            </p>

            <h2>CLAIMS OF COPYRIGHT INFRINGEMENT.</h2>
            <p>Claims of copyright infringement should be sent to PropStop's designated agent at Legal at PropStop.com.
            </p>

            <h2>NOTICE TO PARTIES.</h2>
            <p>PropStop may give notice by electronic mail to your email address identified in your account, or by written communication sent by first class mail
                    or pre-paid post to your address identified in your account. Such notice shall be deemed to have been given upon the expiration of 48 hours after mailing or posting (if sent by first
                    class mail or pre-paid post) or 12 hours after sending (if sent by email). You may give notice to PropStop, with such notice deemed given when received by PropStop, at any time by first
                    class mail or pre-paid post to PropStop, Inc., 2470 Stearns St., Simi Valley, CA 93063.
            </p>

            <h2>GENERAL.</h2>
            <p>You may not assign these Terms without PropStop's prior written approval. PropStop may assign these Terms freely without your consent. Any purported assignment
                    in violation of this section shall be void. No joint venture, partnership, employment, or agency relationship exists between you, PropStop or any Service Provider as a result of these
                    Terms or use of the Services. If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck, and the remaining provisions shall be enforced to
                    the fullest extent of the law. PropStop's failure to enforce any right or provision in these Terms shall not constitute a waiver of such right or provision unless acknowledged and agreed
                    to by PropStop in writing. The section titles are for convenience only and have no legal or contractual effect.
            </p>

            <h2>CONTACT US.</h2>
  
            <p>If you have any questions about this Terms of Use, please <span @click="ContactUsModal = true" style="color: #00aae1;cursor: pointer;">Contact Us</span></p>
  
          </div>
      </section> 
  
      <Footer />

      <PropModal v-if="ContactUsModal" :from="'contact-modal'" :data="null" @modalclose="CloseContactModal" />
  </template>
