<template>
    <div class="select-unit-list animatedss fadeInUp" :style="style">
        <!-- If no data on result -->
        <div v-if="!ListData || ListData.length <= 0" class="empty-result">
            <img src="../../images/icons/empty-result.svg" />
            <p>No data in this list, Please make sure of property unit, tenant on units etc.</p>
        </div>

        <ul class="_propscrl">            
            <li class="transAe" :class="{ active : list.active}" v-for="list in ListData" :key="list.id" @click="ListSelect(list)">
                <div class="image">
                    <ReplaceImage :source="''" />
                </div>

                <div class="content">
                    <h3>{{list.name}} <span v-if="list.verified" class="propverified" title="Verified Property"></span></h3>
                    <p style="color: #919191;font-size: 9pt;line-height: 16pt;">{{list.address}}</p>
                </div>
            </li>
        </ul>

        <div class="confirm-ar">
            <div class="clea-r" @click="ClearAll">Clear All</div>
            <div class="o-k" @click="closeList">Apply</div>
        </div>

    </div>
    <div class="overlay" @click="closeList" style="opacity: 0;"></div>
</template>

<script>

    import ReplaceImage from '../blocks/ReplaceImage.vue';

    export default {

        name: 'MultiUnitSelection',
        props: ['style', 'properties'],
        emits: ['lstboxclse'],
        components: {
            ReplaceImage,
        },

        data () {
            return {
                ListData : this.properties,
                selected : null,
            }
        },

        methods: {
            ListSelect (sl) {
                this.ListData = this.ListData.filter(list => {
                    if (list.id == sl.id) {
                        if (sl.active) {
                            list.active = false;
                        }else {
                            list.active = true;
                        }
                    }
                    return list;
                });
            },

            closeList () {
                this.$emit('lstboxclse', this.ListData);
            },

            ClearAll () {
                this.ListData = this.ListData.filter(list => {
                    list.active = false;
                    return list;
                });
            }
        }
    };
</script>