<script>

      import { useAdminStore } from '../../stores/AdminStore';

      import HtmlSelect from './../../components/blocks/HtmlSelect.vue';    

      import Announcements from './../../components/admin/communication/Announcements.vue';
      import Updates from './../../components/admin/communication/Updates.vue';
      import Wipes from './../../components/admin/communication/Wipes.vue';

      export default {
            name: 'Communication',
            props: ['userProfile', 'owners'],
            components: {
                  HtmlSelect,
                  Announcements,
                  Updates,
                  Wipes
            },
            
            data () {
                  return {
                        AdminStore: useAdminStore(),
                        VerifiedOwners: [],

                        AccountFilters : [
                              {
                                    id      : 1,
                                    name    : 'General Announcements',
                              },
                              {
                                    id      : 2,
                                    name    : 'Software Updates',
                              },
                              {
                                    id      : 3,
                                    name    : 'Server Wipe',
                              }
                        ],

                        FilterChoosed :  {
                                    id      : 1,
                                    name    : 'General Announcements',
                                    active  : true,
                              }
                        }
            },

            mounted () {

                  this.owners.forEach(owner => {
                        if (owner.verified == 'true') {
                              this.VerifiedOwners.push({
                                    id: owner.id,
                                    fullname: owner.fullname,
                                    email: owner.email,
                                    avatar: owner.avatar
                              });
                        }
                  });
            },

            methods: {
            
                  CommFilterHandler (filter) {
                        this.AccountFilters.filter(lter => {
                              lter.active = false;
                              if (filter.id == lter.id) {lter.active = true;}
                        });

                        this.FilterChoosed = filter;
                  },
            }

      }

</script>

<template>

<div class="admin-property _propscrl">
        <div class="dashpageheader">
            <div class="dash-header">
                <div class="actions">
                    <HtmlSelect :options="AccountFilters" @selected="CommFilterHandler" />
                </div>
            </div>
        </div>

        <div class="dash-list">
            <Announcements v-if="FilterChoosed.id == 1" :owners="VerifiedOwners" />
            <Updates v-else-if="FilterChoosed.id == 2" :owners="VerifiedOwners" />
            <Wipes v-else :owners="VerifiedOwners" />
        </div>
    </div>
</template>