<template>
    <div class="prop-form _propscrl" style="height: calc(100% - 90px);">        
        <div class="form-area">
            
            <form v-if="formProcess == 1" name="propform" id="propform-step1">
                <div class="pf--title">
                    <h3>Add New Property</h3>
                    <p>Please tell us a bit about your property.</p>
                    <p style="font-size: 10pt;">* Indicate a required field</p>
                </div>

                <div class="pf--info" style="margin-bottom: 45px;">
                    <div class="icon">
                        <img src="./../../images/icons/info-blue.svg" />
                    </div>
                    <div class="content">
                        <p>As soon as you add your new property, you can update units, parking, etc.</p>
                    </div>
                </div>

                <ul class="select-type">
                    <li v-for="typex in units" :key="typex.key" :class="{active : typex.active}" @click="handleTypeClick(typex)">
                        <div class="image">
                            <img :src="require(`./../../images/icons/${typex.image}`)" v-if="!typex.active" />
                            <img :src="require(`./../../images/icons/${typex.imageHover}`)" v-if="typex.active" />
                        </div>
                        <div class="content">
                            <h3>{{ typex.name }}</h3>
                            <p>{{ typex.description }}</p>                            
                        </div>
                        <div class="hover" v-if="typex.active">
                            <img src="./../../images/icons/arrow-right-blue.svg" class="animated fadeInLeft" />
                        </div>
                    </li>
                </ul>

                <div class="pf--input">
                    <p>Property Name *</p>
                    <input type="text" placeholder="i.e. Underhill Property" v-model="pname" id="PName" data-type="len" data-max="5" />
                </div>

                <div class="pf--input">
                    <p>Property Address *</p>
                    <vue-google-autocomplete
                        v-model="gaddress"
                        ref="address"
                        id="gogleaddress"
                        classname="map-input-control"
                        placeholder="123 Poker Hill Road, Underhill, VT"
                        data-type="len"
                        data-max="10"
                        v-on:placechanged="getAddressData"
                        @keydown="getAddressStarted">
                    </vue-google-autocomplete>
                    <p class="_geo_error" v-if="InvalidLocation">This doesn't appear to be a valid address. In order for Google to resolve your address, please start entering in the address.</p>
                </div>

                <div class="pf--input">
                    <p>Property Type *</p>
                    <select v-model="ptype" id="Ptype" data-type="sel" @change="PropertyTypeChoosed">
                        <option value="-1" selected>- Select One -</option>
                        <option value="Single Family House">Single Family House</option>
                        <option value="Duplex">Duplex</option>
                        <option value="Triplex">Triplex</option>
                        <option value="Quadplex">Quadplex</option>
                        <option value="Mobile Home/Manufactured">Mobile Home/Manufactured</option>
                        <option disabled>────────────────────────</option>
                        <option value="Apartment">Apartment</option>
                        <option value="Condominium">Condominium</option>
                        <option value="Mid-Rise Apartments">Mid-Rise Apartments</option>
                        <option value="High-Rise Apartments">High-Rise Apartments</option>
                        <option value="Townhouse">Townhouse</option>
                        <option disabled>────────────────────────</option>
                        <option value="Office">Office</option>
                        <option value="Retail">Retail</option>
                        <option disabled>────────────────────────</option>
                        <option value="Cabin">Cabin</option>
                        <option value="Hotel">Hotel</option>
                        <option value="Motel">Motel</option>
                        <option value="RV Pad">RV Pad</option>
                        <option value="Short-term Rental">Short-term Rental</option>
                    </select>
                </div>

                <div v-if="!SingleUnit" class="pf--multi-input">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Floors/Stories *</p>
                            <input type="text" v-model="pfloors" @keyup="HandleUnits" placeholder="Floors in your property" id="Floors" data-type="len" data-max="1" />
                        </div>
                    </div>
                    <div class="half hright">
                        <div class="pf--input">
                            <p>Units/each Floor *
                                    <span @click="floortip = true">?</span>
                                    <b v-if="floortip ==  true" @click="floortip = false">Please input average units on each floor, later you can add or remove them.</b>
                            </p>
                            <input type="text" v-model="punits" @keyup="HandleUnits" placeholder="Total units" id="Units" data-type="len" data-max="1" />
                        </div>
                    </div>
                </div>

                <div class="pf--multi-input">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Total Units</p>
                            <input type="text" v-model="totalUnits" id="Floors" readonly disabled />
                        </div>
                    </div>
                </div>


            </form>

            <form v-if="formProcess == 2" name="propform" id="propform-step2">
                <div class="pf--title">
                    <h3>Add New Property</h3>
                    <p>Please choose the brief information about your new property.</p>
                </div>

                <div class="pf--info" style="margin-bottom: 45px;">
                    <div class="icon">
                        <img src="./../../images/icons/info-blue.svg" />
                    </div>
                    <div class="content">
                        <p>Once you have added your new property, you can edit the units, parking, images, and more.</p>
                    </div>
                </div>

                <div class="pf--input">
                    <p>Property Description</p>
                    <textarea placeholder="Property Information (optional)" style="height: 150px" v-model="brief"></textarea>
                </div>

                <div class="pf--multi-input">
                    <div class="half hleft">
                        <div class="pf--input" style="overflow:unset; position:relative;">
                            <p>Services</p>
                            <input v-model="services" type="text" placeholder="Choose One" readonly @click="ServicesMultiSelect = true" />
                            <MultiSelectInput v-if="ServicesMultiSelect == true" :type="'select-list'" :lists="PropertyServices" @lstboxclse="ServicesSelected" />
                        </div>
                    </div>
                    <div class="half hright">
                        <div class="pf--input" style="overflow:unset; position:relative;">
                            <p>Features</p>
                            <input v-model="features" type="text" placeholder="Choose One" readonly  @click="FeaturesMultiSelect = true"/>
                            <MultiSelectInput v-if="FeaturesMultiSelect == true" :type="'select-list'" :lists="PropertyFeatures" @lstboxclse="FeaturesSelected" />
                        </div>
                    </div>
                </div>

                 <div class="pf--input" style="overflow:hidden;">
                    <p>Pets Allowed *</p>
                    <div class="pets-select">
                        <ul>
                            <li :class="{active : catsAllowed}" @click="catsAllowed = !catsAllowed">
                                <div class="image">
                                    <img class="select" src="../../images/icons/select-list.svg" />
                                    <img class="selected" src="../../images/icons/select-check.svg" />
                                </div>
                                <div class="content">
                                    <p>Cats</p>
                                </div>
                            </li>

                            <li :class="{active : dogsAllowed}" @click="dogsAllowed = !dogsAllowed">
                                <div class="image">
                                    <img class="select" src="../../images/icons/select-list.svg" />
                                    <img class="selected" src="../../images/icons/select-check.svg" />
                                </div>
                                <div class="content">
                                    <p>Dogs</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </form>
            
        </div>
        
        <div class="form-action">
            <div class="form-area">
                <div v-if="formProcess != 1" class="btn-secondary process-back" @click="getBackToFirstPage"><img src="./../../images/icons/arrow-left-blue.svg" /></div>
                <div class="btn-primary" v-if="formProcess == 1" @click="ValidateFirstPage">Next Step</div>
                <div class="btn-primary" v-if="formProcess == 2" ref="addproperty" @click="AddNewProperty">Add Property</div>
                <span v-if="formError" class="__formerror">{{ infoText }}</span>
            </div>
        </div>
    </div>
    
    <PropModalVue v-if="isDuplicateProperty == true" :from="'duplicate-property'" :data="DisputeData" @modalclose="CloseDuplicateProperty" />
    <PropModalVue v-if="DuplicateDisputeConfirm == true" :from="'duplicate-dispute'" :data="DisputeData" @modalclose="CloseDuplicateDispute" />
</template>

<script>

    // javascript modules
    import Locale from './../../javascript/Locale';
    import Request from './../../javascript/getRequest';
    import FormValidation from './../../javascript/FormValidation';
    import Handler from '../../javascript/Handler';

    import VueGoogleAutocomplete from 'vue-google-autocomplete';

    // vue components
    import MultiSelectInput from './../web-forms/MultiSelectInput.vue';

    import PropModalVue from './PropModal.vue';

    export default {
        name: 'AddNewProperty',
        emits: ['close'],
        components: {
            VueGoogleAutocomplete,
            MultiSelectInput,

            PropModalVue,
        },

        data () {
            return {
                unitType : false,
                SingleUnit: false,
                pname : '',
                ptype : '-1',
                pfloors : '',
                punits : '',
                totalUnits : 0,
                gaddress : '',
                GoogleAddressValue : '',

                InvalidLocation : false,
                GoogleLocationSelected: false,

                // property addres and geo
                goelocation : {
                    location : {latitude : 0, longitude : 0},
                    country : '',
                    city : '',
                    zipcode : '',
                    state : '',
                    fulladdress : ''
                },

                brief : '',
                services : '',
                features : '',
                catsAllowed : false,
                dogsAllowed : false,                

                // page settings
                formProcess : 1,

                floortip : false,

                // form error
                formError : false,
                infoText : '',

                // step one unit selections
                units : [
                    {
                        key: 1,
                        type: 'single',
                        name: 'Single Unit',
                        description: 'Small Property',
                        image: 'house-blue.svg',
                        imageHover: 'house-white.svg',
                        active: false
                    },
                    {
                        key: 2,
                        type: 'multiple',
                        name: 'Multiple Units',
                        description: 'Buildings/Apartments',
                        image: 'building-blue.svg',
                        imageHover: 'building-white.svg',
                        active: false
                    }
                ],

                // step two property features and services
                PropertyServices : Locale.PropertyServices(),
                SelectedServices : null,
                ServicesMultiSelect : false,

                PropertyFeatures : Locale.PropetyUniqueFeatures(),
                FeaturesMultiSelect : false,
                SelectedFeatures : null,

                // duplicate property control
                LookingForProperty: false,
                isDuplicateProperty : false,
                DuplicateDisputeConfirm : false,
                DisputeData: {},

            }
        },

        mounted() {
            

        },

        methods: {

            getAddressStarted () {
                this.GoogleLocationSelected = false;
            },
            
            getAddressData: async function (addressData, placeResultData, id) {
                let thisFieldID = document.getElementById('gogleaddress');

                 // check if we get the postal code or not for real data
                if (addressData.postal_code == undefined || addressData.postal_code == null) {
                    this.InvalidLocation = true;
                    this.gaddress = '';
                    thisFieldID.value = '';
                    this.getAddressStarted ();

                }else {
                    
                    this.InvalidLocation = false;
                    this.GoogleLocationSelected = true;
                    var duplicate = await this.PropertyDuplicateLookUp (thisFieldID, addressData.latitude, addressData.longitude, thisFieldID.value);

                    if (!duplicate) {

                        this.address = addressData;
                        // location
                        this.goelocation.location.latitude = addressData.latitude;
                        this.goelocation.location.longitude = addressData.longitude;

                        // other address
                        this.goelocation.country = addressData.country;
                        this.goelocation.city = addressData.locality;
                        this.goelocation.state = addressData.administrative_area_level_1;
                        this.goelocation.zipcode = addressData.postal_code;
                        this.goelocation.fulladdress = thisFieldID.value;

                        this.gaddress = thisFieldID.value;
                    }else {

                        this.gaddress = '';
                        thisFieldID.value = '';

                    }
                }
            },

            async PropertyDuplicateLookUp (input, lat, long, address) {
                this.LookingForProperty = true;
                input.classList.add('lookup');

                // now check if current location or address is already
                // in our database
                let formData = new FormData();                
                formData.append('latitude', lat);
                formData.append('longitude', long);
                formData.append('address', address);

                // load data from server
                const ReturnData = await Request.postServer('property/lookup.php', formData);
                input.classList.remove ('lookup');

                // now check if the email already exists
                if (ReturnData.error == false) {
                    var Response = ReturnData.response;
                    if (Response.duplicate == true) {
                        this.DisputeData = {
                            property: Response.property,
                            property_owner: Response.property_owner,
                            owner: Response.owner,
                            ownership: true,
                            self: Response.self
                        };

                        this.isDuplicateProperty = true;
                        this.gaddress = '';
                        input.value = '';
                        return true;
                    }
                }

                return false;
                
            },

            CloseDuplicateProperty (data) {
                if (data) {
                    // another confirmation for the dispute service
                    this.DuplicateDisputeConfirm = true;
                }

                this.isDuplicateProperty = false;
            },

            CloseDuplicateDispute (data) {
                if (data) {
                    Handler.PropNotification('Succussfully submitted your information, we will review your claim and respond via email shortly');
                }
                this.DuplicateDisputeConfirm = false;
            },

            getBackToFirstPage () {
                this.formProcess--;

                setTimeout (()=> {
                    let thisFieldID = document.getElementById('gogleaddress');
                    thisFieldID.value = this.gaddress; 
                }, 100);               
            },

            HandleUnits () {
                this.totalUnits = this.pfloors * this.punits;
            },

            PropertyTypeChoosed (e) {
                if (this.ptype == 'Single Family House') {
                    // handle for the single unit
                    this.SingleUnit = true;
                    this.totalUnits = this.pfloors = this.punits = 1;
                }else if (this.ptype == 'Duplex') {
                    this.SingleUnit = true;
                    this.totalUnits = 2;
                    this.pfloors = 1;
                }else if (this.ptype == 'Triplex') {
                    this.SingleUnit = true;
                    this.totalUnits = 3;
                    this.pfloors = 1;
                }else if (this.ptype == 'Quadplex') {
                    this.SingleUnit = true;
                    this.totalUnits = 4;
                    this.pfloors = 1;
                }else {
                    this.SingleUnit = false;
                }
            },  

            handleTypeClick (e) {       
                this.SingleUnit = false;
                this.totalUnits = 0;
                this.pfloors = this.punits = '';

                this.units = this.units.filter(type => {
                    type.active = false;
                
                    if (type.key == e.key) {
                        type.active = true;
                        this.unitType = type;
                    }

                    return type;
                });   
                
                // handle for the single unit
                if (this.unitType.type == 'single') {
                    this.SingleUnit = true;
                    this.totalUnits = this.pfloors = this.punits = 1;
                }
            },

            // once serivices are slected lets collect the data
            ServicesSelected (services) {
                this.ServicesMultiSelect = false;
                
                // update services list
                this.PropertyServices = services;

                // now update the selected data list too
                this.SelectedServices = this.PropertyServices.filter(list => {
                    if (list.active == true) {
                        return list;
                    }
                });

                // now set the form modal value
                let len = this.SelectedServices.length;
                if (len < 1) {
                    this.services = '';
                }else {
                    this.services = len + ' Selected';
                }
            },


            // once features are slected lets collect the data
            FeaturesSelected (features) {
                this.FeaturesMultiSelect = false;
                
                // update services list
                this.PropertyFeatures = features;

                // now update the selected data list too
                this.SelectedFeatures = this.PropertyFeatures.filter(list => {
                    if (list.active == true) {
                        return list;
                    }
                });

                // now set the form modal value
                // now set the form modal value
                let len = this.SelectedFeatures.length;
                if (len < 1) {
                    this.features = '';
                }else {
                    this.features = len + ' Selected';
                }
            },

            // validate the first form page
            ValidateFirstPage () {
                
                if (!this.GoogleLocationSelected) {
                    this.InvalidLocation = true;
                    return;
                }

                // check for the unit type
                if (!this.unitType) {
                    this.formError = true;
                    this.infoText = "Please choose unit type single or multiple?";
                    return;
                }else {this.formError = false;}

                // form & validation
                let form = document.getElementById('propform-step1');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead) {
                    this.formError = false;
                    // google address data
                    this.GoogleAddressValue = document.getElementById('gogleaddress').value;
                    this.formProcess++;
                }

            },

            // now validate both of the forms 
            // if true add new property
            async AddNewProperty () {
                
                // form & validation
                let form = document.getElementById('propform-step2');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead) {
                    this.formError = false;
                    
                    let formData = new FormData();
                    formData.append('unittype', this.unitType.name);
                    formData.append('name', this.pname);
                    formData.append('type', this.ptype);
                    
                    // geolocation
                    formData.append('latitude', this.goelocation.location.latitude);
                    formData.append('longitude', this.goelocation.location.longitude);
                    formData.append('country', this.goelocation.country);
                    formData.append('city', this.goelocation.city);
                    formData.append('zipcode', this.goelocation.zipcode);
                    formData.append('state', this.goelocation.state);
                    formData.append('fulladdress', this.goelocation.fulladdress);

                    // other data
                    formData.append('type', this.ptype);
                    formData.append('floors', this.pfloors);
                    formData.append('units', this.punits);
                    formData.append('brief', this.brief);

                    formData.append('services', JSON.stringify(this.SelectedServices));
                    formData.append('features', JSON.stringify(this.SelectedFeatures));

                    formData.append('cats', this.catsAllowed);
                    formData.append('dogs', this.dogsAllowed);
                    
                    this.$refs.addproperty.innerHTML = 'Working...';

                    // load data from server
                    const ReturnData = await Request.postServer('property/add.php', formData);

                    // now check if the email already exists
                    if (ReturnData.error == false) {
                        // now can re-render the employee list
                        let Properties = ReturnData.response;

                        // now close modal, and load current property
                        this.$emit('close', Properties);

                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again later.";
                        this.$refs.addproperty.innerHTML = 'Add Property';
                    }

                }
            }


        }

    };
</script>

<style scoped>
.lookup {
    background-image: url('./../../images/gif/lookup.gif');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 99% 10px;
}
span.__formerror {    margin-left: 10px;
    margin-top: 17px;}
</style>