<template>
    <!-- <div class="balance-btns">
        <div class="btn-secondary" @click="AddTransactionModal = true"><p>Add Transaction</p></div>
        <div class="btn-secondary" @click="TenantPaymentModal = true"><p>Add Payment</p></div>
    </div> -->

    <div class="prop--table" v-if="ShowLedger == false">
        <div class="prop-row row-header" style="padding: 10px 0 !important;">
            <div class="column column45"><p>Unit/Property</p></div>
            <div class="column column30"><p>Last Transaction</p></div>
            <div class="column column25"><p>Current Balance</p></div>
        </div>

        <div v-if="FinanceLoadingState == true" style="padding-top: 95px;">
            <SkeletonVue :data="2" />
        </div>

        <div class="prop-row odd" v-else 
                v-for="(trans, index) in TransactionsList" :key="trans"  
                :class="index % 2 ? 'odd' : 'even'" style="cursor: pointer;"
                @click="Showmeledger(trans)">

            <div class="column column45">
                <div class="fin-transaction">
                    <h3>{{ trans.unit }}</h3>
                    <p>{{trans.property}}</p>
                </div>
            </div>

            <div class="column column30">
                <div class="fin-transaction">
                    <p>{{trans.date}}</p>
                </div>
            </div>

            <div class="column column25">
                <div class="fin-paymethod">
                    {{ trans.balance.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        }) }}
                </div>
            </div>

        </div> 

        <div class="prop-row odd">
            <div class="column column45">
                <div class="fin-transaction">
                    <h3></h3>
                </div>
            </div>

            <div class="column column30">
                <div class="fin-transaction">
                    <h3>Total Balance</h3>
                </div>
            </div>

            <div class="column column25">
                <div class="fin-paymethod">
                    <h3>{{ TotalBalance.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        }) }}</h3>
                </div>
            </div>

        </div> 

    </div>

    <TenantLedger v-if="ShowLedger == true"
                :unit="SLUnit"
                :assignment="SLAssignment"
                :property="SLProperty"

                @back="HidemeLedger" />

    <!-- finance (add transaction) and (add payment) -->
    <!-- <RightModal v-if="AddTransactionModal" :form="'add-transaction'" :data="TransactionData" @modalclose="CloseTransactionModal" />
    <RightModal v-if="TenantPaymentModal" :form="'add-payment'" :data="PaymentData" @modalclose="CloseTenantPaymentModal" /> -->

</template>

<script>
    
    import DataCenter from '../../../javascript/DataCenter';
    import SkeletonVue from '../../blocks/Skeleton.vue';
    import Handler from '../../../javascript/Handler';

    import TenantLedger from './../finance/TenantLedger.vue';

    import RightModal from '../../modal/RightModal.vue';    

    export default {
        name: 'Balance',
        props: ['profile', 'tenant'],

        components: {
            SkeletonVue,
            TenantLedger,

            RightModal,
        },

        data() {
            return {
                TransactionsList : [],
                TotalBalance: 0,
                FinanceLoadingState : true,

                ShowLedger:  false,
                SLAssignment: null,
                SLUnit: null,
                SLProperty: null,

                // finance
                TenantPaymentModal: false,
                PaymentData: {
                    hasTenant: true,
                    property : this.profile.id,
                    unit   : this.profile.unit,
                    assignment: this.profile.assignment,
                    tenant : this.tenant.id,
                    name : this.tenant.fullname,
                    unit_name : this.profile.unitname,
                },
                
                AddTransactionModal: false,
                TransactionData : {
                    type: 'choose',
                    isMainPage: true,
                    property: false,
                    unit: false,
                    tenant: false,
                },
            }
        },

        async mounted () {

            console.log (this.profile);

            var property, unit, tenant;
            property = this.profile.property;
            unit = this.profile.unit;
            tenant = this.profile.id;

            // now load current unit/tenant transactions
            // load all the transaction of this property
            this.loadTenantTransactions ();

        },

        methods: {

            Showmeledger (trans) {
                this.SLAssignment = trans.assignment;
                this.SLUnit = trans.id;
                this.SLProperty = trans.propid;

                this.ShowLedger = true;
            },

            HidemeLedger () {
                this.ShowLedger = false;
            },
            
            async loadTenantTransactions () {
                var tenant = this.tenant.id;
                let getdata = {
                    key : 'tenant',
                    value : tenant
                }
                const Transactions = await DataCenter.ProPRequest('tenant-transactions', false, getdata);
                this.FinanceLoadingState = false;

                if (!Transactions) {
                    this.TransactionsList = [];
                }else {
                    this.TransactionsList = Transactions.trans;
                    this.TotalBalance = Transactions.total;
                }
            },


            CloseTransactionModal (trans) {
                if (trans) { 
                    // update notification
                    this.loadTenantTransactions ();
                    Handler.PropNotification ('You have succesfully added new transaction!');
                }

                // close model
                this.AddTransactionModal = false;
            },

            CloseTenantPaymentModal (trans) {
                if (trans) { 
                    // update notification
                    this.loadTenantTransactions ();
                    Handler.PropNotification ('You have succesfully added new transaction!');
                }

                // close model
                this.TenantPaymentModal = false;
            }
        }


    }

</script>

<style scoped>
    div.balance-btns {
        display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 0px !important;
    margin: 15px 0;
    }

    div.balance-btns div.btn-secondary {
        float: right;
    display: flex;
    margin: 0;
    margin-right: 10px;
    padding: 5px 10px;
    font-size: 9pt;
    }

    div.balance-btns div.btn-secondary p {
        font-size: 9pt !important;
    }
</style>