<template>
    <div class="user-list animatedss fadeInUp">
        <ul class="_propscrl">            
            <li class="transAe" v-for="lst in list" :key="lst.id" @click="userSel(lst)">
                <div class="image">
                    <HtmlImage :source="lst.avatar" />
                </div>

                <div class="content">
                    <h3>{{ lst.fullname }}</h3>
                    <p>{{ lst.email }}</p>
                </div>
            </li>
        </ul>
    </div>
    <div class="overlay" @click="closeList"></div>
</template>

<script>

    //@ import components blocks
    import HtmlImage from './HtmlImage.vue';

    export default {
        name: 'UserSelectPop',
        props: ['list'],
        components: {
            HtmlImage
        },

        mounted () {
            console.log (this.list);
        },

        methods : {
            userSel (list) {
                this.$emit('usersel', list);
            },

            closeList () {
                this.$emit('lstboxclse');
            }
        }
    };
</script>