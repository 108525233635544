<template>
    <div class="prop-form _propscrl">        
        <div class="form-area" style="padding-bottom: 65px;">
            
            <form name="propform" id="propformedit-step" style="display: unset">
                <div class="pf--title">
                    <h3>Edit Your Property</h3>
                    <p>{{pname}} <br>{{data.address}}</p>
                </div>

                <div class="pf--input">
                    <p>Property Name</p>
                    <input type="text" placeholder="i.e. Underhill Property" v-model="pname" id="PName" data-type="len" data-max="5" :disabled="Requesting" />
                </div>

                <div class="pf--input">
                    <p>Property Brief Introduction</p>
                    <textarea placeholder="Property Information" style="height: 150px" v-model="brief" id="Brief" data-type="len" data-max="10" :disabled="Requesting"></textarea>
                </div>

                <div class="pf--multi-input">
                    <div class="half hleft">
                        <div class="pf--input" style="overflow:unset; position:relative;">
                            <p>Services</p>
                            <input v-model="services" type="text" placeholder="Choose One" readonly @click="ServicesMultiSelect = true" :disabled="Requesting" />
                            <MultiSelectInput v-if="ServicesMultiSelect == true" :type="'select-list'" :lists="PropertyServices" @lstboxclse="ServicesSelected" />
                        </div>
                    </div>
                    <div class="half hright">
                        <div class="pf--input" style="overflow:unset; position:relative;">
                            <p>Features</p>
                            <input v-model="features" type="text" placeholder="Choose One" readonly  @click="FeaturesMultiSelect = true" :disabled="Requesting" />
                            <MultiSelectInput v-if="FeaturesMultiSelect == true" :type="'select-list'" :lists="PropertyFeatures" @lstboxclse="FeaturesSelected" />
                        </div>
                    </div>
                </div>

                 <div class="pf--input" style="overflow:hidden;">
                    <p>Pets Allowed</p>
                    <div class="pets-select">
                        <ul>
                            <li :class="{active : catsAllowed}" @click="catsAllowed = !catsAllowed">
                                <div class="image">
                                    <img class="select" src="../../images/icons/select-list.svg" />
                                    <img class="selected" src="../../images/icons/select-check.svg" />
                                </div>
                                <div class="content">
                                    <p>Cats</p>
                                </div>
                            </li>

                            <li :class="{active : dogsAllowed}" @click="dogsAllowed = !dogsAllowed">
                                <div class="image">
                                    <img class="select" src="../../images/icons/select-list.svg" />
                                    <img class="selected" src="../../images/icons/select-check.svg" />
                                </div>
                                <div class="content">
                                    <p>Dogs</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>


            </form>
            
        </div>
        
        <div class="form-action">
            <div class="form-area">
                <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" style="width: 140px; margin-top: 13px;float: left;" @click="EditThisProperty">
                    <p v-if="!Requesting">Update Property</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>
                <span v-if="formError" class="__formerror">{{ infoText }}</span>
            </div>
        </div>
    </div>
</template>

<script>

    // javascript modules
    import Locale from '../../javascript/Locale';
    import Request from '../../javascript/getRequest';
    import DataCenter from '../../javascript/DataCenter';
    import FormValidation from '../../javascript/FormValidation';

    // vue components
    import MultiSelectInput from '../web-forms/MultiSelectInput.vue';

    export default {
        name: 'EditProperty',
        props: ['data'],
        emits: ['close'],
        components: {
            MultiSelectInput,
        },

        data () {
            return {
                pname : this.data.name,
                brief : '',
                services : '',
                features : '',
                catsAllowed : false,
                dogsAllowed : false,

                // form error
                formError : false,
                infoText : '',

                // step two property features and services
                PropertyServices : Locale.PropertyServices(), // local storage for property services
                SelectedServices : null,
                ServicesMultiSelect : false,

                PropertyFeatures : Locale.PropetyUniqueFeatures(), // local storage for property unique features
                FeaturesMultiSelect : false,
                SelectedFeatures : null,

                Requesting: true,

            }
        },

        mounted () {

            // load more data from server
            this.PropertyInfo ();

        },

        methods: {

            // current property more data
            async PropertyInfo () {

                let getdata = {
                    key : 'property',
                    value : this.data.id
                }

                const MoreInfo = await DataCenter.ProPRequest('property-info', false, getdata);
                this.Requesting = false;

                // upadte the data
                this.brief = MoreInfo.description;
                var Pets = JSON.parse(MoreInfo.pets);
                this.catsAllowed = Pets.cats;
                this.dogsAllowed = Pets.dogs;
                
                // services & features selected
                var services = MoreInfo.services;
                this.PropertyServices.filter(list => {
                    services.forEach(service => {
                        if (list.id == service.id) {
                            list.active = true;
                        }
                    });
                });this.ServicesSelected (this.PropertyServices);

                // features
                var features = MoreInfo.features;
                this.PropertyFeatures.filter(list => {
                    features.forEach(feature => {
                        if (list.id == feature.id) {
                            list.active = true;
                        }
                    });
                });this.FeaturesSelected (this.PropertyFeatures);

            },

            // once serivices are slected lets collect the data
            ServicesSelected (services) {
                this.ServicesMultiSelect = false;
                
                // update services list
                this.PropertyServices = services;

                // now update the selected data list too
                this.SelectedServices = this.PropertyServices.filter(list => {
                    if (list.active == true) {
                        return list;
                    }
                });

                // now set the form modal value
                let len = this.SelectedServices.length;
                if (len < 1) {
                    this.services = '';
                }else {
                    this.services = len + ' Selected';
                }
            },


            // once features are slected lets collect the data
            FeaturesSelected (features) {
                this.FeaturesMultiSelect = false;
                
                // update services list
                this.PropertyFeatures = features;

                // now update the selected data list too
                this.SelectedFeatures = this.PropertyFeatures.filter(list => {
                    if (list.active == true) {
                        return list;
                    }
                });

                // now set the form modal value
                // now set the form modal value
                let len = this.SelectedFeatures.length;
                if (len < 1) {
                    this.features = '';
                }else {
                    this.features = len + ' Selected';
                }
            },

            // if true edit this property
            async EditThisProperty () {
                if (this.Requesting) return;
                this.formError = false; 
                
                // form & validation
                let form = document.getElementById('propformedit-step');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead) {
                    this.Requesting = true;
                    
                    let formData = new FormData();
                    formData.append('property', this.data.id);
                    formData.append('name', this.pname);
                    formData.append('brief', this.brief);

                    formData.append('services', JSON.stringify(this.SelectedServices));
                    formData.append('features', JSON.stringify(this.SelectedFeatures));

                    formData.append('cats', this.catsAllowed);
                    formData.append('dogs', this.dogsAllowed);

                    // load data from server
                    const ReturnData = await Request.postServer('property/update.php', formData);
                    this.Requesting = false;

                    // now check if the email already exists
                    if (ReturnData.error == false) {
                        // now can re-render the employee list
                        let Properties = ReturnData.response;

                        // now close modal, and load current property
                        this.$emit('close', Properties);

                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again later.";
                    }

                }
            }


        }

    };
</script>

<style scoped>
span.__formerror {    margin-left: 10px;    margin-top: 17px;}

</style>