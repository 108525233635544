<template>
    <div class="property-sel">
        <div class="image">
            <ReplaceImage :source="''" />
        </div>

        <div class="content">
            <h3>{{ property.name }}</h3>
            <p>{{ property.address }}</p>
            <span v-if="property.verified">{{ property.time }}</span>
            <span v-else style="color:#ff5722">(Unverified)</span>
        </div>
    </div>
</template>

<script>
    import ReplaceImage from './ReplaceImage.vue';

    export default {
        name: 'PropertySelected',
        props: ['property'],
        components: {
            ReplaceImage,
        }
    };
</script>