<template>
    <div class="fc--header" style="overflow: unset;">
        <div class="fil--area" style="width: 216px;margin-right: 14px;">
            <input type="text" placeholder="Search.." 
                    :style="{'background-image':'url('+require('../../../images/icons/search.svg')+')'}"
                    @keyup="SearchTransactionList" v-model="SearchKeyword" />
        </div>
    </div>

    <div class="prop--table" style="padding-top: 80px;">
        <div class="prop-row row-header">
            <div class="column column35 sortedColumn" @click="SortByPropertyName(SortingRules.property)"><p>Property</p> <img src="./../../../images/icons/tablesort.svg" /></div>
            <div class="column column30 sortedColumn" @click="SortByMonthlyBalance(SortingRules.balance)"><p>Monthly Balance</p> <img src="./../../../images/icons/tablesort.svg" /></div>
            <div class="column column20 sortedColumn" @click="SortyByYTD(SortingRules.ytd)"><p>YTD</p> <img src="./../../../images/icons/tablesort.svg" /></div>
            <div class="column column15"><p>Status</p></div>
        </div>

        <div class="prop-row odd" v-for="(property, index) in FilteredTransList.filtered" :key="property" :class="index % 2 ? 'odd' : 'even'"  @click="TransactionClickHandler(property)" style="overflow: unset; float: left; cursor:pointer;">
            <div class="column column35">
                <div class="fin-transaction">
                    <div class="image">
                        <ReplaceImageVue :source="property.image" />
                    </div>
                    <div class="content">
                        <h3>{{ property.name }}</h3>
                        <p>{{ property.address }}</p>
                    </div>
                </div>
            </div>

            <div class="column column30">
                <div class="fin-transaction" style="display: block;" v-if="property.monthly == 0">
                    <h3 style="font-size: 14pt;">$0</h3>
                    <p>No transaction yet!</p>
                </div>
                <div class="fin-transaction" style="display: block;" v-else>
                    <h3>{{property.monthly.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            })}}</h3>
                    <p>{{property.remarks}}</p>
                </div>
            </div>

            <div class="column column20">
                <div class="fin-paymethod">
                    <h3>{{property.ytd.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            })}}</h3>
                </div>
            </div>

            <div class="column column15">
                <div class="fin-paymethod">
                    <p v-if ="property.due == 0">All Current</p>
                    <p style="color: #f00" v-else>{{property.due}} Past Due</p>
                </div>
            </div>
        </div>  


    </div>
</template>

<script>

    import ReplaceImageVue from '../../blocks/ReplaceImage.vue';

    // pinia storage
    import { useOwnerStore } from './../../../stores/OwnerStore';

    export default {
        name: 'PropTransaction',
        props: ['transactions'],
        emits: ['propselected'],

        components: {
            ReplaceImageVue,
        },

        data() {
            return {
                FilteredTransList: {
                    filtered: this.transactions,
                    raw: this.transactions
                },

                SortingRules: {
                    property: false,
                    balance: false,
                    ytd: false,
                },

                SearchKeyword: '',


                // pinia storage
                OwnerStore: useOwnerStore (),
            }
        },

        mounted () {
            console.log (this.FilteredTransList);

            // reset on the main page
            // finance payment data
            this.OwnerStore.financepaymentdata = {
                type: 'choose',
                isMainPage: true,
                tenant: false,
                property: false,
                unit: false,
                whichProperty: {},
                whichUnit: {},
                whichTenant: {}
            }
        },

        methods: {

            // search the data list
            SearchTransactionList () {
                this.FilteredTransList.filtered = this.FilteredTransList.filtered.filter (trans => {
        			var keyword = this.SearchKeyword.toUpperCase();
                    if (trans.name.toUpperCase().indexOf(keyword) > -1 || trans.address.toUpperCase().indexOf(keyword) > -1) return trans;
                });

                if (this.SearchKeyword.length == 0) {
                    this.FilteredTransList.filtered = this.FilteredTransList.raw;
                }
            },

            SortByPropertyName (rule) {
                this.SortingRules.property = !rule;
                this.FilteredTransList.filtered = this.FilteredTransList.filtered.sort(function(a,b){
                    var c, d;
                    if (rule) { c = a; d = b} else {d = a; c = b;}
                    a = c; b = d;

                    if (a.name > b.name) return 1;
                    if (a.name == b.name) return 0;
                    if (a.name < b.name) return -1;
                });
            },

            SortByMonthlyBalance (rule) {
                this.SortingRules.balance = !rule;
                this.FilteredTransList.filtered.sort ((a, b) => {
                    var c, d;
                    if (rule) { c = a; d = b} else {d = a; c = b;}
                    a = c; b = d;

                    if (Number(a.monthly) > Number(b.monthly)) return 1;
                    if (Number(a.monthly) == Number(b.monthly)) return 0;
                    if (Number(a.monthly) < Number(b.monthly)) return -1;
                });
            },
             
            SortyByYTD (rule) {
                this.SortingRules.ytd = !rule;
                this.FilteredTransList.filtered.sort ((a, b) => {
                    var c, d;
                    if (rule) { c = a; d = b} else {d = a; c = b;}
                    a = c; b = d;

                    if (Number(a.ytd) > Number(b.ytd)) return 1;
                    if (Number(a.ytd) == Number(b.ytd)) return 0;
                    if (Number(a.ytd) < Number(b.ytd)) return -1;
                });
            },

            TransactionClickHandler (property) {
                this.$emit('propselected', property);
            },
            
        },
    }
</script>