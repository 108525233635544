<template>
    <div class="user-list animatedss fadeInUp" :style="style">
        <!-- If no data on result -->
        <div v-if="!lists || lists.length <= 0" class="empty-result">
            <img style="width: 120px !important;" src="../../images/icons/empty-result.svg" />
            <p style="text-align: center; margin-bottom: 10px;">You have not added a tenant yet. Please add a tenant and then assign them to this unit.</p>
            <router-link class="btn-secondary" style="text-decoration: none;" :to="{name : 'owner-tenants'}">Add Tenant</router-link>
        </div>

        <ul class="_propscrl">
            <li class="transAe" :class="{ active : lst.active}" v-for="lst in lists" :key="lst.id" @click="ListSelect(lst)">
                <div class="image">
                    <UserImage :init="lst.fullname" :back="lst.back" :source="lst.avatar" />
                </div>

                <div class="content">
                    <h3>{{ lst.fullname }}</h3>
                    <p>{{ lst.email }}</p>
                </div>
            </li>

            <li style="background-color: unset;cursor: default;display: flex;justify-content: center;align-items: center;">
                <router-link class="btn-secondary" style="text-decoration: none;" :to="{name : 'owner-tenants'}">Add Tenant</router-link>
            </li>
        </ul>
    </div>
    <div class="overlay" @click="closeList" style="opacity: 0;"></div>
</template>

<script>

    //@ import components blocks
    import UserImage from '../blocks/UserImage.vue';

    export default {
        name: 'TenantSingleSelectInput',
        props: [ 'lists', 'style'],
        emits: ['lstboxclse'],
        components: {
            UserImage,
        },
        data () {
            return {
                ListData : this.lists,
                selected : null,
            }
        },

        mounted () {

            console.log (this.ListData);

        },

        methods: {
            ListSelect (sl) {
                this.ListData = this.ListData.filter(list => {
                    list.active = false;

                    if (list.id == sl.id) {
                        list.active = true;
                        this.selected = list;
                    }

                    return list;
                });

                // close list with selection
                this.$emit('lstboxclse', this.selected);
            },

            closeList () {
                this.$emit('lstboxclse', this.selected);
            },

            AddNewTenant () {

            }
        }
    };
</script>