<template>
    <div class="prop-row" :class="count % 2 ? 'odd' : 'even'" style="overflow: unset; float:left;">
        <div class="column column35">
            <div class="unit--parking">
                <div class="image">
                    <img v-if="parking.type == 'Guest Parking'" src="../../../images/icons/guest-parking.svg" />
                    <img v-else-if="parking.type == 'Handicap Parking'" src="../../../images/icons/handicap-parking.svg" />
                    <img v-else src="../../../images/icons/normal-parking.svg" />
                </div>

                <div class="content">
                    <h3 style="margin-top: 10px;">{{parking.name}} <em>{{parking.type}}</em></h3>
                </div>
            </div>
        </div>

        <div class="column column25">
            <div class="unit--status">
                <div class="status secondary" v-if="parking.status == 'Reserved'">{{parking.status}}</div>
                <div class="status primary" v-if="parking.status == 'Open'">{{parking.status}}</div>
                <p v-if="parking.status == 'Reserved'">{{parking.status_date}}</p>
            </div>
        </div>

        <div class="column column25">
            <div class="reserved-for">
                <h3 v-if="parking.status == 'Reserved'">{{parking.tenant.fullname}}</h3>
                <p v-if="parking.status == 'Reserved'">Unit: {{parking.unit}}</p>
                <h3 v-if="parking.status == 'Open'" style="color: rgb(255, 87, 34); font-size: 10pt; font-weight: 400; letter-spacing: 0.15px;">Not Reserved</h3>
            </div>
        </div>

        <div class="column column15" style="position:relative">
            <div class="unit-menu" @click="ActionClicked">
                <img src="../../../images/icons/ham-menu.svg" />
            </div>
            <ul class="action-menu" v-if="parking.active == true">
                <li><img src="./../../../images/icons/close.svg" @click="parking.active=false" /></li>
                <li @click="EditParking">Edit Parking</li>
                <li @click="AssignTenant" v-if="parking.status == 'Open'">Assign Unit</li>
                <li @click="UnAssignTenant" v-if="parking.status == 'Reserved'">Cancel Reservation</li>
                <li @click="DeleteParking">Delete Parking</li>
            </ul>
        </div>
    </div>
</template>

<script>
    //@import component
    import HtmlImage from './../../blocks/HtmlImage.vue';

    export default {
        name: 'ParkingLists',
        props: ['parking', 'count'],
        emits: ['hamclick', 'editparking', 'assigntenant', 'unassigntenant', 'delete'],
        components: {
            HtmlImage,
        },

        mounted () {
            
        },

        methods : {
            ActionClicked () {
                this.$emit('hamclick', this.parking);
            },

            AssignTenant () {
                this.$emit('assigntenant', this.parking);
            },

            DeleteParking () {
                this.$emit('delete', this.parking);
            },

            UnAssignTenant () {
                this.$emit('unassigntenant', this.parking);
            },

            EditParking () {
                this.$emit('editparking', this.parking);
            }
        }
    };
</script>

<style scoped>
    ul.action-menu {left: -110px;}
    div.prop--table div.prop-row .column h3 em {    font-style: normal;
    font-weight: 500;
    font-size: 10pt;}
</style>