<template>
    <div class="plan--detail">
        <div class="plans">
            <ul class="allpl">
                <li v-for="plan in FloorPlans" :key="plan" @click="FloorClicked(plan)" :class="plan.active ? 'active' : ''">{{plan.name}}</li>
            </ul>
        </div>

        <div class="plandetail _propscrl">
            <div class="pd-thisplan">
                <div class="summery">
                    <p>Floor Type</p>
                    <h3>{{PlanChoosed.floortype}}</h3>

                    <p>Bed Rooms</p>
                    <h3>{{PlanChoosed.bedrooms}}</h3>

                    <p>Bath Rooms</p>
                    <h3>{{PlanChoosed.bathrooms}}</h3>

                    <p>Square Feet</p>
                    <h3>{{PlanChoosed.squarefeet}}</h3>

                    <p>Descriptions</p>
                    <h4>{{PlanChoosed.description}}</h4>
                </div>

                <ul class="amenities">

                    <p>Floor Plan</p>
                    <div class="plan-image">
                        <img :src="PlanChoosed.planurl" />
                    </div>

                    <p>Amenities</p>
                    <li v-for="amenity in ChoosedAmenities" :key="amenity">
                        <img :src="require(`../../../images/amenities/${amenity.icon}`)" />
                        <p>{{amenity.name}}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="floor-plan-action">
        <div class="btn-primary" @click="AddPlan">
            <img src="./../../../images/icons/addbtn-icon.svg" />
            <p>Add New Plan</p>
        </div>
    </div>
</template>

<script>

    import Locale from './../../../javascript/Locale';

    export default {
        name: 'Detail',
        props: ['content'],
        emits: ['addplan'],
        data () {
            return {
                FloorPlans: this.content,
                PlanChoosed: {},
                ChoosedAmenities : [],

                Amenities: Locale.UnitAmenities (),
            }
        },

        mounted () {            
            this.FloorClicked(this.FloorPlans[0]);
        },

        methods: {
            FloorClicked (plan) {
                
                // make the click plan active
                this.FloorPlans = this.FloorPlans.filter(pln => {
                    pln.active = false;
                    
                    if (plan == pln) {
                        pln.active = true;
                        this.PlanChoosed = pln;
                    }

                    return pln;
                });

                // select the amenities
                var Amenities = plan.amenities.split(',');
                this.ChoosedAmenities = this.Amenities;
                this.ChoosedAmenities = this.ChoosedAmenities.filter(amenity => {
                    for (var i = 0; i < Amenities.length; i++) {
                        if (Amenities[i] == amenity.id) return amenity;
                    };
                });
                
            },

            AddPlan () {
                this.$emit('addplan');
            }
        }

    }
</script>