<template>
  <div class="prop-otppage" v-if="!ConfirmationPage && !isRequesting">
    <div class="info success">
      <h3>Successfully updated rent!</h3>
      <p style="margin-top: 15px">
        There are <strong>{{ Properties }}</strong> total properties
      </p>
      <p>
        Including <strong>{{ RentedUnits }}</strong> rented units out of
        <strong>{{ TotalUnits }}</strong> units in all properties.
      </p>
      <p style="margin-bottom: 25px">
        Total rent updated <strong>{{ Rent.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        }) }}</strong>
      </p>
      <span class="btn-primary" @click="CloseDialog">Close</span>
    </div>
  </div>

  <div class="prop-otppage" v-if="ConfirmationPage && !isRequesting">
    <div class="info">
      <h3>Are you sure you want to update rent?</h3>
      <p>
        !If you move ahead with this action, system will update rent<br />to all the
        rented units
      </p>
      <span class="btn-primary" @click="StartUpdatingRent">Update Rent</span>
    </div>
  </div>

  <div class="prop-otppage" v-if="ConfirmationPage && isRequesting">
    <div class="info">
      <p style="text-align: center; margin-bottom: 35px">
        Please wait! system is updating rented unit.
      </p>
      <SkeletonVue :type="'user-list'" :data="2" />
    </div>
  </div>
</template>

<script>

// scripts
import DataCenter from './../../../javascript/DataCenter';

// components
import SkeletonVue from "../../blocks/Skeleton.vue";

export default {
  name: "UpdateRent",
  props: ["data"],
  emits: ["close"],
  components: {
    SkeletonVue,
  },

  data() {
    return {
      isRequesting: false,
      ConfirmationPage: true,

      // data updated
      Properties: 0,
      RentedUnits: 0,
      TotalUnits: 0,
      Rent: 0,
    };
  },

  mounted() {},

  methods: {
    async StartUpdatingRent() {
      this.isRequesting = true;

      const RequestData = await DataCenter.ProPRequest("admin-update-rent", false, false);
      console.log (RequestData);

      this.isRequesting = false;
      if (!RequestData) {
        this.ConfirmationPage = true;
      } else {
        this.Properties = RequestData.properties;
        this.RentedUnits = RequestData.rented;
        this.TotalUnits = RequestData.units;
        this.Rent = RequestData.rent;

        this.ConfirmationPage = false;
      }
    },

    CloseDialog () {
      this.$emit('close', false);
    }
  },
};
</script>
