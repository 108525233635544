<template>
    <!-- Propstop Offer -->
    <section class="we-offer">
        <div class="wrapper" style="padding-top: 0;">
            <ul class="offer">
                <li>
                    <img src="./../../images/website/home_estate2_pic1.svg" alt="Home Estate" />
                </li>
                <li>
                    <p>We care about our users so we want to continually deliver value and treat their business/assets like we want ours treated. In fact, WE use PropStop to manage our own assets.</p>
                    <p>We will offer complimentary services to our core offering, some of which users already pay for elsewhere, but we hope they will prefer to work with us because of our integrity and superior product offering.</p>                    
                    <router-link class="btn-about-us" :to="{name : 'about-us'}">About Us</router-link>
                </li>

            </ul>
        </div>
    </section>
    <!-- Propstop Offer -->
</template>

<script>
    //@import component
    import HtmlImage from './../blocks/HtmlImage.vue';

    export default {
        name : 'PropOffer',
        components : {
            HtmlImage,
        }
    };
</script>