<template>
    <WebNav />

    <!-- internal header hero -->
    <InternalHeader  :page="'web-about'" 
                  :title="'Our Mission to Transform Property Management'" 
                  :description="'Learn about PropStops mission to revolutionize the property management industry through innovative technology. Read our story, values, and how we are making property management easier for everyone.'" />
    <!-- internal header hero -->

    <!-- our story -->
    <section class="about">
        <div class="wrapper" style="padding: 0;">
                <div class="abwr-about">
                    <div class="image">
                            <img src="./../../images/website/about-us-image.jpg" alt="About Us">
                    </div>
                    <div class="content">
                            <span>Our Story</span>
                            <h2>Welcome to PropStop, where we're <em><p>redefining</p></em> the landscape of property management.</h2>
                            <p> Born from a simple idea—to make property management easier, more efficient, and accessible for everyone—PropStop has evolved into a comprehensive platform that serves both landlords and tenants across the globe.</p>
                            <p>Our founders, seasoned property managers, and technology enthusiasts encountered firsthand the complexities and inefficiencies in managing properties. Frustrated by the disjointed tools and processes, they envisioned a unified solution. Thus, PropStop was born—a single platform that addresses every facet of property management, from rent collection to maintenance requests.</p>
                    </div>
                </div>
        </div>
    </section>
    <!-- our story -->

    <Mission />

    <section class="propvalues">
        <div class="wrapper" style="padding: 0;">
            <h2>Our <em><p style="font-weight: 700;">values</p></em></h2>
            <p>Our values are the core beliefs and principles that guide your organization. We shape your company culture, influence your business decisions.</p>

            <ul class="values">
                <li>
                    <div class="image">
                        <img src="./../../images/website/about-innovation.svg" alt="Innovation" />
                    </div>
                    <h3>Innovation</h3>
                    <p>We're committed to continuous improvement and innovation, ensuring our platform meets the evolving needs of the property management community.</p>
                </li>

                <li>
                    <div class="image">
                        <img src="./../../images/website/about-honesty.svg" alt="Integrity" />
                    </div>
                    <h3>Integrity</h3>
                    <p>We operate with integrity, transparency, and honesty, building trust with our users and partners.</p>
                </li>

                <li>
                    <div class="image">
                        <img src="./../../images/website/about-simplicity.svg" alt="Simplicity" />
                    </div>
                    <h3>Simplicity</h3>
                    <p>Our tools are designed to be intuitive and user-friendly, making property management accessible to everyone, regardless of their tech-savviness.</p>
                </li>

                <li>
                    <div class="image">
                        <img src="./../../images/website/about-comunity.svg" alt="Community" />
                    </div>
                    <h3>Community</h3>
                    <p>We advocate for a supportive community, encouraging open communication and feedback between landlords, tenants, and our team.</p>
                </li>
            </ul>
        </div>
    </section>

    <section class="counters">
        <div class="wrapper" style="background-color: transparent; padding: unset; padding: 0">
                <div class="countper">
                    <h2>Join us on our <em><p style="color: white !important;font-weight: 700;">Journey</p></em></h2>
                    <p>As we continue to grow and shape the future of property management, we invite you to join us. Whether you're a landlord seeking a more efficient way to manage your properties, a tenant looking for a hassle-free renting experience, or a talented individual eager to make an impact in the prop-tech space, there's a place for you at PropStop.</p>
                    <p>Together, let's transform the way the world views property management.</p>
                    <router-link to="/waitlist/" class="site-btn-dark">
                            <p>Get Started with PropStop Today</p>
                    </router-link>
                    <p style="font-size: 9pt;color: #a7a7a7;">Welcome to the PropStop family --where property management meets innovation</p>
                </div>
        </div>
    </section>

    <Footer />
</template>

<script>

    // import Website Navigation
    import WebNav from './../../components/website/WebNav.vue';
    import Seprator from './../../components/website/Seprator.vue';
    import InternalHeader from '../../components/website/InternalHeader.vue';
    import Footer from './../../components/website/Footer.vue';

    import Mission from './../../components/website/Mission.vue';
    

    export default {
        name : 'HomePage',
        components : {
            Seprator,
            WebNav,
            InternalHeader,
            Footer,

            Mission,
        },

        mounted () {
            // scroll to top
            window.scrollTo(0, 0);

            // remove styling used for apps
            document.getElementById('propstop-app').style.height = 'auto';
            document.getElementById('propstop-app').style.overflow = 'auto';

            //  change the title
            document.title = "About PropStop: Our Mission to Transform Property Management";

            

        }
    }
</script>