<script>

    

    export default {
        name: 'Teams',
        props: ['options'],     
        emits: ['selected'],
        components : {
        },

        data () {
            return {
                ShowOptions: false,
                Selected: '',
            }
        },

        async mounted () {
            this.Selected = this.options[0].name;
        },

        methods: {

            OptionSelected (option) {
                this.options.forEach(element => {
                    if (element.id == option.id) this.Selected = element.name;
                });

                // close options
                this.ShowOptions = false;

                this.$emit('selected', option);
            },

            StartSelection () {
                this.ShowOptions = true;
            }
        }

    }

</script>

<template>
    <div class="custom-select">
        <div class="cs-selected" @click="StartSelection">
            <p>{{ Selected }}</p>
            <img src="./../../images/icons/select-icon.svg" />
        </div>

        <ul class="cs-option" v-if="ShowOptions == true">
            <li v-for="option in options" :key="option.id" @click="OptionSelected(option)">
                <p>{{ option.name }}</p>
            </li>
        </ul>
    </div>    
</template>