<template>
    <div class="prop-form _propscrl">        
        <div class="form-area" style="padding-bottom: 65px;">
            <form name="propform" id="propform-parking">
                <div class="pf--title">
                    <h3>Add Parking</h3>
                    <p>Please choose the breif information about your parking on current property.</p>
                    <p style="font-size: 10pt;">* Indicate a required field</p>
                </div>

                <div class="pf--info" style="margin-bottom: 45px;">
                    <div class="icon">
                        <img src="./../../images/icons/info-blue.svg" />
                    </div>
                    <div class="content">
                        <p>After adding in the various types of parking spaces in the building, you will be able to assign spaces to units.</p>
                    </div>
                </div>

                <div class="pf--input">
                    <p>Unit Parking? *</p>
                    <input type="text" placeholder="i.e Total Parking Spaces" 
                                        v-model="UnitParking" 
                                        id="UnitParking" data-type="len" data-max="1" />
                </div>

                <div class="pf--input">
                    <p>Handicap Parking? *</p>
                    <input type="text" placeholder="i.e Total Parking Spaces" 
                                        v-model="HandicapParking" 
                                        id="HandicapParking" data-type="len" data-max="1" />
                </div>

                <div class="pf--input">
                    <p>Guest Parking? *</p>
                    <input type="text" placeholder="i.e Total Parking Spaces" 
                                        v-model="GuestParking" 
                                        id="GuestParking" data-type="len" data-max="1" />
                </div>

            </form>
            
            <span v-if="formError" class="__formerror">{{ infoText }}</span>
        </div>

        <div class="form-action">
            <div class="form-area">
                <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="AddNewParking">
                    <p v-if="!Requesting">Add Parking</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    //@ JS Modules
    import Request from './../../javascript/getRequest';
    import FormValidation from './../../javascript/FormValidation';

    // import component
    import PropertyList from './../blocks/PropertyList.vue';

    export default {
        name: 'AddNewParking',
        props: ['data'],
        emits: ['close'],
        components: {
            PropertyList,
        },

        data () {
            return {
                // Form v-Modal
                UnitParking : '',
                HandicapParking : '',
                GuestParking : '',
                HGCanSelect : '',
                PropertyName : this.data.id,
                Requesting: false,

                // form error
                formError : false,
                infoText : '',
            }
        },

        methods: {   

            // now add the parking & get back to the list
            async AddNewParking () {
                if (this.Requesting) return;

                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-parking');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead) {
                    this.Requesting = true;

                    let formData = new FormData();
                    formData.append('property', this.PropertyName);
                    formData.append('unit', this.UnitParking);
                    formData.append('handicap', this.HandicapParking);
                    formData.append('guest', this.GuestParking);

                    // load data from server
                    const ReturnData = await Request.postServer('property/parking/add.php', formData);
                    this.Requesting = false;

                    // now check if the email already exists
                    if (ReturnData.error == false) {
                        // now close modal, and load current property
                        this.$emit('close', ReturnData.response);
                    
                    // if error
                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again later or contact support.";
                    }
                }
            }
        }


    };
</script>

<style scoped>
span.__formerror {margin-left: 0 !important;}
.primary-btn-loader {margin-top: 15px !important;padding: 10px 30px !important;border-radius: 6px !important;float: left !important;width: 125px !important;}
</style>