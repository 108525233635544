<script>

      // pinia
      import { useOwnerStore } from '../../../stores/OwnerStore';
      import ReplaceImage from '../../blocks/ReplaceImage.vue';

      export default {
            name: 'Search',
            props: ['page'],
            components: {
                  ReplaceImage,
            },

            data () {
                  return {
                        isSearching : false,
                        SearchResult : [],
                        isSearchActive: false,
                        CurrentPageName: this.page,

                        Keyword: '',
                  }
            },

            mounted () {

            },

            methods: {
                  InitSearching (event) {
                        if (event.keyCode == 13) {
                              const OwnerStore = useOwnerStore ();
                              if (OwnerStore.route.page == 'Properties') {
                                    this.isSearchActive = true;
                                    this.SearchProperty (OwnerStore.properties);
                              }

                              if (OwnerStore.route.page == 'Tickets') {
                                    this.isSearchActive = true;
                                    this.SearchTickets (OwnerStore.tickets);
                              }
                        }
                  },

                  SearchProperty (properties) {
                        let keyword = this.Keyword.toLowerCase();
                        this.SearchResult = properties.filter (property => {
                              let current = property.name.toLowerCase();
                              if (current.match(keyword)) return property;
                        });
                  },

                  SearchTickets (tickets) {
                        let keyword = this.Keyword.toLowerCase();
                        this.SearchResult = tickets.filter (ticket => {
                              let current = ticket.subject.toLowerCase();
                              if (current.match(keyword)) return ticket;
                        });
                  },

                  RouteSearchResult (route, routeto) {
                        // pinia setup
                        const OwnerStore = useOwnerStore ();

                        /**
                         * Route to the clicked Propertiy
                        */
                        if (route == 'property') {
                              OwnerStore.route.params = routeto.id;
                        }else if (route == 'ticket') {
                              OwnerStore.route.params = routeto.id;
                        }else if (route == 'tenant') {
                              OwnerStore.route.params = routeto.id;
                        }

                        // remove the search box
                        this.isSearchActive = false;

                  }

            }
      };
      
</script>

<template>
      <input type="text" name="search" 
            :placeholder="'Search in PropStop...'"
            @keydown="InitSearching"
            v-model="Keyword"
            :style="{'background-image':'url('+require('../../../images/icons/search.svg')+')'}" />
            
      <div class="-search-result _propscrl" v-if="isSearchActive">            
            <div v-if="!isSearching && SearchResult.length == 0" class="empty-result animated fadeInUp">
                  <img src="../../../images/icons/empty-result.svg" />
                  <p>Empty search result! <br /> Please type any keywords!</p>
            </div>

            <div class="sr-container" v-if="CurrentPageName == 'Properties'">
                  <h3>Properties</h3>
                  <ul>
                        <li v-for="property in SearchResult" @click="RouteSearchResult('property', property)" style="width: auto !important;">
                              <div class="image" style="overflow: hidden;">
                                    <ReplaceImage :source="property.image" :key="property.name" />
                              </div>

                              <div class="content">
                                    <h4>{{ property.name }}</h4>
                                    <p>{{ property.address }}</p>
                              </div>
                        </li>
                  </ul>
            </div>    

            <div class="sr-container" v-if="CurrentPageName == 'Tickets'">
                  <h3>Tickets</h3>
                  <ul>
                        <li v-for="ticket in SearchResult" @click="RouteSearchResult('ticket', ticket)" style="width: auto !important;">
                              <div class="image" style="overflow: hidden;">
                                    <ReplaceImage :source="ticket.prop_unit.thumbnail" :key="ticket.subject" />
                              </div>

                              <div class="content">
                                    <h4>{{ ticket.subject }}</h4>
                                    <p>{{ ticket.priority + ' . ' + ticket.status + ' . ' + ticket.created }}</p>
                              </div>
                        </li>
                  </ul>
            </div>

            <div class="sr-container" v-if="CurrentPageName == 'Tenants'">
                  <h3>Tickets</h3>
                  <ul>
                        <li v-for="tenant in SearchResult" @click="RouteSearchResult('tenant', tenant)" style="width: auto !important;">
                              <div class="image" style="overflow: hidden;">
                                    <ReplaceImage :source="ticket.prop_unit.thumbnail" :key="ticket.subject" />
                              </div>

                              <div class="content">
                                    <h4>{{ ticket.subject }}</h4>
                                    <p>{{ ticket.priority + ' . ' + ticket.status + ' . ' + ticket.created }}</p>
                              </div>
                        </li>
                  </ul>
            </div>

      </div>
      <div class="overlay" style="opacity: 0;" v-if="isSearchActive" @click="(isSearchActive = false)"></div>
</template>