<template>
    <div class="property-verify _propscrl">
        <div class="summery">
            <div class="avatar">
                <div class="image">
                    <ReplaceImage :source="''" />
                </div>    
                <h2>{{ Property.name }}</h2>
            </div>

            <ul class="sumry">
                <li>
                    <div class="icon">
                        <img src="./../../../images/icons/nmap.svg">
                    </div>
                    <p>{{ Property.address }}</p>
                </li>
                <li>
                    <div class="icon">
                        <img src="./../../../images/icons/ndate.svg">
                    </div>
                    <p>{{ Property.created }}</p>
                </li>
                <li>
                    <div class="icon">
                        <img src="./../../../images/icons/house.svg">
                    </div>
                    <p>{{ Property.type }}</p>
                </li>
            </ul>

            <ul class="status">
                <li v-if="Property.verified == 'true'"><img class="admin-verified" src="../../../images/admin/check.svg"> <p>Verified</p></li>
                <li v-else><img class="admin-unverified" src="../../../images/admin/cross.svg"> <p>Unverified</p></li>

                <li v-if="Property.verification == 'true'"><img class="admin-verified" src="../../../images/admin/check.svg"> <p>Waitlist</p></li>
            </ul>
        </div>

        <div class="content">
            <ul class="other-summery">
                <li>
                    <h3>Property Description</h3>

                    <p>{{ Property.description }}</p>
                </li>

                <li>
                    <h3>Property Owner</h3>
                    <div class="pop-owner-info">
                        <div class="image">
                            <UserImage :init="Owner.fullname" :source="Owner.avatar" :back="Owner.back" />
                        </div>
                        <div class="acc-dtls">
                            <h3>{{ Owner.fullname }}</h3>
                            <p>{{ Owner.email }}</p>
                            <p>{{ Owner.mobile }}</p>
                        </div>
                    </div>
                </li>

                <li>
                    <h3>Other Information</h3>
                    <table>
                    <tr>
                        <td>Floors</td>
                        <td>{{ Property.floors }}</td>
                    </tr>
                    <tr>
                        <td>Units</td>
                        <td>{{ Property.units }}</td>
                    </tr>
                    <tr>
                        <td>Business</td>
                        <td>{{ Owner.business }}</td>
                    </tr>
                </table>
                </li>
                
            </ul>
        </div>
    </div>

    <div class="form-action" style="width: calc(100% - 50px);">
        <div class="form-area" style="width: 95%;">
            <div v-if="Property.verified == 'false' && Property.verification == 'true'" class="btn-primary" ref="verify" @click="VerifyProperty">Verify Property</div>
            <div v-else-if="Property.verified == 'true' && Property.verification == 'false'">
                <p class="info success"><img src="../../../images/admin/check.svg" /> Property is verified</p>
            </div>
            <div v-else>
                <p class="info"><img src="../../../images/icons/info-icon.svg" /> To Verify, Property must on the waitlist!</p>
            </div>
        </div>
    </div>
</template>

<script>
    // js library
    import Request from './../../../javascript/getRequest';

    // components
    import UserImage from './../../blocks/UserImage.vue';
    import ReplaceImage from './../../blocks/ReplaceImage.vue';

    export default {
        name: 'PropertyVerify',
        props: ['data'],
        emits: ['close'],
        components: {
            UserImage,
            ReplaceImage
        },

        data () {
            return {
                Property: this.data.property,
                Owner: this.data.property.ownerprofile,
            }
        },

        mounted () {

        },

        methods: {
            async VerifyProperty () {
                let pid = this.Property.id;
                let fullname = this.Owner.fullname;
                let email = this.Owner.email;
                let pname = this.Property.name;

                let formData = new FormData();
                    formData.append('pid', pid);
                    formData.append('pname', pname);
                    formData.append('fullname', fullname);
                    formData.append('email', email);

                    this.$refs.verify.innerHTML = 'Working...';

                    // load data from server
                    const ReturnData = await Request.postServer('admin/action/verify-property.php', formData);
                    if (ReturnData.error == false) {
                        this.Property.verified = 'true';
                        this.Property.verification = 'false';

                        // update the account list too.
                        this.$emit('close', ReturnData.response);
                    }else {
                        this.$refs.verifyowner.innerHTML = 'Verify Property';
                    }
            }
        }
    };
</script>