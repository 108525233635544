<template>
    <div class="prop--table animatedss fadeInUp">
        <TenantList v-for="(tenant, index) in tenants"     
                :key="tenant.id" 
                :tenant="tenant" 
                :count="index" 
                
                @select="TenantSelected"  />
    </div>

    <!-- If no data on result -->
    <div v-if="tenants.length <= 0" class="empty-result animated fadeInUp" style="position: unset !important; padding: 0px !important;">
        <img src="../../images/icons/empty-result.svg" />
        <p style="text-align: center;margin-bottom: 10px;font-size: 12pt important;line-height: 18pt !important;">You dont have much information on this request. <br />Please add tenants on this property.</p>
        <div class="btn-secondary bphasno" @click="RouteToAddTenant">
            <p>Add Tenant</p>
        </div>

    </div>

</template>

<script>
    // import component
    import TenantList from './../property/blocks/TenantList.vue';
    
    export default {
        name: 'OwnerTenants',
        props: ['property', 'tenants'],
        emits: ['selected', 'addtenant'],
        components: {
            TenantList
        },

        data () {
            return {
                propertyid : this.property.id,
                filterTenantList : [],
            }
        },        

        mounted () {
            // filter according to the selected property 
            // also filter according to the filter choosed
            // this.filterTenants ();

            console.log (this.tenants);

        },

        methods: {
            filterTenants () {
                this.filterTenantList = this.tenants.filter (tenant => {
                    if (tenant.property == this.property.id) return tenant;
                });
            },

            // Tenant selected or clicked
            TenantSelected (tenant) {
                this.$emit('selected', tenant);
            },

            RouteToAddTenant () {
                this.$emit('addtenant');
            }
        }
    };
</script>