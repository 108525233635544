<template>
    <div class="internal--header">
        <ul class="tab-menu">
            <li :class="{'active' : OpenTasksTab}" @click="OpenTasksTab = true">Ongoing</li>
            <li :class="{'active' : !OpenTasksTab}" @click="OpenTasksTab = false">Completed</li>
        </ul>

        <div class="actions" style="display: none;">
            <div class="btn-secondary" @click="AddNewTasks">
                <p>Create Task</p>
            </div>
        </div>
    </div>

    <!-- Property Tasks Skeletion -->
    <div v-if="LoadingTaskState == true" style="margin-top:55px">
        <Skeletion :data="3" />
    </div>

    <div v-if="TasksListed && OpenTasksTab" class="internal-content animated fadeInUp">
        <ul class="tasks">
            <TaskList v-for="(task, index) in OpenTasks" :key="task.id" :task="task" :count="index" 
                        @taskchange="TaskClicked" 
                        @taskdelete="DeleteTask"
                        @taskedit="EditCurrentTask"
                        @completetask="CompleteTask" />
        </ul>
    </div>

    <div v-if="TasksListed && !OpenTasksTab" class="internal-content animated fadeInUp">
        <ul class="tasks">
            <TaskList v-for="(task, index) in CompletedTasks" :key="task.id" :task="task" :count="index" 
                        @taskchange="TaskClicked" 
                        @taskdelete="DeleteTask"
                        @completetask="CompleteTask" />
        </ul>
    </div>

    <!-- If no data on result -->
    <div v-if="EmptyOpenTaskList && OpenTasksTab" class="empty-result animated fadeInUp">
        <img src="../../images/icons/empty-result.svg" />
        <p>You dont have much information on this request. <br />Please add new task on this property.</p>
    </div>

    <!-- If no data on result -->
    <div v-if="EmptyCompletedTaskList && !OpenTasksTab" class="empty-result animated fadeInUp">
        <img src="../../images/icons/empty-result.svg" />
        <p>You dont have completed tasks yet.</p>
    </div>

    <!-- pop ups --> 
    <RightModal v-if="AddNewTaskModal" :form="'assign-tasks'" :data="AssignTasksData" @modalclose="CloseTaskModalBox" />
    <RightModal v-if="EditTaskModal == true" :form="'edit-tasks'" :data="EditTaskData" @modalclose="CloseEditModalBox" />
    <PropModal v-if="WantDeleteTask == true" :from="'confirm-delete-task'" :data="DeleteTaskData" @modalclose="CloseConfrimDelete" />
    <PropModal v-if="WantCompleteTask == true" :from="'confirm-complete-task'" :data="CompleteTaskData" @modalclose="CloseConfrimComplete" />

</template>

<script>
    //@ JS Modules
    import Document from '../../javascript/Document';
    import Handler from './../../javascript/Handler';
    import Request from './../../javascript/getRequest';
    import DataCenter from './../../javascript/DataCenter';

    import Skeletion from './../blocks/Skeleton.vue';

    //@ import components blocks
    import HtmlImage from './../blocks/HtmlImage.vue';
    import TaskList from './blocks/TaskList.vue';

    // Modal
    import RightModal from './../../components/modal/RightModal.vue';
    import PropModal from './../../components/modal/PropModal.vue';


    export default {
        name: 'Tasks',
        props: ['property', 'ownerProfile', 'employees', 'initTask'],
        emits: ['dischargeTask'],
        components: {
            Skeletion,

            HtmlImage,
            TaskList,

            RightModal,
            PropModal,
        },

        async mounted () {

            // site title and seo
            Document.DocumentHeadings ({title : ' Properties - Tasks'});

            if (this.initTask) {
                this.AddNewTasks();
            }

            // load units data from server
            let getdata = {
                key : 'property',
                value : this.property.id
            }
            
            const Tasks = await DataCenter.ProPRequest('property-tasks', false, getdata);
            this.LoadingTaskState = false;
            if (!Tasks) {

                this.TasksListed = false;
                this.EmptyTasksList = true;
                this.EmptyCompletedTaskList = true;
                this.EmptyOpenTaskList = true;

            }else {
                this.PropertyTasks = Tasks;

                this.TasksListed = true;
                this.EmptyTasksList = false;
                
                // init the tasks
                this.initTasks (); 
            }           

        },

        data () {
            return {
                OpenTasksTab : true, // false = Completed Tasks
                PropertyTasks : false,
                OpenTasks : [],
                CompletedTasks : [],

                // modal
                AddNewTaskModal : false,
                AssignTasksData : false,
                EditTaskModal : false,
                EditTaskData : false,

                // request setting
                LoadingTaskState : true,
                TasksListed : false,
                EmptyTasksList : false,
                EmptyCompletedTaskList : false,
                EmptyOpenTaskList : false,

                // update tasks
                DeleteTaskData : null,
                CompleteTaskData : null,
                WantDeleteTask : false,
                WantCompleteTask : false,
            }
        },

        methods: {
            // init the tasks
            initTasks () {
                this.EmptyOpenTaskList = false;
                this.EmptyCompletedTaskList = false;

                // filter the open tasks
                this.OpenTasks = this.PropertyTasks.filter(task => {
                    if (task.status == "Open") {
                        task.active = false;
                        return task;
                    }
                });

                if (this.OpenTasks.length <= 0) {
                    this.EmptyOpenTaskList = true;
                }

                // now filter 
                this.CompletedTasks = this.PropertyTasks.filter(task => {
                    if (task.status == "Completed") {
                        task.active = false;
                        return task;
                    }
                });

                if (this.CompletedTasks.length <= 0) {
                    this.EmptyCompletedTaskList = true;
                }
            },
            
            // clicked to the specific tasks
            TaskClicked (tasked) {
                if (this.OpenTasksTab) {
                    this.OpenTasks = this.OpenTasks.filter(task => {
                        task.active = false;
                    
                        if (task.id == tasked.id) {
                            task.active = true;
                        }

                        return task;
                    });
                }

                if (!this.OpenTasksTab) {
                    this.CompletedTasks = this.CompletedTasks.filter(task => {
                        task.active = false;
                    
                        if (task.id == tasked.id) {
                            task.active = true;
                        }

                        return task;
                    });
                }
            },

            // create new tasks
            AddNewTasks () {
                this.AssignTasksData = {
                    property : this.property,
                    owner   : this.ownerProfile,
                    employees : this.employees,
                    assign : false,
                };
                
                this.AddNewTaskModal = true;
            },

            CloseTaskModalBox (task) {
                if (task) {
                    if (!this.PropertyTasks) {
                        this.PropertyTasks = [];
                        this.PropertyTasks[0] = task;
                    }else {
                        this.PropertyTasks.push (task);
                    }

                    // update data
                    this.EmptyTasksList = false;
                    this.OpenTasksTab = true;
                    this.TasksListed = true;

                    // filter tenants
                    this.initTasks();

                    // update notification
                    Handler.PropNotification ('Your new task "' + task.title + '" has been successfully created.');
                }

                this.AddNewTaskModal = false;
                this.$emit('dischargeTask');
            },

            // edit task
            EditCurrentTask (editask) {
                this.EditTaskModal = true;
                this.EditTaskData = {
                    property : this.property,
                    owner   : this.ownerProfile,
                    task : editask
                };
            },

            CloseEditModalBox (task) {
                this.EditTaskModal = false;

                if (task) {

                    // update current task to the previous list
                    var NewListofTask = [];
                    this.PropertyTasks.filter (tsk => {
                        if (tsk.id == task.id) {
                            NewListofTask.push(task);
                        }else {
                            NewListofTask.push (tsk);
                        }
                    });

                    this.PropertyTasks = NewListofTask;

                    // filter tenants
                    this.initTasks();

                    // update notification
                    Handler.PropNotification ('Your new task "' + task.title + '" has been successfully updated.');
                }
            },

            // complete tasks
            CompleteTask (comptask) {
               this.WantCompleteTask = true;
               this.CompleteTaskData = comptask;
            },

            async CloseConfrimComplete (comptask) {
                if (comptask) {
                    let formData = new FormData();
                    formData.append('taskid', comptask.id);
                    await Request.postServer('property/task/complete.php', formData);

                    // transfter to completed tasks
                    this.PropertyTasks = this.PropertyTasks.filter(task => {
                        if (task.id == comptask.id) {
                            task.status = "Completed";
                        }return task;
                    });

                    // filter tasks
                    this.initTasks();

                    // update notification
                    Handler.PropNotification ('Your task "' + comptask.title + '" has been successfully marked as completed.');
                }

                this.WantCompleteTask = false;
            },

            // delete tasks
            DeleteTask (deltask) {
                this.WantDeleteTask = true;
                this.DeleteTaskData = deltask;
            },

            async CloseConfrimDelete (deltask) {
                if (deltask) {
                    let formData = new FormData();
                    formData.append('taskid', deltask.id);

                    const DeleteTask = await Request.postServer('property/task/delete.php', formData);
                    if (DeleteTask.success) {
                        this.PropertyTasks = this.PropertyTasks.filter(task => {
                            if (task.id == deltask.id) {
                            }else {
                                return task;
                            }
                        });
                    }

                    // filter tasks
                    this.initTasks();

                    // update notification
                    Handler.PropNotification ('Your task "' + deltask.title + '" has been successfully marked as archived.');
                }

                // close modal
                this.WantDeleteTask = false;
            }
        },

        filterTasks () {
            
        }


    }
</script>

<style scoped>
    div.internal-content {width: 100%;}
</style>