<script>

      import ReplaceImage from '../blocks/ReplaceImage.vue';

      export default {
            name: 'ViewAttachment',
            props: ['data'],
            emits: ['close'],
            components: {
                  ReplaceImage,
            },

            data () {
                  return {

                  }
            },

            mounted () {

            },

            methods: {

            }
      }
</script>

<template>
      <div class="image-viewer">
            <ReplaceImage :source="data.url" />
      </div>
</template>

<style scoped>
      div.image-viewer {
            width: 100%;
    height: 360px;
    object-fit: cover;
    overflow: hidden;
      }

      .imgrep {
            width: 100%;
      }
</style>