<template>
    <!-- style="top:-85px;left: -15px;" -->
    <div class="select-list1 animatedss fadeInUp" :style="distyle">
        <!-- If no data on result -->
        <div v-if="!ListData || ListData.length <= 0" class="empty-result">
            <img src="../../images/icons/empty-result.svg" />
            <p>No data in this list, Please make sure of property unit, tenant on units etc.</p>
        </div>

        <ul class="selectlist1 _propscrl">            
            <li class="transAe" :class="{ active : list.active}" v-for="list in ListData" :key="list.id" @click="ListSelect(list)">
                <div class="image">
                    <ReplaceImage :source="list.thumbnail" />
                </div>

                <div class="content" v-if="list.status == 'rented'">
                    <h3>{{list.tenant.fullname}}</h3>
                    <p>{{list.unit_name}}</p>
                </div>

                <div class="content" v-else>
                    <h3>{{list.unit_name}}</h3>
                    <p style="color: #919191;font-size: 9pt;line-height: 16pt;">unit is not rented yet.</p>
                </div>
            </li>
        </ul>
    </div>
    <div class="overlay" @click="closeList" style="opacity: 0;"></div>
</template>

<script>

    import ReplaceImage from '../blocks/ReplaceImage.vue';

    export default {

        name: 'UnitSelection',
        props: ['distyle', 'units'],
        emits: ['lstboxclse'],
        components: {
            ReplaceImage,
        },

        data () {
            return {
                ListData : this.units,
                selected : null,
            }
        },

        methods: {
            ListSelect (sl) {
                this.ListData = this.ListData.filter(list => {
                    list.active = false;

                    if (list.id == sl.id) {
                        list.active = true;
                        this.selected = list;
                    }

                    return list;
                });

                // close list with selection
                this.$emit('lstboxclse', this.selected);
            },

            closeList () {
                this.$emit('lstboxclse', this.selected);
            }
        }
    };
</script>

<style scoped>


div.empty-result {    padding: 25px;
    width: calc(100% - 50px);
    height: calc(100% - 50px);}
div.empty-result img {    width: 151px;
    margin-top: 45px;}
div.empty-result p {    line-height: 14pt !important;
    font-size: 10pt !important;;}



</style>