<template>
    <!-- Signup Form -->
    <section class="propstop-signup" id="propstop-signup">
        <div class="wrapper" :style="{'background-image':'url('+require('../../images/website/form-bg.jpg')+')'}">
            <h2>The WaitList for our beta launch is now open!</h2>
            <p>Ready to revolutionize your property management experience? Fill out your information below and join the PropStop revolution.</p>

            <ul class="signup">
                <li class="_propscrl">
                    <div class="website-form" v-if="!isWaitListCompleted">
                        <div class="input-field">
                            <p>First Name</p>
                            <input ref="firstname" type="text" v-model="firstname" placeholder="Jon">
                        </div>

                        <div class="input-field">
                            <p>Last Name</p>
                            <input ref="lastname" type="text" v-model="lastname" placeholder="Doe">
                        </div>

                        <div class="input-field">
                            <p>Email Address</p>
                            <input ref="email" type="text" v-model="email" placeholder="jon.doe@mail.com">
                        </div>

                        <div class="input-field">
                            <p>How many units are you managing?</p>
                            <select v-model="units">
                                <option selected value="1 - 10">1 - 10</option>
                                <option value="11 - 25">11 - 25</option>
                                <option value="26 - 50">26 - 50</option>
                                <option value="51+">51+</option>
                            </select>
                        </div>

                        <div v-if="AddMoreOwnerInfo">                            
                            <h3 style="font-size: 14pt;font-weight: 500;color: black;line-height: 24pt;margin: 15px 0;">Let us get to know you better!</h3>

                            <div class="input-field">
                                <p>Business Name</p>
                                <input ref="buzzname" type="text" v-model="buzzname" placeholder="ABC Group LLC">
                            </div>

                            <div class="input-field">
                                <p>Business Address</p>
                                <vue-google-autocomplete
                                    v-model="Address"
                                    ref="address"
                                    id="gogleaddress"
                                    classname="map-input-control"
                                    placeholder="Bussness or Your Full Address"
                                    data-type="len"
                                    data-max="10"
                                    v-on:placechanged="getAddressData"
                                    @keydown="getAddressStarted"
                                >
                                </vue-google-autocomplete>
                                <p class="_geo_error" v-if="InvalidLocation">This doesn't appear to be a valid address. <br />In order for Google to resolve your address, <br /> please start entering in the address.</p>
                            </div>

                            <div class="input-field">
                                <p>Mobile/Phone</p>
                                <input ref="buzzphone" type="text" v-model="buzzphone" placeholder="+1 818 555 1212">
                            </div>
                        </div>
        
                        <div class="input-field" style="position: relative;">
                            <span class="btn-submit" @click="CreateNewAccount" ref="createact">Join The Waitlist!</span>
                            <span style="width: 100%;top: 50px; cursor:pointer;" @click="this.signupError = false; this.userDuplicate = false;" v-if="userDuplicate || signupError" class="__dubplicate">{{ infoText }}</span>
                        </div>

                        <div style="text-align: center; margin-top: 15px;">
                            <img v-if="!AddMoreOwnerInfo" src="./../../images/icons/add-blue.svg" alt="Add Info" @click="AddMoreOwnerInfo = true;" style="width: 25px;height: 25px;object-fit: contain; cursor: pointer" />
                        </div>

                    </div>

                    <div v-else class="website-welcome">
                        <img src="./../../images/website/waitlist-thank-you.svg" alt="Waitlist" />
                        <h3>Thank you for your interest in PropStop!</h3>
                        <p>Thank you for your interest in PropStop! We can't wait to bring you on board and transform the way you manage or live in rental properties.</p>
                    </div>
                </li>
                <li>
                    <img src="./../../images/website/home_estate2_pic1.svg" alt="House Estate Pic" />
                </li>
            </ul>
        </div>
    </section>
    <!-- Signup Form -->
</template>

<script>
    //@import components
    import HtmlImage from './../blocks/HtmlImage.vue';
    import Request from './../../javascript/getRequest'; 

    // google address
    import VueGoogleAutocomplete from 'vue-google-autocomplete';

    export default {
        name : 'SignupForm',
        components : {
            HtmlImage,
            VueGoogleAutocomplete,
        },
        data () {
            return {
                firstname : '',
                lastname : '',
                email: '',
                password: '',
                units: '1 - 10',
                usertype: 'Owner',
                buzzname: '',
                Address: '',
                buzzphone: '',

                // google address
                InvalidLocation : false,
                GoogleLocationSelected: false,
                Country : '-1',
                City: '',
                ZipCode: '',
                gaddress : '',
                GoogleAddressValue : '',
                goelocation : {
                    location : {latitude : 0, longitude : 0},
                    country : '',
                    city : '',
                    zipcode : '',
                    state : '',
                    fulladdress : ''
                },

                AddMoreOwnerInfo : false,

                infoText: '',
                userDuplicate: false,
                signupError: false,

                isWaitListCompleted: false,
            }
        },

        methods: {

            getAddressStarted () {
                this.GoogleLocationSelected = false;
            },

            getAddressData: function (addressData, placeResultData, id) {
                let thisFieldID = document.getElementById('gogleaddress');

                // check if we get the postal code or not for real data
                if (addressData.postal_code == undefined || addressData.postal_code == null) {
                    this.InvalidLocation = true;
                    this.gaddress = '';
                    thisFieldID.value = '';
                    this.getAddressStarted ();

                }else {
                    this.InvalidLocation = false;
                    this.GoogleLocationSelected = true;
                    this.address = addressData;

                    // location
                    this.goelocation.location.latitude = addressData.latitude;
                    this.goelocation.location.longitude = addressData.longitude;

                    // other address
                    this.goelocation.country = addressData.country;
                    this.goelocation.city = addressData.locality;
                    this.goelocation.state = addressData.administrative_area_level_1;
                    this.goelocation.zipcode = addressData.postal_code;
                    this.goelocation.fulladdress = thisFieldID.value;

                    this.gaddress = thisFieldID.value;

                    // now update the address automatically
                    this.Country = addressData.country;
                    this.City = addressData.locality;
                    this.ZipCode = addressData.postal_code;
                }

            },

            validationCheck () {

                // dom registration
                let first, last, eml, pwd, buzzname, buzzaddress, buzzphone;
                first = this.$refs.firstname;
                last = this.$refs.lastname;
                eml = this.$refs.email;
                pwd = this.$refs.password;
                buzzname = this.$refs.buzzname;
                // buzzaddress = this.$refs.buzzaddress;
                buzzphone = this.$refs.buzzphone;

                // firstname validation
                if (this.firstname.length <= 2) {
                    first.classList.add('__error');
                    return false;
                }else {
                    first.classList.remove('__error');
                }


                // lastname validation
                if (this.lastname.length <= 2) {
                    last.classList.add('__error');
                    return false;
                }else {
                    last.classList.remove('__error');
                }

                if (this.AddMoreOwnerInfo) {

                    // business name validation
                    if (this.buzzname.length <= 3) {
                        buzzname.classList.add('__error');
                        return false;
                    }else {
                        buzzname.classList.remove('__error');
                    }

                    // business address validation
                    // if (this.buzzaddress.length <= 3) {
                    //     buzzaddress.classList.add('__error');
                    //     return false;
                    // }else {
                    //     buzzaddress.classList.remove('__error');
                    // }

                    // business phone validation
                    if (this.buzzphone.length <= 3) {
                        buzzphone.classList.add('__error');
                        return false;
                    }else {
                        buzzphone.classList.remove('__error');
                    }
                }

                // email validation
                var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (!pattern.test(this.email)) {
                    eml.classList.add('__error');
                    return false;
                }else {
                    eml.classList.remove('__error');
                }

                // // password validation
                // if (this.password.length <= 5) {
                //     pwd.classList.add('__error');
                //     return false;
                // }else {
                //     pwd.classList.remove('__error');
                // }

                return true;
            },
            
            // create new account, validation etc
            async CreateNewAccount () {

                // set user duplicate as false
                this.userDuplicate = false;
                this.signupError = false;
                this.infoText = '';

                if (this.AddMoreOwnerInfo && !this.GoogleLocationSelected) {
                    this.InvalidLocation = true;
                    return;
                }
                
                // check if validation is okay
                let canGoAhead = this.validationCheck ();
                
                // now push to server 
                if (canGoAhead) {
                    // create new formdata
                    let formData = new FormData();

                    // add datas
                    formData.append('firstname', this.firstname);
                    formData.append('lastname', this.lastname);
                    formData.append('email', this.email);
                    formData.append('units', this.units);
                    formData.append('usertype', this.usertype);

                    formData.append('buz-name', this.buzzname);
                    formData.append('buz-phone', this.buzzphone);
                    formData.append('buz-address', this.gaddress);
                    formData.append('buz-country', this.Country);
                    formData.append('buz-city', this.City);
                    formData.append('buz-zipcode', this.ZipCode);

                    this.$refs.createact.innerHTML = 'working...';

                    // request to server
                    const ReturnData = await Request.postServer('auth/signup.php', formData);
                    
                    // now check if the email already exists
                    if (ReturnData.success == false) {
                        if (ReturnData.reason == "duplicate") {
                            this.userDuplicate = true;
                            this.infoText = "This user is already registered with PropStop. Please try login with your email address " + this.email;
                            this.$refs.createact.innerHTML = 'Create Account';
                        }else {
                            this.signupError = true;
                            this.infoText = "Something went wrong! Please try again later or contact Support";
                            this.$refs.createact.innerHTML = 'Create Account';
                        }
                    }else if (ReturnData.success == true) {
                        
                        // go to login page
                        // this.$router.push({name : 'Auth'});
                        this.isWaitListCompleted = true;
                        this.$refs.createact.innerHTML = 'Create Account';


                    }else {
                        this.signupError = true;
                        this.infoText = "Something went wrong! Please try again later or contact Support";
                        this.$refs.createact.innerHTML = 'Create Account';
                    }

                }
            }
        }
    };
</script>