<template>
    <div class="select-list animatedss fadeInUp" style="top:-85px;left: -15px;">
        <ul class="_propscrl">            
            <li class="transAe" :class="{ active : list.active}" v-for="list in ListData" :key="list.id" @click="ListSelect(list)">
                <div class="image" :class="{ opctrented : list.status == 'rented'}">
                    <ReplaceImage :source="list.thumbnail" />
                </div>

                <div class="content" :class="{ opctrented : list.status == 'rented'}">
                    <h3>{{list.unit_name}}</h3>
                    <p v-if="list.status == 'rented'">{{list.tenant.fullname}}</p>
                    <p v-else style="color: #919191;font-size: 9pt;line-height: 16pt;">unit is not rented yet.</p>
                </div>
            </li>
        </ul>
    </div>
    <div class="overlay" @click="closeList" style="opacity: 0;"></div>
</template>

<script>

    import ReplaceImage from './../blocks/ReplaceImage.vue';

    export default {

        name: 'UnitSingleSelectInput',
        props: [ 'units'],
        emits: ['lstboxclse'],
        components: {
            ReplaceImage,
        },

        data () {
            return {
                ListData : this.units,
                selected : null,
            }
        },

        methods: {
            ListSelect (sl) {
                if (sl.status == "blank") {
                    this.ListData = this.ListData.filter(list => {
                        list.active = false;

                        if (list.id == sl.id) {
                            list.active = true;
                            this.selected = list;
                        }

                        return list;
                    });

                    // close list with selection
                    this.$emit('lstboxclse', this.selected);
                }
            },

            closeList () {
                this.$emit('lstboxclse', this.selected);
            }
        }
    };
</script>