<template>
    <div class="prop-modal animated fadeInUp" :class="from" :style="'width:' + ModalWidth + '%; left: ' + ModalLeftPosition + '%;'">
        <div class="gs--header">
            <img src="./../../images/icons/close.svg" @click="closeDialog(false)" />
        </div>

        <!-- Admin Property Verification  -->
        <PropertyVerify v-if="From == 'propert-verify'" :data="data" @close="closeDialog" />
        <PropertyDisputeInfo v-if="From == 'property-info'" :data="data" @close="closeDialog" />
        <OwnerVerify v-if="From == 'account-verify'" :data="data" @close="closeDialog" />

        <!-- Owner/Employee modals -->
        <FloorPlanModal v-if="From == 'floor-plans'" :data="data" @close="closeDialog" />
        <EditFloorPlanModal v-if="From == 'edit-floor-plan'" :data="data" @close="closeDialog" />
        <ModifyUnitsModal v-if="From == 'modify-units'" :data="data" @close="closeDialog" />
        <WelcomeOwner v-if="From == 'welcome-owner'" @close="closeDialog" />
        <OwnerMoveOutInfo v-if="From == 'complete-moveout'" @close="closeDialog" />
        <PropertyCreated v-if="From == 'property-created'" @close="closeDialog" />  
        <ViewAttachment v-if="From == 'view-tick-document'" :data="data" @close="closeDialog" />      

        <!-- confirmation dialoges -->
        <ConfirmDeleteParking v-if="From == 'confirm-delete-parking'" :data="data" @close="closeDialog" />
        <ConfirmDeleteFloorPlan v-if="From == 'confirm-delete-floorplan'" :data="data" @close="closeDialog" />
        <ConfirmUnassignParking v-if="From == 'confirm-unassign-parking'" :data="data" @close="closeDialog" />
        <ConfirmVerifyProperty v-if="From == 'confirm-verify-property'" :data="data" @close="closeDialog" />

        <ConfirmDeleteTask v-if="From == 'confirm-delete-task'" :data="data" @close="closeDialog" />
        <ConfirmCompleteTask v-if="From == 'confirm-complete-task'" :data="data" @close="closeDialog" />

        <ConfirmDuplicateProperty v-if="From == 'duplicate-property'" :data="data" @close="closeDialog" />
        <DuplicateDisputeProperty v-if="From == 'duplicate-dispute'" :data="data" @close="closeDialog" />

        <ConfirmDeleteTicket v-if="From == 'confirm-archive-ticket'" :data="data" @close="closeDialog" />
        <ConfirmCloseTicket v-if="From == 'confirm-close-ticket'" :data="data" @close="closeDialog" />

        <!-- User Profile Modals custom for tenant, employee and all -->
        <ChangePassword v-if="From == 'change-password'" :data="data" @close="closeDialog" />
        <AdminChangePassword v-if="From == 'admin-change-password'" :data="data" @close="closeDialog" />
        <UpdateRent v-if="From == 'admin-update-rent'" :data="data" @close="closeDialog" />

        <!-- upload documents -->
        <AttachDocuments v-if="From == 'upload-document'" :data="data" @close="closeDialog" />
        <ViewDocuments v-if="From == 'view-document'" :data="data" @close="closeDialog" />

        <!-- unit unassigned -->
        <ConfirmUnassignTenant v-if="From == 'confirm-unassign-tenant'" :data="data" @close="closeDialog" />

        <!-- unit gallery select -->
        <!-- <UnitImageSelect v-if="From == 'unit-image-update'" :data="data" @close="closeDialog" />  -->

        <!-- Tenant -->
        <RentChangesVue v-if="From == 'rent-changes'" :data="data" @close="closeDialog" />
        <RentHistoryVue v-if="From == 'rent-history'" :data="data" @close="closeDialog" />
        <ConvertToEmailVue v-if="From == 'no-emailto-email'" :data="data" @close="closeDialog" />
        <FormerTenantFinanceHistory v-if="From == 'show-tenat-history'" :data="data" @close="closeDialog" />
        <SearchFilterModel v-if="From == 'search-filter'" :data="data" @close="closeDialog" />

        <!-- Website modal -->
        <ContactUs v-if="From == 'contact-modal'" :data="data" @close="closeDialog" />

    </div>
    <div class="overlay" @click="closeDialog(false)"></div>
</template>

<script>
    
    // admin modal import
    import PropertyVerify from './../admin/modal/PropertyVerify.vue';
    import OwnerVerify from './../admin/modal/OwnerVerify.vue';
    import PropertyDisputeInfo from '../admin/modal/PropertyDisputeInfo.vue';

    // owner/employee modals 
    import FloorPlanModal from './../modal/FloorPlanModal.vue';
    import EditFloorPlanModal from './../modal/EditFloorPlanModal.vue';
    import ModifyUnitsModal from './../modal/ModifyUnitsModal.vue';
    import WelcomeOwner from './../modal/WelcomeOwner.vue';
    import OwnerMoveOutInfo from './OwnerMoveOutInfo.vue';
    import PropertyCreated from './../modal/PropertyCreated.vue';
    import ViewAttachment from './ViewAttachment.vue';

    // confirmation dialog
    import ConfirmDeleteParking from './../modal/confirm/ConfirmDeleteParking.vue';
    import ConfirmDeleteFloorPlan from './../modal/confirm/ConfirmDeleteFloorPlan.vue';
    import ConfirmUnassignParking from './../modal/confirm/ConfirmUnassignParking.vue';
    import ConfirmVerifyProperty from './../modal/confirm/ConfirmVerifyProperty.vue';

    import ConfirmDeleteTask from './../modal/confirm/ConfirmDeleteTask.vue';
    import ConfirmCompleteTask from './../modal/confirm/ConfirmCompleteTask.vue';

    import ConfirmDuplicateProperty from './confirm/ConfirmDuplicateProperty.vue';
    import DuplicateDisputeProperty from './confirm/DuplicateDisputeProperty.vue';

    import ConfirmDeleteTicket from './../modal/confirm/ConfirmDeleteTicket.vue';
    import ConfirmCloseTicket from './../modal/confirm/ConfirmCloseTicket.vue';

    // profile modal content
    import ChangePassword from './../modal/profile/ChangePassword.vue';
    import AdminChangePassword from './../modal/admin/ChangePassword.vue';
    import UpdateRent from './admin/UpdateRent.vue';

    // attachment
    import AttachDocuments from './../modal/AttachDocuments.vue';
    import ViewDocuments from './../modal/ViewDocuments.vue';

    import ConfirmUnassignTenant from './../modal/confirm/ConfirmUnassignTenant.vue';

    // unit gallery images
    import UnitImageSelect from './../modal/UnitImageSelect.vue';
    import RentChangesVue from './RentChanges.vue';
    import RentHistoryVue from './RentHistory.vue';
    import ConvertToEmailVue from './ConvertToEmail.vue';
    import FormerTenantFinanceHistory from './FormerTenantFinanceHistory.vue';
    import SearchFilterModel from './SearchFilterModel.vue'

    // homepage modal
    import ContactUs from './website/ContactUs.vue';

    

    export default {
        name: 'PropModal',
        props: ['from', 'data'],
        emits: ['modalclose'],
        components: {
            PropertyVerify,
            OwnerVerify,
            PropertyDisputeInfo,

            // owner or employee modal data
            FloorPlanModal,
            EditFloorPlanModal,
            ModifyUnitsModal,
            WelcomeOwner,
            OwnerMoveOutInfo,
            PropertyCreated,
            ViewAttachment,

            // confirmation
            ConfirmDeleteParking,
            ConfirmDeleteFloorPlan,
            ConfirmUnassignParking,
            ConfirmVerifyProperty,
            ConfirmDeleteTask,
            ConfirmCompleteTask,
            ConfirmDuplicateProperty,
            DuplicateDisputeProperty,
            ConfirmDeleteTicket,
            ConfirmCloseTicket,

            // amdin user profile modals
            ChangePassword,
            AdminChangePassword,
            UpdateRent,
            
            // attachment
            AttachDocuments,
            ViewDocuments,
            ConfirmUnassignTenant,
            UnitImageSelect,
            RentChangesVue,
            RentHistoryVue,
            ConvertToEmailVue,
            FormerTenantFinanceHistory,
            SearchFilterModel,
            OwnerMoveOutInfo,

            // website
            ContactUs,
        },

        data () {
            return {
                From : this.from,
                ModalWidth : 40, // default width
                ModalLeftPosition : 30,
            }
        },

        mounted () {

            if (this.From == 'modify-units') {
                this.ModalWidth = 70;
            }else if (this.From == 'welcome-owner' || this.From == 'contact-modal') {
                this.ModalWidth = 35;
            }else if (this.From == 'floor-plans' || this.From == 'edit-floor-plan' || this.From == 'search-filter') {
                this.ModalWidth = 70;
            }else if (this.From == 'view-document') {
                this.ModalWidth = 70;
            }else if (this.From == 'unit-image-update') {
                this.ModalWidth = 41;
            }else if (this.From == 'property-created' || this.From == 'propert-verify' || this.From == 'account-verify' || this.From == 'duplicate-property' || this.From == 'property-info') {
                this.ModalWidth = 50;
            }else if (this.From == 'show-tenat-history') {
                this.ModalWidth = 75;
            }

            // calculate the position of the modal from left/right
            this.ModalLeftPosition = (100 - this.ModalWidth) / 2;

        },

        methods: {
            // close confirm dialog
            closeDialog (action) {
                this.$emit('modalclose', action);
            }
        }
    };
</script>