<template>
     <div class="owner-nav" :style="SidebarHamClick ? 'left: 0px !important;box-shadow: 0px 0px 10px rgb(0 0 0 / 33%) !important;' : ''">
        <div class="logo-content">
          <img class="logo-screen" src="@/images/company/logo-white.png" />
          <img class="logo-mobile" src="@/images/company/resp-logo.png" />
        </div>

        <div class="moveout-process" v-if="isOnMoveOutProcess">
            <img class="info" src="./../../images/icons/info-icon.svg" />
            <p>There is move-out process running. Please check tickets!</p>
            <img class="close" @click="isOnMoveOutProcess = false" src="./../../images/icons/close-circled.svg" />
        </div>

        <ul class="navigation">
            <OwnerSideNav v-for="list in navigationlist" :key="list.key" :current="list" @navchange="NavigationClicked" />
        </ul>        

        <div class="owrprtties" id="ownerprtities" v-if="OwnerProperties.length >= 1" @click="isPropertySelection = true">
            <div class="image">
                <ReplaceImage :source="SelectedProperty.image" :key="''" />
            </div>
            <div class="content">
                <h3>{{SelectedProperty.name}}</h3>
                <p>{{SelectedProperty.address}}</p>
            </div>
        </div>

        <div v-else class="sk-owrprtties">
            <Skeletion :type="'user-list'" :data="1" />
        </div>

        <div v-if="isPropertySelection">
            <PropertyList :properties="OwnerProperties" @prosel="PropertyChoosed" @proboxclse="propertylistClose" />
        </div>

    </div>

    <div class="owner-content">
        <div class="content-header">
            <div class="ham-side-menu" @click="SidebarHamClick = true">
                <img src="./../../images/icons/line-menu.svg" />
            </div>

            <ul>
                <li style="display: flex;align-items: center;">
                    <h3>{{CurrentPageName}}</h3>
                    <img src="./../../images/icons/add-blue.svg" id="AppMainAddActionIcon" style="width: 20px;height: 20px;margin-left: 7px;cursor:pointer;" v-if="CurrentPageHasAction" />
                </li>

                <li style="position: relative;">
                    <Search :page="CurrentPageName" :key="CurrentPageName" />
                </li>
                <li>
                    <div v-if="OwnerProfile" class="owner">
                        <div class="notification" @click="ownerNotification = true">
                            <img src="@/images/icons/notification.svg" />
                            <span id="NotificationChanges" style="display: none;">0</span>
                        </div>

                        <div class="user" style="cursor: pointer;" @click="userProfileActive = true">
                            <h3>Hi, {{OwnerProfile.fname}}</h3>
                            <div class="avatar">
                                <UserImage :init="OwnerProfile.fullname" :back="OwnerProfile.back" :source="OwnerProfile.avatar" style="border-radius:50%" />
                            </div>
                        </div>
                        <OwnerProfilePopup v-if="userProfileActive" :user="OwnerProfile" @profileclose="closeProfilepopup" @pprofile="PersonalProfile" @bprofile="BusinessProfile" />
                    </div>
                </li>
            </ul>
        </div>

        <div class="page-content" id="page-content">
            <!-- Dynamic Loading via Ajax -->
            <router-view 
                    :profile="OwnerProfile"
                    :properties="OwnerProperties"
                    :employees="OwnerEmployees"
                    :selected="SelectedProperty"

                    @updateproperties="UpdateProperties"

                    :key="ownerprofileloaded"></router-view>
            <!-- Dynamic Loading via Ajax -->
        </div>
    </div>

    <!-- Notification -->
    <Notifications v-if="ownerNotification" :accounttype="'owner'" @notificlose="closeNotification" />

    <!-- pop ups --> 
    <RightModal v-if="UpdateOwnerProfile" :form="'owner-profile-complete'" @modalclose="CloseModalBox" :data="OwnerProfile" />
    <RightModal v-if="BusinessProfileModal" :form="'owner-business-profile'" @modalclose="CloseBusinessModal" :data="OwnerProfile" />

    <!-- Welcome to propstop pop-ups -->
    <PropModal v-if="WelcomeToOwner == true" :from="'welcome-owner'" @modalclose="CloseWelcomeModal" />
    <PropModal v-if="CompleteMoveOut == true" :from="'complete-moveout'" @modalclose="CloseCompleteMoveOut" />

    <AccountChanges accountype='owner' />

    <Support :key="ownerprofileloaded" :count="AssistanceMessages" />

</template>

<script>

    //@import JavaScript
    import DataCenter from './../../javascript/DataCenter';
    import Storage from './../../javascript/Storage';

    // pinia
    import { useOwnerStore } from '../../stores/OwnerStore';

    //@import component
    import Skeletion from './../blocks/Skeleton.vue';
    import ReplaceImage from './../blocks/ReplaceImage.vue';
    import OwnerSideNav from './../owner/OwnerSideNav.vue';
    import HtmlImage from './../blocks/HtmlImage.vue';
    import UserImage from './../blocks/UserImage.vue';
    import OwnerProfilePopup from './../owner/OwnerProfilePopup.vue';
    import Search from './../owner/dashboard/Search.vue';

    import PropertyList from './../blocks/PropertyList.vue';

    import Notifications from './../reusable/Notifications.vue';
    import AccountChanges from './../reusable/PropStopAccountChanges.vue';

    // support
    import Support from "./../support/Support.vue";

    // Modal
    import RightModal from './../modal/RightModal.vue';
    import PropModal from './../modal/PropModal.vue';

    export default {
        name : 'OwnerDashboard',
        components : {
            Skeletion,
            ReplaceImage,
            OwnerSideNav,
            HtmlImage,
            UserImage,
            OwnerProfilePopup,
            Search,
            PropertyList,

            Notifications,
            Support,

            RightModal,
            PropModal,

            AccountChanges,
        },

        data () {
            return {
                // owner properties & profile global
                OwnerProfile : false,
                OwnerProperties : [],
                OwnerEmployees : [],
                SelectedProperty : {},
                isPropertySelection : false,
                AssistanceMessages: 0,

                ownerprofileloaded : false,

                // Update owner if profile not verified
                UpdateOwnerProfile : false,
                WelcomeToOwner : false,

                userProfileActive : false,
                ownerNotification : false,

                propertySelected : null,
                
                // Owner Dashboard Navigation List
                navigationlist : [
                    {
                        key  : 1,
                        name : 'Dashboard',
                        route : 'owner-dashboard',
                        icon : 'images/icons/dashboard.svg',
                        hasAction: false,
                        active : true
                    },
                    {
                        key  : 2,
                        name : 'Properties',
                        route : 'owner-properties',
                        icon : 'images/icons/properties.svg',
                        hasAction: true,
                        active : false
                    },
                    {
                        key  : 3,
                        name : 'Tickets',
                        route : 'owner-tickets',
                        icon : 'images/icons/tickets.svg',
                        hasAction: true,
                        active : false
                    },
                    {
                        key  : 4,
                        name : 'Tenants',
                        route : 'owner-tenants',
                        icon : 'images/icons/tenants.svg',
                        hasAction: true,
                        active : false
                    },
                    {
                        key  : 5,
                        name : 'Employees',
                        route : 'owner-employees',
                        icon : 'images/icons/employees.svg',
                        hasAction: true,
                        active : false
                    },
                    {
                        key  : 6,
                        name : 'Finance',
                        route : 'owner-finance',
                        icon : 'images/icons/finance.svg',
                        hasAction: true,
                        active : false
                    },
                    /*
                    {
                        key  : 7,
                        name : 'Reports',
                        route : 'owner-reports',
                        icon : 'images/icons/reports.svg',
                        active : false
                    }
                    */
                ],

                // page name
                CurrentPageName : 'Dashboard',
                CurrentPageHasAction: false,

                // business profile view/update
                BusinessProfileModal : false,

                // responsive
                SidebarHamClick : false,

                // moveout
                CompleteMoveOut : false,
                isOnMoveOutProcess: false,

            }
        },

        async mounted () {

            // pinia setup
            const OwnerStore = useOwnerStore ();
            
            // now load current user info if verified or not
            const RequestData = await DataCenter.ProPRequest('owner-profile', false, false);
            if (!RequestData) {}else {
                this.OwnerProfile = RequestData.profile;
                this.isOnMoveOutProcess = RequestData.moveout;
                this.OwnerProperties = RequestData.properties;
                this.OwnerEmployees = RequestData.employees;
                this.SelectedProperty = this.OwnerProperties[0];

                // update pinia
                OwnerStore.user = RequestData.profile;
                OwnerStore.properties = RequestData.properties;
                OwnerStore.employees = RequestData.employees;
                OwnerStore.propselected = RequestData.properties[0];

                
                // walkthrough settings
                OwnerStore.walkthrough.property = RequestData.walkthrough;
                
                this.AssistanceMessages = RequestData.messages;
                this.ownerprofileloaded = true;

                console.log (RequestData.messages);

                // profile verification
                if (this.OwnerProfile.verification == "true") this.UpdateOwnerProfile = true;

                // if the owner is verified and is on the first login
                if (this.OwnerProfile.verification == "false" && this.OwnerProfile.verified == "true" && this.OwnerProfile.welcome == "true") this.WelcomeToOwner = true;

                // update notifications
                let ElTickets = document.getElementById('TicketsChanges');
                let t  = this.OwnerProfile.changes.tickets;
                ElTickets.innerHTML = t;
                if (t <= 0) ElTickets.style.display = 'none';
                else ElTickets.style.display = 'block';
            }
            
        },        

        methods : {

            CloseCompleteMoveOut () {
                this.CompleteMoveOut = false;
            },

            // property selected
            PropertyChoosed (property) {
                this.SelectedProperty = property;
                this.ownerprofileloaded = property.id; // key change

                //this.currentPropertyImageKey = property.id;

                // now close the list too
                this.propertylistClose ();

                // remove sidebar menu
                this.SidebarHamClick = false;
            },

            // close property list
            propertylistClose () {
                this.isPropertySelection = false;
            },

            // close the profile complete modal
            // it can't be close 
            CloseModalBox () {
                // do nothing for now

            },

            // update properties emit function
            UpdateProperties (properties) {
                this.OwnerProperties = properties;

                // now select one which is just added
                var lastproperty = this.OwnerProperties.length;
                this.SelectedProperty = this.OwnerProperties[lastproperty - 1];
            },

            CloseBusinessModal () {
                this.BusinessProfileModal = false;
            },

            // update to server and close the modal
            async CloseWelcomeModal () {
                this.WelcomeToOwner = false;
                await DataCenter.ProPRequest('owner-welcome', false, false);
            },

            // change the active state on the navigation
            NavigationClicked (nav) {
                this.navigationlist = this.navigationlist.filter(list => {
                    list.active = false;
                
                    if (list.key == nav.key) {
                        list.active = true;
                    }

                    return list;
                });

                this.CurrentPageName = nav.name;
                this.CurrentPageHasAction = nav.hasAction;

                // now route to the acitve one
                this.$router.push({name : nav.route});

                // remove sidebar menu
                this.SidebarHamClick = false;

                // pinia
                const OwnerStore = useOwnerStore ();
                OwnerStore.route.page = nav.name;
            },

            PersonalProfile () {
                this.closeProfilepopup ();

                // rmeove active on the nav
                this.navigationlist = this.navigationlist.filter(list => {
                    list.active = false; return list;
                });

                // change the route to profile page
                this.$router.push({name : 'owner-profile'});

                // name on header
                this.CurrentPageName = "Profile";
            },

            BusinessProfile () {
                this.closeProfilepopup ();

                this.BusinessProfileModal = true;

            },

            closeProfilepopup () {
                this.userProfileActive = false;
            },

            closeNotification () {
                this.ownerNotification = false;
            },
        }
    };
</script>


<style scoped>
/* this page css */
.sk-owrprtties {    position: absolute;
    width: 90%;
    margin: 0px auto;
    height: auto;
    bottom: 25px;
    left: 5%;}
    .sk-owrprtties span {    background: #474a85 !important;
    border: 1px solid #449adf !important;}
    .sk-owrprtties .sk-image span {    height: calc(100% - 2px) !important;}
/* this page css */
</style>