<template>
    <!-- Footer -->
    <footer>
        <div class="wrapper ftrwraper">
            <ul class="footer-nav">
                <li>
                    <img src="../../images/website/propstop-footer-logo.svg" alt="PropStop" title="PropStop" />
                    <p>PropStop Inc. <br />2470 Stearns St., Suite 214 <br />Simi Valley, CA 93603</p>
                    <div class="social" style="display: none;">
                        <a href="#" target="_blank"><img src="../../images/website/twitter.svg" alt="Twitter" title="Twitter" /></a>
                        <a href="#" target="_blank"><img src="../../images/website/facebook.svg" alt="Facebook" title="Facebook" /></a>
                        <a href="#" target="_blank"><img src="../../images/website/linkedin-rect.svg" alt="LinkedIn" title="LinkedIn" /></a>
                        <a href="#" target="_blank"><img src="../../images/website/mail.svg" alt="Mail" title="Mail" /></a>
                    </div>
                </li>
                <li>
                    <ul>
                        <li><router-link :to="{name : 'home-page'}">PropStop</router-link></li>
                        <li><router-link :to="{name : 'about-us'}">About Us</router-link></li>
                        <li><router-link :to="{name : 'web-features'}">Features</router-link></li>
                        <li><router-link :to="{name : 'web-tenants'}">Tenants</router-link></li>
                        <li><router-link :to="{name : 'web-landlords'}">Landlords</router-link></li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li>
                            <router-link :to="{name : 'terms-conditions'}">Terms of Use</router-link>
                        </li>

                        <li>
                            <router-link :to="{name : 'privacy-policy'}">Privacy Policy</router-link>
                        </li>

                        <li>
                            <router-link :to="{name : 'cookie-policy'}">Cookie Policy</router-link>
                        </li>
                    </ul>
                </li>

                <li class="subscribe">
                    <h3>Stay Updated</h3>
                    <p>Be the first to know about PropStop updates, insights, and tips for efficient property management. Subscribe to our newsletter and never miss out.</p>
                    <input type="text" placeholder="Enter your email" v-model="Email">
                    <p v-if="EmailError" style="color: red;">{{ ErrorMessage }}</p>
                    <span class="btn-primary" @click="Subscribe()">Subscribe</span>
                </li>

            </ul>
        </div>
    </footer>

    <section class="pos-footer">
        <div class="wrapper" style="padding-top: 0;">
            <p>Ⓒ 2024 {{ CurrentYear }} Inc. All Rights Reserved</p>
        </div>
    </section>
    <!-- Footer -->
</template>

<script>

    import Request from '../../javascript/getRequest';

    export default {
        name : 'Footer',
        data () {
            return {
                CurrentYear: '2024',
                Email: '',
                EmailError: false,
                ErrorMessage: '',
            }
        },

        mounted () {
            var Year = new Date();
            this.CurrentYear = Year.getFullYear();
        },

        methods: {
            Subscribe () {
                this.EmailError = false;                
                var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (!pattern.test(this.Email)) {
                    this.EmailError = true;                
                    this.ErrorMessage = "Email didn't matched!";
                }else {
                    this.UpdateSubscription ();
                }
            },

            async UpdateSubscription () {
                let formData = new FormData();
                formData.append('email', this.Email);
                const ReturnData = await Request.postServer('public/subscribe.php', formData, null);
                this.EmailError = true;
                this.Email = '';
                this.ErrorMessage = ReturnData.message;

            }
        }
    };
</script>

<style>

    ul.footer-nav li.subscribe {    width: 340px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;}
    ul.footer-nav li.subscribe h3 {    font-size: 14pt;
    line-height: 24pt;
    color: black;}
    ul.footer-nav li.subscribe p {    font-size: 10pt;
    font-weight: 500;
    letter-spacing: 0.15px;
    color: #5b5b5b;
    margin: 8px 0;
    line-height: 18pt;}
    ul.footer-nav li.subscribe input {    width: calc(100% - 22px);
    height: auto;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #c4d5ef;
    border-radius: 4px;
    font-size: 11pt;
    color: #000;
    margin-bottom: 10px;}
    ul.footer-nav li.subscribe span {    width: 80px;
    border-radius: 4px;
    text-align: center;
    font-size: 10pt;
    letter-spacing: 0.25px;
    color: white;
    font-weight: 500;
    float: left;
    margin: 0;}


</style>