<script>

      import FormValidation from '../../../javascript/FormValidation';
      import Request from '../../../javascript/getRequest';

      export default {
            name: 'ContactUs',
            props: ['data'],
            emits: ['close'],
            data () {
                  return {
                        Requesting: false,
                        FormError: false,
                        infoText: '',

                        // form data
                        Fullname: '',
                        Email: '',
                        Mobile: '',
                        Query: ''
                  }
            },

            methods: {


                  async SubmitQuery () {
                        if (this.Requesting) return;
                        this.formError = false;

                        // form & validation
                        let form = document.getElementById('propform-contact');
                        let canGoAhead = FormValidation.Validate (form);
                        if (canGoAhead) {
                              this.Requesting = true;

                              let formData = new FormData();

                              formData.append('fullname', this.Fullname);
                              formData.append('email', this.Email);
                              formData.append('mobile', this.Mobile);
                              formData.append('query', this.Query);

                              // upload and load data from server
                              const ReturnData = await Request.postServer('public/contact.php', formData);
                              this.Requesting = false;

                              // now check if the email already exists
                              if (ReturnData.error == false) {
                                    // now close modal, and load current property
                                    this.$emit('close', true);
                              }else {
                                    this.formError = true;
                                    this.infoText = "Something went wrong! Please try again.";
                              }

                        }
                  }
            }

      }
</script>

<template>
      <div class="gs--body">
          <h3 style="margin-top: -30px; margin-bottom: -5px;">Contact Us</h3>        
          <p class="info-text">Want to get in touch? We'd love to here from you. </p>
          <p style="font-size: 10pt;">* Indicate a required field</p>

          <div class="form-area" style="padding-bottom: 10px; padding-top: 10px; width: 100%">
                  <form name="propform" id="propform-contact" style="display: block;">
                        <div class="pf--input">
                              <p>Fullname *</p>
                              <input type="text" v-model="Fullname" placeholder="i.e Jon Doe" id="Fullname" data-type="len" data-max="3" />
                        </div>

                        <div class="pf--input">
                              <p>Email *</p>
                              <input type="text" v-model="Email" placeholder="i.e mail@jondoe.com" id="Email" data-type="email" />
                        </div>

                        <div class="pf--input">
                              <p>Mobile Number</p>
                              <input type="text" v-model="Mobile" placeholder="Mobile (optional)" data-type="len" data-max="5" />
                        </div>

                        <div class="pf--input">
                              <p>Inquiry *</p>
                              <textarea placeholder="Describe issues" v-model="Query" style="height: 95px" id="Query" data-type="len" data-max="5"></textarea>
                        </div>

                  </form>
            </div>
  
      </div>
  
      <div class="gs--action">  
          <span class="primary-btn-loader" style="padding: 7px 15px !important;" :class="Requesting ? 'btn-disabled' : ''" @click="SubmitQuery">
              <p v-if="!Requesting">Submit</p>
              <img v-else class="loader" src="./../../../images/gif/btn-loader.gif" />
          </span>  
      </div>
</template>