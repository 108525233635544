<template>
    <div class="content">
        <div class="summery">
            <h3>Finance</h3>
            <h4 v-if="!requesting">{{ Finance.month }}</h4>

            <div class="seprate"></div>

            <p>Average Monthly Income</p>
            <h2 v-if="requesting">$0.00</h2>
            <h2 v-else>${{ Finance.average }}</h2>

            <div class="prev" v-if="requesting"></div>

            <div class="prev" v-else>
                <img v-if="Changes.trend == 'up'" src="../../../images/icons/arrow-up-right.svg" />
                <img v-else src="../../../images/icons/arrow-down-right.svg" />
                <strong :style="Changes.trend == 'down' ? 'color: rgb(226 87 39);' : ''">{{ Percentage + '%' }} </strong>
                <p> vs Previous Month</p>
            </div>
        </div>

        <div class="linechart">
            <div id="monthly-finance-data"></div>
        </div>
    </div>

    <div class="footer">
        <ul>
            <li class="income">
                <div class="icon">
                    <img src="../../../images/icons/icon-income.svg" />
                </div>
                <div class="content">
                    <h2 v-if="requesting">$0.00</h2>
                    <h2 v-else>${{ Finance.income }}</h2>
                    <p>Total Income</p>
                </div>
            </li>

            <li class="expenses">
                <div class="icon">
                    <img src="../../../images/icons/icon-expenses.svg" />
                </div>
                <div class="content">
                    <h2 v-if="requesting">$0.0</h2>
                    <h2 v-else>${{ Finance.expense }}</h2>
                    <p>Total Expenses</p>
                </div>
            </li>

        </ul>
    </div>
</template>

<script>

    // pinia
    import { useOwnerStore } from '../../../stores/OwnerStore';

    export default {
        name: 'Finance',
        props: ['requesting'],
        components: {},
        data() {
            return {
                Finance: {},
                Changes: {},
                Percentage: 0,
                Requesting: true,
            }
        },
        mounted () {
            
            // pinia setup
            if (!this.requesting) {
                const OwnerStore = useOwnerStore ();
                this.Finance = OwnerStore.dashboard.finance;
                this.dashboardHighChartHandlers (this.Finance.incomes);

                this.Changes = this.Finance.change;
                this.Percentage = this.Changes.percent.toFixed(2);
            }

        },
        methods: {
            dashboardHighChartHandlers (incomes) {
                Highcharts.chart('monthly-finance-data', {
                    chart: {
                        type: 'areaspline',
                        style: {
                            fontFamily: "'font-family: ''Montserrat', sans-serif;"
                        }
                    },

                    title: {
                        text: ''
                    },

                    subtitle: {
                        text: ''
                    },

                    xAxis: {                        
                        labels: {
                            formatter: function () {
                                return this.value / 1000 + 'k';
                            }
                        }
                    },

                    yAxis: {
                        labels: {
                            formatter: function () {
                                return this.value / 1000 + 'k';
                            }
                        }
                    },

                    tooltip: {
                        backgroundColor: '#fff',
                        shadow: true,
                        useHTML: true,
                        padding: 5,
                        pointFormat: '<p style="padding:10px"><b style="font-size:14px">${point.y}</b><br><span style="font-size:14px">{point.name}</span></p>'
                    },
                    credits: {
                        enabled: false
                    },

                    series: [{
                        name: 'Monthly Income',
                        data: incomes,
                        fillColor: {
                            linearGradient: [0, 100, 200, 360],
                            stops: [
                                [0, '#a6cee3'],
                                [1, '#5fa7fa']
                            ]
                        }
                    }]
                });
            }
        }
    };

</script>