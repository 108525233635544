<script>

    // scripts
    import Handler from '../../javascript/Handler';    

      // import Website Navigation
      import WebNav from './../../components/website/WebNav.vue';
      import Footer from './../../components/website/Footer.vue';
      
      import PropModal from '../../components/modal/PropModal.vue';
  
      export default {
          name : 'PrivacyPolicy',
          components : {
              WebNav,
              Footer,

              PropModal
          },

          data () {
            return {

                ContactUsModal: false,

            }
          },
  
          mounted () {
              // scroll to top
              window.scrollTo(0, 0);
  
              // remove styling used for apps
              document.getElementById('propstop-app').style.height = 'auto';
              document.getElementById('propstop-app').style.overflow = 'auto';
  
              //  change the title
              document.title = "PropStop - Polices";
  
          },

          methods: {

            CloseContactModal (succes) {
                this.ContactUsModal = false;

                // update notification
                if (succes) Handler.PropNotification ('Thank You! Your request has been received and a PropStop team member will follow up with you soon.');

            }

          }
      }
</script>

<template>
      <WebNav />
  
      <!-- Internal page breadcrumbs -->
      <section class="internalcrumbs">
          <div class="wrapper" style="overflow: initial; padding: 25px 0;">
              <ul>
                  <li><a href="/"><img src="../../images/icons/home.svg" alt="Home"></a></li>
                  <li><img src="../../images/icons/chevron-right.svg" alt="Right"></li>
                  <li style="width:auto;"><p>Privacy Policies</p></li>
              </ul>
          </div>
      </section>
      <!-- Internal page breadcrumbs -->
  
      <!-- Privacy Policy Content -->
      <section class="_policies_page">
          <div class="wrapper" style="padding-top: 0; padding-bottom: 0;">
  
              <h3>PropStop, Inc. Privacy Policy</h3>
  
              <h2>AGREEMENT.</h2>
  
              <p>This website is owned and operated by PropStop, Inc. (“PropStop” “we” and “us”). Your privacy is important to us. This Privacy Policy describes how we collect, use and share personal information of those who use the website located at <a href="https://www.propstop.com">https://www.propstop.com</a> and the related application for mobile devices (collectively the “Website”) and those who use the Company’s services made available through the Website (the “Services”). This Privacy Policy applies to information we obtain through your use of the Website only. Except as expressly provided herein, this Privacy Policy does not apply to services or websites provided by third parties, including third party service providers. By using this Website or Services, directly or through an advertisement or linking from a third party site, you agree to be bound by the terms of this Privacy Policy, including your consent to our use and disclosure of information about you as required under current federal, state, and local law, as well as in the manner described below. Your use of this Website is voluntary. If you do not provide the information we require, we may not be able to provide you Services.</p>
  
              <h2>YOU HAVE READ OUR TERMS OF USE.</h2>
  
              <p>Any disputes arising from use of the Website or PropStop Services, is subject to this Privacy Policy as well as the Website and all the Terms of Use dispute resolution provisions, including binding arbitration, limitation on damages, choice of law and venue. The Website is intended only for use in and by residents of the United States who are 18 years and older.</p>
  
              <h2>INFORMATION WE MAY COLLECT.</h2>
  
              <p>We collect personally identifiable information that can identify you such as your name, address, business information, telephone number, mobile number, e-mail address, property records, residency information, household data, service requests, property contracts, images (regular and geotagged), location data, public records, credit card information (to facilitate the collection of payment), credit reports, consumer reporting agency reports, certifications and any applicable licenses you may have ("Personally Identifiable Information" or "PII"). We may collect and store account information and financial information and associate it with your PropStop account to improve or personalize our Services. We also collect information such as demographic data and data about your online activity on the Website that does not identify you ("Non-Personally Identifiable Information" or "Non-PII"). We may share Non-PII with third parties at our discretion. PII and Non-PII are known together as "Information". We may also collect and store Information that you provide to us about other people (such as property co-owners, managers and tenants), including their name, address, telephone number and email address. We may process and retain your PII on our servers in the U.S. and elsewhere. We respect your privacy; therefore we never share or sell your PII without your consent. PII collected through the Website is intended to be used in your transaction only. Your personal information is secured via SSL (Secure Socket Layer) Technology.</p>
  
              <h3>HOW WE COLLECT INFORMATION</h3>
  
              <h2>Information From You and Third Parties.</h2>
  
              <p>We collect Information you provide to us (including Information provided through mobile devices) in connection with inquiring about or obtaining our Services. We also may obtain Information about you, including soft inquiry credit reports, information about your property and demographic information, from third parties. Information obtained is not shared with any matched user without your permission. Information including Social Security Number, date of birth, address and phone number may be verified by third parties to assist in the prevention of identity theft and consumer identification. We take your privacy seriously, use best practices according to industry standards and do not alter the Information you provide.</p>
  
              <h2>Social Media.</h2>
  
              <p>We may allow you to share Information with social media sites (such as Facebook), or to use social media sites to create or sign into your account or to connect your account with a social media site, in which case you grant us access to Information in your social media profile and other Information permitted by the privacy settings in your social media account. We may collect and store this Information and associate it with your account to improve or personalize our Services. If you provide us with access to any site with video content, then you agree that we may share your video viewing with, and obtain information about your video viewing from, third-party social media sites for at least two years or until you withdraw your consent in writing. You control the PII you allow us to have access to through the privacy settings on the applicable social media site and the permissions you give us when you grant us access to your PII retained by the respective social media site. By associating an account managed by a social media site with your PropStop account, and authorizing us to have access to this information, you agree that we may collect, use and retain the Information provided by the social media site in accordance with this Privacy Policy. Disassociating such a social media account may impact or terminate your access to our Services.</p>
  
              <h2>Email this Listing/Tell a Friend Function.</h2>
  
              <p>The Website offers you the ability to email certain pages or content to a friend or other third party. If you choose to use the
                  “Email this Listing” function, your name, email address and any message you are allowed to include will be visible to the recipient of the email. PropStop does not use the names and
                  addresses stored through the “Email this Listing” function for any purpose other than sending a one-time email to your friend. The “Email this Listing” function is for informational
                  purposes only, and you may not use it for any unlawful purpose including harassment or threats.</p>
              
              <h2>Information Collected Using Cookies and Similar Technologies.</h2>
              <p>When you use our Services, we, our authorized service providers and advertisers may use Cookies, unique
                  identifiers and other similar technologies (“Cookies”) to store information about the pages you view, the links you click and other actions you take within our advertising or e-mail
                  content. These Cookies are either necessary for the functioning of our Services, help us improve our performance, give you extra functionality or help to serve you targeted ads.</p> 
                 
              <p>Advertisers may use third-party Cookies to collect information such as your device identifier, IP address or identifier for advertising (IDFA). Third-party Cookies are covered by the
                  third party’s privacy policy.  Our Website does not provide any PII to advertisers, but they may collect information about where you, or others who are using your computer, saw or clicked
                  on the advertisements they delivered, and they could possibly associate this information with your subsequent visits to the advertised websites. They also may combine this information with
                  personal information they collect directly from you.</p>
                  
              <p>You are free to block, delete disable or manage these Cookies as your device or browser allows. However, if you decline Cookies, you may not be able to take advantage of our Services,
                  and if, applicable, you may be required to re-enter your password more frequently.</p> 
                  
              <p>Where possible, security measures are set in place to prevent unauthorized access to our Cookies. A unique identifier ensures that only we and our service providers have access to Cookie
                  data, and service providers are subject to confidentiality agreements with us and other legal restrictions on their use or collection of any Information. Any PII we collect or store through
                  Cookies, we do only with your consent.</p>
              <h2>CHILDREN'S PRIVACY.</h2>
              
              <p>This website is intended for users over 18 years old, and we do not knowingly collect personal information from children under the age of 13. 
              </p>
  
              <h2>DO NOT TRACK DISCLOSURES.</h2>
              <p>California law requires us to let you know how we respond to web browser Do Not Track (DNT) signals. Because there is no industry or legal
                  standard for recognizing or honoring DNT signals, we don't respond to them at this time.</p>
  
  
              <h2>HOW WE USE INFORMATION</h2>
              <h2>In General. We may use Information to:</h2>
  
              <ul>
                  <li>Provide access to and use of the Services;</li>
                  <li>Manage your customer relationship and provide you with customer support;</li>
                  <li>Keep track of your watch list, your transactions and users with whom you interact;</li>
                  <li>Communicate with you via e-mail, telephone, mail and mobile devices (data rates may apply) about products and Services that may be of interest to you;</li>
                  <li>Develop and display content and Services tailored to your interests on our Website and other websites;</li>
                  <li>Develop and deliver targeted advertisements;</li>
                  <li>Measure the overall effectiveness of our online advertising, content, programming and other activities;</li>
                  <li>Verify your eligibility and deliver coupons and prizes in connection with promotions, contests and sweepstakes;</li>
                  <li>Prevent or detect fraud or illegal activity;</li>
                  <li>Manage our business;</li>
                  <li>Perform functions as described to you at the time of collection; and</li>
                  <li>We may use geo-location information to provide you with location based Services (such as property addresses, vendor arrival times, advertising, search results and other personalized content)</li>
              </ul>
  
              <h2>Financial Information.</h2>
  
              <p>We may use financial information to: match you with real estate agents, brokers, property owners and managers as well as to investigate your identity,
                  protect against or identify possible fraudulent transactions or otherwise as needed to manage our business.
              </p>
  
              <h2>HOW WE RETAIN YOUR INFORMATION.</h2>
              
              <p>We retain your PII as long as it is necessary and relevant to our operations. In addition, we may retain personal information from closed
                  accounts to comply with laws, collect fees owed, resolve disputes, troubleshoot problems, assist with an investigation, enforce our Terms of Use and take other actions permitted or required
                  by law. After it is no longer necessary for us to retain PII, we dispose of it in a secure manner.</p>
  
              <h2>HOW WE COMMUNICATE WITH YOU.</h2>
              <p>You have a choice about how we use your PII to communicate with you, send you marketing information, provide you with customized and
                  relevant advertising and enable you to stay signed into your account.</p>
  
              <h2>Your Account and Marketing.</h2>
              <p>You can control the methods by which we may contact you about your account and your transactional activities by selecting or deselecting
                      the relevant options in your profile. There you can also control your PropStop user-to-user communication preferences.  If you do not wish to receive marketing communications from us,
                      you can unsubscribe from the link in the email you receive or change your preferences in your profile settings. You agree to contact directly any third parties to notify them of
                      your preferred method of contact.</p>
              
              <h2>Signing In.</h2>
              <p>When you sign in to your PropStop account, we may give you the option to stay signed in to your account. If you sign in to your PropStop account
                      through another account (e.g. Facebook), be aware of the actions necessary to sign out of your PropStop account. If you are using a public or shared computer, you should log out of
                      your PropStop account when not actively using PropStop, or others using the computer you used to sign in will be able to view and access your account and take actions without further
                      authorization. Actions any such user may take include:</p>
              
              <ul>
                  <li>Making or accepting an offer</li>
                  <li>Viewing and editing your PII, including financial information</li>
                  <li>Viewing your watch list or past transactions</li>
                  <li>Communicating with us on your behalf</li>
                  <li>Sending messages from you to Other Users</li>
              </ul>
              
              <h2>Withdrawing Consent.</h2>
              <p>At any time, you may withdraw your consent for the use or disclosure of your PII for purposes set out in this Privacy Policy, but if you
                      withdraw your consent, you may not have access to our Services, and we might not be able to provide you all of the Services and customer support offered to our users and authorized
                      under this Privacy Policy and the Terms of Use.</p>
  
              <h2>UPDATING AND ACCESSING YOUR INFORMATION.</h2>
  
              <p>Property owners and HOA designated representatives have access to their own Information for as long as they own the referenced
                  property and maintain a current PropStop account.  Vendors have access to data related to the Services they provide.  Tenants have access to Information they provide.  We encourage you
                  to update your Information when it changes. You may have the ability to view or edit some of your Information online.</p>
  
              <p>You may cancel your PropStop account at any time, and we will honor any statutory right you might have to access, modify or delete your PII. To request access to your PII, and to find out whether any fees may apply, if permitted by law, please contact us as noted in the Contact Us section below. Where you have a statutory right to request access to or request the modification or deletion of your PII, we can still withhold that access or decline to modify or erase your PII in some cases in accordance with applicable law.  Once you make a public posting, however, you may not be able to change or remove the posting.</p>
                  
              <h2>HOW WE DISCLOSE YOUR INFORMATION.</h2>
  
              <p>Third parties may have access to PII as needed to transact with you, perform their functions, to comply with our legal obligations,
              to enforce our Terms of Use, to facilitate our marketing and advertising activities, or to prevent, detect, mitigate and investigate fraudulent or illegal activities related to our
              Services. As stated above, we do not disclose your PII to third parties for their marketing and advertising purposes without your explicit consent.</p>
  
              <h2>Matching with Other Users.</h2>
  
              <p>When you click on a button indicating agreement to our Terms of Use, processing or submission Information or you signify your agreement
                      to proceed, you are consenting, acknowledging and agreeing to the disclosure of your Information, as described herein, to other Website users ("Other Users") with whom you are or
                      may be matched.  Other Users have automatic access to Information you choose to share on the Website including: your screen name, offers, acceptances, listings, Other Users you follow,
                      your ratings, comments and messages you post.</p>
  
              <p>Your screen name is displayed throughout PropStop and is available to the public. It is connected to all of your PropStop activity. Notices sent to Other Users about suspicious
                      activity and notice violations on our sites refer to a screen name and specific items, so if you associate your name or PII with your screen name, the people to whom you have
                      revealed your name will be able to personally identify your PropStop activities.</p>
  
              <p>To help protect your privacy, we allow only limited access to contact and financial information as necessary to facilitate transactions and collect payments.
                      However, when users are involved in a transaction, they have access to each other's name, email address and other Information. Other Users may have their own requirements,
                      disclosures and consent requirements. Contacting Other Users with unwanted communications is a violation of our Terms of Use. </p>

            
            <div class="extranote">
                <h2>Note:</h2>              
                <p>If you share information with Other Users you should contact them directly concerning their privacy and information sharing practices which may differ 
                      from those of PropStop.</p>
            </div>              
  
              <h2>Service Providers.</h2>
  
              <p>We may share your PII with authorized service providers that perform certain Services on our behalf including payment processors, credit bureaus,
                      title companies, insurance companies, appraisers, escrow companies, marketing and customer service firms and or entities that provide business analysis or Website support.</p>
  
              <h2>Law enforcement, governmental agencies or authorized parties regarding legal proceedings and as authorized by law.</h2>
  
              <ul>
                  <li>
                      to comply with our legal requirements, enforce our Terms of Use, respond to claims that content violates the rights of others, or to protect
                      anyone's rights, property or safety
                  </li>
                  <li>
                      in response to a verified request relating to a criminal investigation or alleged or suspected illegal activity or any other activity
                      that may expose us, you, or any other of our users to legal liability
                  </li>
                  <li>
                      to credit agencies or bureaus as authorized by applicable law (e.g. information on late or missed payments or other defaults on your account that may be reflected
                      in your credit report or file)
                  </li>
                  <li>
                      to third parties involved in a legal proceeding, if they provide us with a subpoena, court order or substantially similar legal procedure or to report suspected
                      illegal activity
                  </li>
              </ul>
  
              <h2>THIRD PARTY LINKS AND POLICIES.</h2>
     
              <p>Our Website contains links to other websites owned and operated by third parties. These third parties and their servers may be
                  located in the United States or in other countries. We do not control, and are not responsible for, the privacy and security practices of these third parties. We recommend that
                  you review the privacy and security policies of these third parties to determine how they handle information and data they may collect about you.
              </p>
  
              <h2>SPECIAL NOTICE TO CALIFORNIA RESIDENTS.</h2>
  
              <p>If you are a resident of California, in addition to the rights set forth in this Privacy Policy you have the following rights:</p>
  
              <h2>Your California Privacy Rights.</h2>
  
              <p>California Civil Code Section 1798.83 permits our customers who are California residents to request and obtain from us once a year, free of charge,
                      information about the PII (if any) we disclosed to third parties for direct marketing purposes in the preceding calendar year. If applicable, this information would include a
                      list of the categories of PII shared and the names and addresses of all third parties with which we shared information in the immediately preceding calendar year. If you are a
                      California resident and would like to make such a request, please submit your request in writing to: PropStop, Inc. Attn.: California Privacy Rights Requests 2470 Stearns Street,
                      Simi Valley, CA  93063.</p>
  
              <h2>HOW WE PROTECT YOUR PII.</h2>
              
              <p>We protect your PII using technical and administrative security measures to reduce the risks of its loss, misuse, unauthorized access,
                  disclosure and alteration. Some of the safeguards we use are firewalls and data encryption, physical access controls to our data centers and information access authorization controls.
                  No system can be completely secure; therefore, although we take steps to secure your Information, we do not promise, and you should not expect, that your PII, searches or other
                  communications will always remain secure.  Users should also take care with how they handle and disclose their PII. Please refer to the Federal Trade Commission’s website for
                  information about how to protect yourself against identity theft. 
              </p>
  
              <h2>NO RIGHTS OF THIRD PARTIES.</h2>
  
              <p>This Privacy Policy does not create rights enforceable by third parties or require disclosure of any PII relating to users of our Website.</p>
  
              <h2>OPTING OUT.</h2>
  
              <p>Our marketing emails tell you how to 'opt-out' of receiving future marketing emails. If you opt out we may still send you non-marketing emails.
                  Non-marketing emails include emails about your account, your use of the Services and our business dealings with you.</p>
  
                  <h2>EFFECTIVE DATE AND CHANGES TO THE PRIVACY POLICY.</h2>
  
              <p>This Privacy Policy will remain in effect except with respect to any provisions which are changed in the future,
                  in which case the changes will become effective on the date they are first posted on the Website. We reserve the right to change this Privacy Policy at any time. Changes will be effective immediately upon posting, so please check for changes when you use the Website. Your continued use of the Website will signify your acceptance of all changes to the
                  Privacy Policy.
              </p>
  
              <h2>CONTACT US.</h2>
  
              <p>If you have any questions about this Privacy Policy, please <span @click="ContactUsModal = true" style="color: #00aae1;cursor: pointer;">Contact Us</span></p>
  
          </div>
      </section>
  
      <Footer />

      <PropModal v-if="ContactUsModal" :from="'contact-modal'" :data="null" @modalclose="CloseContactModal" />
  </template>