<template>
    <div class="ticket-details-content">        
        <div class="lft-sid">
            <div class="lft-heading">
                <div class="backbtn" @click="backtoList">
                    <img src="../../images/tickets/back-btn.png">
                </div>
                <div class="headngs">
                    <p>{{ticket.subject}}</p>
                    <p class="nam-dtl"><a href="#">{{ticket.requested.fullname}}</a> {{ticket.created}}</p>
                </div>
                <div class="ticket-setting">
                    <img src="../../images/icons/ticket-setting.svg" @click="TicketSetting = true">                    
                    <ul class="action-menu" v-if="TicketSetting == true" style="top: 0;">
                        <li><img src="./../../images/icons/close.svg" @click="TicketSetting = false"/></li>
                        <li @click="UpdateTicketFavourite">
                            <div class="pretty p-switch p-fill" style="border: none;">
                                <input type="checkbox" :checked="ticket.isFavourite">
                                <div class="state p-success">
                                    <label>Favorite Ticket</label>
                                </div>
                            </div>
                        </li>
                        
                        <!-- <li @click="WantCloseTicket = true; TicketSetting = false">
                            <img src="./../../images/icons/complete-ticket.svg" class="icon" />
                            <p>Close Ticket</p></li>
                        <li @click="WantDeleteTicket = true; TicketSetting = false">
                            <img src="./../../images/icons/delete.svg" class="icon" />
                            <p>Delete Ticket</p></li> -->
                    </ul> 
                </div>
            </div>

            <div class="ticket-summery _propscrl">
                <!-- Skeleton -->
                <div v-if="!TicketDetail" style="margin-top:55px">
                    <Skeletion :type="'large-list'" :data="2" />
                </div>

                <!-- If no data on result -->
                <div v-if="TicketDetail.length <= 0" class="empty-result animated fadeInUp">
                    <img src="../../images/icons/empty-result.svg" />
                    <p>Ticket has been created at {{ticket.created}} by you. <br> But no thread has been started yet.</p>
                </div>

                <!-- Ticket details -->
                <ul v-if="TicketDetail && TicketDetail.length >= 1" class="ticket-threads">
                    <li class="info">
                        <p>Ticket created at {{ticket.created}}</p>
                    </li>

                    <li v-for="detail in TicketDetail" :key="detail">
                        <div class="image">
                            <div class="avatar">
                                <UserImage :init="detail.threadby.fullname" :back="detail.threadby.back" :source="detail.threadby.avatar" />
                            </div>
                        </div>

                        <div class="content">
                            <div class="user">
                                <h3>{{detail.threadby.fullname}}</h3>
                                <p>{{detail.created}}</p>
                            </div>

                            <div class="message">
                                <p id="TicketDetail">{{detail.content}}</p>

                                <ul class="attachment-files" v-if="detail.type = 'Blob'">
                                    <li v-for="file in detail.attachments" :key="file.id">
                                        <div class="atch-icon">
                                            <img v-if="file.extension == 'doc' || file.extension == 'docx'" src="./../../images/attachment/atch-docx.svg" />

                                            <img v-else-if="file.extension == 'xls' || file.extension == 'xlsx'" src="./../../images/attachment/atch-xlsx.svg" />

                                            <img v-else-if="file.extension == 'ppt' || file.extension == 'pptx'" src="./../../images/attachment/atch-pptx.svg" />

                                            <img v-else-if="file.extension == 'jpeg' || file.extension == 'jpg' || file.extension == 'png'" src="./../../images/attachment/atch-image.svg" />

                                            <img v-else-if="file.extension == 'pdf'" src="./../../images/attachment/atch-pdf.svg" />

                                            <img v-else src="./../../images/attachment/atch-others.svg" />
                                        </div>
                                        
                                        <div class="atch-content">
                                            <h3>{{file.title}}</h3>
                                            <ul class="ac-info">
                                                <li><p>{{detail.created}}</p></li>
                                                <li><p>{{file.size}} kb</p></li>
                                            </ul>
                                        </div>
                                        
                                        <div class="atch-actions">
                                            <span>
                                                <a :href="file.url" target="_blank" v-if="file.url">
                                                    <img src="./../../images/icons/download.svg" />
                                                </a>
                                            </span>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </li>

                    <li v-if="ticket.status == 'Closed'" class="alert">
                        <p>Ticket has been closed!</p>
                    </li>
                </ul>
            </div>

            <div class="ticket-reply">
                <div class="mesz-input">
                    <textarea v-if="ticket.status == 'Open'" placeholder="Type a ticket reply here.." v-model="Message"></textarea>
                    <textarea v-else placeholder="Ticket closed.." readonly></textarea>
                </div>
                <div class="mesz-actions">
                    <ul class="mesz-icons">
                        <li><img src="../../images/tickets/smiley.svg"></li>
                        <li><img src="../../images/tickets/attachment.svg" @click="UploadTicketAttachment ()"></li>
                        <li><img src="../../images/tickets/response.svg"></li>
                        <li v-if="hasAttachments" class="animated fadeInUp" @click="hasAttachments = false">
                            <p>{{TicketAttachments.length}} files ready to upload!</p>
                        </li>
                    </ul>
                    <ul class="mesz-buttons">
                        <li @click="SendMessage" :style="Message.length >= 3 ? '' : 'cursor: not-allowed;background-color: #b3b3b3;'"><p>Send</p></li>
                    </ul>
                </div>
            </div>
            
        </div>

        <div class="rht-sid _propscrl">
            <h3>Ticket Information</h3>

            <p>Status</p>
            <p class="des-bld blue-color">{{ticket.status}}</p>

            <p>Priority</p>
            <ul class="ticket-priority">
                <li v-if="ticket.priority == 'Critical'" class="critical"><img src="../../images/icons/critical.svg" /> <p>{{ticket.priority}}</p></li>
                <li v-if="ticket.priority == 'High'" class="high"><img src="../../images/icons/high.svg" /> <p>{{ticket.priority}}</p></li>
                <li v-if="ticket.priority == 'Normal'" class="normal"><img src="../../images/icons/normal.svg" /> <p>{{ticket.priority}}</p></li>
                <li v-if="ticket.priority == 'Low'" class="low"><img src="../../images/icons/low.svg" /> <p>{{ticket.priority}}</p></li>
            </ul>

            <p>Ticket ID</p>
            <p class="des-bld">#{{ticket.id}}</p>

            <p>Created Date</p>
            <p class="des-bld">{{ticket.created}}</p>

            <p>Due Date</p>
            <p v-if="ticket.expiry == 'Expired'" class="des-bld" style="color: #F00">{{ticket.expiry}}</p>
            <p v-else class="des-bld">{{ticket.expiry}}</p>          
            <p class="des-bld blue-color">{{ticket.expiry_date}}</p>

            <p>Requested For </p>
            <p class="des-bld">{{ticket.requested.fullname}}</p>

            <p>Assignee ({{ticket.assignee.length}})</p>
            <p class="des-bld" v-for="assignee in ticket.assignee" :key="assignee">{{assignee.fullname}}</p>

        </div>
    </div>

    <!-- Modals -->
    <PropModal v-if="WantDeleteTicket == true" :from="'confirm-delete-ticket'" :data="ticket" @modalclose="CloseConfrimDelete" />
    <PropModal v-if="WantCloseTicket == true" :from="'confirm-close-ticket'" :data="ticket" @modalclose="CloseConfrimClose" />

    <PropModal v-if="UploadAttachment == true" :from="'upload-document'" :data="AttachmentData" @modalclose="CloseUploadModal" />


</template>

<script>

    //@ JS Modules
    import Request from './../../javascript/getRequest';
    import Handler from './../../javascript/Handler';

    import Skeletion from '../blocks/Skeleton.vue';
    import UserImage from '../blocks/UserImage.vue';

    import PropModal from './../modal/PropModal.vue';

    export default {
        name: 'OwnerTicketDetail',
        props: ['ticket', 'profile'],
        emits: ['backtolist', 'backandclose'],
        components: {
            Skeletion,
            UserImage,

            // modals
            PropModal,
        },

        data () {
            return {
                // ticket detail to be loaded from server
                TicketDetail : false,
                UserDetail : this.profile,

                // page settings
                Message : '',

                TicketSetting : false,

                WantDeleteTicket : false,
                WantCloseTicket : false,

                UploadAttachment : false,
                AttachmentData: {
                    title: 'Upload Documents',
                    error: 'Some uploaded file type are not supported!',
                    fileschema: ['jpeg', 'jpg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
                },
                hasAttachments: false,
                TicketAttachments: [],

            }
        },

        async mounted () {
            // load ticket data from server
            const Requests = await Request.getData('tenant/tickets/detail.php?ticket=' + this.ticket.id);
            var  dtlcontent = document.getElementById('TicketDetail');

            if (Requests) {
                // we got the ticket list
                this.TicketDetail = Requests.response;
            }
        },

        methods : {
            backtoList () {
                this.$emit('backtolist', false);
            },

            UploadTicketAttachment () {
                if (this.ticket.status != 'Closed' || this.ticket.isArchive == false) {
                    this.UploadAttachment = true;
                }
            },

            // todo
            async UpdateTicketFavourite () {

                let formData = new FormData();
                formData.append('ticket', this.ticket.id);
                formData.append('favourite', this.ticket.isFavourite);
                await Request.postServer('tenant/tickets/favourite.php', formData);

                // notify on success
                Handler.PropNotification('Successfully updated to the favourite list!');
                // this.$emit('updatefilter');

                // update list in client
                var todo = !this.ticket.isFavourite;
                this.ticket.isFavourite = todo;

                this.TicketSetting = false;

            },

            async CloseConfrimDelete (ticket) {
                if (ticket) {
                    let formData = new FormData();
                    formData.append('ticketid', ticket.id);

                    const DeleteTicket = await Request.postServer('tenant/tickets/delete.php', formData);
                    if (!DeleteTicket.error) {
                        this.$emit('backtolist', ticket);
                    }
                }

                this.WantDeleteTicket = false;
            },

            async CloseConfrimClose (ticket) {
                if (ticket) {
                    this.ticket.status = "Closed";
                    
                    let formData = new FormData();
                    formData.append('ticketid', ticket.id);

                    const CloseTicket = await Request.postServer('tenant/tickets/close.php', formData);
                    if (!CloseTicket.error) {
                        this.$emit('backandclose', ticket);
                    }
                }

                // remove dialog
                this.WantCloseTicket = false;
            },

            async SendMessage () {
                if (this.Message.length <=3) return;
                
                let NewThread;

                // update message
                if (this.hasAttachments) NewThread = this.SendMediaMessage ();
                else NewThread = this.SendTextMessage ();

                // push to the list
                this.TicketDetail.push(NewThread.dom);

                // null the message body
                this.Message = '';
                this.hasAttachments = false;

                // send data from server
                await Request.postServer(NewThread.path, NewThread.formdata);

                this.TicketAttachments = [];

            },

            // send text message
            SendTextMessage () {
                let formData = new FormData();
                formData.append('ticket', this.ticket.id);
                formData.append('type', 'Reply');
                formData.append('thready', this.UserDetail.id);
                formData.append('content', this.Message);

                // return the collected data
                return {
                    dom: {
                        content: this.Message,
                        created: 'Just Now',
                        id: Math.round(new Date()/1000),
                        type: 'Reply',
                        threadby: this.UserDetail
                    },
                    path: 'tenant/tickets/update-thread.php',
                    formdata: formData
                };
            },

            // send text and files attachment message
            SendMediaMessage () {
                let formData = new FormData();
                formData.append('ticket', this.ticket.id);
                formData.append('type', 'Blob');
                formData.append('thready', this.UserDetail.id);
                formData.append('content', this.Message);

                // documents as attachments
                // convert file list into files[array] form data    
                var attachments = [];            
                for (let element of this.TicketAttachments) {
                    formData.append('documents[]', element);

                    // extension of the current file
                    var filename = element.name;
                    var extension = filename.split('.').pop();

                    attachments.push ({
                        id: Math.round(new Date()/1000),
                        title: filename,
                        size: element.size,
                        extension: extension,
                        url: false
                    });
                };

                // return the collected data
                return {
                    dom: {
                        content: this.Message,
                        created: 'Just Now',
                        id: Math.round(new Date()/1000),
                        threadby : this.UserDetail,
                        type: 'Blob',
                        attachments: attachments
                    },
                    path: 'tenant/upload/tickets.php',
                    formdata: formData
                };
            },

            // close documents upload modal
            CloseUploadModal (blobupload) {
                if (blobupload) {
                    this.hasAttachments = true;
                    this.TicketAttachments = blobupload;
                    this.DirectAttachmentUpload ();
                }

                this.UploadAttachment = false;
            },

            //todo
            async DirectAttachmentUpload () {
                let NewThread = this.SendMediaMessage ();

                // push to the list
                this.TicketDetail.push(NewThread.dom);

                // null the message body
                this.Message = '';
                this.hasAttachments = false;

                // send data from server
                await Request.postServer(NewThread.path, NewThread.formdata);

                this.TicketAttachments = [];
            },

        }
        

    };
</script>