<template>
    <li class="transAe" :class="{ active : current.active}" @click="NavigationClicked">
        <img :src="require(`../../images/icons/${current.icon}`)" />
        <p>{{ current.name }}</p>
    </li>
</template>

<script>
    export default {
        name: 'PropertyNav',
        props: ['current'],

        methods : {
            NavigationClicked () {
                this.$emit('navchange', this.current);
            }
        }
    };
</script>