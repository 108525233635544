<template>
    <div v-if="tasksLoadingState" style="margin-top:45px">
        <Skeletion :type="'large-list'" :data="2" />
    </div>

    <div v-if="!TasksEmpty" class="prop--table" style="border: 1px solid #e8e8e8;border-bottom: none;width: calc(100% - 2px);">        
        <div class="prop-row ticketClk transAe" v-for="(task, index) in EmployeeTasks" :key="task"  :class="index % 2 ? 'odd' : 'even'">     
            <div class="column column5"></div>
            <div class="column column80">
                <div class="ticket--subject">
                    <p><strong>#{{task.id}}</strong> {{ task.title }}</p>
                    <ul class="ticket-priority">
                        <li v-if="task.priority == 'Critical'" class="critical"><img src="../../../images/icons/critical.svg" /> <p>{{task.priority}}</p></li>
                        <li v-if="task.priority == 'High'" class="high"><img src="../../../images/icons/high.svg" /> <p>{{task.priority}}</p></li>
                        <li v-if="task.priority == 'Normal'" class="normal"><img src="../../../images/icons/normal.svg" /> <p>{{task.priority}}</p></li>
                        <li v-if="task.priority == 'Low'" class="low"><img src="../../../images/icons/low.svg" /> <p>{{task.priority}}</p></li>
                    </ul>
                </div>
            </div>
            <div class="column column5"></div>
        </div>
    </div>

    <div v-if="!tasksLoadingState && TasksEmpty" class="emptyskeleton">
        <img src="./../../../images/icons/empty-ticket.svg" style="width: 220px;" class="psanim-1 fadeInUp">
        <p class="psanim-2 fadeInUp">Seems this user has no open tasks. <br>You can create task for usere from here.</p>
        <div class="btn-secondary psanim-3 fadeInUp" @click="CreateTask">
            <p>Create Task</p>
        </div>
    </div>
</template>

<script>
    import DataCenter from './../../../javascript/DataCenter';

    import Skeletion from './../../../components/blocks/Skeleton.vue';

    export default {
        name: 'Tasks',
        props: ['profile'],
        emits: ['createtask'],
        components: {
            Skeletion,
        },

        data () {
            return {
                tasksLoadingState : true,
                TasksEmpty : true,
                EmployeeTasks : [],
            }
        },

        async mounted () {
            // now load all the tickets of this tenant
            let getdata = {
                key : 'employee',
                value : this.profile.id
            }
            const Tickets = await DataCenter.ProPRequest('employee-tasks', false, getdata);
            if (Tickets) {
                this.TasksEmpty = false;
                this.EmployeeTasks = Tickets;
            }else {
                this.TasksEmpty = true;
            }
            // tickets loaded
            this.tasksLoadingState = false;
        },

        methods: {
            CreateTask () {
                this.$emit('createtask');
            }
        }
    };
</script>