<template>

  <div class="finance-sidebar" :style="SidebarHamClick ? 'left: 0px !important;box-shadow: rgb(0 0 0 / 22%) 0px 7px 10px !important;' : ''">    
    <ul>
      <li :class="list.active ? 'active' : ''" v-for="list in FilterMenuList" :key="list" @click="financeFilterListHandler(list)">
          <p>{{list.name}}</p>
      </li>
    </ul>

    <div class="btn-primary" @click="AddNewTransactionOuter">
        <img src="../../images/icons/addbtn-icon.svg" />
        <p>Add Transaction</p>
    </div>
    
  </div>

  <div class="finance-content _propscrl">
      
    <div class="proprty-ham-menu" style="margin-left: 15px;" @click="SidebarHamClick = true">
        <img src="./../../images/icons/line-menu.svg" />
        <p>Finance Filter</p>
    </div>    

    <div class="fc--content">
        <div v-if="FinanceLoadingState === true" style="margin-top:55px">
            <Skeletion :data="2" />
        </div>

        <div v-if="!FinanceLoadingState && FinanceActivePage == 'properties'">
          <PropTransactionVue v-if="ShowPropTransactionList"
                :transactions="PropTransactionsList"
                @propselected="LoadUnitTenantTransactions"
          />

          <Transactions v-else-if="ShowTransactionsList"              
                :transactions="TransactionsList"
                :propfinance="PropertyFinance"
                :property="FinanceProperty"
                :filter="filterChanges" 
                :key="RefreshTransaction"
                :from="'root'"

                @addpayment="AddTenantPayment"
                @addrent="UpdateRent"
                @transelected="TransactionSelected"
                @back="BackFromUnitLedger"
                @addunitpayment="AddUnitPayment"
            />

          <UnitTransactions v-else-if="ShowTenantLedger"
              :unit="UnitSelected"
              :property="FinanceProperty"

              @addpayment="AddTenantPayment"
              @addtrans="AddNewTransaction"
              @back="BackFromLedger"
          />
        </div>

        <AllTransaction v-if="!FinanceLoadingState && FinanceActivePage == 'transactions'" :key="ChangeAllTransaction" />
        <ProfitLoss v-if="!FinanceLoadingState && FinanceActivePage == 'profit-loss'" :key="ChangePNLTransaction" />
        <Tenants v-if="!FinanceLoadingState && FinanceActivePage == 'tenants'" />
        <!-- all tenants data goes here -->

    </div>
    
  </div>

  <div v-if="OwnerHasNoProperty" class="empty-result animated fadeInUp">
      <img src="./../../images/icons/on-verification.svg">
      <p>It looks like you haven't added a property yet.<br>Please add a property to view all features.</p>
      <div class="btn-primary bphasno" @click="RouteToAddProperty">
          <p>Property Page</p>
      </div>
  </div>

  <!-- pop ups --> 
  <RightModal v-if="AddTransactionModal" :form="'add-transaction'" :data="AddTransactionData" @modalclose="CloseTransactionModal" />

</template>

<script>

  // @ is an alias to /src
  import Document from '../../javascript/Document';
  import DataCenter from './../../javascript/DataCenter';
  import Request from './../../javascript/getRequest';
  import Handler from './../../javascript/Handler';

  // components
  import Skeletion from './../../components/blocks/Skeleton.vue';
  import PropTransactionVue from '../../components/owner/finance/PropTransaction.vue';
  import Transactions from '../../components/owner/finance/Transaction.vue';
  import UnitTransactions from '../../components/owner/finance/UnitTransactions.vue';

  // other pages
  import AllTransaction from '../../components/owner/finance/Transactions.vue';
  import ProfitLoss from '../../components/owner/finance/ProfitLoss.vue';
  import Tenants from '../../components/owner/finance/Tenants.vue';

  // Modal
  import RightModal from './../../components/modal/RightModal.vue';

  // pinia
  import { useOwnerStore } from '../../stores/OwnerStore';

  export default {
    name: 'Finance',
    props: ['profile', 'properties', 'employees', 'selected'],
    emits: ['updateproperties'],
    
    components: {
      Skeletion,
      PropTransactionVue,
      Transactions,
      UnitTransactions,

      AllTransaction,
      ProfitLoss,
      Tenants,

      RightModal
    },

    data() {
      return {
        
        FinanceLoadingState : true,
        ShowPropTransactionList: false,
        ShowTransactionsList : false,
        ShowTenantLedger : false,
        UnitSelected: {},

        PropTransactionsList : [],
        TransactionsList : [],
        filterChanges : '',
        RefreshTransaction : '',
        FinanceProperty: '',

        AddTransactionModal : false,
        TenantPaymentModal : false,

        PaymentData : {},

        AddTransactionData: {
          type: 'choose',
          isMainPage: true,
          property: false,
          unit: false,
          tenant: false,
        },

        TransactionData : {
          from: 'root',
          properties: this.properties,
          selected: this.selected,
          hasProperty: false,
          hasUnit: false,
          property: {
              name: '',
              id: ''
          },
          unit: {
            id: '',
            unit_name: '',
            tenant: {
              name: '',
              id: '',
            }
          }
        },

        // finance filter menu
        FinanceActivePage: 'properties',
        FilterMenuList : [
          {
            key : 1,
            name : 'Properties',
            page  : 'properties',
            active : true
          },
          {
            key : 2,
            name : 'Transactions',
            page  : 'transactions'
          },
          {
            key : 3,
            name : 'Profit & Loss',
            page  : 'profit-loss'
          },
          {
            key : 4,
            name : 'Tenant Finance',
            page  : 'tenants'
          }
        ],

        DateRange: null,

        // responsive
        SidebarHamClick: false,

        OwnerHasNoProperty : true,
        OwnerProperties : this.properties,
        currentProperty : [],
        currentPropertyImageKey : 'current',
        isPropertySelection : false,

        // reload transaction after payment update
        OwnerStore: useOwnerStore (),
        ChangeAllTransaction: 'xyz',
        ChangePNLTransaction: 'xyz',
      }
    },

    async mounted() {
        Document.DocumentHeadings ({title : 'Finance - PropStop'});

        // hide the properties selection from here
        var OwnPrty = document.getElementById('ownerprtities');
            if (OwnPrty != null || OwnPrty != undefined) {
                OwnPrty.style.visibility = 'hidden';
                OwnPrty.style.opacity = '0';
            }

        if (this.OwnerProperties.length >= 1) {
            this.OwnerHasNoProperty = false;

            // load properties transactions initially
            this.LoadPropertyTransactions ();
        }

        // global add action
        var AddAction = document.getElementById('AppMainAddActionIcon');
        AddAction.onclick = () => {
          this.AddTransactionModal = true;
        };

    },

    methods: {

      async LoadPropertyTransactions () {
          if (this.OwnerProperties.length >= 1) {
              this.OwnerHasNoProperty = false;

              const Transactions = await DataCenter.ProPRequest('property-transaction-list', false, false);

              if (!Transactions) {
                  this.PropTransactionsList = [];
              }else {
                  this.PropTransactionsList = Transactions;
              }

              // load the property transaction
              this.FinanceLoadingState = false;
              this.ShowPropTransactionList = true;
          }
      },

      async LoadUnitTenantTransactions (property) {
            // data for transaction
            this.TransactionData.hasProperty = true;
            this.TransactionData.property.name = property.name;
            this.TransactionData.property.id = property.property;

            this.FinanceProperty = property;
            this.FinanceLoadingState = true;
            this.ShowPropTransactionList = false;

            // load all the transaction of this property
            let getdata = {
                key : 'property',
                value : property.property
            }
            const Transactions = await DataCenter.ProPRequest('tenants-transaction-list', false, getdata);
            
            if (!Transactions) {
              this.TransactionsList = [];
              this.PropertyFinance = {};
            }else {
              this.TransactionsList = Transactions.transactions;
              this.PropertyFinance = Transactions.property;
            }

            this.filterChanges = "all";
            this.RefreshTransaction = "all";

            this.FinanceLoadingState = false;
            this.ShowTransactionsList = true;

            // reset on the main page
            // finance payment data
            this.OwnerStore.financepaymentdata = {
                type: 'choose',
                isMainPage: true,
                tenant: false,
                property: true,
                unit: false,
                whichProperty: property,
                whichUnit: {},
                whichTenant: {}
            }
      },
       
       financeFilterListHandler (list) {
            // make the clicked list active
            this.FilterMenuList = this.FilterMenuList.filter (ftr => {
              ftr.active = false;
              if (list.key == ftr.key) {
                ftr.active = true;
                this.FinanceActivePage = ftr.page;
              }
              return ftr;
            });

            this.filterChanges = list.filter;
            this.RefreshTransaction = list.filter;

            // remove sidebar
            this.SidebarHamClick = false;
        },

        // close add transaction right modal
        CloseTransactionModal (trans) {
          if (trans) {
              // update notification
              Handler.PropNotification ('You have succesfully added new transaction!');

              // load properties transactions initially
              this.FinanceLoadingState = true;
              if (this.FinanceActivePage == 'properties') {
                this.LoadPropertyTransactions ();
              }else if (this.FinanceActivePage == 'transactions') {
                this.FinanceLoadingState = false;
                this.ChangeAllTransaction = "added-new" + Math.random();
              }else if (this.FinanceActivePage == 'profit-loss') {
                this.FinanceLoadingState = false;
                this.OwnerStore.finance.profitloss = [];
                this.ChangePNLTransaction = "added-new" + Math.random();
              }
          }

            this.AddTransactionModal = false;
        },

        CloseTenantPaymentModal (trans) {
          if (trans) {
            // update the unit list
            var transaction = trans;              
            this.TransactionsList.filter (trans => {

              if (trans.tenant.unit == transaction.unit) {
                trans.transaction.balance = transaction.balance;
                trans.transaction.remarks = transaction.remarks;
                trans.transaction.created = transaction.created;
              }
            });

              // update notification
              Handler.PropNotification ('You have succesfully added new transaction!');

              // filter to show the changes
              this.filterChanges = 'all';
              this.RefreshTransaction = "added-new" + Math.random();
          }

            this.TenantPaymentModal = false;

        },

        RouteToAddProperty () {
          this.$router.push({name : 'owner-properties'});
        },

        AddTenantPayment (trans) {          
          this.MakeInactive ();
          
          this.PaymentData = {
              hasTenant: true,
              property : trans.unit.property,
              unit   : trans.unit.unit,
              tenant : trans.tenant.tid,
              name : trans.tenant.fullname,
              unit_name : trans.tenant.unit_name,
          };

          this.TenantPaymentModal = true;
        },

        AddNewTransaction (data) {
          this.AddTransactionData = {
            ...data,
            type: 'choose',
            isMainPage: true,
          };
          this.AddTransactionModal = true;
        },

        AddNewTransactionOuter () {
          this.AddTransactionData = {
            type: 'choose',
            isMainPage: true,
            property: false,
            unit: false,
            tenant: false,
          };
          this.AddTransactionModal = true;
        },

        AddUnitPayment (property) {
          this.PaymentData = {
              hasTenant: false,
              property : property.property,
          };
          this.TenantPaymentModal = true;
        },

        async UpdateRent (trans) {
          this.MakeInactive ();

          // update the monthly rent
          // i.e. add new transaction saying current month rent
          var CurrentProperty = this.currentProperty.id;
          var CurrentUnit = trans.tenant.unit;
          var CurrentTenant = trans.tenant.id;
          var UnitRent = trans.tenant.rent;

          let formData = new FormData();
          formData.append('property', CurrentProperty);
          formData.append('unit', CurrentUnit);
          formData.append('tenant', CurrentTenant);
          formData.append('rent', UnitRent);

          // upload and load data from server
          const ReturnData = await Request.postServer('owner/finance/update-rent.php', formData);

          // now check if the email already exists
          if (ReturnData.error == false) {

            // update notification
            Handler.PropNotification ('You have succesfully added new transaction!');
            
            // update the unit list
            var transaction = ReturnData.response;              
            this.TransactionsList.filter (trans => {

              if (trans.tenant.unit == transaction.unit) {
                trans.transaction.balance = transaction.balance;
                trans.transaction.remarks = transaction.remarks;
                trans.transaction.created = transaction.created;
              }
            });
            
          }else {
              
          }            

        },

        TransactionSelected (trans) {
          this.MakeInactive ();

          console.log ('checking here');
          console.log (trans);

          this.UnitSelected = trans;

          this.TransactionData.hasUnit = true;
          this.TransactionData.unit.id = trans.unit.unit;
          this.TransactionData.unit.unit_name = trans.unit.unit_name;
          this.TransactionData.unit.tenant.name = trans.tenant.fullname;
          this.TransactionData.unit.tenant.id = trans.tenant.id;

          
          this.ShowTransactionsList = false;
          this.ShowTenantLedger = true;
        },

        MakeInactive () {
          this.TransactionsList.filter(trans => {
            trans.active = false;
          });
        },

        BackFromUnitLedger () {
          // remove property selection
          this.TransactionData.hasProperty = false;

          this.ShowTenantLedger = false;
          this.ShowPropTransactionList = true;
        },

        BackFromLedger () {
            this.ShowTenantLedger = false;
            this.ShowTransactionsList = true;
        }


    },
    
  }
</script>

<style scoped>

div.empty-result {position: absolute;background-color: rgb(255 255 255 / 92%);}

</style>