<template>
    <div class="prop-form _propscrl">        
        <div class="form-area" style="padding-bottom: 65px;">
            <form name="propform" id="propform-asgnparking">
                <div class="pf--title">
                    <h3>Assign Parking</h3>
                    <p>Please choose currenty property tenant to assign to parking.</p>
                    <p style="font-size: 10pt;">* Indicate a required field</p>
                </div>

                <div class="pf--info" style="margin-bottom: 45px;">
                    <div class="icon">
                        <img src="./../../images/icons/info-blue.svg" />
                    </div>
                    <div class="content">
                        <p>If you can't find the unit/tenant you seek, make sure you have chosen the correct property.</p>
                    </div>
                </div>

                <div class="pf--input" style="position:relative;">
                    <p>Choose Unit *</p>
                    <input v-model="SelUnit" type="text" placeholder="Choose One" id="Tenant" data-type="len" data-max="5" :disabled="Requesting" readonly @click="UnitsSingleSelect = true" />                    
                    <UnitSelection v-if="UnitsSingleSelect == true" :units="PropertyUnits" :distyle="'top: 0px;left: 20px;'" @lstboxclse="UnitSelected" />
                </div>

                <div class="pf--input" style="position: relative;">
                    <p>Parking Space(s) *</p>
                    <input v-model="SelTotalParking" type="text" placeholder="Select Parking" id="SelTotalParking" data-type="len" data-max="5" :disabled="Requesting" readonly @click="ParkingMultiSelect = true" />
                    <MultiSelectParking v-if="ParkingMultiSelect" :lists="AllParkingList" @lstboxclse="ParkingsSelected" />
                </div>
                
            </form>
            
            <span v-if="formError" class="__formerror">{{ infoText }}</span>
        </div>

        <div class="form-action">
            <div class="form-area">
                <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="AssignParking" style="width: 120px;margin-top: 10px;display: block;float: left;">
                    <p v-if="!Requesting">Assign Parking</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    //@ JS Modules
    import DataCenter from './../../javascript/DataCenter';
    import Request from './../../javascript/getRequest';
    import FormValidation from './../../javascript/FormValidation';

    import ParkingSelected from './../blocks/ParkingSelected.vue';

    import UnitSelection from './../web-forms/UnitSelection.vue';
    import MultiSelectParking from './../web-forms/MultiSelectParking.vue';
    

    export default {
        name: 'AddNewParking',
        props: ['data'],
        emits: ['close'],
        components: {
            ParkingSelected,

            UnitSelection,
            MultiSelectParking
        },

        data () {
            return {
                // Form v-Modal
                AllParkingList : [],
                Parkings : [],
                Property : '',
                SelTotalParking: '',

                // units
                SelUnit : '',
                SelUnitID : null,
                SelUnitNumber : null,
                SelUnitTenantID: null,
                PropertyUnits : false,
                UnitsSingleSelect : false,
                SelectedUnit : null,

                // parking selection
                ParkingMultiSelect : false,

                // form error
                Requesting : true,
                formError : false,
                infoText : '',
            }
        },

        async mounted () {

            // all parking lists
            this.AllParkingList = this.data.parkings;
            this.AllParkingList = this.AllParkingList.filter (parking => {
                parking.active = false;
                if (parking.status != "Reserved") return parking;
            });

            // parkings
            if (this.data.selected) {
                this.Parkings.push (this.data.selected);
                this.SelTotalParking = '1 Selected';

                // make this active previously
                let SelectedID = this.data.selected.id;
                this.AllParkingList = this.AllParkingList.filter (parking => {
                    if (parking.id == SelectedID) parking.active = true;
                    return parking; 
                })
            }

            // property id
            this.Property = this.data.property.id;

            // load all units
            this.loadSelectedPropertyUnits (this.Property);

        },

        methods: {
            
            // load blank units on the selected properties
            async loadSelectedPropertyUnits (propertyid) {
                // new selection
                this.SelectedUnit = null;
                this.PropertyUnits = null;

                // load units data from server
                let getdata = {
                    key : 'property',
                    value : propertyid
                }
                
                const Units = await DataCenter.ProPRequest('property-units', false, getdata);
                this.Requesting = false;
                
                // now filter units which only have assigned tenants
                if (!Units) {}else {
                    this.PropertyUnits = Units;

                    // filter only those unit which is not assgned yet
                    this.PropertyUnits = this.PropertyUnits.filter (unit => {
                        if (unit.status == 'rented') return unit;
                    });
                }     
                
                // auto select if the list has only one unit
                if (this.PropertyUnits.length == 1) {
                    this.UnitSelected (this.PropertyUnits[0]);
                }
            },

            // once unit is selected from single select
            UnitSelected (unit) {
                if (unit !== null) {
                    this.SelUnit = unit.unit_name;
                    this.SelUnitNumber = unit.unit_number;
                    this.SelUnitID = unit.id;
                    this.SelUnitTenantID = unit.tenant.id
                }

                this.UnitsSingleSelect = false;                
            },

            // one or multiple parking selected
            ParkingsSelected (parkings) {
                
                this.ParkingMultiSelect = false;
                this.Parkings = parkings.filter((parking) => {
                    if (parking.active) {
                        return parking;
                    }
                });

                if (this.Parkings.length == 0) {
                    this.SelTotalParking = "";
                }else {
                    this.SelTotalParking = this.Parkings.length + " Selected";
                }

            },
            
            // now add the parking & get back to the list
            async AssignParking () {
                if (this.Requesting) return;

                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-asgnparking');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead) {
                    this.Requesting = true;

                    let formData = new FormData();
                    formData.append('tenant', this.SelUnitTenantID);
                    formData.append('unit', this.SelUnitNumber);

                    // all parking ids
                    let Parkings = '';
                    this.Parkings.forEach((key, value) => {
                        Parkings += key.id + ',';
                    });Parkings = Parkings.slice(0, -1);
                    formData.append('parking', Parkings);

                    // load data from server
                    const ReturnData = await Request.postServer('property/parking/assign.php', formData);
                    this.Requesting = false;

                    if (ReturnData.error == false) {
                        this.$emit('close', ReturnData.response);                    
                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again later or contact support.";
                    }
                }
            },

           
        }


    };
</script>

<style scoped>
span.__formerror {margin-left: 0 !important;}
</style>