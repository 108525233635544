<script>

      import Request from '../../../javascript/getRequest';

      // components
      import SkeletonVue from '../../blocks/Skeleton.vue';
      import ReplaceImage from '../../blocks/ReplaceImage.vue';

      // modal
      import PropModal from '../../modal/PropModal.vue';

      export default {
            name: 'TenantHistory',
            props: ['tenant'],
            emits: [],
            components: {
                  SkeletonVue,
                  ReplaceImage,

                  PropModal,
            },

            data () {
                  return {
                        TenantID: this.tenant.tid,
                        PropertiesHistory: [],
                        isRequesting: true,

                        ShowTenantHistory: false,
                        TenantHistory: {},
                  }
            },

            mounted () {
                  this.LoadTenantHistory();
            },

            methods: {
                  async LoadTenantHistory () {
                        const ReturnData = await Request.getData('owner/tenant/history.php?tenant=' + this.TenantID);
                        if (ReturnData && !ReturnData.error) {
                              this.PropertiesHistory = ReturnData.response;
                              this.isRequesting = false;
                        }
                  },

                  CloseModal () {
                        this.ShowTenantHistory = false;
                  },

                  ShowHistory (history) {
                        this.TenantHistory = history;
                        this.ShowTenantHistory = true;
                  }
            }
      }

</script>

<template>

      <!-- Skeleton -->
      <div v-if="isRequesting" style="margin-top:55px">
            <SkeletonVue :data="4" />
      </div>
      
      <div v-else class="prop-history">
            <div class="prop--table">
                  <div class="prop-row row-header">
                        <div class="column column35">Property/Unit</div>
                        <div class="column column30">Start/Moveout</div>
                        <div class="column column15">Duration</div>
                        <div class="column column20">History</div>
                  </div>
            
                  <!-- history list -->
                  <div class="prop-row transAe" style="cursor: default;" v-for="(history, index) in PropertiesHistory" :key="history"  :class="index % 2 ? 'odd' : 'even'">
                        <div class="column column35">
                              <div class="cc-prop">
                                    <div class="image">
                                          <ReplaceImage :source="history.property.thumbnail"  />
                                    </div>
                  
                                    <div class="content">
                                          <h3 style="font-size: 10pt;">{{ history.property.name }}</h3>
                                          <p>{{ history.unit.name }}</p>
                                    </div>`
                              </div>
                        </div>
            
                        <div class="column column30">
                              <div class="dates">
                                    <p><span style="font-weight: 500;">Rented:</span> {{ history.start }}</p>
                                    <p><span style="font-weight: 500;">Moveout:</span> {{ history.end }}</p>
                              </div>
                        </div>
            
                        <div class="column column15">
                              <p>{{ history.duration }} Months</p>
                        </div> 
            
                        <div class="column column20">
                              <span style="color: #449adf;font-weight: 600; cursor: pointer;" @click="ShowHistory(history)">Check History</span>
                        </div>
                  </div>
                  <!-- history list -->

        </div>
      </div>

      <PropModal v-if="ShowTenantHistory == true" :from="'show-tenat-history'" :data="TenantHistory" @modalclose="CloseModal" />

</template>