<template>
    <div class="prop-form _propscrl">        
        <div class="form-area" style="padding-bottom: 65px;">
            <form name="propform" id="propform-plan">
                <div class="pf--title">
                    <h3>Assign Floorplan</h3>
                    <p>Please select the floorplan for this unit.</p>
                </div>

                <div class="pf--info" style="margin-bottom: 45px;">
                    <div class="icon">
                        <img src="./../../../images/icons/info-blue.svg" />
                    </div>
                    <div class="content">
                        <p>If you didn't find any floorplan in the list you can simply add new plan to your property.</p>
                    </div>
                </div>

                <div class="pf--input">
                    <p>Property</p>
                    <PropertySelected :property="data.property" />
                </div>

                <div class="pf--input">
                    <p>Unit</p>
                    <UnitSelected :unit="data.unit" />
                </div>

                <div class="pf--input" style="position:relative;">
                    <p>Floorplan</p>
                    <select v-model="FloorPlan" id="FloorPlan" data-type="select">
                        <option value="-1" selected>- Select One -</option>
                        <option v-for="plan in PropertyFloorPlans" :key="plan" :value="plan.id">{{plan.name}}</option>
                    </select>
                </div>
            </form>
        </div>

        <div class="form-action">
            <div class="form-area">
                <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="AssignPlan">
                    <p v-if="!Requesting">Assign Flooplan</p>
                    <img v-else class="loader" src="./../../../images/gif/btn-loader.gif" />
                </span>
                <span v-if="formError" class="__formerror">{{ infoText }}</span>
            </div>
        </div>
    </div>
</template>

<script>

    // import js
    import Request from './../../../javascript/getRequest';
    import DataCenter from './../../../javascript/DataCenter';
    import FormValidation from './../../../javascript/FormValidation';

    import PropertySelected from './../../blocks/PropertySelected.vue';
    import UnitSelected from './../../blocks/UnitSelected.vue';

    export default {
        name: 'AssignFloorPlan',
        props: ['data'],
        emits: ['close'],
        components: {
            PropertySelected,
            UnitSelected
        },

        data () {
            return {
                FloorPlan : '-1',
                PropertyFloorPlans : [],

                Requesting : true,
                formError : false,
                infoText: false,
            }
        },

        async mounted () {
            // load units data from server
            let getdata = {
                key : 'property',
                value : this.data.property.id
            }
            
            const FloorPlans = await DataCenter.ProPRequest('property-floor-plans', false, getdata);
            this.Requesting = false;

            if (!FloorPlans) {
                this.$emit('close', {
                    addplan : true,
                    updateplan : false,
                    unit : this.data.unit
                });

            }else {
                this.PropertyFloorPlans = FloorPlans;
            }
        },

        methods: {

            async AssignPlan () {
                if (this.Requesting) return;

                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-plan');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead) {
                    this.Requesting = true;

                    let formData = new FormData();
                    formData.append('property', this.data.property.id);
                    formData.append('unit', this.data.unit.id);
                    formData.append('plan', this.FloorPlan);

                    // load data from server
                    const ReturnData = await Request.postServer('owner/property/units/assign-plan.php', formData);
                    this.Requesting = false;
                    
                    if (ReturnData.error == false) {
                        // plan selected
                        let PlanSelected;
                        this.PropertyFloorPlans.map(plan => {
                            if (this.FloorPlan == plan.id) PlanSelected = plan;
                        });

                        this.$emit('close', {
                            addplan : false,
                            updateplan : true,
                            unit : this.data.unit,
                            planselected : PlanSelected
                        });

                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong!";
                    }

                }
            }
        }

    }


</script>