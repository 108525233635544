<template>

    <div class="internal--header">
        <div class="summery fp-smry">
            <ul class="backtounitlist">
                <li><img src="../../../images/employee-detail/arrow-left.svg" @click="BackToList"> <p>Back</p></li>
            </ul>           
        </div>
    </div>

    <div class="prop--table">
        <div class="prop-row row-header" style="padding: 10px 0 !important;">
            <div class="column column20"><p>Date</p></div>
            <div class="column column35"><p>Remarks</p></div>
            <div class="column column15"><p>Billing</p></div>
            <div class="column column15"><p>Payment</p></div>
            <div class="column column15"><p>Balance</p></div>
        </div>

        <div v-if="FinanceLoadingState == true" style="padding-top: 95px;">
            <Skeletion :data="2" />
        </div>

        <div class="prop-row odd" v-else v-for="(trans, index) in TransactionsList" :key="trans"  :class="index % 2 ? 'odd' : 'even'" >
            <div class="column column20">
                <div class="fin-transaction">
                    <p>{{trans.created}}</p>
                </div>
            </div>

            <div class="column column35">
                <div class="fin-transaction">
                    <p>{{trans.remarks}}</p>
                </div>
            </div>

            <div class="column column15">
                <div class="fin-paymethod">
                    <p v-if="trans.billing != '0.0'">{{trans.billing.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        })}}</p>
                </div>
            </div>

            <div class="column column15">
                <div class="fin-paymethod">
                    <p v-if="trans.payment != '0.0'">{{trans.payment.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        })}}</p>
                </div>
            </div>

            <div class="column column15">
                <div class="fin-paymethod">
                    <p>{{trans.balance.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        })}}</p>
                </div>
            </div>

        </div> 


    </div>

</template>

<script>

    import DataCenter from '../../../javascript/DataCenter';
    import Skeletion from '../../blocks/Skeleton.vue';

    export default {
        name: 'TemamtLedger',
        props: ['unit', 'assignment', 'property'],
        emits: ['back', 'addpayment', 'addtrans'],
        components: {
            Skeletion,
        },

        data() {
            return {
                TransactionsList : [],
                FinanceLoadingState : true,
            }
        },

        async mounted () {

            var property, unit, tenant;
            property = this.property;
            unit = this.unit;
            // tenant = this.unit.tenant.id;

            // now load current unit/tenant transactions
            // load all the transaction of this property
            let getdata = {
                key : 'unit',
                value : this.unit
            }
            
            const Transactions = await DataCenter.ProPRequest('unit-transaction-list', false, getdata);
            this.FinanceLoadingState = false;

            if (!Transactions) {
                this.TransactionsList = [];
            }else {
                this.TransactionsList = Transactions;
            }
            
        },

        methods: {

            BackToList () {
                this.$emit('back');
            },


            
        },
}
</script>

<style scoped>
 .btn-secondary {
    float: right;
    margin-right: 15px;
}
div.prop--table div.prop-row {
    padding: 2px !important;
    margin-bottom: 0px !important;
}
div.internal--header {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #E5E5E5;
    overflow: hidden;
    margin-bottom: 20px;
}

div.internal--header div.summery {
    width: 50%;
    float: left;
}
div.internal--header div.actions {
    width: 50%;
    float: right;
}
</style>