<template>
    <div class="prop-form _propscrl">
        <div class="form-area" style="padding-bottom: 65px;">
            
            <form name="propform" id="propform-finance" style="display: block;">
                <div class="pf--title">
                    <h3>Add Payment</h3>
                    <p>Add new payment for this unit/tenant.</p>
                    <p style="font-size: 10pt;">* Indicate a required field</p>
                </div>

                <div class="pf--input" style="height: 75px;overflow:unset; position:relative;">
                    <p>Unit/Tenant *</p>
                    <input v-if="data.hasTenant" type="text" readonly disabled v-model="SelUnit" />
                    <input v-else v-model="SelUnit" type="text" 
                                placeholder="Choose One" id="Units" data-type="len" 
                                data-max="5" readonly 
                                @click="UnitsSingleSelect = true"  :disabled="Requesting" />
                    <!-- unit selections -->
                    <UnitSelection v-if="UnitsSingleSelect == true" 
                                    :units="PropertyUnits"
                                    :distyle="'top: 0;left: 20px;'"
                                    @lstboxclse="UnitSelected" />
                </div>

                <div class="pf--multi-input" style="height: 84px;">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Payment Type *</p>
                            <select v-model="PaymentType" id="PaymentType" data-type="select" :disabled="Requesting">
                                <option value="-1">- Select One -</option>
                                <option value="check">Check</option>
                                <option value="Credit Card">Credit Card</option>
                                <option value="moneyorder">Money Order</option>
                                <option value="venmo">Venmo</option>
                                <option value="zelle">Zelle</option>
                            </select>
                        </div>
                    </div>

                    <div class="half hright">
                        <div class="pf--input">
                            <p>Amount ($) *</p>
                            <input type="number" v-model="TransAmount" id="TransAmount" 
                            placeholder="Price" data-type="len" data-max="1" :disabled="Requesting" />
                        </div>
                    </div>
                </div>

                <!-- description of the transaction -->
                <div class="pf--input">
                    <p>Remarks *</p>
                    <input v-model="Remarks" type="text" placeholder="Transaction notes" id="Remarks" data-type="len" data-max="0"  :disabled="Requesting" />
                </div>

            </form>

        </div>

        <div class="form-action">
            <div class="form-area">
                <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="AddNewTransaction">
                    <p v-if="!Requesting">Add Transaction</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>
                <span v-if="formError" class="__formerror">{{ infoText }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    
    //@ JS Modules
    import Request from './../../javascript/getRequest';
    import DataCenter from './../../javascript/DataCenter';

    import FormValidation from './../../javascript/FormValidation';

    // @component
    import UnitSelection from './../web-forms/UnitSelection.vue';

    export default {

        name: 'AddPayment',
        props: ['data'],
        emits: ['close'],
        components: {
            UnitSelection,
        },

        data () {
            return {
                // Form Modal
                PaymentType : '-1',
                TransAmount : 0,
                Remarks: '',
                
                Requesting: false,

                // unit/tenant
                SelectedProperty : this.data.property,
                SelectedUnit : '',
                SelectedTenant : '',
                SelectedAssignment : '',
                SelUnit: 'Select Unit/Tenant',

                // units
                SelUnit : '',
                SelUnitID : null,
                SelUnitTenantID: null,
                PropertyUnits : false,
                UnitsSingleSelect : false,
                SelectedUnit : null,

                // form error
                formError : false,
                infoText : '',
            }
        },

        async mounted () {

            // property id
            if (this.data.hasTenant) {
                this.SelectedUnit = this.data.unit;
                this.SelectedTenant = this.data.tenant;
                this.SelUnit = this.data.name;
                this.SelectedAssignment = this.data.assignment;
            
            }else {
                this.Requesting = true;
                this.LoadUnitTenants (this.data.property);
            }
        },

        methods: {

            async LoadUnitTenants (property) {
                // load units data from server
                let getdata = {
                    key : 'property',
                    value : property
                }
                
                const Units = await DataCenter.ProPRequest('property-units', false, getdata);
                this.Requesting = false;
                
                // now filter units which only have assigned tenants
                if (!Units) {}else {
                    this.PropertyUnits = Units;

                    // filter only those unit who have tenants
                    this.PropertyUnits = this.PropertyUnits.filter (unit => {
                        if (unit.status == 'rented') return unit;
                    });
                }  
            },

            // once unit is selected from single select
            UnitSelected (unit) {
                if (unit !== null) {
                    this.SelUnit = unit.tenant.fullname;
                    this.SelUnitID = unit.id;
                    this.SelUnitTenantID = unit.tenant.id
                    this.SelectedUnit = unit.id;
                    this.SelectedTenant = unit.tenant.id
                    this.SelectedAssignment = unit.assignment;
                }

                this.UnitsSingleSelect = false;                
            },

            // add new transaction
            async AddNewTransaction () {
                if (this.Requesting) return;
                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-finance');
                let canGoAhead = FormValidation.Validate (form);

                if (canGoAhead) {
                    this.Requesting = true;

                    // collect form data
                    let formData = new FormData();
                    formData.append('property', this.SelectedProperty);
                    formData.append('unit', this.SelectedUnit);
                    formData.append('tenant', this.SelectedTenant);
                    formData.append('assignment', this.SelectedAssignment);
                    formData.append('type', this.PaymentType);
                    formData.append('amount', this.TransAmount);
                    formData.append('remarks', this.Remarks);

                    // upload and load data from server
                    const ReturnData = await Request.postServer('owner/finance/add-payment.php', formData);
                    this.Requesting = false;

                    // now check if the email already exists
                    if (ReturnData.error == false) {
                        // now close modal, and load current property
                        this.$emit('close', ReturnData.response);

                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again.";
                    }                   

                }
            }
        },

    }
</script>