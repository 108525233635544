<template>
    <div class="gs--body">
        <h3 style="margin-top: -30px; margin-bottom: -5px;">{{this.data.title}}</h3>        
        <p class="info-text">{{this.data.description}}</p>
        <p style="color: red;font-size: 10pt;" v-if="FormError">{{this.data.error}}</p>

        <ul class="propattachment _propscrl" v-cloak @drop.prevent="dropHandler" @dragover.prevent>

            <li class="dragndrop" v-if="Attachments.length <= 0" @click="filesclick">
                <img src="../../images/svg/attach-files.svg" />
                <p>Drag your files here</p>
            </li>

            <li v-for="attachment in Attachments" :key="attachment">
                <div class="image">
                    <img v-if="attachment.extension == 'doc' || attachment.extension == 'docx'" src="./../../images/attachment/atch-docx.svg" />

                    <img v-else-if="attachment.extension == 'xls' || attachment.extension == 'xlsx'" src="./../../images/attachment/atch-xlsx.svg" />

                    <img v-else-if="attachment.extension == 'ppt' || attachment.extension == 'pptx'" src="./../../images/attachment/atch-pptx.svg" />

                    <img v-else-if="attachment.extension == 'jpeg' || attachment.extension == 'jpg' || attachment.extension == 'png'" src="./../../images/attachment/atch-image.svg" />

                    <img v-else-if="attachment.extension == 'pdf'" src="./../../images/attachment/atch-pdf.svg" />

                    <img v-else src="./../../images/attachment/atch-others.svg" />
                </div>
                <div class="content">
                    <h3>{{attachment.name}}</h3>

                    <p v-if="attachment.matched">File Supported</p>
                    <p v-else style="color: #F00">File Not Supported</p>

                    <p v-if="!attachment.largesize">{{attachment.size}} Kb</p>
                    <p v-else style="color: #F00">{{attachment.size}}12.05 Kb</p>                    
                </div>

                <div class="close">
                    <img src="./../../images/icons/close.svg" @click="RemoveAttachment (attachment)" />
                </div>
            </li>
        </ul>

    </div>

    <div class="gs--action">
        <div class="btn-secondary addfiles" @click="filesclick">Add Files</div>

        <span class="primary-btn-loader" style="float: left;margin-top: 15px;padding: 7px;width: 100px;" :class="Requesting ? 'btn-disabled' : ''" @click="UploadImages">
            <p v-if="!Requesting">Upload</p>
            <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
        </span>

        <input type="file" ref="files" multiple @change="handleFilesUploads" style="display:none;" />
    </div>
</template>

<script>
    // js import
    import Methods from './../../javascript/Handler';

    export default {
        name: 'AttachDocuments',
        props: ['data'],
        emits: ['close'],
        data () {
            return {
                AttachmentFiles : [],
                Attachments : [],

                Requesting: false,
                FormError: false,
            }
        },

        methods : {
            
            UploadImages () {
                if (this.Requesting) return;

                if (this.Attachments.length === 0) return;

                // return the attachment and process upload
                var CanGoAhead = this.CheckFilesError ();

                if (CanGoAhead) {
                    this.Requesting = true;
                    this.$emit('close', this.AttachmentFiles);
                }
            },

            CheckFilesError () {
                var CanGoAhead = true;

                this.Attachments.forEach (attach => {
                    if (attach.largesize || !attach.matched) {
                        CanGoAhead = false;
                    }
                });

                if (CanGoAhead) this.FormError = false;
                else this.FormError = true;

                // return
                return CanGoAhead;
            },

            filesclick () {
                this.$refs.files.click();
                this.FormError = false;
            },

            handleFilesUploads () {
                if (this.Requesting) return;

                this.AttachmentFiles = this.$refs.files.files;
                let extensionScheme = this.data.fileschema;
                for (let element of this.AttachmentFiles) {
                    let NewFile = Methods.AttachmentUploadHandler (element, extensionScheme, 5);            
                    this.Attachments.push (NewFile);
                    this.CheckFilesError ();
                };
            },

            RemoveAttachment (file) {
                if (this.Requesting) return;

                this.Attachments = this.Attachments.filter(attachment => {
                    if (file.name == attachment.name) {}
                    else {return attachment;}
                });

                this.CheckFilesError ();
            },

            dropHandler (ev) {
                if (this.Requesting) return;

                this.AttachmentFiles = ev.dataTransfer.files;
                let extensionScheme = this.data.fileschema;
                for (let element of this.AttachmentFiles) {
                    let NewFile = Methods.AttachmentUploadHandler (element, extensionScheme, 2);            
                    this.Attachments.push (NewFile);
                    this.CheckFilesError ();
                };

                console.log ()
            }
        }
    };
</script>