<script>

    import AccountChanges from './../../javascript/AccountChanges';

    export default {
        name: 'PropStopAccountChanges',
        props: ['accountype'],
        mounted () {
            AccountChanges.AccountChangesHandler (this.accountype);
        }
    };

</script>
