<template>
  
  <div v-if="TenantisRented == 'true'" class="custom-content" :style="fullscreen ? 'width: 100%;' : ''">
    <div class="prop-info-alert" v-if="tenant.type != 'email' && showTenantAlert" @click="showTenantAlert = false">
      This tenant account is created without email account!
    </div>

    <div class="cc-customer _propscrl">
      <div class="back-btn">
        <img src="../../images/tenant-detail/arrow-left.svg" @click="backbtnClick" />
      </div>

      <TenantPropertySelect v-if="isPropertySelection" :distyle="''" :properties="TenantProperties"
        @prosel="PropertyChoosed"
        @proboxclse="propertylistClose"
      />

      <div class="tenant-properties" v-if="TenantProperties.length >= 1" @click="isPropertySelection = true">
        <div class="image">
          <ReplaceImageVue :source="''" :key="''" />
        </div>
        <div class="content">
          <h3>{{ PropertySelected.propname }}</h3>
          <p>{{ PropertySelected.unitname }}</p>
        </div>
        <div class="select">
          <img src="./../../images/svg/prop-down.svg" />
        </div>
      </div>

      <div class="user-avatar" style="display: none">
        <div class="image">
          <UserImage :init="tenant.fullname" :back="tenant.back" :source="tenant.avatar" />
        </div>
        <div class="name-wrp">
          <h3>{{ tenant.fullname }}</h3>
          <p>{{ tenant.start_date }}</p>
        </div>
      </div>

      <ul class="contact">
        <li>
          <div class="icon">
            <img src="../../images/tenant-detail/mail.svg" />
          </div>

          <p v-if="tenant.type == 'email'">{{ tenant.email }}</p>
          <p v-else style="filter: blur(4px)">xxxxxx@xxxx.xxxx</p>
        </li>
        <li>
          <div class="icon">
            <img src="../../images/tenant-detail/smartphone.svg" />
          </div>
          <p v-if="TenantMobile">{{ tenant.mobile }}</p>
          <input v-else type="text" readonly placeholder="Tenant Mobile" />
        </li>
        <!-- <li>
                    <div class="icon">
                        <img src="../../images/tenant-detail/facebook.svg">
                    </div>
                    <p v-if="TenantFacebook">{{tenant.facebook}}</p>
                    <input v-else type="text" readonly placeholder="Facebook Profile">
                </li>
                <li>
                    <div class="icon">
                        <img src="../../images/tenant-detail/twitter.svg">
                    </div>
                    <p v-if="TenantTwitter">{{tenant.twitter}}</p>
                    <input v-else type="text" readonly placeholder="Twitter Profile">
                </li>
                <li>
                    <div class="icon">
                        <img src="../../images/tenant-detail/airbnb.svg">
                    </div>
                    <p v-if="TenantAirBnb">{{tenant.airbnb}}</p>
                    <input v-else type="text" readonly placeholder="Tenant Airbnb">
                </li> -->
      </ul>

      <div v-if="!isNewTenantAccount" class="flor-unit">
        
        <div v-if="isMoveOutInitiated" class="pfk--info" @click="CompleteMoveOut = true">
            <div class="icon">
                <img src="./../../images/icons/info-blue.svg" />
            </div>
            <div class="content">
                <p>This tenant has already initiate the move-out process!</p>
                <a href="#">Complete Moveout</a>
            </div>
        </div>

        <h2>Floor/unit</h2>
        <p>
          {{ FloorName[PropertySelected.floor] }} Floor, Unit :
          {{ PropertySelected.unitnum }}
        </p>
        <p>Rent: ${{ PropertySelected.rent }}</p>

        <p v-if="PropertySelected.balance < 0" style="color: #f00">
          Balance: -${{ -PropertySelected.balance }}
        </p>
        <p style="color: #919191" v-else>Balance: ${{ PropertySelected.balance }}</p>

        <p>Deposit: ${{ PropertySelected.deposit }}</p>

        <span @click="ShowRentHistory = true" class="btn-secondary">Rent & Deposits History</span>

      </div>

      <div v-else class="flor-unit">
        <h2>Floor/unit</h2>
        <p>Unit not assigned!</p>
        <span class="btn-secondary" @click="AssignTenantUnitModal">Assign Unit</span>

        <div class="walkthrough psanim-3 fadeInRight" v-if="AssignUnitWalk">
          <h3>Assign property unit here!</h3>
          <p>
            This account is not assigned by a property unit, you can assign here or from
            property unit page!
          </p>
          <span class="btn-secondary" @click="AssignUnitWalk = false">Okay</span>
        </div>
      </div>

      <ul class="links">
        <li @click="CreateNewTicket">
          <img src="./../../images/tenant-detail/add-tickets.svg" />
          <p>Create New Ticket</p>
        </li>

        <li>
          <img src="./../../images/tenant-detail/add-issue.svg" />
          <p>Add Issue</p>
        </li>

        <li @click="AddTransactionModal = true;">
          <img src="./../../images/tenant-detail/add-deposits.svg" />
          <p>Add Transaction</p>
        </li>

        <li @click="UploadAttachment = true" style="position: relative">
          <img src="./../../images/tenant-detail/add-agreement.svg" />
          <p>Add Agreement</p>
          <div
            class="walkthrough psanim-3 fadeInRight"
            style="top: -65px; left: 40px"
            v-if="AddAgreementWalk"
            @click="AddAgreementWalk = false"
          >
            <h3>Add Agreement!</h3>
            <p>
              This account is assigned by a property unit, you can now add or upload the
              agreement documents here!
            </p>
            <span class="btn-secondary">Okay</span>
          </div>
        </li>

        <li v-if="tenant.isRented" @click="toAddDeposits">
          <img src="./../../images/tenant-detail/add-deposits.svg" />
          <p>Add Deposits</p>
        </li>

        <li v-if="tenant.isRented" @click="toChangeRentAmoun">
          <img src="./../../images/tenant-detail/rent-change.svg" />
          <p>Change Rent Amount</p>
        </li>

        <li v-if="!SendingInvitation" @click="InviteTenant">
          <img src="./../../images/tenant-detail/invitation.svg" />
          <p>Resend Invitation</p>
        </li>
        <li v-else style="text-decoration: none;color: #000000;">
          <p>Sending...</p>
        </li>

        <li @click="InitiateMoveOut = true">
          <img src="./../../images/tenant-detail/moveout.svg" />
          <p>Initiate Moveout</p>
        </li>

        <li style="display: none;">
          <img src="./../../images/tenant-detail/add-score.svg" />
          <p>Update Score</p>
        </li>

        <li v-if="tenant.type == 'noemail'" @click="UpdateTenantEmail = true">
          <img src="./../../images/tenant-detail/mail.svg" />
          <p>Convert To Email</p>
        </li>

      </ul>
    </div>

    <div class="company--info">
      <div class="top-tan-dtls">
        <ul v-if="!isNewTenantAccount">
          <li>
            <h3>{{ PropertySelected.expiry.count }}</h3>
            <p
              v-if="PropertySelected.expiry.time == 'Expired'"
              class="mnth"
              style="background-color: #f44336"
            >
              {{ PropertySelected.expiry.time }}
            </p>
            <p v-else class="mnth">{{ PropertySelected.expiry.time }}</p>
            <p>Expiry Date</p>
            <p class="dt">({{ PropertySelected.status }})</p>
          </li>
          <li>
            <h3 class="mar-bn">{{ PropertySelected.tickets }}</h3>
            <p>All Tickets</p>
          </li>
          <li>
            <h3 class="mar-bn">{{ PropertySelected.issues }}</h3>
            <p>Issues</p>
          </li>
          <li style="display: none">
            <h3 class="mar-bn">+{{ PropertySelected.score }}</h3>
            <p>Tenant Score</p>
          </li>
        </ul>

        <ul v-else>
          <li>
            <h3>0</h3>
            <p class="mnth">Month</p>
            <p>Expiry Date</p>
            <p>Not Rented Yet!</p>
          </li>

          <li>
            <h3 class="mar-bn">0</h3>
            <p>All Tickets</p>
          </li>

          <li>
            <h3 class="mar-bn">0</h3>
            <p>Issues</p>
          </li>
        </ul>
      </div>
      <ul class="custo--tabs">
        <li
          :class="{ active: pmenu.active }"
          v-for="pmenu in PageMenu"
          :key="pmenu.id"
          @click="PageMenuClickHandler(pmenu)"
        >
          {{ pmenu.name }}
        </li>
      </ul>

      <div class="custo--details _propscrl">
        <Agreement
          v-if="TenantDetailPage == 'Agreement'"
          :profile="PropertySelected"
          :agreements="TenantAgreements"
          :key="AgreementUpdate"
          @addgrement="AddAgreement"
          @editgrement="EditAgreement"
        />

        <Issue v-if="TenantDetailPage == 'Issues'" :profile="PropertySelected" />
        <Score v-if="TenantDetailPage == 'Score'" :profile="PropertySelected" />
        <Ticket
          v-if="TenantDetailPage == 'Tickets'"
          :profile="PropertySelected"
          @createticket="CreateNewTicket"
        />
        <Balance
          v-if="TenantDetailPage == 'Balance'"
          :profile="PropertySelected"
          :tenant="tenant"
        />
      </div>
    </div>
  </div>

  <div v-else class="custom-content" :style="fullscreen ? 'width: 100%;' : ''">
    
    <div class="cc-customer" v-if="!ShowTenantHistory">
      <div class="back-btn">
        <img src="../../images/tenant-detail/arrow-left.svg" @click="backbtnClick" />
      </div>

      <div class="user-avatar">
        <div class="image">
          <UserImage :init="tenant.fullname" :back="tenant.back" :source="tenant.avatar" />
        </div>
        <div class="name-wrp">
          <h3>{{ tenant.fullname }}</h3>
          <p>{{ tenant.start_date }}</p>
        </div>
      </div>

      <ul class="contact">
        <li>
          <div class="icon">
            <img src="../../images/tenant-detail/mail.svg" />
          </div>

          <p v-if="tenant.type == 'email'">{{ tenant.email }}</p>
          <p v-else style="filter: blur(4px)">xxxxxx@xxxx.xxxx</p>
        </li>
        <li>
          <div class="icon">
            <img src="../../images/tenant-detail/smartphone.svg" />
          </div>
          <p v-if="TenantMobile">{{ tenant.mobile }}</p>
          <input v-else type="text" readonly placeholder="Tenant Mobile" />
        </li>
      </ul>

      <div class="flor-unit">
        <h2>Floor/unit</h2>
        <p>Unit not assigned!</p>
        <span class="btn-secondary" @click="AssignTenantUnitModal">Assign Unit</span>

        <div class="walkthrough psanim-3 fadeInRight" v-if="AssignUnitWalk">
          <h3>Assign property unit here!</h3>
          <p>
            This account is not assigned by a property unit, you can assign here or from
            property unit page!
          </p>
          <span class="btn-secondary" @click="AssignUnitWalk = false">Okay</span>
        </div>
      </div>

      <ul class="links">
        
        <li v-if="!SendingInvitation" @click="InviteTenant">
          <p>Resend Invitation</p>
        </li>

        <li v-else style="text-decoration: none;color: #000000;">
          <p>Sending...</p>
        </li>
      </ul>

    </div>

    <!-- Former Tenant History  -->
    <div class="company--info" :style="ShowTenantHistory ? 'width: 100%' : ''">
      
      <div class="back-btn" v-if="ShowTenantHistory" style="padding: 25px;padding-bottom: 0;">
        <img src="../../images/tenant-detail/arrow-left.svg" style="cursor: pointer;" @click="ShowTenantHistory = false" />
      </div>

      <TenantHistory v-if="ShowTenantHistory" :tenant="tenant" />
      
      <div v-else class="emptyskeleton">
          <img src="./../../images/icons/empty-result.svg" class="psanim-1 fadeInUp" style="width: 220px;">
          <p class="psanim-2 fadeInUp">This tenant has not been assigned/rented to any unit. <br>You can check the tenant's properties history.</p>
          <div class="btn-secondary psanim-3 fadeInUp" @click="ShowTenantHistory = true">
              <p>Show Tenant History</p>
          </div>
      </div>

    </div>

  </div>

  <!-- Modal -->
  <RightModal v-if="AddNewTicketModal" :form="'add-ticket'" :data="AddTicketData" @modalclose="CloseModalBox" />

  <RightModal v-if="AssignTenantUnit" :form="'assign-unit'" :data="AssignTenantData" @modalclose="CloseAssignUnitModal" />
  <RightModal v-if="ViewEditContract" :form="'view-contract'" :data="EditViewContractData" @modalclose="CloseViewContractModal" />

  <RightModal v-if="ChangeRentAmount" :form="'change-rent-amount'" :data="RentAmountData" @modalclose="CloseChangeRentAmount" />
  <RightModal v-if="AdditionalDeposits" :form="'additional-deposits'" :data="AdditionalDepositsData" @modalclose="CloseAdditionalDeposits" />

  <PropModal v-if="ShowRentHistory" :from="'rent-history'" :data="RentHistoryData" @modalclose="CloseRentHistory" />
  <PropModal v-if="UpdateTenantEmail" :from="'no-emailto-email'" :data="tenant" @modalclose="CloseUpdateEmailModal" />
  <PropModal v-if="UploadAttachment == true" :from="'upload-document'" :data="AttachmentData" @modalclose="CloseUploadModal" />

  <!-- moveout processes -->
  <RightModal v-if="InitiateMoveOut == true" :form="'initiate-moveout'"  :data="InitiateMoveOutData" @modalclose="CloseInitiateMoveOut" />
  <RightModal v-if="CompleteMoveOut == true" :form="'complete-moveout'"  :data="CompleteMoveOutData" @modalclose="CloseCompleteMoveOut" />

  <!-- add transactions -->
  <RightModal v-if="AddTransactionModal" :form="'add-transaction'" :data="AddTransactionData" @modalclose="CloseTransactionModal" />

</template>

<script>
    // import javascript libraries
    import Storage from "./../../javascript/Storage";
    import Locale from "./../../javascript/Locale";
    import Request from "./../../javascript/getRequest";
    import DataCenter from "./../../javascript/DataCenter";

    // @import components
    import UserImage from "./../blocks/UserImage.vue";
    import ReplaceImageVue from "../blocks/ReplaceImage.vue";

    import Agreement from "./tenant/Agreement.vue";
    import Issue from "./tenant/Issue.vue";
    import Score from "./tenant/Score.vue";
    import Ticket from "./tenant/Ticket.vue";
    import Balance from "./tenant/Balance.vue";
    
    // tenant history with previous properties
    import TenantHistory from "./tenant/TenantHistory.vue";

    import TenantPropertySelect from "./../blocks/TenantPropertySelect.vue";

    import RightModal from "./../../components/modal/RightModal.vue";
    import PropModal from "./../../components/modal/PropModal.vue";
    import Handler from "../../javascript/Handler";

    export default {
      name: "OwnerTenantDetail",
      props: ["tenant", "property", "properties", "fullscreen"],
      emits: ["backtolist", "updatetenant"],
      components: {
        UserImage,
        ReplaceImageVue,

        Agreement,
        Issue,
        Score,
        Ticket,
        Balance,
        TenantHistory,

        TenantPropertySelect,

        RightModal,
        PropModal,
      },

      data() {
        return {
          FloorName: Locale.FloorNumName(),

          PageMenu: [
            {
              id: 1,
              name: "Agreement",
              active: true,
            },
            {
              id: 2,
              name: "Tickets",
            },
            {
              id: 3,
              name: "Issues",
            },
            {
              id: 4,
              name: "Score",
            },
            {
              id: 5,
              name: "Balance",
            },
          ],

          showTenantAlert: true,

          TenantDetailPage: "Agreement",
          AgreementUpdate: false,
          TenantAgreements: false,

          TenantMobile: false,
          TenantFacebook: false,
          TenantTwitter: false,
          TenantAirBnb: false,

          // create ticket
          AddTicketData: false,
          AddNewTicketModal: false,

          // agreement
          UploadAttachment: false,
          AttachmentData: {
            title: "Upload Contracts",
            description:
              'Please click on the "Add Files" button or drag and drop an image below to upload aggrements.',
            error: "Please upload a PDF or JPG of the scanned contract!",
            fileschema: ["jpeg", "jpg", "pdf"],
          },

          isNewTenantAccount: this.tenant.isRented == "true" ? false : true,
          AssignUnitWalk: false,
          AddAgreementWalk: false,

          AssignTenantUnit: false,
          AssignTenantData: {},

          ViewEditContract: false,
          EditViewContractData: null,

          TenantProperties: this.tenant.properties,
          PropertySelected: this.tenant.properties[0],
          PropertySelectedValue: "",
          isPropertySelection: false,

          // rent amount
          ChangeRentAmount: false,
          RentAmountData: null,
          AdditionalDeposits : false,
          AdditionalDepositsData: null,

          // rent history
          ShowRentHistory: false,
          RentHistoryData: [],

          // noe-email to email
          UpdateTenantEmail: false,

          TenantisRented: this.tenant.isRented,
          SendingInvitation: false,

          // add transactions
          AddTransactionModal: false,
          AddTransactionData: {
            type: 'choose',
            isMainPage: true,
            property: false,
            unit: false,
            tenant: false,
          },

          // moveout
          isMoveOutInitiated: false,
          InitiateMoveOut: false,
          InitiateMoveOutData: {
            tenant: this.tenant,
            property: this.tenant.properties[0]
          },
          CompleteMoveOut: false,
          CompleteMoveOutData: {
            tenant: this.tenant,
            property: this.tenant.properties[0]
          },

          // tenant history
          ShowTenantHistory: false,

        };
      },

      mounted () {

        console.log (this.tenant);

        if (this.TenantisRented == 'true') {
          this.RentHistoryData = {
            root: "owner",
            unit: this.PropertySelected.unit,
            assignment: this.PropertySelected.assignment,
            name: this.FloorName[this.PropertySelected.floor],
          };

          // current tenant contact
          this.TenantMobile = this.contactInfoNullCheck(this.tenant.mobile);
          this.TenantFacebook = this.contactInfoNullCheck(this.tenant.facebook);
          this.TenantTwitter = this.contactInfoNullCheck(this.tenant.twitter);
          this.TenantAirBnb = this.contactInfoNullCheck(this.tenant.airbnb);

          // load agreement first
          if (this.TenantDetailPage == "Agreement") {
            this.LoadContractAgreement();
          }

          // moveout initiated
          this.isMoveOutInitiated = this.PropertySelected.moveout.moveout;
        }
      },

      methods: {

        CloseTransactionModal (trans) {
            this.AddTransactionModal = false;
        },

        CloseInitiateMoveOut (moveout) {
          if (moveout) this.PropertySelected.moveout.moveout = true;
          this.InitiateMoveOut = false;
        },

        CloseCompleteMoveOut (moveout) {
          if (moveout) {
            // update the current tenant
            // remove current property from the list
            this.$emit("backtolist", true);
          }

          // close the modal
          this.CompleteMoveOut = false;
        },

        async InviteTenant () {
            let getdata = {key: "tenant",value: this.tenant.id};
            this.SendingInvitation = true;
            await DataCenter.ProPRequest('tenant-invitation', false, getdata);
            Handler.PropNotification ('Successfully invited tenant to your property!');
            this.SendingInvitation = false;
        },

        CloseRentHistory() {
          this.ShowRentHistory = false;
        },

        CloseUpdateEmailModal (mail) {
          if (mail) {
            this.tenant.email = mail;
            this.tenant.type = 'email';
          }
          this.UpdateTenantEmail = false;
        },

        // set change rent amount data
        toChangeRentAmoun() {
          this.RentAmountData = {
            tenant: this.tenant.tid,
            unit: this.PropertySelected.unit,
            rent: this.PropertySelected.rent,
            assignment: this.PropertySelected.assignment
          };

          // show the model
          this.ChangeRentAmount = true;
        },

        // additional deposits
        toAddDeposits () {
          this.AdditionalDepositsData = {
            tenant: this.tenant,
            unit: this.PropertySelected.unit,
            rent: this.PropertySelected.rent,
            assignment: this.PropertySelected.assignment,
            property: this.PropertySelected
          };

          console.log (this.AdditionalDepositsData);

          // show the model
          this.AdditionalDeposits = true;
        },

        // change rent amount model close
        CloseChangeRentAmount(update) {
          this.ChangeRentAmount = false;
        },

        // close additional deposits data
        CloseAdditionalDeposits (update) {
          console.log (update);
          this.AdditionalDeposits = false;
        },

        // property selected
        PropertyChoosed(property) {
          this.PropertySelected = property;

          // set property/unit for move out
          this.InitiateMoveOutData.property = this.PropertySelected;
          this.CompleteMoveOutData.property = this.PropertySelected;

          // now close the list too
          this.propertylistClose();

          this.PageMenuClickHandler({
            id: 1,
            name: "Agreement",
          });
        },

        // close property list
        propertylistClose() {
          this.isPropertySelection = false;
        },

        // add agreement
        AddAgreement() {
          this.UploadAttachment = true;
        },

        backbtnClick() {
          this.$emit("backtolist", false);
        },

        contactInfoNullCheck(contact) {
          if (contact == "" || contact == undefined || contact == null) return false;
          else return true;
        },

        PageMenuClickHandler(menu) {
          this.PageMenu = this.PageMenu.filter((key) => {
            key.active = false;
            if (key.id == menu.id) key.active = true;
            return key;
          });

          // update current menu name
          this.TenantDetailPage = menu.name;
        },

        CreateNewTicket() {
          this.AddTicketData = {
            property: this.property,
            tenant: true,
            tenantData: this.tenant,
          };
          this.AddNewTicketModal = true;
        },

        // create ticket
        CloseModalBox(tickets) {
          if (tickets) {
            Storage.deleteCache("ticket-list");
            Storage.storeCache("ticket-list", tickets);
          }

          this.AddNewTicketModal = false;
        },

        // load agreements
        async LoadContractAgreement() {
          // load gallery data from server
          let getdata = {
            key: "tenant",
            value: this.tenant.id,
          };

          const Contracts = await DataCenter.ProPRequest("tenant-contracts", false, getdata);

          if (Contracts) {
            this.TenantAgreements = Contracts;
          } else {
            this.TenantAgreements = true;
            this.AddAgreementWalk = true;
          }

          this.AgreementUpdate = Math.round(new Date() / 1000);
        },

        // close documents upload modal
        async CloseUploadModal(blobupload) {
          if (blobupload) {
            // now upload the selected files to the server
            let formData = new FormData();
            formData.append("property", this.property.id);
            formData.append("unit", this.tenant.unit);
            formData.append("tenant", this.tenant.id);

            // convert file list into files[array] form data
            blobupload.forEach((element) => {
              formData.append("documents[]", element);
            });

            // upload to the server
            const ReturnData = await Request.postServer(
              "owner/upload/contract.php",
              formData
            );
            if (!ReturnData.error) {
              this.TenantAgreements = ReturnData.response;
              this.AgreementUpdate = Math.round(new Date() / 1000);

              // close the modal
              this.UploadAttachment = false;
            }
          } else {
            // close the modal
            this.UploadAttachment = false;
          }
        },

        EditAgreement(contract) {
          this.EditViewContractData = contract;
          this.ViewEditContract = true;
        },

        CloseViewContractModal(update) {
          this.ViewEditContract = false;
        },

        // assign unit modal open
        AssignTenantUnitModal() {
          // assign unit data
          this.AssignTenantData = {
            property: this.property.id,
            unit: false,
            tenant: this.tenant.id,
            properties: this.properties
          };

          // open modal
          this.AssignTenantUnit = true;
        },

        // assign unit modal close
        CloseAssignUnitModal(tenant) {
          // if succuess assigning update the data
          if (tenant) {
            this.$emit("updatetenant", tenant);
          }

          // close the modal box
          this.AssignTenantUnit = false;
        },
      },
    };
</script>