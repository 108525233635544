<template>    
    <div class="property-created _propscrl">
        <h3>Congratulations, you have added your property!</h3>
        <p>You can now access PropStop's unlimited core features, such as:</p>
        <ul>
            <li>
                <div class="image">
                    <img src="../../images/svg/upload-gallery.svg" />
                </div>
                <div class="content">
                    <h4>Upload Gallery</h4>
                    <p>Upload images in bulk, manage units, or upload a default picture.</p>
                </div>
            </li>

            <li>
                <div class="image">
                    <img src="../../images/svg/modify-units.svg" />
                </div>
                <div class="content">
                    <h4>Units / Floorplans</h4>
                    <p>You can create or modify floorplans, add or remove units, and assign floorplans to each unit.</p>
                </div>
            </li>

            <li>
                <div class="image">
                    <img src="../../images/svg/assign-people.svg" />
                </div>
                <div class="content">
                    <h4>Assign Tenants</h4>
                    <p>Once you have completed your unit configuration, you can add tenants to units and employees to the property.</p>
                </div>
            </li>

            <li>
                <div class="image">
                    <img src="../../images/svg/create-task.svg" />
                </div>
                <div class="content">
                    <h4>Create A Task</h4>
                    <p>You can create and assign tasks to yourself or your employee(s) and they will be notified instantly.</p>
                </div>
            </li>            
        </ul>
    </div>
</template>

<script>
    export default {
        name : 'PropertyCreated',
        emits: ['close'],
        methods: {
            CloseModal () {
                this.$emit('close');
            }
        }
    };
</script>