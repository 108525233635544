<template>
    <div class="gs--body">
        <h3>Close Ticket?</h3>
        <p>Your ticket <strong>{{data.subject}}</strong> will be moved to the closed folder.</p>

        <div class="pf--input" style="margin-top: 25px !important;">
            <p>Close Message</p>                
            <textarea class="reply" placeholder="Type a close message here" v-model="Message"></textarea>
        </div>
    </div>

    <div class="gs--action">
        <div class="btn-primary" @click="confirmModal" ref="confirm" style="width: 120px; display: block;float: left;margin-top: 10px;">Close Ticket</div>
    </div>
</template>

<script>

    export default {
        name: 'ConfirmDeleteTicket',
        props: ['data'],
        emits: ['close'],
        data () {
            return {
                Message: '',
            }
        },
        methods : {
            confirmModal () {
                this.$refs.confirm.innerHTML = 'Working...';
                this.data['message'] = this.Message;
                this.$emit('close', this.data);
            }
        },
        mounted () {
            console.log (this.data);
        }
    };
</script>