<template>
    <div v-if="!property.verified && property.verification == 'false'" class="prop--alert animated fadeInUp">
        <div class="icon">
            <img src="../../images/icons/info-icon.svg" />
        </div>

        <div class="content">
            <h3>Property Verification Alert!</h3>
            <p>Your property <b>{{property.name}}</b> is not verified yet, please update your property, add images, parking, etc and apply for verification.</p>
            <span class="btn-primary" @click="VerifyProperty" style="margin: 0;margin-top: 5px;">Verify Now</span>
        </div>
    </div>

    <div v-if="property.verification == 'true'" class="prop--alert animated fadeInUp">
        <div class="icon">
            <img src="../../images/icons/info-icon.svg" />
        </div>
        <div class="content">
            <p>Your property <b>{{property.name}}</b> is being verified. We will notify by email once the process is complete.</p>
        </div>  
    </div>
    
    <div class="prop-overview animated fadeInUp">    
        <div class="overview">
            <div class="search">
                <div class="pretty p-switch p-fill" style="border: none;">
                    <input type="checkbox" :checked="isPropertySearchEnabled" @click="SearchEnable">
                    <div class="state p-success">
                        <label>Search Enabled</label>
                    </div>
                </div>
            </div>
            <h2>{{ property.name }} <span v-if="property.verified" class="propverified" title="Verified Property"></span></h2>
            <p>{{ property.address }}</p>
    
            <ul class="others">
                <li @click="Navigate('Units')" 
                        @mouseenter="PropToolTip(true)" 
                        @mouseleave="PropToolTip(false)" 
                        :data-tip="property.units.total + ' Units'">

                    <img src="../../images/icons/units.svg" />
                    <p>{{property.units.total}}</p>
                </li>
    
                <li @click="Navigate('Parking')"
                            @mouseenter="PropToolTip(true)" 
                            @mouseleave="PropToolTip(false)" 
                            :data-tip="property.parkings + ' Parking'">

                    <img src="../../images/icons/parking.svg" />
                    <p>{{property.parkings}}</p>
                </li>
    
                <li @click="Navigate('Tasks')"
                            @mouseenter="PropToolTip(true)" 
                            @mouseleave="PropToolTip(false)" 
                            :data-tip="property.tasks + ' Tasks'">

                    <img src="../../images/icons/tasks.svg" />
                    <p>{{property.tasks}}</p>
                </li>
            </ul>

            <ul class="fewothers">
                <li>
                    <img src="../../images/icons/calendar.svg" @mouseenter="PropToolTip(true)" 
                            @mouseleave="PropToolTip(false)" 
                            :data-tip="'Property Built Date'" />
                    <p>Built In 2014</p>
                </li>

                <li>
                    <img src="../../images/icons/peoples.svg" @mouseenter="PropToolTip(true)" 
                            @mouseleave="PropToolTip(false)" 
                            :data-tip="'Total people living this property.'" />
                    <p>{{property.peoples}} Families</p>
                </li>

                <li>
                    <img src="../../images/svg/paw.svg" @mouseenter="PropToolTip(true)" 
                            @mouseleave="PropToolTip(false)" 
                            :data-tip="'Pets'" />
                    <p v-if="property.pets.dogs" style="margin-right: 5px;">Dogs</p>
                    <p v-if="property.pets.cats">Cats</p>
                </li>
            </ul>
    
            <div class="btn-secondary" @click="EditProperty" style="display: none;">
                <p>Edit Property</p>
            </div>

            <WalkThroughVue
                v-if="WthoughPrimary"
                :image="'edit-property.svg'"
                :title="'Edit your property'"
                :content="'You can always edit your properties here!'"
                :toPoint="'nowhere'"
                :style="'bottom: 85px;left: 30px;'"
                @closewth="CloseWalkThrough()" />

        </div>
    
        <div class="gallery-map">
            
            <div class="gallery" @click="Navigate('Gallery')">
                <ReplaceImage :source="property.image" />
                <div class="count">
                    <p>{{property.gallery}}</p>
                    <img src="../../images/icons/gallery-white.svg" />
                </div>
            </div>

            <div class="map" id="GeoLocation"></div>
        </div>
    
    </div>
    
    <ul class="oth-overview animated fadeInUp">
        <li>
            <div class="header">
                <img src="../../images/icons/tasks.svg" />
                <p>Pending Tasks</p>
            </div>
    
            <h2>{{property.tasks}}</h2>
    
            <div class="link" @click="Navigate('Tasks')">
                <p>View Tasks</p>
            </div>
        </li>
    
        <li>
            <div class="header">
                <img src="../../images/icons/peoples.svg" />
                <p>People</p>
            </div>
    
            <h2>{{property.peoples}}</h2>
    
            <div class="link" @click="Navigate('People')">
                <p>View People</p>
            </div>
        </li>
    
        <li>
            <div class="header">
                <img src="../../images/icons/dollar-sign.svg" />
                <p>Pending Balance</p>
            </div>
    
            <h2>{{property.due_balance.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            })}}</h2>
    
            <div class="link" @click="Navigate('Finance')">
                <p>Finance</p>
            </div>
        </li>
    
        <li>
            <div class="header">
                <img src="../../images/icons/applications.svg" />
                <p>Applications</p>
            </div>
    
            <p>Not found! No new applications</p>
    
            <div class="link">
                <p>Application History</p>
            </div>
        </li>
    </ul>

    <div class="otvo-info">
        <div class="etaillist detaillist">
            <div class="header">
                <h3>Property Detail</h3>
            </div>

            <div class="content">
                <p>{{property.description}}</p>
            </div>
        </div>

        <div class="etaillist">
            <div class="header">
                <h3>Unique Features</h3>
            </div>

            <div class="content">
                <ul class="features">
                    <li v-for="feature in PropertyFeatures" :key="feature">
                        <p>{{feature.title}}</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="etaillist">
            <div class="header">
                <h3>Property Services</h3>
            </div>

            <div class="content">
                <ul class="features">
                    <li v-for="feature in PropertyServices" :key="feature">
                        <p>{{feature.title}}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>

    //@ JS Modules
    import Document from '../../javascript/Document';
    import Handler from '../../javascript/Handler';
    import DataCenter from './../../javascript/DataCenter';
    import Request from './../../javascript/getRequest';

    // pinia storage
    import { useOwnerStore } from './../../stores/OwnerStore';

    //@ import components blocks
    import HtmlImage from './../blocks/HtmlImage.vue';
    import ReplaceImage from './../blocks/ReplaceImage.vue';
    
    // walk through
    import WalkThroughVue from './../reusable/WalkThrough.vue';

    export default {
        name: 'Overview',
        props: ['property'],
        emits: ['verify', 'navigate', 'editproperty'],
        components: {
            HtmlImage,
            ReplaceImage,

            WalkThroughVue
        },

        data () {
            return {
                PropertyOverview : false,
                isPropertySearchEnabled: false,
                PropertyServices : this.property.services,
                PropertyFeatures : this.property.features,

                // pinia storage
                OwnerStore: useOwnerStore (),

                // page walk through
                WthoughPrimary: false,

            }
        },

        mounted () {
            // site title and seo
            Document.DocumentHeadings ({title : ' Properties - Overview'});

            // walkthrough settings
            this.WthoughPrimary =  this.OwnerStore.walkthrough.property.overview;

            // start the map
            this.initializeMap ();

            if (this.property.search == 'true') this.isPropertySearchEnabled = true;
            else this.isPropertySearchEnabled = false;
           
        },

        methods : {

            async SearchEnable () {
                this.isPropertySearchEnabled = !this.isPropertySearchEnabled;

                // update to the server
                let formData = new FormData();
                formData.append('property', this.property.id);
                formData.append('search', this.isPropertySearchEnabled);
                const UpdateSearch = await Request.postServer('owner/property/searchenable.php', formData);
                
                if (this.isPropertySearchEnabled) this.property.search = 'true';
                else this.property.search = 'false';

                // notification
                Handler.PropNotification('Successfully updated property searching functions');

            },  

            // close the walkthrough
            async CloseWalkThrough () {
                // this.WthoughPrimary = false;
                // this.OwnerStore.walkthrough.property.overview = false;

                // update to the server
                await DataCenter.ProPRequest('owner-walkghrough', false, {
                    key : 'wlkough',
                    value : 'overview'
                });
            },

            // edit property
            EditProperty () {
                this.$emit('editproperty');
            },
            
            // navigate to screens
            Navigate (page) {
                this.$emit('navigate', page);
            },

            // now send verification request
            VerifyProperty () {
                this.$emit('verify');
            },

            // google map init
            initializeMap () {

                // property lat and long
                var prplocation = JSON.parse(this.property.location);
                
                var icon = 'https://dev.propstop.com/fetch-stock/marker.png';
			
                var locations = [{lat : prplocation.latitude, lng : prplocation.longitude}];
                
                // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
                var mapOptions = {
                    // How zoomed in you want the map to start at (always required)
                    zoom: 16,

                    // The latitude and longitude to center the map (always required)
                    center: new google.maps.LatLng(prplocation.latitude, prplocation.longitude),

                    // remove buttons and etc
                    //disableDefaultUI: true,
                };
        
                // Get the HTML DOM element that will contain your map 
                // We are using a div with id="map" seen below in the <body>
                var mapElement = document.getElementById('GeoLocation');
        
                // Create the Google Map using our element and options defined above
                var map = new google.maps.Map(mapElement, mapOptions);
                        
                // Let's also add a marker while we're at it
                // var markers = locations.map(function(location, i) {
                //     return new google.maps.marker.AdvancedMarkerElement({
                //         position: location,
                //         icon: icon,
                //         animation:google.maps.Animation.DROP
                //     });
                // });

                // // Add a marker clusterer to manage the markers.
			    // var markerCluster = new MarkerClusterer(map, markers,
				// 	{imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
            },

            // tooltip
            PropToolTip (todo) {
                var event = event || window.event;
                Handler.ToolTipHandler (todo, event);                
            }
        }

    }
</script>