<template>
    <div class="property-verify _propscrl">
        <div class="summery" style="position: sticky;top: 0;">
            <div class="avatar">
                <div class="image">
                    <ReplaceImage :source="''" />
                </div>    
                <h2>{{ Property.name }}</h2>
            </div>

            <div class="btn" style="margin-top: -12px;margin-bottom: 15px;">
                <span class="btn-primary" style="display: block;">Transfer Property</span>
            </div>

            <ul class="sumry">
                <li>
                    <div class="icon">
                        <img src="./../../../images/icons/nmap.svg">
                    </div>
                    <p>{{ Property.address }}</p>
                </li>
                <li>
                    <div class="icon">
                        <img src="./../../../images/icons/ndate.svg">
                    </div>
                    <p>{{ Property.created }}</p>
                </li>
                <li>
                    <div class="icon">
                        <img src="./../../../images/icons/house.svg">
                    </div>
                    <p>{{ Property.type }}</p>
                </li>
            </ul>

            <ul class="status">
                <li v-if="Property.verified == 'true'"><img class="admin-verified" src="../../../images/admin/check.svg"> <p>Verified</p></li>
                <li v-else><img class="admin-unverified" src="../../../images/admin/cross.svg"> <p>Unverified</p></li>

                <li v-if="Property.verification == 'true'"><img class="admin-verified" src="../../../images/admin/check.svg"> <p>Waitlist</p></li>
            </ul>
        </div>

        <div class="content">
            <ul class="other-summery">
                <li>
                    <h3>Property Description</h3>
                    <p>{{ Property.description }}</p>
                </li>

                <li>
                    <h3>Dispute Notes</h3>
                    <p>{{ this.data.notes }}</p>
                </li>

                <li>
                    <h3>Property Owner</h3>
                    <div class="pop-owner-info" style="margin: 10px 0;">
                        <div class="image">
                            <UserImage :init="PropOwner.fullname" :source="PropOwner.avatar" :back="PropOwner.back" />
                        </div>
                        <div class="acc-dtls">
                            <h3>{{ PropOwner.fullname }}</h3>
                            <p>{{ PropOwner.email }}</p>
                            <p>{{ PropOwner.mobile }}</p>
                        </div>
                    </div>
                </li>

                <li>
                    <h3>Disputed By (Owner)</h3>
                    <div class="pop-owner-info" style="margin: 10px 0;">
                        <div class="image">
                            <UserImage :init="Owner.fullname" :source="Owner.avatar" :back="Owner.back" />
                        </div>
                        <div class="acc-dtls">
                            <h3>{{ Owner.fullname }}</h3>
                            <p>{{ Owner.email }}</p>
                            <p>{{ Owner.mobile }}</p>
                        </div>
                    </div>
                </li>
                
            </ul>
        </div>
    </div>
</template>

<script>

    // components
    import UserImage from './../../blocks/UserImage.vue';
    import ReplaceImage from './../../blocks/ReplaceImage.vue';

    export default {
        name: 'PropertyDisputeInfo',
        props: ['data'],
        emits: ['close'],
        components: {
            UserImage,
            ReplaceImage
        },

        data () {
            return {
                Property: this.data.property,
                PropOwner: this.data.property_owner,
                Owner: this.data.disputed_by,
            }
        },

        mounted () {
            console.log (this.data);
        },

        methods: {

        }
    };
</script>