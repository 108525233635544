<template>

    <div v-if="requesting" style="margin-top: 35px;">
        <Skeletion :type="'user-list'" :data="3" />
    </div>

    <ul v-if="!requesting && data.length > 0" class="dashactivity _propscrl">        
        <li :class="activity.unread == 'true' ? 'unread':''" v-for="activity in data" :key="activity">
            <div class="icon">
                <img :src="require('../../../images/notifications/' + activity.icon)" />
            </div>

            <div class="content">
                <p :id="activity.id"></p>
                <span><img src="../../../images/icons/time.svg" alt="datetime"> {{ activity.created }}</span>
            </div>
        </li>
    </ul>

    <div v-if="!requesting && data.length == 0" class="empty-result animated fadeInUp" style="width: calc(100% - 50px);height: auto;padding: 20px 0;">
        <img src="../../../images/icons/empty-result.svg" />
        <p style="text-align: center">You don't have much information on this request. <br /> You will see your recent activity.</p>
    </div>
    
</template>

<script>
    // components
    import Skeletion from './../../blocks/Skeleton.vue';

    export default {
        name: 'RecentActivity',
        props: ['data', 'requesting'],
        components: {
            Skeletion,
        },

        data () {
            return {
                ConvertedActivity : [],
            }
        },

        mounted () {
            console.log (this.requesting);
            setTimeout (() => {                
                this.data.map((activity) => {
                    var parser = new DOMParser();
                    var doc = parser.parseFromString(activity.content, 'text/html');
                    var activityID = document.getElementById(activity.id);
                    activityID.innerHTML = doc.body.innerHTML;
                }).join('');    
            }, 100);
        }
    };
</script>

<style scoped>
    ul.activity>li .content p b {padding: 0 5px;}

    /* Remove this Later */
    ul.dashactivity>li {display: flex;}
</style>