<template>
      <WebNav />
            <!-- internal header hero -->
            <InternalHeader  :page="'web-landlords'" 
                        :title="'Streamline Your Property Management with PropStop'" 
                        :description="'Discover how PropStop can simplify your property management tasks. From tracking rent payments to managing documents and tenant requests, our platform is designed to make your life as a landlord easier.'" />
            <!-- internal header hero -->

            <!-- our story -->
            <section class="about" style="padding-bottom: 65px;">
                <div class="wrapper" style="padding: 0;">
                        <div class="abwr-about atbout-resp" style="flex-direction: row-reverse;">
                            <div class="image" style="height: 295px">
                                    <img src="./../../images/website/tenants-exp.svg" style="object-fit: contain;" alt="Landlords">
                            </div>
                            <div class="content">
                                    <span>Landlords</span>
                                    <h2>Revolutionize Your <em><p>Property</p></em> Management with PropStop.</h2>
                                    <p>PropStop is here to transform the way you manage your properties. From streamlined rent collection to efficient maintenance management, our platform is designed to save you time and enhance your tenants' satisfaction.</p>
                            </div>
                        </div>
                </div>
            </section>
            <!-- our story -->

            <section class="more-contents">
                <div class="wrapper" style="padding: 0px;">
                    <ul>
                        <li>
                            <div class="image">
                                <img src="./../../images/website/payment-transfer.svg" />
                            </div>
                            <div class="content">
                                <h2>Automated Rent Collection</h2>
                                <p><strong>Rent collection, reinvented.</strong> Automate your rent collection process with PropStop. Receive payments directly to your account, send automatic reminders to tenants, and track payment history, all in one place.</p>
                            </div>
                        </li>

                        <li>
                            <div class="image">
                                <img src="./../../images/website/maintanence-request.svg" />
                            </div>
                            <div class="content">
                                <h2>Maintenance Management</h2>
                                <p><strong>Address maintenance requests swiftly.</strong> Organize and prioritize maintenance tickets through PropStop. Communicate directly with tenants, schedule repairs, and ensure your property remains in top condition.</p>
                            </div>
                        </li>

                        <li>
                            <div class="image">
                                <img src="./../../images/website/communication.svg" />
                            </div>
                            <div class="content">
                                <h2>Seamless Tenant Communication</h2>
                                <p><strong>Keep your tenants informed and engaged.</strong> Use PropStop to send announcements, updates, and messages to one or all of your tenants. Building a community has never been easier.</p>
                            </div>
                        </li>

                        <li>
                            <div class="image">
                                <img src="./../../images/website/documents.svg" />
                            </div>
                            <div class="content">
                                <h2>Features Designed for Landlords:</h2>
                                <p><strong>Automated Rent Collection:</strong> Efficient and timely rent processing.</p>
                                <p><strong>Maintenance Ticket System:</strong> Streamlined management of repair requests.</p>
                                <p><strong>Tenant Communication:</strong> Direct lines of communication to build better relationships.</p>
                                <p><strong>Document Management:</strong> Store and manage all property-related documents securely.</p>
                            </div>
                        </li>


                    </ul>
                </div>
            </section>

            <section class="counters">
                <div class="wrapper" style="background-color: transparent; padding: unset; padding: 0">
                        <div class="countper">
                            <h2>Join PropStop <em><p style="color: white !important;font-weight: 700;">Today</p></em></h2>
                            <p>Elevate your property management game. With PropStop, spend less time on administrative tasks and more time growing your business.</p>
                            <router-link to="/waitlist/" class="site-btn-dark">
                                    <p>Sign Up as a Landlord</p>
                            </router-link>
                            <p style="font-size: 9pt;color: #a7a7a7;">Welcome to the PropStop family --where property management meets innovation</p>
                        </div>
                </div>
            </section>
      <Footer />
</template>

<script>
      // import Website Navigation
      import WebNav from './../../components/website/WebNav.vue';
      import Seprator from './../../components/website/Seprator.vue';
    import InternalHeader from '../../components/website/InternalHeader.vue';
      import Footer from './../../components/website/Footer.vue';

      export default {
        name : 'Tenants',
        components : {
            WebNav,
            Seprator,
            InternalHeader,
            Footer
        },

        mounted () {
            // scroll to top
            window.scrollTo(0, 0);

            // remove styling used for apps
            document.getElementById('propstop-app').style.height = 'auto';
            document.getElementById('propstop-app').style.overflow = 'auto';

            //  change the title
            document.title = "For Landlords: Streamline Your Property Management with PropStop";

        }
    }
</script>