<template>
    <div class="prop-form _propscrl">
        <div class="form-area" style="padding-bottom: 65px;">
            
            <form name="propform" id="propform-finance" style="display: block;">
                <div class="pf--title">
                    <h3>Add Transaction</h3>
                    <p>Add monthly rent or charges for individual or all unit's tenant</p>
                </div>

                <ul class="select-type">
                    <li v-for="ttype in TransactionTypes" :key="ttype.key" :class="{ active : ttype.active}" @click="handleTypeClick(ttype)">
                        <div class="image">
                            <img :src="require(`./../../images/icons/${ttype.image}`)" v-if="!ttype.active" />
                            <img :src="require(`./../../images/icons/${ttype.imageHover}`)" v-if="ttype.active" />
                        </div>
                        <div class="content">
                            <h3>{{ ttype.name }}</h3>
                            <p>{{ ttype.description }}</p>                            
                        </div>
                        <div class="hover" v-if="ttype.active">
                            <img src="./../../images/icons/arrow-right-blue.svg" class="animated fadeInLeft" />
                        </div>
                    </li>
                </ul>

                <div class="pf--multi-input" style="height:85px;">
                    <div class="half hleft">
                        <div class="pf--input" style="overflow:unset; position:relative;">
                            <p>Choose Property</p>
                            <input v-if="data.hasProperty" type="text" readonly disabled v-model="SelProperty" />
                            <input v-else v-model="SelProperty" type="text" placeholder="Choose One" 
                                        id="Properties" data-type="len" data-max="5" readonly 
                                        @click="PropertySingleSelect = true" :disabled="Requesting" />

                            <PropertyList v-if="PropertySingleSelect" 
                                    :distyle="'top: 90px !important;bottom: unset !important;left: 195px !important;'" 
                                    :properties="OwnerProperties" @prosel="PropertySelected" @proboxclse="CloseList" />
                        </div>
                    </div>

                    <div class="half hright" v-if="TransactionType == 'single' || TransactionType == 'business'">
                        <div class="pf--input" style="overflow:unset; position:relative;">
                            <p>Choose Unit</p>
                            <input v-if="data.hasUnit" type="text" readonly disabled v-model="SelUnit" />
                            <input v-else v-model="SelUnit" type="text" placeholder="Choose One" id="Units" data-type="len" data-max="5" readonly 
                                            @click="UnitsSingleSelect = true"  :disabled="Requesting" />
                            <UnitSelection v-if="UnitsSingleSelect == true" 
                                            :units="PropertyUnits" @lstboxclse="UnitSelected" />
                        </div>
                    </div>                    
                </div>

                <!-- for individual type of transaction only -->
                <div class="pf--multi-input" style="height: 84px;" v-if="TransactionType == 'single'">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Transaction Type</p>
                            <select v-model="TransType" id="TransType" data-type="select" :disabled="Requesting">
                                <option value="-1">- Select One -</option>
                                <option value="utility">Utility</option>
                                <option value="late">Late Fee</option>
                                <option value="trash">Trash</option>
                                <option value="penalty">Penalty</option>
                                <option value="bulk">Bulk Item Pick-Up</option>
                                <option value="misc">Other/Misc</option>
                            </select>
                        </div>
                    </div>

                    <div class="half hright" v-if="TransType == 'utility'">
                        <div class="pf--input">
                            <p>Utility Type</p>
                            <select v-model="UtilType" id="UtilType" data-type="select" :disabled="Requesting">
                                <option value="-1">- Select One -</option>
                                <option value="water">Water</option>
                                <option value="Gas">Gas</option>
                                <option value="electricity">Electricity</option>
                                <option value="multiple">Multiple</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="pf--multi-input" style="height: 84px;" v-else-if="TransactionType == 'multiple'">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Transaction Type</p>
                            <select v-model="UnitTransType" id="TransType" data-type="select" :disabled="Requesting">
                                <option value="-1">- Select One -</option>
                                <option value="trash">Trash</option>
                                <option value="repair">Repair</option>
                                <option value="bulk">Bulk Item Pick-Up</option>
                                <option value="misc">Other/Misc</option>
                            </select>
                        </div>
                    </div>

                    <div class="half hright">
                    </div>
                </div>

                <div class="pf--multi-input" style="height: 84px;" v-else>
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Transaction Type</p>
                            <select v-model="UnitTransType" id="TransType" data-type="select" :disabled="Requesting">
                                <option value="-1">- Select One -</option>
                                <option value="Contract Work">Contract Work</option>
                                <option value="Electrical Work">Electrical Work</option>
                                <option value="Groundskeeper">Groundskeeper</option>
                                <option value="Handyman General Repair">Handyman General Repair</option>
                                <option value="Insurance">Insurance</option>
                                <option value="Minor Project">Minor Project</option>
                                <option value="Plumbing Issue">Plumbing Issue</option>
                                <option value="Pool Repair">Pool Repair</option>
                                <option value="Property Enhancement">Property Enhancement</option>
                                <option value="Side Walk Repair">Side Walk Repair</option>
                                <option value="Sprinkler Repair">Sprinkler Repair</option>
                                <option value="Survey">Survey</option>
                                <option value="Taxes">Taxes</option>
                                <option value="Utility Installation/Repair">Utility Installation/Repair</option>
                                <option value="misc">Other/Misc</option>
                            </select>
                        </div>
                    </div>

                    <div class="half hright">
                    </div>
                </div>
                
                <!-- ammount and payment type -->
                <div class="pf--multi-input" style="height: 84px;">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Amount ($)</p>
                            <input type="number" v-model="TransAmount" id="TransAmount" placeholder="Price" data-type="len" data-max="1" :disabled="Requesting" />
                        </div>
                    </div>
                </div>

                <!-- description of the transaction -->
                <div class="pf--input" v-if="UnitTransType == 'misc' || TransType == 'misc'">
                    <p>Notes</p>
                    <input v-model="Remarks" type="text" placeholder="Transaction brief" id="Remarks" data-type="len" data-max="3" :disabled="Requesting" />
                </div>

            </form>

        </div>

        <div class="form-action">
            <div class="form-area">
                <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="AddNewTransaction" style="display: block; width: 120px; margin-top: 10px">
                    <p v-if="!Requesting">Add Transaction</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>
                <span v-if="formError" class="__formerror">{{ infoText }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    
    //@ JS Modules
    import Request from '../../javascript/getRequest';
    import DataCenter from '../../javascript/DataCenter';
    import Locale from '../../javascript/Locale';

    import FormValidation from '../../javascript/FormValidation';

    // @component
    import PropertyList from '../blocks/PropertyList.vue';
    import UnitSelection from '../web-forms/UnitSelection.vue';

    export default {

        name: 'AddTransaction',
        props: ['data'],
        emits: ['close'],
        components: {
            PropertyList,
            UnitSelection,
        },

        data () {
            return {
                // Form Modal
                TransType : '-1',
                UnitTransType: '-1',
                UtilType : '-1',
                Email : '',
                PropertyName : '',
                TransactionType : 'single',
                TransAmount: '',
                PaymentStatus: '-1',
                Remarks: '',

                Requesting: false,

                // units
                SelUnit : '',
                SelUnitID : null,
                SelUnitTenantID: null,
                PropertyUnits : false,
                UnitsSingleSelect : false,
                SelectedUnit : null,

                SelAssignmentID: null,

                // Properties list
                hasToSelectProperty : false,
                SelProperty: '',
                OwnerProperties : this.data.properties,
                SelectedProperty : false,
                SelectProperty : false,
                PropertySingleSelect: false,

                // transaction types
                TransactionTypes : [
                    {
                        key: 1,
                        type: 'single',
                        name: 'Individual',
                        description: 'Applies to selected unit',
                        image: 'user-blue.svg',
                        imageHover: 'user-white.svg',
                        active: true
                    },
                    {
                        key: 2,
                        type: 'multiple',
                        name: 'All Units',
                        description: 'Applies to all units',
                        image: 'users-blue.svg',
                        imageHover: 'users-white.svg',
                        active: false
                    },
                    {
                        key: 3,
                        type: 'business',
                        name: 'Business Expense',
                        description: '',
                        image: 'users-blue.svg',
                        imageHover: 'users-white.svg',
                        active: false
                    }
                ],

                // for remarks i.e. notes
                TransactionName : Locale.TransactionType(),
                UtilityName : Locale.UtilityType(),

                // form error
                formError : false,
                infoText : '',
            }
        },

        async mounted () {
            if (this.data.hasProperty) {
                this.PropertySelected (this.data.property);

                if (this.data.hasUnit) {
                    this.UnitSelected (this.data.unit);
                }
            }else this.hasToSelectProperty = true;

        },

        methods: {            

            // handle type of transaction
            handleTypeClick (e) {                
                this.TransactionTypes = this.TransactionTypes.filter(type => {
                    type.active = false;
                
                    if (type.key == e.key) {
                        type.active = true;
                        this.TransactionType = type.type;
                    }

                    return type;
                });
                
                this.UnitTransType = '-1';
                this.TransType = '-1';
            },

            // load blank units on the selected properties
            async loadSelectedPropertyUnits (propertyid) {
                // new selection
                this.SelectedUnit = null;
                this.PropertyUnits = null;

                // load units data from server
                let getdata = {
                    key : 'property',
                    value : propertyid
                }
                
                const Units = await DataCenter.ProPRequest('property-units', false, getdata);
                this.Requesting = false;
                
                // now filter units which only have assigned tenants
                if (!Units) {}else {
                    this.PropertyUnits = Units;

                    // filter only those unit who have tenants
                    this.PropertyUnits = this.PropertyUnits.filter (unit => {
                        if (unit.status == 'rented') return unit;
                    });
                }            
            },

            PropertySelected (property) {                
                if (property !== null) {
                    // remove the selected unit
                    this.SelUnit = '';

                    this.SelProperty = property.name;
                    this.SelectedProperty = property;

                    // now load its unit too
                    if (this.TransactionType == 'single' || this.TransactionType == 'business') {
                        this.Requesting = true;
                        this.loadSelectedPropertyUnits (property.id);
                    }
                }

                this.PropertySingleSelect = false;
            },  

            CloseList () {
                this.PropertySingleSelect = false;
            },

            // once unit is selected from single select
            UnitSelected (unit) {
                if (unit !== null) {
                    console.log (unit);

                    this.SelUnit = unit.unit_name;
                    this.SelUnitID = unit.id;
                    this.SelUnitTenantID = unit.tenant.id;
                    this.SelAssignmentID = unit.assignment;
                }

                this.UnitsSingleSelect = false;                
            },

            // add new transaction
            async AddNewTransaction () {
                if (this.Requesting) return;
                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-finance');
                let canGoAhead = FormValidation.Validate (form);

                if (canGoAhead) {
                    this.Requesting = true;

                    // collect form data
                    let formData = new FormData();
                    formData.append('type', this.TransactionType);
                    formData.append('property', this.SelectedProperty.id);
                    formData.append('amount', this.TransAmount);

                    var Notes;
                    
                    if (this.TransactionType == 'single' || this.TransactionType == 'business') {
                        formData.append('unit', this.SelUnitID);
                        formData.append('tenant', this.SelUnitTenantID);
                        formData.append('assignment', this.SelAssignmentID);
                        formData.append('transtype', this.TransType);
                        formData.append('utiltype', this.UtilType);

                        if (this.TransType == 'misc') Notes = this.Remarks;
                        else {
                            Notes = this.TransactionName[this.TransType];
                            if (this.TransType == 'utility') {
                                Notes = this.TransactionName[this.TransType] + ' / ' + this.UtilityName[this.UtilType];
                            }
                        }

                    }else {
                        formData.append('transtype', this.UnitTransType);
                        formData.append('utiltype', '');

                        // remarks i.e. notes accordingly
                        if (this.UnitTransType == 'misc') Notes = this.Remarks;
                        else Notes = this.TransactionName[this.UnitTransType];
                    }

                    formData.append('remarks', Notes);

                    // upload and load data from server
                    const ReturnData = await Request.postServer('owner/finance/add.php', formData);
                    this.Requesting = false;

                    // now check if the email already exists
                    if (ReturnData.error == false) {
                        // now close modal, and load current property
                        this.$emit('close', ReturnData.response);

                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again.";
                    }                   

                }
            }
        },

    }
</script>

<style scoped>
    ul.select-type li:last-child {
        margin-left: 0px;
        margin-top: 10px;
    }
</style>