<template>
<div class="form-area animated fadeInUp" style="width: 100% !important;">
    <div class="lb-logo">
        <img src="../../images/website/propstop-logo.svg" alt="PropStop" title="PropStop" />
        <h2>PropStop</h2>
        <p>Please login to continue.</p>
        <span class="__created" v-if="user">Congratulation!! Your Account has been created, Please login to continue.</span>
    </div>

    <div class="input-form">
        <form name="propform" id="propform-login" style="display: block;">
            <div class="pf--input">
                <p>Username</p>
                <input type="text" placeholder="Email or Username" v-model="Email" id="Username" data-type="email" />
            </div>

            <div class="pf--input">
                <p>Password</p>
                <input type="password" placeholder="********" v-model="Password" id="Password" data-type="len" data-max="8" />
            </div>

            <div class="pf--input" style="overflow: hidden;">
                <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="CheckUserLogin">
                    <p v-if="!Requesting">Login</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" alt="Loader" />
                </span>
            </div>
        </form>
    </div>

    <div class="oth-action">
        <!-- <span class="link" @click="showSignupScreen">Create New Account?</span> -->
        <span class="link" @click="showForgotScreen">Forgot Password?</span>
        <span v-if="loginError || otherError" class="__info">{{ infoText }}</span>
    </div>
</div>
</template>

<script>

    //@import javascript functions
    import Authentication from './../../javascript/Locale';
    import Cookie from './../../javascript/Cookie';
    import FormValidation from './../../javascript/FormValidation';

    //@ JS Modules
    import Document from './../../javascript/Document';
    import Request from './../../javascript/getRequest';

    export default {
        name : "Login",
        props: ['user'],
        emits : ['login', 'forgot'],

        data () {
            return {
                Email : this.user.email,
                Password : '',
                Requesting : false,

                loginError: false,
                otherError: false,
                infoText: ''
            }
        },

        mounted () {
            // site title and seo
            Document.DocumentHeadings ({title : 'Login - PropStop'});
        },
        
        methods : {
            showSignupScreen () {
                this.$emit('login');
            },
            
            showForgotScreen () {
                this.$emit('forgot');
            },

            // set environment setting as false
            setSettingNull () {
                this.loginError = false;
                this.otherError = false;
                this.infoText = '';
            },

            async ForgotPassword () {
                
            },

            async CheckUserLogin () {
                if (this.Requesting) return;

                this.setSettingNull ();

                // form & validation
                let form = document.getElementById('propform-login');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead) {
                    this.Requesting = true;
                    
                    // create new formdata
                    let formData = new FormData();

                    // add datas
                    formData.append('email', this.Email);
                    formData.append('password', this.Password);

                    // request to server
                    const ReturnData = await Request.postServer('auth/login.php', formData, null);
                    this.Requesting = false;

                    if (ReturnData.error == false) {
                        let route, token;
                        route = ReturnData.response.route;
                        token = ReturnData.response.token;

                        // now set data to cookies and force login
                        Cookie.setCookie ('ps-route', route, 1);
                        Cookie.setCookie ('ps-token', token, 1);

                        // reload the app in auth
                        // app mount javascript will handle the rest
                        location.reload();

                    }else if (ReturnData.error == true) {
                        this.loginError = true;

                        if (ReturnData.reason == "verify")
                            this.infoText = "Your account is not verified yet, please check your email frequently.";
                        else                           
                            this.infoText = "The username or password you've entered is incorrect.";

                    }else {
                        this.otherError = true;
                        this.infoText = "Something went wrong, Please try again later or contact support.";
                    }

                }

            },

        }
    };
</script>

<style scoped>
.primary-btn-loader {display: block;
float: none !important;
width: calc(100% - 63px) !important;
margin-top: 10px; text-transform: uppercase;}
</style>