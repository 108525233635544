<template>
    <ul class="recent-property _propscrl">
        <li v-for="property in Properties" :key="property" @click="ClickHandler(property)" >
            <div class="image">
                <ReplaceImage :source="property.thumbnail" />
            </div>
            <div class="property-add">
                <h3>{{ property.name }}
                    <span v-if="property.verified == 'true'" style="display: inline;float: none;">
                        <img style="width: 20px;" src="./../../images/icons/nverified.svg" alt="Property Verified">
                    </span>
                </h3>
                <p>{{ property.address }}</p>
                <p class="date-listing">{{ property.created }}</p>
                
            </div>
        </li>

        <li v-if="properties.length == 0">
            <div class="emptyskeleton">
                <img src="../../images/icons/empty-result.svg" style="height: 180px" class="psanim-1 fadeInUp">
                <p class="psanim-2 fadeInUp">You dont have much information on this request.</p>
            </div>
        </li>
    </ul>
</template>

<script>
    // components
    import ReplaceImage from './../blocks/ReplaceImage.vue';

    export default {
        name: 'DashboardProperties',
        props: ['properties'],
        emits: ['choose'],
        components: {
            ReplaceImage
        },

        data () {
            return {
                Properties: []
            }
        },

        mounted () {
            this.Properties = this.properties.filter (property => {
                if (property.verified == "true") return property;
            });
        },

        methods: {
            ClickHandler (property) {
                this.$emit('choose', property);
            }
        }
    };
</script>