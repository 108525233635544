<template>
    <div class="prop-form _propscrl">
        <div class="form-area" style="padding-bottom: 65px;">
            
            <form name="propform" v-if="formProcess == 1" id="propform-tenant1" style="display: block;">
                <div class="pf--title">
                    <h3>Add New Tenant</h3>
                    <p>Please choose the breif information about your new tenant.</p>
                    <p style="font-size: 10pt;">* Indicate a required field</p>
                </div>

                <div class="pf--info" style="margin-bottom: 45px;">
                    <div class="icon">
                        <img src="./../../images/icons/info-blue.svg" />
                    </div>
                    <div class="content">
                        <p>Enter the email of tenant, you can add more data later.</p>
                    </div>
                </div>

                <!-- <PropertySelected :property="data.property" /> -->

                <ul class="select-type">
                    <li v-for="ttype in TenantTypes" :key="ttype.key" :class="{ active : ttype.active}" @click="handleTypeClick(ttype)">
                        <div class="image">
                            <img :src="require(`./../../images/icons/${ttype.image}`)" v-if="!ttype.active" />
                            <img :src="require(`./../../images/icons/${ttype.imageHover}`)" v-if="ttype.active" />
                        </div>
                        <div class="content">
                            <h3>{{ ttype.name }}</h3>
                            <p>{{ ttype.description }}</p>                            
                        </div>
                        <div class="hover" v-if="ttype.active">
                            <img src="./../../images/icons/arrow-right-blue.svg" class="animated fadeInLeft" />
                        </div>
                    </li>
                </ul>

                <div class="pf--multi-input" style="height: 75px;" v-if="(!isOnLookUp && !isInvitation)">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>First Name *</p>
                            <input type="text" placeholder="i.e Jon" v-model="FirstName" id="FirstName" data-type="len" data-max="3" />
                        </div>
                    </div>
                    <div class="half hright">
                        <div class="pf--input">
                            <p>Last Name *</p>
                            <input type="text" placeholder="i.e Doe" v-model="LastName" id="lastName" data-type="len" data-max="3" />
                        </div>
                    </div>
                </div>

                <div class="pf--multi-input" style="height: 94px;" v-if="TenantType == 'email'">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Email Address *</p>
                            <input type="text" placeholder="i.e jon@example.com" v-model="Email" id="Email" data-type="email" @keypress="ResetTenantSettings" />
                        </div>
                    </div>
                </div>

                <div class="pf--info" style="margin-bottom: 45px;" v-if="isUser && !isTenant">
                    <div class="icon">
                        <img src="./../../images/icons/info-blue.svg" />
                    </div>
                    <div class="content">
                        <p>This account cannot be registered as Tenant. Please try with another email</p>
                    </div>
                </div>

                <div class="pf--info" style="margin-bottom: 45px;" v-if="isUser && isTenant && !isMine">
                    <div class="icon">
                        <img src="./../../images/icons/info-blue.svg" />
                    </div>
                    <div class="content">
                        <p>This tenant is already associated with other's property. You can invite this user to your property.</p>
                    </div>
                </div>

                <div class="pf--info" style="margin-bottom: 45px;" v-if="isUser && isTenant && isMine">
                    <div class="icon">
                        <img src="./../../images/icons/info-blue.svg" />
                    </div>
                    <div class="content">
                        <p>This tenant is already associated with one of your property. You can simply assign to required property unit.</p>
                    </div>
                </div>

                <!-- <div class="pf--multi-input" style="height:85px;" v-if="!isOnLookUp && !isUser">
                    <div class="half hleft" v-if="data.hasProperty == false">
                        <div class="pf--input" style="overflow:unset; position:relative;">
                            <p>Choose Property *</p>
                            <input v-model="SelProperty" type="text" placeholder="Choose One" id="Properties" data-type="len" data-max="5" readonly @click="PropertySingleSelect = true"/>
                            <PropertyList v-if="PropertySingleSelect" :distyle="'top: -90px;position: absolute;height: 355px;width: 360px;left: 0px;overflow-y: scroll;'" :properties="OwnerProperties" @prosel="PropertySelected" />
                        </div>
                    </div>

                    <div class="half" :class="data.hasProperty ? 'hleft' : 'hright'">
                        <div class="pf--input" style="overflow:unset; position:relative;">
                            <p>Choose Unit *</p>
                            <input v-model="SelUnit" type="text" placeholder="Choose One" id="Units" data-type="len" data-max="5" readonly @click="UnitsSingleSelect = true" />
                            <UnitSelection v-if="UnitsSingleSelect == true" :units="PropertyUnits" :distyle="'top: -85px; left: -35px;'" @lstboxclse="UnitSelected" />
                        </div>
                    </div>                    
                </div> -->

            </form>

            <form name="propform" v-if="formProcess == 2" id="propform-tenant2" style="display: block;">
                <div class="pf--title">
                    <h3>Assign Unit</h3>
                    <p>Assign new unit to tenant, select your agreement type and monthly price for the selected unit.</p>
                </div>

                <div class="pf--multi-input" style="height:85px;">
                    <div class="half hleft" v-if="data.hasProperty == false">
                        <div class="pf--input" style="overflow:unset; position:relative;">
                            <p>Choose Property</p>
                            <input v-model="SelProperty" type="text" placeholder="Choose One" id="Properties" data-type="len" data-max="5" readonly @click="PropertySingleSelect = true" :disabled="Requesting" />
                            <PropertyList v-if="PropertySingleSelect" :distyle="'top: 90px !important;bottom: unset !important;left: 195px !important;'" :properties="OwnerProperties" @prosel="PropertySelected" @proboxclse="CloseList" />
                        </div>
                    </div>

                    <div class="half" :class="data.hasProperty ? 'hleft' : 'hright'">
                        <div class="pf--input" style="overflow:unset; position:relative;">
                            <p>Choose Unit</p>
                            <input v-model="SelUnit" type="text" placeholder="Choose One" id="Units" data-type="len" data-max="5" readonly @click="UnitsSingleSelect = true"  :disabled="Requesting || !SelProperty"  />
                            <UnitSelection v-if="UnitsSingleSelect == true" :units="PropertyUnits" :distyle="'top: 0px;left: -35px;'" @lstboxclse="UnitSelected" />
                        </div>
                    </div>                    
                </div>

                <div class="pf--input" style="overflow:hidden;">
                    <p>Agreement Type</p>
                    <div class="pets-select">
                        <ul>
                            <li :class="{active : LeaseAgreement}" @click="AgreementHandler('lease')">
                                <div class="image">
                                    <img class="select" src="../../images/icons/select-list.svg" />
                                    <img class="selected" src="../../images/icons/select-check.svg" />
                                </div>
                                <div class="content">
                                    <p>Lease</p>
                                </div>
                            </li>

                            <li :class="{active : MonthAgreement}" @click="AgreementHandler('month')">
                                <div class="image">
                                    <img class="select" src="../../images/icons/select-list.svg" />
                                    <img class="selected" src="../../images/icons/select-check.svg" />
                                </div>
                                <div class="content">
                                    <p>Monthly</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <!-- ammount and payment type -->
                <div class="pf--multi-input" style="height: 84px;">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Start Date</p>
                            <Datepicker class="dp__input_custom" v-model="StartDate" :enableTimePicker="false" autoApply required placeholder="Pick Date" :disabled="Requesting" />
                        </div>
                    </div>

                    <div class="half hright" v-if="LeaseAgreement == true">
                        <div class="pf--input">
                            <p>End Date</p>
                            <Datepicker class="dp__input_custom" v-model="EndDate" :enableTimePicker="false" autoApply placeholder="Pick Date" :disabled="Requesting" />
                        </div>
                    </div>
                </div>

                <!-- description of the transaction -->
                <div class="pf--input">
                    <p>Monthly Rent</p>
                    <input v-model="MonthlyRent" type="number" placeholder="1200.00" id="MonthlyRent" data-type="len" data-max="2" :disabled="Requesting" />
                </div>


            </form>

            <form name="propform" v-if="formProcess == 3" id="propform-tenant3" style="display: block;">
                <div class="pf--title">
                    <h3>Initial Deposits</h3>
                    <p>Assign new unit to tenant, Enter the initial deposits and other deposits</p>
                </div>

                <!-- Deposit Tracking -->
                <div class="pf--multi-input" style="height: 84px;">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Deposit Amount</p>
                            <input v-model="DepositAmount" type="number" placeholder="1800.00" id="DepositAmount" data-type="len" data-max="2" :disabled="Requesting" />
                        </div>
                    </div>

                    <div class="half hright">
                        <div class="pf--input">
                            <p>Deposit Date</p>
                            <Datepicker class="dp__input_custom" v-model="DepositDate" :enableTimePicker="false" autoApply placeholder="Pick date.." :disabled="Requesting" />
                        </div>
                    </div>
                </div>

                <div class="pf--multi-input" style="height: 84px;">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>Deposit Payment Type</p>
                            <select v-model="PaymentType" id="PaymentType" data-type="select" :disabled="Requesting">
                                <option value="-1">- Select One -</option>
                                <option value="Check">Check</option>
                                <option value="Credit Card">Credit Card</option>
                                <option value="Money Order">Money Order</option>
                                <option value="venmo">Venmo</option>
                                <option value="zelle">Zelle</option>
                            </select>
                        </div>
                    </div>

                    <div class="half hright">
                        <div class="pf--input">
                            <p>Deposit Notes</p>
                            <input v-model="DepositNote" type="text" placeholder="Deposit notes" id="DepositNote" data-type="len" data-max="0" :disabled="Requesting" />
                        </div>
                    </div>
                </div>

            </form>


        </div>

        <div class="form-action">
            <div class="form-area">
                <div v-if="formProcess != 1" class="btn-secondary process-back" @click="GetBackToPreviousPage"><img src="./../../images/icons/arrow-left-blue.svg" /></div>
                
                <span v-if="formProcess == 1" class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="ValidateFirstPage">
                    <p v-if="!Requesting">
                        <span v-if="isOnLookUp">Check Email</span>
                        <span v-if="!isOnLookUp & isInvitation">Invite Tenant</span>
                        <span v-if="!isOnLookUp && !isUser">Next Step</span>
                    </p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>

                <span class="primary-btn-loader" v-if="formProcess == 2" :class="Requesting ? 'btn-disabled' : ''" @click="ValidateSecondPage">
                    <p v-if="!Requesting">Next Step</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>

                <span class="primary-btn-loader" v-if="formProcess == 3" :class="Requesting ? 'btn-disabled' : ''" @click="AddNewTenant">
                    <p v-if="!Requesting">Assign Tenant</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>

                <span v-if="formError" class="__formerror">{{ infoText }}</span>
            </div>
        </div>
    </div>
</template>

<script>

    //@ JS Modules
    import Request from './../../javascript/getRequest';
    import Handler from './../../javascript/Handler';
    import DataCenter from '../../javascript/DataCenter';

    import FormValidation from './../../javascript/FormValidation';

    // pinia
    import { useOwnerStore } from './../../stores/OwnerStore';

    // import component
    import PropertyList from './../blocks/PropertyList.vue';
    import UnitSelection from './../web-forms/UnitSelection.vue';

    // datepicer
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';


    export default {
        name: 'AddTenants',
        props: ['data'],
        emits: ['close'],
        components: {
            PropertyList,
            UnitSelection,

            Datepicker,
        },

        data () {
            return {
                // Form Modal
                FirstName : '',
                LastName : '',
                Email : '',
                PropertyName : '',

                Requesting: false,

                // page settings
                formProcess : 1,

                // step one unit selections
                TenantType : 'email',
                isOnLookUp : true,
                isInvitation: false,
                TenantTypes : [
                    {
                        key: 1,
                        type: 'email',
                        name: 'Email User',
                        description: 'Tenant with email',
                        image: 'mail-blue.svg',
                        imageHover: 'mail-white.svg',
                        active: true
                    },
                    {
                        key: 2,
                        type: 'noemail',
                        name: 'No Email',
                        description: 'Tenant without email',
                        image: 'user-blue.svg',
                        imageHover: 'user-white.svg',
                        active: false
                    }
                ],

                isUser: false,
                isTenant: false,
                isMine: false,

                // form error
                formError : false,
                infoText : '',

                // prperty & unit selection
                // Properties list
                SelProperty: '',
                OwnerProperties : [],
                SelectedProperty : false,
                SelectProperty : false,
                PropertySingleSelect: false,

                // units
                SelUnit : '',
                SelUnitID : null,
                PropertyUnits : false,
                UnitsSingleSelect : false,
                SelectedUnit : null,

                // tenant assign page 
                LeaseAgreement : false,
                MonthAgreement : false,   

                StartDate : null,
                EndDate : null,
                MonthlyRent: '',

                // deposit tracking
                DepositAmount: '',
                DepositDate: null,
                PaymentType: '-1',
                DepositNote: '',

            }
        },

        async mounted () {
            // property id
            this.PropertyName = this.data.property.id; 

            // pinia setup
            const OwnerStore = useOwnerStore ();
            this.OwnerProperties = OwnerStore.properties;

            if (this.data.hasProperty) {
                this.SelProperty = true;
                this.loadSelectedPropertyUnits (this.PropertyName);
            }

        },

        methods: {

            GetBackToPreviousPage () {
                this.formProcess--;
            },

            CloseList () {
                this.PropertySingleSelect = false;
                this.UnitsSingleSelect = false;
            },

            handleTypeClick (e) {                
                this.TenantTypes = this.TenantTypes.filter(type => {
                    type.active = false;
                
                    if (type.key == e.key) {
                        type.active = true;
                        this.TenantType = type.type;
                        if (this.TenantType == 'noemail') {
                            this.isOnLookUp = false;
                            this.isInvitation = false;

                            this.isUser = false;
                            this.isTenant = false;
                            this.isMine = false;
                        
                        }else this.isOnLookUp = true;
                    }

                    return type;
                });   
            },

            // agreemnt radio handler
            AgreementHandler (type) {
                if (type == 'lease') {
                    this.LeaseAgreement = !this.LeaseAgreement;
                    this.MonthAgreement = false;
                }else {
                    this.MonthAgreement = !this.MonthAgreement;
                    this.LeaseAgreement = false;
                }

            }, 

            ResetTenantSettings () {
                this.isUser = false;
                this.isTenant = false;
                this.isMine = false;
                this.isInvitation = false;
                this.isOnLookUp = true;
            },

            // validate the first page
            // lookup and invitations too
            async ValidateFirstPage () {
                if (this.Requesting) return;
                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-tenant1');
                let canGoAhead = FormValidation.Validate (form);
                
                if (canGoAhead) {
                    this.Requesting = true;

                    let formData = new FormData();
                    formData.append('type', this.TenantType);
                    formData.append('firstname', this.FirstName);
                    formData.append('lastname', this.LastName);
                    formData.append('email', this.Email);
                    formData.append('property', this.PropertyName);

                    if (this.isOnLookUp) {
                        const ReturnData = await Request.postServer('tenants/lookup.php', formData);
                        var Response = ReturnData.response;
                        this.Requesting = false;

                        this.isUser = Response.isUser;
                        this.isTenant = Response.isTenant;
                        this.isMine = Response.isMine;

                        if (this.isUser && this.isTenant && !this.isMine) {
                            this.isOnLookUp = false;
                            this.isInvitation = true;
                        }

                        if (this.isUser && this.isTenant && this.isMine) {
                            this.isOnLookUp = true;
                            this.isInvitation = false;
                        }

                        if (!this.isUser) {
                            this.isOnLookUp = false;
                            this.isInvitation = false;
                        }

                    }else if (!this.isOnLookUp && this.isInvitation) {

                        const ReturnData = await Request.postServer('tenants/invite.php', formData);
                        this.Requesting = false;

                        // notify user and close
                        Handler.PropNotification('Successfully invited current tenant to the property!');
                        this.$emit('close', false);

                    }else  {
                        this.Requesting = false;
                        this.formProcess++;
                    }
                }
            },

            // second page validation
            ValidateSecondPage () {
                if (this.Requesting) return;
                this.formError = false;

                let PickerAhead = true;

                // calendar validation
                if (this.StartDate == null) {
                    PickerAhead = false;
                    alert ('Please pick rental start date!');
                }

                // enddate validation
                if (this.LeaseAgreement) {
                    if (this.EndDate == null) {
                        PickerAhead = false;                        
                        alert ('Please pick rental end date!');
                    }
                }

                // form & validation
                let form = document.getElementById('propform-tenant2');
                let canGoAhead = FormValidation.Validate (form);

                if (canGoAhead && PickerAhead) this.formProcess ++;

            },

            // finally add new tenant process
            // form validation if all okay, request to add and refresh the list
            async AddNewTenant () {
                if (this.Requesting) return;
                this.formError = false;

                let PickerAhead = true;

                // calendar validation
                if (this.DepositDate == null) {
                    PickerAhead = false;
                    alert ('Please pick rental start date!');
                }

                // form & validation
                let form = document.getElementById('propform-tenant3');
                let canGoAhead = FormValidation.Validate (form);
                
                if (canGoAhead && PickerAhead) {
                    this.Requesting = true;
                    
                    let formData = new FormData();
                    formData.append('type', this.TenantType);
                    formData.append('firstname', this.FirstName);
                    formData.append('lastname', this.LastName);
                    formData.append('email', this.Email);
                    formData.append('property', this.PropertyName);

                    formData.append('unit', this.SelUnitID);

                    // agreement type
                    let Agreement = 'month';
                    if (this.LeaseAgreement == true) Agreement = 'lease';
                    formData.append('agreement', Agreement);

                    // start date timestamp
                    let StartDate = this.StartDate.getTime();
                    StartDate = StartDate/1000;
                    formData.append('startdate', StartDate);

                    // end date timestamp
                    let EndDate;
                    if (this.LeaseAgreement) {
                        EndDate = this.EndDate.getTime();     
                        
                    // if not lease then end date will be the next month
                    }else {
                        EndDate = new Date();
                        EndDate.setDate(this.StartDate.getDate() + 30);
                        EndDate = EndDate.getTime();
                    }

                    EndDate = EndDate/1000;
                    formData.append('enddate', EndDate);

                    // monthly rent
                    formData.append('rent', this.MonthlyRent);

                    // deposit tracking
                    formData.append('deposit', this.DepositAmount);
                    formData.append('paytype', this.PaymentType);
                    formData.append('depositnote', this.DepositNote); 
                    
                    // deposit date
                    var DepDate = this.DepositDate.getTime();
                    DepDate = DepDate/1000; 
                    formData.append('depositdate', DepDate);
                    
                    // load data from server
                    // add the tenant
                    const ReturnData = await Request.postServer('tenants/add.php', formData);
                    this.Requesting = false;
                    
                    if (ReturnData.error == false) {
                        this.$emit('close', ReturnData.response);
                    }else {
                        if (ReturnData.reason == "duplicate") {
                            this.formError = true;
                            this.infoText = "Duplicate User!";
                        }else {
                            this.formError = true;
                            this.infoText = "Something went wrong! Please try again.";
                        }
                    }
                    
                }
            },

            // once property is selected
            PropertySelected (property) {                
                if (property !== null) {
                    // remove the selected unit
                    this.SelUnit = '';

                    this.SelProperty = property.name;
                    this.SelectedProperty = property;

                    // now load its unit too
                    this.Requesting = true;
                    this.loadSelectedPropertyUnits (property.id);

                }
                this.PropertySingleSelect = false;
            }, 

            // once unit is selected from single select
            UnitSelected (unit) {
                if (unit !== null) {
                    this.SelUnit = unit.unit_name;
                    this.SelUnitID = unit.id;
                }

                this.UnitsSingleSelect = false;                
            },

            // load blank units on the selected properties
            async loadSelectedPropertyUnits (propertyid) {
                // new selection
                this.SelectedUnit = null;
                this.PropertyUnits = null;

                // load units data from server
                let getdata = {
                    key : 'property',
                    value : propertyid
                }
                
                const Units = await DataCenter.ProPRequest('property-units', false, getdata);
                this.Requesting = false;

                console.log (Units);

                if (!Units) {}else {
                    this.PropertyUnits = Units.filter (unit => {
                        if (unit.status == 'blank') return unit;
                    });
                }
                
                this.Requesting = false;
            },

        }
    };

</script>

<style scoped>
span.__formerror {margin-top: 15px !important;margin-left: 15px !important;}
.primary-btn-loader {margin-top: 15px !important;padding: 10px 30px !important;border-radius: 6px !important;float: left !important;width: 125px !important;}div.process-back {display: block;float: left;margin-right: 10px;margin-top: 17px;height: 22px;}div.process-back img {float: none;margin: 0;width: 20px;}
</style>