<script>

    // @ is an alias to /src
    import Locale from './../../../javascript/Locale';
    import DataCenter from './../../../javascript/DataCenter';

    // @import component
    import Skeleton from './../../../components/blocks/Skeleton.vue';
    import UserImage from './../../../components/blocks/UserImage.vue';
    import ReplaceImageVue from './../../../components/blocks/ReplaceImage.vue';
    import UnitSelection from './../../web-forms/UnitSelection.vue';

    import RightModal from './../../modal/RightModal';

    // flicking
    import Flicking from "@egjs/vue3-flicking";
    import "@egjs/vue3-flicking/dist/flicking.css";

     export default {
        name: 'UnitFloorPlanDetail',
        props: ['property', 'planid'],
        components: {
            Skeleton,
            UserImage,
            ReplaceImageVue,
            UnitSelection,
            RightModal,

            Flicking,
        },

        data () {
            return {
                plan: {},
                FloorPlanLoadingState: true,
                list: [0, 1, 2, 3, 4],

                FloorName : Locale.FloorNumName(),

                tenantAgency : false,
                tenantProperty : false,
                tenantUnit : false,

                Amenities: Locale.UnitAmenities (),
                ChoosedAmenities: [],
                AmenitiesUpdated: false,

                FloorPlan: {},

                // view media files
                ViewMediaFile: false,
                ViewMediaData: [],

            }
        },

        async mounted () {

            // load the floorplan details via id
            let getdata = {
                key : 'planproperty',
                value : this.planid + ',' + this.property.id
            }
            
            const FloorPlan = await DataCenter.ProPRequest('owner-floor-plan', false, getdata);
            if (!FloorPlan) {}else {
                this.plan = FloorPlan;
                
                // amenities
                var Amenities = this.plan.amenities.split(',');
                this.ChoosedAmenities = this.Amenities;
                this.ChoosedAmenities = this.ChoosedAmenities.filter(amenity => {
                    for (var i = 0; i < Amenities.length; i++) {
                        if (Amenities[i] == amenity.id) return amenity;
                    };
                });
                this.AmenitiesUpdated = true;

                // show the details
                this.FloorPlanLoadingState = false;
            }            

        },

        methods: {

            // handle view media files
            ViewMediaFileHandler (image) {
                this.ViewMediaData = {
                    property: this.property,
                    gallery : this.plan.gallery,
                    current : image,
                    from : 'floorplan',
                    floorplan: this.plan.id
                };

                // start viewing the selected image
                this.ViewMediaFile = true;

            },

            // close the media files view modal
            CloseViewModal (update) {                
                if (update) {
                    // add all to gallery
                    this.plan.gallery = update.Gallery;

                    // check if set as default
                    if (update.isDefault) {
                        this.$emit('setdefault', update.DefaultUrl);
                    }
                }

                this.ViewMediaFile = false;
            },
        }
    };

</script>

<template>

    <!-- Loading State Units Skeletion -->
    <div v-if="FloorPlanLoadingState" style="margin-top:55px">
        <Skeleton :data="3" />
    </div>

    <div v-else class="unit-info">
        <div class="unit-gallery">
            <Flicking :options="{ align: 'prev', bound: true }">
                <div class="flicking-unit-image" v-for="gallery in plan.gallery" :key="gallery.blobid" @click="ViewMediaFileHandler (gallery)">
                    <img :src="gallery.url" />
                </div>
            </Flicking>
        </div>

        <div class="ui-ot-dtls">

            <div class="uodtls" style="width: 320px;">

                <div class="li">
                    <h4>Plan Name</h4>
                    <h3>{{plan.name}}</h3>
                </div>

                <div class="li">
                    <h4>Floor Type</h4>
                    <h3>{{plan.floortype}}</h3>
                </div>

                <div class="li">
                    <h4>Bed Rooms</h4>
                    <h3>{{plan.bedrooms}} Bd</h3>
                </div>

                <div class="li">
                    <h4>Bath Rooms</h4>
                    <h3>{{plan.bathrooms}}</h3>
                </div>

                <div class="li">
                    <h4>Square Feet</h4>
                    <h3>{{plan.squarefeet}} sq. ft.</h3>
                </div>

            </div>

            <div class="uouiam">

                <div class="li">
                    <h4>Floorplan Description</h4>
                    <h3 class="desc">{{plan.description}}</h3>
                </div>

                <div class="li">
                    <h4>Amenities</h4>                        
                    <ul class="unit-amenities" v-if="AmenitiesUpdated">
                        <li v-for="amenity in ChoosedAmenities" :key="amenity">
                            <img :src="require(`../../../images/amenities/${amenity.icon}`)" />
                            <p>{{amenity.name}}</p>
                        </li>
                    </ul>
                </div>

                <div class="li">
                    <h4>Blue Print</h4>
                    <div class="blueprint">
                        <ReplaceImageVue :source="plan.planurl" />
                    </div>
                </div>

                <div class="li" style="position: relative;">
                    <h4>Linked to Units</h4>

                    <ul class="link-units">
                        <li v-for="unit in plan.units" :key="unit">
                            <div class="image">
                                <ReplaceImageVue :source="''" />
                            </div>
                            <div class="content">
                                <h3>{{unit.unit_name}}</h3>
                                <p v-if="unit.status == 'rented'">{{unit.status_date}}</p>
                                <p v-else style="color:black">Unit not rented yet.</p>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </div>

    <RightModal v-if="ViewMediaFile" :form="'view-document'" :data="ViewMediaData" @modalclose="CloseViewModal" />

</template>