<template>
  <div class="owner-tickets">
      
      <div class="sidebar">

        <!-- Skeleton -->
        <div v-if="TicketLoadingState == true" style="margin-top:55px">
            <Skeletion :data="2" />
        </div>
        
          <ul v-else class="tickets-menu">              
              <li :class="list.active ? 'active' : ''" v-for="list in FilterMenuList" :key="list" @click="ticketFilterListHandler(list)">
                  <p>{{list.name}}</p>
              </li>
          </ul>

          <div class="btn-primary" @click="AddNewTicket">
              <img src="../../images/icons/addbtn-icon.svg" />
              <p>Create Ticket</p>
          </div>
      </div>

        <!-- Ticket list page -->
      <div v-if="!ticketSelected" class="tickets-content _propscrl">
          <TenantTickets :tickets="TicketList" 
                            :property="TenantProperty" 
                            :filter="TicketFilter" 
                            :key="filterChanges" 
                            
                            @ticketClicked="ticketClickHandler" />
      </div>

      <!-- Ticket detail page as selected -->
      <TicketDetail v-else :ticket="selectedTicket" 
                            :profile="profile"
                            :key="ticketSelected"
                            @backtolist="listBackbtn"
                            @backandclose="listBackTicketClosed" />

  </div>

  <div v-if="hasNoProperty" class="empty-result animated fadeInUp" style="top: 0px;left: 0px;">
        <img src="./../../images/icons/on-verification.svg">
        <p>It looks like you haven't been assigned to a property yet.<br>Please search property/units where you want to live.</p>
        <div class="btn-primary bphasno" style="margin-top: 10px;" @click="RouteToPage('tenant-search')">
            <p>Search Properties</p>
        </div>
    </div>

  <!-- pop ups --> 
  <RightModal v-if="AddNewTicketModal" :form="'add-tenant-ticket'" :data="AddTicketData" @modalclose="CloseModalBox" />

</template>

<script>

    //@ JS Modules
    import Document from './../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';
    import Storage from './../../javascript/Storage';

    import Skeletion from './../../components/blocks/Skeleton.vue';

    import TenantTickets from './../../components/tickets/TenantTickets.vue';
    import TicketDetail from './../../components/tickets/TenantTicketDetail.vue';
    import ReplaceImage from '../../components/blocks/ReplaceImage.vue';

    // Modal
    import RightModal from './../../components/modal/RightModal.vue';

    export default {
        name: 'Tickets',
        props: ['profile', 'properties', 'selected', 'hasNoProperty'],

        components: {
            Skeletion,

            TenantTickets,
            TicketDetail,
            ReplaceImage,

            RightModal,
        },

        data () {
            return {
                // ticket filter menu with custom filter 
                FilterMenuList : [
                  {
                    key : 1,
                    name : 'All',
                    filter  : 'all',
                  },
                  {
                    key : 2,
                    name : 'Unassigned',
                    filter  : 'unassigned'
                  },
                  {
                    key : 3,
                    name : 'Open',
                    filter  : 'open',
                    active : true,
                  },
                  {
                    key : 4,
                    name : 'Closed',
                    filter  : 'closed'
                  },
                  {
                    key : 5,
                    name : 'Favorites',
                    filter  : 'favorites'
                  }
                ],

                // all ticket lists
                TicketList : false,
                TicketLoadingState : true,

                // filter selected
                TicketFilter : null,

                // tickets clicked/selected
                ticketSelected : false,
                selectedTicket : null,

                // catch filter click and changes
                filterChanges : 'all',                

                // modal and settings
                AddTicketData : false,
                AddNewTicketModal : false,

                TenantProperty : this.selected,
            }
        },

        async mounted () {

            // site title and seo
            Document.DocumentHeadings ({title : 'Tickets - PropStop'});

            // now load all the owner parkings
            const Tickets = await DataCenter.ProPRequest('tenant-tickets', false, false);
            this.TicketLoadingState = false;

            if (!Tickets) {
              this.TicketList = [];
            }else {
                this.TicketList = Tickets;
                this.filterChanges = "open";
                this.TicketFilter = "open";
            }

        },

        methods : {   

          RouteToPage (page) {
                this.$router.push({name : page});
            },

            ticketFilterListHandler (list) {
                // make the clicked list active
                this.FilterMenuList = this.FilterMenuList.filter (ftr => {
                  ftr.active = false;
                  if (list.key == ftr.key) {ftr.active = true;}
                  return ftr;
                });

                // now set the new filter selection
                this.TicketFilter = list.filter;
                this.filterChanges = list.filter;

                // load the ticket list page
                this.ticketSelected = false;
            },

            ticketClickHandler (ticket) {
                this.selectedTicket = ticket;
                this.ticketSelected = true;
            },

            listBackbtn (ticket) {
                // now delete ticket
                if (ticket) {
                    this.TicketList = this.TicketList.filter (tkt => {
                        if (tkt.id == ticket.id) {} else {return tkt;}
                    });

                    // update to the cache too
                    Storage.deleteCache('tenant-tickets');
                    Storage.storeCache('tenant-tickets', this.TicketList);  
                }

                // back to list
                this.ticketSelected = false;
            },

            // close the ticket
            listBackTicketClosed (ticket) {
                this.TicketList = this.TicketList.filter (tkt => {
                    if (tkt.id == ticket.id) {
                      tkt.status = "Closed";
                    }return tkt;
                });

                // update to the cache too
                Storage.deleteCache('tenant-tickets');
                Storage.storeCache('tenant-tickets', this.TicketList);

                // back to list
                this.ticketSelected = false;

            },

            // Add New Tenant Form/Modal
            AddNewTicket () {
                // now load the right modal for new tenant additions
                this.AddTicketData = {
                    property : this.TenantProperty,
                };

                this.AddNewTicketModal = true;
            },

            // close the modal box
            CloseModalBox (tickets) {
                if (tickets) {
                   this.TicketList = tickets;

                    // update the Storage
                    Storage.deleteCache('tenant-tickets');
                    Storage.storeCache('tenant-tickets', this.TicketList);

                    // update the ticket list
                    this.filterChanges = 'new-ticket-added';
                }

                this.AddNewTicketModal = false;
            }
        }
    };

</script>