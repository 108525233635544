<script>

    import DataCenter from './../../javascript/DataCenter';
    import SkeletonVue from '../../components/blocks/Skeleton.vue';

    // datepicer
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    export default {
        name: 'UnitTransactions',
        props: ['profile', 'properties', 'selected', 'hasNoProperty'],
        emits: ['back'],
        components: {
            SkeletonVue,
            Datepicker,
        },

        data() {
            return {
                TransactionsList : [],
                FinanceLoadingState : true,

                DateFrom: null,
                DateTo: null,
                Categories: [],
            }
        },

        async mounted () {


            if (this.selected.isRented) {

                // now load current unit/tenant transactions
                // load all the transaction of this property
                let getdata = {
                    key : 'unit',
                    value : this.selected.unit.id
                }

                const Transactions = await DataCenter.ProPRequest('tenant-transaction-list', false, getdata);
                this.FinanceLoadingState = false;

                if (!Transactions) {
                    this.TransactionsList = [];
                }else {
                    this.TransactionsList = Transactions;
                }

            }

        },

        methods: {
            RouteToPage (page) {
                this.$router.push({name : page});
            },
        }

    }

</script>

<template>
    <div class="payment-dtl">
        
        <div class="fc--header" style="overflow: unset;">      
            <div class="fc-more-filter" style="width: 100%">


                <div class="pf--inputfilter">
                    <Datepicker class="dp__input_custom dic_input" v-model="DateFrom" 
                        :enableTimePicker="false" 
                        autoApply 
                        required 
                        fixedStart
                        @update:modelValue="CheckPastDate"
                        placeholder="Pick Date" />
                </div>

                <div class="pf--inputfilter">
                    <Datepicker class="dp__input_custom dic_input" v-model="DateTo" 
                        :enableTimePicker="false" 
                        autoApply 
                        required 
                        fixedStart
                        @update:modelValue="CheckPastDate"
                        placeholder="Pick Date" />
                </div>

                <select name="selcategory" class="selcategory">
                    <option value="billing">Billing</option>                
                    <option value="payment">Payment</option>
                </select>

            </div>
        </div>

        <div class="prop--table" style="margin-top: 100px;">
            <div class="prop-row row-header" style="padding: 10px 0 !important;">
                <div class="column column20" style="font-weight: bold;font-size: 11pt;"><p>Date</p></div>
                <div class="column column35" style="font-weight: bold;font-size: 11pt;"><p>Remarks</p></div>
                <div class="column column15" style="font-weight: bold;font-size: 11pt;"><p>Billing</p></div>
                <div class="column column15" style="font-weight: bold;font-size: 11pt;"><p>Payment</p></div>
                <div class="column column15" style="font-weight: bold;font-size: 11pt;"><p>Balance</p></div>
            </div>

            <div v-if="FinanceLoadingState == true" style="padding-top: 195px;">
                <SkeletonVue :data="2" />
            </div>

            <div class="prop-row odd" v-else v-for="(trans, index) in TransactionsList" :key="trans"  :class="index % 2 ? 'odd' : 'even'" >
                <div class="column column20">
                    <div class="fin-transaction">
                        <p>{{trans.created}}</p>
                    </div>
                </div>

                <div class="column column35">
                    <div class="fin-transaction">
                        <p>{{trans.remarks}}</p>
                    </div>
                </div>

                <div class="column column15">
                    <div class="fin-paymethod">
                        <p v-if="trans.billing != '0.0'">{{trans.billing.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            })}}</p>
                    </div>
                </div>

                <div class="column column15">
                    <div class="fin-paymethod">
                        <p v-if="trans.payment != '0.0'">{{trans.payment.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            })}}</p>
                    </div>
                </div>

                <div class="column column15">
                    <div class="fin-paymethod">
                        <p>{{trans.balance.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            })}}</p>
                    </div>
                </div>

            </div> 


        </div>


        <div class="payment-btn-wr">
            <div class="btn-add"><img src="../../images/payments/add.png">Add Payment Method</div>
            <div class="btn-add add-bal">Add Balance</div>
        </div>
    </div>

    <div v-if="hasNoProperty" class="empty-result animated fadeInUp" style="top: 0px;left: 0px;">
        <img src="./../../images/icons/on-verification.svg">
        <p>It looks like you haven't been assigned to a property yet.<br>Please search property/units where you want to live.</p>
        <div class="btn-primary bphasno" style="margin-top: 10px;" @click="RouteToPage('tenant-search')">
            <p>Search Properties</p>
        </div>
    </div>
    
</template>