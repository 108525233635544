<template>
    <div class="prop-form _propscrl">        
        <div class="form-area trenhistry" style="padding-bottom: 65px;">
                <div class="pf--title">
                    <h3>Unit Rent History</h3>
                    <p>View the rent history of current unit <br><b>{{ this.data.name }} Floor</b> </p>
                </div>

                <div class="mu--floors" style="overflow-x: unset;overflow-y: unset;">
                    <ul>
                        <li v-for="tab in HistoryTabs" :key="tab.key" :class="{active : tab.active}" @click="TabClicked (tab)">
                            <p>{{ tab.name }}</p>
                        </li>
                        <li></li>
                    </ul>
                </div>

                <div v-if="Requesting" style="display: flex;margin-top: 65px;">
                    <SkeletonVue :type="'user-list'" :data="1" />
                </div>

                <!-- If no data on result -->
                <div v-if="!Requesting && RentHistory.length == 0 && HistoryTabActive == 'rent'" class="emptyskeleton">
                    <img src="../../images/icons/empty-result.svg" class="psanim-1 fadeInUp">
                    <p class="psanim-2 fadeInUp">This unit doesn't have rent changes history. <br />You will see the unit rent changes history here.</p>
                </div>

                <div v-if="!Requesting && RentHistory.length > 0 && HistoryTabActive == 'rent'" class="prop--table" style="display: flex;flex-direction: column;align-items: center;justify-content: flex-start;border: 1px solid #dbdbdb;margin: 10px 0;border-radius: 7px; padding:10px;">
                    <div class="prop-row row-header" style="padding-bottom: 20px !important;">
                        <div class="column column30"><p>Previous Rent</p></div>
                        <div class="column column30"><p>Current Rent</p></div>
                        <div class="column column40"><p>Effective Date</p></div>
                    </div>

                    <div class="prop-row" v-for="history in RentHistory" :key="history.id">
                        <div class="column column30"><p style="font-weight: bold;" :class="history.active ? '' : 'notactive'">${{history.before}}</p></div>
                        <div class="column column30"><p style="font-weight: bold;" :class="history.active ? '' : 'notactive'">${{history.new}}</p></div>
                        <div class="column column40"><p :class="history.active ? '' : 'notactive'">{{ history.date }}</p></div>
                    </div>
                </div>     

                <!-- If no data on result -->
                <!-- <div v-if="!Requesting && DepositHistory.length == 0 && HistoryTabActive == 'deposit'" class="emptyskeleton">
                    <img src="../../images/icons/empty-result.svg" class="psanim-1 fadeInUp">
                    <p class="psanim-2 fadeInUp">This unit doesn't have deposit history. <br />You will see the unit deposit history here.</p>
                </div> -->

                <div v-if="!Requesting && HistoryTabActive == 'deposit'" class="prop--table" style="display: flex;flex-direction: column;align-items: center;justify-content: flex-start;border: 1px solid #dbdbdb;margin: 10px 0;border-radius: 7px; padding:10px;">
                    <div class="prop-row row-header" style="padding-bottom: 20px !important;">
                        <div class="column column30"><p>Type</p></div>
                        <div class="column column30"><p>Notes</p></div>
                        <div class="column column40"><p>Amount</p></div>
                    </div>

                    <div class="prop-row">
                        <div class="column column30"><p>Initial Depost</p></div>
                        <div class="column column30"><p>note</p></div>
                        <div class="column column40"><p>${{ InitDeposit }}</p></div>
                    </div>

                    <div class="prop-row" v-for="history in DepositHistory" :key="history.id">
                        <div class="column column30"><p>{{history.additional}}</p></div>
                        <div class="column column30"><p>{{history.notes}}</p></div>
                        <div class="column column40"><p>${{ history.amount }}</p></div>
                    </div>
                </div>             

            
        </div>
        <span class="primary-btn-loader" style="float: right !important;" @click="CloseModel">
            <p >Okay</p>
        </span>
    </div>
</template>

<script>
    //@ JS Modules
    import DataCenter from '../../javascript/DataCenter';

    import SkeletonVue from '../blocks/Skeleton.vue';

    // datepicer
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    export default {
        name: 'RentAmountChange',
        props: ['data'],
        emits: ['close'],
        components: {
            SkeletonVue,

            Datepicker,
        },

        data () {
            return {

                Amount: '',
                EffectiveDate: null,
                toNotifyTenant: '-1',
                NotifyTenant: '-1',
                iAgree: false,
                iAgreetoCheck: true,

                // form error
                formError : false,
                infoText : '',
                Requesting: true,
                InitDeposit: 0,
                RentHistory: [],
                DepositHistory: [],

                // tab
                HistoryTabActive : 'rent',
                HistoryTabs: [
                    {
                        key: 1,
                        name: 'Rent History',
                        tab: 'rent',
                        active: true,
                    },
                    {
                        key: 2,
                        name: 'Deposit History',
                        tab: 'deposit',
                        active: false,
                    }
                ],
            }
        },

        async mounted () {

            
            // load rent history 
            let getdata;
            if (this.data.root == 'owner') {
                getdata = {
                    key : 'assignment',
                    value : this.data.assignment
                }
            }else {
                getdata = {
                    key : 'unit',
                    value : this.data.unit
                }
            }

            var datacenter;
            if (this.data.root == 'tenant') datacenter = 'tenant-rent-history';
            else datacenter = 'owner-tenant-rent-history';

            const RequestData = await DataCenter.ProPRequest(datacenter, false, getdata);
            if (RequestData) {
                this.InitDeposit = RequestData.deposit
                this.RentHistory = RequestData.rent_history;
                this.DepositHistory = RequestData.deposit_history;
            }this.Requesting = false;


        },

        methods: {
            // tab clicked
            TabClicked (tab) {
                this.HistoryTabs.filter(flr => {
                    flr.active = false;
                    if (tab.key == flr.key) {
                        flr.active = true;
                        this.HistoryTabActive = flr.tab;
                    }
                    return flr;
                });
            },

            CloseModel () {
                this.$emit('close');
            }
            
        }


    };
</script>

<style scoped>
.notactive {
        text-decoration: line-through !important;
    }
    div.prop--table div.prop-row:last-child {
    border: none;
}
span.__formerror {margin-left: 0 !important;}
.v-calendar {width: calc(100% - 2px) !important;min-width: 100%;}
</style>