<template>
    <div class="gs--body">
        <h3 style="margin-top: -30px; margin-bottom: -5px;">Floor Plans</h3>
        <p style="font-size: 10pt;">* Indicate a required field</p>

        <div class="floor-plan-area">
            <div class="planprop-form _propscrl">        
                <div class="form-area" style="width: 95%;">
                    <form id="propform-floorplan" style="width: 100%;">
                        <ul class="afb-form">
                            <li>

                                <div class="pf--input">
                                    <p>Plan Name *</p>
                                    <input type="text" v-model="PlanName" placeholder="Dryden - All Units" id="PlanName" data-type="len" data-max="3" @focus="FloorPlanSelection = false" />
                                </div>

                                <div class="pf--multi-input" style="height: 75px;">
                                    <div class="half hleft">
                                        <div class="pf--input">
                                            <p>Floor Type *</p>
                                            <div class="floorplantype">
                                                <div class="typeselect" @click="FloorPlanClick">
                                                    <p v-if="SelectedPlanTypes == 0">- Select One -</p>
                                                    <p v-else>{{SelectedPlanTypes}} Selected</p>
                                                    <img src="./../../images/icons/chevron-down.svg" />
                                                </div>
                                                <ul class="options" v-if="FloorPlanSelection">
                                                    <li v-for="plan in FloorPlanType" :key="plan.key" @click="FloorPlanSelect (plan)">
                                                        <input type="checkbox" :checked="plan.active" style="width: 17px !important;" />
                                                        <p>{{plan.title}}</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="half hright">
                                        <div class="pf--input">
                                            <p>Square Feet *</p>
                                            <input type="text" v-model="SquareFeet" placeholder="1275" id="SquareFeet" data-type="len" data-max="1" @focus="FloorPlanSelection = false"/>
                                        </div>
                                    </div>
                                </div>

                                <div class="pf--multi-input" style="height: 75px; overflow: hidden;">
                                    <div class="half hleft">
                                        <div class="pf--input">
                                            <p>Bed Rooms *</p>
                                            <input type="text" v-model="BedRooms" placeholder="3" id="BedRooms" data-type="len" data-max="1" @focus="FloorPlanSelection = false"/>
                                        </div>
                                    </div>
                                    <div class="half hright">
                                        <div class="pf--input">
                                            <p>Bath Rooms *</p>
                                            <input type="text" v-model="BathRooms" placeholder="2.5" id="BathRooms" data-type="len" data-max="1" @focus="FloorPlanSelection = false"/>
                                        </div>
                                    </div>
                                </div>

                                <div class="pf--input">
                                    <p>Description</p>
                                    <textarea v-model="Description" placeholder="Floor plan description (optional)" id="Description" data-type="len" data-max="0" @focus="FloorPlanSelection = false"></textarea>
                                </div>

                                <div class="pf--input">
                                    <div class="upload-floor-plan" @click="filesclick">
                                        <p v-if="hasFileAttached" style="color: #449adf;">File Ready To Upload!</p>
                                        <p v-else>Click to upload floor plan blue print.</p>
                                    </div>
                                    <p v-if="AttachmentError" style="color: red;font-size: 10pt;line-height: 13pt;">The uploaded file is not supported or size is greater than 2MB!</p>
                                </div>

                            </li>

                            <li>
                                <ul class="selectamin _propscrl">
                                    <li v-for="amenity in Amenities" :key="amenity" :class="amenity.active ? 'active' : ''" @click="AmenityChoosed (amenity)">
                                        <img :src="require(`../../images/amenities/${amenity.icon}`)" />
                                        <p>{{amenity.name}}</p>
                                    </li>
                                </ul>

                            </li>
                        </ul>
                    </form>
                </div>
            </div>

            <div class="floor-plan-action">
                <span class="primary-btn-loader" @click="UpdateNewPlan" :class="Requesting ? 'btn-disabled' : ''" style="margin-top: 0px !important;margin-left: 70px;float: left;">
                    <p v-if="!Requesting">Add Plan</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>
                <span v-if="formError" class="__formerror" style="margin-left: 10px !important;margin-top: 0px;padding: 13px 10px;">{{ infoText }}</span>
                
                <input type="file" ref="files" @change="handleFilesUploads" style="display:none;" />
            </div>
        </div>
    </div>
</template>

<script>

    // import javascript
    import Locale from './../../javascript/Locale';
    import Request from './../../javascript/getRequest';
    import FormValidation from './../../javascript/FormValidation';
    import Methods from './../../javascript/Handler';

    export default {
        name: 'FloorPlanModal',
        props: ['data'],
        emits: ['close'],

        components: {
        },

        mounted () {
            console.log (this.data);
        },

        data () {
            return {
                PlanName : '',
                BedRooms : '',
                BathRooms : '',
                SquareFeet: '',
                Description: '',
                AmenitiesChoosed : [],

                UploadAttachment : false,
                AttachmentSchema : ['jpeg', 'jpg', 'png'],
                AttachmentFile: [],
                AttachmentError: false,
                hasFileAttached: false,

                Requesting : false,

                Amenities: Locale.UnitAmenities (),

                formError : false,
                infoText : '',

                FloorPlanType: [
                    {
                        key: 1,
                        active: false,
                        name: 'Wood',
                        title: 'Wood'
                    },
                    {
                        key: 2,
                        active: false,
                        name: 'Carpet',
                        title: 'Carpet'
                    },
                    {
                        key: 3,
                        active: false,
                        name: 'Tile',
                        title: 'Tile'
                    }
                ],
                FloorPlanSelection: false,
                SelectedPlanTypes: 0,

            }
        },

        methods : {

            FloorPlanClick () {
                this.FloorPlanSelection = !this.FloorPlanSelection;
            },

            FloorPlanSelect (plan) {
                this.FloorPlanType.filter (pln => {
                    if (pln.key == plan.key) {
                        pln.active = !pln.active;
                        if (pln.active) {
                            this.SelectedPlanTypes++;
                        }else {
                            this.SelectedPlanTypes--;
                        }
                    }
                });
            },
            
            AmenityChoosed (amenity) {
                if (amenity.active) amenity.active = false;
                else amenity['active'] = true;
                
                this.AmenitiesChoosed = this.Amenities.filter (ament => {
                    if (ament.active) return ament;
                });              
            },

            filesclick () {
                this.$refs.files.click();
                this.formError = false;
                this.AttachmentError = false;
            },

            handleFilesUploads () {
                if (this.Requesting) return;

                this.AttachmentFile = this.$refs.files.files[0];
                var AttachedFileInfo = Methods.AttachmentUploadHandler (this.AttachmentFile, this.AttachmentSchema, 2);
                
                // file is okay to upload
                if (AttachedFileInfo.matched && !AttachedFileInfo.largesize) {
                    this.hasFileAttached = true;
                    this.AttachmentError = false;
                }else {
                    this.AttachmentFile = [];
                    this.AttachmentError = true;
                    this.hasFileAttached = false;
                }

            },

            async UpdateNewPlan () {
                this.formError = false;
                if (this.Requesting) return;

                // form & validation
                let form = document.getElementById('propform-floorplan');
                let canGoAhead = FormValidation.Validate (form);

                // amenities choosed or not
                if (this.AmenitiesChoosed.length <=0 && canGoAhead == true) {
                    canGoAhead = false;
                    this.formError = true;
                    this.infoText = "Please choose floor plan's Amenities";
                }

                // check for the floorplan type selected
                var FloorPlanisOkay = false;
                this.FloorPlanType.forEach (plan => {
                    if (plan.active) FloorPlanisOkay = true;
                });

                if (FloorPlanisOkay == false) {
                    canGoAhead = false;
                    this.formError = true;
                    this.infoText = "Please choose floor plan's type!";
                }

                if (canGoAhead) {
                    this.Requesting = true;

                    let formData = new FormData();
                    formData.append('name', this.PlanName);
                    formData.append('bedrooms', this.BedRooms);
                    formData.append('bathrooms', this.BathRooms);
                    formData.append('squarefeet', this.SquareFeet);
                    formData.append('description', this.Description);
                    formData.append('document', this.AttachmentFile);
                    
                    // floor type 
                    var FloorType = this.FloorPlanType.map(function (plan) {
                        if (plan.active) return plan.name;
                    }).join(', ');
                    FloorType = FloorType.slice(0, -2);
                    formData.append('type', FloorType);

                    // amenities
                    let amenities = this.AmenitiesChoosed.map(amenity => {
                        return amenity.id;
                    }).join(',');

                    formData.append('amenities', amenities);
                    formData.append('property', this.data);

                    // load data from server
                    const ReturnData = await Request.postServer('owner/property/floorplan/add-plan.php', formData);
                    this.Requesting = false;

                    if (ReturnData.error == false) {
                        this.$emit('close', ReturnData.response);
                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again.";
                    }

                }
            },
        }
    };

</script>