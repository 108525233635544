const DocumentHeadings = (data) => {
    if (data.title) {
        document.title = data.title;
    }
};

const MinifiedText = (Str) => {
    let Minified = "";
    let Spaces = 0;
    for(let i = 0; i <= Str.length; i++) {
        if (Str[i] == " ") {
            Spaces++;
        }
        if (Spaces == 3) break;
        Minified += Str[i];
    }

    return Minified + '..';
}

export default {
    DocumentHeadings,
    MinifiedText,
};