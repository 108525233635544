<template>
  <div class="owner-tickets">
      
      <div class="sidebar" :style="SidebarHamClick ? 'left: 0px !important;box-shadow: rgb(0 0 0 / 22%) 0px 7px 10px !important;' : ''">

        <!-- Skeleton -->
        <div v-if="TicketLoadingState == true" style="margin-top:55px">
            <Skeletion :data="3" />
        </div>
        
          <ul v-else class="tickets-menu">              
              <li :class="list.active ? 'active' : ''" v-for="list in FilterMenuList" :key="list" @click="ticketFilterListHandler(list)">
                  <p>{{list.name}}</p>
                  <span v-if="list.count >= 1">{{ list.count }}</span>
              </li>
          </ul>

          <div class="btn-primary" @click="AddNewTicket">
              <img src="../../images/icons/addbtn-icon.svg" />
              <p>Create Ticket</p>
          </div>
      </div>

      <div class="proprty-ham-menu" style="margin-left: 15px;" v-if="!ticketSelected" @click="SidebarHamClick = true">
        <img src="./../../images/icons/line-menu.svg" />
        <p>Tickets Filter</p>
      </div>

        <!-- Ticket list page -->
      <div v-if="!ticketSelected" class="tickets-content _propscrl animated fadeInUp">
          <OwnerTickets :tickets="TicketList" :chart="TicketChart" :category="ChartCategory" :propselected="currentProperty" :properties="properties" :filter="TicketFilter" :key="filterChanges" @ticketClicked="ticketClickHandler" />
      </div>

      <!-- Ticket detail page as selected -->
      <TicketDetail v-else 
            :ticket="selectedTicket" 
            :tickets="TicketList"
            :employees="OwnerEmployees"
            :profile="profile"
            @backtolist="listBackbtn" 
            @backandclose="listBackTicketClosed" @editticket="UpdateEditedTicketList" @updatefilter="FilterTicketWithList"
            :key="ticketSelected" />

      <div v-if="OwnerHasNoProperty" class="empty-result animated fadeInUp">
          <img src="./../../images/icons/on-verification.svg">
          <p>It looks like you haven't added a property yet.<br>Please add a property to view all features.</p>
          <div class="btn-primary bphasno" @click="RouteToAddProperty">
              <p>Property Page</p>
          </div>
      </div>

  </div>

  <!-- pop ups --> 
  <RightModal v-if="AddNewTicketModal" :form="'add-ticket'" :data="AddTicketData" @modalclose="CloseModalBox" />

</template>

<script>

    //@ JS Modules
    import Document from './../../javascript/Document';
    import Handler from './../../javascript/Handler';
    import DataCenter from './../../javascript/DataCenter';
    import Storage from './../../javascript/Storage';

    // pinia
    import { useOwnerStore } from '../../stores/OwnerStore';

    import Skeletion from './../../components/blocks/Skeleton.vue';

    import OwnerTickets from './../../components/tickets/OwnerTickets.vue';
    import TicketDetail from './../../components/tickets/OwnerTicketDetail.vue';
    import ReplaceImage from '../../components/blocks/ReplaceImage.vue';
    import PropertyList from '../../components/blocks/PropertyList.vue';

    // Modal
    import RightModal from './../../components/modal/RightModal.vue';

    export default {
        name: 'Tickets',
        props: ['profile', 'properties', 'employees', 'selected'],
        emits: ['updateproperties'],
        
        components: {
            Skeletion,

            OwnerTickets,
            TicketDetail,
            ReplaceImage,
            PropertyList,

            RightModal,
        },

        data () {
            return {
                // all ticket lists
                TicketList : false,
                TicketChart: false,
                TicketLoadingState : true,
                OwnerEmployees: this.employees,

                // filter selected
                TicketFilter : 'open',

                // tickets clicked/selected
                ticketSelected : false,
                selectedTicket : null,

                // catch filter click and changes
                filterChanges : null,

                // ticket filter menu with custom filter 
                FilterMenuList : [
                  {
                    key : 1,
                    name : 'All',
                    filter  : 'all',
                    count: 0
                  },
                  {
                    key : 2,
                    name : 'Unassigned',
                    filter  : 'unassigned',
                    count: 0
                  },
                  {
                    key : 3,
                    name : 'Open',
                    filter  : 'open',
                    count: 0,
                    active : true
                  },
                  {
                    key : 5,
                    name : 'On Hold',
                    filter  : 'onhold',
                    count: 0
                  },
                  {
                    key : 6,
                    name : 'In-Progress',
                    filter  : 'inprogress',
                    count: 0
                  },
                  {
                    key : 7,
                    name : 'Closed',
                    filter  : 'closed',
                    count: 0
                  },
                  {
                    key : 8,
                    name : 'Favorites',
                    filter  : 'favourites',
                    count: 0
                  },
                  {
                    key : 9,
                    name : 'Archive',
                    filter  : 'archive',
                    count: 0
                  }
                ],

                // modal and settings
                AddTicketData : false,
                AddNewTicketModal : false,

                // property list for each tenants
                OwnerHasNoProperty : true,
                OwnerProperties : false,
                currentProperty : [],
                currentPropertyImageKey : 'current',

                // responsive
                SidebarHamClick: false,

                ChartCategory : null,

                // search click functions
                OwnerStore: useOwnerStore(),
                ParamsChange: useOwnerStore().route,
            }
        },

        watch: {
          ParamsChange: {
              handler (newStore) {
                if (this.ParamsChange.page == 'Tickets') {
                      var SelectedTicket = newStore.params;
                      var Tickets = this.OwnerStore.tickets;
                      var Selected = {};
                      Tickets.filter (ticket => {
                          if (SelectedTicket == ticket.id) Selected = ticket;
                      });

                      // open the ticket
                      if (Selected) this.ticketClickHandler (Selected);
                }
              },
              deep: true,
          }
        },

        async mounted () {

            // site title and seo
            Document.DocumentHeadings ({title : 'Tickets - PropStop'});

            // hide the properties selection from here
            var OwnPrty = document.getElementById('ownerprtities');
            if (OwnPrty != null || OwnPrty != undefined) {
                OwnPrty.style.visibility = 'hidden';
                OwnPrty.style.opacity = '0';
            }

            // pinia setup
            const OwnerStore = useOwnerStore ();
            this.LoadOwnerTickets (OwnerStore);

            // property list of current owner
            this.OwnerProperties = this.properties;
            this.currentProperty = this.selected;

            if (this.OwnerProperties.length >= 1) {
              this.OwnerHasNoProperty = false;
            }  

            // global add action
            var AddAction = document.getElementById('AppMainAddActionIcon');
            AddAction.onclick = () => {
              this.AddNewTicket ();
            };
            
        },

        methods : {

          FilterTicketWithList () {
            var all = 0, open = 0, closed = 0, unassigned = 0, favourites = 0, onhold = 0, inprogress = 0, archive = 0;
            all = this.TicketList.length;

            // filter with selected now
            this.TicketList.filter (ticket => {
                  // open ticket
                  if (ticket.status == "Open" && !ticket.isArchive) open++;
                  if (ticket.status == "Closed" && !ticket.isArchive) closed++;
                  if (!ticket.isAssigned && !ticket.isArchive) unassigned++;
                  if (ticket.isFavourite && !ticket.isArchive) favourites++;
                  if (ticket.status == "On Hold" && !ticket.isArchive) onhold++;
                  if (ticket.status == "In-Progress" && !ticket.isArchive) inprogress++;
                  if (ticket.isArchive) archive++;
              });

              // update the DOM
              this.FilterMenuList.forEach (filter => {
                if (filter.filter == 'all') filter.count = all;
                if (filter.filter == 'open') filter.count = open;
                if (filter.filter == 'closed') filter.count = closed;
                if (filter.filter == 'unassigned') filter.count = unassigned;
                if (filter.filter == 'favourites') filter.count = favourites;
                if (filter.filter == 'onhold') filter.count = onhold;
                if (filter.filter == 'inprogress') filter.count = inprogress;
                if (filter.filter == 'archive') filter.count = archive;
                
              });
          },

          // Load all the tickets
          async LoadOwnerTickets (OwnerStore) {
            if (OwnerStore.tickets.length == 0) {
              // now load all the owner tickets
              const Tickets = await DataCenter.ProPRequest('owner-tickets', false, false);

              if (!Tickets) {
                this.TicketList = [];
                
                // pinia
                OwnerStore.tickets = [];
              }else {
                  this.TicketList = Tickets.tickets;
                  this.TicketChart = Tickets.chart;
                  this.ChartCategory = Tickets.category;

                  // pinia
                  OwnerStore.tickets = Tickets.tickets;
              }

            }else {
              this.TicketList = OwnerStore.tickets;
            }
            
            // remove loading state
            this.TicketLoadingState = false;
            
            // update the list re-render
            this.filterChanges = Math.random(100);

            // update dom with ticket count
            this.FilterTicketWithList ();


          },
          
          // properties choosed for ticket filter
          PropertyFilterApplied () {
            
          },
          
          // no properties now route to add property
            RouteToAddProperty () {
                this.$router.push({name : 'owner-properties'});
            },

            ticketFilterListHandler (list) {
                // make the clicked list active
                this.FilterMenuList = this.FilterMenuList.filter (ftr => {
                  ftr.active = false;
                  if (list.key == ftr.key) {ftr.active = true;}
                  return ftr;
                });

                // now set the new filter selection
                this.TicketFilter = list.filter;
                this.filterChanges = list.filter;

                // load list if it is on ticket detail page
                this.ticketSelected = false;

                // remove sidebar responsive
                this.SidebarHamClick = false;
            },

            ticketClickHandler (ticket) {
                this.selectedTicket = ticket;
                this.ticketSelected = true;
            },

            listBackbtn (ticket) {
                // now delete ticket
                if (ticket) {
                    this.TicketList = this.TicketList.filter (tkt => {
                        if (tkt.id == ticket.id) {} else {return tkt;}
                    });

                    // update to the cache too
                    Storage.deleteCache('owner-tickets');
                    Storage.storeCache('owner-tickets', this.TicketList);

                    // update notification
                    Handler.PropNotification ('Your ticket "' + ticket.subject + '" has been successfully archived.');
                }

                // back to list
                this.ticketSelected = false;
            },

            // close the ticket
            listBackTicketClosed (ticket) {
                this.TicketList = this.TicketList.filter (tkt => {
                    if (tkt.id == ticket.id) {
                      tkt.status = "Closed";
                    }return tkt;
                });

                // update to the cache too
                Storage.deleteCache('owner-tickets');
                Storage.storeCache('owner-tickets', this.TicketList);

                // back to list
                this.ticketSelected = false;

                // update notification
                Handler.PropNotification ('Your ticket "' + ticket.subject + '" has been successfully closed.');
            },

            // Add New Tenant Form/Modal
            AddNewTicket () {
                // now load the right modal for new tenant additions
                this.AddTicketData = {
                    property : this.currentProperty,
                    properties : this.properties,
                    tenant: false,
                };
                this.AddNewTicketModal = true;
            },

            // close the modal box
            CloseModalBox (tickets) {
                if (tickets) {
                   this.TicketList = tickets;

                   // update pinia
                   const OwnerStore = useOwnerStore ();
                   OwnerStore.ResetTickets (tickets);

                    // update the ticket list
                    this.filterChanges = 'new-ticket-added' + Math.random(15);

                    // update notification
                    Handler.PropNotification ('New ticket has been added to the list. Successfully updated!');
                }

                this.AddNewTicketModal = false;
            },

            // udpate the edited ticket list
          UpdateEditedTicketList (tickets, selected) {
            this.TicketList = tickets;
            this.selectedTicket = selected;
          }

          
        },
    };

</script>

<style scoped>
div.empty-result {position: absolute;background-color: rgb(255 255 255 / 92%);}
  .bphasno {margin-top: 15px !important;}
  .bphasno > img {width: 15px !important;}


</style>