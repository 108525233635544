<template>

    <div class="maincontent">
        <div class="mcheader">
            <img src="./../../images/svg/maintenance.svg" alt="Maintenance">
            <h1>We're busy upgrading the site, we will be back soon!</h1>
            <p>We're experiencing technical difficulties. Our engineers are working on it as fast as they can, please check back soon!</p>
        </div>
        <div class="mcfooter">
            <p>PropStop Inc. 2470 Stearns St., Suite 214 Simi Valley, CA 93603</p>
        </div>
    </div>

</template>

<style scoped>
    div.maincontent {    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;}
    div.maincontent div.mcheader {    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 50%;}
    div.mcheader img {    width: 280px;}
    div.mcheader h1 {    font-size: 32pt;
    line-height: 41pt;
    text-align: center;}
    div.mcheader p {    text-align: center;
    font-size: 12pt;
    line-height: 20pt;
    width: 75%;}

    div.mcfooter {    width: 100%;
    align-self: flex-end;
    text-align: center;
    border-top: 1px solid #d4d4d4;
    padding: 15px;
    margin-top: 30px;}
    div.mcfooter p {font-size: 10pt;}
</style>