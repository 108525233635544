const Validate = (form) => {

    var htmlform = form, length, i, t, v, n, max, felb, felbd;
    length = htmlform.elements.length;
    
    for (i=0;i<length;i++) {
        t = htmlform.elements[i].getAttribute('data-type');
        v = htmlform.elements[i].value;
        n = htmlform.elements[i].getAttribute('id');
        
        // every input element parent block id will be same with addition "felb"
        felbd = document.getElementById(n);

        if (felbd == null) continue;

        felbd.classList.remove('__error');

        if (t == "len") {
            max = htmlform.elements[i].getAttribute('data-max');
            if (v.length < max) {
                felbd.classList.add('__error');
                return false;
            }else {
                felbd.classList.remove('__error');
            }
        }else if (t == "select") {
            if (v == "-1") {
                felbd.classList.add('__error');
                return false;
            }else {
                felbd.classList.remove('__error');
            }
        }else if (t == "email") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(v)) {
                felbd.classList.add('__error');
                return false;
            }else {
                felbd.classList.remove('__error');
            }
        }
    }

    return true;
}

export default {
    Validate
};