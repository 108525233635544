<template>
    <ul class="tickets-left">
        <li>
            <div class="dotblue"></div>
            <div class="t-content">
                <p>{{OpenTickets}} Open</p>
            </div>
        </li>
        <li>
            <div class="dotlblue"></div>
            <div class="t-content">
                <p>{{AssignTickets}} Assigned</p>
            </div>
        </li>
        <li>
            <div class="orange"></div>
            <div class="t-content">
                <p>{{ResolvedTickets}} Resolved</p>
            </div>
        </li>
        <li>
            <div class="d-blue"></div>
            <div class="t-content">
                <p>{{CreatedTickets}} Created</p>
            </div>
        </li>
    </ul>

    <div class="tickets-right">
        <div id="tickets-chart" style="overflow: hidden;width: 100%;height: auto; margin-top:25px"></div>
    </div>
</template>

<script>
    export default {
        name : 'Tickets',
        props: ['data'],
        components: {

        },

        data () {
            return {
                OpenTickets : 0,
                AssignTickets : 0,
                ResolvedTickets : 0,
                CreatedTickets : 1,
            }
        },

        mounted () {
            // set the data
            this.OpenTickets = this.data.open;
            this.AssignTickets = this.data.assigned;
            this.ResolvedTickets = this.data.resolved;
            this.CreatedTickets = this.data.created;

            this.TicketsChart (this.data);
        },

        methods : {

            // chart settings
            TicketsChart (data) {
                Highcharts.chart('tickets-chart', {
                    chart: {
                        type: 'column',
                        width: 180,
                        height: 180
                    },
                    title: {
                        text: ''
                    },
                    accessibility: {
                        announceNewData: {
                            enabled: true
                        }
                    },                
                    legend: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            borderWidth: 0,
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    var thispoint = (this.point.y/1)*100;
                                    return thispoint.toFixed(0) + '%';
                                }
                                
                            },
                            colors: [
                                '#5FA7FA',
                                '#A6CEE3',
                                '#FFAA00',                            
                            ],                            
                        }
                    },
                
                    tooltip: {
                        formatter: function () {
                            var thispoint = (this.point.y/1)*100;
                            var pointPercent = thispoint.toFixed(0) + '%';
                            return this.point.name +'<br /><span style="color:{point.color}"> <b>'+ pointPercent +' of '+ data.created +'</b> </span>';
                        }
                    },
                
                    series: [
                        {
                            name: "Tickets",
                            colorByPoint: true,
                            data: [
                                {
                                    name: "Open Tickets",
                                    y: data.open
                                },
                                {
                                    name: "Assigned",
                                    y: data.assigned
                                },
                                {
                                    name: "Resolved",
                                    y: data.resolved
                                }
                                
                                
                            ]
                        }
                    ],
                    
                });
            },

        }
    };
</script>