<template>
    <div class="form-area animated fadeInUp" style="width: 100% !important;">
        <div class="lb-logo">
            <img src="../../images/website/propstop-logo.svg" alt="PropStop" title="PropStop" />
            <h2>PROPSTOP</h2>
            <p>The only property management tool you'll ever need.</p>
        </div>

        <div v-if="ForgotForm == 'request'" class="input-form animated fadeInRight">
            <form name="propform" id="propform-forgot" style="display: block;">
                <div class="pf--input" style="margin: 35px 0;">
                    <p>Email Address</p>
                    <input type="text" placeholder="Email" v-model="Email" id="Username" data-type="email" />
                    <p class="resetcode" @click="ForgotForm = 'verify'">I have verification code</p>
                </div>

                <div class="pf--input">
                    <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="RequestCode">
                        <p v-if="!Requesting">Request Code</p>
                        <img v-else class="loader" src="./../../images/gif/btn-loader.gif" alt="Loader" />
                    </span>
                </div>
            </form>
        </div>

        <div v-if="ForgotForm == 'verify'" class="input-form animated fadeInRight">
            <form name="propform" id="propform-verify" style="display: block;">
                <div class="pf--input" style="margin: 35px 0;">
                    <p>Password Reset Code</p>
                    <input type="text" placeholder="6 digit code" v-model="Verification" id="Verification" data-type="len" data-len="6" />
                    <p class="resetcode" @click="ForgotForm = 'request'">Resend Code?</p>
                </div>

                <div class="pf--input">
                    <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="VerifyEmail">
                        <p v-if="!Requesting">Verify</p>
                        <img v-else class="loader" src="./../../images/gif/btn-loader.gif" alt="Loader" />
                    </span>
                </div>
            </form>
        </div>

        <div v-if="ForgotForm == 'reset'" class="input-form animated fadeInRight">
            <form name="propform" id="propform-reset" style="display: block;">
                
                <div class="pf--input" style="margin-top: 25px;">
                    <PasswordInput @passwordchange="HandlePasswordInput" :direction="''" />
                </div>

                <div class="pf--input">
                    <p style="line-height: 15pt;font-size: 10pt;text-align: center;">You have successfully verified your email, please reset your password and confirm!</p>
                </div>

                <div class="pf--input">
                    <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="ChangePassword">
                        <p v-if="!Requesting">Change Password</p>
                        <img v-else class="loader" src="./../../images/gif/btn-loader.gif" alt="Loader" />
                    </span>
                </div>
            </form>
        </div>

        <div class="oth-action">
            <span v-if="formError" class="__info" @click="HideInfoText">{{ infoText }}</span>
        </div>
        
    </div>
</template>

<script>

    //@import javascript functions
    import Authentication from './../../javascript/Locale';
    import Cookie from './../../javascript/Cookie';
    import FormValidation from './../../javascript/FormValidation';

    //@ JS Modules
    import Document from './../../javascript/Document';
    import Request from './../../javascript/getRequest';

    import PasswordInput from './../web-forms/PasswordInput.vue';

    export default {
        name : "Forgot",
        emits : ['forgot'],
        components: {
            PasswordInput
        },
        data () {
            return {
                Email : '',
                Verification: '',
                Requesting : false,

                Password: '',
                passwordStrength: '',

                formError: false,
                infoText: '',

                ForgotForm: 'request'
            }
        },

        mounted () {
            // site title and seo
            Document.DocumentHeadings ({title : 'Forgot Password - PropStop'});
        },
        
        methods : {
            HideInfoText () {
                this.formError = false;
            },

            async RequestCode () {
                if (this.Requesting) return;

                // form & validation
                let form = document.getElementById('propform-forgot');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead) {
                    this.Requesting = true;
                    
                    // create new formdata
                    let formData = new FormData();

                    // add datas
                    formData.append('email', this.Email);

                    // request to server
                    const ReturnData = await Request.postServer('auth/forgot.php', formData, null);
                    this.Requesting = false;

                    // show for reset code entering form
                    if (!ReturnData.error && ReturnData.response) {
                        this.ForgotForm = 'verify';
                        this.Verification = '';
                    }else {
                        this.formError = true;
                        this.infoText = 'Your email address is not registered with us. Please create new account.';
                    }
                }
            },

            async VerifyEmail () {
                if (this.Requesting) return;

                // form & validation
                let form = document.getElementById('propform-verify');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead) {
                    this.Requesting = true;
                    
                    // create new formdata
                    let formData = new FormData();

                    // add datas
                    formData.append('email', this.Email);
                    formData.append('code', this.Verification);

                    // request to server
                    const ReturnData = await Request.postServer('auth/reset-verify.php', formData, null);
                    this.Requesting = false;

                    // show for reset code entering form
                    if (!ReturnData.error && ReturnData.response) {
                        this.ForgotForm = 'reset';
                    }else {
                        this.formError = true;
                        this.infoText = 'The verification code you are using may expired or incorrect. Please try again!';
                    }
                }
            },

            HandlePasswordInput (password, strength) {
                this.Password = password;
                this.passwordStrength = strength;
            },

            async ChangePassword () {
                if (this.Requesting) return;
                
                // form & validation
                let form = document.getElementById('propform-reset');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead == true && (this.passwordStrength == "green" || this.passwordStrength == "orange")) {
                    this.Requesting = true;
                    
                    // create new formdata
                    let formData = new FormData();

                    // add datas
                    formData.append('email', this.Email);
                    formData.append('password', this.Password);

                    // request to server
                    const ReturnData = await Request.postServer('auth/reset-password.php', formData, null);

                    // show for reset code entering form
                    if (!ReturnData.error && ReturnData.reason == 'success') {
                        let route, token;
                        route = ReturnData.response.route;
                        token = ReturnData.response.token;

                        // now set data to cookies and force login
                        Cookie.setCookie ('ps-route', route, 1);
                        Cookie.setCookie ('ps-token', token, 1);

                        // reload the app in auth
                        // app mount javascript will handle the rest
                        location.reload();
                    }else {
                        this.Requesting = false;
                        this.formError = true;
                        this.infoText = 'Something went wrong, Please try again later or contact support!';
                    }
                }
            },
        }
    };
</script>

<style scoped>
.primary-btn-loader {    display: block;
    float: none !important;
    width: calc(100% - 63px) !important; text-transform: uppercase;}
.resetcode {    color: #449adf;
    cursor: pointer;}
</style>