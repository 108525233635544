<template>
      <WebNav />

        <!-- internal header hero -->
        <InternalHeader  :page="'web-waitlist'" 
                        :title="'Join the PropStop Waitlist: Be the First to Experience Innovation'" 
                        :description="'Secure your spot on the PropStop waitlist for early access to the latest in property management innovation. Join us and be among the first to experience how PropStop is changing the game for landlords and tenants.'" />
            <!-- internal header hero -->

            <!-- our story -->
            <section class="about">
                <div class="wrapper" style="padding: 0;">
                        <div class="abwr-about atbout-resp" style="flex-direction: row-reverse;">
                            <div class="image" style="height: 360px">
                                <img src="./../../images/website/join-waitlist.svg" style="object-fit: contain;" alt="Join Waitlist">
                            </div>
                            <div class="content">
                                    <span>Join Us</span>
                                    <h2>Be the First to <em><p>Experience</p></em> the Future of Property Management.</h2>
                                    <p>Excited about what PropStop has to offer? You're in the right place! Join our waitlist today and be among the first to get access to the most innovative property management platform designed for both landlords and tenants.</p>
                            </div>
                        </div>
                </div>
            </section>
            <!-- our story -->

            <section class="whyhow">
                <div class="wrapper" style="padding: 0;">
                    <ul>
                        <li>
                            <h3>Why Join the Waitlist?</h3>
                            <p><strong>Early Access:</strong> Get a first look and experience the latest features before anyone else.</p>
                            <p><strong>Exclusive Updates:</strong> Receive updates and news directly to your inbox.</p>
                            <p><strong>Feedback Counts:</strong> Your feedback can help shape the future of PropStop. We're building this together.</p>
                        </li>

                        <li>
                            <h3>How It Works?</h3>
                            <p><strong>Sign Up:</strong> Fill out the form below with your details.</p>
                            <p><strong>Stay Tuned:</strong> We'll send you a confirmation email and regular updates about your waitlist status.</p>
                            <p><strong>Get Access:</strong> As soon as we're ready to welcome more users, you'll get exclusive early access to PropStop.</p>
                        </li>

                    </ul>                
                </div>
            </section>

            <SignupForm />

      <Footer />
</template>

<script>
      // import Website Navigation
      import WebNav from './../../components/website/WebNav.vue';
      import Seprator from './../../components/website/Seprator.vue';
        import InternalHeader from '../../components/website/InternalHeader.vue';
      import Footer from './../../components/website/Footer.vue';

      import SignupForm from './../../components/website/SignupForm.vue';

      export default {
        name : 'Waitlist',
        components : {
            WebNav,
            Seprator,
            InternalHeader,
            Footer,

            SignupForm
        },

        mounted () {
            // scroll to top
            window.scrollTo(0, 0);

            // remove styling used for apps
            document.getElementById('propstop-app').style.height = 'auto';
            document.getElementById('propstop-app').style.overflow = 'auto';

            //  change the title
            document.title = "Join the PropStop Waitlist: Be the First to Experience Innovation";

        }
    }

</script>