<script>

export default {
        name : 'WebHeroNew',
        data () {
            return {
                  StartArrowAnimation: false,
                  StartTextAnimation: false,
            }
        },
        
        mounted () {

            // check for the mobile and touch devices
            var isTouchEnabled = this.isMobileDevice ();

            if (isTouchEnabled) this.ManualAnimate ();
            else this.AutoAnimate ();

                     

        },

        methods: {
            isMobileDevice() {
                  if (navigator.userAgent.match(/Android/i)
                  || navigator.userAgent.match(/webOS/i)
                  || navigator.userAgent.match(/iPhone/i)
                  || navigator.userAgent.match(/iPad/i)
                  || navigator.userAgent.match(/iPod/i)
                  || navigator.userAgent.match(/BlackBerry/i)
                  || navigator.userAgent.match(/Windows Phone/i)) {
                        return true;
                  }else return false;
            },

            AutoAnimate () {
                  setTimeout (() => {
                        this.StartArrowAnimation = true;
                  }, 500);
                  setTimeout (() => {
                        this.StartTextAnimation = true;
                  }, 900);
            },

            ManualAnimate () {
                  var Swipeable = document.getElementById('Swipeable');
                  var HrefBtn = document.getElementById('HrefBtn');
                  Swipeable.ontouchmove = (event) => {
                        event.preventDefault ();
                        
                        var xPos = event.touches[0].clientX;
                        if (xPos <= 0 || xPos >= 205) {
                        }else {
                              Swipeable.style.left = xPos + 'px';
                        }

                        if (xPos >= 185) {
                              console.log ('hey text');
                              this.StartTextAnimation = true;
                        }

                        // open the link
                        if (xPos >= 199) {
                              setTimeout(() => {
                                    HrefBtn.click();
                              }, 500);
                        }
                  };
            }
        }

}

</script>

<template>

      <div class="new-page-hero">
            <div class="wrapper" style="display: flex;flex-direction: row;padding: 0;">
                  <div class="nph-content">
                        <div class="nphc-cont">
                              <h1 class="psanim-1 fadeInUp">The Only Property Management Tool You'll Ever Need</h1>
                              <p class="psanim-2 fadeInUp">PropStop is about to change the way property managers, and tenants, do business. PropStop is the only FREE, full-spectrum property manangement tool that does what you need it to do: EVERYTHING</p>
                              <router-link :to="{name : 'web-waitlist'}" id="HrefBtn" class="btn-wicon psanim-3 fadeInUp">
                                    <p :class="StartTextAnimation ? 'jwtext' : ''">Join The Waitlist</p>
                                    <span id="Swipeable" :class="StartArrowAnimation ? 'btn-arrow' : ''">
                                          <img src="./../../images/icons/arrow-right-blue.svg" alt="Right Arrow" />
                                    </span>
                              </router-link>
                        </div>
                  </div>
                  <div class="nph-image">
                        <img src="./../../images/website/hero-image.png" alt="PropStop" title="PropStop Hero Image" style="width: 460px;height: 460px;object-fit: contain;">
                  </div>
                  <!-- <div class="nph-image" :style="{'background-image':'url('+require('../../images/website/hp-hero-image.jpg')+')'}"></div> -->
            </div>
      </div>

</template>