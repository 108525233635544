<template>
    <div class="tenant-dashboard _propscrl" :style="{'background-image':'url('+require('../../images/company/loading-bg.jpg')+')'}">        
        <div v-if="!tenantProfile" style="width: 50%;">
            <Skeletion :type="'user-list'" :data="1" />
        </div>

        <div class="user" v-else>
            <div class="avatar">
                <UserImage :init="tenantProfile.fullname" :back="tenantProfile.back" :source="tenantProfile.avatar" />
            </div>

            <div class="content">
                <h2>Hi, {{tenantProfile.fname}}</h2>
            </div>

            <div class="ten-score" style="display: none;">
                <div class="tscontent">
                    <h3>10 Score</h3>
                    <p>Last Updated : </p>
                </div>

                <div class="tsarrow">
                    <img src="../../images/icons/up-arrow.svg" />
                </div>
            </div>
        </div>

        <div class="teandashboard">
            <ul class="dash-content">
                <li>
                    <div v-if="!tenantUnit" class="dash-box">
                        <div class="header">
                            <h3>Current Balance</h3>
                        </div>
                        <Skeletion :type="'user-list'" :data="1" />
                    </div>

                    <div v-else class="dash-box">
                        <div class="header">
                            <h3>Current Balance</h3>
                            <span>Manage</span>
                        </div>
                        <div class="content dbc-balance">
                            <h2>{{ tenantUnit.balance.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            }) }}</h2>

                            <p>Monthly Rent ({{ tenantUnit.rent.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            }) }})</p>

                            <p @click="ShowRentHistory = true" style="color: #428FD1;font-weight: 600; cursor: pointer; line-height: 18pt;">Rent History</p>

                            <div class="npay">
                                <img src="../../images/icons/calendar-view.svg" />
                                <p>Next Payment (July 21)</p>
                            </div>

                            <!-- <ul class="paymethod">
                                <li>
                                    <img src="../../images/icons/mastercard.svg" />
                                    <p>Mastercard **** 2540</p>
                                    <span>Change</span>
                                </li>
                            </ul> -->
                        </div>
                    </div>

                    <div class="dash-box">
                        <div class="header">
                            <h3>Quick Links</h3>
                        </div>
                        <div class="content">
                            <ul class="dllinks _propscrl">
                                <li @click="RouteToPage ('tenant-profile')">
                                    <div class="image">
                                        <img src="../../images/icons/user-profile.svg" />
                                    </div>

                                    <div class="content">
                                        <h3>Personal Profile</h3>
                                        <p>Update profile, password</p>
                                    </div>
                                </li>

                                <li @click="RouteToPage ('tenant-search')">
                                    <div class="image">
                                        <img src="../../images/icons/search-black.svg" />
                                    </div>

                                    <div class="content">
                                        <h3>Explore Properties</h3>
                                        <p>Search properties for rent or office space</p>
                                    </div>
                                </li>

                                <li @click="RouteToPage ('tenant-tickets')">
                                    <div class="image">
                                        <img src="../../images/icons/ticket-black.svg" />
                                    </div>

                                    <div class="content">
                                        <h3>Need Maintenance?</h3>
                                        <p>Open a ticket, request for maintenance or other support</p>
                                    </div>
                                </li>

                                <li style="display: none;">
                                    <div class="image">
                                        <img src="../../images/icons/document-text-outline.svg" />
                                    </div>

                                    <div class="content">
                                        <h3>Lease Agreement</h3>
                                        <p>Agreement for both parties</p>
                                    </div>
                                </li>

                                
                            </ul>
                        </div>
                    </div>
                </li>

                <li>
                    <div class="dash-box">
                        
                        <div class="dbprtimage">
                            <ReplaceImage :source="tenantUnit.thumbnail" />
                            
                            <div class="count">
                                <p>{{tenantProperty.gallery}}</p>
                                <img src="../../images/icons/gallery-white.svg" />
                            </div>
                        </div>

                        <div class="content">
                            <div class="propery-detail">
                                <div class="title">
                                    <h4>Unit: {{tenantUnit.unit_number}}</h4>
                                    <span>{{tenantProperty.type}}</span>
                                </div>

                                <h3>{{tenantProperty.name}}</h3>
                                <p>{{tenantProperty.address}}</p>

                                <ul class="features" v-if="tenantProperty.total_features > 0">
                                    <li>{{tenantProperty.feature}}</li>
                                    <li>{{tenantProperty.total_features}}+</li>
                                </ul>
                            </div>

                            <ul class="main-feat">
                                <li>
                                    <img src="../../images/icons/bed-outline.svg" />
                                    <p>2 Beds</p>
                                </li>

                                <li>
                                    <img src="../../images/icons/bathroom.svg" />
                                    <p>1 Bath</p>
                                </li>

                                <li>
                                    <img src="../../images/icons/scale.svg" />
                                    <p>1.2k sq. m.</p>
                                </li>
                            </ul>

                        </div>
                    </div>

                    <div class="dash-box">
                        <div class="header">
                            <h3>Agency</h3>
                        </div>
                        
                        <div v-if="!tenantAgency" style="width: 50%;">
                            <Skeletion :type="'user-list'" :data="1" />
                        </div>
                        <div v-else class="content">
                            <div class="agency-profile">
                                <div class="image">
                                    <UserImage :init="tenantAgency.business" :source="''" />
                                </div>

                                <div class="content">
                                    <h3>{{tenantAgency.business}}</h3>
                                    <p>{{tenantAgency.email}} . {{tenantAgency.phone}}</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </li>

                <li>
                    <div class="dash-box" style="padding: 0 15px;padding-bottom: 15px;">
                        <div class="header">
                            <h3>Recent Activities</h3>
                            <span>See All</span>
                        </div>

                        <RecentActivity :data="RcntActivity" :key="rctyload" />
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div v-if="hasNoProperty" class="empty-result animated fadeInUp" style="top: 0px;left: 0px;">
        <img src="./../../images/icons/on-verification.svg">
        <p>It looks like you haven't been assigned to a property yet.<br>Please search property/units where you want to live.</p>
        <div class="btn-primary bphasno" style="margin-top: 10px;" @click="RouteToPage('tenant-search')">
            <p>Search Properties</p>
        </div>
    </div>

    <PropModalVue v-if="ShowRentHistory" :from="'rent-history'" :data="RentHistoryData" @modalclose="CloseRentHistory" />
</template>

<script>
    // @ is an alias to /src
    import Document from '../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';

    import Skeletion from './../../components/blocks/Skeleton.vue';
    import ReplaceImage from '../../components/blocks/ReplaceImage.vue';
    import UserImage from './../../components/blocks/UserImage.vue';
    import PropModalVue from '../../components/modal/PropModal.vue';

    // dashboard component
    import RecentActivity from './../../components/owner/dashboard/RecentActivity.vue';

    export default {
        name: 'Dashboard',
        props: ['profile', 'properties', 'selected', 'hasNoProperty'],

        components : {
            Skeletion,
            ReplaceImage,
            UserImage,

            PropModalVue,

            RecentActivity,
        },

        data () {
            return {
                DashboardInformation : false,
                PropertyData : false,
                RcntActivity : [],
                
                // recent activity loaded
                rctyload : false,

                tenantAgency : false,
                tenantProperty : false,
                tenantUnit : false,
                tenantProfile: false,

                ShowRentHistory: false,
                RentHistoryData: null,
            }
        },

        async mounted () {

            this.RentHistoryData = {
                root: 'tenant',
                unit: this.selected.unit.id,
                name: this.selected.unit.unit_name
            }

            // site title and seo
            Document.DocumentHeadings ({title : 'Dashboard - PropStop'});

            // property and owner info
            var Property = this.selected;
            this.tenantAgency = Property.owner;
            this.tenantProperty = Property.property;
            this.tenantUnit = Property.unit;
            this.tenantProfile = this.profile;

            // tenant activities and property details
            if (this.tenantProperty) {
                const RequestData = await DataCenter.ProPRequest('tenant-dashboard', false, false);
                if (!RequestData) {} else {
                    this.DashboardInformation = RequestData;
                    
                    this.RcntActivity = RequestData;
                    this.rctyload = true;
                }
            }

        },

        methods: {

            RouteToPage (page) {
                this.$router.push({name : page});
            },

            CloseRentHistory () {
                this.ShowRentHistory = false;
            }

        }
    };
</script>

<style scoped>
    div.dash-box {
        border: 1px solid var(--primary-border);
        margin-top: 20px;
    }
</style>