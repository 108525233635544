<script>

      //@ JS Modules
      import Request from '../../../javascript/getRequest';
      import Handler from '../../../javascript/Handler';
      import FormValidation from '../../../javascript/FormValidation';

      import UserMultiSelectInput from '../../web-forms/UserMultiSelectInput.vue';

      export default {
            name: 'Announcements',
            props: ['owners'],
            components: {
                  UserMultiSelectInput,
            },
            
            data () {
                  return {
                        
                        Message: '',
                        BullLists: [
                              {
                                    id : 1,
                                    formId: 'List_1',
                                    input: ''
                              }
                        ],

                        Requesting: false,
                        formError: false,
                        infoText: '',

                        OwnerMultiSelect: false,
                        SelectedOwners: [],
                        SelectedTotalOwners: '',
                        SentToAll: false,
                  }

            },

            mounted () {
                  // console.log (this.owners);
            },

            methods: {

                  AddAnotherSection () {

                        let Count = this.BullLists.length + 1;

                        this.BullLists.push({
                              id: Count,
                              formId: 'List_' + Count,
                              input: ''
                        });

                  },

                  RemoveList (list) {
                        this.BullLists = this.BullLists.filter(bullet => bullet.id !== list.id);

                  },

                  OwnerSelected (owners) {
                        this.OwnerMultiSelect = false;

                        this.SelectedOwners = this.owners.filter((owner) => {
                              if (owner.active) {
                                    return owner;
                              }
                        });

                        if (this.SelectedOwners.length == 0) {
                              this.SelectedTotalOwners = "";
                        }else {
                              this.SelectedTotalOwners = this.SelectedOwners.length + " Selected";
                        }
                  },

                  async HandleSubmitForm () {
                        if (this.Requesting) return;

                        this.formError = false;

                        // form & validation
                        let form = document.getElementById('communication-form');
                        let canGoAhead = FormValidation.Validate (form);
                        if (canGoAhead) {
                              this.Requesting = true;

                              let formData = new FormData();
                              formData.append('message', this.Message);

                              // other bullet list in json data
                              this.BullLists.map(list => {
                                    formData.append('lists[]', list.input);
                              });

                              // list of owners to send email
                              let OwnerData = [];
                              if (this.SentToAll) OwnerData = this.owners;
                              else OwnerData = this.SelectedOwners;

                              formData.append('owners', JSON.stringify(OwnerData));

                              // send and receive data
                              const ReturnData = await Request.postServer('admin/action/announcements.php', formData);
                              this.Requesting = false;
                              
                              if (!ReturnData.error) {
                                    Handler.PropNotification ('Announcement has been sent successfully!');
                              }else {
                                    this.formError = true;
                                    this.infoText = "Something went wrong! Please try again later!"
                              }        
                        }
                  },
            }

      }

</script>

<template>
      <div class="comm-body animated fadeInUp">                        
            <form id="communication-form" autocomplete="off" style="display: block;">

                  <div class="pf--info" style="margin-bottom: 45px;">
                        <div class="icon">
                              <img src="./../../../images/icons/info-blue.svg" />
                        </div>
                        <div class="content">
                              <p>You can include comprehensive details of the announcements in a structured list format by incorporating various sections as needed.</p>
                        </div>
                  </div>

                  <div class="pf--input">
                        <p>Choose Owners</p>
                        <input type="text" v-model="SelectedTotalOwners" placeholder="Choose Owners" readonly @click="OwnerMultiSelect = true" :disabled="SentToAll" style="width: 40% !important;">
                        <UserMultiSelectInput v-if="OwnerMultiSelect" :lists="owners" :style="'left: 25px;top: 100px;'" @lstboxclse="OwnerSelected()" />

                        <div class="checkbox">
                              <input type="checkbox" :checked="SentToAll" v-model="SentToAll" />
                              <p>Send email to all Owners</p>
                        </div>
                  </div>

                  <div class="pf--input">
                        <p>Announcements Message</p>
                        <textarea placeholder="Message" v-model="Message" id="Message" data-type="len" data-max="3"></textarea>
                  </div>

                  <div class="pf--input">
                        <p>Other information in detail</p>
                        
                        <div class="bullt" v-for="(list, index) in BullLists" :key="list.id">
                              <textarea placeholder="Detail information" :name="list.formId" :id="list.formId" v-model="list.input"  data-type="len" data-max="3"></textarea>
                              <img src="./../../../images/icons/close.svg" @click="RemoveList(list)" />
                        </div>

                        <div class="addmor" @click="AddAnotherSection">
                              <p>Add Another Section</p>
                              <img src="./../../../images/icons/plus.svg" />
                        </div>
                  </div>

                  <div class="form-submit" style="margin-top: 25px;">
                        <span style="display: block;float: left;" 
                              class="primary-btn-loader" 
                              :class="Requesting ? 'btn-disabled' : ''" 
                              @click="HandleSubmitForm">

                              <p v-if="!Requesting">Submit Announcement</p>
                              <img v-else class="loader" src="./../../../images/gif/btn-loader.gif" />
                        </span>
                        <span v-if="formError" class="__formerror">{{ infoText }}</span>
                  </div>

            </form>

      </div>
</template>

<style scoped>
      div.checkbox {
            float: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 55%;
      }

      div.checkbox input {
            width: 25px !important;
      }

      div.checkbox p {
            flex: 1;
            line-height: 30px;
      }
</style>