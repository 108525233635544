<script>

    import DataCenter from '../../javascript/DataCenter';

    import { useAdminStore } from '../../stores/AdminStore';

    import Skeleton from './../../components/blocks/Skeleton.vue';
    import UserImage from '../../components/blocks/UserImage.vue';
    import HtmlSelect from './../../components/blocks/HtmlSelect.vue';
    import RightModal from '../../components/modal/RightModal.vue';

    export default {
        name: 'Teams',
        props: ['userProfile', 'owners'],
        
        components : {
            Skeleton,
            UserImage,
            HtmlSelect,
            RightModal,
        },

        data () {
            return {
                AdminStore: useAdminStore(),
                isRequesting: true,
                InviteNewUser: false,
                UpdateTeamUser: false,
                CurrentTeamUser: {},

                AdminUsers: {
                    raw: [],
                    filtered: []
                },

                TeamRoles: [],
            }
        },

        async mounted () {
            
            this.TeamRoles = this.AdminStore.roles;

            // load all the team members
            const ReturnData = await DataCenter.ProPRequest('admin-teams', false, false);
            if (ReturnData) {
                this.AdminUsers.raw = ReturnData;
                this.AdminUsers.filtered = ReturnData;
                this.isRequesting = false;
            }
        },

        methods: {

            RoleClicked (selected) {
                // now filter the team members
                // and show to the stage
                if (selected.id == '01') this.AdminUsers.filtered = this.AdminUsers.raw;
                else {
                    this.AdminUsers.filtered = this.AdminUsers.raw.filter(user => {
                        if (user.role == selected.id) return user;
                    });
                }
            },

            InviteUser () {
                this.InviteNewUser = true;
            },

            CloseInvitation (NewUser) {
                if (NewUser) {
                    this.AdminUsers.raw.push(NewUser); // update new user
                    
                    // now automatically add to the filter with 'all' click 
                    this.AdminUsers.filtered = this.AdminUsers.raw;

                }

                this.InviteNewUser = false;
            },

            UpdateTeamUserHandler (user) {
                this.CurrentTeamUser = {
                    user: user,
                    roles: this.AdminStore.roles
                };

                this.UpdateTeamUser = true;
            },


            CloseUpdate (UpdatedUser) {
                if (UpdatedUser) {
                   this.AdminUsers.raw.map(user => {
                        if (user.id == UpdatedUser.id) {
                            user.fname = UpdatedUser.fname;
                            user.lname = UpdatedUser.lname;
                            user.email = UpdatedUser.email;
                            user.role = UpdatedUser.role;
                            user.role_name = UpdatedUser.role_name;
                        }
                    });

                    this.AdminUsers.filtered = this.AdminUsers.raw;
                }
                this.UpdateTeamUser = false;
            }

        }

    }

</script>

<template>
    <div class="admin-property _propscrl">

        <div class="dashpageheader">
            <div class="dash-header">
                <div class="actions">
                    <HtmlSelect :options="AdminStore.roles" @selected="RoleClicked" />
                </div>
                <div class="addition">
                    <div class="btn-primary" @click="InviteUser">
                        <img src="../../images/icons/addbtn-icon.svg" />
                        <p>Invite User</p>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isRequesting" style="width: 50%;display: flex;margin: 0px auto;margin-top: 25%;">
            <Skeleton :type="'user-list'" :data="2" />
        </div>

        <ul class="dash-list" v-if="!isRequesting">
            <li v-for="team in AdminUsers.filtered" :key="team.id" @click="UpdateTeamUserHandler(team)">
                <div class="content">
                    <div class="image">
                        <UserImage :init="team.fname" :source="team.avatar" :back="team.back" />
                    </div>
                    <div class="dlc-detail">
                        <div class="od-header">
                            <h3>{{ team.fullname }}</h3>
                            <span class="image" v-if="team.verified == 'true'"><img src="../../images/icons/nverified.svg" alt="User Verified"></span>
                        </div>
                        <div class="od-footer">
                            <ul>
                                <li>
                                    <img src="../../images/icons/nemail.svg">
                                    <p>{{ team.email }}</p>
                                </li>
                                <li>
                                    <img src="../../images/icons/nrole.svg">
                                    <p>{{ team.role_name }}</p>
                                </li>
                                <li>
                                    <img src="../../images/icons/ndate.svg">
                                    <p>{{ team.date }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <ul class="dli-hover">
                    <li>
                        <img src="../../images/icons/nedit.svg">
                    </li>
                </ul>
            </li>
        </ul>

        <div v-if="AdminUsers.filtered.length == 0 && !isRequesting" class="empty-result animated fadeInUp" style="position: unset;height: 50vh;">
            <img src="../../images/icons/empty-result.svg" />
            <p>You don't have much information on this request.</p>
            <p>You can invite user by email!</p>
        </div>
    </div>

    <RightModal v-if="InviteNewUser" :form="'invite-user'" :data="TeamRoles" @modalclose="CloseInvitation"  />
    <RightModal v-if="UpdateTeamUser" :form="'update-team-user'" :data="CurrentTeamUser" @modalclose="CloseUpdate"  />
</template>