<template>
    <div v-if="TicketsLoadingState" style="margin-top:45px">
        <Skeleton :type="'large-list'" :data="2" />
    </div>

    <div v-if="!TicketsEmpty" class="prop--table" style="border: 1px solid #e8e8e8;border-bottom: none;width: calc(100% - 2px);">        
        <div class="prop-row ticketClk transAe" v-for="(ticket, index) in TenantTickets" :key="ticket"  :class="index % 2 ? 'odd' : 'even'">     
            <div class="column column5"></div>
            <div class="column column80">
                <div class="ticket--subject">
                    <p><strong>#{{ticket.id}}</strong> {{ ticket.subject }}</p>
                    <ul class="ticket-priority">
                        <li v-if="ticket.priority == 'Critical'" class="critical"><img src="../../../images/icons/critical.svg" /> <p>{{ticket.priority}}</p></li>
                        <li v-if="ticket.priority == 'High'" class="high"><img src="../../../images/icons/high.svg" /> <p>{{ticket.priority}}</p></li>
                        <li v-if="ticket.priority == 'Normal'" class="normal"><img src="../../../images/icons/normal.svg" /> <p>{{ticket.priority}}</p></li>
                        <li v-if="ticket.priority == 'Low'" class="low"><img src="../../../images/icons/low.svg" /> <p>{{ticket.priority}}</p></li>
                    </ul>
                </div>
            </div>
            <div class="column column5"></div>
        </div>
    </div>

    <div v-if="!TicketsLoadingState && TicketsEmpty" class="emptyskeleton">
        <img src="./../../../images/icons/empty-ticket.svg" style="width: 220px;" class="psanim-1 fadeInUp">
        <p class="psanim-2 fadeInUp">Seems you this user has no open tickets. <br>You can create ticket for tenant here.</p>
        <div class="btn-secondary psanim-3 fadeInUp" @click="CreateTicket">
            <p>Create Ticket</p>
        </div>
    </div>
</template>

<script>
    import DataCenter from './../../../javascript/DataCenter';

    import Skeleton from './../../../components/blocks/Skeleton.vue';

    export default {
        name: 'Ticket',
        props: ['profile'],
        emits: ['createticket'],
        components: {
            Skeleton,
        },

        data () {
            return {
                TicketsLoadingState : true,
                TicketsEmpty : true,
                TenantTickets : [],
            }
        },

        async mounted () {
            console.log (this.profile);
            // now load all the tickets of this tenant
            let getdata = {
                key : 'unit',
                value : this.profile.unit
            }
            const Tickets = await DataCenter.ProPRequest('owner-tenant-tickets', false, getdata);
            if (Tickets) {
                this.TicketsEmpty = false;
                this.TenantTickets = Tickets;
            }else {
                this.TicketsEmpty = true;
            }
            // tickets loaded
            this.TicketsLoadingState = false;
        },

        methods: {
            CreateTicket () {
                this.$emit('createticket');
            }
        }
    };
</script>