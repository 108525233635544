<template>
  <div class="prop-form _propscrl">
    <div class="form-area" style="padding-bottom: 65px">
      <form name="propform" id="propform-document" style="display: block">
        <div class="pf--title">
          <h3>Storage Preferences</h3>
          <p>When you visit websites, they may store or retrieve data about you using cookies and similar technologies ("cookies"). Cookies may be necessary for the basic functionality of the website as well as other purposes. You have the option of disabling certain types of cookies, though doing so may impact your experience on the website.</p>
          <span @click="RoutToPolicy">Cookie Policy</span>
        </div>

        <!-- cookie -->
        <div class="pf--input">
          <div class="pretty p-switch p-fill" style="border: none;">
                <input type="checkbox" checked="true" disabled="true">
                <div class="state p-success">
                    <label style="margin-top: 1px;">Essential</label>
                </div>
          </div>
        </div>
        <div class="pf--title">
          <p>These cookies are necessary for the website to function properly. Disabling this, may impact your experience.</p>
        </div>
        <!-- /cookie -->

        <!-- cookie -->
        <div class="pf--input">
          <div class="pretty p-switch p-fill" style="border: none;">
                <input type="checkbox" checked="true">
                <div class="state p-success">
                    <label style="margin-top: 1px;">Targeted Advertising</label>
                </div>
          </div>
        </div>
        <div class="pf--title">
          <p>These cookies help the website show you ads that are more suited to your interests. They may also prevent you from seeing the same ad too often and help measure how well the ads work. The website operator allows advertising networks to place these cookies on their site.</p>
        </div>
        <!-- /cookie -->
      
        <!-- cookie -->
        <div class="pf--input">
          <div class="pretty p-switch p-fill" style="border: none;">
                <input type="checkbox" checked="true">
                <div class="state p-success">
                    <label style="margin-top: 1px;">Personalization</label>
                </div>
          </div>
        </div>
        <div class="pf--title">
          <p>These cookies enable the website to customize your experience based on your preferences. For instance, by remembering your username, language, or region, the website can show you relevant information such as the weather or traffic in your area.</p>
        </div>
        <!-- /cookie -->

        <!-- cookie -->
        <div class="pf--input">
          <div class="pretty p-switch p-fill" style="border: none;">
                <input type="checkbox" checked="true">
                <div class="state p-success">
                    <label style="margin-top: 1px;">Analytics</label>
                </div>
          </div>
        </div>
        <div class="pf--title">
          <p>These cookies allow the website operator to monitor how the website performs, how visitors use the site, and whether there are any technical problems.</p>
        </div>
        <!-- /cookie -->

        
      </form>

    </div>

    <div class="form-action">
      <div class="form-area">
        <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="SaveChanges" style="margin-top: 10px;display: block;float: left;">
          <p v-if="!Requesting">Save Changes</p>
          <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>

    export default {
      name: "CookiePreferences",
      props: ["data"],
      emits: ["close"],
      
      components: {

      },

      data() {
        return {
          

          // form error
          formError: false,
          infoText: "",
          Requesting: false,
        };
      },

      mounted() {
        
      },

      methods: {

        SaveChanges () {
          this.$emit('close', false);
        },

        RoutToPolicy () {
          this.$router.push({name : 'privacy-policy'});
          this.$emit('close', false);
        }

      },
    };
</script>

<style scoped>
div.pf--title span {
  color: #2196F3;
    margin-top: 10px;
    display: block;
    text-decoration: underline;
    font-size: 12pt; cursor: pointer;
}
span.__formerror {
  margin-left: 0 !important;
}
.v-calendar {
  width: calc(100% - 2px) !important;
  min-width: 100%;
}
</style>
