<template>
    <UnderDev />
</template>

<script>
    //@imoprt component
    import UnderDev from './../../components/reusable/UnderDev.vue';

    export default {
        name: 'PropertyDetail',
        components: {
            UnderDev,
        }
    };
</script>