<template>
    <div class="prop-form _propscrl">        
        <div class="form-area" style="padding-bottom: 65px;">
            <form name="propform" id="propform-parking">
                <div class="pf--title">
                    <h3>Edit Parking</h3>
                    <p>You can update the parking name and parking type.</p>
                </div>

                <div class="pf--input">
                    <p>Parking Name</p>
                    <input type="text" placeholder="Parking Name" v-model="ParkingName" id="ParkingName" data-type="len" data-max="2" />
                </div>

                <div class="pf--input">
                    <p>Parking Type</p>
                    <select id="ParkingType" v-model="ParkingType">
                        <option value="-1">- Select One -</option>
                        <option value="Unit Parking">Unit Parking</option>
                        <option value="Handicap Parking">Handicap Parking</option>
                        <option value="Guest Parking">Guest Parking</option>
                    </select>
                </div>

            </form>
            
            <span v-if="formError" class="__formerror">{{ infoText }}</span>
        </div>

        <div class="form-action">
            <div class="form-area">
                <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="EditCurrentParking">
                    <p v-if="!Requesting">Edit Parking</p>
                    <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    //@ JS Modules
    import Request from './../../javascript/getRequest';
    import FormValidation from './../../javascript/FormValidation';

    // import component
    import PropertyList from './../blocks/PropertyList.vue';

    export default {
        name: 'EditParking',
        props: ['data'],
        emits: ['close'],
        components: {
            PropertyList,
        },

        data () {
            return {
                // Form v-Modal
                ParkingName : this.data.name,
                ParkingType : this.data.type,
                Requesting: false,

                // form error
                formError : false,
                infoText : '',
            }
        },

        mounted() {
            console.log (this.data);
        },

        methods: {        
            

            // now add the parking & get back to the list
            async EditCurrentParking () {
                if (this.Requesting) return;

                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-parking');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead) {
                    this.Requesting = true;

                    let formData = new FormData();
                    formData.append('property', this.data.property);
                    formData.append('parking', this.data.id);
                    formData.append('name', this.ParkingName);
                    formData.append('type', this.ParkingType);                    

                    // load data from server
                    const ReturnData = await Request.postServer('property/parking/edit.php', formData);
                    this.Requesting = false;

                    // now check if the email already exists
                    if (ReturnData.error == false) {
                        // now close modal, and load current property
                        this.$emit('close', ReturnData.response);
                    
                    // if error
                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again later or contact support.";
                    }
                }
            }
        }


    };
</script>

<style scoped>
span.__formerror {margin-left: 0 !important;}
.primary-btn-loader {    margin-top: 15px !important;
    padding: 10px 30px !important;
    border-radius: 6px !important;
    float: left !important;
    width: 125px !important;}
</style>