<template>
    <div class="welcome-message">
        <img src="../../images/svg/rent-changes.svg" style="width: 50%; height: 200px;" />
        <p>Your Property Manager Updated Your Rent! Here is the summery of rent changes.</p>
        <div class="prop--table" style="display: flex;flex-direction: column;align-items: center;justify-content: flex-start;border: 1px solid #dbdbdb;margin: 10px 0;border-radius: 7px;">
            <div class="prop-row row-header" style="padding-bottom: 20px !important;">
                <div class="column column40"><p>Unit</p></div>
                <div class="column column30"><p>Previous Rent</p></div>
                <div class="column column30"><p>Current Rent</p></div>
            </div>

            <div class="prop-row">
                <div class="column column40"><p>{{this.data.unit_name}}</p></div>
                <div class="column column30"><p>${{this.data.previous}}</p></div>
                <div class="column column30"><p style="font-weight: bold;">${{ this.data.current }}</p></div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name : 'WelcomeOwner',
        props: ['data'],
        emits: ['close'],
        mounted () {
            console.log (this.data);
        },
        methods: {
            CloseModal () {
                this.$emit('close');
            }
        }
    };
</script>