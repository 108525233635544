<template>
    <div class="internal--header">
        <div class="filter-area">
            <div class="fil-section">
                <select name="imagetype">
                    <option value="-1" selected>Image Type</option>
                    <option value="all">All</option>
                    <option value="images">Images</option>
                    <option value="video">Video</option>
                    <option value="360">360 Views</option>
                </select>
            </div>
        </div>

        <div class="actions" style="display: none;">
            <div class="btn-secondary" @click="UploadAttachment = true"><p>Upload Images</p></div>
        </div>
    </div>

    <div class="prop-gallery _propscrl">
        <div v-if="GalleryLoadingState" style="margin-top:55px">
                <Skeletion :data="3" />
        </div>

        <ul v-if="!GalleryLoadingState && PropertyGallery.length > 0" class="gallery animated fadeInUp" style="width: 100%;">
            <WalkThroughVue
                v-if="WthoughPrimary"
                :image="'edit-property.svg'"
                :title="'Default Image'"
                :content="'Click image to select default property image.'"
                :style="'top: 45px;left: 70px;'"
                @closewth="CloseWalkThrough()" />

            <div v-for="gallery in PropertyGallery" :key="gallery.key">
                <li v-if="gallery.published == 'true'" @click="ViewMediaFileHandler (gallery)">
                    <div class="image">
                        <img :src="gallery.url">
                    </div>
                </li>
            </div>
        </ul>

        <!-- If no images on gallery -->
        <div v-if="!GalleryLoadingState && PropertyGallery.length <= 0" class="empty-gallery">
            <img src="../../images/icons/no-image.png" />
            <p>No images found for this property.</p>

            <WalkThroughVue
                v-if="WthoughSecondary"
                :image="'edit-property.svg'"
                :title="'Upload to gallery'"
                :content="'You can upload videos and property images here.'"
                :style="'top: 40px;left: 440px;'"
                @closewth="WthoughSecondary = false" />

        </div>
    </div>

    <PropModal v-if="UploadAttachment" :from="'upload-document'" :data="AttachmentData" @modalclose="CloseUploadModal" />
    <RightModal v-if="ViewMediaFile" :form="'view-document'" :data="ViewMediaData" @modalclose="CloseViewModal" />

</template>

<script>

    //@ JS Modules
    import Document from '../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';
    import Request from './../../javascript/getRequest';
    import Handler from './../../javascript/Handler';

    // pinia
    import { useOwnerStore } from './../../stores/OwnerStore';

    //@ import components blocks
    import Skeletion from './../blocks/Skeleton.vue';
    import HtmlImage from './../blocks/HtmlImage.vue';

    import PropModal from './../modal/PropModal.vue';
    import RightModal from './../modal/RightModal.vue';

    // walk-through
    import WalkThroughVue from './../reusable/WalkThrough.vue';

    export default {
        name: 'Gallery',
        props: ['property', 'initUpload'],
        emits: ['setdefault', 'dischargeUpload'],
        components: {
            Skeletion,
            HtmlImage,

            PropModal,
            RightModal,

            WalkThroughVue,
        },

        data () {
            return {
                PropertyUnits: [],
                GalleryLoadingState: true,
                PropertyGallery: [],

                // attachment
                UploadAttachment : false,
                AttachmentData: {
                    title: 'Upload Images',
                    description: 'Please click on the "Add Files" button or drag and drop an image below to upload pictures of the property.',
                    error: 'Please upload JPEG or JPG files that are less than 5 MB in size.',
                    fileschema: ['jpeg', 'jpg'],
                },

                // view media files
                ViewMediaFile: false,
                ViewMediaData: [],

                // pinia storage
                OwnerStore: useOwnerStore (),

                // page walk through
                WthoughPrimary: false,
                WthoughSecondary: true,
            }
        },

        async mounted () {
            // site title and seo
            Document.DocumentHeadings ({title : ' Properties - Overview'});

            // walkthrough settings
            this.WthoughPrimary =  this.OwnerStore.walkthrough.property.gallery;

            // init upload images
            if (this.initUpload) {
                this.UploadAttachment = true;
            }

            // load units
            await this.loadCurrentPropertyMedia ();
            
        },

        methods: {

            // close the walkthrough
            async CloseWalkThrough () {
                this.WthoughPrimary = false;
                this.OwnerStore.walkthrough.property.gallery = false;

                // update to the server
                await DataCenter.ProPRequest('owner-walkghrough', false, {
                    key : 'wlkough',
                    value : 'gallery'
                });
            },

            // load property images
            async loadCurrentPropertyMedia () {
                // load gallery data from server
                let getdata = {
                    key : 'property',
                    value : this.property.id
                }
                
                const Gallery = await DataCenter.ProPRequest('property-gallery', false, getdata);
                this.GalleryLoadingState = false;
                if (!Gallery) {
                }else {
                    this.PropertyGallery = Gallery;
                }
            },

            // handle view media files
            ViewMediaFileHandler (image) {
                this.ViewMediaData = {
                    property: this.property,
                    gallery : this.PropertyGallery,
                    current : image,
                    from : 'gallery',
                    floorplan: ''
                };

                // start viewing the selected image
                this.ViewMediaFile = true;
            },

            // close the media files view modal
            CloseViewModal (update) {
                
                if (update) {
                    // add all to gallery
                    this.PropertyGallery = update.Gallery;

                    // check if set as default
                    if (update.isDefault) {
                        this.$emit('setdefault', update.DefaultUrl);
                    }

                    // update notification
                    Handler.PropNotification ("Image updated succesfully!");
                }            

            },

            // close documents upload modal
            async CloseUploadModal (blobupload) {                
                if (blobupload || blobupload.length >= 1) {
                    // now upload the selected files to the server
                    let formData = new FormData();
                    formData.append('property', this.property.id);
                    formData.append('uploadtype', 'property');
                    formData.append('floorplan', '');

                    // convert file list into files[array] form data      
                    for (let element of blobupload) {          
                        formData.append('documents[]', element);
                    };                

                    // upload to the server
                    const ReturnData = await Request.postServer('owner/upload/gallery-upload.php', formData);
                    if (!ReturnData.error) {          
                        // add all to gallery

                        this.PropertyGallery = ReturnData.response;

                        // close the modal
                        this.UploadAttachment = false;

                        // update notification
                        Handler.PropNotification ("Image(s) uploaded succesfully!");
                    }
                }else {
                    // close the modal
                    this.UploadAttachment = false;
                }


                // sent data to parent
                this.$emit('dischargeUpload');
            },
        }
        
    }
    
</script>