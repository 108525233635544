<template>
    <div class="prop-form _propscrl">
        <div class="form-area" style="padding-bottom: 65px;">
            
            <form name="propform" id="propform-finance" style="display: block;">
                <div class="pf--title">
                    <h3>Add Transaction</h3>
                    <p>Choose what do you want to do ahead, you can add business expenses or unit charges or tenant payment.</p>
                </div>

                <ul class="select-type st-update">
                    <li v-for="ttype in TransactionTypes" :key="ttype.key" :class="{ active : ttype.active}" @click="handleTypeClick(ttype)">
                        <div class="image">
                            <img :src="require(`./../../../images/icons/${ttype.image}`)" v-if="!ttype.active" />
                            <img :src="require(`./../../../images/icons/${ttype.imageHover}`)" v-if="ttype.active" />
                        </div>
                        <div class="content">
                            <h3>{{ ttype.name }}</h3>
                            <p>{{ ttype.description }}</p>                            
                        </div>
                        <div class="hover" v-if="ttype.active" style="display : none;">
                            <img src="./../../../images/icons/arrow-right-blue.svg" class="animated fadeInLeft" />
                        </div>
                    </li>
                </ul>

            </form>

        </div>

        <div class="form-action">
            <div class="form-area">
                <span class="primary-btn-loader" @click="NextStep" style="display: block; width: 120px; margin-top: 10px; float: left;">
                    <p>Next Step</p>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ChooseType',
        emits: ['typechoosed'],
        components: {
            
        },

        data () {
            return {
                // transaction types
                TransactionTypes : [
                    {
                        key: 1,
                        type: 'payment',
                        name: 'Tenant Payment',
                        description: 'Select a single tenant to process a payment.',
                        image: 'user-blue.svg',
                        imageHover: 'user-white.svg',
                        active: true
                    },
                    {
                        key: 4,
                        type: 'bulk',
                        name: 'Bulk Rent Payment',
                        description: 'Process payments for multiple tenants at once.',
                        image: 'user-blue.svg',
                        imageHover: 'user-white.svg',
                        active: false
                    },
                    {
                        key: 2,
                        type: 'charges',
                        name: 'Unit Charges',
                        description: 'Bill one or more tenants for services or fees.',
                        image: 'users-blue.svg',
                        imageHover: 'users-white.svg',
                        active: false
                    },
                    {
                        key: 3,
                        type: 'business',
                        name: 'Business Expense',
                        description: 'Add operating expenses for a property.',
                        image: 'users-blue.svg',
                        imageHover: 'users-white.svg',
                        active: false
                    }
                ],

                TransactionType: 'payment'
            }
            
        },

        mounted () {
            
        },

        methods: {

            NextStep () {
                this.$emit('typechoosed', this.TransactionType);
            },

            // handle type of transaction
            handleTypeClick (e) {                
                this.TransactionTypes = this.TransactionTypes.filter(type => {
                    type.active = false;
                
                    if (type.key == e.key) {
                        type.active = true;
                        this.TransactionType = type.type;
                    }

                    return type;
                });
            },


        }
    }
</script>

<style>

    ul.select-type > li:nth-child(2)::after {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: #dddddd;
        bottom: -20px;
        left: 0;
    }

    ul.select-type > li:nth-child(2) {
        position: relative;
        margin-bottom: 25px;
    }

</style>