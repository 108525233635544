<template>
    <div class="prop-form _propscrl">
        <div class="form-area" style="width: 60%;margin-top: 55px;padding-bottom: 65px;">
            <form id="propform-profile" autocomplete="off">
                <div class="pf--title">
                    <h3>Complete Profile</h3>
                    <p><span style="font-size: 18pt;">👋</span>Hello {{FirstName}}! Please Complete your personal profile. Once you submit this information you will be ready for your dashboard.</p>
                </div>

                <div class="pf--info" style="margin-bottom: 45px;width: 60%;">
                    <div class="icon">
                        <img src="./../../../images/icons/info-blue.svg" />
                    </div>
                    <div class="content">
                        <p>Please change or create new strong password to secure your account.</p>
                    </div>
                </div>

                <div class="pf--multi-input" style="height: 75px;">
                    <div class="half hleft">
                        <div class="pf--input">
                            <p>First Name</p>
                            <input type="text" placeholder="Jon" v-model="FirstName" id="FirstName" data-type="len" data-max="3" />
                        </div>
                    </div>
                    <div class="half hright">
                        <div class="pf--input">
                            <p>Last Name</p>
                            <input type="text" placeholder="Doe" autocomplete="new-password" v-model="LastName" id="lastName" data-type="len" data-max="3" />
                        </div>
                    </div>
                </div>

                    <div class="pf--multi-input">
                        <div class="half hleft">
                            <div class="pf--input">
                                <p>Email Address</p>
                                <input type="text" :value="data.email" disabled />
                            </div>
                        </div>
                        <div class="half hright">
                            <PasswordInput @passwordchange="HandlePasswordInput" :direction="''" />
                        </div>
                    </div>

                    <div class="pf--input" style="overflow:hidden;">
                        <p>Mobile Number</p>
                        <input type="text" placeholder="+1 818 555 1212" v-model="Mobile" id="Mobile" data-type="len" data-max="5" />
                    </div>

                    <div class="pf--multi-input">
                        <div class="half hleft">
                            <div class="pf--input">
                                <p>Date of Birth</p>
                                <Datepicker :input-class="'pf--calendar'" v-model="userDOB" :lang="'en'" :position="'top'" />
                            </div>
                        </div>
                        <div class="half hright">
                            <div class="pf--input">
                                <p>Preferred Pronoun</p>
                                <select name="floor" v-model="Gender" id="Gender" data-type="select">
                                    <option value="-1" selected>Select One</option>
                                    <option value="He">He/Him</option>
                                    <option value="She">She/Her</option>
                                    <option value="They">They/Them</option>
                                </select>
                            </div>
                        </div>
                    </div>

            </form>
        </div>

        <div class="form-action">
            <div class="form-area" style="width: 60%;">
            
                <span class="primary-btn-loader" :class="Requesting ? 'btn-disabled' : ''" @click="HandleSubmitForm">
                    <p v-if="!Requesting">Complete Profile</p>
                    <img v-else class="loader" src="./../../../images/gif/btn-loader.gif" />
                </span>

                <span v-if="formError" class="__formerror">{{ infoText }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    //@ JS Modules
    import Request from './../../../javascript/getRequest';
    import FormValidation from './../../../javascript/FormValidation';

    // import datepicker component
    import 'vue-datepicker-ui/lib/vuedatepickerui.css';
    import VueDatepickerUi from 'vue-datepicker-ui';

    // google address
    import PasswordInput from './../../web-forms/PasswordInput.vue';

    export default {
        name: 'CompleteTenantProfile',
        props: ['data'],
        emits: ['close'],
        components: {
            Datepicker: VueDatepickerUi,
            PasswordInput,
        },

        data () {
            return {
                Requesting: false,

                FirstName : this.data.fname,
                LastName : this.data.lname,
                Password : '',
                Mobile : '',
                userDOB : '',
                Gender : '-1',
                Address: '',

                passwordStrength : 'red',

                // form handler
                formError : false,
                infoText : '',
            }
        },

        methods : {

            
            HandlePasswordInput (password, strength) {
                this.Password = password;
                this.passwordStrength = strength;
            },
            
            // submit the form
            async HandleSubmitForm () {
                if (this.Requesting) return;
                this.formError = false;

                // form & validation
                let form = document.getElementById('propform-profile');
                let canGoAhead = FormValidation.Validate (form);
                if (canGoAhead && (this.passwordStrength == "green" || this.passwordStrength == "orange")) {
                    this.Requesting = true;

                    let formData = new FormData();
                    formData.append('fname', this.FirstName);
                    formData.append('lname', this.LastName);
                    formData.append('password', this.Password);
                    formData.append('mobile', this.Mobile);
                    formData.append('dob', this.userDOB);
                    formData.append('gender', this.Gender);

                    // send and receive data
                    const ReturnData = await Request.postServer('employee/user/update-profile.php', formData);
                    this.Requesting = false;

                    if (!ReturnData.error) {
                        // return and close modal
                        this.$emit('close', ReturnData.response);
                    }else {
                        this.formError = true;
                        this.infoText = "Something went wrong! Please try again later!"
                    }

                }
            },



        }

    };
</script>

<style scoped>
span.__formerror {
    margin-left: 10px !important;
    margin-top: 17px !important;}
</style>